import { getStatisticsBodyJson } from '../selectors/downloadables'
import { getMonitoringExcelUrl } from '../selectors/config'
import { closeModal } from './modal'
import { fetchDownloadableWrapper } from './ui'
import FileSaver from 'file-saver'
import moment from 'moment'
import { APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE } from '@layers-frontend/commons/constants'
import { errorNotification } from './notifications'

import get from 'lodash/get'
import includes from 'lodash/includes'

export const DOWNLOAD_STATISTICS = 'DOWNLOAD_STATISTICS'
const STATISTICS_NAME_FILE = 'MONITORING'
const DOWNLOAD_STATISTICS_SUCCESS = 'DOWNLOAD_STATISTICS_SUCCESS'

export const fetchStatisticsModal = () => (dispatch, getState) => {
  const state = getState()
  const downloadUrl = getMonitoringExcelUrl(state)
  const body = getStatisticsBodyJson(state)

  dispatch(
    fetchDownloadableWrapper({
      requestType: DOWNLOAD_STATISTICS,
      customRoute: downloadUrl,
      onSuccess: fetchStatisticsModalSuccess,
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE,
        body: JSON.stringify(body)
      }
    })
  ).catch(error => {
    if (includes(get(error, 'message'), '504')) {
      dispatch(errorNotification('Selection includes too much information, try selecting a shorter date range'))
    }
  })
}

const fetchStatisticsModalSuccess = blob => dispatch => {
  const timeNow = moment().format('DD_MM_YYYY_hh_mm_ss')

  dispatch(closeModal())
  FileSaver.saveAs(blob, `${STATISTICS_NAME_FILE}_${timeNow}.xls`)
  dispatch({
    type: DOWNLOAD_STATISTICS_SUCCESS
  })
}
