import userStoredData from '../userStoredData'
import { setMessagesFromLocale } from '@layers-frontend/commons/store/actions/messages'

export const SET_LOCALE = 'SET_LOCALE'

export const setLocale = (locale = 'es') => ({
  type: SET_LOCALE,
  payload: locale
})

export const seti18n = (locale = 'es') => dispatch => {
  userStoredData.setLocale(locale)
  dispatch(setLocale(locale))

  return dispatch(setMessagesFromLocale(locale))
}
