import React from 'react'

import StyledTextInput from '../../UIComponents/StyledTextInput'
import StyledAutoComplete from '../../UIComponents/StyledAutocomplete'
import StyledTextArea from '../../UIComponents/StyledTextArea'

import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { useFormContext, Controller } from 'react-hook-form'
import { FIELD_SEASON_FORM_KEYS as KEYS, REQUIRED_FORM_VALIDATION_RULE, NOT_REQUIRED_FORM_VALIDATION_RULE } from '../../../constants'

const Wrapper = styled.div`
  color: ${themeColors.whiteColor};
  display: grid;
  grid-column-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  padding: 0 1.5rem 2.5rem 1.5rem;
`

const CommentWrapper = styled.div`
  grid-column: 2;
  grid-row: 1 / 3;
`

function FieldDetailsSection({ customers, isReadOnly }) {
  const { control } = useFormContext()
  return (
    <Wrapper>
      <Controller
        name={KEYS.FIELD_NAME}
        control={control}
        rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <StyledTextInput
            name={KEYS.FIELD_NAME}
            value={value}
            label="Field Name"
            helperText={error?.type}
            onChange={text => onChange(text)}
            InputLabelProps={{ shrink: true }}
            disabled={isReadOnly}
            inputProps={{ 'data-testid': 'field-name-input' }}
          />
        )}
      />
      <CommentWrapper>
        <Controller
          name={KEYS.COMMENT}
          control={control}
          rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <StyledTextArea
              errorText={error?.type}
              name={KEYS.COMMENT}
              value={value}
              label="comment"
              onChange={text => onChange(text)}
              disabled={isReadOnly}
              inputProps={{ 'data-testid': 'comment-input' }}
            />
          )}
        />
      </CommentWrapper>
      <Controller
        name={KEYS.CUSTOMER}
        control={control}
        rules={REQUIRED_FORM_VALIDATION_RULE}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <StyledAutoComplete
              errorText={error?.type}
              label="Customer"
              value={value}
              options={customers}
              onChange={onChange}
              disabled={isReadOnly}
              testId={'customer-input'}
            />
          )
        }}
      />
    </Wrapper>
  )
}

export default FieldDetailsSection
