import { fetchFilteredFields } from './fields'
import { getShouldQueryFilters } from '../selectors/ui'
import { getFieldsLength } from '../selectors/fields'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'
import { isFiltersEmpty, getFiltersSearchInputString } from '../selectors/filters'
export const RESET_CROP_FILTERS = 'RESET_CROP_FILTERS'
export const RESET_CUSTOMER_FILTERS = 'RESET_CUSTOMER_FILTERS'
export const RESET_PLANTATION_DATE_FILTERS = 'RESET_PLANTATION_DATE_FILTERS'
export const RESET_SEARCH_INPUT_FILTERS = 'RESET_SEARCH_INPUT_FILTERS'
export const RESET_CUT_NUMBER_FILTERS = 'RESET_CUT_NUMBER_FILTERS'
export const RESET_SEASON_LABEL_FILTERS = 'RESET_SEASON_LABEL_FILTERS'
export const RESET_ALL_FILTERS = 'RESET_ALL_FILTERS'

export const ADD_CROPS_TO_FILTERS = 'ADD_CROPS_TO_FILTERS'
export const SET_CROP_TO_FILTERS = 'SET_CROP_TO_FILTERS'
export const ADD_CUSTOMERS_TO_FILTERS = 'ADD_CUSTOMERS_TO_FILTERS'
export const SET_CUSTOMER_TO_FILTERS = 'SET_CUSTOMER_TO_FILTERS'
export const REMOVE_CUSTOMERS_FROM_FILTERS = 'REMOVE_CUSTOMERS_FROM_FILTERS'
export const REMOVE_CROPS_FROM_FILTERS = 'REMOVE_CROPS_FROM_FILTERS'

export const SET_CUT_NUMBER_FILTERS = 'SET_CUT_NUMBER_FILTERS'

export const SET_SEASON_LABEL_FILTERS = 'SET_SEASON_LABEL_FILTERS'

export const SET_PLANTATION_DATE_RANGE_TO_FILTERS = 'SET_PLANTATION_DATE_RANGE_TO_FILTERS'

export const SET_SEARCH_INPUT_TEXT_FILTERS = 'SET_SEARCH_INPUT_TEXT_FILTERS'

const resetFilter = type => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  dispatch({ type })
  if (shouldQueryFilters) dispatch(fetchFilteredFields())
}

export const resetCropFilters = () => resetFilter(RESET_CROP_FILTERS)
export const resetCustomerFilters = () => resetFilter(RESET_CUSTOMER_FILTERS)
export const resetPlantationDateFilters = () => resetFilter(RESET_PLANTATION_DATE_FILTERS)
export const resetSearchInputFilters = () => resetFilter(RESET_SEARCH_INPUT_FILTERS)
export const resetCutNumberFilters = () => resetFilter(RESET_CUT_NUMBER_FILTERS)
export const resetSeasonLabelFilters = () => resetFilter(RESET_SEASON_LABEL_FILTERS)
export const resetAllFilters = () => resetFilter(RESET_ALL_FILTERS)

export const addCropsFilters = cropIds => ({ type: ADD_CROPS_TO_FILTERS, cropIds })
export const addCustomersFilters = customerIds => ({ type: ADD_CUSTOMERS_TO_FILTERS, customerIds })

export const setCustomerFilters = customerId => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  dispatch({ type: SET_CUSTOMER_TO_FILTERS, customerId })
  if (shouldQueryFilters && totalFields >= CHUNK_SIZE) dispatch(fetchFilteredFields())
}
export const setCropFilters = cropId => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  dispatch({ type: SET_CROP_TO_FILTERS, cropId })
  if (shouldQueryFilters && totalFields >= CHUNK_SIZE) dispatch(fetchFilteredFields())
}

export const setCutNumberFilters = cutNumber => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  dispatch({ type: SET_CUT_NUMBER_FILTERS, cutNumber })
  if (shouldQueryFilters && totalFields >= CHUNK_SIZE) dispatch(fetchFilteredFields())
}

export const setSeasonLabelFilters = seasonLabel => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  dispatch({ type: SET_SEASON_LABEL_FILTERS, seasonLabel })
  if (shouldQueryFilters && totalFields >= CHUNK_SIZE) dispatch(fetchFilteredFields())
}

export const setPlantationDateFilters = filter => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  dispatch({ type: SET_PLANTATION_DATE_RANGE_TO_FILTERS, filter })
  if (shouldQueryFilters && totalFields >= CHUNK_SIZE) dispatch(fetchFilteredFields())
}

export const setSearchInputTextFilters = searchInputText => (dispatch, getState) => {
  const state = getState()
  const shouldQueryFilters = getShouldQueryFilters(state)
  const totalFields = getFieldsLength(state)
  const oldSearchInput = getFiltersSearchInputString(state)
  dispatch({ type: SET_SEARCH_INPUT_TEXT_FILTERS, searchInputText })
  const stateAfterReset = getState()
  const newStringIsLessLength = searchInputText.length < oldSearchInput.length
  const userWroteNothing = searchInputText.length === 0 && oldSearchInput.length === 0
  const shouldFetchFilteredFields = shouldQueryFilters && (totalFields >= CHUNK_SIZE || isFiltersEmpty(stateAfterReset) || newStringIsLessLength)
  if (shouldFetchFilteredFields && !userWroteNothing) dispatch(fetchFilteredFields())
}
