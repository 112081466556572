import React from 'react'
import PropTypes from 'prop-types'

import MeasureTool from '../../editorComponents/MeasureTool'
import OlVectorSource from 'ol/source/Vector'

//ol
import OlMap from 'ol/Map'

export default class DrawDistance extends MeasureTool {
  render() {
    return (
      <div>
        <MeasureTool type="LineString" config={this.props.config} />
      </div>
    )
  }

  getChildContext() {
    return {
      map: this.context.map,
      source: this.context.source
    }
  }
}

DrawDistance.childContextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}

DrawDistance.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
