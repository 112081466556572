import React from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import { TOP_BAR_OPTIONS } from '../../actions/dashboard'
import includes from 'lodash/includes'
import { USER_ROLES, MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS, TRACK_EVENTS } from '../../constants'
import { List } from '@mui/material'
import StyledListButton from '../UIComponents/StyledListButton'
import { trackEvent } from '../analytics/analytics'

const generateIcon = fontAwesomeIcon => `fa fa-${fontAwesomeIcon}`
const generateCustomIcon = customIcon => `icon-${customIcon}`

const { FIELD_FILTER, FLIGHT_FILTER, FIELD_TEMPORAL_EVOLUTION, HARVEST_PLANNING_TOOL, METEOROLOGICAL_STATISTICS, PRED_TECH } = TOP_BAR_OPTIONS

const { ROLE_PRED_TECH } = USER_ROLES

function DashboardLeftMenuTop({ t, dashboardView, errorNotification, setTopBarVisibility, selectedFieldsLength, userRoles }) {
  const visibilityHandler = topBarItem => () => {
    const previousSelectedTab = dashboardView
    trackEvent(TRACK_EVENTS.DASHBOARD_TAB_SELECTED, { tab: topBarItem })

    if (selectedFieldsLength > MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS && (topBarItem === FIELD_TEMPORAL_EVOLUTION || topBarItem === PRED_TECH)) {
      errorNotification(`${t('max comparable fields')} ${MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS}`)
      setTopBarVisibility(previousSelectedTab)
    } else {
      setTopBarVisibility(topBarItem)
    }
  }

  const listItemsData = [
    {
      icon: generateIcon('chart-bar'),
      onClick: visibilityHandler(FIELD_FILTER),
      value: FIELD_FILTER
    },
    {
      icon: generateIcon('plane'),
      onClick: visibilityHandler(FLIGHT_FILTER),
      value: FLIGHT_FILTER,
      isHidden: true
    },
    {
      icon: generateIcon('chart-line'),
      onClick: visibilityHandler(FIELD_TEMPORAL_EVOLUTION),
      value: FIELD_TEMPORAL_EVOLUTION
    },
    {
      icon: generateIcon('shopping-basket'),
      onClick: visibilityHandler(HARVEST_PLANNING_TOOL),
      value: HARVEST_PLANNING_TOOL,
      isHidden: true
    },
    {
      icon: generateIcon('thermometer-three-quarters'),
      onClick: visibilityHandler(METEOROLOGICAL_STATISTICS),
      value: METEOROLOGICAL_STATISTICS
    },
    {
      icon: generateCustomIcon('chart-line-pred'),
      onClick: visibilityHandler(PRED_TECH),
      value: PRED_TECH,
      isHidden: !includes(userRoles, ROLE_PRED_TECH)
    }
  ]

  return (
    <List value={dashboardView} style={{ padding: 0, width: '100%', textAlign: 'center' }}>
      {listItemsData.map(({ icon, value, onClick, isHidden }) => (
        <StyledListButton
          onClick={onClick}
          value={value}
          key={value}
          style={{ fontSize: 25 }}
          isActiveListItem={dashboardView === value}
          icon={icon}
          label={t(value)}
          hidden={isHidden}
        />
      ))}
    </List>
  )
}

export default withTranslator(DashboardLeftMenuTop)
