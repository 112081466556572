import {
  TOGGLE_FIELD_SEASON_FORM,
  SET_ACTIVE_SEASON_INDEX,
  ADD_SEASON,
  ADD_SEASONS,
  SET_FIELD_SEASON_FORM_SEASON_GEOMETRY_AND_SURFACE
} from '../actions/fieldSeasonForm'
import { CLOSE_CREATION_MODE, OPEN_SEARCH_MODE } from '../actions/viewmode'
import { CLOSE_MODAL, OPEN_MODAL_BY_NAME, FIELD_SEASON_FORM_MODAL } from '../actions/modal'

import union from 'lodash/union'

const initialState = {
  isEditorOpen: false,
  activeSeasonIndex: 0,
  seasons: [],
  editingGeometry: {
    features: undefined,
    surface: undefined
  }
}

export const fieldSeasonForm = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FIELD_SEASON_FORM:
      return {
        ...state,
        isEditorOpen: !state.isEditorOpen
      }
    case SET_ACTIVE_SEASON_INDEX:
      return {
        ...state,
        activeSeasonIndex: action.index
      }
    case ADD_SEASON:
      return {
        ...state,
        seasons: union(state.seasons, [action.season])
      }
    case ADD_SEASONS:
      return {
        ...state,
        seasons: action.seasons
      }

    case SET_FIELD_SEASON_FORM_SEASON_GEOMETRY_AND_SURFACE: {
      const { editingGeometry } = action
      return {
        ...state,
        editingGeometry
      }
    }

    case OPEN_MODAL_BY_NAME: {
      const {
        componentName,
        props: { mode }
      } = action
      if (componentName === FIELD_SEASON_FORM_MODAL) {
        return {
          ...state,
          mode
        }
      }
      return state
    }

    case CLOSE_CREATION_MODE:
    case OPEN_SEARCH_MODE:
    case CLOSE_MODAL:
      return initialState
    default:
      return state
  }
}
