import isNil from 'lodash/isNil'
import get from 'lodash/get'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { LegendCategorical } from './LegendCategorical'
import { LAYERS_BASE_NAMES } from '../../../constants'
import ColoredRangeSlider from '../../UIComponents/ColoredRangeSlider/ColoredRangeSlider'
import StyledSwitch from '../../UIComponents/StyledSwitch/StyledSwitch'

import TurboImageFlipped from '../../../assets/img/turboScheme-flipped.png'
import TurboImage from '../../../assets/img/turboScheme.png'

export function FormattedLegend({
  legend,
  scale,
  selectedLayerBaseName,
  isScaleFromDB,
  isScaleTypeCategoric,
  isSelectionReset,
  onShowHideAllClick,
  onLegendElementClick,
  onLegendElementMouseLeave,
  onLegendElementMouseEnter,
  translate,
  toggleSequentialLegendNulls,
  updateSequentialLegend
}) {
  // Categorical. From the database (DB) and not
  if (isScaleTypeCategoric) {
    let enrichedLegend = []
    if (!isScaleFromDB) {
      enrichedLegend = legend.map((legendItem, index) => {
        const newLegendItem = Object.assign({}, legendItem)
        newLegendItem.color = index === 0 ? '#ccc' : scale(legendItem.value)
        newLegendItem.label = index === 0 ? translate('no data') : legendItem.value

        return newLegendItem
      })
    }

    return (
      <LegendCategorical
        legend={isScaleFromDB ? legend : enrichedLegend}
        translate={translate}
        isSelectionReset={isSelectionReset}
        onShowHideAllClick={onShowHideAllClick}
        onLegendElementClick={onLegendElementClick}
        onLegendElementMouseEnter={onLegendElementMouseEnter}
        onLegendElementMouseLeave={onLegendElementMouseLeave}
      />
    )
  }

  // Sequential
  if (!isScaleTypeCategoric) {
    const min = get(legend, 'min')
    const max = get(legend, 'max')
    const showNulls = get(legend, 'showNulls', true)
    const value = get(legend, 'value', [min, max])
    if (isNil(min) && isNil(max)) {
      return <span>{translate('no data')}</span>
    }

    const roundedMin = Math.round(min * 100) / 100
    const roundedMax = Math.round(max * 100) / 100
    const sliderBackground = selectedLayerBaseName === LAYERS_BASE_NAMES.WATER_STRESS ? TurboImage : TurboImageFlipped

    return (
      <>
        <LegendInnerContainer>
          <ColoredRangeSlider bg={sliderBackground} onChange={updateSequentialLegend} min={roundedMin} max={roundedMax} value={value} scale={scale} />
        </LegendInnerContainer>
        <StyledSwitch
          size={'small'}
          label={translate('show fields with no data')}
          checked={showNulls}
          onChange={event => {
            toggleSequentialLegendNulls(event.target.checked)
          }}
        />
      </>
    )
  }
}

const LegendInnerContainer = styled.ul`
  margin-bottom: 8px;
  list-style-type: none;
  padding-inline-start: 0;
  overflow-y: auto;
`

FormattedLegend.propTypes = {
  legend: PropTypes.oneOfType([
    // categorical
    PropTypes.arrayOf(
      PropTypes.shape({
        // properties of legends from DB and not from DB
        visible: PropTypes.bool.isRequired,
        // properties of legends not from DB only
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.oneOf([null]) // This effectively allows null values
        ]),
        // properties of legends from DB only
        color: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        id: PropTypes.number,
        isScaleFromDB: PropTypes.bool,
        isScaleTypeCategoric: PropTypes.bool,
        range: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)])
      })
    ),
    // sequential
    PropTypes.shape({
      min: PropTypes.number,
      max: PropTypes.number,
      showNulls: PropTypes.bool,
      value: PropTypes.arrayOf(PropTypes.number)
    })
  ]).isRequired,
  scale: PropTypes.func,
  selectedLayerBaseName: PropTypes.string.isRequired,
  isScaleFromDB: PropTypes.bool.isRequired,
  isScaleTypeCategoric: PropTypes.bool.isRequired,
  isSelectionReset: PropTypes.bool.isRequired,
  onShowHideAllClick: PropTypes.func.isRequired,
  onLegendElementClick: PropTypes.func.isRequired,
  onLegendElementMouseEnter: PropTypes.func.isRequired,
  onLegendElementMouseLeave: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  toggleSequentialLegendNulls: PropTypes.func,
  updateSequentialLegend: PropTypes.func
}
