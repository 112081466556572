/* eslint-disable camelcase */
import find from 'lodash/fp/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import moment from 'moment'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'

import { incrementCurrentUserSampleConfigCount } from './config'
import { errorNotification, successNotification } from './notifications'
import { closeNewSampleForm, fetchRequestWrapper, openNewSampleForm } from './ui'
import { deselectAllSamples, fetchSamplesBySeasonIds, requestDeleteSampleSuccess, selectSampleById } from './samples'

import { getMarkerLocationCoordinates } from '../selectors/editor'
import { getSelectedFieldsWithActiveSeasonGeometries } from '../selectors/fields'
import { getSamplesFormSeasonByMarkerLocation, isNewSampleMode } from '../selectors/samplesForm'
import { getCalendarActiveDate } from '../selectors/calendar'
import { getFieldSeasonTimeLineActiveSeasonByFieldId } from '../selectors/fieldsSeasonsTimeline'
import { getSelectedSample } from '../selectors/samples'
import { getSampleUniqueId } from '../selectors/config'

import { getUserId } from '@layers-frontend/commons/store/selectors/user'
import { APPLICATION_JSON_HEADER, sampleFormFields, sampleSources } from '@layers-frontend/commons/constants'
import { getSampleParametersByTypeId } from '@layers-frontend/commons/store/selectors/sampleParametersByType'
import { denySampleLocation, resetFormFields, setFormField } from '@layers-frontend/commons/store/actions/samplesForm'
import { getSamplesForm, getSamplesFormSnapshotDate } from '@layers-frontend/commons/store/selectors/samplesForm'
import { metadataToExtraParamterers } from '@layers-frontend/commons/helpers/extraParameters/extraParameters'
import { EDIT_SAMPLE, REQUEST_PATCH_SAMPLE, REQUEST_POST_SAMPLE, SET_SAMPLE_FORM_FIELD } from '@layers-frontend/commons/store/storeConstants'

const {
  NOTES,
  LOCATION,
  TYPE,
  BASE_LAYER,
  FIELD_ID,
  SAMPLE_CREATION_DATE,
  SEASON_ID,
  PICTURE_TEMP_PATH,
  PICTURE_LOCAL_PATH,
  PICTURE_PATH,
  EXTRA_PARAMETERS
} = sampleFormFields

export const CONFIRM_SAMPLE_FORM_LOCATION = 'CONFIRM_SAMPLE_FORM_LOCATION'
export const SET_SAMPLE_FORM_EXTRA_PARAMETERS = 'SET_SAMPLE_FORM_EXTRA_PARAMETERS'
export const RESET_SAMPLE_FORM_EXTRA_PARAMETERS = 'RESET_SAMPLE_FORM_EXTRA_PARAMETERS'

export const confirmSampleLocation = () => (dispatch, getState) => {
  const state = getState()
  const location = point(getMarkerLocationCoordinates(state))
  const geometries = getSelectedFieldsWithActiveSeasonGeometries(state)
  const isLocationInsideFieldGeometry = find(geometry => booleanPointInPolygon(location, geometry))(geometries)
  return isLocationInsideFieldGeometry
    ? dispatch(setSampleFormLocation())
    : dispatch(errorNotification('The sample location must be inside one of the fields.'))
}

export const setSampleFormLocation = coordinates => (dispatch, getState) => {
  const state = getState()
  const location = getMarkerLocationCoordinates(state)
  const updatedLocation = coordinates || location
  dispatch(setFormField(LOCATION, updatedLocation))
  return dispatch({ type: CONFIRM_SAMPLE_FORM_LOCATION })
}

export const setSampleFormExtraParameters = parameterIds => ({ type: SET_SAMPLE_FORM_EXTRA_PARAMETERS, parameterIds })

export const resetSampleFormExtraParameters = () => ({ type: RESET_SAMPLE_FORM_EXTRA_PARAMETERS })

export const setSampleFormType = (_, value) => dispatch => {
  dispatch(resetSampleFormExtraParameters())
  return dispatch({ type: SET_SAMPLE_FORM_FIELD, field: TYPE, value })
}

export const validateSampleFormLocation = () => (dispatch, getState) => {
  const state = getState()
  const samplesForm = getSamplesForm(state)
  const geometry = getSamplesFormSeasonByMarkerLocation(state)

  if (samplesForm && samplesForm.id) {
    const allSamples = state.samples
    const seasonId = samplesForm.originalSample.seasonId
    const selectedSeasonSamples = allSamples[seasonId]
    const sample = selectedSeasonSamples.find(sample => sample.id === samplesForm.id)
    const coordinates = [sample.lon, sample.lat]

    return dispatch(setSampleFormLocation(coordinates))
  }

  if (isEmpty(geometry)) {
    return dispatch(errorNotification('You need to place the marker on one of the selected fields.'))
  }

  const { id } = geometry
  const season = getFieldSeasonTimeLineActiveSeasonByFieldId(state, id)

  dispatch(setFormField(FIELD_ID, id))
  dispatch(setFormField(SEASON_ID, get(season, 'id')))
  return dispatch(setSampleFormLocation())
}

export const getSampleFormRequestBody = sample => {
  const type = get(sample, TYPE)
  const notes = get(sample, NOTES, undefined)
  const location = get(sample, LOCATION)
  const field = get(sample, FIELD_ID)
  const seasonId = get(sample, SEASON_ID)
  const [lon, lat] = location
  const baseLayer = get(sample, BASE_LAYER)
  const pictureTempPath = get(sample, PICTURE_TEMP_PATH, undefined)
  const sampleCreationDate = get(sample, SAMPLE_CREATION_DATE)
  const metadata = get(sample, EXTRA_PARAMETERS)
  const id = get(sample, 'id')

  return {
    id,
    lat,
    lon,
    type,
    notes,
    field,
    seasonId,
    metadata,
    sampleCreationDate,
    source: sampleSources.WEB,
    deliverableType: baseLayer,
    picture: pictureTempPath
  }
}

export const getSampleFormRequestBodyForPatch = sample => {
  const originalSample = get(sample, 'originalSample')
  const field = get(originalSample, 'fieldId')
  const seasonId = get(originalSample, 'seasonId')
  const id = get(sample, 'id')

  return getSampleFormRequestBody({
    ...sample,
    field,
    seasonId,
    id
  })
}

// POST FUNCTIONS

export const postPatchSample = () => (dispatch, getState) => {
  const state = getState()
  const isNewSample = isNewSampleMode(state)
  const sample = getSamplesForm(state)
  const userId = getUserId(state)
  const uniqueId = getSampleUniqueId(state)
  const sampleBody = isNewSample ? getSampleFormRequestBody(sample) : getSampleFormRequestBodyForPatch(sample)
  const snapshotDate = isNewSample ? moment(getCalendarActiveDate(state)).format('YYYY-MM-DD') : getSamplesFormSnapshotDate(state)

  // We need to format sampleCreationDate only if it's a moment object.
  // If sampleCreationDate is a string (when we edit a sample) or null (if we didn't pick a date),
  // leave sampleCreationDate as is.
  const sampleCreationDate = moment.isMoment(sampleBody.sampleCreationDate) ? sampleBody.sampleCreationDate.format('YYYY-MM-DD') : sampleBody.sampleCreationDate

  const body = {
    ...sampleBody,
    dwhInsertUser: userId,
    dwhUpdateUser: userId,
    snapshotDate,
    uniqueId,
    sampleCreationDate
  }

  return isNewSample ? dispatch(postSample(body)) : dispatch(patchSample(body))
}

export const postSample = sample => dispatch => {
  dispatch(incrementCurrentUserSampleConfigCount())
  return dispatch(
    fetchRequestWrapper({
      route: 'post_plant_historic',
      requestType: REQUEST_POST_SAMPLE,
      item: sample.uniqueId,
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER,
        body: JSON.stringify(sample)
      },
      onSuccess: requestPostPatchSampleSuccess
    })
  )
}

export const patchSample = sample =>
  fetchRequestWrapper({
    requestType: REQUEST_PATCH_SAMPLE,
    route: 'update_plant_historic',
    item: sample.uniqueId,
    urlParams: { plant: sample.id },
    fetchOptions: {
      method: 'PATCH',
      body: JSON.stringify(sample),
      headers: APPLICATION_JSON_HEADER
    },
    onSuccess: newSample => requestPostPatchSampleSuccess(newSample, sample)
  })

export const requestPostPatchSampleSuccess = ({ id, season_id }, sampleToDelete) => async dispatch => {
  if (sampleToDelete) {
    dispatch(requestDeleteSampleSuccess({ ...sampleToDelete, season_id: sampleToDelete.seasonId }))
    dispatch(successNotification('Sample was successfully modified.'))
  } else {
    dispatch(successNotification('New sample created.'))
  }
  await dispatch(fetchSamplesBySeasonIds(season_id))
  await dispatch(selectSampleById(id))
}

export const resetAndCloseSampleForm = () => dispatch => {
  dispatch(closeNewSampleForm())
  dispatch(denySampleLocation())
  return dispatch(resetFormFields())
}

export const resetSampleFormPicturePath = () => dispatch => {
  dispatch(setFormField(PICTURE_LOCAL_PATH, null))
  dispatch(setFormField(PICTURE_TEMP_PATH, null))
  dispatch(setFormField(PICTURE_PATH, null))
}

export const editSample = () => (dispatch, getState) => {
  const state = getState()
  const selectedSample = getSelectedSample(state)
  const typeId = get(selectedSample, 'type.id')
  const parameters = getSampleParametersByTypeId(typeId)(state)
  const extraParameters = metadataToExtraParamterers(get(selectedSample, 'metadata'), parameters)
  const normalizedExtraParameters = reduce(extraParameters, (accumulator, param) => ({ ...accumulator, [param.parameterId]: param.value }), {})
  const modifiedSample = { ...selectedSample, type: typeId, metadata: normalizedExtraParameters }

  dispatch(deselectAllSamples())
  dispatch(openNewSampleForm())
  dispatch({ type: EDIT_SAMPLE, sample: modifiedSample })
}
