import React from 'react'

const noNodeError = new Error('DownloadCSVComponent should have a node prop to be displayed correctly')

function FallbackWrapper({ children, onClick }) {
  return <div onClick={onClick}>{children}</div>
}

export default function DownloadCSVComponent({ node, styledWrapper, data, downloadCSVData, fileName, hidden }) {
  if (!node) {
    throw noNodeError
  }

  if (hidden) return null

  const Wrapper = styledWrapper || FallbackWrapper

  return <Wrapper onClick={() => downloadCSVData({ data, fileName })}>{node}</Wrapper>
}
