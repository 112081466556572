import { connect } from 'react-redux'
import SearchBarList from '../../components/SearchComponents/SearchBarList'
import { selectFields, addFieldsToGroup, removeFieldToGroup, setPreviewFieldId, getNextFieldsChunk } from '../../actions/fields'
import { errorNotification } from '../../actions/notifications'
import { getSelectedFieldsLength } from '../../selectors/fields'
import { openFieldSeasonFormModal } from '../../actions/modal'
import { getFieldStats } from '../../selectors/fieldsStatistics'
import { getUserRoles } from '@layers-frontend/commons/store/selectors/user'
import { isFiltersEmpty } from '../../selectors/filters'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { getShouldQueryFilters } from '../../selectors/ui'
import { compose } from 'redux'

const mapStateToProps = (state, { currentLocation, fields }) => ({
  fields,
  userRoles: getUserRoles(state),
  selectedFieldsLength: getSelectedFieldsLength(state),
  currentLocation,
  fieldStats: getFieldStats(state),
  canGetNextChunk: isFiltersEmpty(state) && getShouldQueryFilters(state)
})

const mapDispatchToProps = {
  selectFields,
  addFieldsToGroup,
  removeFieldToGroup,
  errorNotification,
  openFieldSeasonFormModal,
  setPreviewFieldId,
  getNextFieldsChunk
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(SearchBarList)
