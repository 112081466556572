import React from 'react'
import { plantsMetadataKeys } from '../../../constants'
import { DASHBOARD_FILTERS_TYPES } from '../../../actions/dashboard'
import StyledSelect from '../../UIComponents/StyledSelect'

const { HARVEST_PLANNING_TOOL_FILTER_TYPE_SELECT, HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT } = DASHBOARD_FILTERS_TYPES
const { DRY_MATTER, ATR_POL, ATR, POL } = plantsMetadataKeys

const SHOULD_BE_DISABLED_WHILE_PROCESSING_CORE_FINISHIES = true

export default function TemporalEvolutionCropTypeSelect({ t, toggleDashFilter, selectedType, selectedMetadataKey }) {
  if (SHOULD_BE_DISABLED_WHILE_PROCESSING_CORE_FINISHIES) {
    return null
  }

  const metadataKeysOptionsFromSelectedType = {
    [DRY_MATTER]: [{ name: t('dry matter'), id: DRY_MATTER }],
    [ATR_POL]: [
      { name: t(ATR), id: ATR },
      { name: t(POL), id: POL }
    ]
  }

  const onTypeChange = value => {
    toggleDashFilter(HARVEST_PLANNING_TOOL_FILTER_TYPE_SELECT, value)
    toggleDashFilter(HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT, '')
  }

  const onMetadataKeyChange = value => toggleDashFilter(HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT, value)

  return (
    <>
      <StyledSelect
        value={selectedType}
        options={[
          { name: t('CORN'), id: DRY_MATTER },
          { name: t('CAÑA DE AZÚCAR'), id: ATR_POL }
        ]}
        onChange={onTypeChange}
        placeholder={t('Type')}
      />
      {metadataKeysOptionsFromSelectedType[selectedType] ? (
        <StyledSelect
          value={selectedMetadataKey}
          options={metadataKeysOptionsFromSelectedType[selectedType]}
          onChange={onMetadataKeyChange}
          placeholder={t('sample')}
          disabled={!metadataKeysOptionsFromSelectedType[selectedType]}
        />
      ) : null}
    </>
  )
}
