import React from 'react'
import AccountsLayout from '../../components/AccountComponents/AccountsLayout'
import useAccountsData from './useAccountsData'

const Accounts = ({ t }) => {
  const {
    accountsData,
    accountsError,
    isAccountsLoading,
    userPoliciesError,
    userPoliciesLoading,
    accountPoliciesError,
    accountPoliciesLoading,
    accountConfigLoading,
    accountConfigError
  } = useAccountsData()

  return (
    <AccountsLayout
      t={t}
      accounts={accountsData}
      isLoading={isAccountsLoading || userPoliciesLoading || accountPoliciesLoading || accountConfigLoading}
      error={accountsError || userPoliciesError || accountPoliciesError || accountConfigError}
    />
  )
}
export default Accounts
