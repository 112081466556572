import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import DatePickerCalendar from '../UIComponents/Calendars/DateRangeCalendar/DatePickerCalendar'
import { LargeButton } from '../UIComponents/buttons'
import Validator from '../../form.validator'

const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const DatepickerLabel = styled.label`
  margin-top: 10px;
  cursor: pointer;

  span {
    padding: 10px 0;
    font-size: 16px;
    font-weight: 400;
  }
`

// calendar on the layers search filter

const PlantationDateFilterModal = ({ t, plantationDate, setPlantationDateFilters, closeModal }) => {
  const [dateFilters, setDateFilters] = useState({ from: null, to: moment() })

  const handleChange = useCallback(
    fromOrTo => date => {
      setDateFilters(prevFilters => ({ ...prevFilters, [fromOrTo]: moment(date) }))
    },
    []
  )

  useEffect(() => {
    const { from, to } = plantationDate
    if (from || to) setDateFilters({ from, to })
  }, [plantationDate])

  const isFilterButtonEnabled = Validator.isRequired(dateFilters.from) && Validator.isRequired(dateFilters.to)

  return (
    <FormWrapper>
      <DatepickerLabel>
        <DatePickerCalendar floatingLabelText={t('from')} onChange={handleChange('from')} value={dateFilters.from} />
      </DatepickerLabel>
      <DatepickerLabel>
        <DatePickerCalendar floatingLabelText={t('to')} onChange={handleChange('to')} value={dateFilters.to} defaultValue={dateFilters.to} />
      </DatepickerLabel>
      <LargeButton
        label={t('apply date filter')}
        disabled={!isFilterButtonEnabled}
        className="applyFilterButton"
        width={'50%'}
        onClick={() => {
          setPlantationDateFilters(dateFilters)
          closeModal()
        }}
      />
    </FormWrapper>
  )
}

export default PlantationDateFilterModal
