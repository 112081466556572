import React from 'react'
import layersLogo from '../../assets/img/logo-layers-full.png'
import styled, { keyframes } from 'styled-components'
import { UIStyles } from '../../theme'

const BallsAnimateBefore = keyframes`
    0% {
    transform: translate(-5px, -5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-5px, -5px);
  }
`

const BallsAnimateAfter = keyframes`
0% {
    transform: translate(5px, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(5px, 5px);
  }
`
const BallsAnimate = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`

const Image = styled.img`
  width: ${({ width }) => width || '15rem'};
`

export const MySpinningBalls = styled.div`
  scale: ${({ scale }) => scale || '1'};
  width: 48px;
  height: 48px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  margin-top: ${({ marginTop }) => marginTop || '30px'};
  animation: ${BallsAnimate} 1s linear infinite;
  margin-bottom: 0;
  &:before {
    border-radius: 50%;
    content: ' ';
    width: 24px;
    height: 24px;
    display: inline-block;
    box-sizing: border-box;
    background-color: ${UIStyles.whiteColor};
    position: absolute;
    top: 0;
    left: 0;
    animation: ${BallsAnimateBefore} 1s ease-in-out infinite;
  }
  &:after {
    border-radius: 50%;
    content: ' ';
    width: 24px;
    height: 24px;
    display: inline-block;
    box-sizing: border-box;
    background-color: ${UIStyles.vomitColor};
    position: absolute;
    bottom: 0;
    right: 0;
    animation: ${BallsAnimateAfter} 1s ease-in-out infinite;
  }
`

export const OverlayContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1300;
  background: rgba(8, 44, 63, 0.9);
  transition: all 1s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > div:not(${MySpinningBalls}),
  & > img {
    margin-bottom: 0px;
  }
`

export default function LoadingPageView({ areRequestsPending }) {
  if (!areRequestsPending) return null

  return (
    <OverlayContainer>
      <Image src={layersLogo} width="20rem" />
      <MySpinningBalls />
    </OverlayContainer>
  )
}
