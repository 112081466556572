import { fetchGraphqlWrapper, fetchRequestWrapper } from './ui'
import { loader } from 'graphql.macro'
import { getSamplesSeasonsIds, getSelectedSample } from '../selectors/samples'
import { getSelectedFieldsSeasonsTimelineIds } from '../selectors/fieldsSeasonsTimeline'

import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { REQUEST_DELETE_SAMPLE } from '@layers-frontend/commons/store/storeConstants'
import { APPLICATION_JSON_HEADER, sampleSources } from '@layers-frontend/commons/constants'
import get from 'lodash/get'
import { successNotification } from './notifications'
import { getSamplesForm } from '@layers-frontend/commons/store/selectors/samplesForm'

export const REQUEST_SAMPLES_BY_SEASON_IDS = 'REQUEST_SAMPLES_BY_SEASON_IDS'
export const RECEIVE_SAMPLES_BY_SEASON_IDS = 'RECEIVE_SAMPLES_BY_SEASON_IDS'
export const RECEIVE_SAMPLES_PARAMETERS = 'RECEIVE_SAMPLES_PARAMETERS'
export const RECEIVE_SAMPLES_MODE = 'RECEIVE_SAMPLES_MODE'

export const RECEIVE_SAMPLES_PARAMETERS_BY_TYPE = 'RECEIVE_SAMPLES_PARAMETERS_BY_TYPE'

// sampleParametersByType

export const REQUEST_SAMPLE_PARAMETERS_BY_TYPE = 'REQUEST_SAMPLE_PARAMETERS_BY_TYPE'
export const RECEIVE__SAMPLE_PARAMETERS_BY_TYPE = 'RECEIVE__SAMPLE_PARAMETERS_BY_TYPE'

export const SELECT_SAMPLE_BY_ID = 'SELECT_SAMPLE_BY_ID'
export const DESELECT_ALL_SAMPLES = 'DESELECT_ALL_SAMPLES'
export const DELETE_SAMPLE = 'DELETE_SAMPLE'

export const UPDATE_SAMPLE_POSITION = 'UPDATE_SAMPLE_POSITION'

const getSamplesBySeasonIds = loader('../graphql/samples/getSamplesBySeasonIds.gql').loc.source.body

export const fetchSamplesBySeasonIds = seasonIds =>
  fetchGraphqlWrapper({
    requestType: REQUEST_SAMPLES_BY_SEASON_IDS,
    variables: { fieldSeasonIds: seasonIds },
    query: getSamplesBySeasonIds,
    onSuccess: ({ samples }) => ({ type: RECEIVE_SAMPLES_BY_SEASON_IDS, samples }),
    overlay: false
  })

export const fetchActiveFieldsSamples = () => (dispatch, getState) => {
  const state = getState()
  const existingIds = getSamplesSeasonsIds(state)
  const fieldsSeasonIds = getSelectedFieldsSeasonsTimelineIds(state)
  const idsToFetch = difference(fieldsSeasonIds, existingIds)
  return !isEmpty(idsToFetch) && dispatch(fetchSamplesBySeasonIds(idsToFetch))
}

export const fetchActiveSeasonsSamples = fieldSeasonIds => (dispatch, getState) => {
  const state = getState()
  const existingIds = getSamplesSeasonsIds(state)
  const idsToFetch = difference(fieldSeasonIds, existingIds)
  return !isEmpty(idsToFetch) && dispatch(fetchSamplesBySeasonIds(idsToFetch))
}

export const selectSampleById = sampleId => ({ type: SELECT_SAMPLE_BY_ID, sampleId })

export const setSamplesMode = mode => ({ type: RECEIVE_SAMPLES_MODE, mode })

export const deselectAllSamples = () => ({ type: DESELECT_ALL_SAMPLES })

export const getSampleRequestBody = sample => {
  const {
    lat,
    lon,
    latReal,
    lonReal,
    type,
    snapshotDate,
    baseLayer,
    notes,
    pictureTempPath,
    uniqueId,
    fieldId,
    dwhInsertUser,
    dwhUpdateUser,
    sampleCreationDate,
    metadata
  } = sample

  return {
    lat,
    lon,
    latReal,
    lonReal,
    notes,
    dwhInsertUser,
    dwhUpdateUser,
    field: fieldId,
    source: sampleSources.WEB,
    type: get(type, 'id'),
    deliverableType: baseLayer,
    sampleCreationDate,
    snapshotDate,
    uniqueId,
    picture: pictureTempPath,
    metadata
  }
}

export const updatePosition = ({ lat, lon }) => (dispatch, getState) => {
  const state = getState()
  const sample = getSamplesForm(state)
  const sampleId = sample.id
  const seasonId = get(sample, 'originalSample.seasonId')
  dispatch({
    type: UPDATE_SAMPLE_POSITION,
    sampleId,
    lat,
    lon,
    seasonId
  })
}

export const deleteSelectedSample = () => (dispatch, getState) => {
  const state = getState()
  const sample = getSelectedSample(state)
  const sampleToDelete = { ...getSampleRequestBody(sample), toDelete: true }

  dispatch(deselectAllSamples())
  return dispatch(
    fetchRequestWrapper({
      requestType: REQUEST_DELETE_SAMPLE,
      route: 'update_plant_historic',
      item: sample.uniqueId,
      urlParams: { plant: sample.id },
      fetchOptions: {
        method: 'PATCH',
        body: JSON.stringify(sampleToDelete),
        headers: APPLICATION_JSON_HEADER
      },
      onSuccess: requestDeleteSampleSuccess
    })
  )
}

export const requestDeleteSampleSuccess = sample => dispatch => {
  dispatch(successNotification('Sample was deleted.'))
  return dispatch({ type: DELETE_SAMPLE, sample })
}
