import React from 'react'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { Button } from '../../UIComponents/StyledButtons'

const ConfirmationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const ConfirmationOverlay = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: ${themeColors.customOverlayBackground};
  z-index: 9;
`

const ConfirmationModal = styled.div`
  background-color: ${themeColors.darkBlue};
  z-index: 10;
  border-radius: 0.3rem;
  cursor: default;
  padding: 2rem 2.5rem;
`

const Text = styled.p`
  color: ${themeColors.whiteColor};
  margin-bottom: 20px;
`

const TitleText = styled(Text)`
  font-weight: 600;
  font-size: 1.8rem;
  text-align: center;
`

const FieldNameText = styled.span`
  color: ${themeColors.vomitColor};
  margin-left: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const FieldSeasonFormDeletingConfirmationModal = ({ t, onDelete, closeDeletingModal, selectedFieldName }) => {
  return (
    <ConfirmationWrapper data-testid="deletion-confirmation-modal">
      <ConfirmationOverlay onClick={closeDeletingModal} />
      <ConfirmationModal>
        <TitleText>
          {t('Delete field').toUpperCase()}
          <FieldNameText>{selectedFieldName.toUpperCase()}</FieldNameText>
        </TitleText>
        <Text>{t('This action will delete the field and all its related data')}.</Text>
        <ButtonWrapper>
          <Button onClick={closeDeletingModal} label={t('cancel')} background={themeColors.blueLightHover} color={themeColors.whiteColor} />
          <Button
            onClick={onDelete}
            label={t('Delete')}
            background={themeColors.niceRedColor}
            color={themeColors.whiteColor}
            testId="deletion-confirmation-button"
          />
        </ButtonWrapper>
      </ConfirmationModal>
    </ConfirmationWrapper>
  )
}

export default FieldSeasonFormDeletingConfirmationModal
