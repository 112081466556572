import { connect } from 'react-redux'
import SamplesLayer from '../../components/LayersComponents/SamplesLayer'
import { getSelectedFields } from '../../selectors/fields'
import { getConfigProjection } from '../../selectors/config'
import { getActiveSamples } from '../../selectors/samples'
import { shouldShowSamplesInMap } from '../../selectors/ui'
import { getSamplesFormLocation } from '@layers-frontend/commons/store/selectors/samplesForm'
import { getEditorPresentData } from '../../selectors/editor'
import { MARKER_TOOL } from '../../actions/editor'
const mapState = state => {
  const selectedSample = state.samplesForm.id ? state.samplesForm : null

  return {
    selectedFields: getSelectedFields(state),
    samples: getActiveSamples(state),
    projection: getConfigProjection(state),
    showSamplesInMap: shouldShowSamplesInMap(state),
    samplesFormLocation: getSamplesFormLocation(state),
    samplesForm: state.samplesForm,
    selectedSample,
    isAddingMarker: getEditorPresentData(state, 'activeTool') === MARKER_TOOL
  }
}

export default connect(mapState)(SamplesLayer)
