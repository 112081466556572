import React, { useEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import isFinite from 'lodash/isFinite'
import get from 'lodash/get'
import some from 'lodash/some'
import DatePickerCalendar from '../../UIComponents/Calendars/DateRangeCalendar/DatePickerCalendar'
import { StyledButton } from './TemporalEvolutionMetadataDateRangeSelect'
import MeteorologicalStatistisFieldSelectContainer from '../../../containers/DashboardContainers/DashboadFiltersContainers/MeteorologicalStatistisFieldSelectContainer'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import StyledSlider from '../../UIComponents/StyledSlider'
import find from 'lodash/find'
import { asFarenheit } from '@layers-frontend/commons/conversions'

const StyledButtonWrapper = styled.div`
  padding-top: 30px;
`
const StyledButtonIcon = styled.i`
  padding-left: 5px;
`

const labelStyle = {
  fontWeight: 600,
  color: themeColors.grayColor
}

const inputDatePickerStyle = {
  color: themeColors.vomitColor,
  width: '200px'
}

const wrapperStyle = {
  justifyContent: 'center'
}

const temperatureLabelStyle = {
  color: themeColors.grayColor,
  fontSize: '12px',
  paddingBottom: '10px',
  fontWeight: 600
}

const sliderWrapperStyle = {
  marginBottom: '-35px'
}

export default function MeteorologicalStatisticsFilters({
  t,
  dateRangeFrom,
  dateRangeTo,
  baseTemperature,
  setMeteorologicalStatisticsDateRangeFrom,
  setMeteorologicalStatisticsDateRangeTo,
  setMeteorologicalStatisticsBaseTemp,
  meteorologicalSelectedField,
  fetchMeteorologicalApiData,
  fieldsOptions,
  isMetric,
  setToggled
}) {
  const getSelectedFieldSeason = (fields, selectedField) => {
    const selectedFieldId = selectedField.id
    return find(fields, field => field.id === selectedFieldId).season
  }

  const isDateRangeToAfterDateRangeFrom = moment(dateRangeTo).isAfter(dateRangeFrom)

  useEffect(() => {
    const selectedFieldId = meteorologicalSelectedField?.id
    if (selectedFieldId) {
      const selectedSeason = getSelectedFieldSeason(fieldsOptions, meteorologicalSelectedField)
      const seasonStartDate = get(selectedSeason, 'startDate')
      setMeteorologicalStatisticsDateRangeFrom(seasonStartDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meteorologicalSelectedField, setMeteorologicalStatisticsDateRangeFrom])

  const handleChangeFrom = date => setMeteorologicalStatisticsDateRangeFrom(date)

  const handleChangeTo = date => setMeteorologicalStatisticsDateRangeTo(date)

  const isBaseTempNumber = isFinite(baseTemperature)
  const isButtonDisabled = !(dateRangeFrom && dateRangeTo && isBaseTempNumber)

  const applyButtonHandler = () => {
    if (isButtonDisabled) return
    setToggled(true)

    fetchMeteorologicalApiData({
      startDate: dateRangeFrom,
      endDate: dateRangeTo,
      fieldId: meteorologicalSelectedField.id
    })
  }

  if (!dateRangeTo && isDateRangeToAfterDateRangeFrom) {
    handleChangeTo(null, new Date())
  }

  return (
    <>
      <MeteorologicalStatistisFieldSelectContainer fieldsOptions={fieldsOptions} setToggled={setToggled} />
      {some(fieldsOptions, field => field.id === get(meteorologicalSelectedField, 'id')) && (
        <>
          <DatePickerCalendar
            floatingLabelText={t('from')}
            onChange={handleChangeFrom}
            labelStyle={labelStyle}
            value={dateRangeFrom}
            inputStyle={inputDatePickerStyle}
            fullWidth={false}
            wrapperStyle={wrapperStyle}
          />
          <DatePickerCalendar
            floatingLabelText={t('to')}
            onChange={handleChangeTo}
            value={dateRangeTo}
            labelStyle={labelStyle}
            inputStyle={inputDatePickerStyle}
            fullWidth={false}
            wrapperStyle={wrapperStyle}
          />
          <StyledSlider
            label={t('Base temperature')}
            onChange={setMeteorologicalStatisticsBaseTemp}
            defaultValue={isMetric ? baseTemperature : asFarenheit(baseTemperature).toFixed(2)}
            step={0.5}
            minValue={isMetric ? 0 : asFarenheit(0).toFixed(2)}
            maxValue={isMetric ? 20 : asFarenheit(20).toFixed(2)}
            unit={isMetric ? '°C' : 'ºF'}
            fullWidth={false}
            labelStyle={temperatureLabelStyle}
            wrapperStyle={sliderWrapperStyle}
          />
          <StyledButtonWrapper>
            <StyledButton onClick={applyButtonHandler} disabled={isButtonDisabled}>
              <span>{t('Apply')}</span>
              <StyledButtonIcon className="fa fa-chevron-circle-right" />
            </StyledButton>
          </StyledButtonWrapper>
        </>
      )}
    </>
  )
}
