import { addNotification } from '../actions/notifications'
import GeometryUtils from '../geometryUtils'
import map from 'lodash/map'
import { getConfigProjection } from '../selectors/config'
import { ActionCreators } from 'redux-undo'

export const CHOOSE_TOOL = 'CHOOSE_TOOL'

/*** editor drawing tools **/
export const ADD_POINT = 'ADD_POINT'
export const ADD_MARKER = 'ADD_MARKER'
export const UPDATE_MARKER = 'UPDATE_MARKER'
export const ADD_NEW_POINT = 'ADD_NEW_POINT'
export const POLYGON_DRAW_START = 'POLYGON_DRAW_START'
export const FIELD_MODIFY = 'FIELD_MODIFY'
export const POLYGON_DRAW_END = 'POLYGON_DRAW_END'
export const POLYGON_MOVE = 'POLYGON_MOVE'
export const POLYGON_ADD = 'POLYGON_ADD'
export const ADD_HOLE = 'ADD_HOLE'

/*** tools **/
export const CLICK_POLYGON_MOVE = 'CLICK_POLYGON_MOVE'
export const CLICK_POLYGON_DRAW = 'CLICK_POLYGON_DRAW'
export const CLICK_CIRCLE_DRAW = 'CLICK_CIRCLE_DRAW'
export const CLICK_UNDO = 'CLICK_UNDO'
export const CLICK_REDO = 'CLICK_REDO'
export const CLICK_DELETE = 'CLICK_DELETE'
export const CLICK_POLYGON_SLICE = 'CLICK_POLYGON_SLICE'
export const CLICK_DISTANCE_TOOL = 'CLICK_DISTANCE_TOOL'
export const CLICK_AREA_TOOL = 'CLICK_AREA_TOOL'

/** action tools */
export const POLYGON_DRAW = 'POLYGON_DRAW'
export const CIRCLE_DRAW = 'CIRCLE_DRAW'
export const UNDO = 'UNDO'
export const REDO = 'REDO'
export const DELETE = 'DELETE'

export const POLYGON_SLICE = 'POLYGON_SLICE'

export const SLICE = 'SLICE'
export const SLICE_EXCEPTION = 'SLICE_EXCEPTION'
export const NOTIFICATION_EXPIRE = 'NOTIFICATION_EXPIRE'

export const DISTANCE_TOOL = 'DISTANCE_TOOL'
export const AREA_TOOL = 'AREA_TOOL'
export const MARKER_TOOL = 'MARKER_TOOL'

/** measures tools */
export const TOGGLE_MEASURE_TOOLS = 'TOGGLE_MEASURE_TOOLS'

export const INIT_EDIT_LAYER = 'INIT_EDIT_LAYER'

export const SET_EDITOR_FEATURES_AND_SURFACE = 'SET_EDITOR_FEATURES_AND_SURFACE'
export const EDIT_FIELD = 'EDIT_FIELD'
export const ADD_FIELD = 'ADD_FIELD'
export const SAVE_FIELD = 'SAVE_FIELD'
export const DELETED_FIELD = 'DELETED_FIELD'
export const UPDATE_GEOMETRY = 'UPDATE_GEOMETRY'
export const UPDATE_FIELD_AREA = 'UPDATE_FIELD_AREA'

export const updateGeometryForm = geometry => ({
  type: UPDATE_GEOMETRY,
  payload: geometry
})

export const updateFieldArea = surface => ({
  type: UPDATE_FIELD_AREA,
  surface
})

export const updateGeometry = () => {
  return function (dispatch, getState) {
    let fieldArea = 0
    let coords = []
    /* eslint-disable prefer-const */
    let fieldGeometries = getState().editor.present.features
    let projection = getState().config.projection

    if (fieldGeometries.length > 0) {
      let geometries = fieldGeometries.map(field => {
        return field.geometry
      })
      let multi = GeometryUtils.geometriesToMultipolygon(geometries, projection.to)
      /* eslint-enable prefer-const */
      fieldArea = GeometryUtils.areaFromMultipolygon(multi, projection.from, projection.to)
      multi.transform(getState().config.projection.to, projection.from)
      coords = JSON.stringify(multi.getCoordinates())
    }
    dispatch(updateFieldArea(fieldArea))
    return dispatch(updateGeometryForm(coords))
  }
}

export const setEditorFeaturesAndSurface = ({ features, surface }) => dispatch => {
  dispatch(ActionCreators.clearHistory())
  dispatch({
    type: SET_EDITOR_FEATURES_AND_SURFACE,
    features,
    surface
  })
}

export const setEditorGeometry = geometry => (dispatch, getState) => {
  if (!geometry) {
    return dispatch(setEditorFeaturesAndSurface({ features: [], surface: 0 }))
  }

  const state = getState()
  const projection = getConfigProjection(state)

  const features = GeometryUtils.multipolygonToGeometries(geometry, projection.to)
  const geometries = map(features, 'geometry')
  const multi = GeometryUtils.geometriesToMultipolygon(geometries, projection.to)
  const surface = GeometryUtils.areaFromMultipolygon(multi, projection.from, projection.to)

  return dispatch(setEditorFeaturesAndSurface({ features, surface }))
}

export const wrapUpdGeometry = action => dispatch => {
  dispatch(action)
  dispatch(updateGeometry())
}

export const toggleMeasureTool = () => {
  return {
    type: 'TOGGLE_MEASURE_TOOLS'
  }
}
export const selectTool = payload => {
  return {
    type: 'CLICK_' + payload,
    // eslint-disable-next-line object-shorthand
    payload: payload
  }
}

export const polygonDrawStart = (uid, geometry) => {
  return wrapUpdGeometry({
    type: POLYGON_DRAW_START,
    // eslint-disable-next-line object-shorthand
    payload: { uid: uid, geometry: geometry }
  })
}

export const addPoint = (uid, geometry, area = 0) => {
  return wrapUpdGeometry({
    type: ADD_POINT,
    // eslint-disable-next-line object-shorthand
    payload: { geometry: geometry, uid: uid, drawing: true, area: area }
  })
}

export const addMarker = (uid, geometry, area = 0) => {
  return {
    type: ADD_MARKER,
    // eslint-disable-next-line object-shorthand
    payload: { geometry: geometry, uid: uid, drawing: true, area: area }
  }
}

export const updateMarker = (uid, geometry, area = 0) => (dispatch, getState) => {
  return dispatch({
    type: UPDATE_MARKER,
    payload: { geometry, uid, drawing: true, area }
  })
}

export const addNewPoint = (uid, geometry, area = 0) => {
  return wrapUpdGeometry({
    type: ADD_NEW_POINT,
    payload: {
      /* eslint-disable object-shorthand */
      geometry: geometry,
      uid: uid,
      drawing: true,
      area: area
      /* eslint-enable object-shorthand */
    }
  })
}
export const polygonDrawEnd = (uid, geometry, area = 0) => dispach => {
  dispach(polygonAdd(uid, geometry, area))
}

export const polygonAdd = (uid, geometry, area = 0) => {
  return wrapUpdGeometry({
    type: POLYGON_ADD,
    payload: {
      /* eslint-disable object-shorthand */
      geometry: geometry,
      uid: uid,
      drawing: false,
      delete: true,
      area: area
      /* eslint-enable object-shorthand */
    }
  })
}

export const polygonMove = (uid, geometry, area = 0) => {
  return wrapUpdGeometry({
    type: POLYGON_MOVE,
    payload: {
      /* eslint-disable object-shorthand */
      geometry: geometry,
      uid: uid,
      area: area
      /* eslint-enable object-shorthand */
    }
  })
}

export const addHole = (uid, geometry, area = 0, holeUid) => {
  return wrapUpdGeometry({
    type: ADD_HOLE,
    payload: {
      /* eslint-disable object-shorthand */
      geometry: geometry,
      uid: uid,
      drawing: false,
      area: area,
      holeUid: holeUid
      /* eslint-enable object-shorthand */
    }
  })
}

export const polygonSlice = (uid, geometriesCollection) => dispach => {
  dispach(slice())
  dispach(deletePolygon(uid))
  for (let index = 0; index < geometriesCollection.length; index++) {
    dispach(polygonAdd(geometriesCollection[index].uid, geometriesCollection[index].geometry))
  }
}

export const slice = () => {
  return {
    type: SLICE
  }
}

export const sliceException = exception => dispach => {
  dispach(addNotification(exception))
}

export const fieldModify = geometries => {
  return wrapUpdGeometry({
    type: FIELD_MODIFY,
    // eslint-disable-next-line object-shorthand
    payload: { geometries: geometries }
  })
}

export const deletePolygon = uid => {
  return wrapUpdGeometry({
    type: DELETE,
    // eslint-disable-next-line object-shorthand
    payload: { uid: uid }
  })
}
