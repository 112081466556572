import { LANGS } from './constants'
import isEmpty from 'lodash/isEmpty'

export default class pathUtils {
  /** we look if the url include agrodev ***/
  static isDevMode() {
    return window.location.host.includes('localhost')
  }

  static getServerUrl() {
    // eslint-disable-next-line prefer-const
    let lang = window.location.href.split('/')[window.location.href.split('/').length - 2]
    let splitIndex = window.location.href.lastIndexOf('/') + 1

    if (LANGS.map(lang => lang.split('_')[0]).includes(lang)) splitIndex = window.location.href.lastIndexOf(lang)

    return window.location.href.slice(0, splitIndex)
  }

  static getAppUrl(lang = '') {
    return pathUtils.getServerUrl() + (!isEmpty(lang) ? lang + '/' : '') + 'layers'
  }
}
