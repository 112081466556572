import React from 'react'
import { POLYGON_DRAW, CIRCLE_DRAW, POLYGON_SLICE, POLYGON_MOVE } from '../../actions/editor'
import styled from 'styled-components'

const DrawingToolsWrapper = styled.div`
  margin-top: 10;
`

export default class DrawingTools extends React.Component {
  render() {
    return (
      <DrawingToolsWrapper>
        <div
          className={'tools ' + (!this.props.hasPoints ? 'inactive ' : '') + (this.props.isActiveTool(POLYGON_MOVE) ? 'selected' : '')}
          onClick={() => {
            this.props.selectTool(POLYGON_MOVE)
          }}
        >
          <i className="ion-arrow-move" data-testid="move-field-icon" />
        </div>
        <div
          className={'tools ' + (!this.props.hasPoints ? 'inactive ' : '') + (this.props.isActiveTool(POLYGON_SLICE) ? 'selected' : '')}
          onClick={() => {
            this.props.selectTool(POLYGON_SLICE)
          }}
        >
          <i className="ion-scissors" />
        </div>
        <div
          className={'tools ' + (this.props.isActiveTool(POLYGON_DRAW) ? 'selected' : '')}
          onClick={() => {
            this.props.selectTool(POLYGON_DRAW)
          }}
        >
          <i className="fas fa-pencil-alt" />{' '}
        </div>
        <div
          className={'tools ' + (this.props.isActiveTool(CIRCLE_DRAW) ? 'selected' : '')}
          onClick={() => {
            this.props.selectTool(CIRCLE_DRAW)
          }}
        >
          <i className="ion-android-radio-button-off" />{' '}
        </div>
      </DrawingToolsWrapper>
    )
  }
}
