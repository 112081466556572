import { createSelector } from 'reselect'

import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import nth from 'lodash/fp/nth'
import first from 'lodash/fp/first'
import isNil from 'lodash/fp/isNil'
import reduce from 'lodash/fp/reduce'
import keys from 'lodash/fp/keys'
import union from 'lodash/fp/union'
import uniqBy from 'lodash/fp/uniqBy'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/fp/intersection'
import some from 'lodash/fp/some'
import find from 'lodash/fp/find'

import { getSelectedFieldsAvailableSeasonBaseFileNames } from '../selectors/seasonLayers'
import { getSeasonById, getSeasonsByIds, getSeasons } from './seasons'
import { getSelectedFlightGroupSeasonIds, getSelectedFlightGroupSeasons, getSelectedSeasonsAvailableSatBaseFileNames } from './flightGroups'

import { getFieldSeasonTimeLineActiveSeasonIdByFieldId, getFieldsSeasonsTimeline } from './fieldsSeasonsTimeline'
import moment from 'moment'

/*----------- Basic field -----------*/

export const getFields = get('fields')

export const getFieldsTypes = get('fieldTypes')

export const getIrrigationTypes = get('irrigationTypes')

export const getFieldsIds = createSelector(getFields, map('id'))

export const getFieldsIdsMap = createSelector(
  getFields,
  reduce((accumulator, field) => ({ ...accumulator, [field.id]: field }), {})
)

export const getFieldsLength = pipe(getFields, get('length'))

export const getFieldsPlantationDateObject = state =>
  pipe(
    getFieldsWithActiveSeason,
    reduce((fields, field) => {
      return {
        ...fields,
        [field.id]: get(field, ['season', 'parameters', 'plantationDate', 'value'])
      }
    }, {})
  )(state)

export const getFieldById = (state, id) => {
  const fieldsIdsMap = getFieldsIdsMap(state)
  return get(id)(fieldsIdsMap)
}

export const getFieldNameById = (state, id) => {
  const fieldsIdsMap = getFieldsIdsMap(state)
  const field = fieldsIdsMap[id]
  return get('name')(field)
}

export const getFieldCustomerById = (state, id) => {
  const fieldsIdsMap = getFieldsIdsMap(state)
  const field = fieldsIdsMap[id]
  return get('customer')(field)
}

export const getFieldsByIds = state => ids => {
  const fieldsIdsMap = getFieldsIdsMap(state)
  return pipe(
    getFieldsIds,
    intersection(ids),
    map(id => fieldsIdsMap[id])
  )(state)
}

export const getFieldsNamesByIds = state => ids => {
  return map('name')(getFieldsByIds(state)(ids))
}

export const getSelectedPreviewField = get('selectedPreviewField')

export const getPreviewField = state => {
  const previewFieldId = getSelectedPreviewField(state)
  return getFieldById(state, previewFieldId)
}

/*----------- selected Fields -----------*/

export const getSelectedField = get('selectedField')

export const getSelectedFields = state => {
  const selectedFieldsIds = getSelectedFieldsIds(state)
  return getFieldsByIds(state)(selectedFieldsIds)
}

export const getSelectedFieldsIds = get('selectedFields')

export const getFirstSelectedField = pipe(getSelectedFields, first)

export const getFirstSelectedFieldId = pipe(getSelectedFieldsIds, first)

export const getSelectedFieldsIdsDictionary = pipe(
  getSelectedFieldsIds,
  reduce(
    (fieldsIds, id) => ({
      ...fieldsIds,
      [id]: true
    }),
    {}
  )
)

export const getSelectedFieldsSeasonIds = pipe(
  getSelectedFields,
  map(field => get('season.id')(field))
)

export const isSelectedFieldEmpty = pipe(getSelectedField, isEmpty)

export const isSelectedFieldsEmpty = pipe(getSelectedFieldsIds, isEmpty)

export const getSelectedFieldsLength = pipe(getSelectedFieldsIds, get('length'))

export const isSingleFieldSelected = pipe(getSelectedFieldsLength, fieldsLength => fieldsLength === 1)

export const getSelectedFieldSnapshots = pipe(getSelectedField, get('snapshots'))

export const getSelectedFieldSnapshotsLength = pipe(getSelectedFieldSnapshots, get('length'))

export const getSelectedSnapshotIdx = get('selectedSnapshotIdx')

export const getSelectedFieldSnapshotFromSelectedIdx = state => pipe(getSelectedFieldSnapshots, nth(getSelectedSnapshotIdx(state)))(state)

export const getSelectedIdxSnapshotDeliverables = pipe(getSelectedFieldSnapshotFromSelectedIdx, get('deliverables'))

export const isSelectedSnapshotIdxDefined = pipe(getSelectedSnapshotIdx, isNil, bool => !bool)

/*----------- flight groups -----------*/

export const getTemporalSwipe = get('temporalSwipe')

export const getBaseFileNamesBySeasonId = state => {
  const selectedSeasonsAvailableSatBaseFileNames = getSelectedSeasonsAvailableSatBaseFileNames(state)
  const selectedFieldsAvailableSeasonBaseFileNames = getSelectedFieldsAvailableSeasonBaseFileNames(state)

  const baseFileNamesBySeasonId = reduce((accumulator, seasonId) => {
    return {
      ...accumulator,
      [seasonId]: union(accumulator[seasonId], selectedSeasonsAvailableSatBaseFileNames[seasonId])
    }
  }, selectedFieldsAvailableSeasonBaseFileNames)(keys(selectedSeasonsAvailableSatBaseFileNames))

  return baseFileNamesBySeasonId
}

export const getFieldsWithActiveSeason = state => {
  return map(field => {
    const fieldId = get('id')(field)
    const seasonId = getFieldSeasonTimeLineActiveSeasonIdByFieldId(state, fieldId)
    const season = getSeasonById(seasonId)(state)
    return { ...field, season }
  })(getFields(state))
}

// for some reason using all seasons instead of seasonsByObjectID selector, makes it faster
export const getFieldsWithInitialSeason = createSelector([getFields, getFieldsSeasonsTimeline, getSeasons], (fields, seasonsTimeline, seasons) => {
  if (some(isEmpty)([fields, seasonsTimeline, seasons])) {
    return []
  }

  const today = moment()
  const fieldsSeasons = map(field => {
    const fieldSeasons = get(field.id)(seasonsTimeline)
    const activeSeasonId = get('id')(
      find(season => moment(season.startDate).isSameOrBefore(today, 'day') && moment(season.endDate).isSameOrAfter(today, 'day'))(fieldSeasons)
    )

    return {
      ...field,
      season: get(activeSeasonId)(seasons)
    }
  })(fields)

  return fieldsSeasons
})

export const getFieldsWithInitialSeasonById = createSelector(
  getFieldsWithInitialSeason,
  reduce(
    (fields, field) => ({
      ...fields,
      [field.id]: field
    }),
    {}
  )
)

export const getSelectedFieldsWithActiveSeason = state => {
  const fields = getSelectedFields(state)
  const seasons = getSelectedFlightGroupSeasons(state)
  return map(field => {
    const fieldId = field.id
    const season = find(season => season.fieldId === fieldId)(seasons)
    return { ...field, season }
  })(fields)
}

export const getSelectedFieldsWithActiveSeasonGeometries = state => {
  const fields = getSelectedFieldsWithActiveSeason(state)
  return map(field => get('season.geometry')(field))(fields)
}

export const getFieldsWithSeasonBySeasonIds = (state, seasonIds) => {
  const seasons = getSeasonsByIds(seasonIds)(state)
  const fieldsWithSeason = map(season => {
    const field = getFieldById(state, get('fieldId')(season))
    return { ...field, season }
  })(seasons)
  return uniqBy('id')(fieldsWithSeason)
}

export const getSelectedFlightGroupFieldsWithSeason = state => {
  const selectedFlightGroupSeasonIds = getSelectedFlightGroupSeasonIds(state)
  return getFieldsWithSeasonBySeasonIds(state, selectedFlightGroupSeasonIds)
}

export const hasSelectedFlightGroupGeometry = state => {
  const selectedFlightGroupsSeasons = getSelectedFlightGroupSeasons(state)
  return some(season => get('geometry.coordinates[0]')(season))(selectedFlightGroupsSeasons)
}

export const getGeometries = get('geometries')

export const getGeometriesByFields = fields => state => {
  const fieldIds = map('id')(fields)
  const geometries = getGeometries(state)
  return map(fieldId => find(geometry => get('id')(geometry) === fieldId)(geometries))(fieldIds)
}

export const getSelectedFieldsGeometries = state => {
  const fieldIds = getSelectedFieldsIds(state)
  const geometries = getGeometries(state)
  return map(fieldId => find(geometry => get('id')(geometry) === fieldId)(geometries))(fieldIds)
}
