import React, { useState } from 'react'
import { withTranslator } from '../../HOCComponents/withTranslator'
import capitalize from 'lodash/capitalize'
import styled from 'styled-components'
import { UIStyles } from '../../../theme'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import { FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { StyledTooltip } from '../../UIComponents/StyledTooltip'

const Wrapper = styled(ToolWrapper)`
  /* OVERRIDES MATERIAL INLINE STYLES */
  input[type='radio'] + div svg:nth-child(2) {
    fill: ${UIStyles.vomitColor} !important;
  }
`

const TooltipStyledText = styled.span`
  font-size: 1.4rem;
  margin-left: 5px;
`

const descriptionsByType = {
  inspection: 'report_type_inspection_description',
  annotation: 'report_type_annotation_description',
  action: 'report_type_action_description',
  result: 'report_type_result_description'
}

const radioSx = {
  '& .MuiSvgIcon-root': {
    fontSize: '24px'
  }
}

function ReportTypesRadioButtons({ t, reportTypes, setReportId, selectedReportId, errorNode }) {
  const [tooltipText, setTooltipText] = useState('')

  return (
    <Wrapper>
      <ToolTitle>{t('Report type')} *</ToolTitle>
      <StyledTooltip
        title={
          <>
            <i className={`fa fa-info-circle`} />
            <TooltipStyledText>{t(tooltipText)}</TooltipStyledText>
          </>
        }
        followCursor
      >
        <RadioGroup name="reportTypes" onChange={({ target }) => setReportId(target.value)} defaultValue={selectedReportId} row>
          {reportTypes.map(reportType => (
            <FormControlLabel
              key={reportType.id}
              value={reportType.id}
              label={capitalize(t(reportType.name))}
              name={reportTypes.id}
              sx={{ fontSize: 15 }}
              onMouseEnter={() => setTooltipText(descriptionsByType[reportType.name])}
              control={<Radio sx={radioSx} />}
            />
          ))}
        </RadioGroup>
      </StyledTooltip>
      {errorNode}
    </Wrapper>
  )
}

export default withTranslator(ReportTypesRadioButtons)
