import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import FieldsComparisonChartsFilters from '../../components/DashboardComponents/FieldsComparisonChartsFilters'
import { getFieldComparisonOrderByDate, getMaxCloudCoverage, isSelectedFlightFilterDrone } from '../../selectors/dashboard'
import { openConfirmChangeFieldsSelectionModal } from '../../actions/modal'
import { toggleFieldsComparisonOrderByDate } from '../../actions/dashboard'

const mapStateToProps = state => ({
  maxCloudCoverage: getMaxCloudCoverage(state),
  isDroneFlight: isSelectedFlightFilterDrone(state),
  fieldComparisonOrder: getFieldComparisonOrderByDate(state)
})

const mapDispatchToProps = {
  openConfirmChangeFieldsSelectionModal,
  toggleFieldsComparisonOrderByDate
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(FieldsComparisonChartsFilters)
