import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../../theme'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import TextChips from './TextChips'
import compact from 'lodash/compact'
import reduce from 'lodash/reduce'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import StyledTextInput from '../../UIComponents/StyledTextInput'

const AddEmailButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: -0.5rem;
`

const AddEmailIcon = styled.i`
  color: ${UIStyles.vomitColor};
  transform: translateX(-2.5rem);
  padding-top: 5px;
`

const textChipsStyle = {
  margin: '4px',
  backgroundColor: themeColors.blueLightHover
}

const InputWrapper = styled.div`
  margin-bottom: 15px;
`

const inputStyle = { color: UIStyles.whiteColor }

function RecipientEmailChips({ t, error, setRecipientEmailInput, recipentInput, addRecipientEmail, recipentEmails, removeChip }) {
  const [emailInput, setEmailInput] = useState('')

  useEffect(() => setEmailInput(recipentInput), [recipentInput])

  const cleanInputAddRecipient = () => {
    setEmailInput('')
    addRecipientEmail()
  }

  const onRecipientsKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      setRecipientEmailInput(emailInput)
      cleanInputAddRecipient()
    }
  }

  const formattedErrors = reduce(compact(error), (allErrorMessages, errorMessage) => `${allErrorMessages} ${t(errorMessage)}.`, '')

  return (
    <ToolWrapper>
      <InputWrapper>
        <ToolTitle>{t('Recipients')}</ToolTitle>
        <StyledTextInput
          inputProps={{ style: inputStyle }}
          onKeyPress={onRecipientsKeyPress}
          placeholder="email1@mail.com, email2@mail.net; email3@mail.org"
          helperText={formattedErrors}
          onChange={setEmailInput}
          value={emailInput || ''}
          onBlur={() => setRecipientEmailInput(emailInput)}
          margin="none"
        />
      </InputWrapper>
      <AddEmailButton onClick={cleanInputAddRecipient}>
        <AddEmailIcon className="fas fa-plus" />
      </AddEmailButton>
      <TextChips chips={recipentEmails} removeChip={removeChip} style={textChipsStyle} />
    </ToolWrapper>
  )
}

export default withTranslator(RecipientEmailChips)
