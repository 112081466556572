import find from 'lodash/fp/find'
import {
  getSamplesForm,
  getSamplesFormLocation,
  getSamplesFormPictureLocalPath,
  getSamplesFormPicturePath,
  getSamplesFormPictureTempPath
} from '@layers-frontend/commons/store/selectors/samplesForm'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import { point } from '@turf/helpers'
import { getMarkerLocationCoordinates } from './editor'
import { getSelectedFieldsGeometries } from './fields'
import pipe from 'lodash/fp/pipe'
import get from 'lodash/fp/get'
import isNil from 'lodash/fp/isNil'
import isEmpty from 'lodash/isEmpty'

export const getSamplesFormSeasonByLocation = location => state => {
  const geometries = getSelectedFieldsGeometries(state)
  return find(({ geometry }) => booleanPointInPolygon(point(location), geometry))(geometries)
}

export const getSamplesFormSeasonByMarkerLocation = state => {
  const location = getMarkerLocationCoordinates(state)
  if (isEmpty(location)) return
  return getSamplesFormSeasonByLocation(location)(state)
}

export const getSamplesFormSeasonBySampleFormLocation = state => {
  const location = getSamplesFormLocation(state)
  return getSamplesFormSeasonByLocation(location)(state)
}

export const getSamplesFormImagePath = state =>
  getSamplesFormPictureTempPath(state) || getSamplesFormPictureLocalPath(state) || getSamplesFormPicturePath(state)

export const getSamplesFormSampleSeasonId = pipe(getSamplesForm, get('seasonId'))

export const isNewSampleMode = pipe(getSamplesForm, get('id'), isNil)
