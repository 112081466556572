import React from 'react'
import styled from 'styled-components'
import { TableTitle, TableRowContainer, TableHeaderWrapper, TableRowItem } from '../Layout'
import { UIStyles } from '../../../theme'
import map from 'lodash/map'
import each from 'lodash/each'
import values from 'lodash/values'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import { withTranslator } from '../../HOCComponents/withTranslator'
import StyledListButton from '../../UIComponents/StyledListButton'

const BottomLeftContainer = styled.div`
  background: ${UIStyles.darkBlue};
  margin: 20px;
  width: 100%;
  padding: 20px;
  max-height: 50vh;
  overflow: auto;
`

const listButtonStyle = {
  flex: 0.75
}

function TableMinMax({ t, title, rangeMin, rangeMax, data }) {
  if (isEmpty(data)) return null

  const getArrayData = () => {
    const rows = [[`${t('fields')}`, `Min ${rangeMin} %`, `Max ${rangeMax} %`]]
    each(data, item => {
      rows.push(values(omit(item, 'color')))
    })
    return rows
  }

  const downloadCSV = () => {
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      getArrayData()
        .map(e => e.join(','))
        .join('\n')

    const link = document.createElement('a')
    link.setAttribute('href', encodeURI(csvContent))
    link.setAttribute('download', `${t('harvest planning')}.csv`)
    document.body.appendChild(link)
    window.open(link.click())
  }

  return (
    <>
      <TableHeaderWrapper>
        <TableTitle>{t(title)}</TableTitle>
        <StyledListButton label={t('download values')} onClick={downloadCSV} labelDistance={6} icon={'icon-xls'} itemStyle={listButtonStyle} />
      </TableHeaderWrapper>

      <BottomLeftContainer>
        <TableRowContainer>
          <TableRowItem color="#fff">{t('fields')}</TableRowItem>
          <TableRowItem>Min ({rangeMin}%)</TableRowItem>
          <TableRowItem>Max ({rangeMax}%)</TableRowItem>
        </TableRowContainer>

        {map(data, item => (
          <TableRowContainer key={item.fieldName}>
            <TableRowItem color={item.color}>{item.fieldName}</TableRowItem>
            <TableRowItem>{item.minDate}</TableRowItem>
            <TableRowItem>{item.maxDate}</TableRowItem>
          </TableRowContainer>
        ))}
      </BottomLeftContainer>
    </>
  )
}

export default withTranslator(TableMinMax)
