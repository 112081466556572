import React from 'react'
import styled from 'styled-components'
import { authorize } from '../HOCComponents/withAuthentication'
import { ROLE_AGRO, ROLE_COOPERATIVE } from '../../actions/users'
import { UIStyles } from '../../theme'
import {
  StyledCardFieldItem,
  StyledCardFieldItemDetails,
  ReferenceIdCard,
  CustomerNameCard,
  SeasonLabelCard,
  FieldTypeCard,
  FieldNameAndPlantationDateCard,
  FieldCutCard,
  InnerContainer,
  InfoIconWrapper
} from '../SearchComponents/FieldItem'
import get from 'lodash/get'
import { withTranslator } from '../HOCComponents/withTranslator'
import moment from 'moment'

const StyledFieldsDetails = styled.div`
  transition: 1s ease-in-out;
  border-top: 1px solid rgba(230, 230, 230, 0.2);
  z-index: 3;
  height: auto;
  overflow: auto;
`

const StyledList = styled.li`
  color: ${UIStyles.whiteColor};
  display: inline-block;
  position: relative;
  vertical-align: middle;
  font-family: Roboto;
  font-size: 12px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  width: 100%;
  height: 125px;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(230, 230, 230, 0.2);
  }
`

const CropWrapper = styled.div`
  display: flex;
  > * {
    &:first-child {
      margin-right: 20px;
    }
  }
`

function SelectedFieldsDetails({ userRoles, selectedFields, setPreviewFieldId, openFieldSeasonFormModal, t }) {
  const isAuthorize = authorize(userRoles, [ROLE_AGRO, ROLE_COOPERATIVE])

  const iconClickHandler = (e, fieldId) => {
    e.stopPropagation()
    setPreviewFieldId(fieldId)
    openFieldSeasonFormModal('readOnly')
  }

  return (
    <StyledFieldsDetails>
      <ul className="filterList">
        {selectedFields.map((field, key) => {
          const fieldId = get(field, 'id')
          const fieldType = t(get(field, 'season.type.name', ''))
          const fieldSubType = t(get(field, 'season.subtype.name', null))
          const fieldTypeAndSubtype = `${fieldType}${fieldSubType ? '/' + fieldSubType : ''}`
          const fieldName = get(field, 'name', null)
          const plantationDate = get(field, 'season.parameters.plantationDate.value', null)
          const customerName = get(field, 'customer.name', null)
          const seasonLabel = get(field, 'season.label', null)
          const externalReference = get(field, 'externalReferenceId', null)
          const cutNumber = get(field, 'season.parameters.cutNumber.value', null)

          return (
            <StyledList key={key}>
              <StyledCardFieldItem>
                <FieldNameAndPlantationDateCard fieldName={fieldName} plantationDate={plantationDate && moment(plantationDate).format('YYYY-MM-DD')} />
                <StyledCardFieldItemDetails>
                  <InnerContainer>
                    <CustomerNameCard label={customerName} isAuthorize={isAuthorize} />
                    <CropWrapper>
                      <FieldTypeCard label={fieldTypeAndSubtype} />
                      <FieldCutCard number={cutNumber} />
                    </CropWrapper>
                    <SeasonLabelCard label={seasonLabel} />
                    <ReferenceIdCard label={externalReference} isAuthorize={isAuthorize} />
                  </InnerContainer>
                  <InfoIconWrapper onClick={e => iconClickHandler(e, fieldId)} className="fa-solid fa-circle-info" />
                </StyledCardFieldItemDetails>
              </StyledCardFieldItem>
            </StyledList>
          )
        })}
      </ul>
    </StyledFieldsDetails>
  )
}

export default withTranslator(SelectedFieldsDetails)
