import { validModalNames } from '../constants'
import moment from 'moment'
import { getMetadatasDateRange } from '../selectors/config'
import { openSearchFilters } from '../actions/search'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { openEditMode } from './viewmode'
import { resetFieldsReportModal } from './fieldsReportModal'

export const OPEN_CLOSE_MODAL = 'OPEN_CLOSE_MODAL'
export const SET_MODAL_COMPONENT_NAME = 'SET_MODAL_COMPONENT_NAME'
export const OPEN_MODAL_BY_NAME = 'OPEN_MODAL_BY_NAME'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const TOGGLE_MODAL_VISIBILITY = 'TOGGLE_MODAL_VISIBILITY'
export const SHOW_MODAL = 'SHOW_MODAL'
export const SET_MODAL_PROP = 'SET_MODAL_PROP'

export const closeModal = () => ({
  type: CLOSE_MODAL
})

export const toggleModalVisibility = () => ({
  type: TOGGLE_MODAL_VISIBILITY
})

export const openModal = (componentName, props = {}) => ({
  type: OPEN_MODAL_BY_NAME,
  componentName,
  props
})

export const {
  DOWNLOAD_STATISTICS_MODAL,
  EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL,
  FIELDS_NAMES_MODAL,
  PLANTATION_DATE_FILTER_MODAL,
  DOSAGE_MODAL,
  GO_TO_FIELD_MODAL,
  CONFIRM_CHANGE_FIELDS_SELECTION_MODAL,
  SEND_FIELDS_REPORT_MODAL,
  REPORT_MANAGER_MODAL,
  FILE_GENERATOR_MODAL,
  FIELD_SEASON_FORM_MODAL,
  SAMPLE_IMAGE_MODAL,
  GLOBAL_FILES_MODAL
} = validModalNames

export const setModalComponentName = componentName => ({
  type: SET_MODAL_COMPONENT_NAME,
  componentName
})

export const openDownloadStatisticsModal = () => (dispatch, getState) => {
  const dateRange = getMetadatasDateRange(getState())

  const to = moment().format('YYYY-MM-DD')
  const from = moment().subtract(dateRange, 'days').format('YYYY-MM-DD')

  return dispatch(openModal(DOWNLOAD_STATISTICS_MODAL, { from, to }))
}

export const openPlantationDateFilterModal = () => openModal(PLANTATION_DATE_FILTER_MODAL, { dispatchOnModalClose: openSearchFilters })

export const openExtraStatisticsFetchConfirmationModal = () => openModal(EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL)

export const setModalProps = (propKey, value) => ({
  type: SET_MODAL_PROP,
  propKey,
  value
})

export const openFieldsNamesModal = fieldsNames => openModal(FIELDS_NAMES_MODAL, { fieldsNames })

export const openGoToFieldModal = props => openModal(GO_TO_FIELD_MODAL, props)

export const openConfirmChangeFieldsSelectionModal = props => openModal(CONFIRM_CHANGE_FIELDS_SELECTION_MODAL, props)

export const openSendFieldsReportModal = () =>
  openModal(SEND_FIELDS_REPORT_MODAL, { contentStyle: { maxWidth: '60%', height: 'auto' }, closeOnClickOutside: false })

export const openSendNewFieldsReportModal = () => dispatch => {
  dispatch(resetFieldsReportModal())
  return dispatch(openSendFieldsReportModal())
}

export const openReportManagerModal = () =>
  openModal(REPORT_MANAGER_MODAL, { contentStyle: { maxWidth: '90%', width: '90%', height: 'auto', backgroundColor: themeColors.darkBlue } })

export const openNewReportManagerModal = () => dispatch => {
  dispatch(resetFieldsReportModal())
  dispatch(openReportManagerModal())
}

export const openGlobalFilesModal = () =>
  openModal(GLOBAL_FILES_MODAL, { contentStyle: { maxWidth: '90%', height: 'auto', backgroundColor: themeColors.darkBlue } })

const fieldSeasonModalProps = { closeOnClickOutside: false, contentStyle: { maxWidth: '1100px', width: '80vw', backgroundColor: themeColors.darkBlue } }

export const openFieldSeasonFormModal = mode => dispatch => {
  if (mode !== 'readOnly') dispatch(openEditMode())
  return dispatch(openModal(FIELD_SEASON_FORM_MODAL, { ...fieldSeasonModalProps, mode }))
}

export const openSampleImageModal = src => openModal(SAMPLE_IMAGE_MODAL, { contentStyle: { height: 'auto', display: 'flex', justifyContent: 'center' }, src })
