import { connect } from 'react-redux'

import ViewHandler from '../../components/PagesComponents/ViewHandler'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'
import { getScreenView } from '../../selectors/viewmode'

const mapStateToProps = state => ({
  screenView: getScreenView(state)
})

export default withCurrentRoute(connect(mapStateToProps)(ViewHandler))
