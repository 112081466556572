import { REQUEST_GLOBAL_FILES, REQUEST_GLOBAL_FILES_SUCCESS } from '@layers-frontend/commons/store/storeConstants'

export const initialState = {}

export default function globalFiles(state = initialState, action = {}) {
  const { globalFiles } = action
  switch (action.type) {
    case REQUEST_GLOBAL_FILES:
      return {
        ...state,
        globalFiles
      }
    case REQUEST_GLOBAL_FILES_SUCCESS:
      return {
        ...state,
        globalFiles
      }
    default:
      return state
  }
}
