import { connect } from 'react-redux'
import DashboardLeftMenuTop from '../../components/DashboardComponents/DashboardLeftMenuTop'
import { setTopBarVisibility } from '../../actions/dashboard'
import { getDashboardView } from '../../selectors/dashboard'
import { getSelectedFieldsLength } from '../../selectors/fields'
import { errorNotification } from '../../actions/notifications'
import withTranslator from '../../components/HOCComponents/withTranslator'
import { getUserRoles } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  dashboardView: getDashboardView(state),
  userRoles: getUserRoles(state),
  selectedFieldsLength: getSelectedFieldsLength(state)
})

const mapDispatchToProps = {
  setTopBarVisibility,
  errorNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslator(DashboardLeftMenuTop))
