// import { connectTemporalGroup } from '../../components/HOCComponents/connectTemporalGroup'

import { connect } from 'react-redux'

import TemporalGroupComparisonLayer from '../../components/LayersComponents/TemporalGroupComparisonLayer'

import { getBaseEvalscriptUrl, getConfigData } from '../../selectors/config'
import { getTemporalSwipe } from '../../selectors/fields'
import {
  getSelectedFlightGroupDeliverables,
  getSelectedFlightGroupSource,
  getTemporalComparisonFlightGroup,
  getTemporalComparisonFlightGroupDeliverables,
  getTemporalComparisonFlightGroupSeasons
} from '../../selectors/flightGroups'
import { getTemporalComparisonFlightGroupSnapshots } from '../../selectors/snapshots'
import { isTemporalView, shouldShowLayers } from '../../selectors/viewmode'
import { getGroupDeliverablesBaseZIndex } from '../../selectors/ui'

import get from 'lodash/get'
import map from 'lodash/map'
import { getPlanetApiKeys } from '../../selectors/fieldsTileOrder'

const mapStateToProps = state => {
  const flightGroupDeliverables = getSelectedFlightGroupDeliverables(state) || []
  const orderDeliverables = map(flightGroupDeliverables, 'id')
  return {
    flightGroupDeliverables: getTemporalComparisonFlightGroupDeliverables(state),
    flightSource: getSelectedFlightGroupSource(state),
    flightGroupSeasons: getTemporalComparisonFlightGroupSeasons(state),
    flightGroupSnapshots: getTemporalComparisonFlightGroupSnapshots(state),
    baseEvalscriptUrl: getBaseEvalscriptUrl(state),
    sentinelApiKey: get(getConfigData(state, 'sentinel'), 'apiKey'),
    sentinelBaseUrl: get(getConfigData(state, 'sentinel'), 'baseUrl'),
    planetApiKeys: getPlanetApiKeys(state),
    showLayers: shouldShowLayers(state),
    showTemporal: isTemporalView(state),
    temporalSwipe: getTemporalSwipe(state),
    // eslint-disable-next-line object-shorthand
    orderDeliverables: orderDeliverables,
    selectedFlightGroup: getTemporalComparisonFlightGroup(state),
    groupDeliverablesBaseZIndex: getGroupDeliverablesBaseZIndex(state)
  }
}

export default connect(mapStateToProps)(TemporalGroupComparisonLayer)
