import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../../components/HOCComponents/withTranslator'
import { withFieldsAvailableAndSelected } from '../../../components/HOCComponents/withFieldsAvailableAndSelected'
import { getFieldsFilteredByView } from '../../../selectors/availableFields'
import {
  setMeteorologicalStatisticsDateRangeFrom,
  setMeteorologicalStatisticsDateRangeTo,
  setMeteorologicalStatisticsBaseTemp
} from '../../../actions/dashboard'
import {
  getMeteorologicalStatisticsDateRangeFrom,
  getMeteorologicalStatisticsDateRangeTo,
  getMeteorologicalStatisticsBaseTemperature
  // eslint-disable-next-line import/no-duplicates
} from '../../../selectors/dashboard'
import { fetchMeteorologicalApiData } from '../../../actions/meteorologicalData'
// eslint-disable-next-line import/no-duplicates
import { getMeteorologicalStatisticsSelectedField } from '../../../selectors/dashboard'
import { isMetric } from '@layers-frontend/commons/store/selectors/user'

import MeteorologicalStatisticsFilters from '../../../components/DashboardComponents/DashboadFilters/MeteorologicalStatisticsFilters'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => ({
  dateRangeFrom: getMeteorologicalStatisticsDateRangeFrom(state),
  dateRangeTo: getMeteorologicalStatisticsDateRangeTo(state),
  baseTemperature: getMeteorologicalStatisticsBaseTemperature(state),
  meteorologicalSelectedField: getMeteorologicalStatisticsSelectedField(state),
  fieldsOptions: getFieldsFilteredByView({ fieldsWithAvailableAndSelected })(state),
  isMetric: isMetric(state)
})

const mapDispatchToProps = {
  setMeteorologicalStatisticsDateRangeFrom,
  setMeteorologicalStatisticsDateRangeTo,
  setMeteorologicalStatisticsBaseTemp,
  fetchMeteorologicalApiData
}

export default compose(withTranslator, withFieldsAvailableAndSelected, connect(mapStateToProps, mapDispatchToProps))(MeteorologicalStatisticsFilters)
