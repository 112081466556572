import { connect } from 'react-redux'
import GroupDeliverablesLayer from '../../components/LayersComponents/GroupDeliverablesLayer'
import { isTemporalView, shouldShowLayers } from '../../selectors/viewmode'
import { getTemporalSwipe } from '../../selectors/fields'
import { getBaseEvalscriptUrl, getConfigData } from '../../selectors/config'
import { getGroupDeliverablesBaseZIndex } from '../../selectors/ui'
import {
  getSelectedFlightGroupSource,
  getSelectedFlightGroupDeliverables,
  getSelectedFlightGroupSeasons,
  getSelectedFlightGroup
} from '../../selectors/flightGroups'
import { getSelectedFlightGroupSnapshots } from '../../selectors/snapshots'

import map from 'lodash/map'
import get from 'lodash/get'
import { getPlanetApiKeys } from '../../selectors/fieldsTileOrder'

const mapStateToProps = state => {
  const flightGroupDeliverables = getSelectedFlightGroupDeliverables(state) || []
  const orderDeliverables = map(flightGroupDeliverables, 'id')
  return {
    flightGroupDeliverables,
    flightSource: getSelectedFlightGroupSource(state),
    flightGroupSeasons: getSelectedFlightGroupSeasons(state),
    flightGroupSnapshots: getSelectedFlightGroupSnapshots(state),
    baseEvalscriptUrl: getBaseEvalscriptUrl(state),
    sentinelApiKey: get(getConfigData(state, 'sentinel'), 'apiKey'),
    sentinelBaseUrl: get(getConfigData(state, 'sentinel'), 'baseUrl'),
    planetApiKeys: getPlanetApiKeys(state),
    showLayers: shouldShowLayers(state),
    showTemporal: isTemporalView(state),
    temporalSwipe: getTemporalSwipe(state),
    orderDeliverables,
    selectedFlightGroup: getSelectedFlightGroup(state),
    groupDeliverablesBaseZIndex: getGroupDeliverablesBaseZIndex(state)
  }
}

export default connect(mapStateToProps)(GroupDeliverablesLayer)
