import React from 'react'
import PropTypes from 'prop-types'
import { UIStyles } from '../../theme'
import { withTranslator } from '../HOCComponents/withTranslator'

export const DeliverableLegendItem = withTranslator(({ color, name, measure, t }) => (
  <li className="deliverableLegend--item">
    <div style={{ backgroundColor: color }} />
    <label>
      <span style={{ display: 'inline-block' }}> {t(name.toLowerCase())}</span>
      {measure && <span style={{ color: UIStyles.grayColor }}> {t(measure.short_name)}</span>}
    </label>
  </li>
))

DeliverableLegendItem.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string
}

export const MeasureUnit = ({ label, description }) => (
  <div style={{ paddingLeft: '80px', fontSize: '13px', fontWeight: 300, letterSpacing: '1px', paddingBottom: '5px' }}>
    <span style={{ color: UIStyles.grayColor }}>{label}: </span>
    <span style={{ color: UIStyles.whiteColor }}>{description} </span>
  </div>
)

export const DeliverableLegend = ({ opened, children, measure }) => (
  <div className={' deliverableInfo ' + (!opened ? 'closed' : '')}>
    <ul className={'deliverableLegend'}>{children}</ul>
    {measure && <MeasureUnit label={measure.short_name} description={measure.long_name} />}
  </div>
)

DeliverableLegend.propTypes = {
  children: PropTypes.array
}
