import React from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import { Button, Icon } from '@mui/material'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const style = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  color: themeColors.whiteColor,
  fontSize: 15,
  textTransform: 'capitalize'
}

const iconStyle = {
  fontSize: 20,
  color: themeColors.vomitColor
}

const StyledIcon = () => <Icon style={iconStyle} className={'fas fa-plus'} />

const AddSampleButton = ({ t, openNewSampleForm }) => (
  <Button variant="text" style={style} startIcon={<StyledIcon />} fullWidth onClick={openNewSampleForm}>
    {t('Add new sample')}
  </Button>
)

export default withTranslator(AddSampleButton)
