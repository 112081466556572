import { connect } from 'react-redux'

import { getSeasonLayersBaseZIndex, shouldShowSamplesInMap } from '../../selectors/ui'
import { getScreenView } from '../../selectors/viewmode'
import { TOP_LAYERS_BASE_Z_INDEX, layersViews } from '../../constants'

import FieldViewMapClickInteraction from '../../components/olComponents/interaction/MapClickInteraction'
import { selectSampleById, deselectAllSamples } from '../../actions/samples'

const mapStateToProps = state => ({
  isFieldViewActive: getScreenView(state) === layersViews.FIELD_VIEW,
  isSeasonLayersTabActive: getSeasonLayersBaseZIndex(state) === TOP_LAYERS_BASE_Z_INDEX,
  showSamplesInMap: shouldShowSamplesInMap(state)
})

const mapDispatchToProps = {
  selectSampleById,
  deselectAllSamples
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldViewMapClickInteraction)
