import React from 'react'
import { DeliverableItem } from './DeliverableItem'
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'

import get from 'lodash/get'

export const DraggableDeliverable = props => <DeliverableItem {...props} isDraggable={true} />

export const LayersList = ({ onSortEnd, layers, style, className, visible, renderLayer, setRef, isDraggable }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  function dragEndHandler(event) {
    const { active, over } = event
    if (over && active.id !== over.id) {
      const oldIndex = get(active, ['data', 'current', 'sortable', 'index'])
      const newIndex = get(over, ['data', 'current', 'sortable', 'index'])
      onSortEnd(oldIndex, newIndex)
    }
  }

  return (
    // eslint-disable-next-line no-extra-boolean-cast
    <div className={`layersBlock  ${!!className ? className : ''} ${!visible ? ' hidden ' : ''}`} style={!!style ? style : {}} key="layersBlock" ref={setRef}>
      <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={dragEndHandler}>
        <SortableContext items={layers} strategy={verticalListSortingStrategy}>
          <ul className={isDraggable ? 'DeliverableDroppable' : ''} style={{ paddingLeft: '0px', overflow: 'hidden' }}>
            {layers.map((layer, idx) => (
              <React.Fragment key={layer.id}>{renderLayer(layer, idx)}</React.Fragment>
            ))}
          </ul>
        </SortableContext>
      </DndContext>
    </div>
  )
}

export const LayerBox = props => <LayersList {...props} />
