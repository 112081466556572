import React from 'react'
import styled from 'styled-components'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

import { UIStyles } from '../../theme'

const IconCloseFiltersWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  margin-right: 3px;
`

const IconCloseFilters = styled.i`
  color: ${UIStyles.vomitColor};
  font-size: 24px;
  transform: ${({ isFocus }) => (isFocus ? 'rotate(0deg)' : 'rotate(180deg)')};
`

export const ToggleSearchBar = ({ isFocus, toggleSearch, t }) => {
  return (
    <StyledTooltip title={isFocus ? t('hide filter list') : t('show filter list')}>
      <IconCloseFiltersWrapper onClick={toggleSearch} type="button" aria-label="toggle filter list" id="ToggleSearchList">
        <IconCloseFilters isFocus={isFocus} className="icon-arrow" />
      </IconCloseFiltersWrapper>
    </StyledTooltip>
  )
}
