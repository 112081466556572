import React from 'react'
import FieldItem from './FieldItem'
import styled from 'styled-components'
import { authorize } from '../HOCComponents/withAuthentication'
import { ROLE_AGRO, ROLE_COOPERATIVE } from '../../actions/users'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import { List } from 'react-virtualized'

const SearchListResultsWrapper = styled.div`
  position: sticky;
`

const renderItem = ({
  fields,
  selectFields,
  addFieldsToGroup,
  removeFieldToGroup,
  currentLocation,
  shouldRenderCustomerAndReferenceId,
  selectedFieldsLength,
  errorNotification,
  openFieldSeasonFormModal,
  setPreviewFieldId
}) => ({ index, style }) => {
  const field = fields[index]
  return (
    <FieldItem
      selectFields={selectFields}
      addFieldsToGroup={addFieldsToGroup}
      setPreviewFieldId={setPreviewFieldId}
      openFieldSeasonFormModal={openFieldSeasonFormModal}
      removeFieldToGroup={removeFieldToGroup}
      field={field}
      selected={field.isSelected}
      key={field.id}
      isAvailable={field.isAvailable}
      isSelected={field.isSelected}
      currentLocation={currentLocation}
      shouldRenderCustomerAndReferenceId={shouldRenderCustomerAndReferenceId}
      virlualizedListStyle={style}
      selectedFieldsLength={selectedFieldsLength}
      errorNotification={errorNotification}
      testId={`field-item-${index}`}
    />
  )
}

const nullRenderer = () => null

export default class SearchBarList extends React.Component {
  constructor() {
    super()
    this.wrapperRef = React.createRef()
    this.debounceSetSearchListDimensions = debounce(this.setSearchListDimensions, 500)
  }

  state = {
    height: 0,
    width: 0
  }

  componentDidMount() {
    this.setSearchListDimensions()
    window.addEventListener('resize', this.debounceSetSearchListDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceSetSearchListDimensions)
  }

  setSearchListDimensions = () => {
    const wrapperHeight = get(this, 'wrapperRef.current.clientHeight')
    const wrapperWidth = get(this, 'wrapperRef.current.clientWidth')
    this.setState({ height: wrapperHeight, width: wrapperWidth })
  }

  render() {
    const {
      fields,
      selectFields,
      addFieldsToGroup,
      removeFieldToGroup,
      currentLocation,
      userRoles,
      selectedFieldsLength,
      errorNotification,
      openFieldSeasonFormModal,
      setPreviewFieldId,
      getNextFieldsChunk,
      fieldStats,
      canGetNextChunk
    } = this.props
    const shouldRenderCustomerAndReferenceId = authorize(userRoles, [ROLE_AGRO, ROLE_COOPERATIVE])

    const currentChunk = get(fieldStats, 'currentChunk')
    const totalChunks = get(fieldStats, 'totalChunks')

    const fieldsRenderer = renderItem({
      fields,
      selectFields,
      addFieldsToGroup,
      removeFieldToGroup,
      currentLocation,
      shouldRenderCustomerAndReferenceId,
      selectedFieldsLength,
      errorNotification,
      openFieldSeasonFormModal,
      setPreviewFieldId
    })

    const handleScroll = ({ clientHeight, scrollHeight, scrollTop }) => {
      if (canGetNextChunk && currentChunk < totalChunks && clientHeight + scrollTop === scrollHeight) getNextFieldsChunk()
    }

    return (
      <div className="searchList" ref={this.wrapperRef} style={{ height: '100%' }}>
        <SearchListResultsWrapper data-testid="search-box-field">
          <List
            id="search-list"
            className="search-list"
            height={this.state.height - 40}
            width={this.state.width}
            rowCount={fields.length}
            rowHeight={125}
            rowRenderer={fieldsRenderer}
            noRowsRenderer={nullRenderer}
            overscanRowCount={10}
            onScroll={handleScroll}
          />
        </SearchListResultsWrapper>
      </div>
    )
  }
}
