import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'

import { createApolloFetch } from 'apollo-fetch'

import { hasUserRole } from '../store/selectors/user'
import { setRequestStart, setRequestEnd, setRequestFail, requestNotAuthorizedByRole } from '../store/actions/requests'

export const createFetchRequestWrapper = ({ getToken, routing }) => ({
  requestType,
  route,
  urlParams = {},
  fetchOptions = {},
  onSuccess,
  onError = error => console.log(error),
  customRoute,
  authorizingRole,
  overlay = true,
  item = null,
  responseParser = 'json',
  method = 'POST',
  body = null,
  hasNextPage
}) => async (dispatch, getState) => {
  try {
    if (authorizingRole) {
      const isAuthorized = hasUserRole(getState(), authorizingRole)
      if (!isAuthorized) {
        dispatch(requestNotAuthorizedByRole(requestType, authorizingRole))
        return
      }
    }

    dispatch(setRequestStart(requestType, overlay, item))

    const requestOptions = assign({ api_key: getToken(), method }, urlParams)
    const finalRoute = customRoute || routing.generate(route, requestOptions)

    if (body) {
      fetchOptions.body = JSON.stringify(body)
      fetchOptions.headers = {
        ...fetchOptions.headers,
        'Content-Type': 'application/json'
      }
    }

    const response = await fetch(finalRoute, isEmpty(fetchOptions) ? undefined : fetchOptions)

    // Check for HTTP errors for sentry message
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}, url: ${response.url}`)
    }

    const parsedResponse = await response[responseParser]()
    dispatch(onSuccess(parsedResponse))
    if (!hasNextPage) dispatch(setRequestEnd(requestType, item))
    return parsedResponse
  } catch (error) {
    dispatch(setRequestFail(requestType, item))
    onError(error)
    throw error
  }
}

export const createAFetch = ({ getToken, uri }) =>
  createApolloFetch({ uri }).use((middlewares, next) => {
    middlewares.options.headers = {
      Authorization: getToken()
    }
    next()
  })

export const createFetchGraphqlWrapper = ({ getToken, uri }) => {
  return ({
    requestType,
    query,
    variables = {},
    onSuccess,
    onError = () => console.error(requestType),
    authorizingRole,
    overlay = true,
    fetchOptions = {}
  }) => {
    return (dispatch, getState) => {
      if (authorizingRole) {
        const isAuthorized = hasUserRole(getState(), authorizingRole)
        if (!isAuthorized) {
          return dispatch(requestNotAuthorizedByRole(requestType, authorizingRole))
        }
      }

      dispatch(setRequestStart(requestType, overlay))

      return new Promise((resolve, reject) => {
        fetch(uri, {
          method: 'POST',
          headers: {
            Authorization: getToken(),
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            query,
            variables
          }),
          ...fetchOptions
        })
          .then(response => response.json())
          .then(hasuraResponse => {
            if (hasuraResponse.errors) {
              dispatch(setRequestFail(requestType))

              onError(hasuraResponse.errors)
              return reject(hasuraResponse.errors)
            }

            resolve(hasuraResponse.data)
            dispatch(setRequestEnd(requestType))

            return dispatch(onSuccess(hasuraResponse.data))
          })
          .catch(fetchError => {
            dispatch(setRequestFail(requestType))
            onError(fetchError)

            return reject(fetchError)
          })
      })
    }
  }
}
