import moment from 'moment'
import { getToken } from '../userStoredData'
import { SATELLITE_TYPE, DRONE_TYPE, RADAR_DESC_TYPE, DRONE_RGB_TYPE, PLANET_TYPE, L9_TYPE } from '../constants'
import { applyTemporalEvolutionDateFilter } from './dashboard'
import { fetchRequestWrapper } from './ui'

export const REQUEST_FIELDS_WITH_STATISTICS = 'REQUEST_FIELDS_WITH_STATISTICS'
export const RECEIVE_FIELDS_WITH_STATISTICS = 'RECEIVE_FIELDS_WITH_STATISTICS'
export const FETCH_LATEST_STATISTIC_BY_FIELD_IDS = 'FETCH_LATEST_STATISTIC_BY_FIELD_IDS'
export const RECEIVE_ONE_STATISTIC_BY_FIELD_IDS = 'RECEIVE_ONE_STATISTIC_BY_FIELD_IDS'
export const REQUEST_STATISTICS_BY_FIELD_IDS = 'REQUEST_STATISTICS_BY_FIELD_IDS'
export const RECEIVE_STATISTICS_BY_FIELD_IDS = 'RECEIVE_STATISTICS_BY_FIELD_IDS'

const today = moment().format('YYYY-MM-DD')
const earliestAvailableDate = moment(1).format('YYYY-MM-DD')

const fetchStatisticByFieldIds = ({
  from = earliestAvailableDate,
  source,
  fieldIds,
  to = today,
  authorizingRole = null,
  overlay = true,
  latest = true,
  requestType = FETCH_LATEST_STATISTIC_BY_FIELD_IDS,
  returnType = RECEIVE_ONE_STATISTIC_BY_FIELD_IDS
}) => {
  const baseCustomRoute = `${window.location.origin}/athena/statistics`
  return fetchRequestWrapper({
    customRoute: `${baseCustomRoute}?${new URLSearchParams({ api_key: getToken() })}`,
    requestType,
    authorizingRole,
    fetchOptions: {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ from, to, source, api_key: getToken(), field_ids: fieldIds, latest })
    },
    overlay,
    onSuccess: data => {
      return { type: returnType, data: data.data }
    }
  })
}

export const fetchFieldComparisonStatistics = ({ fieldIds, isSatelliteTypeFlightFilterActive, fieldComparisonFetchDateRange }) => dispatch => {
  if (fieldIds.length === 0) {
    return
  }
  const sources = isSatelliteTypeFlightFilterActive ? [SATELLITE_TYPE, RADAR_DESC_TYPE, PLANET_TYPE, L9_TYPE] : [DRONE_TYPE, DRONE_RGB_TYPE]

  sources.forEach(source => {
    dispatch(
      fetchStatisticByFieldIds({
        from: fieldComparisonFetchDateRange.from,
        to: fieldComparisonFetchDateRange.to,
        source: Number(source),
        fieldIds,
        overlay: false
      })
    )
  })
}

export const fetchTemporalEvolutionStatistics = ({ fieldIds, from, to }) => dispatch => {
  if (fieldIds.length === 0) {
    return
  }

  const sources = [SATELLITE_TYPE, PLANET_TYPE, L9_TYPE, RADAR_DESC_TYPE]

  sources.forEach(source => {
    dispatch(
      fetchStatisticByFieldIds({
        from,
        to,
        source: Number(source),
        fieldIds,
        overlay: false,
        latest: false,
        requestType: REQUEST_STATISTICS_BY_FIELD_IDS,
        returnType: RECEIVE_STATISTICS_BY_FIELD_IDS
      })
    )
  })

  dispatch(applyTemporalEvolutionDateFilter({ from, to }))
}
