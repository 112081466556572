import React, { useEffect, useState } from 'react'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import { UIStyles } from '../../../theme'
import StyledTextInput from '../../UIComponents/StyledTextInput'

const inputStyle = { color: UIStyles.whiteColor }

function NameInput({ t, senderName, setSenderName, error }) {
  const [name, setName] = useState('')

  useEffect(() => setName(senderName), [senderName])

  return (
    <ToolWrapper>
      <ToolTitle>{t('Reported by')} *</ToolTitle>
      <StyledTextInput
        placeholder={t('Name')}
        onChange={setName}
        value={name}
        inputProps={{ style: inputStyle }}
        helperText={t(error)}
        onBlur={() => setSenderName(name)}
        margin="none"
      />
    </ToolWrapper>
  )
}

export default withTranslator(NameInput)
