import { RECEIVE_REPORT_STATUSES } from '../storeConstants'

const initialState = []

export default function reportsStatuses(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_REPORT_STATUSES:
      return action.statuses
    default:
      return state
  }
}
