import { captureScreenshot } from './utilities'
import { closeModal, openSendFieldsReportModal } from './modal'
// eslint-disable-next-line import/no-duplicates
import { errorNotification } from './notifications'
import approve from 'approvejs'
import { getFieldsReportModal, getFieldsReportModalErrors, getFieldsReportId } from '../selectors/fieldsReportModal'
import { getLocale } from '../selectors/i18n'
// eslint-disable-next-line import/no-duplicates
import { successNotification } from './notifications'
import { convert } from '@layers-frontend/commons/conversions'

import get from 'lodash/get'
import keys from 'lodash/keys'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import split from 'lodash/split'
import without from 'lodash/without'
import trim from 'lodash/trim'
import forEach from 'lodash/forEach'
import assign from 'lodash/assign'
import { fetchRequestWrapper } from './ui'
import { FIELDS_REPORT_KEYS } from '../constants'
import { APPLICATION_JSON_HEADER } from '@layers-frontend/commons/constants'
import { getUserConfigSurface } from '@layers-frontend/commons/store/selectors/user'

export const CAPTURE_SCREENSHOT_FOR_FIELDS_REPORT_MODAL_SUCCESS = 'CAPTURE_SCREENSHOT_FOR_FIELDS_REPORT_MODAL_SUCCESS'
export const SET_FIELDS_REPORT_MODAL_PROP = 'SET_FIELDS_REPORT_MODAL_PROP'
export const ADD_RECIPIENT_EMAIL_TO_FIELDS_REPORT_MODAL = 'ADD_RECIPIENT_EMAIL_TO_FIELDS_REPORT_MODAL'
export const REMOVE_RECIPIENT_EMAIL_FROM_FIELDS_REPORT_MODAL = 'REMOVE_RECIPIENT_EMAIL_FROM_FIELDS_REPORT_MODAL'
export const ADD_ERROR_TO_FIELDS_REPORT_MODAL = 'ADD_ERROR_TO_FIELDS_REPORT_MODAL'
export const CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL = 'CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL'
export const RESET_FIELDS_REPORT_MODAL = 'RESET_FIELDS_REPORT_MODAL'
export const SET_FIELDS_REPORT_MODAL_SENDER_NAME = 'SET_FIELDS_REPORT_MODAL_SENDER_NAME'
export const SET_FIELDS_REPORT_MODAL_AFFECTED_HA = 'SET_FIELDS_REPORT_MODAL_AFFECTED_HA'
export const SET_FIELDS_REPORT_MODAL_RELATED_DATE = 'SET_FIELDS_REPORT_MODAL_RELATED_DATE'
export const SET_FIELDS_REPORT_MODAL_NOTES = 'SET_FIELDS_REPORT_MODAL_NOTES'
export const SET_FIELDS_REPORT_MODAL_INFO = 'SET_FIELDS_REPORT_MODAL_INFO'
export const EDIT_REPORT = 'EDIT_REPORT'
export const SET_FIELDS_REPORT_MODAL_SELECTED_FIELDS_IDS = 'SET_FIELDS_REPORT_MODAL_SELECTED_FIELDS_IDS'
export const REQUEST_POST_FIELDS_REPORT = 'REQUEST_POST_FIELDS_REPORT'
export const REQUEST_PATCH_FIELDS_REPORT = 'REQUEST_PATCH_FIELDS_REPORT'

const {
  BASE_64_IMAGE,
  RECIPIENT_EMAIL_INPUT,
  RECIPIENT_EMAILS,
  REPORT_TYPE_ID,
  SENDER_NAME,
  SELECTED_FIELDS_IDS,
  NOTES,
  AFFECTED_HA,
  RELATED_DATE
} = FIELDS_REPORT_KEYS

const requestSubmitFieldsReportSuccess = () => dispatch => {
  dispatch(closeAndResetFieldsReportModal())
  dispatch(successNotification('Report sent correctly'))
}

export const requestSubmitFieldsReport = () => (dispatch, getState) => {
  const state = getState()
  const stateReport = getFieldsReportModal(state)
  const reportId = getFieldsReportId(state)
  const stateScreenshot = stateReport[BASE_64_IMAGE]
  const affectedHa = stateReport[AFFECTED_HA]
  const relatedDate = stateReport[RELATED_DATE]
  const surfaceUnit = getUserConfigSurface(state)
  const referer = reportId ? undefined : get(window, 'location.pathname', '')

  const reportToPost = assign(
    {},
    {
      locale: getLocale(state),
      // eslint-disable-next-line object-shorthand
      referer: referer,
      author: stateReport[SENDER_NAME],
      recipients: stateReport[RECIPIENT_EMAILS],
      notes: stateReport[NOTES],
      fields: stateReport[SELECTED_FIELDS_IDS],
      type: stateReport[REPORT_TYPE_ID],
      screenshot: stateScreenshot
    },
    affectedHa ? { affectedHa: convert(affectedHa, surfaceUnit, 'hectare') } : null,
    relatedDate ? { relatedDate } : null
  )
  return dispatch(reportId ? requestPatchFieldsReport(reportToPost, reportId) : requestPostFieldsReport(reportToPost))
}

const requestPostFieldsReport = reportToPost =>
  fetchRequestWrapper({
    route: 'post_reports',
    requestType: REQUEST_POST_FIELDS_REPORT,
    onSuccess: requestSubmitFieldsReportSuccess,
    onError: () => dispatch => {
      dispatch(closeAndResetFieldsReportModal())
      dispatch(errorNotification('An error occured from the server. Please try again'))
    },
    fetchOptions: {
      method: 'POST',
      headers: APPLICATION_JSON_HEADER,
      body: JSON.stringify(reportToPost)
    }
  })

const requestPatchFieldsReport = (reportToPost, reportId) =>
  fetchRequestWrapper({
    route: 'patch_reports',
    requestType: REQUEST_PATCH_FIELDS_REPORT,
    urlParams: { id: reportId },
    onSuccess: requestSubmitFieldsReportSuccess,
    onError: () => dispatch => {
      dispatch(closeAndResetFieldsReportModal())
      dispatch(errorNotification('An error occured from the server. Please try again'))
    },
    fetchOptions: {
      method: 'PATCH',
      headers: APPLICATION_JSON_HEADER,
      body: JSON.stringify(reportToPost)
    }
  })

const setScreenshotForFieldsReportModal = base64Image => ({ type: CAPTURE_SCREENSHOT_FOR_FIELDS_REPORT_MODAL_SUCCESS, [BASE_64_IMAGE]: base64Image })

export const removeScreenshotForFieldsReportModal = () => setScreenshotForFieldsReportModal(null)

const captureScreenshotForFieldsReportModalSuccess = base64Image => dispatch => {
  dispatch(setScreenshotForFieldsReportModal(base64Image))
  dispatch(openSendFieldsReportModal())
}

const captureScreenshotForFieldsReportModalError = () => dispatch => {
  dispatch(errorNotification('Not supported by browser'))

  dispatch(openSendFieldsReportModal())
}

export const captureScreenshotForFieldsReportModal = () => dispatch => {
  dispatch(closeModal())
  return captureScreenshot({
    onScreenshotSuccess: captureScreenshotForFieldsReportModalSuccess,
    onScreenshotError: captureScreenshotForFieldsReportModalError
  })(dispatch)
}

const setFieldsReportModalProp = (prop, value) => ({
  type: SET_FIELDS_REPORT_MODAL_PROP,
  prop,
  value
})

const withValidation = ({ pathToValue, validationConfig = {}, onSuccess }) => (dispatch, getState) => {
  const fielsReportModalState = getFieldsReportModal(getState())
  const value = get(fielsReportModalState, pathToValue)
  const validation = approve.value(value, validationConfig)

  if (validation.approved) {
    dispatch({ type: CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL, pathToError: pathToValue })
    if (onSuccess) {
      dispatch(onSuccess(value))
    }
    return
  }

  const error = get(validation, 'errors[0]')
  dispatch({
    type: ADD_ERROR_TO_FIELDS_REPORT_MODAL,
    pathToError: pathToValue,
    value,
    error
  })
}

const withEmailsValidation = ({ pathToValue, validationConfig = {}, onSuccess }) => (dispatch, getState) => {
  dispatch({ type: CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL, pathToError: pathToValue })

  const fieldsReportModalState = getFieldsReportModal(getState())
  const values = get(fieldsReportModalState, pathToValue)

  const validations = map(values, currentValue => approve.value(currentValue, validationConfig))
  forEach(validations, (validation, index) => {
    if (validation.approved) {
      if (onSuccess) {
        dispatch(onSuccess(values[index]))
      }
      return
    }

    const error = get(validation, 'errors[0]')

    dispatch({
      type: ADD_ERROR_TO_FIELDS_REPORT_MODAL,
      pathToError: pathToValue,
      value: values[index],
      error
    })
  })
}

export const addRecipientEmailToFieldsReportModal = () =>
  withEmailsValidation({
    pathToValue: RECIPIENT_EMAIL_INPUT,
    validationConfig: {
      email: {
        message: 'Invalid email'
      }
    },
    onSuccess: value => dispatch => {
      dispatch({
        type: ADD_RECIPIENT_EMAIL_TO_FIELDS_REPORT_MODAL,
        [RECIPIENT_EMAIL_INPUT]: value
      })
    }
  })

export const removeRecipientEmailFromFieldsReportModal = emailToRemove => ({
  type: REMOVE_RECIPIENT_EMAIL_FROM_FIELDS_REPORT_MODAL,
  emailToRemove
})

export const setRecipientEmailInputToFieldsReportModal = value => {
  const mailsArray = map(without(split(value, /[,;]/), ''), value => trim(value))
  return setFieldsReportModalProp(RECIPIENT_EMAIL_INPUT, mailsArray)
}

export const setReportTypeIdForFieldsReportModal = id => setFieldsReportModalProp(REPORT_TYPE_ID, Number(id))

export const resetFieldsReportModal = () => ({ type: RESET_FIELDS_REPORT_MODAL })

export const closeAndResetFieldsReportModal = () => dispatch => {
  dispatch(resetFieldsReportModal())
  return dispatch(closeModal())
}

export const editReport = activeRowOriginal => dispatch => {
  dispatch(openSendFieldsReportModal())
  dispatch(setFieldsReportModalInfo(activeRowOriginal))
}

export const setFieldsReportModalSenderName = name => ({
  type: SET_FIELDS_REPORT_MODAL_SENDER_NAME,
  [SENDER_NAME]: name
})

export const setFieldsReportModalAffectedHa = ha => ({
  type: SET_FIELDS_REPORT_MODAL_AFFECTED_HA,
  [AFFECTED_HA]: ha
})

export const setFieldsReportModalRelatedDate = date => ({
  type: SET_FIELDS_REPORT_MODAL_RELATED_DATE,
  [RELATED_DATE]: date
})

export const setFieldsReportModalSelectedFieldsIds = (fieldIds = []) => ({ type: SET_FIELDS_REPORT_MODAL_SELECTED_FIELDS_IDS, [SELECTED_FIELDS_IDS]: fieldIds })

export const setFieldsReportModalNotes = notes => ({
  type: SET_FIELDS_REPORT_MODAL_NOTES,
  [NOTES]: notes
})

export const setFieldsReportModalInfo = info => ({
  type: SET_FIELDS_REPORT_MODAL_INFO,
  info
})

const notNullValidation = {
  required: {
    required: true,
    message: 'Required'
  }
}

const notNullValidationWithMessage = message => ({
  required: {
    required: true,
    message
  }
})

const sendFieldsReportValidations = {
  [SELECTED_FIELDS_IDS]: notNullValidationWithMessage('select at least one field'),
  [REPORT_TYPE_ID]: notNullValidation,
  [RELATED_DATE]: notNullValidation,
  [NOTES]: {
    min: {
      min: 1,
      message: 'Required'
    }
  },
  [SENDER_NAME]: {
    min: {
      min: 3,
      message: 'Requires 3 characters minimun'
    }
  }
}

export const validateAndSendFieldsReport = () => (dispatch, getState) => {
  const validationKeys = keys(sendFieldsReportValidations)

  forEach(validationKeys, keyToValidate =>
    dispatch(
      withValidation({
        pathToValue: keyToValidate,
        validationConfig: sendFieldsReportValidations[keyToValidate]
      })
    )
  )

  const errors = getFieldsReportModalErrors(getState())
  if (isEmpty(errors)) {
    dispatch(requestSubmitFieldsReport())
  }
}
