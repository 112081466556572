import React from 'react'
import PropTypes from 'prop-types'
import { ToolBarContainer, MeasuresBarContainer } from '../../containers/ToolsContainers/ToolsContainers'
import styled from 'styled-components'
import GlobalLayersButtonContainer from '../../containers/ToolsContainers/GlobalLayersButtonContainer'

const AppButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default class AppButtons extends React.Component {
  render() {
    if (this.props.visible)
      return (
        <AppButtonsWrapper>
          <MeasuresBarContainer />
          <ToolBarContainer />
          <GlobalLayersButtonContainer />
        </AppButtonsWrapper>
      )
    return null
  }
}

AppButtons.propTypes = {
  visible: PropTypes.bool
}
