import React from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, LineChart, ReferenceLine, ReferenceDot } from 'recharts'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import without from 'lodash/without'
import assign from 'lodash/assign'
import some from 'lodash/some'
import includes from 'lodash/includes'
import reduce from 'lodash/reduce'
import keys from 'lodash/keys'
import last from 'lodash/last'
import map from 'lodash/map'
import round from 'lodash/round'
import regression from 'regression'
import styled from 'styled-components'
import moment from 'moment'
import { UIStyles } from '../../theme'

import { TitleH1, BoxContainer, ComponentWrapper } from './Layout'

import { REQUEST_FIELD_PLANTS } from '../../actions/plants'
import { DASHBOARD_FILTERS_TYPES } from '../../actions/dashboard'
import Loader from '../Loader'
import TableMinMax from './Tables/TableMinMax'
import { colorArray } from './colorArray'

const { HARVEST_PLANNING_TOOL_FILTER_TYPE_SELECT, HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT, HARVEST_PLANNING_TOOL_FIELDS_SELECT } = DASHBOARD_FILTERS_TYPES

const LeftSection = styled.div`
  width: 25%;
`
const RightSection = styled.div`
  width: 75%;
  margin: 0 20px;
`

const MIN_RANGE_DEFAULT_VALUE = 38
const MAX_RANGE_DEFAULT_VALUE = 60

const formatterLabelBugWithFormatterStyleFix = {
  display: 'none'
}

export const Title = styled.h2`
  color: ${UIStyles.lightBlue};
  background-color: ${UIStyles.darkBlue};
  text-align: left;
  font-family: 'Roboto';
  font-weight: normal;
  margin: 20px;
  margin-bottom: -20px;
  width: 100%;
  padding: 15px 20px;
  font-size: 24px;
`

export const unixDateToDate = unixTimestamp => moment(Math.floor(unixTimestamp) * 1000).format('D MMM YY')

export default class HarvestPlanningTool extends React.Component {
  componentDidMount() {
    const SUGAR_CANE_TYPE = 56
    const { fieldsIdsMap } = this.props
    const hasSugarCaneFields = some(fieldsIdsMap, field => get(field, 'season.type.id') === SUGAR_CANE_TYPE)

    if (!hasSugarCaneFields) {
      this.props.toggleDashFilter(HARVEST_PLANNING_TOOL_FILTER_TYPE_SELECT, 'dryMatterLaboratory')
      this.props.toggleDashFilter(HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT, 'dryMatterLaboratory')
    }

    if (isUndefined(this.props.selectedMetadataKey) && isEmpty(this.props.plants)) this.props.fetchPlants()
  }

  setRegressionData() {
    const { fieldsPlants, selectedFieldsIds, rangeMin = MIN_RANGE_DEFAULT_VALUE, rangeMax = MAX_RANGE_DEFAULT_VALUE, selectedMetadataKey } = this.props

    const regressionData = reduce(
      selectedFieldsIds,
      (accumulator, fieldId, index) => {
        const sampleValues = map(fieldsPlants[fieldId], plant => {
          return [Number(moment(plant.sample_creation_date).unix()), Number(get(plant, selectedMetadataKey).replace(',', '.'))]
        })

        const regressionModel = regression.linear(sampleValues, { precision: 10 })
        const firstTimestamp = sampleValues[0][0]
        // eslint-disable-next-line
        const [_, lineFirstValue] = regressionModel.predict(firstTimestamp)

        // f(x) = m * x + c
        const [m, c] = regressionModel.equation

        const getXandYFromX = y => {
          // f(x) = m * x + c
          const x = (y - c) / m
          return [Math.floor(x), y]
        }

        return {
          ...accumulator,
          [fieldId]: {
            sampleValues,
            lineValues: assign(
              {},
              { first: [firstTimestamp, lineFirstValue] },
              rangeMin && { min: getXandYFromX(rangeMin) },
              rangeMax && { max: getXandYFromX(rangeMax) },
              { last: getXandYFromX(rangeMax + 5) }
              // { today: regressionModel.predict(today) }
            ),
            color: colorArray[index]
          }
        }
      },
      {}
    )

    return regressionData
  }

  render() {
    const {
      fieldsPlants,
      selectedFieldsIds,
      toggleDashFilter,
      rangeMin = MIN_RANGE_DEFAULT_VALUE,
      rangeMax = MAX_RANGE_DEFAULT_VALUE,
      selectedMetadataKey,
      t
    } = this.props

    const firstChartData = map(keys(fieldsPlants), (fieldId, index) => {
      return {
        fieldId,
        color: colorArray[index],
        [`${fieldId}_value`]: get(last(fieldsPlants[fieldId]), selectedMetadataKey),
        value: get(last(fieldsPlants[fieldId]), selectedMetadataKey).replace(',', '.'),
        index
      }
    })

    const regressionData = this.setRegressionData()

    const lineData = reduce(
      keys(regressionData),
      (accmmulator, fieldId) => {
        const generateData = dataToGet => ({
          [`${selectedMetadataKey}_${fieldId}`]: regressionData[fieldId].lineValues[dataToGet][1],
          date: regressionData[fieldId].lineValues[dataToGet][0],
          color: regressionData[fieldId].color,
          fieldName: get(this.props, `fieldsIdsMap[${fieldId}].name`)
        })

        const firstData = generateData('first')
        const minData = generateData('min')
        const maxData = generateData('max')
        const lastData = generateData('last')

        return [...accmmulator, firstData, minData, maxData, lastData]
      },
      []
    )

    const dotData = reduce(
      keys(regressionData),
      (accumulator, fieldId) => {
        return [
          ...accumulator,
          ...regressionData[fieldId].sampleValues.map(values => {
            return {
              x: values[0],
              y: values[1],
              color: regressionData[fieldId].color
            }
          })
        ]
      },
      []
    )

    const handleBarClick = ({ fieldId }) => {
      if (includes(selectedFieldsIds, fieldId)) {
        return toggleDashFilter(HARVEST_PLANNING_TOOL_FIELDS_SELECT, without(selectedFieldsIds, fieldId))
      }
      return toggleDashFilter(HARVEST_PLANNING_TOOL_FIELDS_SELECT, [...selectedFieldsIds, fieldId])
    }

    const tableMinMaxData = map(selectedFieldsIds, fieldId => {
      const fieldName = get(this.props, `fieldsIdsMap[${fieldId}].name`)
      const minDate = unixDateToDate(get(regressionData, `${fieldId}.lineValues.min[0]`))
      const maxDate = unixDateToDate(get(regressionData, `${fieldId}.lineValues.max[0]`))
      // const today = get(regressionData, `${fieldId}.lineValues.today[1]`)
      const color = get(regressionData, `${fieldId}.color`)

      return { fieldName, minDate, maxDate, color }
    })

    const harvestPlanningCharts = (
      <>
        <LeftSection>
          <Title>{t('last sample')}</Title>
          <BoxContainer borderNone height="300px" width="100%" margin="20px" padding="20px 0">
            <ResponsiveContainer width="95%" height="100%">
              <BarChart fill={UIStyles.lightBlue} data={firstChartData}>
                <CartesianGrid strokeDasharray="3 3" stroke={UIStyles.lightBlue} fill="rgba(255,255,255,0.08)" />
                <XAxis stroke="#ffffff" strokeWidth="2" tick={false} dataKey="index" />
                <YAxis stroke="#ffffff" strokeWidth="2" />
                <Tooltip
                  labelStyle={formatterLabelBugWithFormatterStyleFix}
                  formatter={(value, _, props) => {
                    const fieldId = get(props, 'payload.fieldId')
                    const fieldName = get(this.props, `fieldsIdsMap[${fieldId}].name`)

                    return [`${fieldName}: ` + value]
                  }}
                />
                <Bar dataKey="value" onClick={handleBarClick}>
                  {firstChartData.map(entry => {
                    return <Cell key={entry.fieldId} stroke={entry.color} fill={entry.color} />
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </BoxContainer>
          <TableMinMax title={'planificacion de cosecha'} rangeMin={rangeMin} rangeMax={rangeMax} data={tableMinMaxData} />
        </LeftSection>
        <RightSection>
          <Title>{t('prediction')}</Title>
          <BoxContainer borderNone height="80vh" width="100%" margin="20px" padding="20px 0">
            <ResponsiveContainer width="95%">
              <LineChart data={lineData}>
                <CartesianGrid strokeDasharray="3 3" stroke={UIStyles.lightBlue} fill="rgba(255,255,255,0.08)" />
                <XAxis
                  domain={[get(lineData, '[0].date'), get(lineData, '[1].date')]}
                  stroke="#ffffff"
                  strokeWidth="2"
                  dataKey="date"
                  type="number"
                  tickFormatter={date => unixDateToDate(date)}
                />
                <YAxis stroke="#ffffff" strokeWidth="2" domain={[0, rangeMax + 5]} tickFormatter={value => value + '%'} />
                <Tooltip
                  labelStyle={formatterLabelBugWithFormatterStyleFix}
                  formatter={(value, label, props) => {
                    const date = unixDateToDate(get(props, 'payload.date'))
                    const fieldName = get(props, 'payload.fieldName')
                    return [date, `${fieldName} - ${t(selectedMetadataKey)}: ${round(value, 2)}%`]
                  }}
                />
                {map(keys(regressionData), fieldId => {
                  const currentItem = regressionData[fieldId]
                  return <Line key={fieldId} type="linear" dataKey={`${t(selectedMetadataKey)}_${fieldId}`} stroke={currentItem.color} strokeWidth={2} />
                })}

                {dotData.map((item, index) => {
                  return <ReferenceDot key={index} x={item.x} y={item.y} r={4} strokeWidth={2} stroke={item.color} fill={item.color} />
                })}
                <ReferenceLine y={rangeMin} stroke="green" strokeWidth={3} />
                <ReferenceLine y={rangeMax} stroke="red" strokeWidth={3} />
              </LineChart>
            </ResponsiveContainer>
          </BoxContainer>
        </RightSection>
      </>
    )

    if (!selectedMetadataKey) {
      return (
        <ComponentWrapper>
          <Loader requests={REQUEST_FIELD_PLANTS} />
          <TitleH1>{t('select a crop and sample')}</TitleH1>
        </ComponentWrapper>
      )
    }

    return (
      <ComponentWrapper>
        <Loader requests={REQUEST_FIELD_PLANTS} />
        {isEmpty(fieldsPlants) ? <TitleH1>{t('not enough data to display this chart')}</TitleH1> : harvestPlanningCharts}
      </ComponentWrapper>
    )
  }
}
