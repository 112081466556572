import React from 'react'
import { CheckboxDashboard as Checkbox } from '../UIComponents/Form'
import FieldsComparisonDateRangeSelect from './DashboadFilters/FieldsComparisonDateRangeSelect'
import { DASHBOARD_FILTERS_TYPES } from '../../actions/dashboard'
import styled from 'styled-components'
import StyledSelect from '../UIComponents/StyledSelect'
import { UIStyles } from '../../theme'

import map from 'lodash/map'
import get from 'lodash/get'
import TemporalEvolutionMetadataDateRangeSelectContainer from '../../containers/DashboardContainers/DashboadFiltersContainers/TemporalEvolutionMetadataDateRangeSelectContainer'
import HarvestPlanningToolCropTypeSelectContainer from '../../containers/DashboardContainers/DashboadFiltersContainers/HarvestPlanningToolCropTypeSelectContainer'
import StyledFloatInput from '../UIComponents/StyledFloatInput'
import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`

export const ModuleWrapper = styled.div`
  width: calc(100% - 364px - 50px - 168px);
  display: flex;
  align-items: center;
  padding-left: 20px;
  .MuiInputLabel-root {
    font-weight: 600;
  }
  .MuiInput-input {
    color: ${UIStyles.vomitColor};
  }
`

export default class FilterModule extends React.Component {
  render() {
    const {
      t,
      activeFilters,
      form,
      toggleDashFilter,
      selectedHarvesPlanningToolFieldsIds,
      harvestPlanningToolFields,
      harvestPlanningToolDryMatterRangeMin,
      harvestPlanningToolDryMatterRangeMax
    } = this.props

    const {
      FLIGHT_DRONE,
      FLIGHT_SATELLITE,
      DATE_RANGE,
      TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT,
      HARVEST_PLANNING_TOOL_CROP_TYPE_SELECT,
      HARVEST_PLANNING_TOOL_FIELDS_SELECT,
      HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN,
      HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX,
      FIELDS_COMPARISON_DATE_RANGE_SELECT
    } = DASHBOARD_FILTERS_TYPES

    const filters = {
      [FLIGHT_DRONE]: (
        <Checkbox
          style={{ marginTop: '0', marginLeft: '5px' }}
          value={form[FLIGHT_DRONE].value}
          icon="flaticon-drone"
          label="dron"
          onCheck={value => {
            if (value) trackEvent(TRACK_EVENTS.DASHBOARD_SOURCE_SELECTED, { source: 'DRONE' })
            return toggleDashFilter(FLIGHT_DRONE, value)
          }}
        />
      ),
      [FLIGHT_SATELLITE]: (
        <Checkbox
          style={{ marginTop: '0', marginLeft: '5px' }}
          value={form[FLIGHT_SATELLITE].value}
          label="satélite"
          icon="fa-solid fa-satellite"
          onCheck={value => {
            if (value) trackEvent(TRACK_EVENTS.DASHBOARD_SOURCE_SELECTED, { source: 'SATELLITE' })
            return toggleDashFilter(FLIGHT_SATELLITE, value)
          }}
        />
      ),
      [DATE_RANGE]: <FieldsComparisonDateRangeSelect weekSelected={true} onChange={toggleDashFilter} />, // probably not used, refactored to reuse the current date range component, possible test case : maybe when the drone image is available in the current week
      [TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT]: <TemporalEvolutionMetadataDateRangeSelectContainer />,
      [HARVEST_PLANNING_TOOL_CROP_TYPE_SELECT]: <HarvestPlanningToolCropTypeSelectContainer />,
      [HARVEST_PLANNING_TOOL_FIELDS_SELECT]: (
        <StyledSelect
          placeholder={t('fields')}
          value={selectedHarvesPlanningToolFieldsIds}
          onChange={selectedIds => toggleDashFilter(HARVEST_PLANNING_TOOL_FIELDS_SELECT, selectedIds)}
          options={harvestPlanningToolFields}
          disabled={get(harvestPlanningToolFields, 'length') === 1}
        />
      ),
      [HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN]: (
        <StyledFloatInput
          label="Min"
          minValue="0"
          maxValue="100"
          onChange={text => toggleDashFilter(HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN, text)}
          value={harvestPlanningToolDryMatterRangeMin}
        />
      ),
      [HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX]: (
        <StyledFloatInput
          label="Max"
          minValue="0"
          maxValue="100"
          onChange={text => toggleDashFilter(HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX, text)}
          value={harvestPlanningToolDryMatterRangeMax}
        />
      ),
      [FIELDS_COMPARISON_DATE_RANGE_SELECT]: (
        <FieldsComparisonDateRangeSelect
          from={form[FIELDS_COMPARISON_DATE_RANGE_SELECT].value.from}
          to={form[FIELDS_COMPARISON_DATE_RANGE_SELECT].value.to}
          onChange={toggleDashFilter}
          filterId={FIELDS_COMPARISON_DATE_RANGE_SELECT}
          isVisible={true}
        />
      )
    }

    return (
      <ModuleWrapper>
        {map(activeFilters, filterId => {
          return <FilterWrapper key={filterId}>{filters[filterId]}</FilterWrapper>
        })}
      </ModuleWrapper>
    )
  }
}
