import { SATELLITE_TYPE, DRONE_TYPE, RADAR_DESC_TYPE, DRONE_RGB_TYPE, PLANET_TYPE, L9_TYPE } from '../constants'
import { RECEIVE_ONE_STATISTIC_BY_FIELD_IDS, RECEIVE_STATISTICS_BY_FIELD_IDS } from '../actions/statistics'
import { FLUSH_FIELDS_GROUP } from '../actions/fields'
import moment from 'moment'
import get from 'lodash/get'
import reduce from 'lodash/reduce'
import map from 'lodash/map'
import { sigma0ToVegGrowth, percentifyMetadataProperties } from '../dashboardUtils'

const fieldComparisonStatisticsInitialState = {
  [SATELLITE_TYPE]: [],
  [DRONE_TYPE]: [],
  [RADAR_DESC_TYPE]: [],
  [DRONE_RGB_TYPE]: [],
  normalized: {}
}

export function fieldComparisonStatistics(state = fieldComparisonStatisticsInitialState, action = {}) {
  switch (action.type) {
    case RECEIVE_ONE_STATISTIC_BY_FIELD_IDS: {
      if (action.data === null || action.data.length === 0) {
        return state
      }
      let statistics = action.data

      statistics = map(statistics, item => {
        if (item.source.toString() === RADAR_DESC_TYPE)
          return {
            ...item,
            properties: {
              ...item,
              mean_sigma0: sigma0ToVegGrowth(item.mean_sigma0)
            }
          }
        else
          return {
            ...item,
            properties: percentifyMetadataProperties(item)
          }
      })

      return {
        ...state,
        [statistics[0].source]: statistics
      }
    }
    case FLUSH_FIELDS_GROUP:
      return fieldComparisonStatisticsInitialState

    default:
      return state
  }
}

const temporalEvolutionStatisticsInitialState = {
  [SATELLITE_TYPE]: [],
  [RADAR_DESC_TYPE]: [],
  [PLANET_TYPE]: [],
  [L9_TYPE]: [],
  normalized: {}
}

/**
 * Group statistics data by source.
 * @param {Array} data - The statistics data to be grouped.
 * @returns {Object} - The grouped data object where keys are sources and values are arrays of items.
 */
function groupBySource(data) {
  const groupedData = {}
  data.forEach(item => {
    const source = item.source
    if (!groupedData[source]) {
      groupedData[source] = []
    }
    // Modify ndvi_mean if source is PLANET_TYPE (10)
    // https://gitlab.com/hemav-software/layers-frontend/-/issues/863#note_1856737502
    if (source === +PLANET_TYPE) {
      const modifiedItem = { ...item }
      modifiedItem.ndvi_mean -= 0.1 // Subtract 0.1 from ndvi_mean provided by agro team
      groupedData[source].push(modifiedItem)
    } else groupedData[source].push(item)
  })
  return groupedData
}

/**
 * Reducer function for managing temporal evolution statistics state.
 */

export function temporalEvolutionStatistics(state = temporalEvolutionStatisticsInitialState, action = {}) {
  switch (action.type) {
    case RECEIVE_STATISTICS_BY_FIELD_IDS: {
      const statistics = action.data
      const previousNormalizedStatistics = get(state, 'normalized', {})

      if (statistics.length !== 0) {
        const groupedBySource = groupBySource(statistics)

        const normalizedStatistics = reduce(
          statistics,
          (accumulator, data) => {
            const source = get(data, 'source')
            const fieldId = get(data, 'field_id')
            const previousSourceData = get(accumulator, source, {})
            const previousFieldData = get(previousSourceData, fieldId, {})
            const currentDate = moment(get(data, 'flight_date')).format('YYYYMMDD')

            return {
              ...accumulator,
              [source]: {
                ...previousSourceData,
                [fieldId]: {
                  ...previousFieldData,
                  [currentDate]: data
                }
              }
            }
          },
          {}
        )

        return {
          ...state,
          ...groupedBySource,
          normalized: {
            ...previousNormalizedStatistics,
            ...normalizedStatistics
          }
        }
      }
      return state
    }
    case FLUSH_FIELDS_GROUP:
      return temporalEvolutionStatisticsInitialState

    default:
      return state
  }
}
