import { fetchGraphqlWrapper, setSeasonLayers } from './ui'
import { USER_ROLES } from '../constants'
import { loader } from 'graphql.macro'
import { getActiveSeasonLayers, getLayersSeasonsIds } from '../selectors/seasonLayers'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'

export const REQUEST_SEASON_LAYERS = 'REQUEST_SEASON_LAYERS'
export const RECEIVE_SEASON_LAYERS = 'RECEIVE_SEASON_LAYERS'

const getSeasonLayersById = loader('../graphql/seasonLayers/getSeasonLayersById.gql').loc.source.body

export const fetchSeasonLayersBySeasonIds = seasonIds => async (dispatch, getState) => {
  const state = getState()
  const existingIds = getLayersSeasonsIds(state)
  const idsToFetch = difference(seasonIds, existingIds)
  await (!isEmpty(idsToFetch) &&
    dispatch(
      fetchGraphqlWrapper({
        authorizingRole: USER_ROLES.ROLE_SEASON_LAYERS,
        requestType: REQUEST_SEASON_LAYERS,
        variables: { fieldSeasonIds: idsToFetch },
        query: getSeasonLayersById,
        onSuccess: ({ seasonLayers }) => ({ type: RECEIVE_SEASON_LAYERS, seasonLayers }),
        overlay: false
      })
    ))
  const uiSeasonLayers = await getActiveSeasonLayers(getState())
  await dispatch(setSeasonLayers(uiSeasonLayers))
}
