import React from 'react'
import { withAuthentication } from '../HOCComponents/withAuthentication'
import AdvancedFilterContainer from '../../containers/SearchContainers/AdvancedFilterContainer'
import { GroupItem } from './GroupItem'
import { MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS, TRACK_EVENTS, advancedSearch } from '../../constants'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'
import { ROLE_AGRO, ROLE_COOPERATIVE } from '../../actions/users'
import slice from 'lodash/slice'
import filter from 'lodash/filter'
import map from 'lodash/map'
import get from 'lodash/get'
import { trackEvent } from '../analytics/analytics'

const { TYPE_FILTER, PLANTATION_RANGE_FILTER, CUSTOMER_FILTER, CUT_NUMBER_FILTER, SEASON_LABEL_FILTER } = advancedSearch
const CustomerFilter = withAuthentication([ROLE_AGRO, ROLE_COOPERATIVE])(AdvancedFilterContainer)

export default function SearchBarFilters({
  addFieldsToGroup,
  currentLocation,
  fields,
  filters,
  flushFieldsGroup,
  hasCutNumber,
  hasSeasonLabel,
  hasFilters,
  isAdvancedSearch,
  overlay,
  resetCropFilters,
  resetCustomerFilters,
  resetCutNumberFilters,
  resetPlantationDateFilters,
  resetSeasonLabelFilters,
  selectedFields,
  setCropFilters,
  setCustomerFilters,
  setCutNumberFilters,
  setSearchBarListMode,
  searchBarListMode,
  setSeasonLabelFilters,
  sortedBy,
  t,
  toggleSearchSortBy,
  totalFieldCount
}) {
  const toggleFieldsGroup = fields => {
    const fieldsIds = map(fields, 'id')
    if (selectedFields.length !== fields.length && currentLocation.isViewer) return addFieldsToGroup(fieldsIds)

    if (selectedFields.length !== MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS && !currentLocation.isViewer) {
      const availableUnselectedFields = filter(fields, field => !field.isSelected && field.isAvailable)
      const fieldsToSelect = slice(availableUnselectedFields, 0, MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS - selectedFields.length)
      const fieldsIdsToSelect = map(fieldsToSelect, 'id')
      return addFieldsToGroup(fieldsIdsToSelect)
    }
    return flushFieldsGroup()
  }

  const fieldsLength = get(fields, 'length')
  const totalFields = fieldsLength >= CHUNK_SIZE ? totalFieldCount : fieldsLength

  const formatter = number => number + ' ' + (hasFilters ? t('filtered field' + (number !== 1 ? 's' : '')) : t('field' + (number !== 1 ? 's' : '')))

  const formattedFieldCount = formatter(totalFields)

  const selectAllToggleLabel = selectedFields.length === totalFields && selectedFields.length > 0 ? 'Deselect' : 'Select all'

  const selectTemporalEvolutionToggleLabel =
    selectedFields.length === MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS ? 'Deselect' : `${t('Select')}  ${MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS}`

  const handleCropsFilter = id => {
    setCropFilters(id)
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_CROP_FILTER, { action: 'filter', filter: 'crops', value: id })
  }

  const handleAllFields = fields => {
    toggleFieldsGroup(fields)
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_SELECT_ALL_FIELDS, { action: 'Select fields', value: selectAllToggleLabel })
  }

  return (
    <>
      <div className={'advancedSearch ' + (overlay ? 'overlaySearch ' : ' ') + (isAdvancedSearch ? 'open' : '')}>
        <AdvancedFilterContainer
          name="type"
          icon="icon-plant"
          filterType={TYPE_FILTER}
          onSelect={handleCropsFilter}
          onReset={resetCropFilters}
          testId="crop-filter-button"
        />

        {hasCutNumber ? (
          <AdvancedFilterContainer name="cut" filterType={CUT_NUMBER_FILTER} onSelect={setCutNumberFilters} icon="icon-cut" onReset={resetCutNumberFilters} />
        ) : null}

        <AdvancedFilterContainer
          name="plantation date"
          icon="icon-daterange"
          filterType={PLANTATION_RANGE_FILTER}
          onReset={resetPlantationDateFilters}
          testId="plantation-date-filter-button"
        />

        <CustomerFilter
          name="customers"
          filterType={CUSTOMER_FILTER}
          icon="icon-customer"
          onSelect={setCustomerFilters}
          onReset={resetCustomerFilters}
          testId="customer-filter-button"
        />

        {hasSeasonLabel ? (
          <AdvancedFilterContainer
            name="Season label"
            filterType={SEASON_LABEL_FILTER}
            onSelect={setSeasonLabelFilters}
            icon="fa-solid fa-hashtag"
            onReset={resetSeasonLabelFilters}
          />
        ) : null}
      </div>
      <GroupItem
        title={formattedFieldCount}
        type={filters[TYPE_FILTER]}
        plantation_year={filters[PLANTATION_RANGE_FILTER]}
        customer={filters[CUSTOMER_FILTER]}
        onHandleClick={() => handleAllFields(fields)}
        toggleSearchSortBy={toggleSearchSortBy}
        sortedBy={sortedBy}
        selectAllToggleLabel={!currentLocation.isViewer ? selectTemporalEvolutionToggleLabel : selectAllToggleLabel}
        searchBarListMode={searchBarListMode}
        setSearchBarListMode={setSearchBarListMode}
      />
    </>
  )
}
