import { connect } from 'react-redux'
import { compose } from 'redux'
import { getConfigProjection } from '../../selectors/config'
import { isSearchView } from '../../selectors/viewmode'
import GlobalLayers from '../../components/LayersComponents/GlobalLayers'
import { getSelectedFieldsIds, getGeometries } from '../../selectors/fields'
import { getIsGlobalLayerLegendOpen, getSelectedLayerLegend, getHighlightedCategory } from '../../selectors/globalLayer'
import { getFieldsFilteredByView } from '../../selectors/availableFields'
import { fetchGlobalLayerLegendByTypeId, setFilteredFieldIds } from '../../actions/globalLayer'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => {
  const filteredByEverything = getFieldsFilteredByView({ fieldsWithAvailableAndSelected, filterAvailableAndSelected: false })(state)
  const geometries = getGeometries(state)
  const isFilterable = CHUNK_SIZE > geometries.length
  return {
    projection: getConfigProjection(state),
    isSearchView: isSearchView(state),
    selectedFieldIds: getSelectedFieldsIds(state),
    isGlobalLayerLegendOpen: getIsGlobalLayerLegendOpen(state),
    selectedLayerLegend: getSelectedLayerLegend(state),
    highlightedCategory: getHighlightedCategory(state),
    filteredFeatures: state.globalLayer.filteredFeatures,
    filteredByEverything,
    isFilterable
  }
}

const mapDispatchToProps = {
  fetchGlobalLayerLegendByTypeId,
  setFilteredFieldIds
}

export default compose(withFieldsAvailableAndSelected, connect(mapStateToProps, mapDispatchToProps))(GlobalLayers)
