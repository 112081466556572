import React, { useState, useEffect } from 'react'
import SampleInfoImage from './SampleInfoImage'
import RenderIf from '@layers-frontend/commons/helpers/RenderIf'
import withTranslator from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import moment from 'moment'
import get from 'lodash/get'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import { UIStyles } from '../../theme'
import { Divider, ListItem } from '@mui/material'
import ListCollapse from '../UIComponents/ListCollapse'
import { StyledTooltip } from '../UIComponents/StyledTooltip'
import truncate from 'lodash/truncate'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  padding: 1.5rem;
`
const HeaderInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const IdInfo = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const NotesHeader = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const NotesData = styled.p``
const Spacer = styled.div`
  size: ${props => {
    switch (props.size) {
      case 'small':
        return '1rem'
      case 'medium':
        return '2rem'
      default:
        return '3rem'
    }
  }};
`
const NotesWrapper = styled.div``
const ExtraParamsWrapper = styled.div``
const ExtraParamsDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    margin: 0;
  }
`
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const ImageHeader = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const StyledButton = styled.button`
  border: 0;
  background-color: transparent;
  outline: none;
  color: ${UIStyles.vomitColor};
  font-size: 2.3rem;
  text-align: left;
  margin-bottom: 1.5rem;
  display: inline-block;
`

const listItemStyles = {
  paddingRight: 0,
  paddingLeft: 0
}

function SampleInfo({ t, samplesParameters, selectedSample, getS3Url, deselectAllSamples, openSampleImageModal, deleteSelectedSample, editSample }) {
  const [src, setSrc] = useState('')

  useEffect(() => {
    const picturePath = get(selectedSample, 'picturePath')
    setSrc('')
    if (!isEmpty(picturePath)) {
      getS3Url(picturePath).then(({ url }) => setSrc(url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSample])

  const type = get(selectedSample, 'type.name')
  const sampleCreation = get(selectedSample, 'sampleCreationDate')
  const uniqueId = get(selectedSample, 'uniqueId')
  const notes = get(selectedSample, 'notes')
  const externalId = get(selectedSample, 'externalId')

  const infoImageHandleClick = () => openSampleImageModal(src)

  return (
    <Wrapper>
      <ButtonsWrapper>
        <StyledTooltip title={capitalize(t('back'))} placement="right" style={{ display: 'inline' }}>
          <StyledButton className="fas fa-arrow-left" onClick={deselectAllSamples} />
        </StyledTooltip>
        <div style={{ display: 'flex' }}>
          <StyledTooltip title={capitalize(t('edit'))} placement="top" style={{ display: 'inline' }}>
            <StyledButton className="fas fa-edit" onClick={editSample} />
          </StyledTooltip>
          <StyledTooltip title={capitalize(t('delete'))} placement="top" style={{ display: 'inline' }}>
            <StyledButton className="fas fa-trash-alt" onClick={deleteSelectedSample} />
          </StyledTooltip>
        </div>
      </ButtonsWrapper>
      <HeaderInfo>
        <p>{capitalize(t(type))}</p>
        <p>{moment(sampleCreation).format('DD/MM/YYYY')}</p>
      </HeaderInfo>
      <Divider />
      <IdInfo>
        <p>{`ID ${uniqueId || externalId}`}</p>
      </IdInfo>
      <Divider />
      <RenderIf condition={notes}>
        <NotesWrapper>
          <NotesHeader>
            <p>{capitalize(t('notes'))}</p>
          </NotesHeader>
          <Spacer />
          <NotesData>{notes}</NotesData>
          <Divider />
        </NotesWrapper>
      </RenderIf>
      {!isEmpty(samplesParameters) && (
        <ExtraParamsWrapper>
          <ListCollapse title={capitalize(t('extra parameters'))}>
            {map(samplesParameters, param => {
              const units = get(param, 'measureUnit.shortName')
              const value = `${get(param, 'value')} ${units || ''}`
              return (
                <ListItem style={listItemStyles} key={get(param, 'name')}>
                  <ExtraParamsDetails key={get(param, 'parameterId')}>
                    <p title={capitalize(t(get(param, 'name')))}>{truncate(capitalize(t(get(param, 'name'))), { length: 36 })}</p>
                    <p>{value}</p>
                  </ExtraParamsDetails>
                </ListItem>
              )
            })}
          </ListCollapse>
          <Divider />
        </ExtraParamsWrapper>
      )}
      <RenderIf condition={src}>
        <ImageWrapper>
          <ImageHeader>
            <p>{capitalize(t('image'))}</p>
          </ImageHeader>
          <Spacer />
          <SampleInfoImage src={src} onClick={infoImageHandleClick} />
        </ImageWrapper>
      </RenderIf>
    </Wrapper>
  )
}

export default withTranslator(SampleInfo)
