import React from 'react'

export default function CustomTrash() {
  return (
    <svg id="custom_svg_trash" xmlns="http://www.w3.org/2000/svg" width="167.973" height="248.452" version="1.1" viewBox="0 0 131.467 186.339">
      <g fill="currentColor" strokeWidth="0.265" transform="translate(-45.433 -72.12)">
        <path
          id="svg_custom_trash_lid"
          d="M173.306 126.271h-31.75l-2.487-4.947a6.35 6.35 0 00-5.689-3.52H103.14a6.276 6.276 0 00-5.663 3.52l-2.487 4.947H63.24a4.233 4.233 0 00-4.233 4.234v8.466a4.233 4.233 0 004.233 4.234h110.067a4.233 4.233 0 004.233-4.234v-8.466a4.233 4.233 0 00-4.233-4.234z"
        ></path>
        <path d="M67.473 241.664c0 7.014 5.686 12.7 12.7 12.7h76.2c7.014 0 12.7-5.686 12.7-12.7v-88.9h-101.6zm71.966-67.733c.003-5.642 8.47-5.642 8.467 0v59.267c-.003 5.641-8.47 5.641-8.467 0zm-25.4 0c.007-5.637 8.46-5.637 8.467 0v59.267c-.007 5.637-8.46 5.637-8.467 0zm-25.4 0c0-5.644 8.467-5.644 8.467 0v59.267c0 5.644-8.467 5.644-8.467 0z"></path>
      </g>
    </svg>
  )
}
