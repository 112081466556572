import React from 'react'
import PropTypes from 'prop-types'

import SelectInt from './select'

import Map from '../map'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'

import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'

export default class SamplesLayerInteraction extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.feature = this.selectFeature(this.props.selectedSample)
  }

  getChildContext() {
    return {
      map: this.context.map
    }
  }

  selectFeature = feature => {
    if (!isNil(feature)) {
      // eslint-disable-next-line prefer-const
      let featuresLayer = Map.getLayerFromName('SamplesLayer', this.context.map)
      return featuresLayer && featuresLayer.getSource().getFeatureById(feature.id)
    }
    return null
  }

  toggleSample = selectedFeature => {
    const { deselectAllSamples, selectSampleById, setSelectedFieldSamplesTab } = this.props
    if (!isEmpty(selectedFeature)) {
      const selectedFeatureDetails = selectedFeature.get('field')
      const selectedFeatureId = get(selectedFeatureDetails, 'id')
      selectSampleById(selectedFeatureId)
      get(selectedFeature.get('field'), 'selected') && setSelectedFieldSamplesTab()
    } else {
      deselectAllSamples()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.feature = this.selectFeature(nextProps.selectedSample)
  }

  render() {
    const { isFieldViewActive, isSeasonLayersTabActive, showSamplesInMap } = this.props
    const isSelectActive = isFieldViewActive && !isSeasonLayersTabActive && showSamplesInMap
    if (!isSelectActive) return null
    /*=============================================>>>>>
      = Select Interaction =
      ===============================================>>>>>*/

    const select = (
      <div>
        <SelectInt
          select={evt => {
            this.toggleSample(evt.selected[0])
          }}
          selectedFeature={this.feature}
        ></SelectInt>
      </div>
    )

    /*= End of Select Interaction =*/
    /*=============================================<<<<<*/
    return select
  }
}

SamplesLayerInteraction.propTypes = {
  deselectAllSamples: PropTypes.func,
  selectSampleById: PropTypes.func,
  selectedSample: PropTypes.object,
  showSamplesInMap: PropTypes.bool,
  isSeasonLayersTabActive: PropTypes.bool,
  isFieldViewActive: PropTypes.bool
}

SamplesLayerInteraction.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

SamplesLayerInteraction.contextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}
