import React from 'react'
import styled from 'styled-components'
import map from 'lodash/map'
import get from 'lodash/get'
import includes from 'lodash/includes'
import toUpper from 'lodash/toUpper'
import toString from 'lodash/toString'
import { VomitButton, RedButton } from '../UIComponents/buttons'
import { UIStyles } from '../../theme'
import { withTranslator } from '../HOCComponents/withTranslator'

const Wrapper = styled.div`
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  color: ${UIStyles.whiteColor};
  padding: 0 20px;
  width: 400px;

  p {
    width: 100%;
  }
  button {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    font-size: 1.3rem;
  }
`
const FieldsListWrapper = styled.div`
  color: ${UIStyles.whiteColor};
  overflow: auto;
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  max-height: 300px;
`

const FieldItem = styled.p`
  color: ${props => (props.staysSelected ? UIStyles.vomitColor : UIStyles.redColor)};
  text-decoration: ${props => (props.staysSelected ? 'none' : 'line-through')};
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
`

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-self: flex-end;
  margin-top: 20px;
`

const FieldsSelectionStatus = withTranslator(function FieldsSelectionStatus({ previouslySelectedFields, nextSelectedFieldsIds, isOpen, t }) {
  const normalizedFieldsIdsToSelect = map(nextSelectedFieldsIds, id => toString(id))
  const fieldStaySelected = field => includes(normalizedFieldsIdsToSelect, toString(field.id))

  return (
    <FieldsListWrapper>
      {map(previouslySelectedFields, field => (
        <FieldItem key={field.id} staysSelected={fieldStaySelected(field)}>
          {toUpper(get(field, 'name') || t('unnamed field'))}
        </FieldItem>
      ))}
    </FieldsListWrapper>
  )
})

export default function ConfirmChangeFieldsSelectionModal({
  t,
  closeModal,
  resetFieldsComparisonFilters,
  addFieldsToGroup,
  flushFieldsGroup,
  fieldsIdsToSelect,
  selectedFields = [],
  onModalClose
}) {
  const handleConfirm = () => {
    if (selectedFields.length !== fieldsIdsToSelect.length) {
      flushFieldsGroup()
      addFieldsToGroup(fieldsIdsToSelect)
      resetFieldsComparisonFilters()
    }
    handleClose()
  }

  const handleClose = () => {
    closeModal()
    onModalClose()
  }

  const confirmSelectionLabel = `${t('Select')} (${fieldsIdsToSelect.length})`

  return (
    <Wrapper>
      <p>{t('this action will modify your field selection')}</p>
      <FieldsSelectionStatus previouslySelectedFields={selectedFields} nextSelectedFieldsIds={fieldsIdsToSelect} />
      <ButtonsWrapper>
        <RedButton onClick={handleClose} label={t('cancel')} />
        <VomitButton onClick={handleConfirm} label={confirmSelectionLabel} />
      </ButtonsWrapper>
    </Wrapper>
  )
}
