import { RECEIVE_FETCH_METEOROLOGICAL_API_DATA } from '../actions/meteorologicalData'

export default function meteorologicalData(state = {}, action = {}) {
  if (action.type === RECEIVE_FETCH_METEOROLOGICAL_API_DATA && action.meteorologicalData.length > 0) {
    const fieldId = action.meteorologicalData[0].field_id
    return {
      ...state,
      [fieldId]: [...action.meteorologicalData]
    }
  }
  return state
}
