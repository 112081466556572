import React, { useEffect, useState } from 'react'
import AccountFilters from './AccountFilters'
import AccountItem from './AccountItem'
import styled from 'styled-components'
import { List } from '@mui/material'
import FavouriteInstructions from '../../assets/img/accounts/favourite_instructions.png'
import { useSelector, useDispatch } from 'react-redux'
import { updateFavourites, updateDefaultAccount } from '../../features/accounts/accountsSlice'
import { updatePolicy } from '../../features/api/utils'
import { usePutPoliciesMutation } from '../../features/api/accountsConfigApi'
import { ACCOUNTS, UserConfigPolicies } from './constants'

import Search from './Search'

const Container = styled.div`
  width: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: calc(100% - 100px);
`

const Image = styled.img`
  width: auto;
  padding-left: 10px;
`

const AccountsList = ({ t, accounts }) => {
  const dispatch = useDispatch()
  const organizations = useSelector(state => state.userLayers.organizations.data)
  const defaultLoginAccount = useSelector(state => state.accounts.defaultAccount.data)
  const tokenData = useSelector(state => state.userLayers.token)
  const token = tokenData?.data?.access_token
  const allUsersPolicies = useSelector(state => state.accountsConfig?.user?.data)
  const userId = useSelector(state => state.userLayers.user?.data?.id)
  const policy = allUsersPolicies?.find(item => item.id === userId).policy
  const organizationId = useSelector(state => state.userLayers.organizations?.data[0]?.id)
  const favouriteAccounts = useSelector(state => state.accounts.favouriteAccounts?.data)

  const [listAccounts, setListAccounts] = useState()
  const [defaultLoginAccountItem, setDefaultLoginAccountItem] = useState()
  const [showFavorites, setShowFavorites] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setListAccounts()
  }, [defaultLoginAccount])

  useEffect(() => {
    if (accounts && defaultLoginAccount) {
      const _defaultLoginAccountItem = accounts.find(acc => defaultLoginAccount === acc.id)
      setDefaultLoginAccountItem(_defaultLoginAccountItem)
    }
  }, [defaultLoginAccount, accounts, listAccounts])

  useEffect(() => {
    if (listAccounts && searchTerm) {
      const _filteredAccounts = accounts.filter(account => {
        return account.name.toLowerCase().includes(searchTerm.toLowerCase())
      })
      setListAccounts(_filteredAccounts)
    } else if (searchTerm.length === 0) {
      setListAccounts(accounts)
    }
  }, [searchTerm, listAccounts, accounts])

  const [putPolicies] = usePutPoliciesMutation() // use to update default login or favourites

  const updateDefaultLoginAccount = (id, currentDefaultAccountId) => {
    const action = currentDefaultAccountId && id === currentDefaultAccountId ? 'delete' : 'update'
    const policyModified = updatePolicy(policy, UserConfigPolicies.default_login_account, id, action)
    dispatch(updateDefaultAccount({ key: 'defaultAccount', payload: action === 'delete' ? null : id }))
    setDefaultLoginAccountItem(action === 'delete' ? null : accounts.find(acc => id === acc.id))
    return putPolicies({ userId, organizationId, policy: policyModified, token })
  }

  const updateFavouriteAccounts = (id, favouriteIds) => {
    let updatedFavourites
    if (favouriteIds.includes(id)) {
      updatedFavourites = favouriteIds.filter(favId => favId !== id) // remove from fav
    } else {
      updatedFavourites = [...favouriteIds, id] // add to fav
    }
    dispatch(updateFavourites({ key: 'favouriteAccounts', payload: updatedFavourites }))
    const policyModified = updatePolicy(policy, UserConfigPolicies.favourite_accounts, updatedFavourites, 'favourites')
    return putPolicies({ userId, organizationId, policy: policyModified, token })
  }

  const isFavourite = id => {
    return favouriteAccounts.includes(id)
  }

  const findOrganization = (id, orgs) => orgs.find(org => org.id === id)?.name

  const emptyFavoutiteList = (
    <span style={{ padding: '16px' }}>
      You can add favourite accounts to this section by clicking the star at the right of each account <Image src={FavouriteInstructions}></Image>
    </span>
  )

  const generateList = showFavorites => {
    return showFavorites ? (
      <List
        sx={{
          width: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          padding: '0 16px 0 0'
        }}
        key="favourites"
        subheader={<AccountFilters title={ACCOUNTS.favourite_accounts} t={t} />}
      >
        {favouriteAccounts.length > 0
          ? listAccounts?.map(
              account =>
                isFavourite(account.id) && (
                  <AccountItem
                    key={account.id}
                    account={account}
                    name={account.name}
                    organization={findOrganization(account.organization_id, organizations)}
                    // policy={findAccountPolicy(account.id, userAccountsConfig)}
                    onClickDefaultLoginAccount={() => updateDefaultLoginAccount(account.id, defaultLoginAccount)}
                    onToggleFavourite={() => updateFavouriteAccounts(account.id, favouriteAccounts)}
                    isFavourite={true}
                    isDefaultLoginAccount={defaultLoginAccount === account.id}
                  />
                )
            )
          : emptyFavoutiteList}
      </List>
    ) : (
      <List
        sx={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', padding: '0 16px 0 0' }}
        key="accounts"
        subheader={<AccountFilters title={ACCOUNTS.account} t={t} />}
      >
        {listAccounts?.map(account => (
          <AccountItem
            account={account}
            key={account.id}
            name={account.name}
            organization={findOrganization(account.organization_id, organizations)}
            // policy={findAccountPolicy(account.id, userAccountsConfig)}
            onClickDefaultLoginAccount={() => updateDefaultLoginAccount(account.id, defaultLoginAccount)}
            onToggleFavourite={() => updateFavouriteAccounts(account.id, favouriteAccounts)}
            isFavourite={isFavourite(account.id)}
            isDefaultLoginAccount={defaultLoginAccount === account.id}
          />
        ))}
      </List>
    )
  }

  return (
    <Container className="accounts-list-container">
      {defaultLoginAccountItem && (
        <List
          sx={{
            width: '100%',
            padding: '16px 16px 0 0',
            alignContent: 'center'
          }}
          key="default"
          subheader={<AccountFilters title={ACCOUNTS.default_account} t={t} />}
        >
          <AccountItem
            key={defaultLoginAccountItem.id}
            account={defaultLoginAccountItem}
            name={defaultLoginAccountItem.name}
            organization={findOrganization(defaultLoginAccountItem.organization_id, organizations)}
            // policy={findAccountPolicy(account.id, userAccountsConfig)}
            onClickDefaultLoginAccount={() => updateDefaultLoginAccount(defaultLoginAccountItem.id, defaultLoginAccount)}
            onToggleFavourite={() => updateFavouriteAccounts(defaultLoginAccountItem.id, favouriteAccounts)}
            isFavourite={isFavourite(defaultLoginAccountItem.id)}
            isDefaultLoginAccount={defaultLoginAccount === defaultLoginAccountItem.id}
          />
        </List>
      )}
      <Search t={t} searchTerm={searchTerm} setSearchTerm={setSearchTerm} showFavorites={showFavorites} setShowFavorites={setShowFavorites} />
      {generateList(showFavorites)}
    </Container>
  )
}

export default AccountsList
