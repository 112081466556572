import { connect } from 'react-redux'
import { compose } from 'redux'

import { goToField } from '../../actions/fields'
import { closeModal } from '../../actions/modal'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import ReportGoToFieldsButton from '../../components/ToolsComponents/ReportGoToFieldsButton'

const mapDispatch = {
  goToField,
  closeModal
}

export default compose(withTranslator, connect(null, mapDispatch))(ReportGoToFieldsButton)
