import PropTypes from 'prop-types'

import OLInteraction from './ol-interaction'
import { buildStyle } from '../style'

import OlDraw from 'ol/interaction/Draw'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'

export default class Draw extends OLInteraction {
  constructor(props, context) {
    super(props, context)
    this.interaction = this.createInteraction(props, context)
    this.eventHandlerKeys_ = {}
  }

  createInteraction(props, context) {
    return new OlDraw({
      type: props.type,
      style: buildStyle(props.style),
      source: context.source
    })
  }
}

Draw.propTypes = Object.assign({}, OLInteraction.propTypes, {
  drawend: PropTypes.func,
  drawstart: PropTypes.func,
  change: PropTypes.func,
  style: PropTypes.array,
  source: PropTypes.instanceOf(OlVectorSource)
})

Draw.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}

Draw.olEvents = ['drawend', 'drawstart']
