import PropTypes from 'prop-types'
import OLContainer from './ol-container'

import OlLayerTile from 'ol/layer/Tile'
import OlMap from 'ol/Map'
import OlStyle from 'ol/style/Style'

export default class LayerTile extends OLContainer {
  constructor(props) {
    super(props)
    this.layer = new OlLayerTile({
      visible: this.props.visible,
      name: this.props.name,
      extent: this.props.extent
    })

    this.layer.setZIndex(props.zIndex)
    this.layer.setOpacity(props.opacity)
  }

  getChildContext() {
    return {
      layer: this.layer,
      map: this.context.map
    }
  }

  componentDidMount() {
    this.context.map.addLayer(this.layer)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.layer.setVisible(newProps.visible)
    this.layer.setZIndex(newProps.zIndex)
    this.layer.setOpacity(newProps.opacity)
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }
}

LayerTile.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(OlStyle), PropTypes.object]))]),
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
  name: PropTypes.string,
  children: PropTypes.object,
  extent: PropTypes.array
}

LayerTile.defaultProps = {
  visible: true
}

LayerTile.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

LayerTile.childContextTypes = {
  layer: PropTypes.instanceOf(OlLayerTile),
  map: PropTypes.instanceOf(OlMap)
}
