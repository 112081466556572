/**
 * Basic Analytics calls
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 */
export const pageViewed = name => {
  window.analytics.page(name)
}

/**
 *
 * @param {*} event: string
 * @param {*} properties: [key: string]: string | number | boolean
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 */
export const trackEvent = (event, properties) => {
  window.analytics.track(event, properties)
}

/**
 *
 * @param {*} id: string
 * @param {*} props: {username, email, roles, azureId, platform: 'web'}
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
 */
export const identifyUser = (id, props) => {
  window.analytics.identify(id, props)
}
