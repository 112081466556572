import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import PredTech from '../../components/DashboardComponents/PredTech/PredTech'
import { REQUEST_PRED_TECH_DATA_INITIALIZATION, fetchPredTechByLogType, FETCH_FIELDS_WITH_PRED_TECH_LOGS } from '../../actions/predTech'
import { getFieldsIdsMap, getSelectedFieldsIds } from '../../selectors/fields'

import { getSacHa, getProdHa, getNitro, getTchPred, getPolPred, getIdealParameters } from '../../selectors/predTech'
import { areRequestsLoading, areRequestsSuccessful } from '@layers-frontend/commons/store/selectors/requests'
import { getUiTemporalEvolutionLayout } from '../../selectors/ui'

const mapStateToProps = state => ({
  chartsLayout: getUiTemporalEvolutionLayout(state),
  selectedFieldsIds: getSelectedFieldsIds(state),
  sacHa: getSacHa(state),
  prodHa: getProdHa(state),
  nitro: getNitro(state),
  tchPred: getTchPred(state),
  polPred: getPolPred(state),
  idealParameters: getIdealParameters(state),
  isLoading: areRequestsLoading(state, [FETCH_FIELDS_WITH_PRED_TECH_LOGS]),
  fieldsIdsMap: getFieldsIdsMap(state),
  isDataInitialized: areRequestsSuccessful(state, [REQUEST_PRED_TECH_DATA_INITIALIZATION])
})

const mapDispatchToProps = { fetchPredTechByLogType }

export default compose(withFieldsAvailableAndSelected, withTranslator, connect(mapStateToProps, mapDispatchToProps))(PredTech)
