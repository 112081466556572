import { RECEIVE_SATELLITE_FLIGHT_DATES, RECEIVE_SNAPSHOTS_BY_SOURCE, RECEIVE_DB_SNAPSHOTS } from '../actions/snapshots'

import { OPEN_SEARCH_MODE } from '../actions/viewmode'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import toString from 'lodash/toString'
import union from 'lodash/union'
import { FLUSH_FIELDS_GROUP } from '../actions/fields'

// snapshots struct
// {
//     [date]: {
//         [source]:{
//             date
//             source
//             seasonIds: []
//             deliverables: {    DELIVERABLES ARE HERE ONLY FOR DRONE/DRONE_RGB SNAPSHOTS
//               seasonId:{
//                 deliverables:[]
//                 pdfDeliverables: []
//               }
//             }
//
//         }
//     }
// }

const initialState = {}

export function snapshots(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SATELLITE_FLIGHT_DATES: {
      const { seasonId, flights, source, cloudCoverage } = action
      return reduce(
        flights,
        (accumulator, flight) => {
          const date = get(flight, ['date'])
          const previousDateData = get(accumulator, date, {})
          const previousDateSourceData = get(previousDateData, source, {})

          const previousSeasons = get(previousDateSourceData, 'seasons', [])
          const season = { id: toString(seasonId), cloudCoverage }
          return {
            ...accumulator,
            [date]: {
              ...previousDateData,
              [source]: {
                ...previousDateSourceData,
                date,
                source,
                seasons: union(previousSeasons, [season])
              }
            }
          }
        },
        state
      )
    }
    case RECEIVE_DB_SNAPSHOTS: {
      const { snapshots } = action
      return snapshots
    }
    case RECEIVE_SNAPSHOTS_BY_SOURCE: {
      const { snapshots } = action
      return reduce(
        snapshots,
        (accumulator, snapshot) => {
          const seasonId = get(snapshot, 'seasonId')
          const cloudCoverage = get(snapshot, 'cloudCoverage')
          const date = get(snapshot, 'date')
          const source = get(snapshot, 'source')
          const deliverables = get(snapshot, 'deliverables')
          const previousDateData = get(accumulator, date, {})
          const previousDateSourceData = get(previousDateData, source, {})
          const previousDeliverables = get(previousDateSourceData, 'deliverables')

          const previousSeasons = get(previousDateSourceData, 'seasons', [])
          const season = { id: toString(seasonId), cloudCoverage }
          // remove all snapshots without deliverables
          if (isEmpty(deliverables)) {
            return accumulator
          }

          return {
            ...accumulator,
            [date]: {
              ...previousDateData,
              [source]: {
                ...previousDateSourceData,
                date,
                source,
                seasons: union(previousSeasons, [season]),
                deliverables: {
                  ...previousDeliverables,
                  [seasonId]: deliverables
                }
              }
            }
          }
        },
        state
      )
    }

    case OPEN_SEARCH_MODE:
    case FLUSH_FIELDS_GROUP:
      return initialState
    default:
      return state
  }
}
