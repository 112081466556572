import React from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import { dashboardViews, disabledPattern, MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS, TRACK_EVENTS } from '../../constants'
import { withTranslator } from '../HOCComponents/withTranslator'

import get from 'lodash/get'
import isString from 'lodash/isString'
import { trackEvent } from '../analytics/analytics'

export const ListWrapper = styled.li`
  height: 100px;
  background: ${props => (props.isSelected ? UIStyles.vomitLightFieldSelected : UIStyles.blueColorOpacity)};
  color: ${props => (props.isSelected ? UIStyles.blueColor : UIStyles.whiteColor)};
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;
  font-family: Roboto;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: capitalize;
  width: 100%;
  &:after {
    display: ${props => (props.isAvailable ? 'none' : 'block')};
    content: '';
    background: url(${disabledPattern}) repeat;
    cursor: not-allowed;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:hover {
    background: ${props => props.isAvailable && (props.isSelected ? UIStyles.vomitLightFieldSelectedHover : UIStyles.blueLightHover)};
  }
  &:last-child {
    margin-bottom: ${props => !props.isDashboard && '60px'};
  }
  &:not(:last-child) {
    border-bottom: 1px solid rgba(230, 230, 230, 0.2);
  }
  &:last-child {
    margin-bottom: 100px;
  }
`

export const StyledCardFieldItem = styled.div`
  display: flex;
  padding: 0;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  gap: 5px;
`

export const StyledCardFieldItemDetails = styled.div`
  padding: 0;
  position: relative;
  min-height: 1px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
`

export const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const StyledFieldNameWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 1px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
`

const StyledFieldName = styled.h4`
  margin: 0;
  line-height: 17px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-bottom: 3px;
  white-space: nowrap;
  margin-right: 8%;
`

const StyledPlantationDate = styled.div`
  display: flex;
  align-items: center;
`

const StyledIconPlantationDate = styled.i`
  padding-left: 5px;
`

const FieldTypeWrapper = styled.div`
  margin-bottom: 2px;
`

const SeedlingIconWrapper = styled.i`
  padding-top: 3px;
  padding-right: 5px;
  font-size: 14px;
`

export const InfoIconWrapper = styled.i`
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
`

const StyledReferenceWrapper = styled.div`
  margin-bottom: 2px;
`

const StyledReference = styled.div`
  min-height: 17px;
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const StyledSpanReference = styled.span``

const StyledCustomerNameWrapper = styled.div`
  width: 100%;
  float: left;
  position: relative;
  min-height: 1px;
  margin-bottom: 2px;
`

const StyledIconCustomerName = styled.i`
  font-size: 14px;
  padding-right: 5px;
`

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export function CustomerNameCard({ label, isAuthorize }) {
  if (!isString(label) || !isAuthorize) return null

  return (
    <StyledCustomerNameWrapper>
      <span>
        <StyledIconCustomerName className="fa-solid fa-user" />
        {label}
      </span>
    </StyledCustomerNameWrapper>
  )
}

export function ReferenceIdCard({ label, isAuthorize }) {
  if (!isString(label) || !isAuthorize) return null

  return (
    <StyledReferenceWrapper>
      <StyledReference>
        <StyledSpanReference>
          <SeedlingIconWrapper className="fa-solid fa-barcode" />

          {label}
        </StyledSpanReference>
      </StyledReference>
    </StyledReferenceWrapper>
  )
}

export function SeasonLabelCard({ label }) {
  if (!isString(label)) return null

  return (
    <StyledReferenceWrapper>
      <StyledReference>
        <StyledSpanReference>
          <SeedlingIconWrapper className="fa-solid fa-hashtag" />

          {label}
        </StyledSpanReference>
      </StyledReference>
    </StyledReferenceWrapper>
  )
}

export function FieldTypeCard({ label }) {
  return (
    <FieldTypeWrapper>
      <SeedlingIconWrapper className="fas fa-seedling" />
      {label}
    </FieldTypeWrapper>
  )
}

export function FieldCutCard({ number }) {
  if (!isString(number)) return null

  return (
    <FieldTypeWrapper>
      <SeedlingIconWrapper className="fas fa-cut" />
      {number}
    </FieldTypeWrapper>
  )
}

export function FieldNameAndPlantationDateCard({ fieldName, plantationDate }) {
  return (
    <StyledFieldNameWrapper>
      <StyledFieldName>{fieldName}</StyledFieldName>
      {plantationDate ? (
        <StyledPlantationDate>
          {moment(plantationDate).format('DD/MM/YYYY')}
          <StyledIconPlantationDate className="fa-regular fa-calendar" />
        </StyledPlantationDate>
      ) : null}
    </StyledFieldNameWrapper>
  )
}

const shouldMemoize = (prevProps, nextProps) => prevProps.label === nextProps.label
const shouldMemoizeFieldNameAndPlantationDate = (prevProps, nextProps) =>
  prevProps.fieldName === nextProps.fieldName && prevProps.plantationDate === nextProps.plantationDate

const MemoizedCustomerNameCard = React.memo(CustomerNameCard, shouldMemoize)
const MemoizedSeasonLabelCard = React.memo(SeasonLabelCard, shouldMemoize)
const MemoizedReferenceIdCard = React.memo(ReferenceIdCard, shouldMemoize)
const MemoizedFieldTypeCard = React.memo(FieldTypeCard, shouldMemoize)
const MemoizedFieldNameAndPlantationDateCard = React.memo(FieldNameAndPlantationDateCard, shouldMemoizeFieldNameAndPlantationDate)

function FieldItem({
  t,
  selectFields,
  addFieldsToGroup,
  removeFieldToGroup,
  field,
  selected,
  isAvailable,
  isSelected,
  currentLocation,
  shouldRenderCustomerAndReferenceId,
  virlualizedListStyle,
  selectedFieldsLength,
  errorNotification,
  openFieldSeasonFormModal,
  setPreviewFieldId,
  testId
}) {
  const isFieldTemporalEvolution = currentLocation.view === dashboardViews.FIELD_TEMPORAL_EVOLUTION
  const isPredTech = currentLocation.view === dashboardViews.PRED_TECH

  const clickHandler = e => {
    e.preventDefault()
    if (!field.isAvailable) {
      return
    }

    if ((isFieldTemporalEvolution || isPredTech) && selectedFieldsLength === MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS && !selected) {
      errorNotification(`${t('max comparable fields')} ${MAX_TEMPORAL_EVOLUTION_SELECTED_FIELDS}`)
      return
    }

    if (selected) removeFieldToGroup(get(field, 'id'))
    else addFieldsToGroup([get(field, 'id')])
  }

  const doubleClickHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    if (currentLocation.isDashboard) {
      return
    }

    if (!field.isAvailable) {
      return
    }
    addFieldsToGroup([get(field, 'id')])
    selectFields()
  }

  const iconClickHandler = e => {
    e.stopPropagation()
    setPreviewFieldId(get(field, 'id'))
    openFieldSeasonFormModal('readOnly')
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_LIST_FIEL_INFO, {
      location: TRACK_EVENTS.LEFT_SIDEBAR,
      action: 'Field info',
      value: get(field, 'id')
    })
  }

  const fieldName = get(field, 'name', '')
  const plantationDate = get(field, 'season.parameters.plantationDate.value')
  const fieldType = t(get(field, 'season.type.name', ''))
  const fieldSubType = t(get(field, 'season.subtype.name', ''))
  const fieldTypeLabel = fieldSubType ? fieldType + '/' + fieldSubType : fieldType
  const customerName = get(field, 'customer.name')
  const seasonLabel = get(field, 'season.label')
  const externalReferenceId = get(field, 'externalReferenceId')

  return (
    <ListWrapper
      isAvailable={isAvailable}
      isSelected={isSelected}
      selected={selected}
      onClick={clickHandler}
      onDoubleClick={doubleClickHandler}
      isDashboard={currentLocation.isDashboard}
      style={virlualizedListStyle}
      data-testid={testId}
    >
      <StyledCardFieldItem className="fieldItem">
        <MemoizedFieldNameAndPlantationDateCard fieldName={fieldName} plantationDate={plantationDate} />
        <StyledCardFieldItemDetails>
          <InnerContainer>
            <MemoizedCustomerNameCard label={customerName} isAuthorize={shouldRenderCustomerAndReferenceId} />
            <MemoizedFieldTypeCard label={fieldTypeLabel} />
            <MemoizedSeasonLabelCard label={seasonLabel} />
            <MemoizedReferenceIdCard label={externalReferenceId} isAuthorize={shouldRenderCustomerAndReferenceId} />
          </InnerContainer>
          <InfoIconWrapper onClick={iconClickHandler} className="fa-solid fa-circle-info" />
        </StyledCardFieldItemDetails>
      </StyledCardFieldItem>
    </ListWrapper>
  )
}

export default React.memo(withTranslator(FieldItem))
