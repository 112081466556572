import { loader } from 'graphql.macro'
import { fetchGraphqlWrapper, fetchRequestWrapper } from './ui'
import { APPLICATION_JSON_HEADER } from '@layers-frontend/commons/constants'
import {
  DELETE_REPORT_SUCCESS,
  RECEIVE_REPORT_STATUSES,
  RECEIVE_REPORT_TYPES,
  RECEIVE_REPORTS,
  REQUEST_DELETE_REPORT,
  UPDATE_REPORT_SUCCESS
} from '@layers-frontend/commons/store/storeConstants'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import some from 'lodash/some'
import { getFieldSeasonTimeLineActiveSeasonByFieldId } from '../selectors/fieldsSeasonsTimeline'
import moment from 'moment'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import { getFieldsIds } from '../selectors/fields'
import { fetchFieldsByIds } from './fields'

const REQUEST_REPORT_TYPES = 'REQUEST_REPORT_TYPES'
const REQUEST_REPORTS = 'REQUEST_REPORTS'
const REQUEST_REPORTS_BY_FIELD_IDS = 'REQUEST_REPORTS_BY_IDS'
const REQUEST_REPORT_STATUSES = 'REQUEST_REPORT_STATUSES'
const UPDATE_REPORT = 'UPDATE_REPORT'

const getReportTypesQuery = loader('../graphql/reports/getReportTypes.gql').loc.source.body
export const fetchReportTypes = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_REPORT_TYPES,
    query: getReportTypesQuery,
    onSuccess: ({ types }) => ({ type: RECEIVE_REPORT_TYPES, types })
  })

const getReportsQuery = loader('../graphql/reports/getReports.gql').loc.source.body
const getReportsQueryByFieldIds = loader('../graphql/reports/getReportsByFieldIds.gql').loc.source.body

export const fetchReports = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_REPORTS,
    query: getReportsQuery,
    onSuccess: receiveReports
  })

export const fetchReportsByFieldIds = ({ fieldIds }) =>
  fetchGraphqlWrapper({
    requestType: REQUEST_REPORTS_BY_FIELD_IDS,
    query: getReportsQueryByFieldIds,
    // eslint-disable-next-line object-shorthand
    variables: { fieldIds: fieldIds },
    onSuccess: receiveReports
  })

export const receiveReports = ({ list }) => (dispatch, getState) => {
  const state = getState()
  const { modifiedList, fieldsIds } = reduce(
    list,
    (acc, report) => {
      const reportId = get(report, 'id')
      const fieldIds = map(report.fields, field => field.field_id)
      const reportDate = get(report, 'relatedDate') || get(report, 'creationDate')

      const isCurrentSeasonReport = some(fieldIds, fieldId => {
        const activeSeason = getFieldSeasonTimeLineActiveSeasonByFieldId(state, fieldId)
        const activeSeasonStartDate = get(activeSeason, 'startDate')
        return moment(reportDate).isSameOrAfter(activeSeasonStartDate)
      })

      return {
        ...acc,
        modifiedList: {
          ...acc.modifiedList,
          [reportId]: {
            ...report,
            isCurrentSeasonReport
          }
        },
        fieldsIds: [...acc.fieldsIds, ...fieldIds]
      }
    },
    { modifiedList: {}, fieldsIds: [] }
  )

  dispatch(fetchExtraReportsFields(fieldsIds))
  return dispatch({ type: RECEIVE_REPORTS, list: modifiedList })
}

export const fetchExtraReportsFields = fieldsIds => (dispatch, getState) => {
  const state = getState()
  const existingFields = getFieldsIds(state)
  const unfetchedFields = difference(fieldsIds, existingFields)

  if (!isEmpty(unfetchedFields)) {
    dispatch(fetchFieldsByIds(uniq(fieldsIds, existingFields)))
  }
}

const getReportStatusesQuery = loader('../graphql/reports/getReportStatuses.gql').loc.source.body
export const fetchReportStatuses = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_REPORT_STATUSES,
    query: getReportStatusesQuery,
    onSuccess: ({ statuses }) => ({ type: RECEIVE_REPORT_STATUSES, statuses })
  })

export const updateReportStatus = (reportId, statusId) =>
  fetchRequestWrapper({
    requestType: UPDATE_REPORT,
    route: 'patch_reports',
    urlParams: { id: reportId },
    fetchOptions: {
      method: 'PATCH',
      body: JSON.stringify({ status: statusId }),
      headers: APPLICATION_JSON_HEADER
    },
    onSuccess: () => ({ type: UPDATE_REPORT_SUCCESS, reportId, statusId }),
    onError: error => {
      console.log(error)
    }
  })

export const deleteReportById = reportId =>
  fetchRequestWrapper({
    requestType: REQUEST_DELETE_REPORT,
    route: 'patch_reports',
    urlParams: { id: reportId },
    fetchOptions: {
      method: 'PATCH',
      body: JSON.stringify({ enabled: false }),
      headers: APPLICATION_JSON_HEADER
    },
    onSuccess: () => ({ type: DELETE_REPORT_SUCCESS, reportId }),
    onError: error => {
      console.log(error)
    }
  })
