import React from 'react'
import PropTypes from 'prop-types'
import FieldViewContainer from '../../containers/FieldsContainers/FieldViewContainer'
import FieldNewViewContainer from '../../containers/FieldsContainers/FieldNewViewContainer'
import FieldEditViewContainer from '../../containers/FieldsContainers/FieldEditViewContainer'

import { FIELD_VIEW, FIELD_NEW_VIEW, FIELD_EDIT_VIEW, TEMPORAL_COMPARE_VIEW } from '../../actions/viewmode'

export default class ViewHandler extends React.Component {
  render() {
    const view = (function (view) {
      switch (view) {
        case FIELD_VIEW:
          return <FieldViewContainer temporal={false} />
        case TEMPORAL_COMPARE_VIEW:
          return <FieldViewContainer temporal={true} />
        case FIELD_NEW_VIEW:
          return <FieldNewViewContainer />
        case FIELD_EDIT_VIEW:
          return <FieldEditViewContainer />

        default:
          return null
      }
    })(this.props.currentLocation.view)
    return <div>{view}</div>
  }
}

ViewHandler.propTypes = {
  dispatch: PropTypes.func,
  screenView: PropTypes.string
}
