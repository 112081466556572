import React from 'react'
import { UndoActionsContainer, DrawingToolsContainer } from '../../containers/ToolsContainers/ToolsContainers'
import { UIStyles } from '../../theme'
import { FIELD_EDIT_VIEW, FIELD_NEW_VIEW, SAMPLE_LOCATION_VIEW } from '../../actions/viewmode'
import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import { Button } from '../UIComponents/StyledButtons'

const DrawingToolbarWrapper = styled.div`
  display: flex;
  position: fixed;
  z-index: 1;
  bottom: 40px;
  flex-direction: column;
  align-items: center;
`

const SideButtonsWrapper = styled.div`
  position: fixed;
  z-index: 1;
  bottom: 28px;
  left: 70px;
`

class Toolbar extends React.Component {
  render() {
    const {
      isToolbarOpen,
      isTemporalActive,
      isFieldSeasonFormEditorOpen,
      denyFieldSeasonFormGeometryAndSurface,
      confirmFieldSeasonFormGeometryAndSurface,
      t,
      currentLocation,
      validateSampleFormLocation,
      denySampleLocation
    } = this.props
    const currentView = currentLocation.view
    const isDrawingToolBarVisible = currentView === FIELD_EDIT_VIEW || currentView === FIELD_NEW_VIEW
    const isSampleLocationView = currentView === SAMPLE_LOCATION_VIEW
    return (
      <div className={'toolbar drawingToolbar ' + (isToolbarOpen ? 'showed' : '')}>
        {!isTemporalActive && isFieldSeasonFormEditorOpen && isDrawingToolBarVisible && (
          <>
            <DrawingToolbarWrapper>
              <UndoActionsContainer />
              <DrawingToolsContainer />
            </DrawingToolbarWrapper>

            <SideButtonsWrapper>
              <Button label={t('cancel')} onClick={denyFieldSeasonFormGeometryAndSurface} background={UIStyles.redColor} />
              <Button label={t('confirm')} onClick={confirmFieldSeasonFormGeometryAndSurface} testId="confirm-geometry-button" />
            </SideButtonsWrapper>
          </>
        )}
        {isSampleLocationView && (
          <SideButtonsWrapper>
            <Button label={t('cancel')} onClick={denySampleLocation} background={UIStyles.redColor} />
            <Button label={t('confirm')} onClick={validateSampleFormLocation} testId="confirm-geometry-button" />
          </SideButtonsWrapper>
        )}
      </div>
    )
  }
}

export default withTranslator(Toolbar)
