import React from 'react'
import { Tooltip } from '@mui/material'

export const StyledTooltip = ({ title, placement, followCursor = false, open, children, arrow, enterDelay = 800, leaveDelay = 20, enterNextDelay = 800 }) => {
  return (
    <Tooltip
      title={title}
      placement={placement}
      followCursor={followCursor}
      open={open}
      arrow={arrow}
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      enterNextDelay={enterNextDelay}
    >
      <div>{children}</div>
    </Tooltip>
  )
}
