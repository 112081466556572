import React from 'react'
import styled from 'styled-components'

import { withTranslator } from '../HOCComponents/withTranslator'
import { Snackbar } from '@mui/material'
import { Button } from './StyledButtons'
import Slide from '@mui/material/Slide'

const Wrapper = styled.div`
  text-align: center;
`

class Notifier extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: true
    }
  }

  render() {
    const { t, notification, handleNotificationExpire, handleRefreshClick } = this.props
    const isOpen = this.state.open

    const snackbar = (
      <Wrapper>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isOpen}
          autoHideDuration={notification.duration}
          message={t(notification.text)}
          onClose={handleNotificationExpire}
          onClick={notification.handleActionClick}
          key={notification.text}
          TransitionProps={{ className: notification.className }}
          TransitionComponent={Slide}
        />
      </Wrapper>
    )

    const firebaseSnackbar = (
      <Wrapper>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={isOpen}
          action={<Button onClick={handleRefreshClick} label="Actualiza el mapa" />}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={isOpen}
          message={t(notification.text)}
          TransitionProps={{ className: notification.className }}
          autoHideDuration={0}
          onClose={handleNotificationExpire}
          action={<Button label={'Refresh'} />}
        />
      </Wrapper>
    )

    let Notifier
    switch (notification.type) {
      case 'WEB_TYPE':
        Notifier = snackbar
        break
      case 'FIREBASE_TYPE':
        Notifier = firebaseSnackbar
        break
      default:
        Notifier = null
    }

    return Notifier
  }
}

export default withTranslator(Notifier)
