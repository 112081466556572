import { connect } from 'react-redux'

import { openDownloadStatisticsModal } from '../../actions/modal'
import DownloadStatisticsButton from '../../components/ToolsComponents/DownloadStatisticsButton'

const mapDispatchToProps = {
  openDownloadStatisticsModal
}

export default connect(null, mapDispatchToProps)(DownloadStatisticsButton)
