import PropTypes from 'prop-types'
import Draw from './draw'
import { buildStyle } from '../style'

import OlDraw from 'ol/interaction/Draw'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'

export default class SlicePolygon extends Draw {
  createInteraction(props, context) {
    return new OlDraw({
      type: 'LineString',
      style: buildStyle(props.style),
      source: context.source
    })
  }

  UNSAFE_componentWillReceiveProps(newProps, context) {}
}

SlicePolygon.PropTypes = {
  active: PropTypes.bool,
  drawend: PropTypes.func,
  drawstart: PropTypes.func,
  style: PropTypes.arrayOf(PropTypes.object)
}

SlicePolygon.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
