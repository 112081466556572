import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import find from 'lodash/fp/find'

export const getReportsStatuses = get('reportsStatuses')

export const getReportStatus = state => statusId =>
  pipe(
    getReportsStatuses,
    find(item => statusId === item.id),
    get('name')
  )(state)
