import React from 'react'
import PropTypes from 'prop-types'

import MultiPolygon from '../olComponents/geom/MultiPolygon'
import LayerVector from '../olComponents/layervector'
import SourceVector from '../olComponents/sourcevector'
import Feature from '../olComponents/feature'
import '../../utils'
import OlMap from 'ol/Map'

import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'

export default class FieldsLayer extends React.Component {
  getChildContext() {
    return { map: this.context.map }
  }

  componentDidUpdate(prevProps) {
    const prevFields = prevProps.selectedFlightGroupFields
    const newFields = this.props.selectedFlightGroupFields
    // hack to update geometry
    if (!isEqual(prevFields, newFields)) {
      this.setState({})
    }
  }

  render() {
    const { fields, fieldIds, isVisualization, selectedFlightGroupFields, projection, isEditView, geometries, selectedFieldIds, isGlobalLayerOpen } = this.props

    // create a list of field ids; if fields are not queried use them, if queried use fieldIds which represent queried result
    const fieldsIdList = fields.length < CHUNK_SIZE ? map(fields, 'id') : fieldIds

    // filter geometries by list of field ids
    const filteredGeometries = filter(geometries, geom => includes(fieldsIdList, geom.id))

    // filter if does not contain geometry
    let filteredFields = !isVisualization
      ? filter(filteredGeometries, geometry => get(geometry, 'geometry'))
      : filter(filteredGeometries, field => includes(selectedFieldIds, field.id) && get(field, 'geometry'))

    filteredFields =
      isGlobalLayerOpen && !isVisualization
        ? []
        : filteredFields.map(field => {
            let fillColor = themeColors.grayColor
            const strokeColor = themeColors.yellow
            const fieldImages = null
            let opacity = 0
            const coordinates = get(field, ['geometry', 'coordinates']) || get(field, ['season', 'geometry', 'coordinates'])

            if (selectedFlightGroupFields && selectedFlightGroupFields.some(selected => selected.id === field.id || selected.id === field.fieldId)) {
              fillColor = themeColors.transparent
            }

            // globalLayer or geometries
            if (includes(selectedFieldIds, field.fieldId) || (includes(selectedFieldIds, field.id) && !isVisualization)) {
              opacity = 0.8
              fillColor = themeColors.yellowTransparent
            }

            if (isGlobalLayerOpen && !isVisualization) {
              return null
            }
            return (
              <Feature
                key={field.id}
                id={field.id}
                field={field}
                style={[
                  {
                    stroke: {
                      width: 1,
                      color: strokeColor
                    },
                    fill: {
                      color: fillColor
                    },
                    text: {
                      text: String.fromCharCode('0xf00c'),
                      font: "900 40px 'Font Awesome 5 Free'",
                      fill: {
                        color: 'rgba(8, 44, 63,' + opacity + ')'
                      }
                    }
                  }
                ]}
              >
                {fieldImages}
                <MultiPolygon projection={projection}>{coordinates}</MultiPolygon>
              </Feature>
            )
          })

    // eslint-disable-next-line prefer-const
    let fieldsLayer = (
      <LayerVector visible={!isEditView} name="geometries" zIndex={999}>
        <SourceVector>{filteredFields}</SourceVector>
      </LayerVector>
    )

    return <div>{fieldsLayer}</div>
  }
}

FieldsLayer.propTypes = {
  geometries: PropTypes.arrayOf(PropTypes.object),
  deliverablesLength: PropTypes.number,
  isEditView: PropTypes.bool,
  projection: PropTypes.object
}

FieldsLayer.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

FieldsLayer.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
