import themeColors from '@layers-frontend/commons/styles/themeColors'

export const USER_ROLES = {
  ROLE_AGRO: 'ROLE_AGRO',
  ROLE_FARMER: 'ROLE_FARMER',
  ROLE_COOPERATIVE: 'ROLE_COOPERATIVE',
  ROLE_SATELLITE_PDF: 'ROLE_SATELLITE_PDF',
  ROLE_DEMO: 'ROLE_DEMO',
  ROLE_IRRIGA: 'ROLE_IRRIGA',
  ROLE_HARVEST_PLANNING_TOOL: 'ROLE_HARVEST_PLANNING_TOOL',
  ROLE_COORDS_LOGGER: 'ROLE_COORDS_LOGGER',
  ROLE_SACCHAROSE: 'ROLE_SACCHAROSE',
  ROLE_SEASON_LAYERS: 'ROLE_SEASON_LAYERS',
  ROLE_FILE_GENERATOR: 'ROLE_FILE_GENERATOR',
  ROLE_PLANET: 'ROLE_PLANET',
  ROLE_PRED_TECH: 'ROLE_PRED_TECH',
  ROLE_PREDTECH_REPORTS: 'ROLE_PREDTECH_REPORTS',
  ROLE_PREDTECH_FORECASTS: 'ROLE_PREDTECH_FORECASTS',
  ROLE_PREDTECH_AUX: 'ROLE_PREDTECH_AUX',
  ROLE_SH_CALENDAR: 'ROLE_SH_CALENDAR',
  ROLE_ORDERED_FEATURES: 'ROLE_ORDERED_FEATURES',
  ROLE_GM_SUGARCANE: 'ROLE_GM_SUGARCANE',
  ROLE_GM_SUGAR_PREDTECH: 'ROLE_GM_SUGAR_PREDTECH',
  ROLE_GM_PREDTECH: 'ROLE_GM_PREDTECH',
  ROLE_GM_SUGARCANE_PREDTECH: 'ROLE_GM_SUGARCANE_PREDTECH',
  ROLE_GM_DRONEGAPS: 'ROLE_GM_DRONEGAPS',
  ROLE_GM_DRONEWEEDS: 'ROLE_GM_DRONEWEEDS',
  ROLE_DEACTIVATE_DASHBOARD: 'ROLE_DEACTIVATE_DASHBOARD',
  ROLE_GM_DRONE_NDVI: 'ROLE_GM_DRONE_NDVI',
  ROLE_DISABLED_MOBILE: 'ROLE_DISABLED_MOBILE'
}

export const REQUEST_EOX_CAPABILITIES = 'REQUEST_EOX_CAPABILITIES'
export const RECEIVE_EOX_CAPABILITIES = 'RECEIVE_EOX_CAPABILITIES'

export const DRONE_SNAPSHOT_SOURCE = 1
export const SAT_SNAPSHOT_SOURCE = 3
export const DRONE_RGB_SNAPSHOT_SOURCE = 6
export const RADAR_SNAPSHOT_SOURCE = 7
export const RADAR_ASC_SNAPSHOT_SOURCE = 8
export const RADAR_DESC_SNAPSHOT_SOURCE = 9
export const PLANET_SNAPSHOT_SOURCE = 10
export const LANDSAT_SNAPSHOT_SOURCE = 11

export const CHUNK_SIZE = +process.env.REACT_APP_CHUNK_SIZE
export const DEFAULT_LEGEND_TYPE = 'gm_luvi_ndvi_std' // luvi_std

export const CLUSTER_ZOOM_THRESHOLD = 11

export const CATEGORICAL_COLORS = [
  '#1a53ff', // blue
  '#8be04e', // green
  '#4421af', // purple
  '#ffb55a', // orange
  '#fdcce5', // light pink
  '#0d88e6', // blue
  '#7c1158', // purple
  '#bd7ebe', // pink
  '#7eb0d5', // light blue
  '#b2e061', // light green
  '#00b7c7', // cyan
  '#beb9db', // lavender
  '#8bd3c7', // light cyan
  '#fd7f6f', // salmon
  '#b30000' // red
]

export const DAY_CYCLE_CAP = 500

export const QUERIED_GLOBAL_LAYERS_PERMISSIONS = [
  {
    id: 'gm_avg_ndvi',
    necessaryRoles: [USER_ROLES.ROLE_GM_DRONE_NDVI]
  },
  {
    id: 'gm_perc_overseeding',
    necessaryRoles: [USER_ROLES.ROLE_GM_DRONEGAPS]
  },
  {
    id: 'gm_perc_weeds',
    necessaryRoles: [USER_ROLES.ROLE_GM_DRONEWEEDS]
  }
]

export const GLOBAL_LAYERS_FRONTEND_COLOR_STRATEGY = [
  {
    id: 'gm_type_name'
  },
  {
    id: 'gm_sub_type_name'
  },
  {
    id: 'gm_sub_type_id'
  },
  {
    id: 'gm_cut_number',
    necessaryRoles: [USER_ROLES.ROLE_GM_SUGARCANE]
  },
  {
    id: 'gm_luvi_day_cycle'
  },
  {
    id: 'gm_season_label'
  },
  // {
  //   id: 'gm_lws_mean'
  // },
  {
    id: 'gm_hemav_sac_estimation',
    necessaryRoles: [USER_ROLES.ROLE_GM_SUGAR_PREDTECH]
  },
  {
    id: 'gm_harvest_estimated_date',
    necessaryRoles: [USER_ROLES.ROLE_GM_PREDTECH]
  },
  {
    id: 'gm_hemav_prodph_estimation',
    necessaryRoles: [USER_ROLES.ROLE_GM_PREDTECH]
  },
  {
    id: 'gm_hemav_atr_estimation',
    necessaryRoles: [USER_ROLES.ROLE_GM_SUGARCANE_PREDTECH]
  },
  {
    id: 'gm_hemav_pol_estimation',
    necessaryRoles: [USER_ROLES.ROLE_GM_SUGAR_PREDTECH]
  },
  {
    id: 'gm_hemav_realprodph_lastseason',
    necessaryRoles: [USER_ROLES.ROLE_GM_PREDTECH]
  }
]

export const CALENDAR_LEGENDS = {
  [SAT_SNAPSHOT_SOURCE]: {
    name: 'SAT',
    color: themeColors.fluoBlueColor,
    isLegend: true
  },
  [DRONE_SNAPSHOT_SOURCE]: {
    name: 'DRONE',
    color: themeColors.niceRedColor,
    isLegend: true
  },
  [DRONE_RGB_SNAPSHOT_SOURCE]: {
    name: 'DRONE',
    color: themeColors.niceRedColor,
    isLegend: false
  },
  [RADAR_ASC_SNAPSHOT_SOURCE]: {
    name: 'RADAR',
    color: themeColors.lightgreen,
    isLegend: true
  },
  [RADAR_DESC_SNAPSHOT_SOURCE]: {
    name: 'RADAR',
    color: themeColors.lightgreen,
    isLegend: false
  },
  [LANDSAT_SNAPSHOT_SOURCE]: {
    name: 'LD9',
    color: themeColors.fluoOrangeColor,
    isLegend: true
  },
  [PLANET_SNAPSHOT_SOURCE]: {
    name: 'PLANET',
    color: themeColors.nicePurpleColor,
    isLegend: true
  }
}

export const DELIVERABLE_TYPE_PREFIXES = {
  RADAR: ['IW_', 'EW_'],
  PLANET: ['PLANET'],
  LANDSAT: ['L9_']
}

export const DELIVERABLE_FORMATS = {
  STATIC_RANGE: 1 /**  normal deliverables */,
  DYNAMIC_RANGE: 2 /** for range deliverables ( not used already ) */,
  RAW_DOWNLOAD: 3 /** raw download is for PDF deliverables */,
  DYNAMIC_URL: 4 /** Dynamic URL are for satellite deliverables */,
  LANDSAT_PLANET_TEMP: 7
}
export const MAX_SENTINEL_URL_LENGTH = 7796

export const APPLE_STORE_URL = 'https://apps.apple.com/us/app/layers/id1559256066'
export const ANDROID_STORE_URL = 'https://play.google.com/store/apps/details?id=com.hemav.layers.app'
export const APP_DEEP_LINK_BASE_URL = 'apphemavlayers://open'

/* fetch options */

export const APPLICATION_JSON_HEADER = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE = {
  Type: 'text',
  'Content-Type': 'application/json'
}

/* special field types */
export const NO_CULTIVO_ID = 78

export const LANGUAGE = 'language'
export const UNIT_SYSTEM = 'unitSystem'
export const SURFACE_UNIT = 'surfaceUnit'
export const DEFAULT_GLOBAL_MAP = 'defaultGlobalMap'

export const USER_CONFIGURATION_OPTIONS_INPUT_TYPE = {
  [LANGUAGE]: 'select',
  [UNIT_SYSTEM]: 'buttonGroup',
  [SURFACE_UNIT]: 'buttonGroup'
}

export const LANGUAGE_OPTIONS = [
  { value: 'de', label: 'Deutsch (DE)' },
  { value: 'en', label: 'English (EN)' },
  { value: 'es', label: 'Español (ES)' },
  { value: 'fr', label: 'Français (FR)' },
  { value: 'hu', label: 'Magyar (HU)' },
  { value: 'it', label: 'Italiano (IT)' },
  { value: 'pt', label: 'Português (PT)' },
  { value: 'ro', label: 'Română (RO)' },
  { value: 'ru', label: 'Русский (RU)' },
  { value: 'sk', label: 'Slovenčina (SK)' },
  { value: 'sr', label: 'Српски (SR)' },
  { value: 'uk', label: 'Українська (UA)' }
]

export const DEFAULT_LOCALE = 'es'

export const USER_CONFIGURATION_OPTIONS = {
  [LANGUAGE]: {
    options: LANGUAGE_OPTIONS
  },
  [UNIT_SYSTEM]: [
    { value: 'metric', label: 'Metric' },
    { value: 'imperial', label: 'Imperial' }
  ],
  [SURFACE_UNIT]: [
    { value: 'ha', label: 'Hectare', shortLabel: 'Ha' },
    { value: 'acre', label: 'Acre', shortLabel: 'Ac' },
    { value: 'feddan', label: 'Feddan', shortLabel: 'Fed' }
  ]
}

export const SURFACE_UNIT_CONFIGURATION = USER_CONFIGURATION_OPTIONS[SURFACE_UNIT]

export const SEASON_PARAMETERS = {
  PLANTATION_DATE: 2,
  CUT_NUMBER: 14
}

export const SEASON_LAYERS_OMITTED_PROPERTIES_KEYS = ['color', 'hasTooltip', 'OBJECTID']

export const sampleFormFields = {
  NOTES: 'notes',
  TYPE: 'type',
  SAMPLE_CREATION_DATE: 'sampleCreationDate',
  LOCATION: 'location',
  PICTURE: 'picture',
  BASE_LAYER: 'baseLayer',
  SNAPSHOT_DATE: 'snapshotDate',
  EXTRA_PARAMETERS: 'extraParameters',
  PICTURE_TEMP_PATH: 'pictureTempPath',
  PICTURE_LOCAL_PATH: 'pictureLocalPath',
  PICTURE_PATH: 'picturePath',
  FIELD_ID: 'field',
  SEASON_ID: 'seasonId'
}

export const defaultValuesSampleFormFields = {
  [sampleFormFields.NOTES]: null,
  [sampleFormFields.SAMPLE_CREATION_DATE]: null,
  [sampleFormFields.TYPE]: null,
  [sampleFormFields.PICTURE]: null,
  [sampleFormFields.LOCATION]: null,
  [sampleFormFields.BASE_LAYER]: null,
  [sampleFormFields.SNAPSHOT_DATE]: null
}

export const PENDING_REASONS = {
  POST: 'POST',
  PATCH: 'PATCH',
  DOWNLOAD: 'DOWNLOAD'
}

export const sampleSources = {
  ANDROID: 5,
  IOS: 6,
  WEB: 7
}

export const NO_VALUE = 'no value'

export const FILETYPE = {
  PDF: 'pdf',
  CSV: 'csv'
}

// agro__field_type table crop values for translations
export const CROP_TYPES_DICTIONARY = {
  palm: 'PALMA', // 77,
  sugarcane: 'CAÑA DE AZUCAR', // 56
  beetroot: 'REMOLACHA', //31,
  soybean: 'SOJA', // 53,
  corn: 'MAIZ', // 2,
  cotton: 'ALGODON', // 59,
  vineyard: 'VIÑA', // 7,
  coffee: 'CAFE' // 73,
}
