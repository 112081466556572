import sortBy from 'lodash/sortBy'
import map from 'lodash/map'
import get from 'lodash/get'
import isPlainObject from 'lodash/isPlainObject'
import isFunction from 'lodash/isFunction'

/**
 * Translates names in the provided array of sampleExtraParameters using the specified translation function,
 * capitalizes the first letter of the translated names, and sorts the objects alphabetically by translation value.
 *
 * @param {Array<Object>} sampleExtraParameters - An array of objects containing parameters to be translated.
 * @param {Function} t - A translation function used to translate parameter names.
 * @returns {Array<Object>} - A new array of translated and sorted sampleExtraParameters objects.
 */
export const sortedParameters = (sampleExtraParameters, t) => {
  const translatedParameters = map(sampleExtraParameters, parameter => {
    if (!isPlainObject(parameter) || !isFunction(t)) {
      throw Error('this function needs to receive a sample parameter and a translation function')
    }
    const translatedName = t(parameter.name)
    const translation = translatedName ? translatedName.charAt(0).toUpperCase() + translatedName.slice(1) : ''
    const units = get(parameter, 'units')

    return {
      ...parameter,
      name: units ? `${translation} - ${units}` : `${translation}`
    }
  })
  return sortBy(translatedParameters, 'name')
}
