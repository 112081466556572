import React, { useState } from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import { Collapse, Icon, List, ListItemButton } from '@mui/material'

const activeIconSx = {
  transform: 'rotateZ(180deg)'
}

function ListCollapse({ t, children, title }) {
  const [isOpenList, setIsOpenList] = useState(false)

  const openList = () => {
    setIsOpenList(!isOpenList)
  }

  return (
    <List
      subheader={
        <ListItemButton onClick={openList}>
          {title}
          <Icon className={`fa-solid fa-angle-down`} sx={[isOpenList && activeIconSx]} />
        </ListItemButton>
      }
    >
      <Collapse in={isOpenList} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  )
}

export default withTranslator(ListCollapse)
