import React, { useEffect, useRef, useState } from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import isEmpty from 'lodash/isEmpty'
import ScreenshotImage from '../../ModalComponents/SendFieldsReportModal/ScreenshotImage'
import { StyledSampleIconButton } from './SampleForm'

const screenshotStyle = {
  width: '50%',
  margin: 0
}

function SampleImageUpload({ t, picturePath, onChange, getS3Url, isNewSampleMode, resetSampleFormPicturePath }) {
  const isVisible = !isEmpty(picturePath)
  const fileInputRef = useRef(null)
  const [src, setSrc] = useState('')

  const handleButtonClick = () => fileInputRef.current.click()

  function handleFileInputChange(event) {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      const base64Data = reader.result
      onChange(base64Data)
      setSrc(base64Data)
    }
    // eslint-disable-next-line
    reader.onerror = function (error) {
      // logic for error
    }
  }

  useEffect(() => {
    if (!isNewSampleMode && picturePath) {
      getS3Url(picturePath).then(({ url }) => setSrc(url))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const removeScreenshot = () => {
    setSrc('')
    resetSampleFormPicturePath()
  }

  if (isVisible) {
    return <ScreenshotImage hideTitle style={screenshotStyle} removeScreenshot={removeScreenshot} activeScreenshot={src} />
  }

  return (
    <StyledSampleIconButton hasValue={isVisible} title={t('add photo')} icon={'fas fa-camera'} onClick={handleButtonClick}>
      <input type="file" accept="image/*" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileInputChange} />
    </StyledSampleIconButton>
  )
}

export default withTranslator(SampleImageUpload)
