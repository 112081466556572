import React from 'react'
import LayerGroupContainer from '../../containers/olContainers/LayerGroupContainer'

export const ProspectLayers = ({ layers }) => (
  <div>
    {layers.map((layer, index) => (
      <LayerGroupContainer
        display={'all'}
        visible={layer.deliverable.visible}
        opacity={layer.deliverable.opacity}
        zIndex={layers.length - index}
        key={'prospectlayer' + index + ''}
        name={'prospectlayer' + index + ''}
        layers={[{ url: layer.deliverable.geoserver_url }]}
      />
    ))}
  </div>
)
