import { Router as FosRouter } from 'symfony-ts-router'

const router = new FosRouter()

router.locationHostName = window.location.hostname

export default router

export const initializeViewerRoutes = () => {
  return new Promise(resolve => {
    fetch('/api/routes')
      .then(response => response.json())
      .then(routes => {
        router.setRoutingData(routes)
        resolve(routes)
      })
  })
}
