import React, { useState } from 'react'
import withTranslator from './withTranslator'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import styled from 'styled-components'

const ConfirmationWrapper = styled.div`
  position: relative;
`

const ConfirmationToolTip = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  bottom: 100%;
  background-color: ${themeColors.customTooltipBackground};
  z-index: 10;
  border-radius: 0.3rem;
  cursor: default;
`

const ConfirmationOverlay = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: ${themeColors.customOverlayBackground};
  z-index: 9;
`

const ConfirmationTitle = styled.span`
  display: block;
  font-size: 1.3rem;
  text-align: center;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
`

const Button = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  margin-bottom: 0.75rem;
  i {
    color: ${props => props.color};
  }
`

const ConfirmationButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem;
`

const ArrowPointer = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  position: absolute;
  left: calc(50% - 10px);
  top: 100%;
`

const withConfirmation = (Component, onConfirmation) =>
  withTranslator(props => {
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const closeConfirmation = () => setConfirmationOpen(false)
    const confirm = () => {
      onConfirmation(props)
      closeConfirmation()
    }

    const confirmation = (
      <>
        <ConfirmationOverlay onClick={closeConfirmation} />
        <ConfirmationToolTip>
          <ConfirmationTitle>{props.t('Are You sure?')}</ConfirmationTitle>
          <ConfirmationButtonsWrapper>
            <Button onClick={closeConfirmation}>
              <i className="fa fa-chevron-left" />
            </Button>

            <Button color={themeColors.vomitColor} onClick={confirm}>
              <i className="fa fa-check" />
            </Button>
          </ConfirmationButtonsWrapper>
          <ArrowPointer />
        </ConfirmationToolTip>
      </>
    )
    return (
      <ConfirmationWrapper>
        {confirmationOpen ? confirmation : null}
        <Component {...props} setConfirmationOpen={setConfirmationOpen} />
      </ConfirmationWrapper>
    )
  })

export default withConfirmation
