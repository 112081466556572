import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import toString from 'lodash/toString'

import { selectTool, deletePolygon } from '../../actions/editor'

export const connectDrawingTools = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      hasPoints: state.editor.present.features.length > 0,
      isActiveTool: tool => toString(state.editor.present.activeTool) === toString(tool),
      currentFeatures: state.editor.present.features
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        // eslint-disable-next-line object-shorthand
        selectTool: selectTool,
        // eslint-disable-next-line object-shorthand
        deletePolygon: deletePolygon
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
