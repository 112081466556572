import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { getRequests, areRequestsLoading, hasRequestFail, areRequestsPending } from '@layers-frontend/commons/store/selectors/requests'
import { REQUEST_STATUS_SUCCESS, REQUEST_STATUS_PENDING } from '@layers-frontend/commons/store/storeConstants'
import { createSelector } from 'reselect'
import isArray from 'lodash/isArray'
import { TOP_LAYERS_BASE_Z_INDEX } from '../constants'

export { areRequestsLoading, hasRequestFail, areRequestsPending }
export const getUiRequests = getRequests

const getUI = get('UI')

export const getZoomLevel = pipe(getUI, get('zoomLevel'))

export const getViewportBBox = pipe(getUI, get('viewportBBox'))

export const getIsFlightGroupLoading = pipe(getUI, get('isFlightGroupLoading'))

export const getUiData = (state, key) => pipe(getUI, get(key))(state)

export const getSatStatisticsFetchFromDate = pipe(getUI, get('satStatisticsFetchFromDate'))

export const getRadarDataFetchFromDate = pipe(getUI, get('radarDataFetchFromDate'))

export const isUISelectedFieldsDetailsExpanded = pipe(getUI, get('isUISelectedFieldsDetailsExpanded'))

export const getUiTemporalEvolutionLayout = pipe(getUI, get('temporalEvolutionLayout'))

export const getUIRequestViewerDataInitializationStatus = pipe(getUiRequests, get('REQUEST_VIEWER_DATA_INITIALIZATION.status'))

export const getUIRequestDashboardDataInitializationStatus = pipe(getUiRequests, get('REQUEST_DASHBOARD_DATA_INITIALIZATION.status'))

export const getShouldQueryFilters = pipe(getUI, get('shouldQueryFilters'))

export const isUIRequestViewerDataInitializationPending = state => {
  const requestStatus = getUIRequestViewerDataInitializationStatus(state)

  return requestStatus === REQUEST_STATUS_PENDING
}

export const isUIRequestViewerDataInitializationSuccess = state => {
  const requestStatus = getUIRequestViewerDataInitializationStatus(state)

  return requestStatus === REQUEST_STATUS_SUCCESS
}

export const isUIRequestDashboardDataInitializationSuccess = state => {
  const requestStatus = getUIRequestDashboardDataInitializationStatus(state)

  return requestStatus === REQUEST_STATUS_SUCCESS
}

export const getUIScreenshotOverlayVisibility = pipe(getUI, get('screenshotOverlayVisibility'))

export const getUISeasonLayers = pipe(getUI, get('seasonLayers'))

export const getSeasonLayersBaseZIndex = pipe(getUI, get('baseZIndexSeasonLayers'))

export const getGroupDeliverablesBaseZIndex = pipe(getUI, get('baseZIndexGroupDeliverables'))

export const getUISeasonLayersOrderMap = createSelector(getUISeasonLayers, layers => {
  if (isArray(layers)) {
    return layers.reduce(
      (layersOrdered, layer, index) => ({
        ...layersOrdered,
        [get('type.id')(layer)]: layers.length - index
      }),
      {}
    )
  }
  return {}
})

export const isSeasonLayerTabActive = state => {
  const seasonLayersZIndex = getSeasonLayersBaseZIndex(state)
  return seasonLayersZIndex === TOP_LAYERS_BASE_Z_INDEX
}

export const shouldShowSamplesInMap = pipe(getUI, get('showSamplesInMap'))

export const getSelectedFieldAsideExpanded = pipe(getUI, get('selectedFieldAsideExpanded'))

export const getToolbarsDisabled = pipe(getUI, get('toolbarsDisabled'))

export const getUiFieldsUpdatedAt = pipe(getUI, get('fieldsUpdatedAt'))

export const getSamplesMode = pipe(getUI, get('samplesMode'))

export const getSearchBarListMode = pipe(getUI, get('searchBarListMode'))

export const isShowCurrentSeasonReportsMode = pipe(getUI, get('isShowCurrentSeasonReportsMode'))

export const isSampleFormOpen = pipe(getUI, get('isSampleFormOpen'))
