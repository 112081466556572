import React, { useState } from 'react'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import capitalize from 'lodash/capitalize'
import first from 'lodash/first'
import { Tab, Tabs } from '@mui/material'
import { StyledTooltip } from '../../UIComponents/StyledTooltip'

const Wrapper = styled.div`
  .tabs {
    & > div {
      background-color: transparent !important;
    }
  }
`

const TabContentWrapper = styled.div`
  padding: 1.5rem;
  max-height: 30vh;
`

export const muiStyles = {
  inkBarStyle: {
    backgroundColor: themeColors.vomitColor,
    height: 2
  },
  buttonStyle: {
    height: '4rem',
    color: themeColors.whiteColor,
    fontWeight: 500,
    fontSize: 15,
    textTransform: 'initial'
  },
  buttonStyleError: {
    height: '4rem',
    color: themeColors.redColor,
    fontSize: 15
  }
}

export default function FieldSeasonFormContent({ tabs = [] }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [tooltipText, setTooltipText] = useState(null)
  const handleChange = (_, index) => setActiveTabIndex(index)
  const openTooltipWithText = text => () => setTooltipText(capitalize(text))
  const closeTooltip = () => setTooltipText(null)

  return (
    <Wrapper>
      <StyledTooltip title={<>{tooltipText}</>} open={!!tooltipText} followCursor placement="top">
        <Tabs variant="fullWidth" onChange={handleChange} value={activeTabIndex} className="tabs" textColor="inherit">
          {/* REMOVE first() to enable Advance parameters */}
          {first(
            tabs.map((tab, tabIndex) => (
              <Tab
                key={tabIndex}
                value={tabIndex}
                label={capitalize(tab.label)}
                sx={tab.hasError ? muiStyles.buttonStyleError : muiStyles.buttonStyle}
                onMouseEnter={openTooltipWithText(tab.tooltipText)}
                onMouseLeave={closeTooltip}
              />
            ))
          )}
        </Tabs>
        <SwipeableViews index={activeTabIndex}>
          {tabs.map(({ content }, tabIndex) => (
            <TabContentWrapper key={tabIndex}>{content}</TabContentWrapper>
          ))}
        </SwipeableViews>
      </StyledTooltip>
    </Wrapper>
  )
}
