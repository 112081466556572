import { useState, useCallback } from 'react'
import toNumber from 'lodash/toNumber'
import isFinite from 'lodash/isFinite'
import replace from 'lodash/replace'
import includes from 'lodash/includes'
import some from 'lodash/some'
import toString from 'lodash/toString'

const floatForbiddenChars = [' ', '\n']
const hasForbiddenChar = value => some(floatForbiddenChars, char => includes(value, char))

export default function useFloat({ value, onChangeText, minValue, maxValue }) {
  const [inputStringValue, setInputStringValue] = useState(isFinite(value) ? `${value}` : '')

  const onChangeTextWrapper = useCallback(
    valueFromInputEvent => {
      if (hasForbiddenChar(valueFromInputEvent)) {
        return
      }

      const formattedValue = replace(valueFromInputEvent, ',', '.')
      const numberValue = toNumber(formattedValue)

      if (isFinite(numberValue)) {
        if (formattedValue === '') {
          onChangeText(null)
          return setInputStringValue('')
        }

        if (minValue && maxValue) {
          const minNumber = Number(minValue)
          const maxNumber = Number(maxValue)
          if (numberValue >= minNumber && numberValue <= maxNumber) {
            onChangeText(numberValue)
            setInputStringValue(toString(numberValue))
          }
        } else {
          onChangeText(numberValue)
          setInputStringValue(formattedValue)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChangeText]
  )

  return { value: inputStringValue, onChangeText: onChangeTextWrapper }
}
