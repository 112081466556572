import themeColors from '@layers-frontend/commons/styles/themeColors'
import { NO_VALUE } from '@layers-frontend/commons/constants'
import map from 'lodash/map'

// db legend parse
export const legendParser = values => {
  const parsedLegend = map(values, (value, index) => {
    const parsedValue = +value.name
    const nan = isNaN(parsedValue)
    if (nan) {
      return {
        ...value,
        visible: true,
        isScaleFromDB: true,
        isScaleTypeCategoric: true,
        color: themeColors.grayColor,
        range: NO_VALUE,
        label: NO_VALUE
      }
    }
    const firstValue = parsedValue

    const nextValue = values[index + 1] ? +values[index + 1].name : Infinity
    const label = nextValue !== Infinity ? `${firstValue} - ${nextValue}` : `>${firstValue}`
    return {
      ...value, // includes value.color
      visible: true,
      isScaleFromDB: true,
      isScaleTypeCategoric: true,
      range: [firstValue, nextValue],
      label
    }
  })
  return parsedLegend
}
