import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import isString from 'lodash/isString'
import isUndefined from 'lodash/isUndefined'
import values from 'lodash/fp/values'
import some from 'lodash/fp/some'
import every from 'lodash/fp/every'
import map from 'lodash/fp/map'
import pickBy from 'lodash/fp/pickBy'
import isEqual from 'lodash/fp/isEqual'
import { REQUEST_STATUS_SUCCESS, REQUEST_STATUS_PENDING, REQUEST_STATUS_FAIL } from '../storeConstants'

const isRequestLoading = requestStatus => {
  if (isUndefined(requestStatus)) {
    return false
  }

  if (requestStatus !== REQUEST_STATUS_SUCCESS && requestStatus !== REQUEST_STATUS_FAIL) {
    return true
  }

  return false
}

const isRequestSuccessful = requestStatus => requestStatus === REQUEST_STATUS_SUCCESS

export const getRequestTypeStatus = requestType => pipe(get(requestType), get('status'))

export const getRequests = pipe(get('requests'))

const getRequestsWithOverlay = pipe(getRequests, pickBy('overlay'))

export const areRequestsLoading = (state, requests) => {
  const stateRequests = getRequests(state)

  if (isString(requests)) {
    const requestStatus = getRequestTypeStatus(requests)(stateRequests)

    return isRequestLoading(requestStatus)
  }

  return some(requestType => {
    const requestStatus = getRequestTypeStatus(requestType)(stateRequests)

    return isRequestLoading(requestStatus)
  })(requests)
}

export const areRequestsSuccessful = (state, requests) => {
  const stateRequests = getRequests(state)
  return every(requestType => {
    const requestStatus = getRequestTypeStatus(requestType)(stateRequests)
    return isRequestSuccessful(requestStatus)
  })(requests)
}

export const hasRequestFail = (state, request) => pipe(getRequests, getRequestTypeStatus)(state) === REQUEST_STATUS_FAIL

export const areRequestsPending = pipe(getRequests, values, map('status'), some(isEqual(REQUEST_STATUS_PENDING)))

export const areRequestsWithOverlayPending = pipe(getRequestsWithOverlay, values, map('status'), some(isEqual(REQUEST_STATUS_PENDING)))

export const getPendingRequests = pipe(getRequests, pickBy(['status', REQUEST_STATUS_PENDING]))
