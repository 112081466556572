import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../../components/HOCComponents/withTranslator'
import FieldsComparisonChartsMaxCloudCoverageInput from '../../../components/DashboardComponents/DashboadFilters/FieldsComparisonChartsMaxCloudCoverageInput'
import { setMaxCloudCoverage } from '../../../actions/dashboard'
import { getMaxCloudCoverage } from '../../../selectors/dashboard'

const mapStateToProps = state => ({
  maxCloudCoverage: getMaxCloudCoverage(state)
})
const mapDispatchToProps = {
  setMaxCloudCoverage
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(FieldsComparisonChartsMaxCloudCoverageInput)
