export const SET_MESSAGES = 'SET_MESSAGES'

// requests
export const REQUEST_START = 'REQUEST_START'
export const REQUEST_END = 'REQUEST_END'
export const REQUEST_FAIL = 'REQUEST_FAIL'

export const REQUEST_STATUS_PENDING = 'PENDING'
export const REQUEST_STATUS_SUCCESS = 'SUCCESS'
export const REQUEST_STATUS_FAIL = 'FAIL'
export const REQUEST_NOT_AUTHORIZED_BY_ROLE = 'REQUEST_NOT_AUTHORIZED_BY_ROLE'

// reports

export const REQUEST_REPORTS = 'REQUEST_REPORTS'
export const REQUEST_REPORTS_STATUSES = 'REQUEST_REPORTS_STATUSES'
export const REQUEST_REPORTS_TYPES = 'REQUEST_REPORTS_TYPES'
export const REQUEST_UPDATE_REPORT = 'REQUEST_UPDATE_REPORT'
export const REQUEST_UPDATE_REPORTS_IN_SELECTION = 'REQUEST_UPDATE_REPORTS_IN_SELECTION'

export const RECEIVE_REPORT_TYPES = 'RECEIVE_REPORT_TYPES'
export const RECEIVE_REPORTS = 'RECEIVE_REPORTS'
export const RECEIVE_REPORT_STATUSES = 'RECEIVE_REPORT_STATUSES'
export const UPDATE_REPORT_SUCCESS = 'UPDATE_REPORT_SUCCESS'
export const SELECT_REPORT = 'SELECT_REPORT'
export const RESET_SELECTED_REPORT = 'SELECT_REPORT'

export const ADD_SEARCH_AGGREGATE_TO_REPORTS = 'ADD_SEARCH_AGGREGATE_TO_REPORTS'

export const REQUEST_DELETE_REPORT = 'REQUEST_DELETE_REPORT'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'

// fieldsTileOrder
export const REQUEST_FIELDS_TILE_ORDER = 'REQUEST_FIELDS_TILE_ORDER'
export const RECEIVE_FIELDS_TILE_ORDER = 'RECEIVE_FIELDS_TILE_ORDER'

// sampleForm

export const SET_SAMPLE_FORM_FIELD = 'SET_SAMPLE_FORM_FIELD'
export const DENY_SAMPLE_LOCATION = 'DENY_SAMPLE_LOCATION'
export const EDIT_SAMPLE = 'EDIT_SAMPLE'
export const RESET_SAMPLE_FORM_FIELDS = 'RESET_SAMPLE_FORM_FIELDS'
export const REQUEST_POST_SAMPLE = 'REQUEST_POST_SAMPLE'
export const REQUEST_PATCH_SAMPLE = 'REQUEST_PATCH_SAMPLE'
export const REQUEST_DELETE_SAMPLE = 'REQUEST_DELETE_SAMPLE'
export const RECEIVE_POSTED_SAMPLE = 'RECEIVE_POSTED_SAMPLE'
export const ADD_SAMPLE_FORM_EXTRA_PARAMETER = 'ADD_SAMPLE_FORM_EXTRA_PARAMETER'
export const REMOVE_SAMPLE_FORM_EXTRA_PARAMETER = 'REMOVE_SAMPLE_FORM_EXTRA_PARAMETER'
export const SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID = 'SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID'
export const FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS = 'FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS'

// sampleTypes

export const REQUEST_SAMPLE_TYPES = 'REQUEST_SAMPLE_TYPES'
export const REQUEST_SAMPLE_TYPES_SUCCESS = 'REQUEST_SAMPLE_TYPES_SUCCESS'

// sampleParametersByType

export const REQUEST_SAMPLE_PARAMETERS_BY_TYPE = 'REQUEST_SAMPLE_PARAMETERS_BY_TYPE'
export const RECEIVE__SAMPLE_PARAMETERS_BY_TYPE = 'RECEIVE__SAMPLE_PARAMETERS_BY_TYPE'

// user
export const RECEIVE_AZURE_CLIENT = 'RECEIVE_AZURE_CLIENT'

// global S3 files for reports
export const REQUEST_GLOBAL_FILES_SUCCESS = 'REQUEST_GLOBAL_FILES_SUCCESS'
export const REQUEST_GLOBAL_FILES = 'REQUEST_GLOBAL_FILES'

export const REQUEST_DOWNLOAD_HISTOGRAM_SUCCESS = 'REQUEST_DOWNLOAD_HISTOGRAM_SUCCESS'
export const REQUEST_DOWNLOAD_HISTOGRAM = 'REQUEST_DOWNLOAD_HISTOGRAM'
