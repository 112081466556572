import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SearchBarContainer from '../../containers/SearchContainers/SearchBarContainer'
import { ActionButtonContainer } from '../../containers/ToolsContainers/ToolsContainers'

import { SEARCH_VIEW } from '../../actions/viewmode'
import { ROUTES } from '../../constants'

import { LayerBox } from '../DeliverablesComponents/LayerBox'
import { DeliverableItem } from '../DeliverablesComponents/DeliverableItem'
import ToggleSearchBarContainer from '../../containers/TopBar/ToggleSearchBarContainer'
import styled from 'styled-components'
import SearchBarFiltersAndListContainer from '../../containers/SearchContainers/SearchBarFiltersAndListContainer'

export const AdvancedFiltersWrapper = styled.div`
  display: flex;
`

const SearchWrapper = styled.div`
  position: absolute;
  background-color: rgba(8, 44, 63, 0.8);
  height: calc(100vh - 60px);
  left: 0;
  right: 0;
  top: 100%;
`

const BottonsWrapper = styled.div`
  display: flex;
`

const SearchView = ({
  isAppReady,
  prospectLayers,
  isFocus,
  hasDeleted,
  isSearchView,
  onToggleVisibility,
  onToggleLayer,
  onChangeOpacity,
  t,
  currentLocation,
  totalFieldCount
}) => {
  // Create a ref to hold the wrapper element
  const WrapperRef = useRef(null)

  function setWrapperRef(node) {
    WrapperRef.current = node
  }

  const [originalFieldCount, setOriginalFieldCount] = useState() // total original fields for client

  const currentView = currentLocation.view
  const currentRoute = currentLocation.route
  const isSearchBarVisible = currentView === SEARCH_VIEW || currentRoute === ROUTES.DASHBOARD_ROUTE

  useEffect(() => {
    if (!originalFieldCount) {
      setOriginalFieldCount(totalFieldCount)
    }
  }, [totalFieldCount, originalFieldCount])

  if (isAppReady)
    return (
      <>
        <div ref={setWrapperRef} className={`searchListContainer ${isFocus ? 'withFocus' : ''}`}>
          <SearchBarContainer isVisible={isSearchBarVisible} fieldCount={originalFieldCount}>
            <ToggleSearchBarContainer />
            <BottonsWrapper>
              <ActionButtonContainer testIdCrossBtn="clear-fields-button" />
            </BottonsWrapper>
            {isFocus && (
              <SearchWrapper>
                <SearchBarFiltersAndListContainer totalFieldCount={totalFieldCount} />
              </SearchWrapper>
            )}
          </SearchBarContainer>
        </div>
        {hasDeleted && <div className="deleteNotify">{t('Your field has been successfully deleted.')}</div>}
        <div className="layerBoxes">
          <LayerBox
            layers={prospectLayers}
            renderLayer={(layer, index) => (
              <DeliverableItem
                idx={index}
                index={index}
                key={index}
                deliverable={layer.deliverable}
                isTemporal={false}
                toggleVisibility={() => onToggleVisibility(layer)}
                toggleDeliverable={() => onToggleLayer(layer)}
                changeOpacity={opacity => onChangeOpacity(layer, opacity)}
              />
            )}
            visible={isSearchView}
          />
        </div>
      </>
    )
  return null
}

SearchView.propTypes = {
  filters: PropTypes.object,
  hasDeleted: PropTypes.bool,
  isFocus: PropTypes.bool,
  currentLocation: PropTypes.object,
  totalFieldCount: PropTypes.number
}

export default SearchView
