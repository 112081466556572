import React from 'react'
import styled, { keyframes, css } from 'styled-components'

//========================================================================================
//
//    ###    ##     ##  ##  ###    ###    ###    ######  ##   #####   ##     ##   ####
//   ## ##   ####   ##  ##  ## #  # ##   ## ##     ##    ##  ##   ##  ####   ##  ##
//  ##   ##  ##  ## ##  ##  ##  ##  ##  ##   ##    ##    ##  ##   ##  ##  ## ##   ###
//  #######  ##    ###  ##  ##      ##  #######    ##    ##  ##   ##  ##    ###     ##
//  ##   ##  ##     ##  ##  ##      ##  ##   ##    ##    ##   #####   ##     ##  ####
//
//========================================================================================

const Logo = props => {
  return (
    <svg {...props} viewBox="0 0 150 150">
      <g fill="none">
        <g id="bottom" className="st1">
          <path
            style={{ fill: '#6c7009' }}
            d="m81.2 150.5c-14.9-12.7-28.7-26.5-41.4-41.5-1.9-2.3-1.9-6.1 0-8.4 12.7-15 26.5-28.7 41.4-41.4 2.3-1.9 6.1-1.9 8.4 0 15 12.7 28.8 26.4 41.5 41.4 1.9 2.3 1.9 6.1 0 8.4-12.7 15-26.5 28.8-41.5 41.5-2.3 1.9-6.1 1.9-8.4 0z"
          />
          <path
            fillRule="evenodd"
            style={{ fill: '#5cc4d4' }}
            d="m85.4 152c-1.5 0-3.1-0.5-4.2-1.4-14.9-12.7-28.9-26.6-41.5-41.5-2-2.4-2-6.2 0-8.5 12.6-14.9 26.6-28.9 41.5-41.5 2.3-2 6.2-2 8.5 0 14.9 12.6 28.8 26.6 41.5 41.5 1.9 2.3 1.9 6.1 0 8.5-12.7 14.9-26.6 28.8-41.5 41.5-1.2 0.9-2.7 1.4-4.3 1.4zm-4.1-1.6c2.3 1.9 6 1.9 8.2 0 14.9-12.6 28.9-26.6 41.5-41.5 1.9-2.2 1.9-5.9 0-8.2-12.6-14.9-26.6-28.8-41.5-41.4-2.2-1.9-5.9-1.9-8.2 0-14.9 12.6-28.8 26.5-41.4 41.4-1.9 2.3-1.9 6 0 8.2 12.6 14.9 26.5 28.9 41.4 41.5z"
          />
        </g>
        <g id="middle" style={{ opacity: '.8' }} className="st2">
          <path
            style={{ fill: '#c1bf2f' }}
            d="m81.2 131.7c-14.9-12.7-28.7-26.4-41.4-41.4-1.9-2.3-1.9-6.1 0-8.4 12.7-15 26.5-28.8 41.4-41.4 2.3-2 6.1-2 8.4 0 15 12.6 28.8 26.4 41.5 41.4 1.9 2.3 1.9 6.1 0 8.4-12.7 15-26.5 28.7-41.5 41.4-2.3 1.9-6.1 1.9-8.4 0z"
          />
          <path
            fillRule="evenodd"
            style={{ fill: '#5cc4d4' }}
            d="m85.4 133.4c-1.5 0-3.1-0.5-4.3-1.5-14.9-12.6-28.9-26.6-41.5-41.5-2-2.4-2-6.3 0-8.7 12.6-14.9 26.6-28.8 41.5-41.4 2.4-2.1 6.3-2.1 8.7 0 14.9 12.6 28.8 26.5 41.5 41.4 2 2.4 2 6.3 0 8.7-12.7 14.9-26.6 28.9-41.5 41.5-1.2 1-2.8 1.5-4.4 1.5zm-4-1.9c2.2 1.9 5.8 1.9 8.1 0 14.8-12.6 28.8-26.5 41.4-41.4 1.8-2.2 1.8-5.8 0-8-12.6-14.9-26.6-28.9-41.4-41.4-2.3-1.9-5.9-1.9-8.1 0-14.9 12.5-28.8 26.5-41.4 41.4-1.9 2.2-1.9 5.8 0 8 12.6 14.9 26.5 28.8 41.4 41.4z"
          />
        </g>
        <g id="top" style={{ opacity: '.8' }} className="st3">
          <path
            style={{ fill: '#ded000' }}
            d="m81.2 112.6c-14.9-12.7-28.7-26.5-41.4-41.5-1.9-2.3-1.9-6.1 0-8.4 12.7-14.9 26.5-28.7 41.4-41.4 2.3-1.9 6.1-1.9 8.4 0 15 12.7 28.8 26.5 41.5 41.4 1.9 2.3 1.9 6.1 0 8.4-12.7 15-26.5 28.8-41.5 41.5-2.3 1.9-6.1 1.9-8.4 0z"
          />
          <path
            fillRule="evenodd"
            style={{ fill: '#5cc4d4' }}
            d="m85.4 114.5c-1.6 0-3.2-0.5-4.5-1.6-14.9-12.6-28.9-26.5-41.5-41.5-2.1-2.4-2.1-6.5 0-9 12.6-14.9 26.6-28.9 41.5-41.5 2.5-2.1 6.6-2.1 9 0 15 12.6 28.9 26.6 41.5 41.5 2.1 2.5 2.1 6.6 0 9-12.6 15-26.5 28.9-41.5 41.5-1.2 1.1-2.8 1.6-4.5 1.6zm-3.8-2.3c2.1 1.7 5.6 1.7 7.7 0 14.9-12.6 28.8-26.5 41.4-41.4 1.7-2.1 1.7-5.6 0-7.7-12.6-14.9-26.5-28.8-41.4-41.4-2.1-1.8-5.6-1.8-7.7 0-14.9 12.6-28.8 26.5-41.4 41.4-1.8 2.1-1.8 5.6 0 7.7 12.6 14.9 26.5 28.8 41.4 41.4z"
          />
        </g>
      </g>
    </svg>
  )
}

const pulse = keyframes`
0% {
  transform: scale(0.85);
  transform-origin: center;
}
100% {
  transform: scale(1);
  opacity: 1;
  transform-origin: center;
}
`

const animation = props => {
  if (props.pulse)
    return css`
      ${pulse} 1.5s ease-in-out  infinite;
    `
}

//===============================================================================================================================
//
//  ##       #####    ####     #####          ####   #####   ###    ###  #####    #####   ##     ##  #####  ##     ##  ######
//  ##      ##   ##  ##       ##   ##        ##     ##   ##  ## #  # ##  ##  ##  ##   ##  ####   ##  ##     ####   ##    ##
//  ##      ##   ##  ##  ###  ##   ##        ##     ##   ##  ##  ##  ##  #####   ##   ##  ##  ## ##  #####  ##  ## ##    ##
//  ##      ##   ##  ##   ##  ##   ##        ##     ##   ##  ##      ##  ##      ##   ##  ##    ###  ##     ##    ###    ##
//  ######   #####    ####     #####          ####   #####   ##      ##  ##       #####   ##     ##  #####  ##     ##    ##
//
//===============================================================================================================================

const LayersLogo = props => {
  const StyledLayersLogo = styled(({ vSlide, rotate, fade, pulse, shuffle, bounce, horizontalSlide, ...props }) => <Logo {...props} />)`
    height: auto;
    width: 180px;
    display: inline-block;
    margin: auto;
    overflow: visible !important;
    .st0 {
      fill: none;
    }
    .st1 {
      animation: ${animation};
      animation-delay: ${props.delay1 || '-1s'};
      opacity: 0.7;
      fill: #6a701b;
      transform-origin: center;
    }
    .st2 {
      animation: ${animation};
      animation-delay: ${props.delay2 || '-2s'};
      opacity: 0.75;
      fill: #e2e20c;
      transform-origin: center;
    }
    .st3 {
      animation: ${animation};
      animation-delay: ${props.delay3 || '-3s'};
      opacity: 0.8;
      fill: #dfdf81;
      transform-origin: center;
    }
  `
  return <StyledLayersLogo pulse />
}

export default LayersLogo
