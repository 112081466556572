import { connect } from 'react-redux'
import { compose } from 'redux'
import ModalBase from '../../components/ModalComponents/ModalBase'
import { isModalOpen, getModalComponentName, getModalProp, getModalProps, isModalVisible } from '../../selectors/modal'
import { closeModal } from '../../actions/modal'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

const mapStateToProps = state => ({
  isModalOpen: isModalOpen(state),
  isModalVisible: isModalVisible(state),
  openModalComponentName: getModalComponentName(state),
  modalTitle: getModalProp(state, 'title'),
  modalProps: getModalProps(state)
})

const mapDispatchToProps = {
  closeModal
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(ModalBase)
