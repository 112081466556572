import React from 'react'
import { useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { isDashboardRoute, isViewerRoute, isSampleImageRoute, isBiLabsRoute, isAccountsRoute } from '../../utils'
import { getDashboardView } from '../../selectors/dashboard'
import { getScreenView } from '../../selectors/viewmode'

//hoc with access to state to use selectors

const mapStateToProps = state => ({
  dashboardView: getDashboardView(state),
  screenView: getScreenView(state)
})

export function withCurrentRoute(WrappedComponent) {
  const ComponentWithCurrentRoute = props => {
    const location = useLocation()

    const isDashboard = isDashboardRoute(location)
    const isViewer = isViewerRoute(location)
    const isSampleImage = isSampleImageRoute(location)
    const isBiLabs = isBiLabsRoute(location)
    const isAccounts = isAccountsRoute(location)
    const route = isDashboard ? 'dashboard' : 'viewer'
    const view = isDashboard ? props.dashboardView : props.screenView

    const currentLocation = {
      isDashboard,
      isViewer,
      isSampleImage,
      isBiLabs,
      isAccounts,
      route,
      view
    }

    return <WrappedComponent {...props} currentLocation={currentLocation} />
  }

  return compose(connect(mapStateToProps))(ComponentWithCurrentRoute)
}
