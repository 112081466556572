import last from 'lodash/last'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { dashboardViews } from '../constants'

import { getHarvesPlanningToolFieldsOptions } from '../selectors/dashboard'

// CONSTANTS

export const TOGGLE_DASH_FILTER = 'TOGGLE_DASH_FILTER'
export const SET_DASH_FILTERS_ACTIVE = 'SET_DASH_FILTERS_ACTIVE'
export const SET_TOP_BAR_VISIBILITY = 'SET_TOP_BAR_VISIBILITY'
export const SET_MAX_CLOUD_COVERAGE = 'SET_MAX_CLOUD_COVERAGE'
export const SET_TEMPORAL_EVOLUTION_MAX_CLOUD_COVERAGE = 'SET_TEMPORAL_EVOLUTION_MAX_CLOUD_COVERAGE'
export const TOGGLE_FIELDS_COMPARISON_ORDER_BY_DATE = 'TOGGLE_FIELDS_COMPARISON_ORDER_BY_DATE'
export const TOP_BAR_OPTIONS = dashboardViews

export const MAX_CLOUD_COVERAGE_INITIAL_STATE = 100
export const FIELDS_COMPARISON_ORDER_BY_DATE_INITIAL_STATE = 'asc'

export const DASHBOARD_FILTERS_TYPES = {
  CUSTOMER: 'customer',
  CROP: 'crop',
  FIELDS: 'fields',
  PLANTATION_DATE_RANGE: 'plantationDateRange',
  FLIGHT_DRONE: 'flightDrone',
  FLIGHT_SATELLITE: 'flightSatellite',
  DATE_RANGE: 'dateRange',
  HARVEST_PLANNING_TOOL_CROP_TYPE_SELECT: 'harvestPlanningToolCropTypeSelect',
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT: 'TemporalEvolutionMetadataDateRangeSelect',
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO: 'TemporalEvolutionMetadataDateRangeSelectTo',
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM: 'TemporalEvolutionMetadataDateRangeSelectFrom',
  TEMPORAL_EVOLUTION_APPLIED_DATE_FILTER: 'TemporalEvolutionAppliedDateFilter',
  HARVEST_PLANNING_TOOL_FIELDS_SELECT: 'harvestPlanningMultiFieldsSelect',
  HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN: 'harvestPlanningToolDryMatterRangeMin',
  HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX: 'harvestPlanningToolDryMatterRangeMax',
  METEOROLOGICAL_STATISTICS_FIELDS_SELECT: 'meteorologicalStatisticsFieldsSelect',
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_TO: 'meteorologicalStatisticsDateRangeTo',
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_FROM: 'meteorologicalStatisticsDateRangeFrom',
  METEOROLOGICAL_STATISTICS_BASE_TEMPERATURE: 'meteorologicalStatisticsBaseTemperature',
  HARVEST_PLANNING_TOOL_FILTER_TYPE_SELECT: 'harvestPlanningToolFilterTypeSelect',
  HARVEST_PLANNING_TOOL_METADATA_KEY_SELECT: 'harvestPlanningToolMetadataKeySelect',
  FIELDS_COMPARISON_DATE_RANGE_SELECT: 'fieldComparisonDateRangeSelect',
  FIELDS_COMPARISON_FILTERS_RANGE_KEY: 'fieldsComparisonFiltersRangeKey',
  FIELDS_COMPARISON_FILTERS_MIN: 'fieldsComparisonFiltersMin',
  FIELDS_COMPARISON_FILTERS_MAX: 'fieldsComparisonFiltersMax',
  FIELDS_COMPARISON_ORDER_BY_DATE: 'fieldsComparisonOrderByDate'
}

const { FIELD_FILTER, FLIGHT_FILTER, FIELD_TEMPORAL_EVOLUTION, HARVEST_PLANNING_TOOL, METEOROLOGICAL_STATISTICS } = TOP_BAR_OPTIONS

const {
  FLIGHT_DRONE,
  FLIGHT_SATELLITE,
  DATE_RANGE,
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT,
  TEMPORAL_EVOLUTION_APPLIED_DATE_FILTER,
  HARVEST_PLANNING_TOOL_CROP_TYPE_SELECT,
  HARVEST_PLANNING_TOOL_FIELDS_SELECT,
  HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN,
  HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX,
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_TO,
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_FROM,
  METEOROLOGICAL_STATISTICS_BASE_TEMPERATURE,
  FIELDS_COMPARISON_DATE_RANGE_SELECT
} = DASHBOARD_FILTERS_TYPES

export const FILTER_OPTIONS = {
  [FIELD_FILTER]: [FLIGHT_SATELLITE, FLIGHT_DRONE, FIELDS_COMPARISON_DATE_RANGE_SELECT],
  [FLIGHT_FILTER]: [DATE_RANGE, FLIGHT_SATELLITE, FLIGHT_DRONE],
  [FIELD_TEMPORAL_EVOLUTION]: [TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT],
  [HARVEST_PLANNING_TOOL]: [
    HARVEST_PLANNING_TOOL_CROP_TYPE_SELECT,
    HARVEST_PLANNING_TOOL_FIELDS_SELECT,
    HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MIN,
    HARVEST_PLANNING_TOOL_DRY_MATTER_RANGE_MAX
  ],
  [METEOROLOGICAL_STATISTICS]: []
}

// ACTION CREATORS

export const toggleDashFilter = (type, value) => (dispatch, getState) => {
  if (type === HARVEST_PLANNING_TOOL_FIELDS_SELECT && last(value) === -1) {
    if (value.length === 1) {
      const fields = getHarvesPlanningToolFieldsOptions(getState())
      const filteredFields = filter(fields, item => !item.disabled && item.id !== -1)
      const ids = map(filteredFields, 'id')

      return dispatch({
        type: TOGGLE_DASH_FILTER,
        payload: { type, value: ids }
      })
    }

    if (value.length > 1) {
      return dispatch({
        type: TOGGLE_DASH_FILTER,
        payload: { type, value: [] }
      })
    }
  }

  return dispatch({
    type: TOGGLE_DASH_FILTER,
    // eslint-disable-next-line object-shorthand
    payload: { type: type, value: value }
  })
}

export const applyTemporalEvolutionDateFilter = ({ from, to }) => toggleDashFilter(TEMPORAL_EVOLUTION_APPLIED_DATE_FILTER, { from, to })

export const setMeteorologicalStatisticsDateRangeFrom = from => toggleDashFilter(METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_FROM, from)
export const setMeteorologicalStatisticsDateRangeTo = to => toggleDashFilter(METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_TO, to)
export const setMeteorologicalStatisticsBaseTemp = temperature => toggleDashFilter(METEOROLOGICAL_STATISTICS_BASE_TEMPERATURE, temperature)

export const resetMaxCloudCoverage = () => setMaxCloudCoverage(MAX_CLOUD_COVERAGE_INITIAL_STATE)

export const resetFieldsComparisonFilters = () => dispatch => {
  dispatch(resetMaxCloudCoverage())
}

export const setTopBarVisibility = selectedTopBar => dispatch => {
  const activeFilters = FILTER_OPTIONS[selectedTopBar]
  dispatch({
    type: SET_TOP_BAR_VISIBILITY,
    selectedTopBar,
    activeFilters
  })
}

export const setMaxCloudCoverage = cloudCoverage => ({
  type: SET_MAX_CLOUD_COVERAGE,
  cloudCoverage: Number(cloudCoverage)
})

export const setTemporalEvolutionMaxCloudCoverage = cloudCoverage => ({
  type: SET_TEMPORAL_EVOLUTION_MAX_CLOUD_COVERAGE,
  cloudCoverage: Number(cloudCoverage)
})

export const toggleFieldsComparisonOrderByDate = () => ({
  type: TOGGLE_FIELDS_COMPARISON_ORDER_BY_DATE
})
