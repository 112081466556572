import React from 'react'
import { connect } from 'react-redux'

export const authorize = (userRoles, roles) => {
  let authorization = false

  roles.forEach(role => {
    if (userRoles.includes(role)) authorization = true
  })

  return authorization
}

export const withAuthentication = (roles = []) => WrappedComponent => {
  class Authorize extends React.Component {
    constructor(props) {
      super(props)

      // eslint-disable-next-line prefer-const
      let isAuthorized = this.authorizeUser(roles)
      this.state = {
        // eslint-disable-next-line object-shorthand
        isAuthorized: isAuthorized
      }
    }

    authorizeUser = roles => authorize(this.props.UserRoles, roles)

    render() {
      if (this.state.isAuthorized) return <WrappedComponent {...this.props} />

      return null
    }
  }

  function mapStateToProps(state) {
    // REDACTED
    return {
      UserRoles: state.user.roles || []
    }
  }

  return connect(mapStateToProps)(Authorize)
}
