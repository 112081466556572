import React from 'react'
import PropTypes from 'prop-types'

import OLComponent from '../ol-component'
// ol
import OlPolygon from 'ol/geom/Polygon'
import OlGeometry from 'ol/geom/Geometry'
import OlMap from 'ol/Map'
import OlFeature from 'ol/Feature'

export default class Polygon extends OLComponent {
  constructor(props) {
    super(props)
    this.geometry = new OlPolygon([])
    this.updateFromProps(props)
  }

  updateFromProps(props) {
    this.geometry.setCoordinates(this.props.children)
    this.geometry.transform(props.projection.from, props.projection.to)
  }

  componentDidMount() {
    this.context.feature.setGeometry(this.geometry)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateFromProps(newProps)
  }

  render() {
    return <div>{this.props.notification}</div>
  }

  getChildContext() {
    return {
      geometry: this.geometry,
      map: this.context.map
    }
  }
}

Polygon.contextTypes = {
  feature: PropTypes.instanceOf(OlFeature),
  map: PropTypes.instanceOf(OlMap)
}

Polygon.childContextTypes = {
  geometry: PropTypes.instanceOf(OlGeometry),
  map: PropTypes.instanceOf(OlMap)
}
