import get from 'lodash/fp/get'
import { getSelectedSample } from './samples'
import { metadataToExtraParamterers } from '@layers-frontend/commons/helpers/extraParameters/extraParameters'
import { getSampleParametersByTypeId } from '@layers-frontend/commons/store/selectors/sampleParametersByType'

export const getSelectedSampleNormalizedParameters = state => {
  const sample = getSelectedSample(state)
  const sampleMetadata = get('metadata')(sample)
  const sampleTypeId = get('type.id')(sample)
  const parameters = getSampleParametersByTypeId(sampleTypeId)(state)
  return metadataToExtraParamterers(sampleMetadata, parameters)
}
