const s3Bucket = process.env.REACT_APP_BUCKET
const baseTilerUrl = `${process.env.REACT_APP_TILER_BASE_URL}/cog/tiles/{z}/{x}/{y}.png?scale=2&TileMatrixSetId=WebMercatorQuad&resampling_method=nearest&return_mask=true&url=`

const s3PathToUrl = s3Path => {
  return `${s3Bucket}/${s3Path}`
}

export const s3PathToTilerUrl = s3Path => {
  const s3Url = s3PathToUrl(s3Path)
  return `${baseTilerUrl}${s3Url}`
}
