import { connect } from 'react-redux'
import { compose } from 'redux'
import invoke from 'lodash/invoke'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import SearchBarFilters from '../../components/SearchComponents/SearchBarFilters'
import isNil from 'lodash/isNil'
import {
  getSelectedFilter,
  getSearchData,
  getSearch,
  getSearchFilters,
  getSearchSortBy,
  hasCutNumberFilter,
  hasSeasonLabelFilter
} from '../../selectors/search'
import { getSelectedFields } from '../../selectors/fields'

import {
  resetCropFilters,
  setCropFilters,
  resetCustomerFilters,
  setCustomerFilters,
  resetPlantationDateFilters,
  setCutNumberFilters,
  resetCutNumberFilters,
  setSeasonLabelFilters,
  resetSeasonLabelFilters
} from '../../actions/filters'
import { addFieldsToGroup, flushFieldsGroup } from '../../actions/fields'
import { toggleSearchSortBy } from '../../actions/search'
import { getSearchBarListMode } from '../../selectors/ui'
import { setSearchBarListMode } from '../../actions/ui'

const mapStateToProps = (state, { fields, currentLocation }) => {
  return {
    overlay: !isNil(getSelectedFilter(state)),
    isAdvancedSearch: getSearchData(state, 'element'),
    hasFilters: invoke(getSearch(state), 'hasFilters'),
    filters: getSearchFilters(state),
    selectedFields: getSelectedFields(state),
    fields,
    sortedBy: getSearchSortBy(state),
    hasCutNumber: hasCutNumberFilter(state),
    searchBarListMode: getSearchBarListMode(state),
    hasSeasonLabel: hasSeasonLabelFilter(state),
    currentLocation
  }
}

const mapDispatchToProps = dispatch => ({
  setCropFilters: cropId => dispatch(setCropFilters(cropId)),
  setCustomerFilters: customerId => dispatch(setCustomerFilters(customerId)),
  resetCropFilters: () => dispatch(resetCropFilters()),
  resetCustomerFilters: () => dispatch(resetCustomerFilters()),
  resetPlantationDateFilters: () => dispatch(resetPlantationDateFilters()),
  resetCutNumberFilters: () => dispatch(resetCutNumberFilters()),
  resetSeasonLabelFilters: () => dispatch(resetSeasonLabelFilters()),
  addFieldsToGroup: fieldsIds => dispatch(addFieldsToGroup(fieldsIds)),
  flushFieldsGroup: () => dispatch(flushFieldsGroup()),
  toggleSearchSortBy: () => dispatch(toggleSearchSortBy()),
  setCutNumberFilters: cutNumber => dispatch(setCutNumberFilters(cutNumber)),
  setSearchBarListMode: mode => dispatch(setSearchBarListMode(mode)),
  setSeasonLabelFilters: seasonLayer => dispatch(setSeasonLabelFilters(seasonLayer))
})

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(SearchBarFilters)
