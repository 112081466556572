import styled, { css } from 'styled-components'
import toString from 'lodash/toString'

export const DateBox = styled.div`
  position: absolute;
  top: 60px;
  padding: 2px 4px;
  background: #082c3f;
  width: 60px;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  color: #cccccc;

  ${props =>
    toString(props.align) === 'left' &&
    css`
      left: -60px;
      border-bottom-left-radius: 3px;
    `};

  ${props =>
    toString(props.align) === 'right' &&
    css`
      left: 10px;
      border-bottom-right-radius: 3px;
    `};
`
