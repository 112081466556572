import React, { useState } from 'react'
import { Slider } from '@mui/material'
import { styled } from '@mui/material/styles'

const CustomSlider = styled(Slider)(props => ({
  height: 4,
  '&>.MuiSlider-rail': {
    backgroundImage: `url(${props.bg})`,
    backgroundSize: 'contain',
    opacity: 1
  },
  '&>.MuiSlider-thumb': {
    '&[data-index="0"]': {
      color: `${props.lefthandlecolor} !important`
    },
    '&[data-index="1"]': {
      color: `${props.righthandlecolor} !important`
    }
  },
  '& .MuiSlider-valueLabel': {
    fontSize: 12,
    background: 'unset',
    top: 50
  }
}))

const SliderWrapper = styled('div')({
  padding: '12px 20px 32px 25px',
  overflow: 'hidden'
})

const ColoredRangedSlider = props => {
  const [innerValue, setInnerValue] = useState(props.value)

  const handleChange = (_, value) => {
    setInnerValue(value)
  }

  const handleCommittedChange = () => {
    props.onChange(innerValue)
  }

  const valueLabelFormat = value => Math.round(value * 100) / 100

  // check if props.min and props.max are apart by more than 1
  // if so, set step to 1
  // else, set step to 0.01
  const step = props.max - props.min > 1 ? 1 : 0.01

  return (
    <SliderWrapper>
      <CustomSlider
        lefthandlecolor={props.scale(innerValue[0])}
        righthandlecolor={props.scale(innerValue[1])}
        bg={props.bg}
        valueLabelDisplay="on"
        track={false}
        onChange={handleChange}
        onChangeCommitted={handleCommittedChange}
        valueLabelFormat={valueLabelFormat}
        value={innerValue}
        min={props.min}
        max={props.max}
        step={step}
      />
    </SliderWrapper>
  )
}

export default ColoredRangedSlider
