import { REQUEST_START, REQUEST_END, REQUEST_FAIL, REQUEST_STATUS_PENDING, REQUEST_STATUS_SUCCESS, REQUEST_STATUS_FAIL } from '../storeConstants'

const initialState = {}

export default function requests(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_START:
      return {
        ...state,
        [action.requestType]: {
          status: REQUEST_STATUS_PENDING,
          overlay: action.overlay
        }
      }

    case REQUEST_END:
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          status: REQUEST_STATUS_SUCCESS
        }
      }

    case REQUEST_FAIL:
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          status: REQUEST_STATUS_FAIL
        }
      }

    default:
      return state
  }
}
