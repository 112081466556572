import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../../theme'
import ReportTypesRadioButtons from './ReportTypesRadioButtons'
import { HalfColumn, FullSizeContainer, Row, Column } from '../../DashboardComponents/Layout'
import ModalTitle from './ModalTitle'
import NameInput from './NameInput'
import FieldsSelector from './FieldsSelector'
import RecipientEmailChips from './RecipientEmailChips'
import Notes from './Notes'
import ScreenshotImage from './ScreenshotImage'
import CaptureScreenshotButton from './CaptureScreenshotButton'
import RelatedDatePicker from './RelatedDatePicker'
import AffectedHaInput from './AffectedHaInput'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { FIELDS_REPORT_KEYS } from '../../../constants'

const { RECIPIENT_EMAIL_INPUT, RECIPIENT_EMAILS, REPORT_TYPE_ID, SENDER_NAME, SELECTED_FIELDS_IDS, NOTES } = FIELDS_REPORT_KEYS

const SendFieldsReportModalWrapper = styled.div`
  overflow: auto;
  height: auto;
  min-height: 500px;
  max-height: 90vh;
  position: relative;
  padding: 0 1.5rem 1.5rem 1.5rem;
  background: ${UIStyles.darkBlue};
`

const CloseModalButton = styled.button`
  background-color: ${UIStyles.darkBlue};
  color: ${UIStyles.whiteColor};
  border: none;
  position: absolute;
  z-index: 1;
  right: 1.5rem;
  top: 1.5rem;
  font-size: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 2.5rem;
  width: 2.5rem;
  i.fas {
    width: auto;
    height: auto;
  }
  &:hover {
    color: ${UIStyles.redColor};
  }
`

const StyledSendButton = styled.button`
  border: 1px solid ${UIStyles.lightBlue};
  color: ${UIStyles.whiteColor};
  background: none;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-weight: 600;
  border-radius: 0.3rem;
  display: block;
  margin: auto;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:hover {
    border: 1px solid ${UIStyles.vomitColor};
    color: ${UIStyles.vomitColor};
  }
`

const JustifyRight = styled.div`
  display: flex;
  justify-content: flex-end;
`
const WithCustomErrorWrapper = styled.div`
  position: relative;
`
const TextError = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: ${UIStyles.errorRed};
  position: absolute;
  bottom: -1.5rem;
  margin: 0;
`

const createErrorNode = error => withTranslator(({ t }) => <WithCustomErrorWrapper>{error ? <TextError>{t(error)}</TextError> : null}</WithCustomErrorWrapper>)

export default function SendFieldsReportModal({
  t,
  addRecipientEmailToFieldsReportModal,
  appSelectedFields,
  captureScreenshotForFieldsReportModal,
  removeScreenshotForFieldsReportModal,
  closeAndResetFieldsReportModal,
  errors,
  recipentEmails,
  recipientInput,
  notes,
  removeRecipientEmailFromFieldsReportModal,
  reportTypes,
  screenshotSource,
  screenshotPath,
  senderName,
  setFieldsReportModalSenderName,
  setRecipientEmailInputToFieldsReportModal,
  setReportTypeIdForFieldsReportModal,
  setFieldsReportModalSelectedFieldsIds,
  modalSelectedFieldsIds,
  setFieldsReportModalNotes,
  selectedReportId,
  validateAndSendFieldsReport,
  setFieldsReportModalAffectedHa,
  setFieldsReportModalRelatedDate,
  affectedHa,
  relatedDate,
  reportId,
  openReportManagerModal,
  surfaceUnit
}) {
  const {
    [RECIPIENT_EMAIL_INPUT]: recipientInputError,
    [SENDER_NAME]: senderNameError,
    [RECIPIENT_EMAILS]: recipientEmailsError,
    [NOTES]: notesError,
    [REPORT_TYPE_ID]: reportTypeError,
    [SELECTED_FIELDS_IDS]: selectFieldsError
  } = errors

  const activeScreenshot = screenshotSource || screenshotPath

  const ReportTypesRadioButtonsErrorNode = createErrorNode(reportTypeError)

  const NotesErrorNode = createErrorNode(notesError)

  const handleClose = () => {
    reportId ? openReportManagerModal() : closeAndResetFieldsReportModal()
  }

  return (
    <SendFieldsReportModalWrapper>
      <CloseModalButton onClick={handleClose}>
        <i className="fas fa-times" />
      </CloseModalButton>
      <FullSizeContainer>
        <Row>
          <ModalTitle />
        </Row>
        <Row noMarginBottom>
          <HalfColumn>
            <ReportTypesRadioButtons
              selectedReportId={selectedReportId}
              reportTypes={reportTypes}
              setReportId={setReportTypeIdForFieldsReportModal}
              errorNode={<ReportTypesRadioButtonsErrorNode />}
            />
          </HalfColumn>
          <HalfColumn>
            <Row>
              <Column width="50%">
                <AffectedHaInput surfaceUnit={surfaceUnit} setAffectedHa={setFieldsReportModalAffectedHa} affectedHa={affectedHa} />
              </Column>
              <Column width="50%">
                <RelatedDatePicker setRelatedDate={setFieldsReportModalRelatedDate} relatedDate={relatedDate} />
              </Column>
            </Row>
          </HalfColumn>
          <HalfColumn>
            <NameInput senderName={senderName} setSenderName={setFieldsReportModalSenderName} error={senderNameError} />
            <FieldsSelector
              appSelectedFields={appSelectedFields}
              selectedIds={modalSelectedFieldsIds}
              setSelectedFieldsIds={setFieldsReportModalSelectedFieldsIds}
              error={selectFieldsError}
            />
          </HalfColumn>
          <HalfColumn>
            <RecipientEmailChips
              error={[recipientInputError, recipientEmailsError]}
              setRecipientEmailInput={setRecipientEmailInputToFieldsReportModal}
              addRecipientEmail={addRecipientEmailToFieldsReportModal}
              recipientInput={recipientInput}
              recipentEmails={recipentEmails}
              removeChip={removeRecipientEmailFromFieldsReportModal}
            />
          </HalfColumn>
        </Row>
        <Row noMarginBottom>
          <Column width={activeScreenshot ? '70%' : '100%'}>
            <Notes storeNotes={notes} setFieldsReportModalNotes={setFieldsReportModalNotes} error={notesError} errorNode={<NotesErrorNode />} />
          </Column>
          <Column width={activeScreenshot ? '30%' : '0%'}>
            <ScreenshotImage
              hideTitle
              captureScreenshot={captureScreenshotForFieldsReportModal}
              removeScreenshot={removeScreenshotForFieldsReportModal}
              activeScreenshot={activeScreenshot}
            />
          </Column>
        </Row>
        <Row noMarginBottom>
          <Column>
            <JustifyRight>
              <CaptureScreenshotButton onClick={captureScreenshotForFieldsReportModal} visible={!activeScreenshot} />
            </JustifyRight>
          </Column>
        </Row>
      </FullSizeContainer>
      <StyledSendButton onClick={validateAndSendFieldsReport}>{t('Send')}</StyledSendButton>
    </SendFieldsReportModalWrapper>
  )
}
