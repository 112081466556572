import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import values from 'lodash/fp/values'
import some from 'lodash/fp/some'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'
import { sampleFormFields } from '../../constants'

export const getSamplesForm = get('samplesForm')

export const getSamplesFormPendingReason = pipe(getSamplesForm, get('pendingReason'))

export const getSamplesFormNotes = pipe(getSamplesForm, get(sampleFormFields.NOTES))

export const getSamplesFormLocation = pipe(getSamplesForm, get(sampleFormFields.LOCATION))

export const getSamplesFormType = pipe(getSamplesForm, get(sampleFormFields.TYPE))

export const getSamplesFormTypeId = pipe(getSamplesFormType, get('id'))

export const getSamplesFormPictureTempPath = pipe(getSamplesForm, get(sampleFormFields.PICTURE_TEMP_PATH))

export const getSamplesFormPictureLocalPath = pipe(getSamplesForm, get('pictureLocalPath'))

export const getSamplesFormPicturePath = pipe(getSamplesForm, get('picturePath'))

export const getSamplesFormSnapshotDate = pipe(getSamplesForm, get(sampleFormFields.SNAPSHOT_DATE))

export const getSamplesFormBaseLayerId = pipe(getSamplesForm, get(sampleFormFields.BASE_LAYER))

export const getSampleFormUniqueId = pipe(getSamplesForm, get('uniqueId'))

export const getSamplesFormSampleId = pipe(getSamplesForm, get('id'))

export const getSamplesFormSampleCreationDate = pipe(getSamplesForm, get('sampleCreationDate'))

export const getSamplesFormOriginalSample = pipe(getSamplesForm, get('originalSample'))

export const getSamplesFormExtraParameters = pipe(getSamplesForm, get(sampleFormFields.EXTRA_PARAMETERS))

export const getSamplesFormExtraParametersOrder = pipe(getSamplesForm, get('extraParametersOrder'))

export const isEditingSamplesForm = createSelector(
  getSamplesForm,
  pipe(
    values,
    some(formValue => !isEmpty(formValue))
  )
)
