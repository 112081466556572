import React, { useEffect } from 'react'
import MapContainer from '../containers/olContainers/MapContainer'
import View from './olComponents/view'
import BaseMap from './olComponents/baseMap'
import ViewContainer from '../containers/PagesContainers/ViewContainer'
import AppButtons from './PagesComponents/AppButtons'
import MapLayers from './LayersComponents/MapLayers'
import useLocalStorage from '../hooks/localStorage/useLocalStorage'

import get from 'lodash/get'

const ViewerApp = props => {
  const {
    isViewerDataLoaded,
    baseMap,
    isModalOpen,
    isTutorial,
    isLoading,
    projection,
    eoxXML,
    view,
    zoomLevel,
    baseMapsSelectedIndex,
    selectBaseMap,
    initializeViewer,
    isViewerReady
  } = props

  const [baseMapIndex, setBaseMapIndex] = useLocalStorage('configBasemapIndex', 0)

  useEffect(() => {
    if (!isViewerDataLoaded) {
      initializeViewer()
      if (baseMapIndex !== baseMapsSelectedIndex) selectBaseMap(baseMapIndex) // dispatch the selection of the basemap with localStorage saved index on initialization
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isViewerDataLoaded])

  useEffect(() => {
    if (baseMapIndex !== baseMapsSelectedIndex) setBaseMapIndex(baseMapsSelectedIndex) // set localStorage with selected basemap index
  }, [baseMapsSelectedIndex, baseMapIndex, setBaseMapIndex])

  return (
    <div id="viewer" className={'ViewerApp ' + (isModalOpen ? 'blur ' : '') + (isTutorial ? 'cursorhelp' : '')}>
      <ViewContainer />
      <AppButtons visible={isViewerReady} />
      <div className={isLoading ? 'blur' : 'loaded-view'}>
        <MapContainer view={<View projection={projection} center={[-3.707398, 40.415363]} />}>
          <BaseMap eoxXML={eoxXML} sources={get(baseMap, 'sources')} key={get(baseMap, 'type')} projection={projection} />
          <MapLayers zoomLevel={zoomLevel} visible={isViewerReady} view={view} />
        </MapContainer>
      </div>
    </div>
  )
}

export default ViewerApp
