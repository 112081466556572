import React from 'react'
import { useForm } from 'react-hook-form'
import { useCreateTokenMutation } from '../../features/api/authApiSlice'
import Input from '@mui/material/Input'
import InputLabel from '@mui/material/InputLabel'
import { UIStyles } from '../../theme'
import { Button } from '@mui/material'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

export const LoginForm = ({ t }) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const [createToken] = useCreateTokenMutation()

  const selectUserStatus = useSelector(state => state.userLayers.user.status)

  const onSubmit = ({ username, password }) => {
    createToken({ username, password })
  }

  return (
    <FormWrapper onSubmit={handleSubmit(onSubmit)}>
      <div>
        <InputLabel htmlFor="username" style={labelFieldStyle}>
          {t('Username')}
        </InputLabel>
        <Input
          type="email"
          {...register('username', { required: 'Username is required' })}
          style={textFieldStyle}
          id="username" // "input-user"
          inputProps={{ 'data-testid': 'user-input' }}
          autoComplete="username"
        />
        {errors.username && <p>{errors.username.message}</p>}
      </div>
      <div>
        <InputLabel htmlFor="password" style={labelFieldStyle}>
          {t('Password')}
        </InputLabel>
        <Input
          type="password"
          id="password" // "input-password"
          {...register('password', { required: 'Password is required' })}
          style={textFieldStyle}
          inputProps={{ 'data-testid': 'password-input' }}
          autoComplete="password"
        />
        {errors.password && <p>{errors.password.message}</p>}
      </div>
      <Button type="submit" id="login" data-testid="new-login-button" style={buttonStyle} disabled={selectUserStatus === 'loading'}>
        {t('Login')}
      </Button>
    </FormWrapper>
  )
}
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  color: ${UIStyles.whiteColor};
  font-size: 12px;
`

const textFieldStyle = { width: '230px', fontSize: '15px', marginBottom: '10px' }
const labelFieldStyle = { fontSize: '12px', color: `${UIStyles.vomitColor}`, paddingLeft: '6px' }

const buttonStyle = {
  background: '#235B80',
  padding: '10px 30px',
  margin: '10px 0 15px',
  color: `${UIStyles.vomitColor}`,
  borderRadius: '10px'
}
