import { connect } from 'react-redux'
import { compose } from 'redux'
import { connectLocale } from '../../components/HOCComponents/connectLocale'
import DownloadStatisticsModal from '../../components/ModalComponents/DownloadStatisticsModal'
import { getSelectedFields, getFieldsLength, getSelectedFieldsLength } from '../../selectors/fields'
import { getMetadatasDateRange } from '../../selectors/config'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { setModalProps } from '../../actions/modal'
import { isDownloadStatisticsButtonEnabled } from '../../selectors/downloadables'
import { getModalProp } from '../../selectors/modal'
import { fetchStatisticsModal } from '../../actions/downloadables'
import toNumber from 'lodash/toNumber'

const mapStateToProps = state => ({
  selectedFields: getSelectedFields(state),
  fieldsLength: getFieldsLength(state),
  selectedFieldsLength: toNumber(getSelectedFieldsLength(state)),
  excelTotalDays: getMetadatasDateRange(state),
  modalFrom: getModalProp(state, 'from'),
  modalTo: getModalProp(state, 'to'),
  isDownloadButtonEnabled: isDownloadStatisticsButtonEnabled(state)
})

const mapDispatchToProps = { setModalProps, fetchStatisticsModal }

export default compose(connectLocale, withTranslator, connect(mapStateToProps, mapDispatchToProps))(DownloadStatisticsModal)
