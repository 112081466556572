import { closeModal, toggleModalVisibility } from './modal'
import { closeCreationMode, closeEditMode, closeReadOnlyMode, openSearchMode } from './viewmode'

import { toggleToolbarsDisabled, setFieldsUpdatedAt } from './ui'
import { getEditorPresentData } from '../selectors/editor'
import { isNewField, isReadOnly } from '../selectors/fieldSeasonForm'
import { ActionCreators } from 'redux-undo'
import { createReduxEmptySeason, fieldResponseToReduxField } from '../seasonFormUtils'
import {
  updateFieldIds,
  addFieldsToGroup,
  fetchGeometriesByFieldIds,
  deleteFieldFromList,
  flushFieldsGroup,
  removeField,
  resetPreviewFieldId,
  selectFields
} from './fields'
import { resetAllFilters } from './filters'
import { successNotification } from './notifications'

export const TOGGLE_FIELD_SEASON_FORM = 'TOGGLE_FIELD_SEASON_FORM'
export const SET_ACTIVE_SEASON_INDEX = 'SELECT_ACTIVE_SEASON_INDEX'
export const ADD_SEASON = 'ADD_SEASON'
export const ADD_SEASONS = 'ADD_SEASONS'
export const ADD_SELECTED_FIELD_SEASONS = 'ADD_SELECTED_FIELD_SEASONS'
export const SET_FIELD_SEASON_FORM_SEASON_GEOMETRY_AND_SURFACE = 'SET_FIELD_SEASON_FORM_SEASON_GEOMETRY_AND_SURFACE'
export const RESET_FIELD_SEASON_FORM_EDITING_FEATURES = 'RESET_FIELD_SEASON_FORM_EDITING_FEATURES'
export const UPDATE_FIELD_AND_SEASONS = 'UPDATE_FIELD_AND_SEASONS'

const toggleFieldSeasonForm = () => ({
  type: TOGGLE_FIELD_SEASON_FORM
})

const closeNewFieldSeasonFormModal = () => dispatch => {
  dispatch(closeCreationMode())
  return dispatch(closeModal())
}

const closeEditFieldSeasonFormModal = () => dispatch => {
  dispatch(closeEditMode())
  return dispatch(closeModal())
}

const closeReadOnlyFieldSeasonFormModal = () => dispatch => {
  dispatch(resetPreviewFieldId())
  dispatch(closeReadOnlyMode())
  return dispatch(closeModal())
}

export const toggleFieldSeasonFormEditor = () => dispatch => {
  dispatch(toggleModalVisibility())
  dispatch(toggleToolbarsDisabled())
  dispatch(toggleFieldSeasonForm())
}

export const denyFieldSeasonFormGeometryAndSurface = () => dispatch => {
  dispatch(ActionCreators.jumpToPast(0))
  dispatch(ActionCreators.clearHistory())
  dispatch(toggleFieldSeasonFormEditor())
}

export const setActiveSeasonIndex = index => ({ type: SET_ACTIVE_SEASON_INDEX, index })

export const addSeason = season => ({ type: ADD_SEASON, season })

export const addSeasons = seasons => ({ type: ADD_SEASONS, seasons })

export const resetEditingFeaturesAndSurface = () => ({ type: RESET_FIELD_SEASON_FORM_EDITING_FEATURES })

export const confirmFieldSeasonFormGeometryAndSurface = () => (dispatch, getState) => {
  const state = getState()
  const surface = getEditorPresentData(state, 'surface')
  const features = JSON.stringify(getEditorPresentData(state, 'features'))

  dispatch({
    type: SET_FIELD_SEASON_FORM_SEASON_GEOMETRY_AND_SURFACE,
    editingGeometry: {
      surface,
      features
    }
  })
  dispatch(toggleFieldSeasonFormEditor())
}

export const createNewFieldSeasons = () => addSeasons([createReduxEmptySeason()])

export const closeFieldSeasonForm = () => (dispatch, getState) => {
  const state = getState()
  if (isNewField(state)) {
    return dispatch(closeNewFieldSeasonFormModal())
  }
  if (isReadOnly(state)) {
    return dispatch(closeReadOnlyFieldSeasonFormModal())
  }
  dispatch(closeEditFieldSeasonFormModal())
}

export const updateFieldAndSeasons = fieldFromResponse => dispatch => {
  const field = fieldResponseToReduxField(fieldFromResponse)

  dispatch(closeFieldSeasonForm())
  dispatch(flushFieldsGroup())
  dispatch(addFieldsToGroup([field.id]))
  dispatch(fetchGeometriesByFieldIds([field.id]))
  dispatch(updateFieldIds(field.id))
  dispatch(selectFields())
  dispatch(setFieldsUpdatedAt())
}

export const updateFieldAfterDeleting = fieldId => dispatch => {
  dispatch(closeModal())
  dispatch(openSearchMode(true))
  dispatch(resetAllFilters())
  dispatch(deleteFieldFromList(fieldId))
  dispatch(removeField(fieldId))
  dispatch(successNotification('DeleteField'))
}
