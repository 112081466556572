import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { openGlobalFilesModal } from '../../actions/modal'
import GlobalFilesButton from '../../components/ToolsComponents/GlobalFilesButton'
import { getUserId, getUserRoles } from '@layers-frontend/commons/store/selectors/user'
import { getGlobalFiles } from '@layers-frontend/commons/store/selectors/reports'
import { fetchGlobalFiles } from '../../actions/globalFiles'

const mapStateToProps = state => ({
  user: getUserId(state),
  globalFiles: getGlobalFiles(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = {
  openGlobalFilesModal,
  fetchGlobalFiles
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(GlobalFilesButton)
