import React from 'react'
import styled from 'styled-components'

const IframeWrapper = styled.iframe`
  overflow: auto;
  width: 100%;
  height: calc(100% - 60px);
  justify-content: center;
  align-items: center;
  border: 0;
  padding-top: 16px;
  background: white;
`
/**
 *
 * @param {url} url - the URL of the page to embed
 * @param {title} title - value to describe the embedded content for a11y
 * @returns the emdedded external HTML content into the current container
 */

export default function Iframe({ url, title }) {
  if (!url) return null

  return <IframeWrapper src={url} title={title}></IframeWrapper>
}
