import PropTypes from 'prop-types'
import OLInteraction from './ol-interaction'

import OlModify from 'ol/interaction/Modify'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'
import OlCollection from 'ol/Collection'

export default class Modify extends OLInteraction {
  constructor(props, context) {
    super(props, context)

    this.interaction = this.createInteraction(props, context)
    this.eventHandlerKeys_ = {}
  }

  createInteraction(props, context) {
    return new OlModify({
      condition: props.condition,
      source: context.source
    })
  }
}

Modify.propTypes = Object.assign({}, OLInteraction.propTypes, {
  condition: PropTypes.func,
  modifyend: PropTypes.func,
  modifystart: PropTypes.func,
  features: PropTypes.instanceOf(OlCollection)
})

Modify.contextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}

Modify.olEvents = ['modifyend', 'modifystart']
