import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { ToggleSearchBar } from '../../components/TopBar/ToggleSearchBar.js'
import { toggleSearch } from '../../actions/search'
import { isSearchFocus } from '../../selectors/search'
import withTranslator from '../../components/HOCComponents/withTranslator.js'

const mapStateToProps = state => {
  return {
    isFocus: isSearchFocus(state)
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ toggleSearch }, dispatch)
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(ToggleSearchBar)
