import { fetchGraphqlWrapper } from './ui'
import { loader } from 'graphql.macro'
import { REQUEST_SAMPLE_TYPES, REQUEST_SAMPLE_TYPES_SUCCESS } from '@layers-frontend/commons/store/storeConstants'

const getSampleTypesQuery = loader('../graphql/samples/getSampleTypes.gql').loc.source.body

export const fetchSampleTypes = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_SAMPLE_TYPES,
    query: getSampleTypesQuery,
    onSuccess: ({ types }) => ({ type: REQUEST_SAMPLE_TYPES_SUCCESS, sampleTypes: types })
  })
