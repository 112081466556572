import React, { useEffect, useCallback } from 'react'
import { HistogramChart } from '../DashboardComponents/Charts'
import { SATELLITE_TYPE, DRONE_TYPE, RADAR_DESC_TYPE, DRONE_RGB_TYPE, FETCH_STATISTICS_DEBOUNCE_TIME } from '../../constants'
import { ChartTitle, FullSizeContainer, Row, BoxContainer, FullWidthColumn, Column } from './Layout'
import moment from 'moment'
import some from 'lodash/some'
import map from 'lodash/map'
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import FieldsComparisonChartsFiltersContainer from '../../containers/DashboardContainers/FieldsComparisonChartsFiltersContainer'
import SwitchLayoutIconContainer from '../../containers/DashboardContainers/SwitchLayoutIconContainer'
import { GRID_LAYOUT } from '../../actions/ui'
import styled from 'styled-components'
import Loader from '../Loader/Loader'

const SwitchLayoutWrapper = styled.div`
  display: flex;
  flex: 1;
  margin-right: 15px;
  justify-content: flex-end;
  align-items: center;
`

const formatChartData = ({ data = {}, valueName, positiveOnly = true }) => {
  return reduce(
    data,
    (accumulator, snapshot) => {
      const season = get(snapshot, 'season')
      const field = get(season, 'field')
      const customer = get(snapshot, 'customer_name')
      const fieldName = get(snapshot, 'field_name')
      const fieldId = get(snapshot, 'field_id')
      const flightDate = moment(get(snapshot, 'flight_date')).format('YYYY-MM-DD')
      const value = get(snapshot, `properties[${valueName}]`)

      if (!value) {
        return accumulator
      }

      const removeArrayFromValue = isArray(value) ? Number(value[0]) : Number(value)
      let formattedValue
      if (positiveOnly) {
        formattedValue = removeArrayFromValue < 0 ? 0 : removeArrayFromValue
      } else {
        formattedValue = removeArrayFromValue
      }

      return [
        ...accumulator,
        {
          cloud_coverage: get(snapshot, 'cloud_coverage'),
          customer,
          customer_id: get(customer, 'id'),
          dealer: get(customer, 'agrouser.commercial_name', ''),
          dealer_code: get(customer, 'agrouser.agro_code', ''),
          external_reference_id: get(field, 'external_reference_id', null),
          field: fieldName,
          field_id: fieldId,
          field_type: get(season, 'type.name'),
          flight_date: flightDate,
          label: `${fieldName} (${flightDate})`,
          name: fieldName,
          [valueName]: formattedValue,
          properties: get(snapshot, 'properties', {}),
          snapshot_id: get(snapshot, 'snapshot_id'),
          source: get(snapshot, 'source'),
          type: get(season, 'type.name'),
          type_id: get(season, 'type.id'),
          update_date: get(snapshot, 'update_date')
        }
      ]
    },
    []
  )
}

export default function FieldsComparisonCharts(props) {
  const {
    goToField,
    selectedFields,
    selectedFieldsLength,
    t,
    selectedFlightFilterId,
    fieldsIdsMap,
    chartsLayout,
    fetchFieldComparisonStatistics,
    fieldComparisonFetchDateRange,
    fieldComparisonOrder,
    fieldComparisonStatistics,
    isLoading
  } = props

  const isSatelliteTypeFlightFilterActive = String(selectedFlightFilterId) === SATELLITE_TYPE
  const selectedFieldsIds = map(selectedFields, field => `${field.id}`)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchFieldComparisonStatistics = useCallback(debounce(fetchFieldComparisonStatistics, FETCH_STATISTICS_DEBOUNCE_TIME), [])

  useEffect(() => {
    debouncedFetchFieldComparisonStatistics({ fieldIds: selectedFieldsIds, isSatelliteTypeFlightFilterActive, fieldComparisonFetchDateRange })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldsLength, selectedFlightFilterId, fieldComparisonFetchDateRange])

  const dronesStatistics = [...fieldComparisonStatistics[DRONE_TYPE], ...fieldComparisonStatistics[DRONE_RGB_TYPE]]

  const currentStatistic = isSatelliteTypeFlightFilterActive ? fieldComparisonStatistics[SATELLITE_TYPE] : dronesStatistics

  const currentRadarData = isSatelliteTypeFlightFilterActive ? fieldComparisonStatistics[RADAR_DESC_TYPE] : []

  const hydricStatusTranslations = {
    [DRONE_TYPE]: t('hydric status drone'),
    [SATELLITE_TYPE]: t('nitrogen chlorophyll (ndre)')
  }

  // pre-calc charts/filters shared data
  const currentlyDisplayedDroneSatMetadata = formatChartData({ data: currentStatistic, valueName: 'ndvi_std' })
  const currentlyDisplayedDroneRgbMetadata = formatChartData({ data: currentStatistic, valueName: 'lines_l' })
  const currentlyDisplayedRadarMetadata = formatChartData({ data: currentRadarData, valueName: 'mean_sigma0' })

  const chartsProps = [
    {
      title: `${t('ndvi')} (0-1)`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'ndvi_mean' }),
      YLabel: 'ndvi_mean',
      label: `${t('ndvi')} (0-1)`,
      onClick: goToField('TARGET')
    },
    {
      title: `${t('variability')} (%)`,
      subtitle: t('mean'),
      withAverage: true,
      data: currentlyDisplayedDroneSatMetadata,
      YLabel: 'ndvi_std',
      label: `${t('variability')} (%)`,
      onClick: goToField('VARIABILITY')
    },
    {
      title: `${t('faults map')} (% ${t('area')})`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'overs_perc' }),
      YLabel: 'overs_perc',
      label: `${t('faults map')} (% ${t('area')})`,
      onClick: goToField('OVERSEEDING')
    },
    {
      title: `${t('faults map')} (% ${t('linear meters')})`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'overs_pl' }),
      YLabel: 'overs_pl',
      label: `${t('faults map')} (% ${t('linear meters')})`,
      onClick: goToField('OVERSEEDING')
    },
    {
      title: t('count (units)'),
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'plant_count' }),
      YLabel: 'plant_count',
      label: t('count (units)'),
      onClick: goToField(undefined)
    },
    {
      title: t('vegetative growth'),
      subtitle: t('mean'),
      withAverage: true,
      data: currentlyDisplayedRadarMetadata,
      YLabel: 'mean_sigma0',
      label: t('vegetative growth'),
      onClick: goToField(undefined)
    },
    {
      title: `${t('vegetative growth variability')} (std)`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentRadarData, valueName: 'std_sigma0' }),
      YLabel: 'std_sigma0',
      label: `${t('vegetative growth variability')} (std)`,
      onClick: goToField(undefined)
    },
    {
      title: hydricStatusTranslations[selectedFlightFilterId],
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'ndre_mean' }),
      YLabel: 'ndre_mean',
      label: hydricStatusTranslations[selectedFlightFilterId],
      onClick: goToField(undefined)
    },
    {
      title: `${t('hydric status')}`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'moisture_mean', positiveOnly: false }),
      YLabel: 'moisture_mean',
      label: t('hydric status'),
      onClick: goToField(undefined)
    },
    {
      title: t('nitrogen'),
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'nitr_mean', fieldsIdsMap }),
      YLabel: 'nitr_mean',
      label: t('nitrogen'),
      onClick: goToField(undefined)
    },
    {
      title: t('relative nitrogen'),
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'nrel_mean' }),
      YLabel: 'nrel_mean',
      label: t('relative nitrogen'),
      onClick: goToField(undefined)
    },
    {
      title: t('phosporus'),
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'phos_mean', fieldsIdsMap }),
      YLabel: 'phos_mean',
      label: t('phosporus'),
      onClick: goToField(undefined)
    },
    {
      title: t('potassium'),
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'pot_mean', fieldsIdsMap }),
      YLabel: 'pot_mean',
      label: t('potassium'),
      onClick: goToField(undefined)
    },
    {
      title: `${t('warning map')} (%)`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'alert_perc' }),
      YLabel: 'alert_perc',
      label: t('warning map'),
      onClick: goToField('WEEDS')
    },
    {
      title: `${t('weeds area')} (%)`,
      subtitle: t('mean'),
      withAverage: true,
      data: formatChartData({ data: currentStatistic, valueName: 'weeds_perc' }),
      YLabel: 'weeds_perc',
      label: t('weeds area'),
      onClick: goToField('WEEDS')
    }
  ]

  const filtersRangeKeyDisplay = reduce(
    chartsProps,
    (acc, chart) => {
      if (chart.data.length) {
        return [...acc, { name: chart.label, id: chart.YLabel }]
      }

      return acc
    },
    []
  )

  const isGridLayout = chartsLayout === GRID_LAYOUT
  const columnWidth = isGridLayout ? '50%' : '100%'

  const hasData = some(chartsProps, props => !isEmpty(props.data))

  return (
    <FullSizeContainer>
      <Loader zIndex={1} backgroundColor={'rgba(0,0,0, 0.3)'} isLoading={isLoading} />
      <Row>
        <Column width="calc(100% - 70px)">
          <FieldsComparisonChartsFiltersContainer
            filtersRangeKeyDisplay={filtersRangeKeyDisplay}
            currentlyDisplayedMetadata={
              isSatelliteTypeFlightFilterActive
                ? currentlyDisplayedDroneSatMetadata
                : [...currentlyDisplayedDroneSatMetadata, ...currentlyDisplayedDroneRgbMetadata]
            }
            currentlyDisplayedRadarData={currentlyDisplayedRadarMetadata}
          />
        </Column>
        <SwitchLayoutWrapper>
          <SwitchLayoutIconContainer />
        </SwitchLayoutWrapper>
      </Row>
      <Row>
        {hasData ? (
          chartsProps.map((props, index) => <HistogramChart key={index} {...props} columnWidth={columnWidth} order={fieldComparisonOrder} />)
        ) : (
          <FullWidthColumn>
            <BoxContainer>
              <ChartTitle data-testid="field-comparison-title" marginBottom="0">
                {t('no data with current selection')}
              </ChartTitle>
            </BoxContainer>
          </FullWidthColumn>
        )}
      </Row>
    </FullSizeContainer>
  )
}
