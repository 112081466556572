import React from 'react'
import PropTypes from 'prop-types'
import { withTranslator } from '../HOCComponents/withTranslator'
import { UIStyles } from '../../theme'
import styled from 'styled-components'
import { advancedSearch } from '../../constants'
import { find, isEmpty } from 'lodash'
import moment from 'moment'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const { PLANTATION_RANGE_FILTER } = advancedSearch
const StyledDivFilter = styled.div`
  cursor: pointer;
  transition: 0.5s ease-in-out;
  position: relative;
  text-align: center;
  height: auto;
  width: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px;
  background-color: ${props => props.selected && UIStyles.blueColor};
  &:hover {
    background: ${UIStyles.blueGrey};
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }
`

const StyledDeleteAdvancedFilter = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 35%;
  background: #0000005e;
  color: ${UIStyles.redColor};
  font-weight: 100;
  padding: 5px 5px 3px;
  font-size: 6px;
  text-align: center;
`
const StyledIconClose = styled.i`
  font-size: 8px;
`

const StyledIcon = styled.i`
  color: ${UIStyles.logoColor};
  font-size: 2rem;
`

const StyledDivAdvFilterList = styled.div`
  position: absolute;
  width: 200px;
  border: 1px solid #ddd;
  background: ${UIStyles.whiteColor};
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(8, 44, 64, 0.2);
  display: ${props => (props.isOpen ? `block` : `none`)};
  top: 40px;
  left: -25px;
  z-index: 5;
  &:before {
    position: absolute;
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    top: -11px;
    z-index: -1;
    left: 29px;
    border-width: 0 12px 12px 12px;
    border-color: transparent transparent #fff transparent;
  }
`

const StyledUlFilterList = styled.ul`
  overflow-y: auto;
  max-height: 400px;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
`

const StyledLiFilterName = styled.li`
  padding: 10px;
  font-size: 12px;
  letter-spacing: 0.5px;
  color: ${UIStyles.blueColor};
  &:hover {
    background: #082c3f;
    color: #ccc;
  }
`

const AdvancedFilter = ({ searchOptions, selectedFilterIds, filters, filterType, name, icon, isOpen, t, openFilterType, onSelect, onReset, testId }) => {
  const isYearFilterType = filterType === PLANTATION_RANGE_FILTER
  const isFilterEmpty = isYearFilterType ? !filters[filterType].from : isEmpty(filters[filterType])
  const filterOptions = searchOptions[filterType]
  const selectedOptionFilter = find(filterOptions, { id: selectedFilterIds[0] })
  const toolTipTitleBySelectedFilter = isFilterEmpty
    ? t(name)
    : isYearFilterType
    ? moment(filters[filterType].from, 'YYYY/MM/DD').format('DD/MM/YYYY') + ' - ' + moment(filters[filterType].to, 'YYYY/MM/DD').format('DD/MM/YYYY')
    : t(selectedOptionFilter.name)

  if (searchOptions.cutNumber) {
    searchOptions.cutNumber.sort((a, b) => parseInt(a.id) - parseInt(b.id))
  }

  return (
    <StyledDivFilter selected={!isFilterEmpty}>
      {!isFilterEmpty && (
        <StyledDeleteAdvancedFilter onClick={() => onReset()}>
          <StyledIconClose className="ion-close" />
        </StyledDeleteAdvancedFilter>
      )}
      <div onClick={() => openFilterType(filterType)}>
        <StyledTooltip
          // options
          title={isFilterEmpty ? t(name) : t(toolTipTitleBySelectedFilter)}
          placement="top"
          key={t(name)}
        >
          <StyledIcon className={icon} data-testid={testId} />
        </StyledTooltip>
        {!isYearFilterType && (
          <StyledDivAdvFilterList isOpen={isOpen}>
            <StyledUlFilterList>
              {searchOptions[filterType].map((filter, key) => (
                <StyledLiFilterName key={key} onClick={() => onSelect(filter.id)}>
                  {t(filter.name)}
                </StyledLiFilterName>
              ))}
            </StyledUlFilterList>
          </StyledDivAdvFilterList>
        )}
      </div>
    </StyledDivFilter>
  )
}

AdvancedFilter.propTypes = {
  filterType: PropTypes.string,
  icon: PropTypes.string,
  isOpen: PropTypes.bool,
  name: PropTypes.string,
  selectFilter: PropTypes.func,
  selected: PropTypes.object,
  openFilterType: PropTypes.func,
  testId: PropTypes.string,
  t: PropTypes.func,
  filters: PropTypes.object,
  searchOptions: PropTypes.object,
  selectedFilterIds: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
}

export default withTranslator(AdvancedFilter)
