import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import TemporalEvolutionCharts from '../../components/DashboardComponents/TemporalEvolutionCharts'
import { getTemporalEvolutionStatistics } from '../../selectors/statistics'
import { getTemporalEvolutionAppliedFilterFromAndTo, getTemporalEvolutionMaxCloudCoverage } from '../../selectors/dashboard'
import { getUiTemporalEvolutionLayout } from '../../selectors/ui'
import { getSelectedFieldsIds } from '../../selectors/fields'
import { fetchTemporalEvolutionStatistics, REQUEST_STATISTICS_BY_FIELD_IDS } from '../../actions/statistics'
import { areRequestsLoading } from '@layers-frontend/commons/store/selectors/requests'

const mapStateToProps = state => ({
  isLoading: areRequestsLoading(state, REQUEST_STATISTICS_BY_FIELD_IDS),
  normalizedTemporalEvolution: getTemporalEvolutionStatistics(state),
  selectedFlightDateRange: getTemporalEvolutionAppliedFilterFromAndTo(state),
  chartsLayout: getUiTemporalEvolutionLayout(state),
  temporalEvolutionMaxCloudCoverage: getTemporalEvolutionMaxCloudCoverage(state),
  selectedFieldsIds: getSelectedFieldsIds(state)
})

const mapDispatchToProps = { fetchTemporalEvolutionStatistics }

export default compose(withTranslator, withFieldsAvailableAndSelected, connect(mapStateToProps, mapDispatchToProps))(TemporalEvolutionCharts)
