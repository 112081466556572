import React from 'react'
import { useTable } from 'react-table'
import { TableContainer } from '../ReportManagerModal/ReportsTable' //styled div wrapper
import styled from 'styled-components'
import get from 'lodash/get'
import toLower from 'lodash/toLower'
import { CROP_TYPES_DICTIONARY } from '@layers-frontend/commons/constants'

/**
 * Represents a table displaying files with specified columns and data.
 * @component
 * @param {Object} columns - The columns configuration for the table.
 * @param {Object[]} data - The data to be displayed in the table.
 * @returns {JSX.Element} - The rendered component.
 */
const FilesTable = ({ columns, data, downloadGlobalFile }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data })

  return (
    <TableContainer>
      <table {...getTableProps()} style={{ width: '100%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            const { payload, fileName } = row.original
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()} onClick={() => downloadGlobalFile(payload, fileName)}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </TableContainer>
  )
}

/**
 * Returns a wrapper for a table displaying global files.
 * @component
 * @param {Object} downloadGlobalFile - The function to download the clicked file
 * @param {Object} reports - The data to display
 * @returns {JSX.Element} - The rendered component.
 */
const GlobalFilesTable = ({ t, reports, downloadGlobalFile }) => {
  /**
   * Represents the columns of the files table.
   * **/
  const columns = [
    {
      Header: t('File name'),
      accessor: 'fileName'
    },
    {
      Header: t('date'),
      accessor: 'date'
    },
    {
      Header: t('model type'),
      accessor: data => t(get(data, 'variable'))
    },
    {
      Header: t('Crop type'),
      accessor: data => t(CROP_TYPES_DICTIONARY[toLower(get(data, 'crop'))])
    }
  ]

  return (
    <Wrapper style={{ marginTop: '20px' }}>
      {reports && reports.length > 0 && <FilesTable columns={columns} data={reports} downloadGlobalFile={downloadGlobalFile} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .tabs {
    & > div {
      padding-right: 1rem;
      flex-grow: 0;
    }
  }
`

export default GlobalFilesTable
