import React from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import moment from 'moment'
import capitalize from 'lodash/capitalize'
import { isEmpty } from 'lodash'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import themeColors from '@layers-frontend/commons/styles/themeColors'

// date picker used in sample form, new/edit field form, and report form

const StyledDatePicker = ({ t, value, label, onChange, defaultValue, disabled = false, customStyleMargin }) => {
  const formattedValue = value && !isEmpty(value) ? moment(value) : null

  return (
    <DatePicker
      onChange={date => onChange(date)}
      value={formattedValue}
      defaultValue={defaultValue}
      disabled={disabled}
      disableFuture={true}
      minDate={moment(new Date(2017, 0, 1))}
      maxDate={moment()}
      slotProps={{ textField: { label: capitalize(t(label)) } }}
      sx={{
        '& .MuiSvgIcon-root': {
          // calendar icon
          color: themeColors.whiteColor
        },
        margin: customStyleMargin || 'auto'
      }}
    />
  )
}

export default withTranslator(StyledDatePicker)
