import { connect } from 'react-redux'
import FieldView from '../../components/FieldsComponents/FieldView'
import { toggleSearch } from '../../actions/search'
import { openSearchMode, closeEditMode } from '../../actions/viewmode'

import { getSelectedFlightGroupFieldsWithSeason } from '../../selectors/fields'
import { getFlightGroups } from '../../selectors/flightGroups'
import unionBy from 'lodash/unionBy'
import { getSelectedFieldsWithSeason } from '../../selectors/fieldsSeasonsTimeline'

const mapStateToProps = state => {
  const selectedFlightGroupFieldsWithSeason = getSelectedFlightGroupFieldsWithSeason(state)
  const selectedFieldsWithSeason = getSelectedFieldsWithSeason(state)
  const selectedFields = unionBy(selectedFlightGroupFieldsWithSeason, selectedFieldsWithSeason, 'id')
  const flightGroups = getFlightGroups(state)
  return {
    flightGroups,
    selectedFields
  }
}

const mapDispatchToProps = { toggleSearch, openSearchMode, closeEditMode }
export default connect(mapStateToProps, mapDispatchToProps)(FieldView)
