import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'

import { SATELLITE_TYPE, RADAR_DESC_TYPE, PLANET_TYPE, L9_TYPE } from '../constants'

export const getFieldComparisonStatistics = get('fieldComparisonStatistics')

export const getSatFieldComparisonStatistics = pipe(getFieldComparisonStatistics, get(SATELLITE_TYPE))

export const getRadarFieldComparisonStatistics = pipe(getFieldComparisonStatistics, get(RADAR_DESC_TYPE))

export const getSatFieldComparisonStatisticsNormalized = pipe(getFieldComparisonStatistics, get('normalized'), get(SATELLITE_TYPE))

export const getRadarFieldComparisonStatisticsNormalized = pipe(getFieldComparisonStatistics, get('normalized'), get(RADAR_DESC_TYPE))

export const getTemporalEvolutionStatistics = pipe(get('temporalEvolutionStatistics'), get('normalized'))

export const getSatTemporalEvolutionStatisticsNormalized = pipe(getTemporalEvolutionStatistics, get(SATELLITE_TYPE))

export const getRadarTemporalEvolutionStatisticsNormalized = pipe(getTemporalEvolutionStatistics, get(RADAR_DESC_TYPE))

export const getPlanetTemporalEvolutionStatisticsNormalized = pipe(getTemporalEvolutionStatistics, get(PLANET_TYPE))

export const getL9TemporalEvolutionStatisticsNormalized = pipe(getTemporalEvolutionStatistics, get(L9_TYPE))
