import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import find from 'lodash/fp/find'
import includes from 'lodash/fp/includes'
import { FIELD_EDIT_VIEW, FIELD_VIEW, FIELD_NEW_VIEW, TEMPORAL_COMPARE_VIEW, SEARCH_VIEW, SEARCH_DELETED_VIEW, SAMPLE_LOCATION_VIEW } from '../actions/viewmode'
import findIndex from 'lodash/fp/findIndex'

export const getScreenView = get('screenView')

const getBaseMaps = get('baseMaps')

export const getSelectedBaseMap = pipe(getBaseMaps, find('selected'))

export const getSelectedBaseMapIndex = pipe(getBaseMaps, findIndex('selected'))

export const isSearchView = pipe(getScreenView, view => view === SEARCH_VIEW)

export const isFieldView = pipe(getScreenView, view => view === FIELD_VIEW)

export const isFieldEditView = pipe(getScreenView, view => view === FIELD_EDIT_VIEW)

export const isFieldNewView = pipe(getScreenView, view => view === FIELD_NEW_VIEW)

export const isTemporalView = pipe(getScreenView, view => view === TEMPORAL_COMPARE_VIEW)

export const isSampleLocationView = pipe(getScreenView, view => view === SAMPLE_LOCATION_VIEW)

export const isSearchDeletedView = pipe(getScreenView, view => view === SEARCH_DELETED_VIEW)

export const getSelectedBaseMapData = (state, key) => pipe(getSelectedBaseMap, get(key))(state)

export const shouldShowLayers = pipe(getScreenView, screenView => includes(screenView)([FIELD_VIEW, TEMPORAL_COMPARE_VIEW, SAMPLE_LOCATION_VIEW]))

export const shouldShowSeasonLayers = pipe(getScreenView, screenView => includes(screenView)([FIELD_VIEW, SAMPLE_LOCATION_VIEW]))
