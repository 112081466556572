import React, { useState } from 'react'
import styled from 'styled-components'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import map from 'lodash/map'

const ITEM_HEIGHT = 48
const StyledButtonIcon = styled.i`
  padding: 5px;
  color: ${themeColors.whiteColor};
`
/**
 *
 * @param {String[]} options - array of strings for options
 * @param {Number[]|String[]} - array of values for options
 * @param {Function} handleChange - function on selecting the option from the menu
 * @param {Boolean} disabled - disable the menu options
 * @returns three dot menu
 */

export const MenuMoreOptions = ({ options, values, handleChange, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = event => {
    handleChange(event.currentTarget.value)
    setAnchorEl(null)
  }

  return (
    <div style={{ alignSelf: 'flex-end' }}>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <StyledButtonIcon className="fas fa-ellipsis-v" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'more options menu'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch'
          }
        }}
      >
        {map(options, (option, index) => (
          <MenuItem key={option} value={values[index]} onClick={handleClose} disabled={disabled}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
