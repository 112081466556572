import { REQUEST_DOWNLOAD_HISTOGRAM_SUCCESS } from '@layers-frontend/commons/store/storeConstants'

export const initialState = { counts: [], intervals: [] }

export default function fileGeneratorPlot(state = initialState, action = {}) {
  const { counts = [], intervals = [] } = action.data || {}

  switch (action.type) {
    case REQUEST_DOWNLOAD_HISTOGRAM_SUCCESS:
      return {
        ...state,
        intervals,
        counts
      }
    default:
      return state
  }
}
