import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import OLInteraction from './ol-interaction'
import OlSelect from 'ol/interaction/Select'
import get from 'lodash/get'
import has from 'lodash/has'
import omitBy from 'lodash/omitBy'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { Fill, Style, Stroke } from 'ol/style'
import Tooltip from '../../UIComponents/KeyValueList'
import { samplesLayerStyleFunction } from '../../LayersComponents/SamplesLayer'
import includes from 'lodash/includes'
import { SEASON_LAYERS_OMITTED_PROPERTIES_KEYS } from '@layers-frontend/commons/constants'

// eslint-disable-next-line prefer-const
let styleCache = {}
const isFeatureSampleMarker = feature => has(feature.get('field'), 'externalId')
const isFeatureVectorDeliverable = feature => get(feature.get('field'), ['properties', 'hasTooltip'])

const styleFunction = (feature, resolution) => {
  let style = {}
  const isSampleMarker = isFeatureSampleMarker(feature)
  const olFeature = feature.get('field')

  if (!isSampleMarker) {
    const fillColor = `rgb(${get(olFeature, ['properties', 'color'])})`
    const styleKey = get(olFeature, ['properties', 'color'])

    // eslint-disable-next-line no-prototype-builtins
    if (!styleCache.hasOwnProperty(styleKey)) {
      const fill = new Fill({ color: fillColor })
      // eslint-disable-next-line object-shorthand
      style = new Style({ fill: fill, stroke: new Stroke({ color: themeColors.blueColorOpacity, width: 2 }) })

      styleCache[styleKey] = style
    } else {
      style = styleCache[styleKey]
    }
    return style
  }

  return samplesLayerStyleFunction(feature)
}
class MapClickInteraction extends OLInteraction {
  createInteraction(props) {
    return new OlSelect({
      condition: props.condition,
      multi: false,
      filter: feature => {
        return isFeatureVectorDeliverable(feature) || isFeatureSampleMarker(feature)
      },
      style: styleFunction
    })
  }
}

MapClickInteraction.propTypes = Object.assign({}, OLInteraction.propTypes, {
  condition: PropTypes.func,
  select: PropTypes.func
})

MapClickInteraction.olEvents = ['select']

export default function FieldViewMapClickInteraction({ isFieldViewActive, isSeasonLayersTabActive, selectSampleById, deselectAllSamples, showSamplesInMap }) {
  const [selectedFeatureProperties, setSelectedFeatureProperties] = useState({})
  const [tooltipPosition, setTooltipPosition] = useState([])

  useEffect(() => {
    if (!isSeasonLayersTabActive) {
      setSelectedFeatureProperties({})
    }
  }, [isSeasonLayersTabActive])

  const onClick = event => {
    const selectedFeature = get(event, 'selected[0]')
    const deselectedFeature = get(event, 'deselected[0]')

    if (isEmpty(selectedFeature)) {
      setSelectedFeatureProperties({})
    }
    if (selectedFeature && isSeasonLayersTabActive && isFeatureVectorDeliverable(selectedFeature)) {
      highlightSeasonLayer(event, selectedFeature)
    }

    if (selectedFeature && showSamplesInMap && isFeatureSampleMarker(selectedFeature)) {
      highlightSampleMarker(selectedFeature)
    }

    if (deselectedFeature && showSamplesInMap && isFeatureSampleMarker(deselectedFeature)) {
      deselectAllSamples()
    }
  }

  const highlightSeasonLayer = (event, selectedFeature) => {
    const [x, y] = event.mapBrowserEvent.pixel
    const selectedFeatureProperties = get(selectedFeature.getProperties(), ['field', 'properties'])
    setTooltipPosition([y, x])
    setSelectedFeatureProperties(selectedFeatureProperties)
  }

  const highlightSampleMarker = selectedFeature => {
    const id = get(selectedFeature.get('field'), 'id')
    setSelectedFeatureProperties({})
    selectSampleById(id)
  }

  const omitByFn = (value, key) => includes(SEASON_LAYERS_OMITTED_PROPERTIES_KEYS, key) || isObject(value) || isArray(value)

  return isFieldViewActive && isSeasonLayersTabActive ? (
    <>
      <MapClickInteraction select={onClick} />
      {!isEmpty(selectedFeatureProperties) ? <Tooltip position={tooltipPosition} properties={omitBy(selectedFeatureProperties, omitByFn)} /> : null}
    </>
  ) : null
}
