import React from 'react'
import PropTypes from 'prop-types'
import OlMap from 'ol/Map'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

import { builGroupdDeliverablesFromProps } from '../../groupDeliverablesUtils'
import LayerGroupContainer from '../../containers/olContainers/LayerGroupContainer'
import { isEqual } from 'lodash'

const initialState = []

export default class GroupDeliverablesLayer extends React.Component {
  state = {
    layerGroupsData: initialState
  }

  getChildContext() {
    return { map: this.context.map }
  }

  _resetState() {
    this.setState({
      layerGroupsData: initialState
    })
  }

  _prepareDeliverables(props) {
    const deliverables = builGroupdDeliverablesFromProps(props)
    this.setState({ layerGroupsData: deliverables })
  }

  componentDidMount() {
    const { flightGroupDeliverables, flightSource } = this.props
    // fields without geometries
    if (flightSource === undefined) {
      this._resetState()
    }
    if (!isEmpty(flightGroupDeliverables)) {
      this._prepareDeliverables(this.props)
    }
  }
  // eslint-disable-next-line lines-between-class-members
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { showLayers, flightSource, flightGroupDeliverables, selectedFlightGroup } = this.props
    // fields without geometries
    if (nextProps.flightSource === undefined) {
      this._resetState()
    }

    if (
      !isEqual(nextProps.showLayers, showLayers) ||
      !isEqual(nextProps.flightSource, flightSource) ||
      !isEqual(nextProps.flightGroupDeliverables, flightGroupDeliverables) ||
      !isEqual(nextProps.selectedFlightGroup, selectedFlightGroup)
    ) {
      this._prepareDeliverables(nextProps)
    }
  }

  render = () => {
    const { showTemporal, showLayers, flightGroupDeliverables, orderDeliverables, temporalSwipe, groupDeliverablesBaseZIndex, token } = this.props
    const leng = showLayers ? flightGroupDeliverables.length : 0
    const temporalDisplay = showTemporal ? 'left' : 'all'
    // eslint-disable-next-line prefer-const
    let deliverablesLayer =
      showLayers && !isEmpty(this.state.layerGroupsData) && !isEmpty(this.state.layerGroupsData[0])
        ? this.state.layerGroupsData.map((layerGroupData, index) => {
            const deliverable = get(layerGroupData, '[0].deliverable')
            // eslint-disable-next-line prefer-const
            let zIndex = leng - orderDeliverables.indexOf(deliverable.id)
            if (deliverable.visible) {
              return (
                <LayerGroupContainer
                  display={temporalDisplay}
                  temporalSwipe={temporalSwipe}
                  visible={deliverable.visible}
                  opacity={deliverable.opacity}
                  zIndex={groupDeliverablesBaseZIndex + zIndex}
                  key={`maskGroup ${deliverable.id} ${index}`}
                  name="maskGroup"
                  layers={layerGroupData}
                  token={token}
                />
              )
            }
            return null
          })
        : null

    return <div>{deliverablesLayer}</div>
  }
}

GroupDeliverablesLayer.propTypes = {
  flightSource: PropTypes.number,
  deliverables: PropTypes.arrayOf(PropTypes.object),
  dispatch: PropTypes.func,
  orderDeliverables: PropTypes.arrayOf(PropTypes.number),
  showLayers: PropTypes.bool,
  showTemporal: PropTypes.bool,
  groupDeliverablesBaseZIndex: PropTypes.number,
  token: PropTypes.string
}

GroupDeliverablesLayer.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

GroupDeliverablesLayer.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
