/* eslint-disable no-unneeded-ternary */
import React, { useState } from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import moment from 'moment'

// eslint-disable-next-line import/no-duplicates
import { useDroppable } from '@dnd-kit/core'
// eslint-disable-next-line import/no-duplicates
import { useDndMonitor } from '@dnd-kit/core'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

const TemporalComparisonContainer = styled.section`
  margin-bottom: 16px;
`

const TemporalComparisonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: -15px 0 0;
  padding: 20px 0 10px;
`

const Text = styled.p`
  color: ${themeColors.whiteColor};
  margin: 0 15px;
`

const Title = styled.h1`
  color: ${themeColors.vomitColor};
  margin-bottom: 15px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
`

const DateField = styled.div`
  width: 110px;
  height: 30px;
  border-radius: 6px;
  color: ${themeColors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${themeColors.vomitColor};
`

const DropZone = styled.div`
  width: 110px;
  height: 30px;
  border-radius: 6px;
  color: ${themeColors.whiteColor};
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${props => (props.isDisabled ? '0.6' : '1')};
  transform: ${props => (props.isOver && !props.isDisabled ? 'scale(1.14)' : props.isDragging && !props.isDisabled ? 'scale(1.08)' : 'scale(1)')};
  transition: 0.25s ease all;
  border: ${props =>
    `2px solid ${props.isDisabled ? themeColors.dangerRed : props.droppableDate || props.isOver ? themeColors.vomitColor : themeColors.grayColor}`};
  background: ${props => (props.isDisabled ? themeColors.dangerRed : props.droppableDate ? themeColors.vomitColor : themeColors.darkGrayHoverColor)};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
`

const formatDate = date => moment(date).format('D MMM. YYYY')

function TemporalComparison({ t, selectedFlightGroupDate, tempComparisonFlightGroupDate, isComparable = true }) {
  const formattedDate = formatDate(selectedFlightGroupDate)

  const droppableDate = tempComparisonFlightGroupDate ? formatDate(tempComparisonFlightGroupDate) : null
  const { setNodeRef } = useDroppable({ id: 'drop-zone' })

  const [isDragging, setIsDragging] = useState(false)
  const [isOver, setIsOver] = useState(false)

  useDndMonitor({
    onDragStart() {
      setIsDragging(true)
    },
    onDragOver(event) {
      setIsOver(!!event.over)
    },
    onDragEnd(event) {
      setIsDragging(false)
      setIsOver(false)
      trackEvent(TRACK_EVENTS.FIELD_PANEL_TEMPORAL_COMPARE, {
        value: event.active.id,
        button: 'Temporal Comparison drop zone',
        location: TRACK_EVENTS.FIELD_PANEL
      })
    },
    onDragCancel() {
      setIsDragging(false)
      setIsOver(false)
    }
  })

  return (
    <TemporalComparisonContainer>
      <Title>{t('FLIGHT_FILTER')}</Title>
      <TemporalComparisonWrapper>
        <DateField activeDate={true}>{formattedDate}</DateField>
        <Text>vs</Text>
        <div style={{ display: 'flex', alignItems: 'center' }} ref={setNodeRef}>
          <DropZone isOver={isOver} droppableDate={droppableDate} isDragging={isDragging} isDisabled={!isComparable && isDragging}>
            {droppableDate ? droppableDate : !isComparable && isDragging ? <i className="fa-solid fa-ban" /> : t('TIME_COMPARISON_DROP_ZONE')}
          </DropZone>
        </div>
      </TemporalComparisonWrapper>
    </TemporalComparisonContainer>
  )
}

export default withTranslator(TemporalComparison)
