import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { UIStyles } from '../../theme'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const buttonStyles = css`
  width: 40px;
  height: 40px;
  transition: all 0.5s ease;
  display: flex;
  z-index: ${props => (props.zIndex ? props.zIndex : '1')};
  position: ${props => (props.absolute ? 'absolute' : 'relative')};
  top: ${props => (props.absolute ? props.top : 'auto')};
  right: ${props => (props.right ? props.right : 'auto')};
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  background: #fff;
  border: none;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  color: ${UIStyles.blueColor};

  i {
    margin-top: ${props => (props.marginTopIcon ? props.marginTopIcon : '0px')};
    font-size: ${props => (props.sizeIcon ? props.sizeIcon : '45px')};
  }

  ${props =>
    props.small &&
    css`
      width: 24px;
      height: 24px;
      line-height: 21px;
      cursor: pointer !important;
      i {
        font-size: 16px !important;
      }
    `};

  ${props =>
    props.medium &&
    css`
      width: 56px;
      height: 56px;
    `};

  ${props =>
    props.big &&
    css`
      width: 80px;
      height: 80px;
    `};

  ${props =>
    props.giant &&
    css`
      width: 120px;
      height: 120px;
    `};

  ${props =>
    props.primary &&
    css`
      background: #c6c30d;
      color: ${UIStyles.blueColor};
    `};

  ${props =>
    props.danger &&
    css`
      background: ${themeColors.dangerRed};
    `};

  ${props =>
    props.information &&
    css`
      background: ${UIStyles.blueColor};
      color: #ccc;
      cursor: default;
      margin-bottom: 10px;
    `};

  ${props =>
    props.secondary &&
    css`
      background: ${UIStyles.blueColor};
      color: #c6c30d;
    `};

  ${props =>
    props.tertiary &&
    css`
      background: ${UIStyles.blueColor};
      color: #c6c30d;
    `};

  div {
    color: #c6c30d;
    font-family: Roboto, 'Roboto Condensed';
    font-size: 12px;
    position: absolute;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      transition: all 0.5s ease;
    `};

  ${'' /*  Available animations glow, rotate, scale,shake, gradient*/}
  ${props =>
    props.primaryAnimation &&
    css`
      animation: ${scale} 0.5s linear 0s 1;
    `};

  ${props =>
    props.secondaryAnimation &&
    css`
      background: linear-gradient(269deg, #c6c30d, #082c3f);
      background-size: 400% 400%;
      animation: ${gradient} 1.5s ease infinite;
    `};

  ${props =>
    props.hidden &&
    css`
      opacity: 0;
      transition: all 0.5s ease;
    `};
`
export const LinkButton = styled.a`
  ${buttonStyles};
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
`
export const RoundButton = styled.button.attrs({
  type: props => (props.type ? props.type : 'button')
})`
  margin: ${props => (props.margin ? props.margin : 'none')};
  ${buttonStyles};
`

export const RoundBox = styled.div`
  ${buttonStyles};
  border: ${props => (props.border ? props.border : 'none')};
`

export const Button = styled.button`
  width: ${props => (props.width ? props.width : '50%')};
  transition: all 0.5s ease;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  border: none;
  cursor: pointer;
  padding: 10px;
  background: ${props => props.theme.main};
  color: ${props => props.theme.secondary};
  margin: ${props => (props.primary ? '20px 0px 0px 0px' : '0px')};
  font-style: ${props => (props.primary ? 'italic' : 'none')};
  font-weight: ${props => (props.primary ? 'bold' : 'normal')};
  font-family: Roboto, 'Roboto Condensed';
  letter-spacing: 0.5px;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.4;
      transition: all 0.5s ease;
    `};
  &:hover i.black-icon {
    color: #000;
  }
`
export const ThemeButton = ({ label, icon, rightIcon, disabled, onClick, width, background, color, className, id, testId }) => (
  <Button
    id={id}
    data-testid={testId}
    type="button"
    disabled={disabled}
    primary
    width={width}
    className={className}
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
    theme={{ main: background, secondary: color }}
  >
    {icon && <i className={icon} style={{ marginRight: '10px' }} />}
    {label}
    {rightIcon && <i className={`black-icon ${rightIcon}`} style={{ marginLeft: '10px' }} />}
  </Button>
)

export const LargeButton = ({ label, icon, rightIcon, disabled, onClick, width, className, id, testId }) => (
  <ThemeButton
    id={id}
    testId={testId}
    disabled={disabled}
    primary
    width={width}
    onClick={onClick}
    background={UIStyles.vomitColor}
    color={UIStyles.blueColor}
    icon={icon}
    rightIcon={rightIcon}
    label={label}
    className={className}
  />
)

export const RedButton = ({ label, icon, disabled, onClick, width, style }) => (
  <ThemeButton
    disabled={disabled}
    primary
    width={width}
    onClick={onClick}
    background={UIStyles.redColor}
    color={UIStyles.blueColor}
    icon={icon}
    label={label}
    style={style}
  />
)

export const VomitButton = ({ label, icon, disabled, onClick, width, style }) => (
  <ThemeButton
    disabled={disabled}
    primary
    width={width}
    onClick={onClick}
    background={UIStyles.vomitColor}
    color={UIStyles.blueColor}
    icon={icon}
    label={label}
    style={style}
  />
)

/*=============================================>>>>>
= Animations =
===============================================>>>>>*/
export const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`

export const glow = keyframes`
0% {
  opacity: 0.4;
}
10%, 20% {
  opacity: 1;
}
30%, 40% {
  opacity:0.4;
}
50%, 60% {
  opacity: 1;
}
70%, 80% {
  opacity:0.4;
}
90%, 100% {
  opacity: 1;
}
`

export const scale = keyframes`
0% {
  transform: scale(0.7);
}
20%, 40% {
  transform: scale(1.2);
}
60%, 80% {
  transform: scale(0.7);
}
100% {
  transform: scale(1);
}
`

export const shake = keyframes`
0% {
  transform: translate(-2px);
}
10%, 20% {
  transform: translate(4px);
}
30%, 40% {
  transform: translate(-4px);
}
50%, 60% {
  transform: translate(4px);
}
70%, 80% {
  transform: translate(-4px);
}
90%, 100% {
  transform: translate(2px);
}`

const gradient = keyframes`
    0%{background-position:0% 50%}
    50%{background-position:70% 100%}
    100%{background-position:0% 50%}`

/*= End of Animations =*/
/*=============================================<<<<<*/
