import styled from 'styled-components'
import { UIStyles } from '../../theme'

// main dashboard container
export const Dashboard = styled.div`
  background: #072732;
  width: 100%;
  height: 100%;
  padding-left: 60px;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  overflow-y: auto;
`
// top bar container
export const TopBarWrapper = styled.div`
  background: ${UIStyles.darkBlue};
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  min-height: 120px;
`

// Content container
export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
`

// charts container
export const ChartsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  height: auto;
`

// switch button container
export const LogoSwitch = styled.img`
  width: 5%;
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  padding: 0.5%;
`

// filters container
export const FiltersWrapper = styled.div`
  /* background: ${UIStyles.darkBlue}; */
  width: calc(85% - 20px);
  display: flex;
  flex-flow: row wrap;
  self-align: left;
  margin-left: 20px;
`
// single filter wrapper
export const FilterWrapper = styled.div`
  display: flex;
  margin-left: 10px;
`
