import { connect } from 'react-redux'
import SelectedFieldsAside from '../../components/FieldsComponents/SelectedFieldsAside'
import { shouldShowSamplesInMap, getSelectedFieldAsideExpanded, getSamplesMode } from '../../selectors/ui'
import { toggleShowSampleMarkers, toggleExpandSelectedFieldAside } from '../../actions/ui'
import { hasSamples } from '../../selectors/samples'
import { fetchActiveFieldsSamples, setSamplesMode } from '../../actions/samples'
import { getUserConfigSurface } from '@layers-frontend/commons/store/selectors/user'
import { getSelectedFlightGroupFirstFlightDate } from '../../selectors/flightGroups'

const mapStateToProps = state => ({
  shouldShowSamplesInMap: shouldShowSamplesInMap(state),
  asideExpanded: getSelectedFieldAsideExpanded(state),
  samplesMode: getSamplesMode(state),
  surfaceUnit: getUserConfigSurface(state),
  hasSamples: hasSamples(state),
  selectedFlightGroupDate: getSelectedFlightGroupFirstFlightDate(state)
})

const mapDispatchToProps = {
  toggleShowSampleMarkers,
  toggleExpandSelectedFieldAside,
  fetchActiveFieldsSamples,
  setSamplesMode
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFieldsAside)
