import React from 'react'
import { MAX_CLOUD_COVERAGE_INITIAL_STATE } from '../../../actions/dashboard'
import { OpenModalButton } from '../FieldsComparisonChartsFilters'
import MaxCloudCoverageInput from '../MaxCloudCoverageInput'
import { StyledTooltip } from '../../UIComponents/StyledTooltip'

export default function FieldsComparisonChartsMaxCloudCoverageInput({ t, buttonDisabled, openModal, maxCloudCoverage, setMaxCloudCoverage }) {
  const handleOpenModal = () => {
    if (!buttonDisabled) {
      openModal()
    }
  }

  const showHintText = undefined
  const valueOrHintText = maxCloudCoverage === MAX_CLOUD_COVERAGE_INITIAL_STATE ? showHintText : maxCloudCoverage

  return (
    <>
      <StyledTooltip title={`${t('Maximun cloud coverage')} %`} placement="bottom-start">
        <MaxCloudCoverageInput onChange={setMaxCloudCoverage} value={valueOrHintText} />
      </StyledTooltip>
      <OpenModalButton disabled={buttonDisabled} onClick={handleOpenModal}>
        {t('Select')}
      </OpenModalButton>
    </>
  )
}
