import React from 'react'
import Cluster from '../olComponents/cluster'
import withTranslator from '../HOCComponents/withTranslator'
const ClusterLayer = props => {
  const {
    features,
    selectedLayerLegend,
    touchedLayers,
    isGlobalLayerLegendOpen,
    isSearchView,
    t,
    setFilteredFieldIds,
    filteredFields,
    filteredByEverything,
    isFilterable
  } = props
  if (!isGlobalLayerLegendOpen) return null
  const fieldsLayer = isSearchView ? (
    <Cluster
      t={t}
      isFilterable={isFilterable}
      selectedLayerLegend={selectedLayerLegend}
      features={features}
      touchedLayers={touchedLayers}
      setFilteredFieldIds={setFilteredFieldIds}
      filteredFields={filteredFields}
      filteredByEverything={filteredByEverything}
    ></Cluster>
  ) : null

  return <>{features && fieldsLayer}</>
}

export default withTranslator(ClusterLayer)
