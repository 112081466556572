import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

// eslint-disable-next-line import/no-duplicates
import { TEMPORAL_COMPARE_VIEW } from '../../actions/viewmode'
// eslint-disable-next-line import/no-duplicates
import { openCreationMode, closeCreationMode, closeEditMode } from '../../actions/viewmode'

export const connectEditorMode = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      isToolbarOpen: state.editor.present.active || state.screenView === TEMPORAL_COMPARE_VIEW,
      temporalView: state.screenView === TEMPORAL_COMPARE_VIEW,
      selectedField: state.selectedField,
      selectedFields: state.selectedFields
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        /* eslint-disable object-shorthand */
        openCreationMode: openCreationMode,
        closeEditMode: closeEditMode,
        closeCreationMode: closeCreationMode
        /* eslint-enable object-shorthand */
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
