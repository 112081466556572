import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { selectFields, flushFieldsGroup, downloadSelectedFieldsGeoJson, REQUEST_DOWNLOAD_FIELDS_GEOJSON } from '../../actions/fields'
import { closeSearchFilters } from '../../actions/search'
import { areRequestsLoading, getIsFlightGroupLoading } from '../../selectors/ui'
import { getSelectedFieldsIds } from '../../selectors/fields'
import { isSearchFocus } from '../../selectors/search'

export const connectFieldsGroup = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      selectedFields: getSelectedFieldsIds(state),
      isLoading: getIsFlightGroupLoading(state),
      isSearchFocus: isSearchFocus(state),
      isFieldsGeoJsonLoading: areRequestsLoading(state, REQUEST_DOWNLOAD_FIELDS_GEOJSON)
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        selectFields,
        flushFieldsGroup,
        downloadSelectedFieldsGeoJson,
        closeSearchFilters
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
