import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../accounts/accountsSlice'

export const accountsApi = createApi({
  reducerPath: 'accountsApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${window.location.origin}/auth` }),
  endpoints: build => ({
    getUserByUsername: build.query({
      query: ({ organizationId, userId, token }) => ({
        url: `?organization_id=${organizationId}&filter={"id":"${userId}"}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data[0] })) // returns array of 1, but there is only one
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error: error.toString() }))
        }
      }
    }),
    getAccountsByUserId: build.query({
      query: ({ token, organizationId }) => ({
        url: `access/account/?organization_id=${organizationId}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'userAccounts' }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'userAccounts', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'userAccounts', error: error.toString() }))
        }
      }
    })
  })
})

// Export hooks based on the defined endpoints
export const { useGetUserByUsernameQuery, useGetAccountsByUserIdQuery } = accountsApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
