import { RECEIVE_DELIVERABLE_TYPES } from '../actions/deliverables'

import reduce from 'lodash/reduce'
import get from 'lodash/get'

export const deliverableTypes = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_DELIVERABLE_TYPES:
      // eslint-disable-next-line no-case-declarations
      const { deliverableTypes } = action
      return reduce(
        deliverableTypes,
        (accumulator, deliverableType) => {
          return {
            ...accumulator,
            [get(deliverableType, 'id')]: deliverableType
          }
        },
        {}
      )
    default:
      return state
  }
}
