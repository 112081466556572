import PropTypes from 'prop-types'

import OLComponent from './ol-component'
import GeometryUtils from '../../geometryUtils'

// eslint-disable-next-line import/no-duplicates
import OlPolygon from 'ol/geom/Polygon'
// eslint-disable-next-line import/no-duplicates
import { fromCircle } from 'ol/geom/Polygon'
import OlOverlay from 'ol/Overlay'
import { add } from 'ol/coordinate'
import OlGeometry from 'ol/geom/Geometry'
import OlMap from 'ol/Map'
import { convertAsString } from '@layers-frontend/commons/conversions'
import { SURFACE_UNIT } from '@layers-frontend/commons/constants'

export default class DeleteOverlay extends OLComponent {
  constructor(props, context) {
    super(props, context)

    // eslint-disable-next-line prefer-const
    let _this = this
    this.overlayElement = document.createElement('div')
    this.overlayElement.className = 'tooltip tooltip-static tooltip-delete-feature'
    if (this.context.geometry instanceof OlPolygon)
      this.overlayElement.innerHTML = convertAsString(GeometryUtils.getHectares(this.context.geometry), 'hectare', this.props.config[SURFACE_UNIT])
    else this.overlayElement.innerHTML = GeometryUtils.formatArea(fromCircle(this.context.geometry, 500))

    // eslint-disable-next-line prefer-const
    let deleteElement = document.createElement('div')
    // eslint-disable-next-line prefer-const
    let deleteButton = document.createElement('i')

    deleteButton.className = 'ion-android-delete'
    deleteElement.appendChild(deleteButton)
    deleteElement.className = 'measureDeleteButton'
    deleteElement.addEventListener('click', function () {
      _this.props.deletePolygon(_this.props.feature.uid)
    })

    this.overlayElement.appendChild(deleteElement)

    this.overlay = new OlOverlay({
      element: this.overlayElement,
      offset: [0, -15],
      positioning: 'top-center',
      position:
        this.context.geometry instanceof OlPolygon
          ? this.context.geometry.getInteriorPoint().getCoordinates()
          : add(this.context.geometry.getCenter(), [0, this.context.geometry.getRadius() / 2])
    })
  }
  // eslint-disable-next-line lines-between-class-members
  componentDidMount() {
    if (this.props.isSliceTool) this.overlayElement.style.display = 'none'

    this.context.map.addOverlay(this.overlay)
  }

  componentWillUnmount() {
    this.context.map.removeOverlay(this.overlay)
  }
}

DeleteOverlay.propTypes = {
  deletePolygon: PropTypes.func,
  feature: PropTypes.object,
  isSliceTool: PropTypes.bool
}

DeleteOverlay.contextTypes = {
  geometry: PropTypes.instanceOf(OlGeometry),
  map: PropTypes.instanceOf(OlMap)
}
