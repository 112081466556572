import { connect } from 'react-redux'
import { compose } from 'redux'

import { getConfigProjection } from '../../selectors/config'
import { getFieldsFilteredByView } from '../../selectors/availableFields'
import { isSearchView } from '../../selectors/viewmode'
import ClusterLayer from '../../components/LayersComponents/ClusterLayer'
import {
  getGlobalMapCentroidsFeatures,
  getSelectedLayerLegend,
  getTouchedLayers,
  getIsGlobalLayerLegendOpen,
  getFilteredFieldIds
} from '../../selectors/globalLayer'
import { setFilteredFieldIds } from '../../actions/globalLayer'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'
import { getGeometries } from '../../selectors/fields'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => {
  const filteredByEverything = getFieldsFilteredByView({ fieldsWithAvailableAndSelected, filterAvailableAndSelected: false })(state)
  const geometries = getGeometries(state)
  const isFilterable = CHUNK_SIZE > geometries.length

  return {
    features: getGlobalMapCentroidsFeatures(state),
    projection: getConfigProjection(state),
    isSearchView: isSearchView(state),
    selectedLayerLegend: getSelectedLayerLegend(state),
    touchedLayers: getTouchedLayers(state),
    isGlobalLayerLegendOpen: getIsGlobalLayerLegendOpen(state),
    filteredFields: getFilteredFieldIds(state),
    filteredByEverything,
    isFilterable
  }
}

const mapDispatchToProps = {
  setFilteredFieldIds
}

export default compose(withFieldsAvailableAndSelected, connect(mapStateToProps, mapDispatchToProps))(ClusterLayer)
