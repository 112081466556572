import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { getLocale } from '@layers-frontend/commons/store/selectors/locale'

const LANGS = {
  de: 'de_DE',
  en: 'en_GB',
  es: 'es_ES',
  fr: 'fr_FR',
  hu: 'hu_HU',
  it: 'it_IT',
  pt: 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sr: 'sr_SR',
  uk: 'uk_UA'
}

export const getMessages = get('messages')

export const getLanguage = state => pipe(getLocale, lang => get(lang)(LANGS))(state)

export { getLocale }
