import get from 'lodash/fp/get'
import mapKeys from 'lodash/mapKeys'

import { createSelector } from 'reselect'

export const getSampleTypes = get('sampleTypes')

export const getSampleTypesById = createSelector(getSampleTypes, types => mapKeys(types, 'id'))

export const getSamplesParametersByType = get('samplesParametersByType')
