import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../../components/HOCComponents/withTranslator'
import HarvestPlanningToolCropTypeSelect from '../../../components/DashboardComponents/DashboadFilters/HarvestPlanningToolCropTypeSelect'
import { toggleDashFilter } from '../../../actions/dashboard'
import { getHarvestPlanningToolSelectedTypeFilter, getHarvestPlanningToolSelectedMetadataKey } from '../../../selectors/dashboard'

const mapStateToProps = state => ({
  selectedType: getHarvestPlanningToolSelectedTypeFilter(state),
  selectedMetadataKey: getHarvestPlanningToolSelectedMetadataKey(state)
})
const mapDispatchToProps = {
  toggleDashFilter
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(HarvestPlanningToolCropTypeSelect)
