import React, { useEffect, useMemo } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'

import Loader from '../../Loader/Loader'
import { GRID_LAYOUT } from '../../../actions/ui'
import IdealMaxChart from '../SeasonEvolution/IdealMaxChart'
import IdealCurveChart from '../SeasonEvolution/IdealCurveChart'
import SwitchLayoutIcon from '../../../containers/DashboardContainers/SwitchLayoutIconContainer'
import { ChartTitle, FullSizeContainer, FullWidthColumn, Row, TabTitle, Column } from '../Layout'

import styled from 'styled-components'

const HeaderContainer = styled(Row)`
  justify-content: space-between;
`

// predTech charts

/**
 * Renders the PredTech component.
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.fetchPredTechByLogType - The function to fetch PredTech data by log type.
 * @param {Array} props.selectedFieldsIds - The selected field IDs.
 * @param {boolean} props.isLoading - Indicates if the component is loading.
 * @param {Object} props.sacHa - The sacHa data.
 * @param {Object} props.prodHa - The prodHa data.
 * @param {Object} props.nitro - The nitro data.
 * @param {Object} props.tchPred - The tchPred data.
 * @param {Object} props.polPred - The polPred data.
 * @param {string} props.chartsLayout - The layout of the charts.
 * @returns {JSX.Element} The rendered PredTech component.
 */
export default function PredTech(props) {
  const { t, fetchPredTechByLogType, selectedFieldsIds, isLoading, sacHa, prodHa, nitro, tchPred, polPred, chartsLayout } = props

  const areFieldsSelected = useMemo(() => {
    return get(selectedFieldsIds, 'length') > 0
  }, [selectedFieldsIds])

  const isGridLayout = chartsLayout === GRID_LAYOUT
  const chartsWidth = isGridLayout ? '50%' : '100%'

  useEffect(() => {
    if (selectedFieldsIds.length > 0) {
      fetchPredTechByLogType({ fieldIds: selectedFieldsIds, mode: 'weekly' })
      fetchPredTechByLogType({ fieldIds: selectedFieldsIds, mode: 'ideal_season' })
      fetchPredTechByLogType({ fieldIds: selectedFieldsIds, mode: 'season_max' })
    }
  }, [fetchPredTechByLogType, selectedFieldsIds])

  /**
   * Renders the SacHa charts.
   * @returns {JSX.Element} The rendered SacHa charts.
   */
  const SacHaCharts = () => (
    <Row>
      <IdealCurveChart t={t} title="estimación sacarosa" data={sacHa} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={t('Suc/Ha')} />
      <IdealMaxChart t={t} title="estimación sacarosa" data={sacHa} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={t('Suc/Ha')} />
    </Row>
  )

  /**
   * Renders the ProdHa charts.
   * @returns {JSX.Element} The rendered ProdHa charts.
   */
  const ProdHaCharts = () => (
    <Row>
      <IdealCurveChart
        t={t}
        title="Estimacion produccion ( Tn / Ha )"
        data={prodHa}
        areFieldsSelected={areFieldsSelected}
        chartsWidth={chartsWidth}
        yLabel={'Tn/Ha'}
      />
      <IdealMaxChart
        t={t}
        title="Estimacion produccion ( Tn / Ha )"
        data={prodHa}
        areFieldsSelected={areFieldsSelected}
        chartsWidth={chartsWidth}
        yLabel={'Tn/Ha'}
      />
    </Row>
  )

  /**
   * Renders the Nitro charts.
   * @returns {JSX.Element} The rendered Nitro charts.
   */
  const NitroCharts = () => (
    <Row>
      <IdealCurveChart t={t} title="Nitrogeno %" data={nitro} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'%'} />
      <IdealMaxChart t={t} title="Nitrogeno %" data={nitro} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'%'} />
    </Row>
  )

  /**
   * Renders the Tch charts.
   * @returns {JSX.Element} The rendered Tch charts.
   */
  const TchCharts = () => (
    <Row>
      <IdealCurveChart t={t} title="TCH  ( Tn / Ha )" data={tchPred} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'Tn/Ha'} />
      <IdealMaxChart t={t} title="TCH  ( Tn / Ha )" data={tchPred} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'Tn/Ha'} />
    </Row>
  )

  /**
   * Renders the Pol charts.
   * @returns {JSX.Element} The rendered Pol charts.
   */
  const PolCharts = () => (
    <Row>
      <IdealCurveChart t={t} title="POL ( ºBx )" data={polPred} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'ºBx'} />
      <IdealMaxChart t={t} title="POL ( ºBx )" data={polPred} areFieldsSelected={areFieldsSelected} chartsWidth={chartsWidth} yLabel={'ºBx'} />
    </Row>
  )

  return (
    <FullSizeContainer>
      <Loader zIndex={1} backgroundColor={'rgba(0,0,0, 0.3)'} isLoading={isLoading} />
      <Row>
        <FullWidthColumn>
          <HeaderContainer noMarginBottom>
            <Column width="auto">
              <TabTitle>
                {'PredTech ' + t('SEASON_EVOLUTION')} {areFieldsSelected}
              </TabTitle>
            </Column>
            {areFieldsSelected ? (
              <Column width="auto">
                <SwitchLayoutIcon />
              </Column>
            ) : null}
          </HeaderContainer>
          {areFieldsSelected ? (
            <>
              {sacHa && !isEmpty(sacHa.normalized) && <SacHaCharts />}
              {prodHa && !isEmpty(prodHa.normalized) && <ProdHaCharts />}
              {nitro && !isEmpty(nitro.normalized) && <NitroCharts />}
              {tchPred && !isEmpty(tchPred.normalized) && <TchCharts />}
              {polPred && !isEmpty(polPred.normalized) && <PolCharts />}
            </>
          ) : (
            <ChartTitle>{t('select at least one field')}</ChartTitle>
          )}
        </FullWidthColumn>
      </Row>
    </FullSizeContainer>
  )
}

PredTech.propTypes = {
  sacHa: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  polPred: PropTypes.object.isRequired,
  chartsLayout: PropTypes.string.isRequired,
  selectedFieldsIds: PropTypes.array.isRequired,
  fetchPredTechByLogType: PropTypes.func.isRequired
}
