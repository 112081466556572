import styled from 'styled-components'
import { UIStyles } from '../../../theme'

export const ToolTitle = styled.h4`
  color: ${UIStyles.whiteColor};
  font-weight: bold;
`

export const ToolWrapper = styled.div`
  margin-bottom: 2rem;
  margin-top: ${props => props.marginTop || 0};
  overflow: ${props => (props.overflowHidden ? 'hidden' : 'initial')};
`
