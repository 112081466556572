import React from 'react'
import styled from 'styled-components'
import { FormSection } from '../UIComponents/Form'
import moment from 'moment'
import DatePickerCalendar from '../UIComponents/Calendars/DateRangeCalendar/DatePickerCalendar'
import { UIStyles } from '../../theme'
import { StyledButton } from '../DeliverablesComponents/forms/FileGeneratorForm'

export const ButtonsWrapper = styled.div`
  display: flex;
  font-size: 5rem;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 3rem;
`

export const FormWrapper = styled.form`
  display: flex;
  justify-content: center;
  .MuiInputLabel-root {
    font-size: 20px;
    font-weight: 400;
    color: ${UIStyles.vomitColor};
  }
  .MuiTextField-root {
    margin-bottom: 22px;
  }
`

const DownloadStatisticsModal = ({
  t,
  selectedFields,
  fieldsLength,
  selectedFieldsLength,
  isDownloadButtonEnabled,
  fetchStatisticsModal,
  setModalProps,
  modalFrom,
  modalTo
}) => {
  const handleClick = e => {
    e.preventDefault()
    fetchStatisticsModal()
  }

  const setModalDate = fromOrTo => value => setModalProps(fromOrTo, moment(value).format('YYYY-MM-DD'))

  return (
    <FormWrapper>
      <FormSection
        little
        icon="fa fa-info-circle"
        title={
          selectedFieldsLength === 0
            ? fieldsLength + ' ' + t('fields')
            : selectedFieldsLength === 1
            ? selectedFields[0].name.toUpperCase()
            : selectedFieldsLength + ' ' + t('fields')
        }
        titleStyle={{ fontSize: '130%' }}
      >
        <DatePickerCalendar onChange={setModalDate('from')} value={modalFrom} floatingLabelText={t('from')} />

        <DatePickerCalendar onChange={setModalDate('to')} value={modalTo} floatingLabelText={t('to')} />

        <ButtonsWrapper>
          <StyledButton type="submit" value={t('Download')} disabled={!isDownloadButtonEnabled} onClick={handleClick} />
        </ButtonsWrapper>
      </FormSection>
    </FormWrapper>
  )
}

export default DownloadStatisticsModal
