import get from 'lodash/get'

import pathUtils from '../pathUtils'

import {
  DRONE_RGB_SNAPSHOT_SOURCE,
  DRONE_SNAPSHOT_SOURCE,
  PLANET_SNAPSHOT_SOURCE,
  RADAR_ASC_SNAPSHOT_SOURCE,
  RADAR_DESC_SNAPSHOT_SOURCE,
  SAT_SNAPSHOT_SOURCE,
  RECEIVE_EOX_CAPABILITIES
} from '@layers-frontend/commons/constants'

import { REQUEST_END } from '../actions/ui'
import { RECEIVE_VIEWER_DATA, RECEIVE_DASHBOARD_DATA } from '../actions/viewmode'
import { INCREMENT_SAMPLE_CONFIG_COUNT_BY_USER, RECEIVE_SAMPLE_PREFIX } from '../actions/config'

import { MAX_SENTINEL_URL_LENGTH, METADATA_DATE_RANGE, LANGS_COMPLETE } from '../constants'

export const DEV = 'dev'
export const PROD = 'prod'

export const ENV = pathUtils.isDevMode() ? DEV : PROD

const baseViewerUrl = window.location.origin
const baseEvalScriptUrl = process.env.REACT_APP_MICROSERVICE_BASE_URL

const configParameters = {
  isAppReady: false,
  initializationQueries: {
    viewer: {
      REQUEST_GLOBAL_LAYER_BY_LEGEND: false,
      REQUEST_GLOBAL_LAYER_LEGEND_BY_DELIVERABLE_NAME: false,
      REQUEST_GLOBAL_MAP_CENTROID: false,
      REQUEST_FIELD_TYPES: false,
      REQUEST_IRRIGATION_TYPES: false,
      REQUEST_REPORT_TYPES: false,
      REQUEST_REPORT_STATUSES: false,
      REQUEST_FIELDS_SEASON_PARAMETERS: false,
      REQUEST_FIELDS_TILE_ORDER: false,
      REQUEST_GEOMETRIES: false,
      REQUEST_CUSTOMER_VALUES: false,
      REQUEST_CUT_NUMBER_VALUES: false,
      REQUEST_FIELD_TYPES_VALUES: false,
      REQUEST_SAMPLE_TYPES: false,
      REQUEST_FIELDS_BY_ID: false,
      REQUEST_SAMPLE_PREFIX: false
    }
  },
  isDashboardReady: false,
  devMode: true,
  projection: {
    from: 'EPSG:4326',
    to: 'EPSG:3857'
  },
  eoxXML: null,
  maxSentinelUrlLength: MAX_SENTINEL_URL_LENGTH,
  /* metadatas default range in days*/
  metadatasDateRange: METADATA_DATE_RANGE,
  /* period of days between the first and the last flight of a campaign */
  flightDifferenceDays: {
    [SAT_SNAPSHOT_SOURCE]: 1,
    [PLANET_SNAPSHOT_SOURCE]: 0,
    [DRONE_SNAPSHOT_SOURCE]: 7,
    [DRONE_RGB_SNAPSHOT_SOURCE]: 7,
    [RADAR_ASC_SNAPSHOT_SOURCE]: 1,
    [RADAR_DESC_SNAPSHOT_SOURCE]: 1
  },
  /* sentinel HUB access token */
  sentinel: {
    baseUrl: 'https://services.sentinel-hub.com/ogc',
    apiKey: process.env.REACT_APP_SHUB_INSTANCE_ID,
    maxWfsFeatures: '100'
  },
  planet: {
    baseUrl: 'https://services.sentinel-hub.com/ogc',
    maxWfsFeatures: '100'
  },
  /* url of PDF service function for generating satellite pdf */
  baseRealtimeDeliverableDosageURL: `${baseViewerUrl}/realtime/deliverable/dosage`,
  baseRealtimeDeliverableURL: `${baseViewerUrl}/realtime/deliverable`,
  baseMonitoringGenerationUrl: `${baseViewerUrl}/monitoring_excel`,
  baseFileGeneratorUrl: `${baseViewerUrl}/files`,
  microserviceSpecsUrl: `${baseViewerUrl}/openapi.json`,
  baseEvalscriptUrl: `${baseEvalScriptUrl}/sentinelhub/evalscript`,
  /* url for downloading global files */
  baseGlobalFileUrl: `${baseViewerUrl}/s3`,
  /* available languages */
  i18n: {
    availableLocales: LANGS_COMPLETE.map(lang => ({
      locale: lang.value,
      label: lang.label,
      flag: lang.value,
      href: pathUtils.getAppUrl(lang.value !== 'es' ? lang.value : '')
    }))
  }
}

export const config = (state = configParameters, action) => {
  switch (action.type) {
    case REQUEST_END: {
      const { requestType } = action

      return {
        ...state,
        initializationQueries: {
          ...state.initializationQueries,
          viewer: {
            ...state.initializationQueries.viewer,
            [requestType]: true
          }
        }
      }
    }

    case RECEIVE_EOX_CAPABILITIES: {
      const { eoxXML } = action
      return {
        ...state,
        eoxXML
      }
    }

    case RECEIVE_VIEWER_DATA:
      return {
        ...state,
        isAppReady: true
      }

    case RECEIVE_DASHBOARD_DATA:
      return {
        ...state,
        isDashboardReady: true
      }

    case RECEIVE_SAMPLE_PREFIX: {
      const { userId, prefix, count } = action
      const previousSampleConfig = get(state, 'sampleConfig')
      return {
        ...state,
        sampleConfig: {
          ...previousSampleConfig,
          [userId]: {
            prefix,
            count
          }
        }
      }
    }

    case INCREMENT_SAMPLE_CONFIG_COUNT_BY_USER: {
      const { userId } = action
      const previousSampleConfig = get(state, 'sampleConfig')
      const previousUserSampleConfig = get(previousSampleConfig, userId)
      return {
        ...state,
        sampleConfig: {
          ...previousSampleConfig,
          [userId]: {
            ...previousUserSampleConfig,
            count: previousUserSampleConfig.count + 1
          }
        }
      }
    }
    default:
      return state
  }
}
