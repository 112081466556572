import React from 'react'
import styled from 'styled-components'
import { Dialog, DialogTitle } from '@mui/material'
import DownloadStatisticsModalContainer from '../../containers/ModalContainers/DownloadStatisticsModalContainer'
import ExtraStatisticsFetchConfirmationModalContainer from '../../containers/ModalContainers/ExtraStatisticsFetchConfirmationModalContainer'
import PlantationDateFilterModalContainer from '../../containers/ModalContainers/PlantationDateFilterModalContainer'
import GoToFieldModalContainer from '../../containers/ModalContainers/GoToFieldModalContainer'
import ConfirmChangeFieldsSelectionModalContainer from '../../containers/ModalContainers/ConfirmChangeFieldsSelectionModalContainer'
import SendFieldsReportModalContainer from '../../containers/ModalContainers/SendFieldsReportModalContainer'
import ReportManagerModalContainer from '../../containers/ModalContainers/ReportManagerModalContainer'
import FieldSeasonFormModalContainer from '../../containers/ModalContainers/FieldSeasonFormModalContainer'
import SampleImageModal from './SampleImageModal'
import noop from 'lodash/noop'

import { validModalNames } from '../../constants'
import GlobalFilesModalContainer from '../../containers/ModalContainers/GlobalFilesModalContainer'

export const DialogWrapper = styled(Dialog)`
  visibility: ${props => (props.visible === 'true' ? 'initial' : 'hidden')};
  z-index: 1200 !important;
  & > div > .dialogContent > div {
    box-shadow: none !important;
  }

  & > div > .ModalBase > div:first-child > div:first-child {
    padding: 0 !important;
    border: 0 !important;
  }

  & > * {
    opacity: ${props => (props.visible ? 'initial' : 0)};
  }
`

const {
  DOWNLOAD_STATISTICS_MODAL,
  EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL,
  PLANTATION_DATE_FILTER_MODAL,
  GO_TO_FIELD_MODAL,
  CONFIRM_CHANGE_FIELDS_SELECTION_MODAL,
  SEND_FIELDS_REPORT_MODAL,
  REPORT_MANAGER_MODAL,
  FIELD_SEASON_FORM_MODAL,
  SAMPLE_IMAGE_MODAL,
  GLOBAL_FILES_MODAL
} = validModalNames

const validModalComponents = {
  [DOWNLOAD_STATISTICS_MODAL]: DownloadStatisticsModalContainer,
  [EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL]: ExtraStatisticsFetchConfirmationModalContainer,
  [PLANTATION_DATE_FILTER_MODAL]: PlantationDateFilterModalContainer,
  [GO_TO_FIELD_MODAL]: GoToFieldModalContainer,
  [CONFIRM_CHANGE_FIELDS_SELECTION_MODAL]: ConfirmChangeFieldsSelectionModalContainer,
  [SEND_FIELDS_REPORT_MODAL]: SendFieldsReportModalContainer,
  [REPORT_MANAGER_MODAL]: ReportManagerModalContainer,
  [FIELD_SEASON_FORM_MODAL]: FieldSeasonFormModalContainer,
  [SAMPLE_IMAGE_MODAL]: SampleImageModal,
  [GLOBAL_FILES_MODAL]: GlobalFilesModalContainer
}

const componentsTitles = {
  [DOWNLOAD_STATISTICS_MODAL]: 'Statistics',
  [EXTRA_STATISTICS_FETCH_CONFIRMATION_MODAL]: 'Information',
  [PLANTATION_DATE_FILTER_MODAL]: 'plantation_start_date_range',
  [GO_TO_FIELD_MODAL]: 'go to field',
  [CONFIRM_CHANGE_FIELDS_SELECTION_MODAL]: 'confirm'
}

export default function ModalBase({ isModalOpen, isModalVisible, openModalComponentName, closeModal, t, modalProps, modalTitle, dispatch }) {
  const ModalComponentToShow = validModalComponents[openModalComponentName]
  const title = t(modalTitle && modalTitle.toLowerCase()) || t(componentsTitles[openModalComponentName])
  const { dispatchOnModalClose, onModalClose, contentStyle = {}, closeOnClickOutside = true } = modalProps
  const onModalCloseHandler = !closeOnClickOutside
    ? noop
    : () => {
        if (dispatchOnModalClose) {
          dispatch(dispatchOnModalClose())
        }
        if (onModalClose) {
          onModalClose()
        }
        closeModal()
      }

  return (
    <DialogWrapper
      disableScrollLock
      onClose={closeOnClickOutside ? onModalCloseHandler : noop}
      open={isModalOpen}
      visible={isModalVisible.toString()}
      PaperProps={{ sx: [contentStyle, title && { paddingBottom: '30px' }] }}
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      {ModalComponentToShow && <ModalComponentToShow t={t} closeModal={closeModal} {...modalProps} />}
    </DialogWrapper>
  )
}
