import React from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import styled from 'styled-components'
import { Button, DangerButton } from '../../UIComponents/StyledButtons'
import { useFormContext } from 'react-hook-form'

import capitalize from 'lodash/capitalize'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${themeColors.grayColor};
  padding: 1.5rem;
`

function FieldSeasonFormFooter({ t, closeModal, onConfirm, hasRoleDemo }) {
  const { handleSubmit } = useFormContext()

  return (
    <Wrapper>
      <DangerButton onClick={closeModal} label={capitalize(t('cancel'))} />
      <Button disabled={hasRoleDemo} label={capitalize(t('save_'))} onClick={handleSubmit(onConfirm)} testId="save-button" />
    </Wrapper>
  )
}

export default withTranslator(FieldSeasonFormFooter)
