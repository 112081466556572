import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../../theme'
import withTranslator from '../../HOCComponents/withTranslator'

const Spinner = styled.i`
  display: ${props => (props.isLoading ? 'block' : 'none')};
`
const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const LoaderText = styled.p`
  color: ${UIStyles.whiteColor};
  font-size: 1.3rem;
  text-transform: lowercase;
  margin-top: 2rem;
`

export default withTranslator(({ t, isLoading }) =>
  isLoading ? (
    <LoaderWrapper>
      <Spinner className="fa-2x fa-pulse fa-spinner fas" isLoading={isLoading} />
      <LoaderText className="fa-fade">{t('loading')}</LoaderText>
    </LoaderWrapper>
  ) : null
)
