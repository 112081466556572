import React from 'react'
import PropTypes from 'prop-types'

import LayerVector from '../olComponents/layervector'
import SourceVector from '../olComponents/sourcevector'
import MeasuresContainer from '../../containers/EditorContainers/MeasuresContainer'
import OlMap from 'ol/Map'
import { MEASURE_TOOL_Z_INDEX } from '../../constants'

export default class MeasureToolsLayer extends React.Component {
  getChildContext() {
    return { map: this.context.map }
  }

  getStyle() {
    return [
      {
        stroke: {
          color: 'rgba(255, 255, 255, 1)',
          lineDash: [10, 10],
          width: 2
        },
        fill: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        image: {
          fill: {
            color: 'red'
          },
          points: 4,
          radius: 7
        }
        // RS is for regularshape
      }
    ]
  }

  render() {
    // eslint-disable-next-line prefer-const
    let _this = this

    return (
      <div>
        <LayerVector visible={_this.props.isMeasureToolsActive} name="measures" zIndex={MEASURE_TOOL_Z_INDEX} style={this.getStyle()}>
          <SourceVector key="measures">
            <MeasuresContainer />
          </SourceVector>
        </LayerVector>
      </div>
    )
  }
}

MeasureToolsLayer.propTypes = {
  dispatch: PropTypes.func,
  isMeasureToolsActive: PropTypes.bool
}

MeasureToolsLayer.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

MeasureToolsLayer.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
