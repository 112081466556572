import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { getSelectedFlightGroupFirstFlightDate, getSelectedFlightGroupSource } from './flightGroups'
import moment from 'moment'
import keys from 'lodash/fp/keys'
import some from 'lodash/fp/some'
import find from 'lodash/fp/find'
import isEmpty from 'lodash/fp/isEmpty'
import map from 'lodash/fp/map'
import intersection from 'lodash/fp/intersection'

export const getCalendar = get('calendar')

export const getCalendarActiveDate = pipe(getCalendar, get('activeDate'))

export const getCalendarDots = pipe(getCalendar, get('calendarDots'))

export const getTempComparisonDate = pipe(getCalendar, get('tempComparisonDate'))

export const getCloudCoverage = pipe(getCalendar, get('cloudCoverage'))

const getCalendarDotsByDate = date => state => {
  const calendarDots = getCalendarDots(state)
  const yearMonth = moment(date).format('YYYY-MM')
  return pipe(get(yearMonth), get(date))(calendarDots)
}

export const getTempComparisonIndex = state => {
  const tempComparisonDate = getTempComparisonDate(state)
  const selectedFlightGroupSource = getSelectedFlightGroupSource(state)
  const tempCalendarDots = getCalendarDotsByDate(tempComparisonDate)(state)
  const tempComparisonDot = find(tempCalendarDot => tempCalendarDot.source === selectedFlightGroupSource)(tempCalendarDots)
  const tempComparisonIndex = get('index')(tempComparisonDot)
  return tempComparisonIndex
}

export const getActiveDateCalendarDots = state => {
  const activeDate = getSelectedFlightGroupFirstFlightDate(state)
  return getCalendarDotsByDate(activeDate)(state)
}

export const isActiveYearMonthLoaded = state => {
  const calendarDotsKeys = keys(getCalendarDots(state))
  const yearMonth = getCalendarActiveDate(state).format('YYYY-MM')

  return some(key => key === yearMonth)(calendarDotsKeys)
}

export const isCommonSourcesWithTemporalComparisonDate = state => {
  const activeDateCalendarDots = getActiveDateCalendarDots(state)
  const tempComparisonDate = getTempComparisonDate(state)
  const tempComparisonCalendarDots = getCalendarDotsByDate(tempComparisonDate)(state)

  const activeSources = map('source')(activeDateCalendarDots)
  const tempSources = map('source')(tempComparisonCalendarDots)

  const commonSources = intersection(activeSources, tempSources)

  return !isEmpty(commonSources)
}
