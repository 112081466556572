import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import pick from 'lodash/fp/pick'
import sortBy from 'lodash/fp/sortBy'
import concat from 'lodash/concat'
import keys from 'lodash/fp/keys'
import isEqual from 'lodash/fp/isEqual'
import toString from 'lodash/fp/toString'
import toUpper from 'lodash/fp/toUpper'
import { DRONE_TYPE } from '../constants'

import { getMessages } from './i18n'
import { getFields, getFieldsIdsMap, getSelectedFields } from './fields'
import { getFieldsPlantsWithMoreThanOnePlant } from './plants'

export const getDashboard = get('dashboard')

export const getDashboardView = get('dashboard.dashboardView')

export const getDashboardFormFilters = get('dashboard.formFilters')

export const getDashboardFormFilter = (state, key) => pipe(getDashboardFormFilters, get(key))(state)

export const getFormData = pipe(getDashboard, get('formData'))

export const getActiveFilters = pipe(getDashboard, get('activeFilters'))

export const getFieldsNameAndId = pipe(getFields, map(pick(['id', 'name'])))

export const getSelectedFieldsNameAndId = pipe(getSelectedFields, map(pick(['id', 'name'])))

export const getSortedFieldsNameAndId = pipe(
  getFieldsNameAndId,
  map(field => ({ id: field.id, name: get('name')(field), fieldNameUpperCase: pipe(get('name'), toUpper)(field) })),
  sortBy('fieldNameUpperCase')
)

export const getSortedSelectedFieldsNameAndId = pipe(
  getSelectedFieldsNameAndId,
  map(field => ({ id: field.id, name: get('name')(field), fieldNameUpperCase: pipe(get('name'), toUpper)(field) })),
  sortBy('fieldNameUpperCase')
)

export const getSelectedHarvestPlanningMultiFieldsSelectIds = pipe(getDashboardFormFilters, getOr([], `harvestPlanningMultiFieldsSelect.value`))

export const getSelectedFlightFilter = state => {
  const options = ['flightSatellite', 'flightDrone']
  const filters = getDashboardFormFilters(state)
  const selectedOption = options.find(option => filters[option].value === true)

  return selectedOption
}

export const getSelectedFlightFilterId = state => {
  const selectedFlightFilter = getSelectedFlightFilter(state)
  const id = getDashboardFormFilter(state, `${selectedFlightFilter}.id`)
  return id
}

export const isSelectedFlightFilterDrone = pipe(getSelectedFlightFilterId, toString, isEqual(DRONE_TYPE))

export const getHarvesPlanningToolFieldsOptions = state => {
  const selectedQuantity = get('length')(getSelectedHarvestPlanningMultiFieldsSelectIds(state))
  const selectOrDeselectAllMessage = selectedQuantity === 0 ? get('select all')(getMessages(state)) : get('deselect all')(getMessages(state))
  const selectAllOption = { id: -1, name: selectOrDeselectAllMessage }

  const fieldsPlants = getFieldsPlantsWithMoreThanOnePlant(state)

  const fieldsIdsMap = getFieldsIdsMap(state)

  const mappedFieldIdAndName = map(fieldId => ({ id: fieldId, name: get('name')(fieldsIdsMap[fieldId]) }))(keys(fieldsPlants))

  return concat(selectAllOption, mappedFieldIdAndName)
}

export const getHarvestPlanningToolDryMatterRangeMin = state => {
  const value = pipe(getDashboardFormFilters, get('harvestPlanningToolDryMatterRangeMin.value'))(state)

  // eslint-disable-next-line no-unneeded-ternary
  return value ? value : undefined
}

export const getHarvestPlanningToolDryMatterRangeMax = state => {
  const value = pipe(getDashboardFormFilters, get('harvestPlanningToolDryMatterRangeMax.value'))(state)
  // eslint-disable-next-line no-unneeded-ternary
  return value ? value : undefined
}

export const getMeteorologicalStatisticsSelectedFieldId = pipe(getDashboardFormFilters, get('meteorologicalStatisticsFieldsSelect.value'))

export const getMeteorologicalStatisticsSelectedField = state => {
  const selectedFieldId = getMeteorologicalStatisticsSelectedFieldId(state)
  const fields = getFieldsIdsMap(state)
  return fields[selectedFieldId]
}

export const getTemporalEvolutionAppliedFilter = pipe(getDashboardFormFilters, get('TemporalEvolutionAppliedDateFilter.value'))

export const getTemporalEvolutionAppliedFilterFrom = pipe(getTemporalEvolutionAppliedFilter, get('from'))

export const getTemporalEvolutionAppliedFilterTo = pipe(getTemporalEvolutionAppliedFilter, get('to'))

export const getTemporalEvolutionAppliedFilterFromAndTo = state => {
  const from = getTemporalEvolutionAppliedFilterFrom(state)
  const to = getTemporalEvolutionAppliedFilterTo(state)

  const fromAndTo = { from, to }

  return fromAndTo
}

export const getHarvestPlanningToolSelectedTypeFilter = pipe(getDashboardFormFilters, get('harvestPlanningToolFilterTypeSelect.value'))

export const getHarvestPlanningToolSelectedMetadataKey = pipe(getDashboardFormFilters, get('harvestPlanningToolMetadataKeySelect.value'))

export const getMeteorologicalStatisticsDateRangeFrom = pipe(getDashboardFormFilters, get('meteorologicalStatisticsDateRangeFrom.value'))

export const getMeteorologicalStatisticsDateRangeTo = pipe(getDashboardFormFilters, get('meteorologicalStatisticsDateRangeTo.value'))

export const getMeteorologicalStatisticsBaseTemperature = pipe(getDashboardFormFilters, get('meteorologicalStatisticsBaseTemperature.value'))

export const getDateRange = pipe(getDashboardFormFilters, get('dateRange.value'))

export const getDateRangeFrom = pipe(getDateRange, get('from'))

export const getDateRangeTo = pipe(getDateRange, get('to'))

export const getMaxCloudCoverage = pipe(getDashboard, get('maxCloudCoverage'))

export const getTemporalEvolutionMaxCloudCoverage = pipe(getDashboard, get('temporalEvolutionMaxCloudCoverage'))

export const getFieldComparisonOrderByDate = pipe(getDashboard, get('fieldsComparisonOrderByDate'))
