import PropTypes from 'prop-types'

import OLInteraction from './ol-interaction'
import OlDragBox from 'ol/interaction/DragBox'
import { platformModifierKeyOnly } from 'ol/events/condition'
export default class DragBox extends OLInteraction {
  createInteraction(props) {
    return new OlDragBox({
      condition: platformModifierKeyOnly
    })
  }
}

DragBox.propTypes = Object.assign({}, OLInteraction.propTypes, {
  boxdrag: PropTypes.func,
  boxend: PropTypes.func,
  boxstart: PropTypes.func,
  condition: PropTypes.func
})

DragBox.olEvents = ['boxdrag', 'boxend', 'boxstart']
