import { deselectAllSamples } from './samples'
import { resetFormFields } from '@layers-frontend/commons/store/actions/samplesForm'
import { isEditorPresentActive } from '../selectors/editor'
export const SET_ACTIVE_FIELD_TAB = 'SET_ACTIVE_FIELD_TAB'
export const SET_SELECTED_FIELD_TAB_VISIBILITY = 'SET_SELECTED_FIELD_TAB_VISIBILITY'
export const RESET_SELECTED_FIELD_TABS = 'RESET_SELECTED_FIELD_TABS'
export const SET_SELECTED_FIELD_SAMPLES_TAB = 'SET_SELECTED_FIELD_SAMPLES_TAB'
export const HIDE_SELECTED_FIELD_TAB = 'HIDE_SELECTED_FIELD_TAB'

export const setSelectedFieldTab = tabIndex => ({ type: SET_ACTIVE_FIELD_TAB, tabIndex })

export const setSelectedFieldTabVisibility = tabName => ({ type: SET_SELECTED_FIELD_TAB_VISIBILITY, tabName })

export const hideSelectedFieldTab = tabName => ({ type: HIDE_SELECTED_FIELD_TAB, tabName })

export const resetSelectedFieldTabs = () => (dispatch, getState) => {
  const state = getState()
  const isActive = isEditorPresentActive(state)
  if (!isActive) {
    dispatch(deselectAllSamples())
    dispatch(resetFormFields())
  }
  return dispatch({ type: RESET_SELECTED_FIELD_TABS })
}

export const setSelectedFieldSamplesTab = () => ({ type: SET_SELECTED_FIELD_SAMPLES_TAB })
