import React from 'react'
import '../../utils'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import split from 'lodash/split'
import last from 'lodash/last'
import get from 'lodash/get'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 5px;
  right: 25px;
  cursor: ${props => (props.hasRoleIrriga ? 'default' : 'pointer')};
  background: ${UIStyles.vomitColor};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
`

const ImageWrapper = styled.img`
  width: 100%;
  border-radius: 50%;
`

const IconWrapper = styled.i`
  text-align: center;
  display: block;
  color: ${themeColors.blueColor};
  font-size: 26px;
`

export default class Avatar extends React.Component {
  constructor(props) {
    super(props)

    this.handleToggleMenuChange = this.handleToggleMenuChange.bind(this)
  }

  componentDidMount() {}

  handleToggleMenuChange() {
    this.props.handleToggleMenu()
  }

  render() {
    const { user, hasRoleIrriga } = this.props
    const agroUserLogo = get(user, 'agro__customers[0].agro_user.user.logo')
    const bucketUrl = process.env.REACT_APP_BUCKET
    const bucketName = last(split(bucketUrl, '//'))
    const logoBaseUrl = `https://${bucketName}.s3.eu-west-2.amazonaws.com/logos/`

    const isUserLogo = !user.isNullObject() && user.logo
    const isCooperative = get(user, 'agro__customers[0].agro__cooperative')
    const isCooperativeLogo = isCooperative?.user?.logo || null
    const isAgroUserLogo = agroUserLogo || null
    const srcLogo = isUserLogo || (isCooperative ? isCooperativeLogo : isAgroUserLogo)

    const profilePic = srcLogo ? <ImageWrapper data-testid="avatar-image" src={`${logoBaseUrl}${srcLogo}`} /> : <IconWrapper className="fa-solid fa-user" />

    return (
      <Wrapper onClick={this.handleToggleMenuChange} hasRoleIrriga={hasRoleIrriga}>
        {profilePic}
      </Wrapper>
    )
  }
}
