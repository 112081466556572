import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { listenerMiddleware } from './listenerMiddleware'

export default ({ rootReducer, rootMiddleware = [], apiMiddlewares = [] }) =>
  configureStore({
    reducer: rootReducer,
    // Add the listener middleware _before_ the thunk or dev checks
    middleware: getDefaultMiddleware => {
      const middleware = getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).prepend(listenerMiddleware.middleware)
      // Conditionally add another middleware in dev
      if (process.env.NODE_ENV !== 'production') {
        middleware.push(logger)
      }
      rootMiddleware && middleware.concat(rootMiddleware)
      if (apiMiddlewares.length > 0) {
        middleware.push(...apiMiddlewares)
      }

      return middleware
    }
  })
