import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { metadataToExtraParamterers } from '../../helpers/extraParameters/extraParameters'

export const getSampleParametersByType = get('sampleParametersByType')

export const getSampleParametersByTypeId = id => pipe(getSampleParametersByType, get(id))

export const getSampleNormalizedParameters = state => sample => {
  const sampleMetadata = get('metadata')(sample)
  const sampleTypeId = get('type.id')(sample)
  const parameters = getSampleParametersByTypeId(sampleTypeId)(state)
  return metadataToExtraParamterers(sampleMetadata, parameters)
}
