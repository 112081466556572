import PropTypes from 'prop-types'
import OLInteraction from './ol-interaction'
import { doubleClick } from 'ol/events/condition'
import OlSelect from 'ol/interaction/Select'

export default class DoubleClick extends OLInteraction {
  createInteraction(props) {
    return new OlSelect({
      condition: doubleClick
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    super.UNSAFE_componentWillReceiveProps(newProps)
    this.selectFeature(newProps.selectedFeature)
  }

  componentDidMount() {
    this.selectFeature(this.props.selectedFeature)
    super.componentDidMount()
  }

  selectFeature(feature) {
    this.interaction.getFeatures().clear()
    if (feature != null) {
      this.interaction.getFeatures().push(feature)
    }
  }
}

DoubleClick.propTypes = Object.assign({}, OLInteraction.propTypes, {
  condition: PropTypes.func,
  select: PropTypes.func
})

DoubleClick.olEvents = ['select']
