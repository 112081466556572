import React, { useState, useEffect } from 'react'
import AutoComplete from '@mui/material/Autocomplete'
import { TextField } from '@mui/material'

import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'

import reduce from 'lodash/reduce'

import withTranslator from '../HOCComponents/withTranslator'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import isNil from 'lodash/isNil'

const style = {
  '& .MuiAutocomplete-option.Mui-focused': {
    backgroundColor: themeColors.vomitColor
  }
}

const preventSubmitOnEnter = e => {
  e.key === 'Enter' && e.preventDefault()
}

const StyledAutoComplete = ({
  t,
  label,
  options,
  value,
  onChange,
  optionIdKey = 'id',
  optionReadableKey = 'name',
  errorText,
  disabled = false,
  defaultValue = null,
  placeholder = null,
  testId
}) => {
  const [searchText, setSearchText] = useState('')
  const [normalizedOptions, setNormalizedOptions] = useState({})
  const normalizedDefaultValue = defaultValue ? get(defaultValue, optionIdKey) : null

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const normalized = reduce(
      options,
      (accumulator, option) => {
        return {
          ...accumulator,
          [get(option, optionIdKey)]: get(option, optionReadableKey)
        }
      },
      {}
    )

    setNormalizedOptions(normalized)
  }, [options])

  useEffect(() => {
    if (isFinite(value)) {
      const text = t(get(normalizedOptions, value, ''))
      setSearchText(text)
    }
  }, [value, normalizedOptions])

  useEffect(() => {
    if (normalizedDefaultValue && isNil(value)) {
      onChange(normalizedDefaultValue)
    }
  }, [])
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <AutoComplete
      options={map(
        sortBy(options, option => t(get(option, optionReadableKey))),
        item => {
          return {
            label: t(get(item, optionReadableKey)),
            value: item.id
          }
        }
      )}
      onClose={() => {
        const text = get(normalizedOptions, value, '')
        setSearchText(text)
      }}
      onChange={(_, option) => onChange(option.value)}
      filterOptions={AutoComplete.fuzzyFilter}
      openOnFocus={true}
      defaultValue={normalizedDefaultValue}
      disableClearable
      freeSolo
      onFocus={() => setSearchText('')}
      onInputChange={e => e?.target?.value && setSearchText(e.target.value)}
      onKeyPress={preventSubmitOnEnter}
      disabled={isEmpty(options) || disabled}
      value={t(searchText)}
      ListboxProps={{ sx: style }}
      data-testid={testId}
      renderInput={params => (
        <TextField
          error={errorText}
          {...params}
          label={capitalize(t(label))}
          variant="standard"
          color="primary"
          margin="normal"
          InputLabelProps={{ shrink: true, color: 'secondary' }}
          helperText={capitalize(t(errorText))}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default withTranslator(StyledAutoComplete)
