import React from 'react'
import PropTypes from 'prop-types'
import MeasureTool from '../../editorComponents/MeasureTool'

//ol
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'

export default class DrawArea extends MeasureTool {
  getChildContext() {
    return {
      map: this.context.map,
      source: this.context.source
    }
  }

  render() {
    return (
      <div>
        <MeasureTool config={this.props.config} type="Polygon" />
      </div>
    )
  }
}

DrawArea.childContextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}

DrawArea.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
