import filter from 'lodash/filter'
import { UPDATE_REPORT_SUCCESS, DELETE_REPORT_SUCCESS, SELECT_REPORT, RESET_SELECTED_REPORT } from '../storeConstants'

export const initialState = {
  list: [],
  selectedReportId: null
}

export default function reports(state = initialState, action = {}) {
  switch (action.type) {
    case UPDATE_REPORT_SUCCESS:
      const reportId = action.reportId
      const statusId = action.statusId
      return {
        ...state,
        list: {
          ...state.list,
          [reportId]: {
            ...state.list[reportId],
            statusId
          }
        }
      }
    case DELETE_REPORT_SUCCESS:
      return {
        ...state,
        list: filter(state.list, report => report.id !== action.reportId)
      }
    case SELECT_REPORT:
      return {
        ...state,
        selectedReportId: action.reportId
      }
    case RESET_SELECTED_REPORT:
      return {
        ...state,
        selectedReportId: initialState.selectedReportId
      }
    default:
      return state
  }
}
