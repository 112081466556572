import PropTypes from 'prop-types'
import OLComponent from './ol-component'

import OlLayerGroup from 'ol/layer/Group'

import OlSourceXYZ from 'ol/source/XYZ'
import OlSourceWMTS, { optionsFromCapabilities } from 'ol/source/WMTS'
import OlSourceWMS from 'ol/source/TileWMS'
import { createXYZ } from 'ol/tilegrid'
import OlAttribution from 'ol/control/Attribution'
import { get } from 'ol/proj'

import WMTSCapabilities from 'ol/format/WMTSCapabilities.js'

import OlLayerTile from 'ol/layer/Tile'

import map from 'lodash/map'
import OlMap from 'ol/Map'

export default class BaseMap extends OLComponent {
  constructor(props) {
    super(props)
    const parser = new WMTSCapabilities()
    const result = this.props.eoxXML !== null ? parser.read(this.props.eoxXML) : null
    this.result = result
    this.layer = null
  }

  componentDidUpdate(prevProps) {
    if (this.props.eoxXML !== prevProps.eoxXML) {
      const parser = new WMTSCapabilities()
      const result = this.props.eoxXML !== null ? parser.read(this.props.eoxXML) : null
      this.result = result
      this.context.map.removeLayer(this.layer)
      this.componentDidMount()
    }
  }

  componentDidMount() {
    this.sources = map(this.props.sources, source => {
      switch (source.sourceType) {
        case 'OlSourceXYZ':
          return new OlSourceXYZ({
            attributions: [new OlAttribution({ html: source.attributions })],
            url: source.url,
            matrixSet: this.props.projection.from,
            projection: get(this.props.projection.to),
            maxZoom: 20
          })
        case 'OlSourceWMS':
          return new OlSourceWMS({
            attributions: [new OlAttribution({ html: source.attributions })],
            url: source.url,
            tileGrid: createXYZ({ maxZoom: 22, minZoom: 0 }),
            projection: get(this.props.projection.to),
            maxZoom: 20,
            params: {
              LAYERS: source.layers
            }
          })
        case 'OlSourceWMTS':
          if (!this.result) break
          // eslint-disable-next-line no-case-declarations
          const options = optionsFromCapabilities(this.result, {
            layer: source.layer,
            matrixSet: this.props.projection.from
          })
          return new OlSourceWMTS(options)

        default:
          return new OlSourceXYZ({
            attributions: [new OlAttribution({ html: source.attributions })],
            url: source.url,
            matrixSet: this.props.projection.from,
            projection: get(this.props.projection.to),
            maxZoom: 20
          })
      }
    })
    const layers = this.sources.map(source => {
      const layer = new OlLayerTile({
        visible: true,
        preload: 1,
        source
      })
      return layer
    })

    const layerGroup = new OlLayerGroup({
      visible: true,
      name: 'base',
      zIndex: 1000,
      opacity: 1,
      layers
    })

    this.layer = layerGroup

    this.context.map.addLayer(layerGroup)
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }

  getChildContext() {
    return {
      layerGroup: this.layerGroup,
      map: this.context.map
    }
  }
}

BaseMap.propTypes = {
  accessToken: PropTypes.string,
  projection: PropTypes.object
}
BaseMap.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
BaseMap.childContextTypes = {
  layerGroup: PropTypes.instanceOf(OlLayerGroup),
  map: PropTypes.instanceOf(OlMap)
}
