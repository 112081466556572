import React from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import layersLogo from '../../assets/img/logo-layers-full.png'
import { APPLE_STORE_URL, ANDROID_STORE_URL, APP_DEEP_LINK_BASE_URL } from '@layers-frontend/commons/constants'
import { RadialGradientBG } from '../Login'

/*----------- Styled components -----------*/

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  overflow-x: auto;
  overflow-y: auto;
`

export const Logo = styled.img`
  width: 21rem;
  align-self: center;
  margin-bottom: 15px;
`
export const LogoButton = styled.a`
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
`

export const Message = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  align-self: center;
  color: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 45px;
`

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  color: white;
  background-color: transparent;
  margin-bottom: 30px;
`

export const ButtonText = styled.label`
  font-size: 1.8em;
  color: white;
  background-color: transparent;
`

export const Button = styled.a`
  width: 11rem;
  height: 11rem;
  display: flex;
  justify-content: center;
  align-self: center;
  text-align: center;
  font-size: 5rem;
  border-radius: 50%;
  border: 0.5rem solid;
  color: white;
  background-color: transparent;
`

export const ButtonLabel = styled.h3`
  font-size: 2.8rem;
`

class AlertMobile extends React.Component {
  handleOpenAppStore = () => {
    const storeURL = this.props.isIOS ? APPLE_STORE_URL : ANDROID_STORE_URL

    window.open(storeURL, '_blank').focus()
  }

  handleOpenApp = () => {
    const url = `${APP_DEEP_LINK_BASE_URL}?token=${this.props.apiKey}`

    window.open(url, '_self').focus()
  }

  render() {
    const { t, isIOS } = this.props
    return (
      <Wrapper>
        <RadialGradientBG />
        <Message>{t('Try our mobile app for a better experience')}</Message>
        <ButtonsBox>
          <ButtonWrapper>
            <Button onClick={this.handleOpenAppStore}>
              <i className={isIOS ? 'fab fa-apple' : 'fab fa-google-play'} style={{ alignSelf: 'center' }} />
            </Button>
            <ButtonText>{t('Download')}</ButtonText>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={this.handleOpenApp}>
              <i className={'fas fa-mobile-alt'} style={{ alignSelf: 'center' }} />
            </Button>
            <ButtonText>{t('Open')}</ButtonText>
          </ButtonWrapper>
        </ButtonsBox>
        <LogoButton onClick={this.handleOpenApp}>
          <Logo src={layersLogo} />
        </LogoButton>
      </Wrapper>
    )
  }
}

export default withTranslator(AlertMobile)
