import React from 'react'
import styled from 'styled-components'

import isEmpty from 'lodash/isEmpty'

const Image = styled.img`
  height: auto;
  max-width: 100%;
`
const ImageData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
export default function SampleInfoImage({ src, onClick }) {
  return !isEmpty(src) ? (
    <ImageData>
      <Image src={src} onClick={onClick} />
    </ImageData>
  ) : null
}
