import React from 'react'

export default class FieldEditView extends React.Component {
  render() {
    return (
      <div className="fieldViewContainer">
        <div className="description"></div>
      </div>
    )
  }
}
