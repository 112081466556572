import React from 'react'
import PropTypes from 'prop-types'
import OlMap from 'ol/Map'

import get from 'lodash/get'

import { builGroupdDeliverablesFromProps } from '../../groupDeliverablesUtils'
import LayerGroupContainer from '../../containers/olContainers/LayerGroupContainer'

const initialState = []
export default class TemporalGroupComparisionLayer extends React.Component {
  state = {
    layerGroupsData: initialState
  }

  getChildContext() {
    return { map: this.context.map }
  }

  _resetState() {
    this.setState({
      layerGroupsData: initialState
    })
  }

  _prepareDeliverables(props) {
    const deliverables = builGroupdDeliverablesFromProps(props)
    this.setState({ layerGroupsData: deliverables })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.showTemporal) {
      this._prepareDeliverables(nextProps)
    } else {
      this._resetState()
    }
  }

  render = () => {
    const { showLayers, orderDeliverables, temporalSwipe, groupDeliverablesBaseZIndex, token } = this.props
    // eslint-disable-next-line prefer-const
    let deliverablesLayer =
      showLayers && this.state.layerGroupsData.length > 0
        ? this.state.layerGroupsData.map((layerGroupData, index) => {
            const deliverable = get(layerGroupData[0], 'deliverable')
            if (deliverable && deliverable.visible) {
              // eslint-disable-next-line prefer-const
              let zIndex = orderDeliverables.indexOf(deliverable.id)
              return (
                <LayerGroupContainer
                  display="right"
                  temporalSwipe={temporalSwipe}
                  visible={deliverable.visible}
                  opacity={1}
                  zIndex={groupDeliverablesBaseZIndex + zIndex}
                  key={'maskCompare' + deliverable.id + '' + index + ''}
                  name={'maskCompare'}
                  layers={layerGroupData}
                  token={token}
                />
              )
            }
            return null
          })
        : null

    return <div>{deliverablesLayer}</div>
  }
}

TemporalGroupComparisionLayer.propTypes = {
  dispatch: PropTypes.func,
  orderDeliverables: PropTypes.arrayOf(PropTypes.number),
  selectedComparedDeliverable: PropTypes.object,
  showLayers: PropTypes.bool,
  showTemporal: PropTypes.bool,
  groupDeliverablesBaseZIndex: PropTypes.number,
  token: PropTypes.string
}

TemporalGroupComparisionLayer.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

TemporalGroupComparisionLayer.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
