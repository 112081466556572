import { REQUEST_SAMPLE_TYPES_SUCCESS } from '../storeConstants'

const initialState = []

export default function sampleTypes(state = initialState, action = {}) {
  switch (action.type) {
    case REQUEST_SAMPLE_TYPES_SUCCESS:
      return action.sampleTypes

    default:
      return state
  }
}
