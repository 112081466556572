import DashboardAppContainer from '../containers/DashboardAppContainer'
import ViewerAppContainer from '../containers/ViewerAppContainer'
import LoginContainer from '../containers/LoginContainer'
import TokenLogin from '../components/TokenLogin'
import LogoutContainer from '../containers/LogoutContainer'
import { ROUTES } from '../constants'
import IframeContainer from '../containers/UtilitiesContainers/IframeContainer'
import AccountPage from './AccountPage'
import LoginToAccounts from './LoginToAccounts'

export const { LAYERS_ROUTE, DASHBOARD_ROUTE, TOKEN_LOGIN_ROUTE, LOG_OUT_ROUTE, BILABS_ROUTE, ACCOUNTS_ROUTE, LOGIN_ROUTE } = ROUTES

export const notAuthenticatedRoutes = [
  {
    path: '/',
    name: 'Login',
    element: <LoginContainer />,
    exactRoute: true
  },
  {
    path: '/login',
    name: 'Login',
    element: <LoginToAccounts />,
    exactRoute: true
  },
  {
    path: `/:locale?/${TOKEN_LOGIN_ROUTE}/:token`,
    name: 'Token login',
    element: <TokenLogin />
  },
  {
    path: `/:locale?/${LOG_OUT_ROUTE}`,
    name: 'Logout',
    element: <LogoutContainer />,
    exactRoute: true
  }
]

export const authenticatedRoutes = [
  {
    path: `/:locale?/${LAYERS_ROUTE}`,
    name: 'Layers',
    element: <ViewerAppContainer />
  },
  {
    path: `/:locale?/${DASHBOARD_ROUTE}`,
    name: 'Dashboard (beta)',
    element: <DashboardAppContainer />
  },
  {
    path: `/:locale?/${BILABS_ROUTE}`,
    name: 'BI Labs',
    element: <IframeContainer />
  },
  {
    path: `/:locale?/${ACCOUNTS_ROUTE}`,
    name: 'Accounts',
    element: <AccountPage />
  }
]
