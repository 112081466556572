import FieldSeasonFormModal from '../../components/ModalComponents/FieldSeasonFormModal'
import { connect } from 'react-redux'
import { compose } from 'redux'

import withTranslator from '../../components/HOCComponents/withTranslator'
// eslint-disable-next-line import/no-duplicates
import { getFieldsTypes } from '../../selectors/fields'
import { getCustomers } from '../../selectors/users'
// eslint-disable-next-line import/no-duplicates
import { getFirstSelectedField, getPreviewField } from '../../selectors/fields'
import {
  addSeason,
  closeFieldSeasonForm,
  setActiveSeasonIndex,
  toggleFieldSeasonFormEditor,
  resetEditingFeaturesAndSurface,
  createNewFieldSeasons
} from '../../actions/fieldSeasonForm'
import { fetchFirstSelectedFieldSeasons, fetchPreviewFieldSeasons } from '../../actions/seasons'
import { postFieldWithSeasons, patchFieldWithSeason, onDeleteField } from '../../actions/fields'
import {
  getActiveSeasonIndex,
  getFieldSeasonFormSeasons,
  isFieldSeasonFormEditorOpen,
  getEditingGeometry,
  isNewField,
  isReadOnly
} from '../../selectors/fieldSeasonForm'
import { openEditModeWithCurrentGeometry } from '../../actions/viewmode'
import { setEditorGeometry, setEditorFeaturesAndSurface } from '../../actions/editor'
import { hasRoleDemo } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  fieldTypes: getFieldsTypes(state),
  customers: getCustomers(state),
  isFieldSeasonFormEditorOpen: isFieldSeasonFormEditorOpen(state),
  seasons: getFieldSeasonFormSeasons(state),
  activeSeasonIndex: getActiveSeasonIndex(state),
  selectedField: getFirstSelectedField(state),
  previewField: getPreviewField(state),
  isNewField: isNewField(state),
  isReadOnly: isReadOnly(state),
  editingGeometry: getEditingGeometry(state),
  hasRoleDemo: hasRoleDemo(state)
})

const mapDispatchToProps = {
  toggleFieldSeasonFormEditor,
  setActiveSeasonIndex,
  addSeason,
  fetchFirstSelectedFieldSeasons,
  fetchPreviewFieldSeasons,
  setEditorGeometry,
  setEditorFeaturesAndSurface,
  resetEditingFeaturesAndSurface,
  openEditModeWithCurrentGeometry,
  createNewFieldSeasons,
  postFieldWithSeasons,
  patchFieldWithSeason,
  closeModal: closeFieldSeasonForm,
  onDeleteField
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(FieldSeasonFormModal)
