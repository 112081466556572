import values from 'lodash/values'
import snakeCase from 'lodash/snakeCase'
import userStoredData from '../userStoredData'
import { loader } from 'graphql.macro'
import { afetch } from '../apolloFetch'
import { errorNotification } from './notifications'

import { USER_ROLES, APPLICATION_JSON_HEADER, LANGUAGE, UNIT_SYSTEM, SURFACE_UNIT, DEFAULT_GLOBAL_MAP } from '@layers-frontend/commons/constants'

import { fetchRequestWrapper } from './ui'
import get from 'lodash/get'

export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS'
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS'
export const REQUEST_PROFILE = 'REQUEST_PROFILE'
export const RECEIVE_PROFILE = 'RECEIVE_PROFILE'
export const REQUEST_LOGIN = 'REQUEST_LOGIN'
export const RECEIVE_LOGIN = 'RECEIVE_LOGIN'
export const REQUEST_API_KEY_WITH_OAUTH_TOKEN = 'REQUEST_API_KEY_WITH_OAUTH_TOKEN'
export const RECEIVE_API_KEY_WITH_OAUTH_TOKEN = 'RECEIVE_API_KEY_WITH_OAUTH_TOKEN'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const LOG_OUT = 'LOG_OUT'

/** user roles **/
export const ROLE_AGRO = USER_ROLES.ROLE_AGRO
export const ROLE_FARMER = USER_ROLES.ROLE_FARMER
export const ROLE_COOPERATIVE = USER_ROLES.ROLE_COOPERATIVE
export const ROLE_SATELLITE_PDF = USER_ROLES.ROLE_SATELLITE_PDF
export const ROLE_DEMO = USER_ROLES.ROLE_DEMO
export const ROLE_IRRIGA = USER_ROLES.ROLE_IRRIGA
export const ROLE_HARVEST_PLANNING_TOOL = USER_ROLES.ROLE_HARVEST_PLANNING_TOOL
export const ROLE_COORDS_LOGGER = USER_ROLES.ROLE_COORDS_LOGGER
export const ROLE_FILE_GENERATOR = USER_ROLES.ROLE_FILE_GENERATOR
export const ROLE_PLANET = USER_ROLES.ROLE_PLANET
export const ROLE_PRED_TECH = USER_ROLES.ROLE_PRED_TECH
export const ROLE_ORDERED_FEATURES = USER_ROLES.ROLE_ORDERED_FEATURES

/** user settings **/
export const REQUEST_PATCH_USER_CONFIG = 'REQUEST_PATCH_USER_CONFIG'
export const RECEIVE_PATCH_USER_CONFIG = 'RECEIVE_PATCH_USER_CONFIG'

/*----------- Fetch all customers -----------*/
const requestCustomers = user => ({
  type: REQUEST_CUSTOMERS
})

const receiveCustomers = json => ({
  type: RECEIVE_CUSTOMERS,
  payload: json,
  receivedAt: Date.now()
})

const requestProfile = () => ({
  type: REQUEST_PROFILE
})

const receiveProfile = json => ({
  type: RECEIVE_PROFILE,
  payload: json
})

// eslint-disable-next-line camelcase
const receiveLogin = ({ api_key }) => {
  userStoredData.setToken(api_key)

  return {
    type: RECEIVE_LOGIN,
    // eslint-disable-next-line camelcase
    token: api_key
  }
}

const receiveConfig = key => config => dispatch => {
  return dispatch({
    type: RECEIVE_PATCH_USER_CONFIG,
    config: {
      [LANGUAGE]: get(config, LANGUAGE),
      [UNIT_SYSTEM]: get(config, snakeCase(UNIT_SYSTEM)),
      [SURFACE_UNIT]: get(config, snakeCase(SURFACE_UNIT)),
      [DEFAULT_GLOBAL_MAP]: get(config, DEFAULT_GLOBAL_MAP)
    }
  })
}

const errorLogin = error => ({
  type: ERROR_LOGIN,
  error
})

export const logout = () => {
  userStoredData.removeToken()

  return {
    type: LOG_OUT
  }
}

const getCustomers = loader('../graphql/customers/getCustomers.gql').loc.source.body
// thunk action creator
export const fetchCustomers = () => dispatch => {
  dispatch(requestCustomers(userStoredData.API_KEY))
  return afetch({
    query: getCustomers
  })
    .then(
      response => response,
      // eslint-disable-next-line
      error => dispatch(errorNotification('An error occurred from the server. Please try again'))
    )
    .then(json => dispatch(receiveCustomers(json.data.customers)))
}

const getUser = loader('../graphql/getUser.gql').loc.source.body

export const fetchProfile = () => dispatch => {
  dispatch(requestProfile())
  return afetch({
    query: getUser,
    variables: { apiKey: userStoredData.API_KEY }
  })
    .then(
      response => response,
      // eslint-disable-next-line
      error => dispatch(errorNotification('An error occurred from the server. Please try again'))
    )
    .then(json => {
      return dispatch(receiveProfile(json.data.user[0]))
    })
}

export const fetchLogin = ({ login, password }) =>
  fetchRequestWrapper({
    route: 'get_api_key',
    requestType: REQUEST_LOGIN,
    onSuccess: receiveLogin,
    onError: errorLogin,
    fetchOptions: {
      method: 'POST',
      headers: APPLICATION_JSON_HEADER,
      body: JSON.stringify({ login, password })
    }
  })

export const fetchApiKeyWithOauthToken = ({ oauthData }) => dispatch =>
  dispatch(
    fetchRequestWrapper({
      route: 'get_azure_api_key',
      requestType: REQUEST_API_KEY_WITH_OAUTH_TOKEN,
      onSuccess: receiveLogin,
      onError: errorLogin,
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER,
        body: JSON.stringify(oauthData)
      }
    })
  )

export const patchConfig = (key, value) => dispatch => {
  const body = {
    [key]: value
  }

  return dispatch(
    fetchRequestWrapper({
      requestType: REQUEST_PATCH_USER_CONFIG,
      route: 'patch_user_configuration',
      onSuccess: receiveConfig(key),
      onError: error => {
        const message = values(error.errors?.[0])?.[0]
        if (message) {
          errorNotification(message)
        } else {
          errorNotification('An error occured from the server. Please try again')
        }
      },
      overlay: false,

      fetchOptions: {
        method: 'PATCH',
        headers: APPLICATION_JSON_HEADER,
        body: JSON.stringify(body)
      }
    })
  )
}
