import React from 'react'
import DatePickerCalendar from '../../UIComponents/Calendars/DateRangeCalendar/DatePickerCalendar'
import { DASHBOARD_FILTERS_TYPES } from '../../../actions/dashboard'
import moment from 'moment'
import styled from 'styled-components'
import { UIStyles } from '../../../theme'

const { TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM, TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO } = DASHBOARD_FILTERS_TYPES

// Temporal evolution dashboard date range

export default function TemporalEvolutionMetadataDateRangeSelect({
  t,
  toggleDashFilter,
  dateRangeFrom,
  dateRangeTo,
  satStatisticsFetchFromDate,
  openExtraStatisticsFetchConfirmationModal,
  selectedFields
}) {
  const isTemporalEvolutionFieldSelected = selectedFields.length > 0
  const today = new Date()

  const momentSatStatisticsFetchFromDate = moment(satStatisticsFetchFromDate)
  const isDatePickerFromBeforeSatStatisticsFetchFromDate = moment(dateRangeFrom).isBefore(momentSatStatisticsFetchFromDate)

  const buttonTitle = !isTemporalEvolutionFieldSelected ? t('select at least one field') : undefined

  const clickHandler = () => {
    if (isDatePickerFromBeforeSatStatisticsFetchFromDate) {
      return openExtraStatisticsFetchConfirmationModal()
    }
  }

  const handleChangeFrom = date => {
    toggleDashFilter(TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM, date)
  }

  const handleChangeTo = date => {
    toggleDashFilter(TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO, date)
  }

  const toMinDate = dateRangeFrom ? new Date(dateRangeFrom) : new Date(momentSatStatisticsFetchFromDate)
  toMinDate.setDate(toMinDate.getDate() + 1)

  const fromMaxDate = dateRangeTo ? new Date(dateRangeTo) : new Date(today)
  fromMaxDate.setDate(fromMaxDate.getDate() - 1)

  return (
    <FilterWrapper>
      <DatePickerCalendar floatingLabelText={t('from')} onChange={handleChangeFrom} value={dateRangeFrom} maxDate={fromMaxDate} fullWidth={false} />
      <DatePickerCalendar floatingLabelText={t('to')} onChange={handleChangeTo} value={dateRangeTo} minDate={toMinDate} fullWidth={false} />
      <StyledButton onClick={clickHandler} disabled={!isTemporalEvolutionFieldSelected} title={buttonTitle}>
        <span>{t('apply date filter')} </span>
        <i className="fa fa-chevron-circle-right" />
      </StyledButton>
    </FilterWrapper>
  )
}

export const StyledButton = styled.button`
  border: 1px solid;
  border-radius: 4px;
  outline: none;
  background-color: transparent;
  color: ${UIStyles.whiteColor};
  border-color: ${UIStyles.whiteColor};
  padding: 6px 12px;
  display: block;
  margin: 9px 0 0 0;
  transition: all 0.3s ease;

  &[disabled],
  &[disabled]:hover {
    background-color: rgba(0, 0, 0, 0.4);
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
    border-color: rgba(0, 0, 0, 0.4);
  }

  &:hover {
    color: ${UIStyles.vomitColor};
    border-color: ${UIStyles.vomitColor};
  }
`

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  margin: ${props => (props.margin ? props.margin : '0rem 0rem')};
  .MuiTextField-root {
    margin-right: 20px;
  }
  .MuiInputLabel-root {
    font-weight: 600;
  }
  .MuiInput-input {
    color: ${UIStyles.vomitColor};
  }
`
