import { fetchRequestWrapper, setRequestFail } from './ui'
import { REQUEST_GLOBAL_FILES } from '@layers-frontend/commons/store/storeConstants'
import { APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE } from '@layers-frontend/commons/constants'
import { getGlobalFilesJson } from '../selectors/config'

export const fetchGlobalFiles = payload => (dispatch, getState) => {
  const state = getState()
  const customRoute = getGlobalFilesJson(state)
  return dispatch(
    fetchRequestWrapper({
      requestType: REQUEST_GLOBAL_FILES,
      customRoute,
      onSuccess: array => {
        return { type: REQUEST_GLOBAL_FILES, globalFiles: array }
      },
      fetchOptions: {
        method: 'POST',
        headers: APPLICATION_JSON_HEADER_PROCESSING_MICROSERVICE,
        body: JSON.stringify(payload)
      },
      onError: error => {
        dispatch(setRequestFail)
        console.log('error', error)
      }
    })
  )
}
