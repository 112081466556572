import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import map from 'lodash/map'
import filter from 'lodash/filter'
import toNumber from 'lodash/toNumber'
import union from 'lodash/union'
import unionBy from 'lodash/unionBy'
import toString from 'lodash/toString'

import { CHUNK_SIZE } from '@layers-frontend/commons/constants'

import {
  SELECT_FIELD,
  SELECT_FIELDS,
  DESELECT_FIELDS,
  REQUEST_FIELDS,
  RECEIVE_FIELDS,
  REQUEST_FIELD_TYPES,
  RECEIVE_FIELD_TYPES,
  RECEIVE_IRRIGATION_TYPES,
  SELECT_SNAPSHOT,
  SELECT_FLIGHTGROUP,
  MOVE_TEMPORAL_SLIDER,
  SELECT_FIELD_IMAGE,
  DESELECT_FIELD_IMAGE,
  ADD_FLIGHT_GROUPS,
  ADD_FIELDS_TO_GROUP,
  REMOVE_FIELD_TO_GROUP,
  FLUSH_FIELDS_GROUP,
  DELETE_FIELD_FROM_LIST,
  SET_PREVIEW_FIELD_ID,
  RESET_PREVIEW_FIELD_ID,
  RECEIVE_FIELDS_COUNT,
  UPDATE_FIELDS_COUNT,
  UPDATE_CURRENT_CHUNK,
  RECEIVE_GEOMETRIES
} from '../actions/fields'

import {
  TOGGLE_DELIVERABLE_GROUP,
  TOGGLE_VISIBILITY_DELIVERABLE_GROUP,
  CHANGE_OPACITY_GROUP,
  SET_LAYER_ORDER_GROUP,
  CLEAR_VISIBILITIES_GROUP,
  ACTIVE_LAYERS
} from '../actions/deliverables'

import { TOGGLE_SELECTION } from '../actions/globalLayer'

import { OPEN_SEARCH_MODE } from '../actions/viewmode'
import { arrayMove } from '../utils'
import orderBy from 'lodash/orderBy'

export const geometries = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_GEOMETRIES:
      // eslint-disable-next-line no-case-declarations
      const parsedGeometries = map(action.geometries, field => {
        const geometryInfo = get(field, 'geometry[0]')
        const name = get(field, 'name')
        return {
          name,
          ...geometryInfo
        }
      })
      // eslint-disable-next-line no-case-declarations
      const allGeometries = unionBy(parsedGeometries, state, 'id')
      return allGeometries
    default:
      return state
  }
}

export const fields = (state = [], action) => {
  switch (action.type) {
    case REQUEST_FIELDS:
      return state
    case RECEIVE_FIELDS: {
      const allFields = unionBy(action.fields, state, 'id')
      return orderBy(allFields, ['name'], ['asc'])
    }
    case DELETE_FIELD_FROM_LIST: {
      return filter(state, field => field.id !== action.fieldId)
    }
    default:
      return state
  }
}

export const selectedSnapshotIdx = (state = null, action) => {
  switch (action.type) {
    case SELECT_FIELD:
      // eslint-disable-next-line no-prototype-builtins
      return action.payload.field.hasOwnProperty('snapshots')
        ? action.payload.field.snapshots.filter(snapshot => snapshot.deliverables.length).length - 1
        : null
    case SELECT_SNAPSHOT:
      return action.payload.snapshotId
    case OPEN_SEARCH_MODE:
      return null
    default:
      return state
  }
}

export const temporalSwipe = (state = window.innerWidth / 2, action) => {
  switch (action.type) {
    case MOVE_TEMPORAL_SLIDER:
      return action.payload
    default:
      return state
  }
}

export const fieldStats = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_FIELDS_COUNT:
      // eslint-disable-next-line no-case-declarations
      const { fieldId } = action
      return {
        ...state,
        fieldIds: [...state.fieldIds, fieldId],
        fieldCount: state.fieldCount + 1
      }
    case RECEIVE_FIELDS_COUNT:
      /* eslint-disable no-case-declarations */
      const { fieldIds } = action
      const chunkSize = CHUNK_SIZE
      const chunks = {}
      let totalChunks = 0
      /* eslint-enable no-case-declarations */
      for (let i = 0; i < fieldIds.length; i += chunkSize) {
        const chunk = fieldIds.slice(i, i + chunkSize)
        totalChunks = totalChunks + 1
        chunks[totalChunks] = chunk
      }
      return { fieldCount: fieldIds.length, chunks, currentChunk: 1, totalChunks, fieldIds: map(fieldIds, 'id') }
    case UPDATE_CURRENT_CHUNK:
      // eslint-disable-next-line no-case-declarations
      const { currentChunk } = action

      return { ...state, currentChunk }
    default:
      return state
  }
}

export const fieldTypes = (state = [], action) => {
  switch (action.type) {
    case REQUEST_FIELD_TYPES:
      return state
    case RECEIVE_FIELD_TYPES:
      return action.types
    default:
      return state
  }
}

export const selectedFields = (state = [], action) => {
  switch (action.type) {
    case SELECT_FIELDS:
      return action.payload
    case REMOVE_FIELD_TO_GROUP:
      return state.filter(element => toString(element) !== toString(action.fieldId))
    case ADD_FIELDS_TO_GROUP: {
      const { fieldsIds } = action
      const numericFieldsIds = map(fieldsIds, toNumber)
      return union(state, numericFieldsIds)
    }
    case TOGGLE_SELECTION: {
      const { fieldIds, selectFilteredFields } = action
      const numericFieldsIds = map(fieldIds, toNumber)
      if (!selectFilteredFields) {
        return state
      }
      return numericFieldsIds
    }
    case FLUSH_FIELDS_GROUP:
      return []
    case DESELECT_FIELDS:
      return state.map(field => ({
        ...field,
        snapshots: []
      }))
    default:
      return state
  }
}

export const selectedPreviewField = (state = null, action) => {
  switch (action.type) {
    case SET_PREVIEW_FIELD_ID:
      return action.fieldId
    case RESET_PREVIEW_FIELD_ID:
      return null
    default:
      return state
  }
}

/** use to modify a specific deliverable in the selected deliverables **/
export const deliverableChanger = (flightGroups, deliverableType, changerFunction) =>
  deliverablesChanger(flightGroups, deliverables => {
    return deliverables.map(deliverable => {
      if (deliverable.type.id === deliverableType) {
        return changerFunction(deliverable)
      }
      return deliverable
    })
  })

export const pdfDeliverableChanger = (flightGroups, pdfDeliverableType, changerFunction) =>
  deliverablesChanger(
    flightGroups,

    pdfDeliverables =>
      pdfDeliverables.map(pdfDeliverable => {
        if (pdfDeliverable.type.id === pdfDeliverableType) {
          return changerFunction(pdfDeliverable)
        }
        return pdfDeliverable
      }),
    'pdfDeliverables'
  )
/** use to modify the selected deliverables **/

export const deliverablesChanger = (flightGroups, changerFunction, deliverableFormat = 'deliverables') => {
  const selectedGroupSource = flightGroups.find(group => group.selected).flights[0].source
  return flightGroups.map(flightGroup => {
    // update same source groups
    if (!flightGroup.selected && toString(flightGroup.flights[0].source) !== toString(selectedGroupSource)) return flightGroup
    return {
      ...flightGroup,
      [deliverableFormat]: changerFunction(flightGroup[deliverableFormat])
    }
  })
}

/** use to modify the selected deliverables **/

export const flightGroups = (state = [], action) => {
  switch (action.type) {
    case ADD_FLIGHT_GROUPS:
      /* eslint-disable no-case-declarations */
      const { payload } = action
      const indexOffset = payload.length - state.length
      const previousSelectedFlightIndex = findIndex(state, 'selected')
      const selectedFlightGroup = get(state, previousSelectedFlightIndex)
      // activate last snapshot by default on first load
      const currentSelectedIndex = previousSelectedFlightIndex !== -1 ? previousSelectedFlightIndex + indexOffset : payload.length - 1
      /* eslint-enable no-case-declarations */
      return map(payload, (value, index) => {
        if (index === currentSelectedIndex) {
          return selectedFlightGroup || { ...value, selected: true }
        }
        return value
      })
    case OPEN_SEARCH_MODE:
    case DESELECT_FIELDS:
    case FLUSH_FIELDS_GROUP:
      return []
    case SELECT_FLIGHTGROUP:
      return state.map((flightGroup, idx) => ({
        ...flightGroup,
        selected: idx === action.payload.snapshotId,
        activeLayers: true
      }))
    case ACTIVE_LAYERS:
      return state.map(flightGroup => ({
        ...flightGroup,
        activeLayers: flightGroup.selected ? action.payload : flightGroup.activeLayers
      }))
    case CLEAR_VISIBILITIES_GROUP:
      return deliverablesChanger([...state], deliverables => deliverables.map(deliverable => ({ ...deliverable, visible: false })))
    case TOGGLE_VISIBILITY_DELIVERABLE_GROUP:
      return deliverableChanger([...state], action.payload.type.id, deliverable => ({
        ...deliverable,
        visible: !deliverable.visible
      }))
    case TOGGLE_DELIVERABLE_GROUP:
      return deliverableChanger([...state], action.payload.type.id, deliverable => ({
        ...deliverable,
        opened: !deliverable.opened
      }))
    case CHANGE_OPACITY_GROUP:
      return deliverableChanger([...state], action.payload.deliverable.type.id, deliverable => ({
        ...deliverable,
        opacity: action.payload.opacity
      }))
    case SET_LAYER_ORDER_GROUP:
      return deliverablesChanger([...state], deliverables => arrayMove([...deliverables], action.payload.oldIndex, action.payload.newIndex))
    default:
      return state
  }
}

const selectedFieldInitialState = {
  hasDroneSnapshots: function () {
    if (this.snapshots) {
      // eslint-disable-next-line prefer-const
      let availableSatSnaps = this.snapshots.filter(snapshot => snapshot.id !== null).length
      return availableSatSnaps > 0
    }
  },
  hasPictures: function () {
    if (this.images) {
      // eslint-disable-next-line prefer-const
      let availableImages = this.images.filter(image => image.id !== null).length
      return availableImages > 0
    }
  },
  hasPlants: function () {
    if (this.plants) {
      // eslint-disable-next-line prefer-const
      let availablePlants = this.plants.filter(plant => plant.id !== null).length
      return availablePlants > 0
    }
    return false
  }
}

export const selectedField = (state = {}, action) => {
  switch (action.type) {
    case SELECT_FIELD:
      return {
        ...state,
        ...selectedFieldInitialState,
        ...action.payload.field
      }
    case OPEN_SEARCH_MODE:
      return {}

    default:
      return state
  }
}

export const selectedFieldImage = (state = {}, action) => {
  switch (action.type) {
    case SELECT_FIELD_IMAGE:
      return { ...state, ...action.payload }
    case DESELECT_FIELD_IMAGE:
      return {}

    default:
      return state
  }
}

export const irrigationTypes = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_IRRIGATION_TYPES:
      return action.payload
    default:
      return state
  }
}
