import './assets/css/app.css'
import './assets/css/70custom.css'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import rootReducer from './reducers'
import { isDevelopmentEnvironment } from './constants'
import { initializeViewerRoutes } from './Routing'
import updateStore from '@layers-frontend/commons/store/store'

import { init, browserTracingIntegration } from '@sentry/browser'
import { captureConsoleIntegration } from '@sentry/integrations'

import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './azureAuthConfig'

import App from './components/App'
import { accountsApi } from './features/api/accountsApi'
import { accountsConfigApi } from './features/api/accountsConfigApi'
import { usersApi } from './features/api/authApiSlice'

const msalInstance = new PublicClientApplication(msalConfig)

// eslint-disable-next-line prefer-const
let middleware = []

if (!isDevelopmentEnvironment) {
  console.log('prod')
}

init({
  dsn: process.env.REACT_APP_SENTRY_DSN_WEB,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [
    captureConsoleIntegration({
      levels: ['error']
    }),
    browserTracingIntegration()
  ],
  tracesSampleRate: 1.0
})

// init store
export const store = await updateStore({
  rootReducer,
  rootMiddleware: middleware,
  apiMiddlewares: [accountsApi.middleware, accountsConfigApi.middleware, usersApi.middleware]
})

const container = document.getElementById('root')
const root = createRoot(container)

const renderAppComponent = Component =>
  root.render(
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <Component />
      </MsalProvider>
    </Provider>
  )

initializeViewerRoutes().then(() => {
  renderAppComponent(App)
})

if (isDevelopmentEnvironment && module.hot) {
  module.hot.accept('./components/App', () => {
    const NextApp = require('./components/App').default
    renderAppComponent(NextApp)
  })
}
