import React, { useMemo } from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import map from 'lodash/map'
import round from 'lodash/round'
import sortBy from 'lodash/sortBy'
import unionBy from 'lodash/unionBy'

import { BoxContainer, ChartTitle, Column } from '../Layout'
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, ReferenceLine } from 'recharts'
import createSeasonEvolutionTooltip from './createSeasonEvolutionTooltip'
import { findLastTimeCoordinateInPast } from '../../../dashboardUtils'

import { colorArray } from '../colorArray'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import moment from 'moment'

const IdealMaxChart = ({ t, title, data, chartsWidth, yLabel }) => {
  const { idealMax, normalized } = data

  const dateFormatter = date => moment(date).format('DD/MM/YYYY')

  const selectedFieldsIds = Object.keys(normalized)

  const datesArray = useMemo(() => {
    let dates = []
    selectedFieldsIds.forEach(fieldId => {
      if (isEmpty(normalized)) return
      if (isEmpty(normalized[fieldId])) return

      const fieldDates = map(keys(normalized[fieldId].byDate), date => ({
        date: Number(date)
      }))
      dates = sortBy(unionBy(dates, fieldDates, 'date'), ['date'])
    })
    return dates
  }, [normalized, selectedFieldsIds])

  const getFieldCurve = (fieldId, dataPeriod) => {
    if (!data || data.length < 1) return

    const dataByDate = get(data, `normalized[${fieldId}].byDate`)
    const lastPastDate = findLastTimeCoordinateInPast(dataByDate)

    return ({ date }) => {
      const currentData = get(data, `normalized[${fieldId}][byDate][${date}]`)

      if (!currentData || currentData < 0) return
      if (dataPeriod === 'past' && currentData.date > lastPastDate) return
      if (dataPeriod === 'future' && currentData.date < lastPastDate) return

      return currentData.avg
    }
  }

  const getTooltipData = payload => {
    return map(payload, item => {
      const currentField = normalized[item.name].byDate
      const firstKeyObject = Object.values(currentField)[0]
      const fieldName = firstKeyObject.field_name
      const label = !isEmpty(fieldName) ? fieldName : firstKeyObject.customer_name
      const value = get(item, 'value').toFixed('2')
      const color = get(item, 'color')
      return { label, value, color }
    })
  }

  const getYDomain = () => {
    const min = 0
    const max = round(idealMax + idealMax * 0.1)
    return [min, max]
  }

  return (
    !isEmpty(datesArray) && (
      <Column marginBottom="30px" width={chartsWidth} transition="0.3s ease-in-out">
        <BoxContainer>
          <ChartTitle>{t(title)}</ChartTitle>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart fill={themeColors.lightBlue} height={4500} data={datesArray} margin={{ top: 20, bottom: 20, left: 0, right: 20 }}>
              <XAxis
                stroke="#ffffff"
                dataKey={'date'}
                label={{ value: t('date'), position: 'insideBottom', offset: -15 }}
                scale="time"
                type="number"
                domain={[datesArray[0].date, datesArray[datesArray.length - 1].date]}
                tickFormatter={dateFormatter}
              />
              <YAxis
                stroke="#ffffff"
                type="number"
                interval={0}
                label={{ value: yLabel, angle: -90, position: 'insideLeft', offset: 10 }}
                domain={getYDomain()}
                tickFormatter={value => round(value)}
              />
              <CartesianGrid strokeDasharray="3 3" stroke={themeColors.lightBlue} fill="rgba(255,255,255,0.08)" />

              {createSeasonEvolutionTooltip({
                getTitle: payload => {
                  return dateFormatter(get(payload[0], 'payload.date'))
                },
                getData: getTooltipData
              })}

              {idealMax && (
                <ReferenceLine infront y={idealMax} stroke={themeColors.lightBlue} strokeWidth={3} label={{ value: idealMax.toFixed(2), position: 'top' }} />
              )}

              {map(selectedFieldsIds, (fieldId, index) => (
                <Line
                  key={`${fieldId}Future`}
                  name={fieldId}
                  dataKey={getFieldCurve(fieldId, 'future')}
                  type="basis"
                  connectNulls
                  stroke={colorArray[index]}
                  strokeDasharray="5"
                  strokeWidth={2}
                  isAnimationActive={false}
                  dot={false}
                />
              ))}

              {map(selectedFieldsIds, (fieldId, index) => (
                <Line
                  key={`${fieldId}Past`}
                  name={fieldId}
                  dataKey={getFieldCurve(fieldId, 'past')}
                  type="basis"
                  connectNulls
                  stroke={colorArray[index]}
                  strokeWidth={2}
                  isAnimationActive={false}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
        </BoxContainer>
      </Column>
    )
  )
}

export default IdealMaxChart
