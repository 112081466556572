import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { resetNotification } from '../../actions/notifications'

import { fetchFields } from '../../actions/fields'

import Notifier from '../../components/UIComponents/Notifier'

import { getNotifications } from '../../selectors/notifications'

const mapStateToProps = state => ({
  notification: getNotifications(state)
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      handleNotificationExpire: resetNotification,
      handleRefreshClick: fetchFields
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifier)
