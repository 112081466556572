import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import isEqual from 'lodash/fp/isEqual'

export const getFieldSeasonForm = get('fieldSeasonForm')

export const getActiveSeasonIndex = pipe(getFieldSeasonForm, get('activeSeasonIndex'))

export const getFieldSeasonFormSeasons = pipe(getFieldSeasonForm, get('seasons'))

export const isFieldSeasonFormEditorOpen = pipe(getFieldSeasonForm, get('isEditorOpen'))

export const getEditingGeometry = pipe(getFieldSeasonForm, get('editingGeometry'))

export const isNewField = pipe(getFieldSeasonForm, get('mode'), isEqual('new'))

export const isReadOnly = pipe(getFieldSeasonForm, get('mode'), isEqual('readOnly'))

export const getFieldSeasonActiveSeason = state => {
  const index = getActiveSeasonIndex(state)
  const seasons = getFieldSeasonFormSeasons(state)

  return get(index)(seasons)
}
