// for debug or style edit
// 1 - pass the mocked payload to  custom tooltip (payload = mockePayload)
// 2 - comment if (active && payload && payload.length)
// 3 - return a tooltip like
// <Tooltip filterNull content={<CustomTooltip />} wrapperStyle={{ visibility: 'visible', width: 'auto%' }}
// position={{ x: 0, y: 210 }} />

import React from 'react'
import { Tooltip } from 'recharts'
import { TooltipWrapper, TooltopHeading } from '../Charts'

import themeColors from '@layers-frontend/commons/styles/themeColors'

import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import styled from 'styled-components'
import RenderIf from '@layers-frontend/commons/helpers/RenderIf'

const TooltipListColor = styled.div`
  background: ${props => props.background || 'initial'};
  height: 1rem;
  width: 1rem;
`
const TooltipListItem = styled.div`
  display: flex;
  align-items: baseline;
  & > div:not(:last-child) {
    margin-right: 1rem;
  }
`

const TooltipLabel = styled.div`
  font-weight: 400;
  margin-bottom: 5px;
  color: ${props => props.color || 'initial'};
`

const TooltipValue = styled.div`
  color: ${themeColors.darkGrayColor};
  margin-left: auto;
`

const renderTooltipItem = (item, index) => {
  return item ? (
    <TooltipListItem key={index}>
      <TooltipListColor background={item.color} />
      <RenderIf condition={item.label}>
        <TooltipLabel>{item.label}: </TooltipLabel>
      </RenderIf>
      <TooltipValue>{item.value}</TooltipValue>
    </TooltipListItem>
  ) : null
}

function createSeasonEvolutionTooltip({ getTitle, getData }) {
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const title = getTitle(payload)
      const tooltipData = getData(payload)

      if (isEmpty(tooltipData)) return null

      return (
        <div>
          {title ? <TooltopHeading>{title}</TooltopHeading> : null}
          <TooltipWrapper>{map(tooltipData, renderTooltipItem)}</TooltipWrapper>
        </div>
      )
    }

    return null
  }

  return <Tooltip filterNull content={<CustomTooltip />} wrapperStyle={{ zIndex: 1 }} isAnimationActive={false} />
}

export default createSeasonEvolutionTooltip
