import { connect } from 'react-redux'
import { closeNewSampleForm, openNewSampleForm } from '../../../actions/ui'
import SampleFormWrapper from '../../../components/FieldsComponents/SampleForm/SampleFormWrapper'
import { isSampleFormOpen } from '../../../selectors/ui'

const mapStateToProps = state => ({
  isSampleFormOpen: isSampleFormOpen(state)
})

const mapDispatchToProps = {
  openNewSampleForm,
  closeNewSampleForm
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleFormWrapper)
