import SamplesList from '../../components/FieldsComponents/SamplesList'
import { connect } from 'react-redux'
import { getActiveSamples } from '../../selectors/samples'
import { selectSampleById } from '../../actions/samples'
import { isSampleFormOpen } from '../../selectors/ui'

const mapStateToProps = state => ({
  samples: getActiveSamples(state),
  isSampleFormOpen: isSampleFormOpen(state)
})

const mapDispatchToProps = {
  selectSampleById
}

export default connect(mapStateToProps, mapDispatchToProps)(SamplesList)
