import {
  SET_SAMPLE_FORM_FIELD,
  DENY_SAMPLE_LOCATION,
  RESET_SAMPLE_FORM_FIELDS,
  EDIT_SAMPLE,
  REMOVE_SAMPLE_FORM_EXTRA_PARAMETER,
  SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID,
  FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS
} from '../storeConstants'
import { sampleFormFields, PENDING_REASONS } from '../../constants'
import omit from 'lodash/omit'
import reject from 'lodash/reject'
import reduce from 'lodash/reduce'
import isFinite from 'lodash/isFinite'
import toNumber from 'lodash/toNumber'

const {
  NOTES,
  LOCATION,
  TYPE,
  BASE_LAYER,
  SNAPSHOT_DATE,
  SAMPLE_CREATION_DATE,
  EXTRA_PARAMETERS,
  PICTURE_TEMP_PATH,
  PICTURE_LOCAL_PATH,
  PICTURE_PATH
} = sampleFormFields

export const initialState = {
  [NOTES]: null,
  [TYPE]: null,
  [LOCATION]: null,
  [BASE_LAYER]: null,
  [SNAPSHOT_DATE]: null,
  [SAMPLE_CREATION_DATE]: null,
  [EXTRA_PARAMETERS]: {},
  extraParametersOrder: [],
  pendingReason: null,
  originalSample: {},
  [PICTURE_TEMP_PATH]: undefined,
  [PICTURE_LOCAL_PATH]: undefined,
  [PICTURE_PATH]: undefined
}

const errorStates = {
  [NOTES]: undefined,
  [TYPE]: undefined,
  [LOCATION]: undefined,
  [BASE_LAYER]: undefined,
  [SNAPSHOT_DATE]: undefined,
  [SAMPLE_CREATION_DATE]: undefined
}

const extraParametersOrderReducer = (acc, _, parameterId) => (isFinite(toNumber(parameterId)) ? [...acc, `${parameterId}`] : acc)

export default function samplesFormCommon(state = initialState, action = {}) {
  switch (action.type) {
    case SET_SAMPLE_FORM_FIELD:
      const { field, value } = action
      return {
        ...state,
        [field]: value
      }
    case EDIT_SAMPLE:
      const {
        id,
        lat,
        lon,
        notes,
        type,
        snapshotDate,
        baseLayer,
        pendingReason,
        uniqueId,
        pictureTempPath,
        pictureLocalPath,
        picturePath,
        sampleCreationDate,
        metadata
      } = action.sample
      const coords = [lon, lat]

      const extraParametersOrder = reduce(metadata, extraParametersOrderReducer, [])

      return {
        ...state,
        id,
        [NOTES]: notes,
        [TYPE]: type,
        [LOCATION]: coords,
        [BASE_LAYER]: baseLayer,
        [SNAPSHOT_DATE]: snapshotDate,
        [SAMPLE_CREATION_DATE]: sampleCreationDate,
        [EXTRA_PARAMETERS]: metadata,
        extraParametersOrder,
        uniqueId,
        pendingReason: pendingReason || PENDING_REASONS.PATCH,
        originalSample: action.sample,
        pictureTempPath,
        pictureLocalPath,
        picturePath
      }

    case REMOVE_SAMPLE_FORM_EXTRA_PARAMETER: {
      return {
        ...state,
        [EXTRA_PARAMETERS]: omit(state[EXTRA_PARAMETERS], `${action.parameterId}`),
        extraParametersOrder: reject(state.extraParametersOrder, id => `${id}` === `${action.parameterId}`)
      }
    }

    case SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID: {
      const { parameterId, value } = action
      return {
        ...state,
        [EXTRA_PARAMETERS]: {
          ...state[EXTRA_PARAMETERS],
          [parameterId]: value
        }
      }
    }

    case FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS: {
      return {
        ...state,
        [EXTRA_PARAMETERS]: initialState[EXTRA_PARAMETERS],
        extraParametersOrder: initialState.extraParametersOrder
      }
    }

    case DENY_SAMPLE_LOCATION:
      return {
        ...state,
        [LOCATION]: action.lon && action.lat ? [action.lon, action.lat] : errorStates[location]
      }

    case RESET_SAMPLE_FORM_FIELDS:
      return initialState

    default:
      return state
  }
}
