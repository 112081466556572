import React from 'react'
import SampleInfoContainer from '../../containers/FieldsContainers/SampleInfoContainer'
import SamplesListContainer from '../../containers/FieldsContainers/SamplesListContainer'

export default function SamplesTab({ selectedSample }) {
  if (selectedSample) {
    return <SampleInfoContainer />
  }

  return <SamplesListContainer />
}
