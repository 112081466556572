import {
  getSelectedFlightGroupDates,
  getSelectedFlightGroupSource,
  getTemporalComparisonFlightGroupDates,
  getTemporalComparisonFlightGroupSource
} from './flightGroups'

import get from 'lodash/fp/get'
import reduce from 'lodash/fp/reduce'
import { pipe } from 'lodash/fp'
import keys from 'lodash/keys'
import includes from 'lodash/includes'
import map from 'lodash/fp/map'
import { DRONE_RGB_SNAPSHOT_SOURCE, DRONE_SNAPSHOT_SOURCE } from '@layers-frontend/commons/constants'
import toNumber from 'lodash/fp/toNumber'

const convertReduce = reduce.convert({ cap: false })

export const getSnapshots = get('snapshots')

const getSnapshotsByDate = date => state => pipe(getSnapshots, get(date))(state)

const getSnapshotsByDateAndSource = (date, source) => state => pipe(getSnapshotsByDate(date), get(source))(state)

export const getSelectedFlightGroupSnapshots = state => {
  const flightGroupDates = getSelectedFlightGroupDates(state)
  const flightSource = getSelectedFlightGroupSource(state)
  return reduce((accumulator, flightDate) => {
    const snapshots = getSnapshotsByDateAndSource(flightDate, flightSource)(state)
    return [...accumulator, snapshots]
  }, [])(flightGroupDates)
}

export const getTemporalComparisonFlightGroupSnapshots = state => {
  const flightGroupDates = getTemporalComparisonFlightGroupDates(state)
  const flightSource = getTemporalComparisonFlightGroupSource(state)
  return reduce((accumulator, flightDate) => {
    const snapshots = getSnapshotsByDateAndSource(flightDate, flightSource)(state)
    return [...accumulator, snapshots]
  }, [])(flightGroupDates)
}

export const getDroneFlightsSnapshotsDates = state => {
  const snapshotsByDates = getSnapshots(state)
  return convertReduce((accumulator, snapshots, date) => {
    const sources = map(toNumber)(keys(snapshots))
    const isDrone = includes(sources, DRONE_RGB_SNAPSHOT_SOURCE) || includes(sources, DRONE_SNAPSHOT_SOURCE)

    if (isDrone) return [...accumulator, date]

    return accumulator
  }, [])(snapshotsByDates)
}
