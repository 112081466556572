import React from 'react'

import PropTypes from 'prop-types'

import OLComponent from '../ol-component'
// Openlayers 5 molules
import OlCircle from 'ol/geom/Circle'
import OlMap from 'ol/Map'
import OlFeature from 'ol/Feature'
import OlGeometry from 'ol/geom/Geometry'

export default class Circle extends OLComponent {
  constructor(props) {
    super(props)
    this.geometry = new OlCircle(props.center, props.radius)
    this.updateFromProps(props)
  }

  updateFromProps(props) {
    this.geometry.setRadius(this.props.radius)
    this.geometry.setCenter(this.props.center)
  }

  componentDidMount() {
    this.context.feature.setGeometry(this.geometry)
  }

  render() {
    return <div>{this.props.notification}</div>
  }

  getChildContext() {
    return {
      geometry: this.geometry,
      map: this.context.map
    }
  }
}

Circle.propTypes = {
  radius: PropTypes.number.isRequired,
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  notification: PropTypes.object,
  projection: PropTypes.object
}

Circle.contextTypes = {
  feature: PropTypes.instanceOf(OlFeature),
  map: PropTypes.instanceOf(OlMap)
}

Circle.childContextTypes = {
  geometry: PropTypes.instanceOf(OlGeometry),
  map: PropTypes.instanceOf(OlMap)
}
