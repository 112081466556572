import React from 'react'
import styled from 'styled-components'
import SelectedFieldsCalendarLegendItem from './SelectedFieldsCalendarLegendItem'
import map from 'lodash/map'
import { CALENDAR_LEGENDS, DRONE_SNAPSHOT_SOURCE } from '@layers-frontend/commons/constants'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import set from 'lodash/set'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 30px 0 40px;
  justify-content: space-between;
`

function SelectedFieldsCalendarLegend({ hasDroneSnapshots, droneSnapshotsMode, handleSetDroneSnapshotsMode }) {
  set(CALENDAR_LEGENDS, [DRONE_SNAPSHOT_SOURCE, 'isLegend'], hasDroneSnapshots)

  return (
    <Wrapper>
      {map(CALENDAR_LEGENDS, (calendarLegend, key) => {
        const isDrone = isEqual(key, String(DRONE_SNAPSHOT_SOURCE))

        const handleClick = () => (isDrone ? handleSetDroneSnapshotsMode(!droneSnapshotsMode) : noop)

        return calendarLegend.isLegend ? (
          <SelectedFieldsCalendarLegendItem
            name={calendarLegend.name}
            dotColor={calendarLegend.color}
            key={calendarLegend.name}
            handleClick={handleClick}
            isDrone={isDrone}
            droneSnapshotsMode={droneSnapshotsMode}
          />
        ) : null
      })}
    </Wrapper>
  )
}

export default SelectedFieldsCalendarLegend
