import { RECEIVE_FIELDS_SEASONS_TIMELINE } from '../actions/fieldsSeasonsTimeline'
import { RECEIVE_FIELDS } from '../actions/fields'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'

const initialState = {}
const parseFields = (fields, state) =>
  reduce(
    fields,
    (accumulator, field) => {
      const fieldId = get(field, 'id')
      const seasons = get(field, 'seasons')
      const remappedSeasons = map(seasons, season => ({
        ...season,
        label: get(season, ['label', 'value'])
      }))

      return {
        ...accumulator,
        [fieldId]: remappedSeasons
      }
    },
    state
  )
export default function FieldsSeasonsTimeline(state = initialState, action = {}) {
  const fields = parseFields(action.fields, state)
  switch (action.type) {
    case RECEIVE_FIELDS:
      return fields

    case RECEIVE_FIELDS_SEASONS_TIMELINE:
      return reduce(
        action.fields,
        (accumulator, field) => {
          const fieldId = get(field, 'id')
          const seasons = get(field, 'seasons')
          const remappedSeasons = map(seasons, season => ({
            ...season,
            label: get(season, ['label', 'value'])
          }))

          return {
            ...accumulator,
            [fieldId]: remappedSeasons
          }
        },
        {}
      )
    default:
      return state
  }
}
