import { create, all } from 'mathjs'

const math = create(all)

math.createUnit('feddan', { definition: '1.038 acres', aliases: ['Feddan', 'fed'] })
math.createUnit('hectare', { definition: '2.47105 acres', aliases: ['hectare', 'Hectare', 'Ha', 'ha'] }, { override: true })

const { evaluate } = math

const asFarenheit = degree => evaluate(`${degree} degC to degF`).toNumber()

const convert = (units, from, to) => evaluate(`${units} ${from} to ${to}`).toNumber()

const convertAsString = (units, from, to) => `${convert(units, from, to).toFixed(2)} ${to}`

const convertHectares = (hectares, toUnit) => {
  let result

  switch (toUnit.toLowerCase()) {
    case 'acre':
      result = hectares * 2.47105
      break
    case 'feddan':
      result = hectares * 2.381
      break
    default:
      return hectares
  }

  return result
}

export { evaluate, asFarenheit, convertAsString, convert, convertHectares }
