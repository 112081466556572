import map from 'lodash/fp/map'
import { getSamplesFormExtraParameters, getSamplesFormType } from '@layers-frontend/commons/store/selectors/samplesForm'
import { getSampleParametersByTypeId } from '@layers-frontend/commons/store/selectors/sampleParametersByType'
import find from 'lodash/fp/find'
import get from 'lodash/fp/get'
import toNumber from 'lodash/toNumber'

export const getSampleFormExtraParametersByTypeId = state => {
  const sampleTypeId = getSamplesFormType(state)
  return getSampleParametersByTypeId(sampleTypeId)(state)
}

export const getSampleFormExtraParameterById = state => id => {
  const extraParameters = getSampleFormExtraParametersByTypeId(state)
  return find(param => toNumber(param.parameterId) === toNumber(id))(extraParameters)
}

export const getSampleFormExtraParameterNameById = state => id => {
  const param = getSampleFormExtraParameterById(state)(id)
  return get('name')(param)
}

export const getSampleFormExtraParameterNameWithUnitsById = state => id => {
  const param = getSampleFormExtraParameterById(state)(id)
  return { name: get('name')(param), units: get('measureUnit.shortName')(param) }
}

export const formattedOptionsOfSampleExtraParameters = state => {
  const parameters = getSampleFormExtraParametersByTypeId(state)
  return map(param => ({ id: param.parameterId, name: param.name, units: get('measureUnit.shortName')(param) }))(parameters)
}

export const formattedOptionsOfSelectedSampleExtraParameters = state => {
  const parameters = getSamplesFormExtraParameters(state)
  return map((_, id) => ({ id, ...getSampleFormExtraParameterNameWithUnitsById(state)(id) }))(parameters)
}
