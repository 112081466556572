import { connect } from 'react-redux'

import { getUserName, getBiLabsUrl, getUserConfig } from '@layers-frontend/commons/store/selectors/user'
import { patchConfig } from '../../actions/users'

import UserMenu from '../../components/UserComponents/UserMenu'

const mapStateToProps = state => ({
  userName: getUserName(state),
  biLabsUrl: getBiLabsUrl(state),
  config: getUserConfig(state)
})

const mapDispatchToProps = {
  patchConfig
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu)
