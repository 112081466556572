import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
import map from 'lodash/map'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend } from 'recharts'
import moment from 'moment'

import Loader from '../Loader'
import { REQUEST_FETCH_METEOROLOGICAL_API_DATA } from '../../actions/meteorologicalData'
import { UIStyles } from '../../theme'
import MeteorologicalStatisticsFiltersContainer from '../../containers/DashboardContainers/DashboadFiltersContainers/MeteorologicalStatisticsFiltersContainer'
import { BoxContainer, FullSizeContainer, Row, FullWidthColumn, ChartTitle, TabTitle } from '../DashboardComponents/Layout'
import createSeasonEvolutionTooltip from './SeasonEvolution/createSeasonEvolutionTooltip'
import { asFarenheit } from '@layers-frontend/commons/conversions'

const xAxisTickFormatter = date => moment(date, 'YYYY-MM-DD').format('D MMM YY')

const FullWidthTopColumn = styled(FullWidthColumn)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -30px;
  align-items: center;
`

const MeteorologicalFullSizeContainer = styled(FullSizeContainer)`
  min-height: calc(100vh - 60px);
  position: relative;
  flex: 1;
`

const StyledTabTitle = styled(TabTitle)`
  max-width: 400px;
  margin-top: 30px;
`

const StyledChartTitle = styled(ChartTitle)`
  margin-top: 30px;
`

const Capitalize = styled.span`
  text-transform: capitalize;
`

export default function MeteorologicalStatistics({ t, selectedFieldMeteorologicalData, isSelectedFieldsEmpty, baseTemperature, selectedField, isMetric }) {
  const [toggled, setToggled] = useState(false) // the state for the Apply btn

  const baseTemperatureValue = useMemo(() => baseTemperature || 0, [baseTemperature])

  const accumulatedTemperatureData = useMemo(() => {
    if (!selectedFieldMeteorologicalData) return null

    return reduce(
      selectedFieldMeteorologicalData,
      (acc, currentData, index) => {
        const previousTemp = get(acc, `[${index - 1}].temp`)
        const currentTemperatureAverage = parseFloat(currentData.temp) - baseTemperatureValue
        const isNegative = currentTemperatureAverage < 0
        const currentTemperature = isNegative ? 0 : currentTemperatureAverage

        return [
          ...acc,
          {
            temp: previousTemp ? previousTemp + currentTemperature : currentTemperature,
            date: currentData.date
          }
        ]
      },
      []
    )
  }, [selectedFieldMeteorologicalData, baseTemperatureValue])

  const getTooltipData = payload => {
    return map(payload, item => {
      const label = null
      const value = isMetric ? `${Math.round(get(item, 'value'))} ºC` : `${asFarenheit(Math.round(get(item, 'value')).toFixed(2))} ºF`
      const color = get(item, 'color')
      return { label, value, color }
    })
  }
  return (
    <MeteorologicalFullSizeContainer>
      <Loader requests={REQUEST_FETCH_METEOROLOGICAL_API_DATA} />
      <Row>
        <FullWidthTopColumn>
          <StyledTabTitle>
            {t('Thermal Accumulation')} ({isMetric ? 'ºC' : 'ºF'})
          </StyledTabTitle>
          {isSelectedFieldsEmpty && <StyledChartTitle>{t('Choose at least one field from the main search list')}</StyledChartTitle>}
          {!isSelectedFieldsEmpty && <MeteorologicalStatisticsFiltersContainer setToggled={setToggled} />}
        </FullWidthTopColumn>
      </Row>
      <Row marginBottom="50px">
        {!selectedFieldMeteorologicalData && !isSelectedFieldsEmpty && selectedField && toggled && (
          <ChartTitle>{t('no data with current selection')}</ChartTitle>
        )}
        {selectedFieldMeteorologicalData && accumulatedTemperatureData && !isSelectedFieldsEmpty && (
          <FullWidthColumn>
            <BoxContainer>
              <ChartTitle>
                <Capitalize>{t('field')}</Capitalize>: &nbsp; {get(selectedField, 'name', '')}
              </ChartTitle>
              <ResponsiveContainer width="100%" height={700}>
                <AreaChart fill="#16a3b7" height={700} data={accumulatedTemperatureData} margin={{ top: 20, bottom: 40, left: 20, right: 20 }}>
                  <XAxis stroke="#ffffff" dataKey="date" tickFormatter={xAxisTickFormatter} />
                  <YAxis
                    stroke="#ffffff"
                    type="number"
                    interval={0}
                    tickFormatter={value => (isMetric ? `${value}ºC` : `${asFarenheit(value).toFixed(0)}ºF`)}
                  />
                  <CartesianGrid strokeDasharray="3 3" stroke="#16a3b7" fill="rgba(255,255,255,0.08)" />
                  {createSeasonEvolutionTooltip({
                    getTitle: payload => {
                      return get(payload[0], ['payload', 'date'])
                    },
                    getData: getTooltipData
                  })}
                  <Area type="monotone" connectNulls dataKey="temp" stroke={UIStyles.whiteColor} strokeWidth={2} />
                  <Legend
                    iconType="line"
                    iconSize={26}
                    formatter={(value, entry) => (
                      <span style={{ color: entry.color }}>
                        &nbsp;&nbsp;
                        {value}
                      </span>
                    )}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </BoxContainer>
          </FullWidthColumn>
        )}
      </Row>
    </MeteorologicalFullSizeContainer>
  )
}
