import { ListSubheader } from '@mui/material'
import React from 'react'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import useMediaQuery from '@mui/material/useMediaQuery'

/*   @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  } */

const AccountFilters = ({ t, title }) => {
  const matches = useMediaQuery('(min-width:780px)')
  return (
    <ListSubheader
      className="accounts-list-item"
      sx={{
        bgcolor: '#1d4965',
        paddingLeft: 2,
        paddingRight: 2,
        marginTop: 0,
        color: `${themeColors.whiteColor}`,
        width: '100%'
      }}
      style={{
        gridTemplateColumns: '2fr 1fr 1fr 130px',
        display: matches ? 'grid' : 'flex',
        flexDirection: 'column',
        flexBasis: 'content',
        fontSize: '14px'
      }}
    >
      <span style={{ fontSize: '20px' }}>{title}</span>
      <span style={{ paddingLeft: '5px' }}>Organization</span>
      {/*  <span style={{ paddingLeft: '10px' }}>{capitalize(t('season'))}</span> */}
      {/*  <span style={{ paddingLeft: '10px' }}>Account Type</span> */}
      <span></span>
    </ListSubheader>
  )
}

export default AccountFilters
