import React from 'react'
import styled from 'styled-components'
import StyledFloatInput from '../UIComponents/StyledFloatInput'

const wrapperInputStyle = {
  width: '60px'
}

const StyledCloudIcon = styled.i`
  display: flex;
  align-items: center;
  padding-right: 0.6rem;
  font-size: 18px;
`
const AlignBaseLine = styled.span`
  display: flex;
  align-items: baseline;
`
export default function MaxCloudCoverageInput({ value, onChange }) {
  return (
    <AlignBaseLine>
      <StyledCloudIcon className="fas fa-cloud" />
      <StyledFloatInput inputValue={value} placeholder={'Max %'} onChange={onChange} wrapperStyle={wrapperInputStyle} minValue="0" maxValue="100" />
    </AlignBaseLine>
  )
}
