import { connect } from 'react-redux'
import AdvancedFilter from '../../components/SearchComponents/AdvancedFilter'
import { openFilterType } from '../../actions/search'
import { setModalProps, openPlantationDateFilterModal } from '../../actions/modal'
import { getSelectedFilter, getSearchOptions } from '../../selectors/search'
import { getFilters, getFiltersByType } from '../../selectors/filters'

const mapStateToProps = (state, props) => ({
  isOpen: getSelectedFilter(state) === props.filterType,
  selectedFilterIds: getFiltersByType(props.filterType)(state),
  filters: getFilters(state),
  searchOptions: getSearchOptions(state)
})

const mapDispatchToProps = {
  openFilterType,
  setModalProps,
  openPlantationDateFilterModal
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedFilter)
