import map from 'lodash/map'
import union from 'lodash/union'
import keys from 'lodash/keys'
import get from 'lodash/get'

import { layersViews, dashboardViews, plantsMetadataKeys } from '../constants'

import { RECEIVE_FIELDS_WITH_STATISTICS } from '../actions/statistics'
import { RECEIVE_FIELDS_WITH_PRED_TECH_LOGS } from '../actions/predTech'
import { RECEIVE_FIELDS } from '../actions/fields'
import { RECEIVE_FIELD_PLANTS } from '../actions/plants'
import { SET_METEOROLOGICAL_STATISTICS_AVAILABLE_FIELDS } from '../actions/availableFields'

const { DRY_MATTER, ATR_POL } = plantsMetadataKeys

const {
  AREA_MEASURE_VIEW,
  FIELD_EDIT_VIEW,
  FIELD_NEW_VIEW,
  FIELD_VIEW,
  FIELDS_LIST_VIEW,
  LINE_MEASURE_VIEW,
  LOADING_PAGE_VIEW,
  SEARCH_DELETED_VIEW,
  SEARCH_VIEW,
  TEMPORAL_COMPARE_VIEW
} = layersViews

const { FIELD_FILTER, FIELD_TEMPORAL_EVOLUTION, FLIGHT_FILTER, HARVEST_PLANNING_TOOL, METEOROLOGICAL_STATISTICS, PRED_TECH } = dashboardViews

const availableFieldsInitialState = {
  [AREA_MEASURE_VIEW]: [],
  [FIELD_EDIT_VIEW]: [],
  [FIELD_NEW_VIEW]: [],
  [FIELD_VIEW]: [],
  [FIELDS_LIST_VIEW]: [],
  [LINE_MEASURE_VIEW]: [],
  [LOADING_PAGE_VIEW]: [],
  [SEARCH_DELETED_VIEW]: [],
  [SEARCH_VIEW]: [],
  [TEMPORAL_COMPARE_VIEW]: [],

  [FIELD_FILTER]: [],
  [FIELD_TEMPORAL_EVOLUTION]: [],
  [FLIGHT_FILTER]: [],
  [HARVEST_PLANNING_TOOL]: [],
  [METEOROLOGICAL_STATISTICS]: [],
  [PRED_TECH]: []
}

export default function availableFields(state = availableFieldsInitialState, action = {}) {
  const { type, plants, data } = action
  switch (type) {
    case RECEIVE_FIELDS_WITH_STATISTICS: {
      const radarFieldIds = map(data.radar, field => `${field.id}`)
      const satFieldIds = map(data.sat, field => `${field.id}`)
      const planetFieldIds = map(data.planet, field => `${field.id}`)
      const l9FieldIds = map(data.l9, field => `${field.id}`)
      const fieldIds = union(radarFieldIds, satFieldIds, planetFieldIds, l9FieldIds)

      return {
        ...state,
        [FIELD_FILTER]: union(state[FIELD_FILTER], fieldIds),
        [FIELD_TEMPORAL_EVOLUTION]: union(state[FIELD_TEMPORAL_EVOLUTION], fieldIds)
      }
    }

    case RECEIVE_FIELDS_WITH_PRED_TECH_LOGS: {
      const fieldIds = map(data.fields, item => `${item.field_id}`)

      return {
        ...state,
        [PRED_TECH]: fieldIds
      }
    }

    case RECEIVE_FIELDS:
      // eslint-disable-next-line no-case-declarations
      const { fields } = action
      // eslint-disable-next-line no-case-declarations
      const fieldsIds = union(
        map(fields, field => `${field.id}`),
        state[SEARCH_VIEW]
      )
      // const fieldsWithGeometriesIds = reduce(
      //   payload,
      //   (accumulator, field) => {
      //     if (field.season.geometry) {
      //       return [...accumulator, `${field.id}`]
      //     }

      //     return accumulator
      //   },
      //   []
      // )

      return {
        ...state,
        [AREA_MEASURE_VIEW]: fieldsIds,
        [FIELD_EDIT_VIEW]: fieldsIds,
        [FIELD_NEW_VIEW]: fieldsIds,
        [FIELD_VIEW]: fieldsIds,
        [FIELDS_LIST_VIEW]: fieldsIds,
        [LINE_MEASURE_VIEW]: fieldsIds,
        [LOADING_PAGE_VIEW]: fieldsIds,
        [SEARCH_DELETED_VIEW]: fieldsIds,
        [SEARCH_VIEW]: fieldsIds,
        [TEMPORAL_COMPARE_VIEW]: fieldsIds
      }

    case RECEIVE_FIELD_PLANTS:
      /* eslint-disable no-case-declarations */
      const fieldsWithDryMatter = get(plants, `Production.${DRY_MATTER}`)
      const fieldsWithAtrPol = get(plants, `Production.${ATR_POL}`)

      const fieldIdsWithMoreThenOnePlant = [
        ...map(keys(fieldsWithDryMatter), fieldId => (fieldId.length > 1 ? fieldId : null)),
        ...map(keys(fieldsWithAtrPol), fieldId => (fieldId.length > 1 ? fieldId : null))
      ]
      /* eslint-enable no-case-declarations */

      return {
        ...state,
        [HARVEST_PLANNING_TOOL]: fieldIdsWithMoreThenOnePlant
      }

    case SET_METEOROLOGICAL_STATISTICS_AVAILABLE_FIELDS:
      return {
        ...state,
        [METEOROLOGICAL_STATISTICS]: action.fieldsIds
      }

    default:
      return state
  }
}
