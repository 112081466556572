import React from 'react'
import StyledListButton from '../UIComponents/StyledListButton'
import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

export default function SendFieldsReportButton({ t, openSendNewFieldsReportModal, disabled }) {
  const handleClick = () => {
    openSendNewFieldsReportModal()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_SEND_REPORT, {
      button: 'Send report',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return (
    <StyledListButton
      label={t('Send report')}
      disabled={disabled}
      onClick={handleClick}
      labelDistance={6}
      icon={'fas fa-envelope-open-text'}
      iconAfter={true}
    />
  )
}
