import { connect } from 'react-redux'

export const connectProspectLayers = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      /** projection of the map **/
      // eslint-disable-next-line no-extra-boolean-cast
      layers: !!state.user.prospect_layers ? state.user.prospect_layers : []
    }
  }

  return connect(mapStateToProps, null)(WrappedComponent)
}
