import React from 'react'
import { Navigate } from 'react-router-dom'
import { Dashboard, ContentWrapper } from './UIComponents/Dashboard'
import FieldsComparisonChartsContainer from '../containers/DashboardContainers/FieldsComparisonChartsContainer'
import TemporalEvolutionChartsContainer from '../containers/DashboardContainers/TemporalEvolutionChartsContainer'
import HarvestPlanningToolContainer from '../containers/DashboardContainers/HarvestPlanningToolContainer'
import MeteorologicalStatisticsContainer from '../containers/DashboardContainers/MeteorologicalStatisticsContainer'
import { DRONE_RGB_SNAPSHOT_SOURCE, DRONE_SNAPSHOT_SOURCE } from '@layers-frontend/commons/constants'

import PredTechContainer from '../containers/DashboardContainers/PredTechContainer'

import { TOP_BAR_OPTIONS } from '../actions/dashboard'

import includes from 'lodash/includes'
import { TRACK_EVENTS } from '../constants'
import { trackEvent } from './analytics/analytics'

const validSources = [DRONE_SNAPSHOT_SOURCE, DRONE_RGB_SNAPSHOT_SOURCE]
export default class DashboardApp extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isRedirected: false }
  }

  componentDidMount() {
    if (!this.props.isDashboardReady) {
      this.props.initializeDashboard()
    }

    trackEvent(TRACK_EVENTS.DASHBOARD_TAB_SELECTED, { tab: TOP_BAR_OPTIONS.FIELD_FILTER })
  }

  goToField = deliverableTypeName => data => {
    const { openGoToFieldModal, addFieldsToGroup, selectFields, flushFieldsGroup } = this.props
    const source = data.source
    const selectField = () => {
      const isSatFlight = !includes(validSources, source)

      const fieldIdFromData = data.field_id ? data.field_id : data.field.id
      flushFieldsGroup()
      addFieldsToGroup([fieldIdFromData])
      return fetch(selectFields())
        .then(() => {
          const { toggleFocusSearch, selectFlightGroup, toggleVisibility, flightGroups } = this.props
          const activeFlightGroupIndex = flightGroups
            .map(flightGroup => {
              return flightGroup.flights[0].source
            })
            .lastIndexOf(source)
          selectFlightGroup(activeFlightGroupIndex)
          if (deliverableTypeName !== undefined && !isSatFlight) {
            const deliverable = flightGroups[activeFlightGroupIndex].deliverables.find(deliverable => deliverable.type.base_file_name === deliverableTypeName)
            if (deliverable) toggleVisibility(deliverable)
          }
          this.setState(() => ({ isRedirected: true }))
          // hide serch bar visibility
          toggleFocusSearch()
        })
        .catch(e => {
          console.log(e)
          this.props.errorNotification(this.props.t('An error occured'))
        })
    }

    openGoToFieldModal({ selectField })
  }

  render() {
    if (!this.props.isDashboardReady || !this.props.isAppReady) return null

    if (this.state.isRedirected || this.props.hasRoleDeactivateDashboard) {
      if (!this.props.hasDashboardAvailableFields && !this.state.isRedirected) {
        this.props.errorNotification(this.props.t('no data'))
      }

      return <Navigate to={(this.props.locale !== 'es' ? '/' + this.props.locale : '') + '/layers'} replace />
    }

    const { FIELD_FILTER, FIELD_TEMPORAL_EVOLUTION, HARVEST_PLANNING_TOOL, METEOROLOGICAL_STATISTICS, PRED_TECH } = TOP_BAR_OPTIONS

    const charts = {
      [FIELD_FILTER]: <FieldsComparisonChartsContainer goToField={this.goToField} />,
      [FIELD_TEMPORAL_EVOLUTION]: <TemporalEvolutionChartsContainer />,
      [HARVEST_PLANNING_TOOL]: <HarvestPlanningToolContainer />,
      [METEOROLOGICAL_STATISTICS]: <MeteorologicalStatisticsContainer />,
      [PRED_TECH]: <PredTechContainer />
    }

    return (
      <Dashboard>
        <ContentWrapper>{charts[this.props.dashboardView]}</ContentWrapper>
      </Dashboard>
    )
  }
}
