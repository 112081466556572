import { CLOSE_MODAL, TOGGLE_MODAL_VISIBILITY, OPEN_MODAL_BY_NAME, SET_MODAL_PROP } from '../actions/modal'

const initialModalState = {
  isOpen: false,
  isVisible: true,
  componentName: undefined,
  props: {}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialModalState, action = {}) => {
  switch (action.type) {
    case OPEN_MODAL_BY_NAME:
      return {
        isOpen: true,
        isVisible: true,
        componentName: action.componentName,
        props: action.props
      }

    case CLOSE_MODAL:
      return initialModalState

    case TOGGLE_MODAL_VISIBILITY:
      return {
        ...state,
        isVisible: !state.isVisible
      }
    case SET_MODAL_PROP:
      return {
        ...state,
        props: {
          ...state.props,
          [action.propKey]: action.value
        }
      }

    default:
      return state
  }
}
