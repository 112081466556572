import { connect } from 'react-redux'
import { compose } from 'redux'

import { setSearchOptions } from '../actions/search'
import { getFieldsWithInitialSeason } from '../selectors/fields'
import { DataManager } from '../components/DataManager'
import {
  isUIRequestViewerDataInitializationSuccess,
  isUIRequestDashboardDataInitializationSuccess,
  getShouldQueryFilters,
  getUiFieldsUpdatedAt
} from '../selectors/ui'
import withTranslator from '../components/HOCComponents/withTranslator'
import { setMeteorologicalStatsAvailableFields } from '../actions/availableFields'
import { getCalendarActiveDate } from '../selectors/calendar'

const mapStateToProps = state => {
  const shouldQueryFilters = getShouldQueryFilters(state)
  return {
    activeDate: getCalendarActiveDate(state),
    isViewerDataLoaded: isUIRequestViewerDataInitializationSuccess(state),
    isDashboardLoaded: isUIRequestDashboardDataInitializationSuccess(state),
    fieldsWithInitialSeason: getFieldsWithInitialSeason(state),
    fieldsUpdatedAt: getUiFieldsUpdatedAt(state),
    shouldResetFilters: !shouldQueryFilters
  }
}

const mapDispatchToProps = {
  setSearchOptions,
  setMeteorologicalStatsAvailableFields
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(DataManager)
