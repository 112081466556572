import React from 'react'
import FileGeneratorTabs from './FileGeneratorTabs'
import FileGeneratorForm from '../DeliverablesComponents/forms/FileGeneratorForm'

export default function FileGenerator(props) {
  const tabs = [
    {
      icon: 'icon-shp',
      content: <FileGeneratorForm {...props} formatType={'shp'} />
    },
    {
      icon: 'icon-pdf',
      content: <FileGeneratorForm {...props} formatType={'pdf'} />
    },
    {
      icon: 'icon-raw',
      content: <FileGeneratorForm {...props} formatType={'raw'} />
    }
  ]

  const droneFlightTabs = [
    {
      icon: 'icon-raw',
      content: <FileGeneratorForm {...props} formatType={'raw'} />
    }
  ]

  return <FileGeneratorTabs tabs={props.isSatOrRadarSelected ? tabs : droneFlightTabs} />
}
