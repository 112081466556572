import React from 'react'
import { FormControl, FormHelperText, Input, MenuItem, Select } from '@mui/material'
import { UIStyles } from '../../theme'
import { withTranslator } from '../HOCComponents/withTranslator'
import { Dot } from '../FieldsComponents/SelectedFieldsCalendarLegendItem'

import map from 'lodash/map'
import find from 'lodash/find'
import isNil from 'lodash/isNil'

const inputTextStyle = {
  color: UIStyles.vomitColor
}

const disableInputTextStyle = {
  color: UIStyles.grayColor
}

const menuItemDisableSx = {
  color: UIStyles.darkGrayColor,
  backgroundColor: UIStyles.grayColor,
  cursor: 'not-allowed'
}

function StyledSelect({
  t,
  placeholder = '',
  onChange,
  options,
  disabled,
  errorText,
  defaultValue,
  multiple = false,
  value,
  fullWidth = true,
  autoWidth = false,
  isCalendarSelector = false,
  wrapperStyle = {},
  inputStyle = {},
  renderValue = value => (value ? find(options, item => item.id === value)?.name : placeholder),
  menuProps = {},
  testid = '',
  labelSelector = 'name',
  valueSelector = 'id',
  inputStyleDropdown = {},
  sx = {},
  inputLabel = null
}) {
  const result = isNil(value) ? (multiple ? [] : '') : value
  return (
    <FormControl style={wrapperStyle} fullWidth={fullWidth}>
      {inputLabel && (
        <label htmlFor={placeholder} style={{ color: UIStyles.whiteColor, fontSize: '12px', marginRight: '6px', whiteSpace: 'nowrap', fontWeight: 300 }}>
          {inputLabel}
        </label>
      )}
      <Select
        disabled={disabled}
        multiple={multiple}
        autoWidth={autoWidth}
        displayEmpty
        fullWidth={fullWidth}
        defaultValue={t(defaultValue)}
        value={result}
        onChange={event => onChange(event.target.value)}
        input={<Input placeholder={t(placeholder)} sx={[value ? inputTextStyle : disableInputTextStyle, inputStyle]} name={placeholder} />}
        label={t(placeholder)}
        renderValue={renderValue}
        error={errorText}
        MenuProps={menuProps}
        data-testid={testid}
        sx={sx}
      >
        {map(options, item => {
          const value = item[valueSelector]
          const label = item[labelSelector]
          return (
            <MenuItem
              disabled={item.disabled}
              value={value}
              sx={[item.disabled && menuItemDisableSx]}
              title={!item.disabled ? null : t('not enough data to display this chart')}
              key={value}
              data-testid={`${testid}-${value}`}
              style={inputStyleDropdown}
            >
              {isCalendarSelector && <Dot key={item.source} dotColor={item.color} style={{ marginRight: '10px' }} />}
              {t(label)}
            </MenuItem>
          )
        })}
      </Select>
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}
export default withTranslator(StyledSelect)
