import PropTypes from 'prop-types'
import OLContainer from './ol-container'
import { buildStyle } from './style'

import OlLayerVector from 'ol/layer/Vector'
import OlMap from 'ol/Map'
import OlStyle from 'ol/style/Style'

export default class LayerVector extends OLContainer {
  constructor(props) {
    super(props)
    this.layer = new OlLayerVector({
      updateWhileAnimating: props.updateWhileAnimating,
      updateWhileInteracting: props.updateWhileInteracting,
      style: buildStyle(this.props.style),
      visible: this.props.visible,
      name: this.props.name,
      renderMode: 'image'
    })

    this.layer.setZIndex(props.zIndex)
    this.layer.setOpacity(props.opacity)
  }

  getChildContext() {
    return {
      layer: this.layer,
      map: this.context.map
    }
  }

  componentDidMount() {
    this.context.map.addLayer(this.layer)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.layer.setStyle(buildStyle(newProps.style))
    this.layer.setVisible(newProps.visible)
    this.layer.setZIndex(newProps.zIndex)
    this.layer.setOpacity(newProps.opacity)
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layer)
  }
}

LayerVector.propTypes = {
  updateWhileAnimating: PropTypes.bool,
  updateWhileInteracting: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.instanceOf(OlStyle),
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(OlStyle), PropTypes.object]))
  ]),
  visible: PropTypes.bool,
  zIndex: PropTypes.number,
  name: PropTypes.string,
  children: PropTypes.object
}

LayerVector.defaultProps = {
  visible: true
}

LayerVector.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

LayerVector.childContextTypes = {
  layer: PropTypes.instanceOf(OlLayerVector),
  map: PropTypes.instanceOf(OlMap)
}
