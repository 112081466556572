import { RECEIVE_FIELDS_SEASONS_BY_IDS, RECEIVE_FIELDS_SEASON_PARAMETERS } from '../actions/seasons'

import reduce from 'lodash/reduce'
import get from 'lodash/get'
import camelCase from 'lodash/camelCase'

const initialState = {}

const parametersObjectFromArray = parameters => {
  return reduce(
    parameters,
    (accumulator, parameter) => {
      return {
        ...accumulator,
        [camelCase(get(parameter, 'parameter.name'))]: parameter
      }
    },
    {}
  )
}

const mapSeasonParameters = season => {
  const parameters = get(season, 'parameters')
  return {
    ...season,
    parameters: parametersObjectFromArray(parameters)
  }
}

const parseSeasons = seasons => {
  const normalizedNewSeasons = reduce(
    seasons,
    (accumulator, season) => ({
      ...accumulator,
      [season.id]: mapSeasonParameters(season)
    }),
    {}
  )
  return normalizedNewSeasons
}

export function seasons(state = initialState, action = {}) {
  const { seasons } = action
  const normalizedNewSeasons = parseSeasons(seasons, action)
  switch (action.type) {
    case RECEIVE_FIELDS_SEASONS_BY_IDS:
      return {
        ...state,
        ...normalizedNewSeasons
      }

    default:
      return state
  }
}

export function fieldSeasonParameters(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_FIELDS_SEASON_PARAMETERS:
      // eslint-disable-next-line no-case-declarations
      const normalizedParameters = reduce(
        action.parameters,
        (accumulator, parameter) => ({
          ...accumulator,
          [parameter.id]: parameter
        }),
        {}
      )
      return {
        ...state,
        ...normalizedParameters
      }

    default:
      return state
  }
}
