import { RECEIVE_CUSTOMER_VALUES, RECEIVE_CUT_NUMBER_VALUES, RECEIVE_FIELD_TYPES_VALUES, RECEIVE_SEASON_LABEL_VALUES } from '../actions/fields'
import {
  TYPE_SEARCH_TEXT,
  DELETE_SEARCH_TEXT,
  SELECT_SEARCH_ITEM,
  TOGGLE_FOCUS_SEARCH_BAR,
  TOGGLE_ADVANCED_SEARCH,
  OPEN_FILTER,
  TOGGLE_SEARCH_BAR,
  SET_SEARCH_OPTIONS,
  ASCENDENT,
  DESCENDENT,
  TOGGLE_SEARCH_SORT_BY
  // eslint-disable-next-line import/no-duplicates
} from '../actions/search'
// eslint-disable-next-line import/no-duplicates
import { hasFilters } from '../actions/search'
import { OPEN_SEARCH_MODE } from '../actions/viewmode'
import map from 'lodash/map'
import get from 'lodash/get'

import { advancedSearch } from '../constants'
import reduce from 'lodash/fp/reduce'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'

const { CUSTOMER_FILTER, TYPE_FILTER, PLANTATION_RANGE_FILTER, NAME_FILTER, SEASON_LABEL_FILTER } = advancedSearch
const initialSearchState = {
  input: '',
  focus: false,
  open: true,
  element: {},
  advancedSearchOpen: true,
  filters: {
    selected: null,
    [TYPE_FILTER]: {},
    [CUSTOMER_FILTER]: {},
    [PLANTATION_RANGE_FILTER]: {},
    [SEASON_LABEL_FILTER]: {}
  },
  sortBy: ASCENDENT,
  hasFilters: function () {
    return (
      this.input !== '' ||
      !this.filters[TYPE_FILTER].isNullObject() ||
      !this.filters[CUSTOMER_FILTER].isNullObject() ||
      !this.filters[PLANTATION_RANGE_FILTER].isNullObject() ||
      !this.filters[SEASON_LABEL_FILTER].isNullObject()
    )
  }
}

export const search = (state = initialSearchState, action) => {
  switch (action.type) {
    case TYPE_SEARCH_TEXT:
      return {
        ...state,
        input: action.payload,
        focus: true,
        element: {}
      }
    case TOGGLE_SEARCH_BAR:
      return {
        ...state,
        open: true,
        focus: !state.focus
      }
    case DELETE_SEARCH_TEXT:
      return {
        ...state,
        input: '',
        element: {},
        filters: {
          ...state.filters,
          selected: null,
          [PLANTATION_RANGE_FILTER]: {},
          [CUSTOMER_FILTER]: {},
          [TYPE_FILTER]: {},
          [SEASON_LABEL_FILTER]: {}
        }
      }

    case OPEN_SEARCH_MODE:
      if (hasFilters(state.filters)) {
        return {
          ...state,
          open: true,
          focus: true
        }
      } else {
        return {
          ...state,
          input: '',
          focus: false
        }
      }
    case TOGGLE_FOCUS_SEARCH_BAR:
      return {
        ...state,
        focus: action.payload
      }

    case SELECT_SEARCH_ITEM:
      return {
        ...state,
        input: action.payload.element.name,
        focus: true,
        element: action.payload
      }

    case TOGGLE_ADVANCED_SEARCH:
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: null
        }
      }

    case OPEN_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: toString(action.payload) !== toString(state.filters.selected) ? action.payload : null
        }
      }

    case TOGGLE_SEARCH_SORT_BY:
      return {
        ...state,
        sortBy: state.sortBy === ASCENDENT ? DESCENDENT : ASCENDENT
      }
    default:
      return state
  }
}

const initialSearchOptionsState = {
  [TYPE_FILTER]: [],
  [CUSTOMER_FILTER]: [],
  [NAME_FILTER]: []
}

export const searchOptions = (state = initialSearchOptionsState, action) => {
  switch (action.type) {
    case SET_SEARCH_OPTIONS:
      return action.searchOptions
    case RECEIVE_CUSTOMER_VALUES:
      return {
        ...state,
        customers: action.customerValues
      }
    case RECEIVE_CUT_NUMBER_VALUES:
      return {
        ...state,
        cutNumber: map(action.cutNumberValues, cutNumberValue => ({ ...cutNumberValue, name: cutNumberValue.value, id: cutNumberValue.value }))
      }
    case RECEIVE_FIELD_TYPES_VALUES:
      return {
        ...state,
        crops: map(action.fieldTypes, 'type')
      }
    case RECEIVE_SEASON_LABEL_VALUES:
      // eslint-disable-next-line no-case-declarations
      const seasonLabel = reduce((accumulator, seasonLabelValue) => {
        if (isEmpty(get(seasonLabelValue, 'label'))) return accumulator
        return [...accumulator, { ...seasonLabelValue, name: seasonLabelValue.label }]
      }, [])(action.seasonLabelValues)

      return {
        ...state,
        // eslint-disable-next-line object-shorthand
        seasonLabel: seasonLabel
      }
    default:
      return state
  }
}
