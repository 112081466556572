import { getLanguage } from './i18n'
import { getSelectedFieldsIds } from './fields'
import { getModalProp } from './modal'
import userStoredData from '../userStoredData'
import moment from 'moment'

export const getStatisticsBodyJson = state => {
  const fieldIds = getSelectedFieldsIds(state)
  const locale = getLanguage(state)
  const dateFrom = getModalProp(state, 'from')
  const dateTo = getModalProp(state, 'to')

  return {
    field_ids: fieldIds,
    from: `${dateFrom}`,
    to: `${dateTo}`,
    language: locale,
    api_key: userStoredData.API_KEY
  }
}

export const isDownloadStatisticsButtonEnabled = state => {
  const dateFrom = getModalProp(state, 'from')
  const dateTo = getModalProp(state, 'to')
  return moment(dateTo).isAfter(dateFrom)
}
