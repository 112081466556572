import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import ReportManagerModal from '../../components/ModalComponents/ReportManagerModal'

import { getReportsList } from '@layers-frontend/commons/store/selectors/reports'
import { getReportsStatuses } from '@layers-frontend/commons/store/selectors/reportsStatuses'
import { getFieldsReportId } from '../../selectors/fieldsReportModal'
import { fetchReports, updateReportStatus, deleteReportById, fetchReportsByFieldIds } from '../../actions/reports'
import { getFieldsNamesByIds, getFieldsByIds, getSelectedFieldsIds, getFieldsLength } from '../../selectors/fields'
import { editReport } from '../../actions/fieldsReportModal'
import { getUserConfigSurface } from '@layers-frontend/commons/store/selectors/user'
import { toggleCurrentSeasonReportsMode } from '../../actions/ui'
import { isShowCurrentSeasonReportsMode } from '../../selectors/ui'
import { getReportsTypes } from '@layers-frontend/commons/store/selectors/reportsTypes'

const mapStateToProps = state => ({
  reportTypes: getReportsTypes(state),
  reportStatuses: getReportsStatuses(state),
  reports: getReportsList(state),
  fieldsLength: getFieldsLength(state),
  fieldsNamesByIds: getFieldsNamesByIds(state),
  fieldsByIds: getFieldsByIds(state),
  selectedFieldsIds: getSelectedFieldsIds(state),
  editingReportId: getFieldsReportId(state),
  surfaceUnit: getUserConfigSurface(state),
  isShowCurrentSeasonReportsMode: isShowCurrentSeasonReportsMode(state)
})

const mapDispatchToProps = {
  fetchReports,
  fetchReportsByFieldIds,
  updateReportStatus,
  deleteReportById,
  editReport,
  toggleCurrentSeasonReportsMode
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(ReportManagerModal)
