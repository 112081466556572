import { fetchRequestWrapper } from './ui'
import { APPLICATION_JSON_HEADER } from '@layers-frontend/commons/constants'
import { getUserId } from '@layers-frontend/commons/store/selectors/user'
import { getUniqueId } from '@layers-frontend/commons/helpers/randomGenerator'

export const REQUEST_SAMPLE_PREFIX = 'REQUEST_SAMPLE_PREFIX'
export const RECEIVE_SAMPLE_PREFIX = 'RECEIVE_SAMPLE_PREFIX'
export const SET_UNIQUE_ID = 'SET_UNIQUE_ID'
export const INCREMENT_SAMPLE_CONFIG_COUNT_BY_USER = 'INCREMENT_SAMPLE_CONFIG_COUNT_BY_USER'

export const fetchSamplePrefix = () => (dispatch, getState) => {
  const state = getState()
  const userId = getUserId(state)
  return dispatch(
    fetchRequestWrapper({
      route: 'get_unique_prefix',
      fetchOptions: {
        method: 'POST',
        body: JSON.stringify({ deviceId: getUniqueId() }),
        headers: APPLICATION_JSON_HEADER
      },
      requestType: REQUEST_SAMPLE_PREFIX,
      onSuccess: ({ prefix, count }) => ({ type: RECEIVE_SAMPLE_PREFIX, prefix, count, userId })
    })
  )
}

export const incrementSampleConfigCountByUser = userId => ({ type: INCREMENT_SAMPLE_CONFIG_COUNT_BY_USER, userId })

export const incrementCurrentUserSampleConfigCount = () => (dispatch, getState) => {
  const userId = getUserId(getState())
  return dispatch(incrementSampleConfigCountByUser(userId))
}
