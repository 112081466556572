// eslint-disable-next-line import/no-named-default
import { initialState as initialReportsFormState, default as reportsCommon } from '@layers-frontend/commons/store/reducers/reports'
import { RECEIVE_REPORTS } from '@layers-frontend/commons/store/storeConstants'

export default function reports(state = initialReportsFormState, action = {}) {
  switch (action.type) {
    case RECEIVE_REPORTS:
      return {
        ...state,
        list: action.list
      }

    default:
      return {
        ...reportsCommon(state, action)
      }
  }
}
