import React from 'react'
import PropTypes from 'prop-types'
import { withTranslator } from '../HOCComponents/withTranslator'
import get from 'lodash/get'

class PdfDeliverable extends React.Component {
  openWindow = deliverableUrl => {
    return window.open(deliverableUrl.href, '_blank')
  }

  openDeliverableUrl = () => {
    const { deliverable, getDeliveryS3Url } = this.props
    let deliverableUrl
    const isSatDeliverable = get(deliverable, 's3_url.href')

    if (isSatDeliverable) {
      deliverableUrl = get(deliverable, 's3_url')
      this.openWindow(deliverableUrl)
      return
    }

    getDeliveryS3Url(deliverable.s3Path).then(data => {
      deliverableUrl = new URL(data.url)
      this.openWindow(deliverableUrl)
    })
  }

  render() {
    const { t, deliverable } = this.props
    const clickHandler = this.openDeliverableUrl
    return (
      <li>
        <button onClick={clickHandler}>
          <h3>{t(deliverable.type.name.toLowerCase())}</h3>
        </button>
      </li>
    )
  }
}

PdfDeliverable.propTypes = {
  deliverable: PropTypes.object,
  muiTheme: PropTypes.object
}

export default withTranslator(PdfDeliverable)
