import { MySpinningBalls } from '../../PagesComponents/LoadingPageView'
import styled from 'styled-components'

const LoaderWrapper = styled.div`
  transform: scale(0.6);
  margin-bottom: 25px;
`

export const CalendarLoader = () => {
  return (
    <LoaderWrapper>
      <MySpinningBalls />
    </LoaderWrapper>
  )
}
