import React from 'react'
import PropTypes from 'prop-types'
import ReactSlider from 'react-slider'
import { DeliverableLegend, DeliverableLegendItem } from './DeliverableLegend'

import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import upperFirst from 'lodash/upperFirst'
import toLower from 'lodash/toLower'

import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`

const StyledTrack = styled.div`
  top: 0;
  bottom: 0;
  background: ${props => (props.index === 2 ? 'white' : props.index === 1 ? 'grey' : 'white')};
  border-radius: 10px;
`

const StyledIcon = styled.i`
  color: ${UIStyles.vomitColor};
  margin-top: -7px;
`

const StyledLegendIcon = styled.i`
  visibility: ${({ isDisabled }) => (isDisabled ? 'hidden' : 'visible')};
`

const Thumb = props => (
  <div {...props}>
    <StyledIcon className={'fa fa-adjust'} />
  </div>
)

const Track = (props, state) => <StyledTrack {...props} index={state.index} />

export const DeliverableItem = withTranslator(
  ({ deliverable, toggleVisibility, toggleDeliverable, changeOpacity, clickTemporal, idx, isDraggable, isTemporal, t }) => {
    const { opened, visible, opacity, legendValues } = deliverable
    // eslint-disable-next-line camelcase
    const { name, legend_colors, measure_unit } = deliverable.type
    // eslint-disable-next-line no-extra-boolean-cast, camelcase
    const legends = !!legend_colors ? legend_colors : []
    const hasLegends = legends.length > 0
    const deliverableName = upperFirst(t(toLower(name)))
    const { isDragging, attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef } = useSortable({ id: deliverable.id })

    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      boxShadow: isDragging ? '0px 3px 6px 0px rgba(0,0,0,0.31)' : 'none'
    }

    return (
      <li key={idx} {...attributes} style={style} ref={setNodeRef} className={'deliverableDraggable ' + (!isDraggable ? ' undraggable ' : '')}>
        <div className="deliverableHeader">
          <div className="col-md-7">
            {isDraggable ? (
              <h1 {...listeners} ref={setActivatorNodeRef}>
                <i className="icon-grip-lines-solid" />
                {deliverableName}
              </h1>
            ) : (
              deliverableName
            )}
          </div>
          <div className="col-md-5 options">
            <div className={'opacitySliderWrapper ' + (isTemporal ? 'hidden' : '')}>
              <StyledSlider
                defaultValue={opacity}
                renderTrack={Track}
                renderThumb={Thumb}
                onChange={value => changeOpacity(value)}
                min={0}
                max={1}
                step={0.01}
              />
            </div>
            <i
              className={'ion-eye ' + (visible ? '' : 'gray')}
              onClick={() => {
                if (isTemporal) {
                  clickTemporal()
                } else {
                  toggleVisibility()
                }
              }}
            />
            <StyledLegendIcon
              className={'fa fa-info-circle ' + (opened ? '' : 'gray')}
              onClick={hasLegends ? toggleDeliverable : undefined}
              isDisabled={!hasLegends}
            />
          </div>
        </div>
        {hasLegends && (
          // eslint-disable-next-line camelcase
          <DeliverableLegend opened={opened} measure={measure_unit}>
            {legends
              .sort((a, b) => a.order_by - b.order_by)
              .map(legend => {
                // eslint-disable-next-line no-extra-boolean-cast
                const legendValue = !!legendValues ? legendValues.find(legendValue => legendValue.legend.id === legend.id) : null

                return (
                  <DeliverableLegendItem
                    key={legend.id}
                    name={legendValue ? t(legendValue.value) : t(legend.name)}
                    color={legend.color}
                    // eslint-disable-next-line camelcase
                    measure={measure_unit}
                  />
                )
              })}
          </DeliverableLegend>
        )}
      </li>
    )
  }
)

DeliverableItem.propTypes = {
  changeOpacity: PropTypes.func,
  changeRangeColor: PropTypes.func,
  idx: PropTypes.number,
  isTemporal: PropTypes.bool,
  isDraggable: PropTypes.bool,
  max: PropTypes.number,
  min: PropTypes.number,
  opacity: PropTypes.number,
  opened: PropTypes.bool,
  setTemporalComparison: PropTypes.func,
  toggleDeliverable: PropTypes.func,
  toggleVisibility: PropTypes.func,
  visible: PropTypes.bool
}
