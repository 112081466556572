import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'

const getEditor = get('editor')

const getEditorPresent = pipe(getEditor, get('present'))

export const getEditorFuture = pipe(getEditor, get('future'))

export const getEditorPresentData = (state, key) => pipe(getEditorPresent, get(key))(state)

export const isEditorPresentActive = pipe(getEditorPresent, get('active'))

export const isEditorPresentActiveTool = pipe(getEditorPresent, get('activeTool'))

export const isEditorMeasureToolActive = pipe(getEditorPresent, get('isMeasureToolsActive'))

export const getMarkerLocationCoordinates = pipe(getEditorPresent, get('features[0].geometry.coordinates'))
