import { connect } from 'react-redux'
import Interactions from '../../components/editorComponents/Interactions'

import { bindActionCreators } from 'redux'

import {
  polygonDrawStart,
  polygonDrawEnd,
  polygonAdd,
  polygonMove,
  addPoint,
  addNewPoint,
  addHole,
  polygonSlice,
  fieldModify,
  sliceException,
  addMarker,
  updateMarker
  // eslint-disable-next-line import/no-duplicates
} from '../../actions/editor'

import { updatePosition } from '../../actions/samples'
// eslint-disable-next-line import/no-duplicates
import { POLYGON_DRAW, CIRCLE_DRAW, DELETE, DISTANCE_TOOL, AREA_TOOL } from '../../actions/editor'

import { getScreenView } from '../../selectors/viewmode'
import { getEditorPresentData } from '../../selectors/editor'
import { getConfigProjection } from '../../selectors/config'
import { getSelectedField } from '../../selectors/fields'

const mapStateToProps = state => {
  const selectedSample = state.samplesForm.id ? state.samplesForm : null
  return {
    screenView: getScreenView(state),
    isDrawingTools: getEditorPresentData(state, 'activeTool') !== DELETE,
    isPolygonDrawingTool: getEditorPresentData(state, 'activeTool') === POLYGON_DRAW,
    isCircleDrawingTool: getEditorPresentData(state, 'activeTool') === CIRCLE_DRAW,
    isModifyTool: ![DISTANCE_TOOL, AREA_TOOL].includes(getEditorPresentData(state, 'activeTool')) && getEditorPresentData(state, 'active'),
    features: getEditorPresentData(state, 'features'),
    activeTool: getEditorPresentData(state, 'activeTool'),
    selectedField: getSelectedField(state),
    projection: getConfigProjection(state),
    drawing: getEditorPresentData(state, 'drawing'),
    currentDrawingGeometry: getEditorPresentData(state, 'currentDrawingGeometry'),
    selectedSample
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      /* eslint-disable object-shorthand */
      polygonDrawStart: polygonDrawStart,
      fieldModify: fieldModify,
      polygonDrawEnd: polygonDrawEnd,
      polygonAdd: polygonAdd,
      polygonMove: polygonMove,
      addPoint: addPoint,
      addMarker: addMarker,
      updateMarker,
      updatePosition,
      addNewPoint: addNewPoint,
      addHole: addHole,
      polygonSlice: polygonSlice,
      sliceException: sliceException
      /* eslint-enable object-shorthand */
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps, null)(Interactions)
