import React from 'react'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { StyledTooltip } from './StyledTooltip'

const Wrapper = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  height: 100%;
  gap: 5px;
  cursor: pointer;
`
const MainIcon = styled.i`
  color: ${themeColors.vomitColor};
  font-size: 20px;
`
const DirectionIcon = styled.i`
  color: ${themeColors.vomitColor};
  font-size: 24px;
`

export const SortButton = ({ mainIcon, onClick, direction, tooltipTitle }) => {
  return (
    <StyledTooltip title={tooltipTitle} placement="left">
      <Wrapper onClick={onClick}>
        <DirectionIcon className={direction === 'asc' ? 'fas fa-long-arrow-alt-up' : 'fas fa-long-arrow-alt-down'} />
        <MainIcon className={mainIcon} />
      </Wrapper>
    </StyledTooltip>
  )
}
