import PropTypes from 'prop-types'
import Draw from './draw'
import { buildStyle } from '../style'

import OlDraw from 'ol/interaction/Draw'

export default class DrawPolygon extends Draw {
  createInteraction(props, context) {
    return new OlDraw({
      type: 'Polygon',
      style: buildStyle(props.style),
      source: context.source
    })
  }
}

DrawPolygon.propTypes = {
  active: PropTypes.bool,
  drawend: PropTypes.func,
  drawstart: PropTypes.func,
  style: PropTypes.arrayOf(PropTypes.object)
}
