import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { connect } from 'react-redux'
import SwitchLayoutIcon from '../../components/DashboardComponents/SwitchLayoutIcon'
import { setChartsLayoutGrid, setChartsLayoutList } from '../../actions/ui'
import { getUiTemporalEvolutionLayout } from '../../selectors/ui'

const mapStateToProps = state => ({ chartsLayout: getUiTemporalEvolutionLayout(state) })

const mapDispatchToProps = {
  setChartsLayoutGrid,
  setChartsLayoutList
}
export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(SwitchLayoutIcon)
