import React, { useState, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

export const Title = styled.h1`
  text-align: center;
  width: 100%;
  color: ${themeColors.vomitColor};
  display: inline-flex;
  justify-content: center;
`

/**
 * Define keyframes for the fade-out animation
 */
const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

/**
 * Styled title with animation
 */

export const DisappearingTitle = ({ text, timeout }) => {
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, timeout)

    return () => clearTimeout(timer)
  }, [timeout])

  const TitleWrapper = styled.span`
    animation: ${fadeOut} 3s ease-in-out 0s forwards;
    font-weight: 400;
    display: ${isVisible ? 'block' : 'none'};
  `
  return <TitleWrapper>{text}</TitleWrapper>
}
