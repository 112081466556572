import { connect } from 'react-redux'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'

import LoadingPageView from '../../components/PagesComponents/LoadingPageView'
import { areRequestsWithOverlayPending } from '@layers-frontend/commons/store/selectors/requests'

const mapStateToProps = state => ({
  areRequestsPending: areRequestsWithOverlayPending(state)
})

export default withCurrentRoute(connect(mapStateToProps)(LoadingPageView))
