import React from 'react'
import moment from 'moment'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import { withTranslator } from '../../HOCComponents/withTranslator'
import styled from 'styled-components'
import StyledDatePicker from '../../UIComponents/Calendars/StyledDatePicker'

const Wrapper = styled(ToolWrapper)`
  input[name='RelatedDatePicker'] {
    color: white;
  }
`

function RelatedDatePicker({ t, setRelatedDate, relatedDate }) {
  const onDateSelect = date => setRelatedDate(date)
  return (
    <Wrapper>
      <ToolTitle>{t('Related date')}</ToolTitle>
      <StyledDatePicker
        name="RelatedDatePicker"
        value={relatedDate}
        formatDate={date => moment(date, 'YYYY-MM-DD').format('D MMM YY')}
        onChange={onDateSelect}
      />
    </Wrapper>
  )
}

export default withTranslator(RelatedDatePicker)
