import reduce from 'lodash/reduce'
import get from 'lodash/get'
import capitalize from 'lodash/capitalize'
import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isPlainObject from 'lodash/isPlainObject'
import isFunction from 'lodash/isFunction'

export const metadataToExtraParamterers = (metadata, parameters) => {
  if (!isPlainObject(metadata) && !isArray(parameters)) {
    throw Error('this function needs to receive sample metadata and paramtersArray')
  }

  return reduce(
    metadata,
    (accumulator, value, metadataParameterId) => {
      const parameterToAdd = find(parameters, param => `${param.parameterId}` === `${metadataParameterId}`)

      if (parameterToAdd) {
        return [...accumulator, { ...parameterToAdd, value }]
      }

      return accumulator
    },
    []
  )
}

export const extraParamterersToMedatada = extraParameters => {
  if (!isArray(extraParameters)) {
    throw Error('this function needs to receive the extraParameters array')
  }

  return reduce(
    extraParameters,
    (accumulator, parameter) => {
      return {
        ...accumulator,
        [parameter.parameterId]: parameter.value
      }
    },
    {}
  )
}

export const translateParameterName = (parameter, t) => {
  if (!isPlainObject(parameter) || !isFunction(t)) {
    throw Error('this function needs to receive a sample parameter and a translation function')
  }
  const unit = get(parameter, ['measureUnit', 'shortName'])
  const userFriendlyText = `${capitalize(t(parameter.name))}${unit ? ' [' + unit + ']' : ''}`
  return userFriendlyText
}
