import React from 'react'

import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import map from 'lodash/map'
import slice from 'lodash/slice'

import { BoxContainer, ChartTitle, Column } from '../Layout'
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar } from 'recharts'
import createSeasonEvolutionTooltip from './createSeasonEvolutionTooltip'
import { findLastTimeCoordinateInPast } from '../../../dashboardUtils'

import { colorArray } from '../colorArray'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const IdealCurveChart = ({ t, title, data, areFieldsSelected, chartsWidth, yLabel }) => {
  const { idealCurve, normalized } = data
  const selectedFieldsIds = Object.keys(normalized)
  const getIdealCurveData = ({ avg }) => avg

  const getFieldCurve = (fieldId, dataPeriod) => {
    if (!data || data.length < 1) return

    const dataByWeek = get(data, `normalized[${fieldId}].byWeek`)
    const lastPastWeek = findLastTimeCoordinateInPast(dataByWeek)
    /* eslint-disable camelcase */
    return ({ week_from_plantation_date }) => {
      const weekData = get(data, `normalized[${fieldId}]['byWeek'][${week_from_plantation_date}]`)
      if (!weekData || weekData.avg < 0) return
      if (dataPeriod === 'past' && weekData.week_from_plantation_date > lastPastWeek) return
      if (dataPeriod === 'future' && weekData.week_from_plantation_date < lastPastWeek) return
      /* eslint-enable camelcase */

      return weekData.avg
    }
  }

  // tooltip functions
  const getTooltipData = payload => {
    const linesData = slice(payload, 0, -1)
    return map(linesData, item => {
      const currentField = normalized[item.name].byDate
      const firstKeyObject = Object.values(currentField)[0]
      const fieldName = firstKeyObject.field_name
      const label = !isEmpty(fieldName) ? fieldName : firstKeyObject.customer_name
      const value = get(item, 'value').toFixed('2')
      const color = get(item, 'color')
      return { label, value, color }
    })
  }

  const getTooltipTitle = payload => {
    const areaData = last(payload)
    const average = get(areaData, 'payload.avg').toFixed(2)
    const week = `${get(areaData, 'payload.week_from_plantation_date')}`
    return `${t('week')} ${week} | ${average} ${t(get(areaData, 'unit'))}`
  }

  return (
    !isEmpty(normalized) &&
    areFieldsSelected && (
      <Column marginBottom="30px" width={chartsWidth} transition="0.3s ease-in-out">
        <BoxContainer>
          <ChartTitle>{t(title)}</ChartTitle>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart fill={themeColors.lightBlue} height={4500} data={idealCurve} margin={{ top: 20, bottom: 20, left: 0, right: 20 }}>
              <XAxis stroke="#ffffff" dataKey="week_from_plantation_date" label={{ value: t('week'), position: 'insideBottom', offset: -15 }} />
              <YAxis stroke="#ffffff" type="number" interval={0} label={{ value: yLabel, angle: -90, position: 'insideLeft', offset: 10 }} />
              <CartesianGrid strokeDasharray="3 3" stroke={themeColors.lightBlue} fill="rgba(255,255,255,0.08)" />
              {createSeasonEvolutionTooltip({
                getTitle: getTooltipTitle,
                getData: getTooltipData
              })}

              {!isEmpty(normalized) &&
                map(selectedFieldsIds, (fieldId, index) => (
                  <Line
                    key={`${fieldId}Future`}
                    name={fieldId}
                    dataKey={getFieldCurve(fieldId, 'future')}
                    type="basis"
                    connectNulls
                    stroke={colorArray[index]}
                    strokeDasharray="5"
                    strokeWidth={2}
                    isAnimationActive={false}
                    dot={false}
                  />
                ))}

              {!isEmpty(normalized) &&
                map(selectedFieldsIds, (fieldId, index) => (
                  <Line
                    key={`${fieldId}Past`}
                    name={fieldId}
                    dataKey={getFieldCurve(fieldId, 'past')}
                    type="basis"
                    connectNulls
                    stroke={colorArray[index]}
                    strokeWidth={2}
                    isAnimationActive={false}
                  />
                ))}
              <Bar dataKey={getIdealCurveData} fill="rgba(255, 255, 255, 0.3)" isAnimationActive={false} unit={yLabel} />
            </ComposedChart>
          </ResponsiveContainer>
        </BoxContainer>
      </Column>
    )
  )
}

export default IdealCurveChart
