import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import isEmpty from 'lodash/fp/isEmpty'
import find from 'lodash/fp/find'

import { advancedSearch } from '../constants'

export const getSearch = get('search')

export const isSearchOpen = pipe(getSearch, get('open'))

export const getSearchFilters = pipe(getSearch, get('filters'))

export const getSearchData = (state, key) => pipe(getSearch, get(key))(state)

export const isSearchFocus = pipe(getSearch, get('focus'))

export const getSelectedFilter = pipe(getSearchFilters, get('selected'))

export const getSearchOptions = get('searchOptions')

export const getSearchSortBy = pipe(getSearch, get('sortBy'))

const { CUT_NUMBER_FILTER, SEASON_LABEL_FILTER } = advancedSearch
export const hasCutNumberFilter = pipe(getSearchOptions, get(CUT_NUMBER_FILTER), cutNumbers => !isEmpty(cutNumbers))
export const hasSeasonLabelFilter = pipe(getSearchOptions, get(SEASON_LABEL_FILTER), seasonLabels => !isEmpty(seasonLabels))

export const getCutNumberValues = pipe(getSearchOptions, get('cutNumberValues'))
export const getCustomerValues = pipe(getSearchOptions, get('customers'))
export const getSeasonLabelValues = pipe(getSearchOptions, get('seasonLabelValues'))
export const getFieldTypesValues = pipe(getSearchOptions, get('fieldTypesValues'))

export const getCutNumberValueById = id => pipe(getCutNumberValues, get(id), get('value'))

export const getSeasonLabelValueById = id => state => {
  const values = getSeasonLabelValues(state)
  const value = find(values, v => v.id === id)
  const label = get('label')(value)
  return label
}
