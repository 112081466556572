import React from 'react'
import PropTypes from 'prop-types'

export default class FieldNewView extends React.Component {
  render() {
    return (
      <div className="fieldViewContainer">
        <div className="description"></div>
      </div>
    )
  }
}

FieldNewView.propTypes = {
  dispatch: PropTypes.func
}
