import { createSelector } from 'reselect'
import { getFieldsWithInitialSeason } from './fields'
import { getFilteredFieldIds, getGlobalMapFeatures, getIsGlobalLayerLegendOpen } from './globalLayer'
import { dashboardViews, SEARCH_BAR_LIST_MODE } from '../constants'
import {
  getFiltersCropsIds,
  getFiltersCustomersIds,
  getFiltersPlantationDateRange,
  getFiltersSearchInputString,
  getFiltersCutNumber,
  getFiltersSeasonLabel
} from './filters'
import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import map from 'lodash/fp/map'
import includes from 'lodash/fp/includes'
import orderBy from 'lodash/fp/orderBy'
import isString from 'lodash/isString'
import filter from 'lodash/fp/filter'
import isEmpty from 'lodash/fp/isEmpty'
import toLower from 'lodash/fp/toLower'
import moment from 'moment'
import { getSearchSortBy } from './search'
import some from 'lodash/fp/some'
import { getSearchBarListMode } from './ui'

export const getAvailableFields = get('availableFields')

const keyToUpperCase = key => data => {
  const keyValue = get(key)(data)
  if (!isString(keyValue)) return undefined

  return keyValue.toUpperCase()
}

export const getAvailableFieldsByView = viewName => pipe(getAvailableFields, get(viewName))

export const getFieldsWithAvailableAndSelected = ({ selectedFieldsIds = [] }) =>
  createSelector(
    getFieldsWithInitialSeason,
    map(field => {
      const fieldId = get('id')(field)
      const isSelected = includes(fieldId)(selectedFieldsIds)
      const isAvailable = true // this sets the fields on the dashboard as available or disabled (greyed out on the search list)

      return {
        ...field,
        fieldNameUpperCase: keyToUpperCase('name')(field),
        fieldCustomerUpperCase: keyToUpperCase('customer.name')(field),
        fieldTypeUpperCase: keyToUpperCase('season.type.name')(field),
        fieldSubTypeUpperCase: keyToUpperCase('season.subtype.name')(field),
        fieldExternalReferenceIdUpperCase: keyToUpperCase('externalReferenceId')(field),
        fieldSeasonLabelUpperCase: keyToUpperCase('season.label')(field),
        fieldCommentUpperCase: keyToUpperCase('comment')(field),

        isSelected,
        isAvailable
      }
    })
  )

export const getFieldsSelected = ({ selectedFieldsIds = [] }) =>
  createSelector(
    getFieldsWithAvailableAndSelected({ selectedFieldsIds }),
    filter(field => field.isSelected)
  )

export const getFieldsWithActiveCrop = ({ selectedFieldsIds = [] }) =>
  createSelector(
    getFieldsWithAvailableAndSelected({ selectedFieldsIds }),
    filter(field => get('season.type.id')(field) !== 78)
  )

export const getFieldsBySearchBarListMode = ({ selectedFieldsIds = [] }) => state => {
  const mode = getSearchBarListMode(state)
  switch (mode) {
    case SEARCH_BAR_LIST_MODE.ALL.value:
      return getFieldsWithAvailableAndSelected({ selectedFieldsIds })(state)
    case SEARCH_BAR_LIST_MODE.ACTIVE.value:
      return getFieldsWithActiveCrop({ selectedFieldsIds })(state)
    case SEARCH_BAR_LIST_MODE.SELECTED.value:
      return getFieldsSelected({ selectedFieldsIds })(state)
    default:
      // eslint-disable-next-line no-useless-return
      return
  }
}

const filterFieldsWithGlobalLayers = (globalLayerFeatureIds = [], isGlobalLayerLegendOpen) => (fields = []) => {
  if (isEmpty(globalLayerFeatureIds) || !isGlobalLayerLegendOpen) {
    return fields
  }
  return filter(field => includes(+field.id)(globalLayerFeatureIds))(fields)
}

const filterFieldsByCrop = (cropIds = []) => (fields = []) => {
  if (isEmpty(cropIds)) {
    return fields
  }

  return filter(field => {
    const currentCropId = get('season.type.id')(field)
    return includes(currentCropId)(cropIds) || isAvailableAndSelected(field)
  })(fields)
}

const filterFieldsByCustumer = (customersIds = []) => (fields = []) => {
  if (isEmpty(customersIds)) {
    return fields
  }

  return filter(field => {
    const currentCustomerId = get('customer.id')(field)
    return includes(currentCustomerId)(customersIds) || isAvailableAndSelected(field)
  })(fields)
}

const filterFieldsByPlantationDate = ({ from = undefined, to = undefined }) => (fields = []) => {
  if (!from || !to) {
    return fields
  }

  return filter(field => {
    const plantationDate = get('season.parameters.plantationDate.value')(field)

    return (plantationDate && moment(plantationDate).isBetween(from, to)) || isAvailableAndSelected(field)
  })(fields)
}

const isAvailableAndSelected = ({ isAvailable, isSelected }) => isAvailable && isSelected
const filterByAvailableAndSelected = shouldFilter => {
  if (!shouldFilter) {
    return fields => fields
  }

  return filter(isAvailableAndSelected)
}

const isStringContained = (stringToCheck = '', searchValue) => stringToCheck.includes(searchValue)

const filterFieldsBySearchInput = (searchInput = '') => {
  if (isEmpty(searchInput)) {
    return fields => fields
  }

  const searchInputUpperCase = searchInput.toUpperCase()
  return filter(field => {
    switch (true) {
      case isStringContained(field.fieldNameUpperCase, searchInputUpperCase): // agro__field - name
      case isStringContained(field.fieldCustomerUpperCase, searchInputUpperCase): // agro_customer - name
      case isStringContained(field.fieldTypeUpperCase, searchInputUpperCase): // agro__field_type - name
      case isStringContained(field.fieldSubTypeUpperCase, searchInputUpperCase): // agro__field_sub_type - name
      case isStringContained(field.fieldExternalReferenceIdUpperCase, searchInputUpperCase): // agro__field - external_reference_id
      case isStringContained(field.fieldSeasonLabelUpperCase, searchInputUpperCase): // agro__seasons - label
      case isStringContained(field.fieldCommentUpperCase, searchInputUpperCase): // agro__field - comment
      case isAvailableAndSelected(field):
        return true

      default:
        return false
    }
  })
}

const filterFieldsByCutNumber = (cutNumbers = []) => {
  if (isEmpty(cutNumbers)) {
    return fields => fields
  }

  return filter(field => {
    const currentCutNumber = get('season.parameters.cutNumber.value')(field)
    if (isAvailableAndSelected(field)) {
      return true
    }

    return isString(currentCutNumber) && includes(currentCutNumber.toUpperCase())(cutNumbers)
  })
}

const filterFieldsBySeasonLabel = (seasonLabels = []) => (fields = []) => {
  if (isEmpty(seasonLabels)) {
    return fields
  }

  return filter(field => {
    const currentSeasonLabels = get('season.label')(field)
    return includes(currentSeasonLabels)(seasonLabels) || isAvailableAndSelected(field)
  })(fields)
}

const sortByUpperCaseName = sortedOrder => orderBy(field => pipe(get('name'), toLower)(field), sortedOrder)

export const getFieldsFilteredByView = ({ fieldsWithAvailableAndSelected = [], filterAvailableAndSelected = true }) => state => {
  const selectedCropsIds = getFiltersCropsIds(state)
  const selectedCustomersIds = getFiltersCustomersIds(state)
  const plantationDateRange = getFiltersPlantationDateRange(state)
  const searchInput = getFiltersSearchInputString(state)
  const sortedBy = getSearchSortBy(state)
  const cutNumbers = getFiltersCutNumber(state)
  const seasonLabel = getFiltersSeasonLabel(state)
  const globalLayerFeatures = getGlobalMapFeatures(state)
  const filteredFieldIdsFromGlobalLayer = getFilteredFieldIds(state)
  const totalGlobalLayerFeatures = globalLayerFeatures?.length

  const fieldsFilteredByGlobalLayer = filteredFieldIdsFromGlobalLayer.length === totalGlobalLayerFeatures ? [] : filteredFieldIdsFromGlobalLayer

  const isGlobalLayerLegendOpen = getIsGlobalLayerLegendOpen(state)
  const fields = pipe(
    filterFieldsWithGlobalLayers(fieldsFilteredByGlobalLayer, isGlobalLayerLegendOpen),
    filterByAvailableAndSelected(filterAvailableAndSelected),
    filterFieldsByCrop(selectedCropsIds), // season.type.id
    filterFieldsByCustumer(selectedCustomersIds), // customer.id
    filterFieldsByPlantationDate(plantationDateRange), // season.parameters.plantationDate.value
    filterFieldsBySearchInput(searchInput), // search input
    filterFieldsByCutNumber(cutNumbers), // season.parameters.cutNumber.value
    filterFieldsBySeasonLabel(seasonLabel), // season.label
    sortByUpperCaseName(sortedBy)
  )(fieldsWithAvailableAndSelected)
  return fields
}

export const hasDashboardAvailableFields = state => {
  return pipe(some(view => !isEmpty(getAvailableFieldsByView(view)(state))))(Object.keys(dashboardViews))
}
