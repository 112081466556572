import PropTypes from 'prop-types'

import OLComponent from './ol-component'
import OlSourceXYZ from 'ol/source/XYZ'
import OlLayerTile from 'ol/layer/Tile'
import OlSource from 'ol/source/Source'

export default class SourceXYZ extends OLComponent {
  constructor(props) {
    super(props)

    this.source = new OlSourceXYZ({
      url: props.url,
      tileSize: 512,
      minZoom: this.props.minZoom,
      maxZoom: this.props.maxZoom
    })
  }

  getChildContext() {
    return {
      source: this.source
    }
  }

  componentDidMount() {
    this.context.layer.setSource(this.source)
  }
}

SourceXYZ.propTypes = {
  url: PropTypes.string,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number
}

SourceXYZ.defaultProps = {}

SourceXYZ.contextTypes = {
  layer: PropTypes.instanceOf(OlLayerTile)
}

SourceXYZ.childContextTypes = {
  source: PropTypes.instanceOf(OlSource)
}
