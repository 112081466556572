import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import ExtraStatisticsFetchConfirmationModal from '../../components/ModalComponents/ExtraStatisticsFetchConfirmationModal'
import { closeModal } from '../../actions/modal'
import { fetchTemporalEvolutionStatistics } from '../../actions/statistics'
import { getDashboardFormFilter } from '../../selectors/dashboard'
import { getSelectedFieldsIds } from '../../selectors/fields'

const mapStateToProps = state => ({
  from: getDashboardFormFilter(state, 'TemporalEvolutionMetadataDateRangeSelectFrom.value'),
  to: getDashboardFormFilter(state, 'TemporalEvolutionMetadataDateRangeSelectTo.value'),
  selectedFieldsIds: getSelectedFieldsIds(state)
})

const mapDispatchToProps = {
  closeModal,
  fetchTemporalEvolutionStatistics
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(ExtraStatisticsFetchConfirmationModal)
