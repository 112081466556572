import React from 'react'
import { DraggableDeliverable, LayerBox } from './LayerBox'
import PdfDeliverableContainer from '../../containers/DeliverablesContainers/PdfDeliverableContainer'
import DeliverablesSourceSelector from './DeliverablesSourceSelector'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'
import noop from 'lodash/noop'

function DeliverablesLayerBoxesContainer({
  changeOpacity,
  deliverables,
  isLayers,
  isTemporalView,
  pdfDeliverables,
  setLayerOrder,
  setTemporalComparison,
  toggleDeliverable,
  toggleVisibility,
  setVisibilitySeasonLayer,
  setOpacitySeasonLayer,
  setOrderSeasonLayers,
  toggleSeasonLayerLegends,
  seasonLayers,
  activeTab,
  activeDateCalendarDots,
  selectFlightGroup,
  selectedFlightGroupIndex
}) {
  const isLayersTab = isLayers && activeTab === 'layers'
  const isDownloadsTab = !isLayersTab && activeTab === 'downloads'
  const isSeasonLayersTab = activeTab === 'seasonLayers'

  const layerBoxes = [
    <LayerBox
      layers={deliverables}
      key="DraggableDeliverable"
      isDraggable={true}
      onSortEnd={setLayerOrder}
      visible={isLayersTab}
      renderLayer={(deliverable, index) => (
        <DraggableDeliverable
          idx={index}
          index={index}
          key={index}
          deliverable={deliverable}
          isTemporal={isTemporalView}
          toggleVisibility={() => toggleVisibility(deliverable)}
          clickTemporal={() => setTemporalComparison(deliverable)}
          toggleDeliverable={() => toggleDeliverable(deliverable)}
          changeOpacity={opacity => changeOpacity(deliverable, opacity)}
        />
      )}
    />
  ]

  if (!isEmpty(seasonLayers)) {
    layerBoxes.push(
      <LayerBox
        layers={seasonLayers}
        key="SEASON_LAYERS"
        isDraggable={true}
        onSortEnd={setOrderSeasonLayers}
        visible={isSeasonLayersTab}
        renderLayer={(deliverable, index) => (
          <DraggableDeliverable
            idx={index}
            index={index}
            key={index}
            deliverable={deliverable}
            isTemporal={isTemporalView}
            toggleVisibility={() => setVisibilitySeasonLayer(index, !deliverable.visible)}
            clickTemporal={noop}
            toggleDeliverable={() => toggleSeasonLayerLegends(index)}
            changeOpacity={opacity => setOpacitySeasonLayer(index, opacity)}
          />
        )}
      />
    )
  }

  if (!isEmpty(pdfDeliverables)) {
    layerBoxes.push(
      <LayerBox
        key="pdfwrapper"
        layers={pdfDeliverables}
        className={'pdfwrapper'}
        renderLayer={(deliverable, index) => <PdfDeliverableContainer index={index} deliverable={deliverable} />}
        visible={isDownloadsTab}
      />
    )
  }

  return (
    <>
      {size(activeDateCalendarDots) > 1 && isLayersTab && (
        <DeliverablesSourceSelector
          activeDateCalendarDots={activeDateCalendarDots}
          selectFlightGroup={selectFlightGroup}
          selectedFlightGroupIndex={selectedFlightGroupIndex}
        />
      )}
      <div className="layerBoxes">{layerBoxes}</div>
    </>
  )
}

export default DeliverablesLayerBoxesContainer
