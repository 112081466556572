import React from 'react'
import styled from 'styled-components'
import { ToolWrapper } from './LayoutComponents'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { UIStyles } from '../../../theme'
import CustomTrash from './CustomTrash'

const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  font-size: 2.5rem;
`

const RemoveImageButton = styled(Button)`
  position: absolute;
  z-index: 1;
  right: calc(50% - 1.25rem);
  top: calc(50% - 1.5rem);
  color: ${UIStyles.grayColor};
  transition: all 0.4s ease-in-out;
  opacity: 0;
  font-size: 5rem;
`

const StyledImage = styled.img`
  max-width: 100%;
`

const ButtonsAndImageWrapper = styled(ToolWrapper)`
  margin-top: 3.6rem;
  position: relative;

  ${({ style }) => style}

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent;
    transition: all 1s ease-in-out;
  }
  &:hover:after {
    background-color: rgba(0, 0, 0, 0.6);
  }
  #custom_svg_trash {
    width: 2.5rem;
    height: auto;
  }
  #custom_svg_trash #svg_custom_trash_lid {
    transform-origin: 50% 70%;
    transition: all 0.4s ease-in-out;
  }
  &:hover {
    ${RemoveImageButton} {
      opacity: 1;
      &:hover #custom_svg_trash #svg_custom_trash_lid {
        transform: rotate(-25deg);
      }
    }
  }
`

function Image({ source }) {
  if (!source) return null

  return <StyledImage src={source} />
}

function ScreenshotImage({ activeScreenshot, removeScreenshot, style = {} }) {
  const hasImageSet = !!activeScreenshot
  if (!hasImageSet) return null

  return (
    <ButtonsAndImageWrapper style={style}>
      <RemoveImageButton onClick={removeScreenshot}>
        <CustomTrash />
      </RemoveImageButton>
      <Image source={activeScreenshot} />
    </ButtonsAndImageWrapper>
  )
}

export default withTranslator(ScreenshotImage)
