import GeometryUtils from '../geometryUtils'
import map from 'lodash/map'
import { alertNotification } from './notifications'
import { activeLayers } from './deliverables'
import { layersViews } from '../constants'

import { getConfigProjection } from '../selectors/config'

/** app is ready **/

export const RECEIVE_VIEWER_DATA = 'RECEIVE_VIEWER_DATA'
export const RECEIVE_DASHBOARD_DATA = 'RECEIVE_DASHBOARD_DATA'

/** actions **/

export const OPEN_CREATION_MODE = 'OPEN_CREATION_MODE'
export const OPEN_EDIT_MODE = 'OPEN_EDIT_MODE'
export const OPEN_FIELD_SEASON_FORM = 'OPEN_FIELD_SEASON_FORM'
export const OPEN_SEARCH_MODE = 'OPEN_SEARCH_MODE'
export const CLOSE_CREATION_MODE = 'CLOSE_CREATION_MODE'
export const CLOSE_EDIT_MODE = 'CLOSE_EDIT_MODE'
export const CLOSE_TEMPORAL_COMPARE_MODE = 'CLOSE_TEMPORAL_COMPARE_MODE'
export const OPEN_EDIT_MODE_WITH_CURRENT_GEOMETRY = 'OPEN_EDIT_MODE_WITH_CURRENT_GEOMETRY'
export const OPEN_SAMPLE_LOCATION_MODE = 'OPEN_SAMPLE_LOCATION_MODE'
export const CLOSE_SAMPLE_LOCATION_MODE = 'CLOSE_SAMPLE_LOCATION_MODE'
export const CLOSE_READ_ONLY_MODE = 'CLOSE_READ_ONLY_MODE'
/** screen global layersViews **/

export const SEARCH_VIEW = layersViews.SEARCH_VIEW
export const SEARCH_DELETED_VIEW = layersViews.SEARCH_DELETED_VIEW
export const FIELDS_LIST_VIEW = layersViews.FIELDS_LIST_VIEW
export const FIELD_VIEW = layersViews.FIELD_VIEW
export const FIELD_NEW_VIEW = layersViews.FIELD_NEW_VIEW
export const FIELD_EDIT_VIEW = layersViews.FIELD_EDIT_VIEW
export const AREA_MEASURE_VIEW = layersViews.AREA_MEASURE_VIEW
export const LINE_MEASURE_VIEW = layersViews.LINE_MEASURE_VIEW
export const TEMPORAL_COMPARE_VIEW = layersViews.TEMPORAL_COMPARE_VIEW
export const LOADING_PAGE_VIEW = layersViews.LOADING_PAGE_VIEW
export const SAMPLE_LOCATION_VIEW = layersViews.SAMPLE_LOCATION_VIEW

/** Change satellite map  **/
export const TOGGLE_BASE_MAP = 'TOGGLE_BASE_MAP'

export const SELECT_BASE_MAP = 'SELECT_BASE_MAP'

export const receiveAllData = () => {
  return {
    type: RECEIVE_VIEWER_DATA
  }
}

export const receiveAllDashboardData = () => {
  return {
    type: RECEIVE_DASHBOARD_DATA
  }
}

export const openCreation = () => {
  return {
    type: OPEN_CREATION_MODE
  }
}

export const openCreationMode = () => (dispatch, getState) => {
  // eslint-disable-next-line prefer-const
  let customers = getState().customers

  if (!customers.length) dispatch(alertNotification("You cannot create field because you don't have any customers. Please ask us for adding ones"))
  return dispatch(openCreation())
}

export const openEditMode = (features = [], surface = 0) => ({ type: OPEN_EDIT_MODE, features, surface })

export const openFieldSeasonForm = (features = [], surface = 0) => ({ type: OPEN_FIELD_SEASON_FORM, features, surface })

export const openEditModeWithCurrentGeometry = () => ({ type: OPEN_EDIT_MODE_WITH_CURRENT_GEOMETRY })

export const openSampleLocationMode = () => (dispatch, getState) => {
  const state = getState()
  return dispatch({ type: OPEN_SAMPLE_LOCATION_MODE, selectedSample: state.samplesForm.id })
}

export const closeSampleLocationMode = () => ({ type: CLOSE_SAMPLE_LOCATION_MODE })

export const setEditModeGeometry = geometry => (dispatch, getState) => {
  if (!geometry) {
    return dispatch(openEditMode())
  }
  const state = getState()
  const projection = getConfigProjection(state)

  const features = GeometryUtils.multipolygonToGeometries(geometry, projection.to)
  const geometries = map(features, 'geometry')
  const multi = GeometryUtils.geometriesToMultipolygon(geometries, projection.to)
  const surface = GeometryUtils.areaFromMultipolygon(multi, projection.from, projection.to)

  return dispatch(openEditMode(features, surface))
}

export const openSearchMode = () => {
  return {
    type: OPEN_SEARCH_MODE
  }
}

export const closeEdit = () => {
  return {
    type: CLOSE_EDIT_MODE
  }
}

export const closeReadOnlyMode = () => {
  return {
    type: CLOSE_READ_ONLY_MODE
  }
}

export const closeTemporalCompareMode = () => {
  return {
    type: CLOSE_TEMPORAL_COMPARE_MODE
  }
}

export const closeEditMode = () => (dispatch, getState) => {
  /* use only if we are in single selection mode */
  if (!getState().selectedFields.length > 0) {
    dispatch(activeLayers(true))
  }
  return dispatch(closeEdit())
}

export const closeCreationMode = () => {
  return {
    type: CLOSE_CREATION_MODE,
    payload: {
      active: 'select'
    }
  }
}

export const toggleBaseMap = () => {
  return {
    type: TOGGLE_BASE_MAP
  }
}

export const selectBaseMap = localStorageIndex => dispatch => {
  return dispatch({
    type: SELECT_BASE_MAP,
    localStorageIndex
  })
}
