import React from 'react'
import AccountsList from './AccountsList'
import styled from 'styled-components'

const LayoutWrapper = styled.div`
  margin: 0px 60px 20px 60px;
  color: white;
`

const ErrorMessage = styled.div`
  color: white;
  font-size: 18px;
  margin: 20px 0;
`

const LoadingMessage = styled.div`
  font-size: 18px;
  margin: 20px 0;
  color: white;
`

const AccountsLayout = ({ t, isLoading, error, accounts }) => {
  if (isLoading) {
    return (
      <LayoutWrapper>
        <LoadingMessage>Loading...</LoadingMessage>
      </LayoutWrapper>
    )
  }

  if (error) {
    return (
      <LayoutWrapper>
        <ErrorMessage>Error loading data</ErrorMessage>
      </LayoutWrapper>
    )
  }

  return (
    <LayoutWrapper className="accounts_container">
      <AccountsList t={t} accounts={accounts} />
    </LayoutWrapper>
  )
}
export default AccountsLayout
