import { useSelector } from 'react-redux'
import { useGetUserByTokenQuery, useGetOrganizationsForUserQuery } from '../../features/api/authApiSlice'

/**
  Custom hook to fetch user and organizations data.
**/

const useAuthData = () => {
  const tokenData = useSelector(state => state.userLayers.token)
  const token = tokenData?.data?.access_token

  const { data: user, error: userError, isLoading: isUserLoading } = useGetUserByTokenQuery(token, {
    skip: !token
  })
  const { data: organizations, error: organizationsError, isLoading: isOrganizationsLoading } = useGetOrganizationsForUserQuery(token, {
    skip: !token
  })

  return {
    user,
    userError,
    isUserLoading,
    organizations,
    organizationsError,
    isOrganizationsLoading
  }
}

export default useAuthData
