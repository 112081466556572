import { ADD_NOTIFICATION, RESET_NOTIFICATION } from '../actions/notifications'

const initialNotificationsState = {
  text: '',
  className: '',
  type: ''
}

export const notifications = (state = initialNotificationsState, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        text: action.payload.text,
        className: action.payload.className,
        type: action.payload.type,
        duration: action.payload.duration
      }
    case RESET_NOTIFICATION:
      return initialNotificationsState
    default:
      return state
  }
}
