import React, { useEffect } from 'react'
import MultiPolygon from '../olComponents/geom/MultiPolygon'
import LayerVector from '../olComponents/layervector'
import LayerTile from '../olComponents/layerTile'
import SourceVector from '../olComponents/sourcevector'
import SourceXYZ from '../olComponents/sourceXYZ'
import Feature from '../olComponents/feature'
import { featureEach } from '@turf/meta'
import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import indexOf from 'lodash/indexOf'
import { Fill, Style } from 'ol/style'
import { s3PathToTilerUrl } from '../../tilerUtils'
import GeometryUtils from '../../geometryUtils'
import moment from 'moment'

/*=============================================>>>>>
= Cached styles =
===============================================>>>>>*/
// eslint-disable-next-line prefer-const
let vectorStyleCache = {}
const vectorLayerStyleFunction = (feature, resolution) => {
  let style = {}
  const olFeature = feature.get('field')
  const fillColor = `rgb(${get(olFeature, ['properties', 'color'])})`
  const styleKey = get(olFeature, ['properties', 'color'])

  // eslint-disable-next-line no-prototype-builtins
  if (!vectorStyleCache.hasOwnProperty(styleKey)) {
    const fill = new Fill({ color: fillColor })
    // eslint-disable-next-line object-shorthand
    style = new Style({ fill: fill })

    vectorStyleCache[styleKey] = style
  } else {
    style = vectorStyleCache[styleKey]
  }
  return style
}
/*= End of Cached styles =*/
/*=============================================<<<<<*/
export default function SeasonLayers({
  selectedFlightGroupSeasons,
  selectedFieldsIds,
  selectedFlightGroupSeasonIds,
  seasonLayers,
  projection,
  uiSeasonLayers,
  fetchSeasonLayersBySeasonIds,
  seasonLayersOrderMap,
  seasonLayersBaseZIndex,
  showSeasonLayers
}) {
  // eslint-disable-next-line prefer-const
  let layerToDraw = []
  const seasonLayersLength = uiSeasonLayers.length
  const topVisibleLayer = find(uiSeasonLayers, layer => get(layer, 'visible'))
  const topVisibleLayerZIndex = seasonLayersLength - indexOf(uiSeasonLayers, topVisibleLayer) + seasonLayersBaseZIndex

  useEffect(() => {
    if (selectedFlightGroupSeasonIds && showSeasonLayers) {
      fetchSeasonLayersBySeasonIds(selectedFlightGroupSeasonIds)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldsIds, showSeasonLayers])

  if (!showSeasonLayers || isEmpty(seasonLayers)) return null

  const seasonsLayers = reduce(
    keys(seasonLayers),
    (layersAcc, seasonId) => {
      const layersByDeliverableType = get(seasonLayers, seasonId)

      const fieldLayers = map(keys(layersByDeliverableType), deliverableTypeId => {
        return get(layersByDeliverableType, deliverableTypeId)
      })
      return [...layersAcc, ...fieldLayers]
    },
    []
  )

  forEach(seasonsLayers, (layer, layerIndex) => {
    // eslint-disable-next-line prefer-const
    let featuresToDraw = []
    const deliverableTypeId = get(layer, 'deliverable.deliverableType.id')
    const layerVisibility = find(uiSeasonLayers, ['type.id', deliverableTypeId])
    const layerZIndex = get(seasonLayersOrderMap, deliverableTypeId)

    const resourcePath = get(layer, ['deliverable', 'resourcePath'])
    const isRaster = resourcePath !== null
    const opacity = layerVisibility?.opacity
    const visible = layerVisibility?.visible

    if (!isRaster && layerVisibility) {
      const currentLayerZIndex = seasonLayersBaseZIndex + layerZIndex
      if (layerVisibility.visible) {
        const hasTooltip = topVisibleLayerZIndex === currentLayerZIndex
        featureEach(get(layer, 'deliverable.vector'), (polygonFeature, index) => {
          // eslint-disable-next-line dot-notation
          polygonFeature['properties']['referenceDate'] = moment(get(layer, 'referenceDate')).format('DD/MM/YYYY')
          // eslint-disable-next-line dot-notation
          polygonFeature['properties']['hasTooltip'] = hasTooltip

          featuresToDraw.push(
            <Feature key={`${index}_${layer.fieldId}_SEASON_LAYER`} id={`${index}_${layer.fieldId}_SEASON_LAYER`} field={polygonFeature}>
              <MultiPolygon projection={projection}>{polygonFeature.geometry.coordinates}</MultiPolygon>
            </Feature>
          )
        })
      }

      layerToDraw.push(
        <LayerVector
          visible={visible}
          name={`SeasonLayers_${layerIndex}`}
          zIndex={currentLayerZIndex}
          opacity={opacity}
          key={layerIndex}
          style={vectorLayerStyleFunction}
        >
          <SourceVector>{featuresToDraw}</SourceVector>
        </LayerVector>
      )
    } else if (layerVisibility) {
      const fieldId = get(layer, 'fieldId')
      const layerSeason = find(selectedFlightGroupSeasons, season => get(season, 'fieldId') === fieldId)
      const layerGeometry = get(layerSeason, 'geometry')
      const extent = GeometryUtils.Xtransform(GeometryUtils.geometryToBbox(layerGeometry))

      const layerUrl = s3PathToTilerUrl(resourcePath)
      layerToDraw.push(
        <LayerTile
          visible={visible}
          name={`SeasonLayers_${layerIndex}`}
          zIndex={seasonLayersBaseZIndex + layerZIndex}
          opacity={opacity}
          key={layerIndex}
          extent={extent}
        >
          <SourceXYZ url={layerUrl} minZoom={15} maxZoom={19} />
        </LayerTile>
      )
    }
  })

  return layerToDraw
}
