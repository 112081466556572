import React from 'react'
import styled from 'styled-components'
import FavouriteSelected from '../../assets/img/accounts/favourite-selected.svg'
import Favourite from '../../assets/img/accounts/favourite-default.svg'
import SearchIcon from '../../assets/img/accounts/search.svg'
import { Button, InputAdornment, TextField, IconButton } from '@mui/material'

/**
 * Search component to filter accounts by name and toggle the display of favorite accounts.
 **/

const Search = ({ t, searchTerm, setSearchTerm, showFavorites, setShowFavorites }) => {
  const handleSearch = e => {
    setSearchTerm(e.target.value)
  }

  const handleClearSearch = () => {
    setSearchTerm('')
  }

  const Icon = styled.img`
    width: 25px;
    height: 25px;
    padding: 5px;
  `

  return (
    <div style={{ margin: '32px 8px -8px 8px' }}>
      <TextField
        variant="outlined"
        placeholder="Search by Account Name"
        value={searchTerm}
        onChange={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Icon src={SearchIcon} alt="icon" />
            </InputAdornment>
          ),
          endAdornment: searchTerm ? (
            <InputAdornment position="end">
              <IconButton onClick={handleClearSearch} edge="end" size="small">
                <i className="fas fa-times" style={{ color: '#fff' }} />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
        size="small"
        style={{ marginBottom: '10px', backgroundColor: 'rgba(8, 44, 61, 1)', width: '300px', borderRadius: 8 }} // Optional styles
      />
      <Button
        onClick={() => setShowFavorites(!showFavorites)}
        variant="outlined"
        endIcon={showFavorites ? <Icon src={FavouriteSelected} alt="favourite" /> : <Icon src={Favourite} alt="favourite selected" />}
        style={{
          marginLeft: 16,
          borderRadius: 16,
          borderWidth: 1,
          transition: 'all 0.3s ease',
          color: 'white',
          backgroundColor: showFavorites ? '#0c2c3c' : '#0c2c3c', // Dark blue when active
          borderColor: showFavorites ? 'white' : 'transparent', // White border when active
          fontWeight: showFavorites ? 'normal' : 300 // Normal font weight when active
        }}
      >
        <span style={{ textTransform: 'capitalize' }}>{t('favourites', 'Favourites')}</span>
      </Button>
    </div>
  )
}

export default Search
