import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { withTranslator } from '../HOCComponents/withTranslator'
import StyledButtonGroup from '../UIComponents/StyledButtonGroup'
import { SEARCH_BAR_LIST_MODE, TRACK_EVENTS } from '../../constants'
import { Button, IconButton } from '@mui/material'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { StyledTooltip } from '../UIComponents/StyledTooltip'
import { trackEvent } from '../analytics/analytics'

const GroupItemWrapper = styled.div`
  display: inline-block;
  width: 364px;
  z-index: 2;
  cursor: pointer;
  padding: 10px;
  vertical-align: middle;
  font-family: Roboto;
  letter-spacing: 0.5px;
  font-size: 12px;
  text-transform: capitalize;
`
const HeaderFilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

const SameWidthContainer = styled.div`
  flex-basis: 30%;
  text-align: ${props => props.align || 'center'};
`

const FieldsNumber = styled.h5`
  color: white;
  font-family: Roboto;
  text-align: center;
  margin: auto;
  font-size: 13px;
`

const StyledIcon = styled.i`
  font-size: 20px;
  color: ${props => (props.disabled ? themeColors.grayColor : themeColors.vomitColor)};
`

export const StyledIconButtonWithTooltip = ({ onClick, className, tooltipTitle, html, disabled, type, style, placement }) => (
  <IconButton onClick={onClick} disabled={disabled} type={type}>
    <StyledTooltip title={html || tooltipTitle} placement={placement || 'top'}>
      <StyledIcon className={className} disabled={disabled} style={style} />
    </StyledTooltip>
  </IconButton>
)

const selectAllButtonSx = {
  letterSpacing: '-0.01em',
  textTransform: 'none',
  lineHeight: '120%',
  color: themeColors.whiteColor,
  minWidth: 100,
  padding: '6px 2px',
  background: 'none',
  border: `1px solid ${themeColors.whiteColor}`,
  margin: 0,
  '&:hover': {
    background: themeColors.blueLightGrey
  }
}

const deselectButtonSx = {
  background: themeColors.blueLightGrey
}

export const GroupItem = withTranslator(function GroupItem({
  t,
  title,
  onHandleClick,
  toggleSearchSortBy,
  sortedBy,
  selectAllToggleLabel,
  searchBarListMode,
  setSearchBarListMode
}) {
  const handleButtonClick = ({ target }) => {
    setSearchBarListMode(target.value)
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_LIST_STATUS, {
      location: TRACK_EVENTS.LEFT_SIDEBAR,
      action: 'Filter by tab',
      value: target.value
    })
  }

  const handleSortClick = value => {
    toggleSearchSortBy()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_SORT, { location: TRACK_EVENTS.LEFT_SIDEBAR, action: 'sort', value })
  }

  const selectAllButtonStyles = [selectAllButtonSx, selectAllToggleLabel === 'Deselect' && deselectButtonSx]

  return (
    <GroupItemWrapper>
      <HeaderFilterWrapper>
        <SameWidthContainer>
          <Button onClick={onHandleClick} sx={selectAllButtonStyles}>
            {t(selectAllToggleLabel)}
          </Button>
        </SameWidthContainer>
        <SameWidthContainer>
          {' '}
          <FieldsNumber id="FieldsCounter">{title}</FieldsNumber>
        </SameWidthContainer>
        <SameWidthContainer align="right">
          <StyledIconButtonWithTooltip
            tooltipTitle={t('Sort')}
            onClick={() => handleSortClick(sortedBy)}
            className={`fa-solid ${sortedBy === 'asc' ? 'fa-arrow-down-short-wide' : 'fa-arrow-up-short-wide'}`}
          />
        </SameWidthContainer>
      </HeaderFilterWrapper>
      <StyledButtonGroup selectedButton={searchBarListMode} onChange={handleButtonClick} buttons={SEARCH_BAR_LIST_MODE} />
    </GroupItemWrapper>
  )
})

GroupItem.propTypes = {
  field: PropTypes.object,
  selectField: PropTypes.func
}
