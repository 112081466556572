import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

import { openNewReportManagerModal } from '../../actions/modal'
import ReportManagerButton from '../../components/ToolsComponents/ReportManagerButton'

const mapDispatchToProps = {
  openNewReportManagerModal
}

export default compose(withTranslator, connect(null, mapDispatchToProps))(ReportManagerButton)
