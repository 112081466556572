import React from 'react'
import PropTypes from 'prop-types'
import FieldsLayerContainer from '../../containers/LayersContainers/FieldsLayerContainer'
import GroupDeliverablesLayerContainer from '../../containers/LayersContainers/GroupDeliverablesLayerContainer'
import TemporalGroupComparisonLayerContainer from '../../containers/LayersContainers/TemporalGroupComparisonLayerContainer'

import MeasureToolsLayerContainer from '../../containers/LayersContainers/MeasureToolsLayerContainer'
import { ProspectLayersContainer } from '../../containers/LayersContainers/ProspectLayersContainer'
import OlMap from 'ol/Map'
import SeasonLayersContainer from '../../containers/LayersContainers/SeasonLayersContainer'
import SamplesLayerContainer from '../../containers/LayersContainers/SamplesLayerContainer'
import GlobalLayersContainer from '../../containers/LayersContainers/GlobalLayersContainer'
import ClusterLayerContainer from '../../containers/LayersContainers/ClusterLayerContainer'
import { CLUSTER_ZOOM_THRESHOLD } from '@layers-frontend/commons/constants'

export default class MapLayers extends React.Component {
  render() {
    if (this.props.visible)
      return (
        <div id="MapLayers">
          <FieldsLayerContainer />
          <SeasonLayersContainer />
          <SamplesLayerContainer />
          <GroupDeliverablesLayerContainer />
          <TemporalGroupComparisonLayerContainer />
          <MeasureToolsLayerContainer />
          <ProspectLayersContainer />
          {this.props.zoomLevel >= CLUSTER_ZOOM_THRESHOLD ? <GlobalLayersContainer /> : null}
          {this.props.zoomLevel < CLUSTER_ZOOM_THRESHOLD ? <ClusterLayerContainer /> : null}
        </div>
      )

    return null
  }

  getChildContext() {
    return { map: this.context.map }
  }
}

MapLayers.propTypes = {
  visible: PropTypes.bool
}

MapLayers.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

MapLayers.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
