import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

import GlobalLayersButton from '../../components/ToolsComponents/GlobalLayersButton'
import {
  getIsGlobalLayerLegendOpen,
  getLegendList,
  getSelectedLayerLegend,
  getSelectFilteredFields,
  getKeepSelection,
  getTouchedLayers
} from '../../selectors/globalLayer'
import {
  toggleGlobalLayerLegend,
  selectGlobalLayer,
  toggleLegend,
  toggleSequentialLegendNulls,
  updateSequentialLegend,
  setHighlightedCategory,
  toggleGlobalMapSelection,
  toggleGlobalLayersVisibility,
  toggleKeepSelection,
  resetGlobalLegend
} from '../../actions/globalLayer'
import { isSearchView } from '../../selectors/viewmode'

const mapStateToProps = state => ({
  isGlobalLayerLegendOpen: getIsGlobalLayerLegendOpen(state),
  isSearchView: isSearchView(state),
  legends: getLegendList(state),
  selectedLayer: getSelectedLayerLegend(state),
  selectFilteredFields: getSelectFilteredFields(state),
  keepSelection: getKeepSelection(state),
  touchedLegends: getTouchedLayers(state)
})

const mapDispatchToProps = {
  toggleGlobalLayerLegend,
  selectGlobalLayer,
  toggleLegend,
  updateSequentialLegend,
  setHighlightedCategory,
  toggleSequentialLegendNulls,
  toggleGlobalMapSelection,
  toggleGlobalLayersVisibility,
  toggleKeepSelection,
  resetGlobalLegend
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(GlobalLayersButton)
