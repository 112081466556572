import { compose } from 'redux'
import { connect } from 'react-redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import HarvestPlanningTool from '../../components/DashboardComponents/HarvestPlanningTool'
// eslint-disable-next-line import/no-duplicates
import { fetchPlants } from '../../actions/plants'
import { toggleDashFilter } from '../../actions/dashboard'
import { getPlants, getFieldsPlantsWithMoreThanOnePlant } from '../../selectors/plants'
import { areRequestsLoading } from '../../selectors/ui'
// eslint-disable-next-line import/no-duplicates
import { REQUEST_FIELD_PLANTS } from '../../actions/plants'
import {
  getSelectedHarvestPlanningMultiFieldsSelectIds,
  getHarvestPlanningToolDryMatterRangeMin,
  getHarvestPlanningToolDryMatterRangeMax,
  getHarvestPlanningToolSelectedMetadataKey
} from '../../selectors/dashboard'
import { getFieldsIdsMap } from '../../selectors/fields'

const mapStateToProps = state => ({
  plants: getPlants(state),
  isLoading: areRequestsLoading(state, REQUEST_FIELD_PLANTS),
  fieldsPlants: getFieldsPlantsWithMoreThanOnePlant(state),
  selectedFieldsIds: getSelectedHarvestPlanningMultiFieldsSelectIds(state),
  fieldsIdsMap: getFieldsIdsMap(state),
  rangeMin: getHarvestPlanningToolDryMatterRangeMin(state),
  rangeMax: getHarvestPlanningToolDryMatterRangeMax(state),
  selectedMetadataKey: getHarvestPlanningToolSelectedMetadataKey(state)
})

const mapDispatchToProps = {
  fetchPlants,
  toggleDashFilter
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(HarvestPlanningTool)
