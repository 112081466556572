import { RECEIVE_FIELD_PLANTS, RECEIVE_SAMPLE_IMAGE_URL } from '../actions/plants'

export function plants(state = {}, action = {}) {
  if (action.type === RECEIVE_FIELD_PLANTS) {
    return action.plants
  }
  return state
}

export function plantImage(state = {}, action = {}) {
  if (action.type === RECEIVE_SAMPLE_IMAGE_URL) {
    return {
      ...state,
      url: action.url
    }
  }
  return state
}
