import { connect } from 'react-redux'
import { connectLocale } from '../../components/HOCComponents/connectLocale'
import { DashboardViewerToggler } from '../../components/TopBar/DashboardViewerToggler'
import { compose } from 'redux'
import { getScreenView } from '../../selectors/viewmode'
import { selectFields } from '../../actions/fields'
import { getSelectedFieldsLength } from '../../selectors/fields'
import { getBiLabsUrl } from '../../selectors/users'
import withTranslator from '../../components/HOCComponents/withTranslator'
import { hasRoleDeactivateDashboard } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => {
  return {
    isEditModeView: getScreenView(state).includes('FIELD'),
    selectedFieldsLength: getSelectedFieldsLength(state),
    biLabsUrl: getBiLabsUrl(state),
    hasRoleDeactivateDashboard: hasRoleDeactivateDashboard(state)
  }
}

const mapDispatchToProps = {
  selectFields
}

export default compose(withTranslator, connectLocale, connect(mapStateToProps, mapDispatchToProps))(DashboardViewerToggler)
