import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Column, Row } from '../../DashboardComponents/Layout'
import Placeholder from '../../../assets/img/image-regular.svg'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import TextChips from '../SendFieldsReportModal/TextChips'
import withTranslator from '../../HOCComponents/withTranslator'
import ReportGoToFieldsButtonContainer from '../../../containers/ToolsContainers/ReportGoToFieldsButtonContainer'

import find from 'lodash/find'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'

export const ReportContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection || 'column'};
  margin: 0;
  color: ${themeColors.whiteColor};
  position: relative;
  flex-wrap: wrap;
`

const Image = styled.img`
  min-width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 43vh;
  color: ${themeColors.whiteColor};
`

const FlexRow = styled(Row)`
  border-bottom: 1px solid grey;
`
const FlexColumn = styled(Column)`
  display: flex;
  flex-flow: ${props => props.flexBasis || 'row wrap'};
  flex-basis: ${props => props.flexBasis || 'initial'};
  flex: ${props => props.flex || 'initial'};
  justify-content: center;
  background-color: ${props => props.backgroundColor || 'initial'};
  padding: ${props => props.padding || 'initial'};
  margin: ${props => props.margin || 'initial'};
  border-left: ${props => props.borderLeft || 'initial'};
  align-items: ${props => props.alignItems || 'initial'};
`

const GridColumn = styled(Column)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex: ${props => props.flex || 'initial'};
  padding: ${props => props.padding || 'initial'};
  margin: ${props => props.margin || 'initial'};
`

const MiniCardContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: ${props => props.width};
  grid-area: ${props => (props.isEmptyRecipients ? '1 / 2 / 3 / auto' : 'initial')};
  padding-bottom: 3rem;
`
// TITLE
const MiniCardTitleIcon = styled.div`
  color: ${themeColors.lightBlue};
`
const MiniCardTitleText = styled.div`
  padding-left: 1.5rem;
  color: ${themeColors.white};
`

const MiniCardTitleUnderline = styled.div`
  display: flex;
  padding: 0 7px 2px 0;
  border-bottom: 1px solid;
`
const MiniCardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 1.5rem;
  align-items: baseline;
  justify-content: space-between;
`

const MiniCardTitle = ({ icon, text, children }) => {
  return (
    <MiniCardTitleContainer>
      <MiniCardTitleUnderline>
        <MiniCardTitleIcon className={icon} />
        <MiniCardTitleText>{text}</MiniCardTitleText>
      </MiniCardTitleUnderline>
      {children}
    </MiniCardTitleContainer>
  )
}

// CONTENT
const MiniCardContentContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  margin-right: 2rem;
  max-height: ${props => (props.isEmptyRecipients ? '30vh' : '20vh')};
`

// KEY VALUE CONTENT
const MiniCardKeyValueItemLabel = styled.label``
const MiniCardKeyValueItemText = styled.label`
  font-weight: normal;
`
const MiniCardKeyValueItem = ({ label, text }) => {
  return !text ? null : (
    <>
      <Column width="50%" padding="0px">
        <MiniCardKeyValueItemLabel>{label}:</MiniCardKeyValueItemLabel>
      </Column>
      <Column width={'50%'}>
        <MiniCardKeyValueItemText>{text}</MiniCardKeyValueItemText>
      </Column>
    </>
  )
}
const MiniCardKeyValue = ({ t, data, keys }) => {
  const list = map(Object.keys(keys), key => {
    return {
      label: t(keys[key]),
      text: t(data[key])
    }
  })

  return (
    <>
      {map(list, item => (
        <MiniCardKeyValueItem key={item.label} label={item.label} text={item.text} />
      ))}
    </>
  )
}

// CHIPS CONTENT

const MiniCardChips = ({ list, isSelectedField, onChipClick }) => {
  return (
    <TextChips
      chips={list}
      onChipClick={onChipClick}
      isSelectedField={isSelectedField}
      style={{ margin: 4, backgroundColor: themeColors.blueLightHover }}
      avatarStyle={{
        borderColor: themeColors.lightBlue,
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: 'transparent'
      }}
    />
  )
}

// PARAGRAPH CONTENT
const MiniCardText = styled.p`
  text-justify: inter-word;
  text-align: justify;
`

const infoKeys = {
  author: 'author',
  status: 'status',
  typeName: 'report type',
  affectedHa: 'Affected Surface',
  relatedDate: 'Related date',
  updateDate: 'updated',
  creationDate: 'created'
}

const MiniCard = ({ t, icon, title, content, width = '100%', height = '100%', isFieldsColumn = false, fieldsIds = [], isEmptyRecipients }) => {
  return (
    <MiniCardContainer width={width} height={height} isEmptyRecipients={isEmptyRecipients}>
      <MiniCardTitle icon={icon} text={title}>
        {isFieldsColumn && <ReportGoToFieldsButtonContainer fieldsIds={fieldsIds} />}
      </MiniCardTitle>
      <MiniCardContentContainer isEmptyRecipients={isEmptyRecipients}>{content}</MiniCardContentContainer>
    </MiniCardContainer>
  )
}

const Screenshot = ({ source }) => {
  return <div>{source ? <Image src={String(source)} /> : <Image src={Placeholder} />}</div>
}

const ReportCard = ({ t, data }) => {
  const [selectedFields, setSelectedFields] = useState([])
  const [selectedDataFields, setSelectedDataFields] = useState([])

  function onChipClick(chip) {
    const id = chip.id
    const newSet = new Set(selectedFields)
    if (newSet.has(id)) newSet.delete(id)
    else newSet.add(id)
    setSelectedFields([...newSet])
  }

  const isSelectedField = chip => selectedFields.includes(chip.id)

  const getFieldIds = arr => arr.map(item => item.id)

  useEffect(() => {
    // eslint-disable-next-line object-shorthand
    const selectedDataFields = selectedFields.map(id => find(data.fields, { id: id }))
    setSelectedDataFields(selectedDataFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFields])

  return (
    <FlexRow>
      <GridColumn flex={2} padding={'2rem'} margin={'0 2rem 0 0'}>
        <MiniCard icon={'fas fa-info-circle'} title={t('Info')} content={<MiniCardKeyValue t={t} data={data} keys={infoKeys} />} />
        <MiniCard
          icon={'fas fa-draw-polygon'}
          title={t('fields')}
          isEmptyRecipients={!data.recipients}
          isFieldsColumn
          fieldsIds={selectedDataFields.length ? getFieldIds(selectedDataFields) : getFieldIds(data.fields)}
          content={<MiniCardChips list={data.fields} onChipClick={onChipClick} isSelectedField={isSelectedField} />}
        />
        <MiniCard icon={'fas fa-clipboard'} title={capitalize(t('notes'))} content={<MiniCardText>{data.notes}</MiniCardText>} />
        {data.recipients && <MiniCard icon={'fas fa-envelope'} title={t('Recipients')} content={<MiniCardChips list={data.recipients} />} />}
      </GridColumn>
      <FlexColumn flex={1.5} flexFlow={'column wrap'} alignItems={'center'} margin={'0 0 2rem 0'} padding={'2rem'} borderLeft={'1px solid gray'}>
        <Screenshot source={data.screenshotPath} />
      </FlexColumn>
    </FlexRow>
  )
}
export default withTranslator(ReportCard)
