import React from 'react'
import { Avatar, Chip } from '@mui/material'
import { UIStyles } from '../../../theme'
import isEmpty from 'lodash/isEmpty'
import flow from 'lodash/flow'
import first from 'lodash/first'
import toUpper from 'lodash/toUpper'
import truncate from 'lodash/truncate'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
`

const chipStyle = {
  margin: 4,
  backgroundColor: UIStyles.darkBlue
}

const defaultAvatarStyle = {
  borderColor: UIStyles.lightBlue,
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: themeColors.blueLightHover,
  transition: '100ms linear'
}

const selectedChipStyle = {
  margin: 4,
  backgroundColor: themeColors.fluoOrangeColor,
  transition: '300ms'
}

const selectedAvatarStyle = {
  borderColor: UIStyles.whiteColor,
  borderWidth: '1px',
  borderStyle: 'solid',
  backgroundColor: themeColors.fluoOrangeColor,
  transition: '100ms linear'
}

export default function TextChips({ chips, removeChip, chipMaxLength = 20, style = chipStyle, onChipClick, isSelectedField }) {
  if (!isEmpty(chips)) {
    return (
      <ChipsWrapper>
        {chips.map((chip, index) => {
          const initialLetter = flow(toUpper, first)(chip.name ? chip.name : chip)
          const chipText = truncate(chip.name ? chip.name : chip, { length: chipMaxLength })
          const isSelected = isSelectedField && isSelectedField(chip)
          return (
            <Chip
              label={chipText}
              key={index}
              style={isSelected ? selectedChipStyle : style}
              onClick={onChipClick ? () => onChipClick(chip) : undefined}
              onDelete={removeChip ? () => removeChip(chip) : undefined}
              avatar={
                <Avatar style={isSelected ? selectedAvatarStyle : defaultAvatarStyle} size={32}>
                  {initialLetter}
                </Avatar>
              }
            />
          )
        })}
      </ChipsWrapper>
    )
  }

  return null
}
