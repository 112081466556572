import React from 'react'
import { compose } from 'redux'
import { withTranslator } from '../HOCComponents/withTranslator'
import withLocale from '../HOCComponents/withLocale'
import { USER_CONFIGURATION_OPTIONS, USER_CONFIGURATION_OPTIONS_INPUT_TYPE } from '@layers-frontend/commons/constants'
import StyledButtonGroup from '../UIComponents/StyledButtonGroup'
import map from 'lodash/map'
import styled from 'styled-components'
import StyledSelect from '../UIComponents/StyledSelect'

const TextLabel = styled.p`
  color: white;
  font-size: 12px;
`

const UserConfiguration = ({ t, patchConfig, config }) => {
  return (
    <div style={{ padding: '20px' }}>
      {map(USER_CONFIGURATION_OPTIONS, (settings, key) => {
        const configType = USER_CONFIGURATION_OPTIONS_INPUT_TYPE[key]
        switch (configType) {
          case 'select':
            return (
              <div key={key} style={{ marginBottom: '15px' }}>
                <TextLabel>{t(key)}</TextLabel>
                <StyledSelect
                  menuProps={{ disablePortal: true }}
                  renderValue={value => {
                    const index = settings.options.findIndex(item => item.value === value)
                    return index !== -1 ? settings.options[index].label : 'en'
                  }}
                  inputStyle={{ color: 'white' }}
                  options={settings.options}
                  value={config.language}
                  labelSelector={'label'}
                  valueSelector={'value'}
                  onChange={e => patchConfig(key, e)}
                />
              </div>
            )
          case 'buttonGroup':
            return (
              <div key={key} style={{ marginBottom: '15px' }}>
                <StyledButtonGroup
                  fullWidth={false}
                  selectedButton={config[key]}
                  buttons={settings}
                  onChange={e => patchConfig(key, e.target.value)}
                  textLabel={t(key)}
                />
              </div>
            )

          default:
            return null
        }
      })}
    </div>
  )
}

export default compose(withTranslator, withLocale)(UserConfiguration)
