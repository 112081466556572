import { RECEIVE_AZURE_CLIENT } from '../storeConstants'

export const user_azure = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_AZURE_CLIENT:
      return action.payload
    default:
      return state
  }
}
