import { connect } from 'react-redux'
import MeasureToolsLayer from '../../components/LayersComponents/MeasureToolsLayer'

import { getEditorPresentData } from '../../selectors/editor'

const mapStateToProps = state => ({
  isMeasureToolsActive: getEditorPresentData(state, 'isMeasureToolsActive')
})

export default connect(mapStateToProps)(MeasureToolsLayer)
