import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useIsAuthenticated, useMsal } from '@azure/msal-react'

// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ logout }) {
  const { instance } = useMsal()
  const isAuthenticated = useIsAuthenticated()
  useEffect(() => {
    isAuthenticated &&
      instance.logoutRedirect({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/' // redirects the top level app after logout
      })
    logout()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !isAuthenticated ? <Navigate to="/" replace /> : null
}
