import styled from 'styled-components'
import { UIStyles } from '../../theme'
//------------------------------------- HEADER -------------------------------------------------
export const TitleH1 = styled.h1`
  color: ${UIStyles.lightBlue};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  font-family: 'Roboto';
  padding: 0px 20px;
  font-weight: normal;
  font-size: 30px;
  margin-bottom: 25px;
`
export const TitleH2 = styled.h2`
  color: ${UIStyles.lightBlue};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  font-family: 'Roboto';
  padding: 0px 20px;
  font-weight: normal;
  font-size: 18px;
`

/* eslint-disable no-unneeded-ternary */
export const ChartTitle = styled(TitleH2)`
  margin: 0;
  padding: 0;
  text-align: inherit;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : '15px')};
`
/* eslint-enable no-unneeded-ternary */

export const ChartSubTitle = styled.h3`
  font-size: 13px;
  margin: 0;
  margin-bottom: 10px;
  padding: 0;
  color: ${UIStyles.grayColor};
`

export const TabTitle = styled(TitleH1)`
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
`
//------------------------------------- CONTENT -------------------------------------------------

export const ComponentWrapper = styled.div`
  width: calc(95% - 25px);
  display: flex;
  position: relative;
`

export const FullSizeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 20px;
  padding-bottom: ${props => props.paddingBottom || '20px'};
  color: ${UIStyles.whiteColor};
  position: relative;
`
/* eslint-disable no-unneeded-ternary */
export const Row = styled.div`
  display: flex;
  margin-left: -15px;
  margin-right: -15px;
  text-align: ${({ textCentered }) => (textCentered ? 'center' : 'inherit')};
  margin-bottom: ${({ noMarginBottom, marginBottom }) => (noMarginBottom ? 0 : marginBottom ? marginBottom : '30px')};
  flex-wrap: wrap;
`
/* eslint-enable no-unneeded-ternary */

export const Column = styled.div`
  padding: 0 15px;
  width: ${({ width }) => width || '100%'};
  margin-bottom: ${({ marginBottom }) => marginBottom || 'initial'};
  transition: ${({ transition }) => transition || 'initial'};
`

export const QuarterColumn = styled(Column)`
  width: 25%;
`
export const HalfColumn = styled(Column)`
  width: 50%;
`

export const FullWidthColumn = styled(Column)`
  width: 100%;
`

export const BoxContainer = styled.div`
  background-color: ${UIStyles.darkBlue};
  border: ${props => (props.borderNone ? 'none' : `1px solid ${UIStyles.lightGrey}`)};
  border-radius: 3px;
  padding: ${props => (props.padding ? props.padding : '15px')};
  height: ${props => (props.height ? props.height : '100%')};
  width: ${props => props.width && props.width};
  margin: ${props => (props.margin ? props.margin : 0)};
  max-height: ${props => props.maxHeight || 'initial'};
  overflow: ${props => props.overflow || 'initial'};
  display: flex;
  flex-direction: column;
`

//------------------------------------- TABLES -------------------------------------------------

export const TableTitle = styled(TitleH2)`
  padding: 0;
`

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #fff;
`
export const TableHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${UIStyles.darkBlue};
  margin: 20px;
  margin-bottom: -20px;
  width: 100%;
  padding: 15px 20px;
`
export const TableRowItem = styled.div`
  color: ${props => (props.color ? props.color : '#fff')};
  font-weight: ${props => (props.color ? '700' : '400')};
  padding: 5px;
  width: ${props => (props.width ? props.width : props.color ? '40%' : '30%')};
  overflow: hidden;
`
