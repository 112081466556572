import {
  SAT_SNAPSHOT_SOURCE,
  RADAR_ASC_SNAPSHOT_SOURCE,
  RADAR_DESC_SNAPSHOT_SOURCE,
  LANDSAT_SNAPSHOT_SOURCE,
  PLANET_SNAPSHOT_SOURCE
} from '@layers-frontend/commons/constants'

import { createSelector } from 'reselect'

import { getSeasonsByIds } from './seasons'

import moment from 'moment'

import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import first from 'lodash/fp/first'
import map from 'lodash/fp/map'
import orderBy from 'lodash/orderBy'
import uniqBy from 'lodash/fp/uniqBy'
import isEqual from 'lodash/fp/isEqual'
import reduce from 'lodash/fp/reduce'
import union from 'lodash/fp/union'
import reverse from 'lodash/fp/reverse'
import toNumber from 'lodash/fp/toNumber'
import { getTempComparisonIndex } from './calendar'
import findIndex from 'lodash/fp/findIndex'

export const getFlightGroups = get('flightGroups')

export const getFlightGroupsLength = pipe(getFlightGroups, get('length'))

export const getSelectedFlightGroup = pipe(getFlightGroups, find('selected'))

export const getSelectedFlightGroupIndex = pipe(getFlightGroups, findIndex('selected'))

export const getFlightGroupDates = pipe(
  getFlightGroups,
  map(flightGroup => {
    const value = get('flights[0].date')(flightGroup)
    const name = moment(value).format('DD MMM YY')

    return { value, name }
  })
)

export const getSelectedFlightGroupFlights = pipe(getSelectedFlightGroup, get('flights'))

export const getSelectedFlightGroupFirstFlight = pipe(getSelectedFlightGroupFlights, first)

export const getSelectedFlightGroupFirstFlightDate = pipe(getSelectedFlightGroupFirstFlight, get('date'))

const getFlightGroupSeasonIds = flights =>
  reduce((accumulator, flight) => {
    const seasons = get('seasons')(flight)
    const seasonIds = map('id')(seasons)
    return union(accumulator, seasonIds)
  }, [])(flights)

export const getSelectedFlightGroupSeasonIds = pipe(getSelectedFlightGroupFlights, getFlightGroupSeasonIds, map(toNumber))

export const getSelectedFlightGroupSeasons = state => getSeasonsByIds(getSelectedFlightGroupSeasonIds(state))(state)

export const getOrderedFlightGroupDates = pipe(getFlightGroupDates, dates => orderBy(dates, 'value', 'desc'), uniqBy('value'))

export const getSelectedFlightGroupsDate = pipe(getSelectedFlightGroup, get('flights[0].date'))

export const getSelectedFlightGroupDates = pipe(getSelectedFlightGroupFlights, map('date'))

export const getSelectedFlightGroupDeliverables = pipe(getSelectedFlightGroup, get('deliverables'))

export const getSelectedFlightGroupFirstVisibleDeliverable = pipe(getSelectedFlightGroupDeliverables, reverse, find(get('visible')))

export const getSelectedFlightGroupPdfDeliverables = pipe(getSelectedFlightGroup, get('pdfDeliverables'))

export const getSelectedFlightGroupDeliverablesTypes = pipe(getSelectedFlightGroupDeliverables, map('type'))

export const getSelectedFlightGroupSource = pipe(getSelectedFlightGroup, get('flights[0].source'))

export const getSelectedFlightGroupActiveLayers = pipe(getSelectedFlightGroup, get('activeLayers'))

export const isSelectedFlightGroupSatType = state => {
  const selectedFlightGroupSource = getSelectedFlightGroupSource(state)

  return (
    isEqual(SAT_SNAPSHOT_SOURCE)(selectedFlightGroupSource) ||
    isEqual(PLANET_SNAPSHOT_SOURCE)(selectedFlightGroupSource) ||
    isEqual(LANDSAT_SNAPSHOT_SOURCE)(selectedFlightGroupSource)
  )
}

export const isSelectedFlightGroupRadarType = state => {
  const selectedFlightSource = getSelectedFlightGroupSource(state)
  return includes(selectedFlightSource)([RADAR_ASC_SNAPSHOT_SOURCE, RADAR_DESC_SNAPSHOT_SOURCE])
}

// file generator
export const getSelectedFlightGroupSatDeliverablesFileGeneratorBaseLayers = (state, key) => {
  const types = map('type')(getSelectedFlightGroupDeliverables(state))
  const availableBaseDeliverableTypes = filter(type => type[key] === true)(types)
  return availableBaseDeliverableTypes
}

export const getSelectedFlightGroupDroneDeliverablesFileGeneratorBaseLayers = state => {
  return map('type')(getSelectedFlightGroupDeliverables(state))
}

export const getSelectedSeasonsAvailableSatBaseFileNames = createSelector([getSelectedFlightGroupSeasons, getSelectedFlightGroupSource], (seasons, source) => {
  return reduce(
    (accumulator, season) => ({
      ...accumulator,
      [season.id]: map('type.base_file_name')(get([source, 'deliverables'])(get('deliverables')(season)))
    }),
    {}
  )(seasons)
})

// TEMOPRAL cOMPARISON
export const getTemporalComparisonFlightGroup = state => pipe(getFlightGroups, get(getTempComparisonIndex(state)))(state)

export const getTemporalComparisonFlightGroupFlights = pipe(getTemporalComparisonFlightGroup, get('flights'))

const getTemporalComparisonFlightGroupSeasonIds = pipe(getTemporalComparisonFlightGroupFlights, getFlightGroupSeasonIds)

export const getTemporalComparisonFlightGroupSeasons = state => getSeasonsByIds(getTemporalComparisonFlightGroupSeasonIds(state))(state)

export const getTemporalComparisonFlightGroupDeliverables = pipe(getTemporalComparisonFlightGroup, get('deliverables'))

export const getTemporalComparisonFlightGroupDates = pipe(getTemporalComparisonFlightGroupFlights, map('date'))

export const getTemporalComparisonFlightGroupSource = pipe(getTemporalComparisonFlightGroup, get('flights[0].source'))
