// eslint-disable-next-line no-unused-vars
import React, { useEffect } from 'react'
import { advancedSearch } from '../constants'

import getFp from 'lodash/fp/get'
import mapFp from 'lodash/fp/map'
import orderByFp from 'lodash/fp/orderBy'
import pipeFp from 'lodash/fp/pipe'
import reduceFp from 'lodash/fp/reduce'
import uniqByFp from 'lodash/fp/uniqBy'
import filterFp from 'lodash/fp/filter'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import map from 'lodash/map'

import { cutNumberReducer } from '@layers-frontend/commons/helpers/reducer/cutNumberReducer'

const _cutNumberReduce = reduceFp((cutOptions, field) => {
  const cutNumber = getFp('season.parameters.cutNumber.value')(field)
  return cutNumberReducer({ cutNumber, cutOptions })
}, [])

export const DataManager = ({
  activeDate,
  isViewerDataLoaded,
  setSearchOptions,
  fieldsWithInitialSeason,
  setMeteorologicalStatsAvailableFields,
  isDashboardLoaded,
  fieldsUpdatedAt,
  shouldResetFilters
}) => {
  // calculate reducer searchOptions
  useEffect(() => {
    if (isViewerDataLoaded && shouldResetFilters) {
      const searchOptions = {
        [advancedSearch.TYPE_FILTER]: pipeFp(
          mapFp(field => ({ id: getFp('season.type.id')(field), name: getFp('season.type.name')(field) })),
          uniqByFp('id'),
          orderByFp('name', 'asc')
        )(fieldsWithInitialSeason),
        [advancedSearch.CUSTOMER_FILTER]: pipeFp(
          mapFp(field => ({ id: field.customer.id, name: field.customer.name })),
          uniqByFp('id'),
          orderByFp('name', 'asc')
        )(fieldsWithInitialSeason),
        [advancedSearch.NAME_FILTER]: pipeFp(
          mapFp(field => ({ id: field.id, name: getFp('name')(field) })),
          orderByFp('name', 'asc')
        )(fieldsWithInitialSeason),
        [advancedSearch.CUT_NUMBER_FILTER]: pipeFp(
          _cutNumberReduce,
          mapFp(cutNumber => ({ id: cutNumber, name: cutNumber, cutNumber: Number(cutNumber) })),
          orderByFp('cutNumber', 'asc')
        )(fieldsWithInitialSeason),
        [advancedSearch.SEASON_LABEL_FILTER]: pipeFp(
          mapFp(field => ({ id: getFp('season.label')(field), name: getFp('season.label')(field) })),
          uniqByFp('name'),
          filterFp('name'),
          orderByFp('name', 'asc')
        )(fieldsWithInitialSeason)
      }
      setSearchOptions(searchOptions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate, isViewerDataLoaded, fieldsUpdatedAt, fieldsWithInitialSeason])

  useEffect(() => {
    if (isDashboardLoaded && !isEmpty(fieldsWithInitialSeason)) {
      const fieldsIdsWithGeometry = map(filter(fieldsWithInitialSeason, 'season.geometry'), field => `${field.id}`)

      setMeteorologicalStatsAvailableFields(fieldsIdsWithGeometry)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDashboardLoaded, fieldsWithInitialSeason, fieldsUpdatedAt])
  return null
}
