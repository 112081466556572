import React from 'react'
import withTranslator from '../HOCComponents/withTranslator'

import capitalize from 'lodash/capitalize'
import { TextField } from '@mui/material'
import noop from 'lodash/noop'
import isObject from 'lodash/isObject'

const preventSubmitOnEnter = e => {
  e.key === 'Enter' && e.preventDefault()
}

const StyledTextInput = ({
  t,
  name,
  value,
  label,
  onChange,
  onBlur = noop,
  margin = 'normal',
  fullWidth = true,
  placeholder,
  inputProps = {},
  helperText = '',
  onKeyPress = noop,
  autoFocus = false,
  textFieldStyle = {},
  InputLabelProps = {},
  InputProps = {},
  disabled,
  type = 'text',
  id,
  size = 'normal'
}) => {
  return (
    <TextField
      id={id}
      name={name}
      value={value || ''}
      label={isObject(label) ? label : capitalize(t(label))}
      helperText={capitalize(helperText)}
      onChange={e => onChange(e.target.value)}
      onBlur={onBlur}
      style={textFieldStyle}
      fullWidth={fullWidth}
      onKeyPressCapture={preventSubmitOnEnter}
      margin={margin}
      type={type}
      placeholder={placeholder}
      inputProps={inputProps}
      onKeyDown={onKeyPress}
      autoFocus={autoFocus}
      disabled={disabled}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      size={size}
    />
  )
}

export default withTranslator(StyledTextInput)
