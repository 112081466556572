import { fetchGraphqlWrapper } from './ui'
import { loader } from 'graphql.macro'

export const REQUEST_FIELDS_SEASONS_TIMELINE = 'REQUEST_FIELDS_SEASONS_TIMELINE'
export const RECEIVE_FIELDS_SEASONS_TIMELINE = 'RECEIVE_FIELDS_SEASONS_TIMELINE'

const getFieldsSeasonsTimeline = loader('../graphql/seasons/getFieldsSeasonsTimeline.gql').loc.source.body

export const fetchFieldsSeasonsTimeline = () => dispatch => {
  return dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_SEASONS_TIMELINE,
      query: getFieldsSeasonsTimeline,
      onSuccess: ({ fields }) => ({ type: RECEIVE_FIELDS_SEASONS_TIMELINE, fields })
    })
  )
}
