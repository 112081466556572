import React from 'react'
import styled from 'styled-components'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { ToolTitle } from './LayoutComponents'
import { UIStyles } from '../../../theme'

const Button = styled.button`
  background-color: transparent;
  outline: none;
  border: none;
  display: flex;
  align-items: baseline;
  &:hover > *:not(i) {
    text-decoration: underline;
  }

  i {
    display: inline-block;
    margin-right: 1.5rem;
    font-size: 2.5rem;
    color: ${UIStyles.whiteColor};
  }
`

function CaptureScreenshotButton({ t, onClick, visible }) {
  if (!visible) return null

  return (
    <Button onClick={onClick}>
      <i className="fas fa-camera" />
      <ToolTitle>{t('Capture screenshot')}</ToolTitle>
    </Button>
  )
}

export default withTranslator(CaptureScreenshotButton)
