import { connect } from 'react-redux'
import FileGenerator from '../../components/FileGenerator'
import { fetchLayerClasses, downloadLayer, downloadHistogram } from '../../actions/fileGenerator'
import {
  getSelectedFlightGroupDroneDeliverablesFileGeneratorBaseLayers,
  getSelectedFlightGroupFlights,
  getSelectedFlightGroupSeasonIds,
  isSelectedFlightGroupRadarType,
  isSelectedFlightGroupSatType,
  getSelectedFlightGroupSatDeliverablesFileGeneratorBaseLayers
} from '../../selectors/flightGroups'
import { getFieldsNamesByIds, getSelectedFieldsIds, getBaseFileNamesBySeasonId } from '../../selectors/fields'
import { getSeasonLayers, getSelectedFieldsAvailableSeasonBaseLayers } from '../../selectors/seasonLayers'
import { getLocale } from '../../selectors/i18n'
import { isSeasonLayerTabActive } from '../../selectors/ui'
import { getUserConfigSurface } from '@layers-frontend/commons/store/selectors/user'
import { getFileGeneratorPlotData } from '../../selectors/fileGenerator'

const mapStateToProps = state => {
  const selectedFieldsIds = getSelectedFieldsIds(state)
  const isSatOrRadarSelected = isSelectedFlightGroupSatType(state) || isSelectedFlightGroupRadarType(state)

  return {
    fieldIds: selectedFieldsIds,
    selectedFlightGroupSeasonIds: getSelectedFlightGroupSeasonIds(state),
    baseFileNamesBySeasonId: getBaseFileNamesBySeasonId(state),
    fieldsNames: getFieldsNamesByIds(state)(selectedFieldsIds),
    selectedFieldsAvailablePdfBaseLayers: getSelectedFlightGroupSatDeliverablesFileGeneratorBaseLayers(state, 'fg_pdf'),
    selectedFieldsAvailableShpBaseLayers: getSelectedFlightGroupSatDeliverablesFileGeneratorBaseLayers(state, 'fg_shp'),
    selectedFieldsAvailablePdfSeasonLayers: getSelectedFieldsAvailableSeasonBaseLayers(state, 'fg_pdf'),
    selectedFieldsAvailableShpSeasonLayers: getSelectedFieldsAvailableSeasonBaseLayers(state, 'fg_shp'),
    selectedFieldsAvailableDroneBaseLayers: !isSatOrRadarSelected ? getSelectedFlightGroupDroneDeliverablesFileGeneratorBaseLayers(state) : [],
    seasonLayers: getSeasonLayers(state),
    isSeasonLayersTabActive: isSeasonLayerTabActive(state),
    selectedFlightGroupFlights: getSelectedFlightGroupFlights(state),
    language: getLocale(state),
    surfaceUnit: getUserConfigSurface(state),
    fileGeneratorPlot: getFileGeneratorPlotData(state),
    isSatOrRadarSelected
  }
}

const mapDispatchToProps = {
  fetchLayerClasses,
  downloadLayer,
  downloadHistogram
}

export default connect(mapStateToProps, mapDispatchToProps)(FileGenerator)
