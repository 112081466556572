import get from 'lodash/get'
import each from 'lodash/each'
import { VEGETATION_GROWTH_ADDEND } from './constants'

const keysToPercentify = ['overs_perc', 'overs_pl', 'fat_perc', 'alert_perc', 'weeds_perc']

export const sigma0ToVegGrowth = value => (value ? +value + VEGETATION_GROWTH_ADDEND : null)

export const percentifyMetadataProperties = properties => {
  each(keysToPercentify, key => {
    if (get(properties, key)) {
      properties[key] = Number(get(properties, key)) * 100
    }
  })
  return properties
}

export const findLastTimeCoordinateInPast = data => {
  let lastTimeCoordinateInPast = null

  for (const time in data) {
    if (data[time].isFuture === true) {
      break
    }

    lastTimeCoordinateInPast = time
  }

  return lastTimeCoordinateInPast
}
