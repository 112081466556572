import { TOGGLE_TUTORIAL, CHANGE_TUTORIAL_STEP, SET_TUTORIAL, ADD_FIELD_TUTORIAL } from '../actions/tutorial'
import { POLYGON_ADD, SLICE, POLYGON_MOVE } from '../actions/editor'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

const initialTutorialState = {
  isOpen: false,
  steps: 1,
  name: null
}

export const tutorial = (state = initialTutorialState, action) => {
  switch (action.type) {
    case TOGGLE_TUTORIAL:
      if (state.isOpen) return initialTutorialState
      else
        return {
          ...state,
          isOpen: true
        }
    case CHANGE_TUTORIAL_STEP:
      return {
        ...state,
        steps: action.payload
      }
    case SET_TUTORIAL:
      return {
        ...state,
        name: action.payload
      }
    // SPECIAL CASE FOR DRAWING TUTORIAL -> WE CHANGE STEP DIRECTLY HERE LISTENING TO THE POLYGON ADD ACTION
    case POLYGON_MOVE:
      if (state.isOpen && toNumber(state.steps) === 7)
        return {
          ...state,
          steps: state.steps + 1
        }
      else return state

    case SLICE:
      if (state.isOpen && toNumber(state.steps) === 6)
        return {
          ...state,
          steps: state.steps + 1
        }
      else return state

    // for add/edit field we had to do some special conditions for slicing cause slicing trigger many polygon_add

    case POLYGON_ADD:
      if (state.isOpen)
        if (toString(state.name) === ADD_FIELD_TUTORIAL && state.steps >= 6) return state
        else
          return {
            ...state,
            steps: state.steps + 1
          }
      else return state
    default:
      return state
  }
}
