import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import FieldsComparisonCharts from '../../components/DashboardComponents/FieldsComparisonCharts'
// eslint-disable-next-line import/no-duplicates
import { getFieldComparisonOrderByDate, getSelectedFlightFilterId } from '../../selectors/dashboard'
import { getFieldsFilteredByView } from '../../selectors/availableFields'
import { getFieldComparisonStatistics } from '../../selectors/statistics'
import { getSelectedFieldsLength } from '../../selectors/fields'
import { getUiTemporalEvolutionLayout } from '../../selectors/ui'
// eslint-disable-next-line import/no-duplicates
import { getDashboardFormFilter } from '../../selectors/dashboard'
import { DASHBOARD_FILTERS_TYPES } from '../../actions/dashboard'

import { fetchFieldComparisonStatistics, FETCH_LATEST_STATISTIC_BY_FIELD_IDS } from '../../actions/statistics'
import { areRequestsLoading } from '@layers-frontend/commons/store/selectors/requests'

const { FIELDS_COMPARISON_DATE_RANGE_SELECT } = DASHBOARD_FILTERS_TYPES

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => ({
  selectedFields: getFieldsFilteredByView({ fieldsWithAvailableAndSelected })(state),
  selectedFieldsLength: getSelectedFieldsLength(state),
  selectedFlightFilterId: getSelectedFlightFilterId(state),
  chartsLayout: getUiTemporalEvolutionLayout(state),
  fieldComparisonStatistics: getFieldComparisonStatistics(state),
  fieldComparisonFetchDateRange: getDashboardFormFilter(state, `${FIELDS_COMPARISON_DATE_RANGE_SELECT}.value`),
  fieldComparisonOrder: getFieldComparisonOrderByDate(state),
  isLoading: areRequestsLoading(state, FETCH_LATEST_STATISTIC_BY_FIELD_IDS)
})

const mapDispatchToProps = { fetchFieldComparisonStatistics }

export default compose(withFieldsAvailableAndSelected, withTranslator, connect(mapStateToProps, mapDispatchToProps))(FieldsComparisonCharts)
