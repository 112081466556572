import OlStyle from 'ol/style/Style'

import OlFillStyle from 'ol/style/Fill'
import OlRegularShapeStyle from 'ol/style/RegularShape'
import OlStrokeStyle from 'ol/style/Stroke'
import OlTextStyle from 'ol/style/Text'
import OlIconStyle from 'ol/style/Icon'

const STYLE_KEY_FACTORIES = {
  icon: value => new OlIconStyle(value),
  fill: value => new OlFillStyle(value),
  image: value => new OlRegularShapeStyle(value),
  stroke: value => new OlStrokeStyle(value),
  text: value => new OlTextStyle(value)
}

export function buildStyle(style) {
  if (!style) {
    return null
  }

  if (Array.isArray(style)) {
    return style.map(buildStyle)
  }

  if (typeof style === 'function') {
    return style
  }

  // eslint-disable-next-line no-prototype-builtins
  if (OlStyle.prototype.isPrototypeOf(style)) {
    return style
  }

  const result = {}

  Object.keys(STYLE_KEY_FACTORIES)
    .filter(key => !!style[key])
    .forEach(key => {
      Object.keys(style[key]).map(function (objectKey, index) {
        // eslint-disable-next-line no-var
        var value = style[key][objectKey]

        if (value instanceof Object && !Array.isArray(value)) {
          style[key][objectKey] = STYLE_KEY_FACTORIES[objectKey](value)
        }
        return null
      })

      // eslint-disable-next-line prefer-const
      let newKey = key === 'icon' ? 'image' : key
      result[newKey] = STYLE_KEY_FACTORIES[key](style[key])
    })

  return new OlStyle(result)
}
