import isStringFp from 'lodash/fp/isString'
import union from 'lodash/union'

export const cutNumberReducer = ({ cutNumber, cutOptions }) => {
  if (cutNumber === '') {
    return cutOptions
  }

  if (isStringFp(cutNumber) && cutNumber.toUpperCase() === 'R') {
    return union(cutOptions, ['R'])
  }

  const castedToNumber = Number(cutNumber)
  if (castedToNumber > -1) {
    return union(cutOptions, [String(castedToNumber)])
  }

  return cutOptions
}
