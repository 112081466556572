import { connect } from 'react-redux'
import SelectedFieldsCalendar from '../../components/FieldsComponents/SelectedFieldsCalendar'
import { compareToFlightGroup, selectFields, selectFlightGroup } from '../../actions/fields'
import { getFlightGroups, getSelectedFlightGroupFirstFlightDate, getSelectedFlightGroupSource } from '../../selectors/flightGroups'
import { getDroneFlightsSnapshotsDates, getSnapshots } from '../../selectors/snapshots'
import {
  getActiveDateCalendarDots,
  getCalendarActiveDate,
  getCalendarDots,
  getCloudCoverage,
  getTempComparisonDate,
  isActiveYearMonthLoaded
} from '../../selectors/calendar'
import { setActiveCalendarDate, setCloudCoverage } from '../../actions/calendar'
import { isTemporalView } from '../../selectors/viewmode'
import { hasRoleSHCalendar } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => {
  return {
    flightGroups: getFlightGroups(state),
    calendarDots: getCalendarDots(state),
    snapshots: getSnapshots(state),
    tempComparisonDate: getTempComparisonDate(state),
    isTemporalCompareView: isTemporalView(state),
    selectedFlightGroupDate: getSelectedFlightGroupFirstFlightDate(state),
    activeDate: getCalendarActiveDate(state),
    activeDateFlightGroups: getActiveDateCalendarDots(state),
    isLoading: !isActiveYearMonthLoaded(state),
    selectedFlightGroupSource: getSelectedFlightGroupSource(state),
    droneFlightsSnapshotsDates: getDroneFlightsSnapshotsDates(state),
    cloudCoverage: getCloudCoverage(state),
    hasRoleSHCalendar: hasRoleSHCalendar(state)
  }
}

const mapDispatchToProps = {
  selectFields,
  selectFlightGroup,
  setActiveCalendarDate,
  compareToFlightGroup,
  setCloudCoverage
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFieldsCalendar)
