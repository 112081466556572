import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import debounce from 'lodash/debounce'
import Divider from '@mui/material/Divider'
import { StyledTooltip } from '../UIComponents/StyledTooltip'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 364px;
  height: 60px;
  z-index: 2;
  background-color: transparent;
`

const SearchBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 255px;
  height: 60%;
  border: none;
  background-color: ${UIStyles.blueGrey};
  border-radius: 10px;
  margin-left: 10px;
`

const InputWrapper = styled.input`
  display: flex;
  width: 90%;
  height: 100%;
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 1px;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  background-color: ${UIStyles.blueGrey};
  border-radius: 10px;
  &::-webkit-input-placeholder {
    color: white;
  }
  &:focus::placeholder {
    color: transparent;
  }
`

const ButtonWrapper = styled.button`
  height: 100%;
  width: 15%;
  border-radius: 5px;
  border: none;
  background-color: ${UIStyles.blueGrey};
`

const IconWrapper = styled.i`
  color: ${UIStyles.whiteColor};
  font-size: 1.3em;
  :hover {
    color: ${UIStyles.vomitColor};
  }
`

const SearchBar = ({ fieldCount, isFocus, disabled, isVisible, toggleFocusSearchBar, setSearchInputTextFilters, resetSearchInputFilters, children, t }) => {
  const [searchInput, setSearchInput] = useState('')
  const [activeSearch, setActiveSearch] = useState(false) // activate search input on key enter or search button (big clients)
  const inputRef = useRef(null)

  const setQuery = debounce(setSearchInputTextFilters, 1000)
  const showSearchButton = fieldCount > CHUNK_SIZE

  const handleFilterDelete = () => {
    if (searchInput !== '') {
      setSearchInput('')
      if (activeSearch) resetSearchInputFilters()
    }
  }

  const handleFilterSearch = () => {
    setActiveSearch(true)
    setQuery(searchInput)
  }

  const handleOnFocus = () => {
    toggleFocusSearchBar(true)
  }

  const openSearchFilters = () => {
    if (!isFocus) openSearchFilters()
  }

  if (!isVisible) return null

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      setActiveSearch(true)
      setQuery(searchInput)
    }
  }

  const handleFilterTextInputChange = e => {
    const { value } = e.target

    if (fieldCount < CHUNK_SIZE) {
      setActiveSearch(true)
      setSearchInput(value)

      setQuery(value)
    } else {
      setActiveSearch(false)
      setSearchInput(value)
    }
  }

  return (
    <SearchWrapper>
      <SearchBarWrapper id="search">
        <InputWrapper
          placeholder={t('search')}
          inputProps={{ 'aria-label': 'search' }}
          ref={inputRef}
          disabled={disabled}
          id="input-search-general"
          data-testid="search-general-input"
          placeholderTextColor="white"
          value={searchInput}
          onFocus={handleOnFocus}
          onChange={handleFilterTextInputChange}
          onClick={openSearchFilters}
          onKeyUp={e => handleKeyPress(e)}
          autoComplete="off"
          type="search"
          name="search"
          spellcheck="false"
        />
        {searchInput.length > 0 && (
          <ButtonWrapper onClick={handleFilterDelete} id="search-delete">
            <StyledTooltip title={t('clear')}>
              <IconWrapper className="ion-android-close" data-testid="remove-selection-button" />
            </StyledTooltip>
          </ButtonWrapper>
        )}
        {showSearchButton && (
          <>
            <Divider orientation="vertical" />
            <ButtonWrapper onClick={handleFilterSearch} id="search-submit" data-testid="search-submit-button">
              <StyledTooltip title={t('Search')}>
                <IconWrapper className="ion-android-search" />
              </StyledTooltip>
            </ButtonWrapper>
          </>
        )}
      </SearchBarWrapper>
      {children}
    </SearchWrapper>
  )
}

SearchBar.propTypes = {
  isFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  isVisible: PropTypes.bool,
  toggleFocusSearchBar: PropTypes.func,
  setSearchInputTextFilters: PropTypes.func,
  resetSearchInputFilters: PropTypes.func,
  openSearchFilters: PropTypes.func,
  fieldCount: PropTypes.number
}

export default withTranslator(SearchBar)
