import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: { key: 'user', data: null, status: 'idle', error: null },
  userAccounts: { key: 'userAccounts', data: null, status: 'idle', error: null },
  favouriteAccounts: { key: 'favourite_accounts', data: [], status: 'idle', error: null },
  defaultAccount: { key: 'default_login_account', data: null, status: 'idle', error: null }
}

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    fetchDataStart(state, action) {
      const { key } = action.payload
      state[key].status = 'loading'
    },
    fetchDataSuccess(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'succeeded'
      state[key].data = payload
    },
    fetchDataFailure(state, action) {
      const { key, error } = action.payload
      state[key].status = 'failed'
      state[key].error = error
    },
    updateDefaultAccount(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'updated'
      state[key].data = payload
    },
    updateFavourites(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'updated'
      state[key].data = payload
    },
    updateUserPolicies(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'updated'
      state[key].data = payload
    }
  }
})

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, updateDefaultAccount, updateFavourites, updateUserPolicies } = accountsSlice.actions

export default accountsSlice.reducer
