import { METADATA_DATE_RANGE } from '../constants'
import {
  DASHBOARD_FILTERS_TYPES,
  MAX_CLOUD_COVERAGE_INITIAL_STATE,
  FIELDS_COMPARISON_ORDER_BY_DATE_INITIAL_STATE,
  TOGGLE_DASH_FILTER,
  SET_TOP_BAR_VISIBILITY,
  TOP_BAR_OPTIONS,
  SET_DASH_FILTERS_ACTIVE,
  FILTER_OPTIONS,
  SET_MAX_CLOUD_COVERAGE,
  SET_TEMPORAL_EVOLUTION_MAX_CLOUD_COVERAGE,
  TOGGLE_FIELDS_COMPARISON_ORDER_BY_DATE
} from '../actions/dashboard'
import assign from 'lodash/assign'
import includes from 'lodash/includes'
import without from 'lodash/without'
import forEach from 'lodash/forEach'
import moment from 'moment'

const {
  FLIGHT_DRONE,
  FLIGHT_SATELLITE,
  DATE_RANGE,
  TEMPORAL_EVOLUTION_APPLIED_DATE_FILTER,
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO,
  TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM,
  FIELDS_COMPARISON_DATE_RANGE_SELECT,
  FIELDS_COMPARISON_ORDER_BY_DATE,
  METEOROLOGICAL_STATISTICS_BASE_TEMPERATURE,
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_TO,
  METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_FROM
} = DASHBOARD_FILTERS_TYPES

const today = moment().format('YYYY-MM-DD')
const metadataDateRangeAgoDate = moment().subtract(METADATA_DATE_RANGE, 'days').format('YYYY-MM-DD')

const initialDashboardState = {
  formFilters: {
    [FLIGHT_DRONE]: {
      id: 1,
      value: false
    },
    [FLIGHT_SATELLITE]: {
      id: 3,
      value: true
    },
    [DATE_RANGE]: {
      value: ''
    },
    [TEMPORAL_EVOLUTION_APPLIED_DATE_FILTER]: {
      value: {
        from: metadataDateRangeAgoDate,
        to: today
      }
    },
    [TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO]: { value: today },
    [TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM]: { value: metadataDateRangeAgoDate },
    [FIELDS_COMPARISON_DATE_RANGE_SELECT]: {
      value: {
        from: metadataDateRangeAgoDate,
        to: today
      }
    },
    [METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_TO]: { value: today },
    [METEOROLOGICAL_STATISTICS_FIELDS_DATE_RANGE_FROM]: { value: '' },
    [METEOROLOGICAL_STATISTICS_BASE_TEMPERATURE]: { value: 10 }
  },
  dashboardView: TOP_BAR_OPTIONS.FIELD_FILTER,
  activeFilters: FILTER_OPTIONS[TOP_BAR_OPTIONS.FIELD_FILTER],
  maxCloudCoverage: MAX_CLOUD_COVERAGE_INITIAL_STATE,
  temporalEvolutionMaxCloudCoverage: MAX_CLOUD_COVERAGE_INITIAL_STATE,
  [FIELDS_COMPARISON_ORDER_BY_DATE]: FIELDS_COMPARISON_ORDER_BY_DATE_INITIAL_STATE
}

export const dashboard = (state = initialDashboardState, action) => {
  switch (action.type) {
    case TOGGLE_DASH_FILTER:
      return _applyFilters(state, action.payload)
    case SET_DASH_FILTERS_ACTIVE:
      return { ...state, formFilters: action.filters }
    case SET_TOP_BAR_VISIBILITY:
      return { ...state, dashboardView: action.selectedTopBar, activeFilters: action.activeFilters }
    case SET_MAX_CLOUD_COVERAGE:
      return {
        ...state,
        maxCloudCoverage: action.cloudCoverage,
        temporalEvolutionMaxCloudCoverage:
          action.cloudCoverage === MAX_CLOUD_COVERAGE_INITIAL_STATE ? state.temporalEvolutionMaxCloudCoverage : action.cloudCoverage
      }
    case SET_TEMPORAL_EVOLUTION_MAX_CLOUD_COVERAGE:
      return {
        ...state,
        temporalEvolutionMaxCloudCoverage: action.cloudCoverage
      }
    case TOGGLE_FIELDS_COMPARISON_ORDER_BY_DATE:
      return {
        ...state,
        [FIELDS_COMPARISON_ORDER_BY_DATE]: state[FIELDS_COMPARISON_ORDER_BY_DATE] === 'asc' ? 'desc' : 'asc'
      }
    default:
      return state
  }
}

const _applyFilters = (state, action = {}) => {
  const filterType = action.type
  const filters = assign(
    {},
    state.formFilters,
    filterType && {
      [filterType]: {
        ...state.formFilters[filterType],
        value: action.value
      }
    }
  )

  const radioButtonOptions = ['flightSatellite', 'flightDrone']

  if (includes(radioButtonOptions, action.type)) {
    const notCurrentOptions = without(radioButtonOptions, action.type)

    if (action.value === false) {
      filters[action.type] = {
        ...filters[action.type],
        value: true
      }
    }

    forEach(notCurrentOptions, option => {
      filters[option] = {
        ...filters[option],
        value: false
      }
    })
  }

  return {
    ...state,
    formFilters: filters
  }
}
