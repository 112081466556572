export default {
  black: '#000',
  blueColor: '#082c3f',
  blueColorOpacity: 'rgba(8,44,63,.8)',
  blueDisabled: 'rgba(0, 0, 0, 0.6)',
  blueGrey: '#4c6d7c',
  blueLightGrey: 'rgba(125, 136, 147, 0.5)',
  blueGrey1: '#0b5c75',
  blueGrey2: '#4e5f6d',
  blueLightHover: '#27515E',
  dangerRed: '#d31224',
  dangerRedTransparent: 'rgba(211,18,36,0.4)',
  coral: '#be5f52',
  darkBlue: '#092c40',
  darkestGrayColor: '#212121',
  mediumDarkGrayColor: '#343434',
  darkGrayColor: '#616161',
  darkGrayHoverColor: 'rgba(97,97,97,0.3)',
  lightGrayHoverColor: 'rgba(230,230,230,0.2)',
  deepblueColor: '#0a354c',
  deepblueColor1: '#093248',
  deepblueColor2: '#072636',
  errorRed: '#f44336',
  fluoBlueColor: 'rgba(32,172, 247, 1)',
  fluoBlueColorTransparent: 'rgba(32,172, 247, 0.2)',
  fluoGreenColor: '#74F929',
  fluoOrangeColor: '#FFA020',
  fluoRedColor: '#EB1F22',
  grayColor: '#bdbdbd',
  labelFontWeight: 300,
  lightBlue: '#16a3b7',
  lightGrey: 'rgba(255, 255, 255, 0.4)',
  lightgreen: '#90ee90',
  lightGreySolid: 'rgb(111, 130, 134)',
  logoColor: '#c5c711',
  niceRedColor: '#e11428',
  nicePurpleColor: '#bf4495',
  redColor: '#d31224',
  sourColor: '#E9E9E9',
  transparent: 'rgba(0,0,0,0)',
  vomitColor: 'rgba(198, 195, 13, 1)',
  vomitColorTransparent: 'rgba(198, 195, 13, 0.5)',
  vomitLight: 'rgb(198,195,12)',
  vomitLightFieldSelected: 'rgba(198, 195, 13, 0.9)',
  vomitLightFieldSelectedHover: '#dddd7b',
  vomitLightHover: 'rgba(198, 195, 13, 0.2)',
  vomitLightHoverTransparent: 'rgba(198, 195, 13, 0.1)',
  whiteColor: '#FFFFFF',
  yellow: 'rgba(255, 252, 42,1)',
  yellowTransparent: 'rgba(255, 252, 42,0.6)',
  customOverlayBackground: 'rgba(0, 0, 0, 0.5)',
  customLightOverlayBackground: 'rgba(0, 0, 0, 0.3)',
  customTooltipBackground: 'rgba(0, 0, 0, 0.9)',
  thumbSwitched: {
    backgroundColor: 'red'
  },
  thumbSwitchos: {
    backgroundColor: 'green'
  },
  deliverables: {
    baseWidth: '270px',
    topWidth: '100%'
  },
  toggle: {
    marginBottom: 0,
    display: 'inline-block',
    width: 'auto',
    height: 16,
    color: '#FFFFFF'
  }
}
