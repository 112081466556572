import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { toggleMeasureTool, selectTool } from '../../actions/editor'

export const connectMeasures = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      isMeasureToolsActive: state.editor.present.isMeasureToolsActive,
      activeTool: state.editor.present.activeTool
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        // eslint-disable-next-line object-shorthand
        toggleMeasureTool: toggleMeasureTool,
        // eslint-disable-next-line object-shorthand
        selectTool: selectTool
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
