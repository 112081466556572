import SampleInfo from '../../components/FieldsComponents/SampleInfo'
import { connect } from 'react-redux'
import { getSelectedSample } from '../../selectors/samples'
import { getS3Url } from '../../actions/utilities'
import { deleteSelectedSample, deselectAllSamples } from '../../actions/samples'
import { openSampleImageModal } from '../../actions/modal'
import { getSelectedSampleNormalizedParameters } from '../../selectors/sampleParametersByType'
import { editSample } from '../../actions/samplesForm'

const mapStateToProps = state => ({
  samplesParameters: getSelectedSampleNormalizedParameters(state),
  selectedSample: getSelectedSample(state)
})

const mapDispatchToProps = {
  getS3Url,
  deselectAllSamples,
  openSampleImageModal,
  deleteSelectedSample,
  editSample
}

export default connect(mapStateToProps, mapDispatchToProps)(SampleInfo)
