import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: { key: 'user', data: [], status: 'idle', error: null },
  userAccounts: { key: 'userAccounts', data: null, status: 'idle', error: null },
  userPolicies: { key: 'userPolicies', data: null, status: 'idle', error: null },
  accountPolicies: { key: 'accountPolicies', data: null, status: 'idle', error: null },
  organizations: { key: 'organizations', data: null, status: 'idle', error: null }
}

function updateObjectById(data, id, newPolicy) {
  return data.map(item => (item.id === id ? { ...newPolicy } : item))
}

const accountsConfigSlice = createSlice({
  name: 'accountsConfig',
  initialState,
  reducers: {
    fetchDataStart(state, action) {
      const { key } = action.payload
      state[key].status = 'loading'
    },
    fetchDataSuccess(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'succeeded'
      state[key].data = payload
      state[key].error = null
    },
    fetchDataFailure(state, action) {
      const { key, error } = action.payload
      state[key].status = 'failed'
      state[key].error = error
    },
    updatePolicies(state, action) {
      const { key, payload } = action.payload
      state[key].status = 'policies update succeded'
      state[key].data = updateObjectById(state[key].data, payload.id, payload)
      state[key].error = null
    }
  }
})

export const { fetchDataStart, fetchDataSuccess, fetchDataFailure, updatePolicies } = accountsConfigSlice.actions

export default accountsConfigSlice.reducer
