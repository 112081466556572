import React from 'react'
import get from 'lodash/get'
import map from 'lodash/map'

import LayerVector from '../olComponents/layervector'
import Feature from '../olComponents/feature'
import MultiPolygon from '../olComponents/geom/MultiPolygon'
import Point from '../olComponents/geom/Point'

import '../../utils'

import themeColors from '@layers-frontend/commons/styles/themeColors'
import SourceVector from '../olComponents/sourcevector'
import { includes } from 'ol/array'
import { DAY_CYCLE_CAP, NO_VALUE } from '@layers-frontend/commons/constants'

const GlobalLayers = props => {
  const { filteredByEverything, filteredFeatures, isFilterable } = props
  if (!props.isGlobalLayerLegendOpen) return null

  const { selectedLayerLegend, highlightedCategory, selectedFieldIds } = props
  const selectedLayerKey = get(selectedLayerLegend, 'baseName')
  const selectedLayerLegendValues = get(selectedLayerLegend, 'legend')

  if (!selectedLayerLegendValues) return null

  const isScaleFromDB = get(selectedLayerLegend, 'isScaleFromDB')
  const isScaleTypeCategoric = get(selectedLayerLegend, 'isScaleTypeCategoric')
  const disabledLegends = []

  if (!isScaleFromDB && isScaleTypeCategoric) {
    map(selectedLayerLegendValues, legend => {
      if (!legend.visible) disabledLegends.push(legend.value)
    })
  }
  let highlightedRange = null
  if (isScaleFromDB) {
    const isHighlightedIndex = selectedLayerLegendValues.findIndex(legend => highlightedCategory && highlightedCategory.name === legend.name)
    if (isHighlightedIndex !== -1) {
      highlightedRange = selectedLayerLegendValues[isHighlightedIndex].range
    }
  }

  const fieldsLayer = props.isSearchView ? (
    <LayerVector visible={true} name="global_layer" opacity={1} zIndex={100000}>
      <SourceVector>
        {filteredFeatures.map(feat =>
          renderFeature(feat, props, {
            isFilterable,
            filteredByEverything,
            selectedFieldIds,
            selectedLayerKey,
            isScaleFromDB,
            highlightedRange,
            isScaleTypeCategoric,
            highlightedCategory
          })
        )}
      </SourceVector>
    </LayerVector>
  ) : null

  return <>{fieldsLayer}</>
}

const renderFeature = (feat, props, options) => {
  const {
    isFilterable,
    filteredByEverything,
    selectedFieldIds,
    selectedLayerKey,
    isScaleFromDB,
    highlightedRange,
    isScaleTypeCategoric,
    highlightedCategory
  } = options

  if (!feat) return null
  if (isFilterable && !filteredByEverything.find(f => f.id === feat.field.gm_field_id)) return null

  const isSelected = includes(selectedFieldIds, feat.field.gm_field_id)
  const selectedLayerValue = get(feat, ['field', selectedLayerKey])
  let finalStyle = getInitialStyle(feat, isSelected)

  if (isScaleFromDB) finalStyle = applyScaleFromDBStyle(finalStyle, highlightedRange, selectedLayerValue)

  if (isScaleTypeCategoric) finalStyle = applyScaleTypeCategoricStyle(finalStyle, selectedLayerValue, selectedLayerKey, highlightedCategory)

  return (
    <Feature key={feat.index} id={feat.index} field={feat.field} style={[finalStyle]}>
      {feat.geometry.type === 'Point' ? (
        <Point projection={feat.projection}>{feat.coordinates}</Point>
      ) : (
        <MultiPolygon projection={feat.projection}>{feat.coordinates}</MultiPolygon>
      )}
    </Feature>
  )
}

const getInitialStyle = (feat, isSelected) => ({
  ...feat.style,
  fill: {
    color: isSelected ? themeColors.yellowTransparent : feat.style.fill.color
  },
  text: {
    text: String.fromCharCode('0xf00c'),
    font: "900 40px 'Font Awesome 5 Free'",
    fill: {
      color: 'rgba(8, 44, 63,' + (isSelected ? 0.8 : 0) + ')'
    }
  }
})

const applyScaleFromDBStyle = (style, highlightedRange, selectedLayerValue) => {
  if (highlightedRange === NO_VALUE && !selectedLayerValue) {
    return {
      ...style,
      stroke: {
        width: 2,
        color: themeColors.whiteColor
      }
    }
  }
  if (highlightedRange && selectedLayerValue >= highlightedRange[0] && selectedLayerValue <= highlightedRange[1]) {
    return {
      ...style,
      stroke: {
        width: 2,
        color: themeColors.whiteColor
      }
    }
  }
  return style
}

const applyScaleTypeCategoricStyle = (style, selectedLayerValue, selectedLayerKey, highlightedCategory) => {
  let maxSelectedValue = selectedLayerValue
  if (selectedLayerValue && selectedLayerKey === 'gm_luvi_day_cycle') {
    maxSelectedValue = selectedLayerValue > DAY_CYCLE_CAP ? DAY_CYCLE_CAP : selectedLayerValue
  }

  if ((highlightedCategory && highlightedCategory === maxSelectedValue) || (highlightedCategory === 'null' && maxSelectedValue === null)) {
    return {
      ...style,
      stroke: {
        width: 2,
        color: themeColors.whiteColor
      }
    }
  }
  return style
}

export default GlobalLayers
