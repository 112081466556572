// prettier-ignore
export default {
  "-0.10 Very Low": "-0.10 Muy bajo",
  "% Mapa alertas": "% Mapa alertas",
  "% Media Fosforo": "% Media Fosforo",
  "% Media Nitrogeno": "% Media Nitrogeno",
  "% Media Potasio": "% Media Potasio",
  "% resiembra": "% resiembra",
  "0.10 Low": "0.10 Bajo",
  "0.20 Very Low": "0.20 Muy bajo",
  "0.25 Very Low": "0.25 Muy bajo",
  "0.30 Low": "0.30 Bajo",
  "0.30 Medium": "0.30 Medio",
  "0.40  Low": "0.40  Bajo",
  "0.40 Medium": "0.40 Medio",
  "0.45 High": "0.45 Alto",
  "0.50 High": "0.50 Alto",
  "0.55 Medium": "0.55 Medio",
  "0.65 Very High": "0.65 Muy alto",
  "0.70 High": "0.70 Alto",
  "0.85 Very High": "0.85 Muy alto",
  "abonado de cobertera": "Abonado de cobertera",
  "abonado de fondo": "Abonado de fondo",
  "abonado de fondo ": "Abonado de fondo",
  "abonado dosificado": "Abonado Dosificado",
  "abonado tasa fija": "Abonado Tasa Fija",
  "abonado zonificado": "Abonado Zonificado",
  "accumulated vegetative growth": "Crecimiento Vegetativo Acumulado",
  "Acre": "Acre",
  "active": "activas",
  "Add at least one recipient (+)": "Añadir un destinatario como mínimo (+)",
  "Add Field Button": "Botón: Añadir Campos",
  "Add Field Tutorial": "Tutorial: Añadir Campos",
  "add location": "agregar ubicación",
  "Add new sample": "Añadir muestra nueva",
  "add photo": "agregar foto",
  "added to pending": "añadido a pendiente",
  "Advanced": "Avanzado",
  "advanced season parameters": "parámetros de campaña avanzados",
  "afección": "Afección",
  "afección extensivo": "Afección Extensivo",
  "Affected Surface": "Superficie Afectada",
  "After filling the form, you can draw the field": "Una vez has acabado el formulario, pulsa este botón para dibujar la parcela",
  "agrodelivery": "AgroDelivery",
  "all": "todas",
  "ALMENDRA": "ALMENDRA",
  "alto estrés hídrico": "Alto estrés hídrico",
  "alto pot prod": "Alto Pot Prod",
  "ambiente": "Ambiente",
  "An error occured": "Se produjo un error",
  "An error occurred from the server. Please try again": "Se produjo un error en el servidor. Inténtalo de nuevo",
  "And that's it!": "Y eso es todo!",
  "anexo información": "Anexo Información",
  "Applied filters": "Filtros aplicados",
  "Apply": "Hecho",
  "apply date filter": "Aplicar filtro de fechas",
  "Apply selection": "Aplicar selección",
  "Are you sure to close the editor?": "¿Estás seguro de cerrar el editor?",
  "Are You sure?": "Estás seguro?",
  "area": "superficie",
  "Area": "Área",
  "ATR": "Azúcar Total Recuperable",
  "author": "Autor",
  "Automatic harvest": "Cosecha automática",
  "auxiliary files": "Archivos auxiliares",
  "back": "atrás",
  "bajo estrés hídrico": "Bajo estrés hídrico",
  "bajo pot prod": "Bajo Pot Prod",
  "Base temperature": "Temperatura base",
  "baseLayer": "Capa base",
  "Basic": "Básico",
  "basic season parameters": "parámetros de campaña básicos",
  "bipolaris_sacchari_g1": "bipolaris sacchari \"mancha del ojo\" - g1",
  "bipolaris_sacchari_g2": "bipolaris sacchari \"mancha del ojo\" - g2",
  "bipolaris_sacchari_g3": "bipolaris sacchari \"mancha del ojo\" - g3",
  "bipolaris_sacchari_g4": "bipolaris sacchari \"mancha del ojo\" - g4",
  "bipolaris_sacchari_g5": "bipolaris sacchari \"mancha del ojo\" - g5",
  "Both first and last points of the slice line must be outside of the polygon/circle": "El primer y último punto tienen que estar fuera polígono/círculo",
  "Buried": "Enterrado",
  "calendar": "Calendario",
  "calibre": "Calibre",
  "camera error message": "Parece que hay un problema con los permisos de camera",
  "camera_unavailable": "cámara no disponible",
  "Can't modify field with active planet subscription": "No es posible modificar un campo con una suscripción de Planet activa",
  "cancel": "Cancelar",
  "cane_num": "Número de cañas",
  "cantidad": "Cantidad",
  "capa base": "Capa base",
  "Capture screenshot": "Captura de pantalla",
  "Change opacity": "Cambiar opacidad",
  "Choose at least one field from the main search list": "Seleccione al menos una parcela de la lista principal",
  "Choose your layer": "Escoger la capa",
  "Choose your tool": "Escoge tu herramienta",
  "Circle Drawing": "Dibujar círculos",
  "class": "Clase",
  "Class values must be numerical": "Los valores de las clases deben ser numéricos",
  "classes": "Clases",
  "classes number": "Número de clases",
  "clear": "Borrar",
  "Clear all": "Restablecer",
  "clear form": "restablecer formulario",
  "clear selected fields": "Borrar campos seleccionados",
  "Clicking this button, you can toggle the visibility of the selected layer.": "Pulsando este botón, puedes alternar la visibilidad de la capa seleccionada.",
  "clientDeviation": "Desviación de Cliente",
  "Close": "Cerrar",
  "Close editor": "Cerrar el editor",
  "Close selection": "Cerrar selección",
  "Close the temporal Comparison": "Cerrar comparación temporal",
  "close_selected": "Cerrar selección",
  "closed": "cerrado",
  "cloud": "Nube",
  "Cloud Coverage": "Nubosidad",
  "comment": "comentario",
  "comp. temporal target": "Comp. Temporal Target",
  "comp. temporal variabilidad": "Comp. Temporal Variabilidad",
  "comparación de conteo": "Comparación de conteo (%)",
  "comparación de estado hídrico": "Comparación de estado hídrico (%)",
  "comparación de estado hídrico drone": "Comparación de estado hídrico - Drone (%)",
  "comparación de estado hídrico sat": "Comparación de estado hídrico - SAT (%)",
  "comparación de estimación de producción": "Comparación de estimación de producción media (%)",
  "comparación de fósforo": "Comparación de fósforo (%)",
  "comparación de mapa de alertas": "Comparación de mapa de alertas (%)",
  "comparación de mapa de faltas": "Comparación de mapa de faltas (%)",
  "comparación de media de conteo": "Comparación de media de conteo (%)",
  "comparación de media de estado hídrico": "Comparación de media de estado hídrico (%)",
  "comparación de media de fósforo": "Comparación de media de fósforo (%)",
  "comparación de media de mapa de alertas": "Comparación de media de mapa de alertas (%)",
  "comparación de media de mapa de faltas": "Comparación de media de mapa de faltas (%)",
  "comparación de media de nitrógeno": "Comparación de media de nitrógeno (%)",
  "comparación de media de potasio": "Comparación de media de potasio (%)",
  "comparación de media de producción total": "Comparación de media de producción total (%)",
  "comparación de media de rendimiento graso": "Comparación de media de rendimiento graso (%)",
  "comparación de medias de estimación de producción": "Comparación de medias de est. de producción (%)",
  "comparación de medias de ndvi": "Comparación de medias de NDVI (%)",
  "comparación de medias de proteína": "Comparación de medias de proteína (%)",
  "comparación de medias de variabilidad": "Comparación de medias de variabilidad (%)",
  "comparación de ndvi": "Comparación de NDVI (%)",
  "comparación de nitrógeno": "Comparación de nitrógeno (%)",
  "comparación de nitrógeno relativo": "Comparación de nitrógeno relativo(%)",
  "comparación de potasio": "Comparación de potasio (%)",
  "comparación de producción total": "Comparación de producción total (%)",
  "comparación de proteína": "Comparación de proteína (%)",
  "comparación de rendimiento graso": "Comparación de rendimiento graso (%)",
  "comparación de sacarosa": "Comparación de sacarosa",
  "comparación de variabilidad": "Comparación de variabilidad (%)",
  "confirm": "Confirmar",
  "Congratulations! Your field has been created.": "El campo ha sido creado",
  "Congratulations! Your field has been edited.": "El campo ha sido modificado",
  "Contact": "Contacto",
  "Contenido de sacarosa ( % )": "Contenido de sacarosa ( % )",
  "conteo - pdf rgb": "conteo - pdf rgb",
  "conteo - shps": "conteo - shps",
  "conteo de individuos": "Conteo de Individuos",
  "conteo lechugas": "Conteo Lechugas",
  "continue": "continuar",
  "CORN": "MAIZ",
  "correction": "Corrección",
  "Corte nitrogeno": "Corte nitrogeno",
  "cost": "Coste",
  "count (units)": "Conteo",
  "Counting": "Conteo",
  "create": "crear",
  "Create New Field": "Crear nueva parcela",
  "CREATE_RANGES": "Basado en el histograma de la imagen, crear rangos con un porcentaje de datos similares en cada rango.",
  "created": "Creado",
  "crecimiento cultivo": "Crecimiento Cultivo",
  "Crop": "Cortar",
  "Crop type": "Tipo de cultivo",
  "CROP_INSPECTION": "Inspección de cultivos",
  "cultive": "Cultivo",
  "Cultivo": "Cultivo",
  "current season": "temporada actual",
  "current selection could take a moment to process": "Se ha aumentado el rango de fechas, el pedido puede demorar en ser procesado",
  "Customer": "Cliente",
  "customerProductionEstimation": "Estimación de producción de cliente",
  "customers": "Cliente",
  "cut": "Corte",
  "Cutter": "Recortar",
  "dashboard": "Panel",
  "date": "Fecha",
  "DD/MM/YYYY": "DD/MM/AAAA",
  "delete": "eliminar",
  "Delete": "Eliminar",
  "Delete downloaded report": "Eliminar reporte descargado",
  "delete downloaded sample": "eliminar muestra descargada",
  "Delete field": "Borrar Parcela",
  "delete layers?": "eliminar capas?",
  "delete sample": "eliminar muestra",
  "delete sample edition": "eliminar edición de muestra",
  "DeleteField": "El campo ha sido borrado",
  "Deliverables tutorial": "Tutorial: Entregables",
  "Description": "Descripción",
  "Deselect": "Deseleccionar",
  "deselect all": "Deseleccionar todos",
  "deviation": "Desviación",
  "Did you forget your password ?": "¿ Olvidaste tu contraseña ?",
  "disponible en visor": "Disponible en visor",
  "do you want to delete": "quieres borrar",
  "documentation": "Documentación",
  "don't show again": "no volver a mostrar",
  "Done": "Hecho",
  "dosificación genérica": "Dosificación genérica",
  "Dosis Media Cliente": "Dosis media cliente",
  "Dosis media Propuesta": "Dosis media propuesta",
  "dosis riego": "Dosis Riego",
  "Download": "Descarga",
  "download failed": "Las siguientes descargas han fallado",
  "Download list": "Descargar listado",
  "Download selected fields samples": "Descarga de aforo de parcelas seleccionadas",
  "download selected geometries": "Descargar geometrías seleccionadas",
  "download values": "Descargar valores",
  "downloaded": "descargado",
  "downloading layers": "descargando capas",
  "downloads": "Descargas",
  "Drag and drop your layers to arrange them in the list.": "Arrastra las capas para organizarlas en la lista.",
  "Drag Fields": "Arrastrar Campos",
  "Drag your layers": "Arrastrar capas",
  "Draw Field": "Dibujar parcela",
  "dron": "Dron",
  "Dron sectores": "Dron sectores",
  "drop here": "arrastra aquí",
  "Dry": "Secano",
  "dry matter": "Materia seca",
  "dryMatterField": "Materia seca",
  "dryMatterLaboratory": "Materia seca de laboratorio",
  "dsm": "DSM",
  "earliest harvest date": "Fecha de cosecha anterior",
  "edit": "editar",
  "Edit Field": "Editar terreno",
  "Edit geometry": "Editar geometría",
  "edit range": "Editar rango",
  "Edit values": "Editar valores",
  "ej 100": "Ej 100",
  "End Date": "Fecha final",
  "Equal Interval": "Intervalo Igual",
  "Equidistant ranges": "Rangos equidistantes",
  "error fetching classes": "El cielo está demasiado nublado en la fecha seleccionada. Ajuste el filtro de nubes del calendario para encontrar un día más despejado.",
  "estado hídrico": "Estado hídrico",
  "estado hídrico (ndmi)": "Estado Hídrico (NDMI)",
  "estado hidrico sat": "NDRE",
  "estimación": "Estimación",
  "Estimacion produccion ( Tn / Ha )": "Estimación producción ( Tn / Ha )",
  "estimación producción cualitativa": "Estimación Producción Cualitativa",
  "estimación proteína": "Estimación Proteína",
  "Estimacion proteina ( % )": "Estimacion proteina ( % )",
  "estimación sacarosa": "Estimación Sacarosa",
  "estimación sacarosa ": "Estimación Sacarosa",
  "estimación sacarosa cualitativa": "Estimación Sacarosa Cualitativa",
  "Estimacion Total ( Kg )": "Estimacion Total ( Kg )",
  "estimation gauging": "Estimation Gauging",
  "estrategia de dosificacion": "Estrategia de dosificación",
  "estrés hídrico (lws)": "Estrés hídrico (LWS)",
  "evolución de NDVI": "Evolución de NDVI",
  "evolution": "evolución",
  "Ex : comment": "Ej : comentario",
  "Ex : field R02": "Ej : Parcela R02",
  "Ex : Tresbolillos, row, tree": "Ej : Tresbolillos, fila, árbol",
  "expected_final_weight": "Peso final esperado",
  "external_id": "ID externo",
  "extra parameters": "parámetros adicionales",
  "falhas carreadores": "Faltas Transportista",
  "falhas internas": "Faltas Internas",
  "falhas terraços": "Faltas en Terrazas",
  "fat performance": "Rendimiento graso",
  "faults map": "Mapa de faltas",
  "fdn": "Fdn",
  "Fecha de vuelo": "Fecha de vuelo",
  "Fecha Plantacion": "Fecha Plantacion",
  "Feddan": "Feddan",
  "fertilization strategy": "Estrategia de Fertilización",
  "Fertilizer recommendation": "Recomendación de abonado",
  "field": "parcela",
  "Field Name": "Nombre de parcela",
  "Field without geometry": "Parcela sin geometría",
  "FIELD_FILTER": "Filtros de campo",
  "field_name": "Nombre de parcela",
  "FIELD_TEMPORAL_EVOLUTION": "Evolución temporal",
  "fields": "Parcelas",
  "Fields without geometries": "Parcelas sin geometrías",
  "File cannot be generated due to high amounts of clouds. Please select another date": "No se ha podido generar el fichero, debido a un alto nivel de nubes. Por favor seleccione otra fecha",
  "File generator": "Generador de ficheros",
  "File Generator": "Generador de ficheros",
  "File name": "Nombre del fichero",
  "file type": "Tipo de archivo",
  "file-is-not-available-in-selected-language": "Este archivo no esta disponible en el lenguaje seleccionado. Generado usando ingles.",
  "Fill plantation date of current field, showing one year of historic data": "Completar la fecha de plantación de la parcela, mostrando 1 año de datos históricos",
  "Fill the field form": "Rellenar el formulario del campo",
  "Fill the form to apply for all our services": "Complete el formulario para solicitar todos nuestros servicios",
  "filtered field": "parcela filtrada",
  "filtered fields": "parcelas filtradas",
  "Filters": "Filtros",
  "final_weight": "Peso final",
  "fitopatógenos ": "Fitopatógenos",
  "Flight Slider": "Barra de vuelos",
  "FLIGHT_FILTER": "Comparación temporal",
  "flights type": "Tipo de vuelos",
  "fmsavi": "FMSAVI",
  "for everyone": "para todos",
  "for me": "para mí",
  "forecasts": "Predicción",
  "formulario de abonado": "Formulario de abonado",
  "formulario de dosis": "Formulario de dosis",
  "fósforo foliar": "Fósforo Foliar",
  "fr": "Francio",
  "Fraction": "Fracción",
  "free": "libre",
  "from": "Desde",
  "fruits_100_weight": "Peso de 100 frutas",
  "fruits_actual1_weight": "Peso de fruta actual",
  "Fuente de informacion": "Fuente de informacion",
  "gallery": "galeria",
  "gauging date": "Fecha aforo",
  "generation error": "error de generación",
  "geometry": "geometría",
  "get a free trial": "probar gratis",
  "Get back to editor": "Regrese al editor",
  "global files": "Descargar archivos PDF y CSV de preprocesamiento, entrenamiento y pronóstico",
  "global layer": "Capa global",
  "gm_cut_number": "Número de corte ",
  "gm_data_generation": "Fecha de satélite (semanas)",
  "gm_day_cycle": "Días de cultivo",
  "gm_harvest_estimated_date": "Fecha de cosecha prevista (semanas)",
  "gm_hemav_atr_estimation": "Estimación de ATR ",
  "gm_hemav_pol_estimation": "Estimación de polarización ",
  "gm_hemav_prodph_estimation": "Estimación de producción ",
  "gm_hemav_realprodph_lastseason": "Producción real de la campaña anterior",
  "gm_hemav_sac_estimation": "Estimación de sacarosa ",
  "gm_luvi_date_generation": "Fecha de satélite - LUVI (semanas)",
  "gm_luvi_day_cycle": "Días de cultivo (NDVI-LUVI)",
  "gm_luvi_mean": "Vigor Vegetativo (NDVI-LUVI)",
  "gm_luvi_ndvi_std": "Variabilidad (NDVI-LUVI)",
  "gm_lws_mean": "Estrés hídrico (LWS)",
  "gm_ndmi_date_generation": "Fecha de satélite - NDMI (semanas)",
  "gm_ndmi_day_cycle": "Días de cultivo (NDMI)",
  "gm_ndre_date_generation": "Fecha de satélite - NDRE (semanas)",
  "gm_ndre_day_cycle": "Días de cultivo (NDRE)",
  "gm_season_label": "Campaña",
  "gm_sub_type_name": "Variedad",
  "gm_type_name": "Cultivo",
  "go to field": "Ir a la parcela",
  "grid": "grilla",
  "harvest date": "Fecha de cosecha",
  "harvest planning": "Planificación de cosecha",
  "HARVEST_PLANNING_TOOL": "Planificación de cosecha",
  "HARVEST_PREDICTION": "Predicción de cosecha",
  "hasta": "Hasta",
  "Hectare": "Hectárea",
  "Hemav Default": "Predeterminado Hemav",
  "HEMAV Default": "Predeterminado HEMAV",
  "Here, you can play with the preview of your different layers.": "Aquí, puedes jugar con la visualización de las diferentes capas.",
  "hide": "ocultar",
  "Hide All Layers": "Esconder todas las capas",
  "hide filter list": "Ocultar lista de filtros",
  "Hide Global Layer": "Ocultar Capa Global",
  "Hide Measure Tools": "Ocultar herramientas de medición",
  "high": "Alto",
  "high level": "Alto",
  "high need": "Más necesidades",
  "higher clay content": "Mayor contenido de arcillas",
  "humedad de suelo - beta (%)": "Humedad de suelo - Beta (%)",
  "humedad vegetativa (ndmi)": "Humedad Vegetativa (NDMI)",
  "Hybrid View": "Vista híbrida",
  "hydric status": "Estado Hídrico -  NDMI",
  "hydric status drone": "Estado hídrico - Drone",
  "If foliar sampler haven't been made, the date you must enter is the current": "Si no se han realizado aforos foliares la fecha que debe introducir es la actual",
  "If you have two or more flights, you can compare them temporally using this tool.": "Si tienes dos o más vuelos, puedes compararlos temporalmente utilizando esta herramienta.",
  "If you have undone an action, you can press this button to redo it.": "Si has deshecho alguna acción acciones, puedes pulsar este botón para rehacerla.",
  "image": "imagen",
  "Impacto estimado": "Impacto estimado",
  "In this tab, you can download the generated PDFs.": "En esta pestaña, puedes descargar los PDFs generados.",
  "individuals_num": "Número de individuos afectados",
  "infection": "Infección",
  "info": "Info",
  "Information": "Información",
  "informe": "Informe",
  "integración con maquinaria": "Integración con maquinaria",
  "Invalid credentials": "Credenciales inválidas",
  "invalid date": "Fecha inválida",
  "Invalid email": "Email inválido",
  "invest": "Invertir",
  "Irrigation recommendation": "Recomendación de riego",
  "Irrigation type": "Tipo de riego",
  "It is commonly adjusted to establish the amount of fertilizer or seeds to be applied per unit area": "Comúnmente se ajusta para establecer la cantidad de fertilizante o semillas que se aplicarán por unidad de superficie.",
  "item_num": "Número items",
  "Keep selection": "Mantener selección",
  "label": "01.Etiqueta",
  "Label": "Etiqueta",
  "languaje": "Idioma",
  "last sample": "Último aforo",
  "lat": "Latitud",
  "layers": "Capas",
  "layers estrés hídrico (lws)": "Layers estrés hídrico (LWS)",
  "layers status": "Estado de layers",
  "Legal limitation": "Limitación legal",
  "leptosphaeria_sacchari_g1": "leptosphaeria sacchari \"mancha anular\" - g1",
  "leptosphaeria_sacchari_g2": "leptosphaeria sacchari \"mancha anular\" - g2",
  "leptosphaeria_sacchari_g3": "leptosphaeria sacchari \"mancha anular\" - g3",
  "leptosphaeria_sacchari_g4": "leptosphaeria sacchari \"mancha anular\" - g4",
  "leptosphaeria_sacchari_g5": "leptosphaeria sacchari \"mancha anular\" - g5",
  "letter only": "Sólo letras",
  "linear meters": "linear meters",
  "líneas de cultivo": "Líneas de cultivo",
  "list": "lista",
  "loading": "Cargando",
  "location": "Ubicación",
  "Log in": "Entrar",
  "logout": "Desconectar",
  "lon": "Longitud",
  "looks like its taking too long": "parece que está tardando mucho",
  "low": "Bajo",
  "low level": "Bajo",
  "low need": "Menos necesidades",
  "lower clay content": "Menor contenido de arcillas",
  "LUVI variability": "Variabilidad LUVI",
  "Machines Type": "Tipo de máquina",
  "main_macronutrient": "Macronutriente principal",
  "malas hierbas": "Malas Hierbas",
  "malas hierbas - hoja estrecha": "Malas hierbas - Hoja Estrecha",
  "malas hierbas - hoja larga": "Malas hierbas - Hoja Larga",
  "malas hierbas - zonificado": "Malas hierbas - Zonificado",
  "malas hierbas shps": "Malas hierbas SHPs",
  "malas hierbas shps - hoja estrecha": "Malas hierbas - Hoja Estrecha",
  "malas hierbas shps - hoja larga": "Malas hierbas - Hoja Larga",
  "malas hierbas shps - zonificado": "Malas hierbas - Zonificado",
  "Manage report": "Gestionar reportes",
  "max 2 years": "Máximo 2 años",
  "max comparable fields": "Numero maximo de campos comparables",
  "maximo de variacion": "Máximo de variación",
  "máximo vigor dos meses (ndvi)": "Máximo vigor dos meses (NDVI)",
  "máximo vigor un año (ndvi)": "Máximo vigor un año (NDVI)",
  "Maximun cloud coverage": "Máxima nubosidad",
  "mean": "Media",
  "Measures tool tutorial": "Tutorial: Herramientas de Medición",
  "Measures tools": "Herramientas de Medición",
  "media de faltas": "Media de Faltas",
  "medias": "Medias",
  "medio pot prod": "Medio Pot Prod",
  "medium": "Medio",
  "medium clay content": "Contenido de arcillas medio",
  "medium level": "Medio",
  "medium_weight_bigSize": "Peso de frutos grandes",
  "medium_weight_mediumSize": "Peso medio de frutos medianos",
  "medium_weight_smallSize": "Peso medio de frutos pequeños",
  "Mensual limit": "Límite mensual",
  "Message": "Mensaje",
  "METEOROLOGICAL_STATISTICS": "Estadísticas meteorológicas",
  "mode": "Modo",
  "model type": "Tipo de modelo",
  "Move the slider": "Mover la barra",
  "msavi": "MSAVI",
  "N_BUNCHES": "Número de racimos",
  "Name": "Nombre",
  "nameless field": "Campo sin nombre",
  "Natural Breaks (Jenks)": "Puntos de Ruptura Naturales (Jenks)",
  "ndre": "NDRE",
  "ndvi": "NDVI",
  "ndvi real": "NDVI Real",
  "ndvi-curvas de nivel": "NDVI-Curvas de Nivel",
  "Network request failed": "Error de conexión",
  "new sample": "muestra nueva",
  "New sample created.": "Nueva muestra creada.",
  "NITRO": "Nitrógeno",
  "nitrogen chlorophyll (ndre)": "Nitrógeno-Clorofila (NDRE)",
  "nitrogen_assimilable": "Nitrógeno assimilable",
  "nitrógeno foliar": "Nitrógeno foliar",
  "Nivel de estres": "Nivel de estres",
  "no data": "No hay datos",
  "no data available": "No hay datos disponibles todavía",
  "no data with current selection": "No hay datos con la presente selección",
  "No fields selected": "No hay campos selecionados",
  "no fields to clear": "No hay campos para borrar",
  "NO_DELIVERABLES_IMAGES_MESSAGE": "No hay imágenes para este día.",
  "Not a user yet ?": "¿ No es usuario ?",
  "not enough data to display this chart": "No hay suficientes datos para presentar el gráfico",
  "Not supported by browser": "No soportado por su navegador",
  "notes": "notas",
  "number only": "Solo valor numérico",
  "Numero vuelo": "Numero vuelo",
  "observations": "observaciones",
  "offline access": "acceso offline",
  "offline mode": "Modo offline",
  "oil_yield": "Rendimiento de aceite",
  "ok": "Aceptar",
  "Once you are done, click this button to save the changes.": "Una vez has acabado, pulsa este botón para guardar los cambios.",
  "open": "abierto",
  "Open": "Abrir",
  "Open selection": "Abrir selección",
  "open temporal comparison": "Abrir comparación temporal",
  "open_selected": "Abrir selección",
  "optimize": "Optimizar",
  "or": "o",
  "Order by a - z": "Ordenar a - z",
  "Order by distance to the field": "Ordenar por distancia a la parcela",
  "Order by z - a": "Ordenar z - a",
  "ourDeviation": "Desviación de Hemav",
  "ourProductionEstimation": "Estimación de producción de Hemav",
  "Parcela": "Parcela",
  "Password": "Contraseña",
  "pb": "Plomo",
  "pcd": "PCD",
  "pcd arbol": "PCD arbol",
  "pcd interpolado": "PCD Interpolado",
  "pcd row": "PCD Row",
  "pdf": "PDF",
  "pending": "pendiente",
  "PERELLADA": "PERELLADA",
  "phospohorus": "Fosforo",
  "phosporus": "Fósforo",
  "photo": "foto",
  "photoid": "Id de imagen",
  "photoId": "Foto",
  "picture_path": "Foto",
  "plague": "Plaga",
  "planned dosage": "Dosificación prevista",
  "Plant emitters": "Emisores",
  "plantation date": "Fecha de plantación",
  "Plantation date": "Fecha de plantación",
  "plantation_end_date_range": "Rango fecha de plantación",
  "plantation_start_date_range": "Rango fecha de plantación",
  "Plantations": "Plantaciones",
  "plants": "Aforo",
  "Plants distance": "Distancia entre plantas",
  "plants_density_range": "Rango de densidad de plantas",
  "Please wait or order one to have data to visualized.": "Programa un vuelo para obtener entregables para esta parcela",
  "Please, choose a field variety": "Selecciona una variedad de cultivo",
  "Please, choose the field owner": "Por favor, seleccionar un cliente",
  "plural selected": "seleccionados",
  "POL": "Polarización",
  "Polygon drawing": "Dibujar polígonos",
  "positive number only": "Sólo número positivo",
  "positive number only and minor 100": "Sólo número positivo y menor o igual a 100",
  "pot. horticolar 90": "pot. horticolar 90",
  "pot. otros cultivos 150": "pot. otros cultivos 150",
  "pot. otros cultivos 90": "pot. otros cultivos 90",
  "potasio foliar": "Potasio foliar",
  "potencial productivo histórico (ndvi)": "Potencial productivo histórico (NDVI)",
  "precio": "Precio",
  "PRED_TECH": "PredTech",
  "Predefined ranges by HEMAV which are generally appropriate for the majority of fields": "Rangos predefinidos por HEMAV que son generalmente apropiados para la mayoría de los campos.",
  "prediction": "Predicción",
  "preprocessing": "Preprocesamiento",
  "Press this button to add a new field.": "Pulsa este botón para añadir nuevo campo.",
  "Pressing this button lets you go back to the field view. ": "Pulsar este botón te permite volver a la vista de campo",
  "Preview": "Prevista",
  "price Kg": "Precio por kg",
  "primer vuelo": "Primer vuelo",
  "PROD": "Rendimiento por unidad de superficie",
  "producción esperada": "Producción esperada",
  "product Kg": "Kg del producto",
  "product Kg is mandatory": "Los Kg del producto es un campo obligatorio",
  "Production estimate": "Estimación de producción",
  "production estimate (units)": "Estimación de producción media (Kg/Ha)",
  "profile": "Perfil",
  "protein": "Proteína",
  "puccinia_sp_g1": "puccinia sp \"roya\" - g1",
  "puccinia_sp_g2": "puccinia sp \"roya\" - g2",
  "puccinia_sp_g3": "puccinia sp \"roya\" - g3",
  "puccinia_sp_g4": "puccinia sp \"roya\" - g4",
  "puccinia_sp_g5": "puccinia sp \"roya\" - g5",
  "Quick start with Azure": "Acceso rápido con Azure",
  "Random fertilization": "Fertilización variable",
  "Random seedling": "Siembra variable",
  "Range": "Rango",
  "Ranges adjusted to the index": "Rangos ajustados al índice",
  "Ranges of the same size will be generated according to the minimum and maximum values of the image. In cases where the image is very homogeneous, it is likely that no differentiation will be seen.": "Se generarán rangos del mismo tamaño según los valores mínimo y máximo de la imagen. En casos donde la imagen sea muy homogénea, es probable que no se vea ninguna diferenciación.",
  "realProduction": "Producción real",
  "Recipients": "Destinatarios",
  "recomendación abonado dosificado": "Recomendación Abonado Dosificado",
  "recomendación abonado zonificado": "Rec. Abonado zonificado",
  "Recomendacion de riego": "Recomendacion de riego",
  "recomendación riego": "Rec. Riego",
  "Redo Action": "Rehacer acciones",
  "Related date": "Fecha relacionada",
  "relative nitrogen": "Nitrógeno relativo",
  "Rendimiento graso ( % )": "Rendimiento graso ( % )",
  "report": "Reporte",
  "Report sent correctly": "Reporte enviado correctamente",
  "report type": "Tipo",
  "Report type": "Tipo de reporte",
  "report_type_action_description": "Propuesta de tratamientos o acciones a ser aplicados",
  "report_type_annotation_description": "Diagnóstico: Análisis realizado en campo",
  "report_type_inspection_description": "Análisis en LAYERS (cerrar después de analizar)",
  "report_type_result_description": "Análisis de los resultados de la solución o tratamiento aplicado.",
  "reporte agronómico": "Reporte Agronómico",
  "Reported by": "Reportado por",
  "Reported fields": "Parcelas reportadas",
  "reports manager": "Reportes",
  "required": "requerido",
  "Required": "Requerido",
  "Requires 3 characters minimun": "Requiere un mínimo de 3 caracteres",
  "Reset": "Borrar",
  "resiembra": "Resiembra",
  "resolution": "Resolución SHP (m)",
  "resolution (only SHP)": "Resolución (sólo para SHP)",
  "retención de agua": "Retención de agua",
  "retry": "reintentar",
  "rgb": "RGB",
  "richness": "Riqueza",
  "riego zonificado": "Riego zonificado",
  "riesgo malherbología": "Riesgo Malherbología",
  "Ruler": "Regla",
  "SAC": "Sacarosa por unidad de superficie",
  "saccharose estimate": "Estimación Sacarosa",
  "sample": "Muestra",
  "sample type": "tipo",
  "Sample was deleted.": "La muestra fue eliminada.",
  "Sample was successfully modified.": "La muestra fue modificada exitosamente.",
  "sample_creation_date": "Fecha de creación",
  "samples": "Muestras",
  "satélite": "Satélite",
  "Satellite button tutorial": "Tutorial: Vista de Satélite",
  "Satellite View": "Vista de satélite",
  "save": "Ahorrar",
  "Save field": "Guardar campo",
  "save_": "guardar",
  "search": "Buscar",
  "Search": "Buscar",
  "season": "temporada",
  "Season label": "Etiqueta de temporada",
  "season name": "nombre de campaña",
  "SEASON_EVOLUTION": "Evolución Campaña",
  "Sector": "Sector",
  "sectores target": "Sectores Target",
  "See? Now, clicking the button again lets you go back to the satellite view.": "Lo tienes? Ahora, pulsando el botón otra vez puedes volver a la vista de satélite.",
  "seguimiento_cultivo.xlsx": "seguimiento_cultivo.xlsx",
  "segundo vuelo": "Segundo vuelo",
  "Seleccione una parcela": "Seleccione una parcela",
  "Select": "Seleccionar",
  "select a crop and sample": "Seleccione tipo de cultivo y muestra",
  "select all": "Seleccionar todos",
  "Select all": "Seleccionar todo",
  "select at least one field": "Seleccione al menos una parcela",
  "select fields by": "Seleccionar parcelas por",
  "select fields by variability range": "Seleccionar parcelas por rango de variabilidad",
  "select fields to open temporal comparison": "Selecciona campos para abrir comparación temporal",
  "select one": "Elige uno",
  "select one customer please": "Elige un cliente, por favor",
  "select one subtype please": "Seleccione un subtipo, por favor",
  "select one type please": "Selecciona un tipo de cultivo",
  "select sample base layer and date": "seleccionar capa de base y fecha para la muestra",
  "select sample location": "seleccione la ubicación de la muestra",
  "select season": "seleccionar campaña",
  "selected": "seleccionadas",
  "Selected fields names": "Parcelas seleccionadas",
  "Selected fields samples": "Aforo de parcelas seleccionadas",
  "Selection includes too much information, try selecting a shorter date range": "La selección incluye demasiada información, intenta un período de fechas más corto",
  "Send": "Enviar",
  "Send report": "Enviar reporte",
  "settings": "Ajustes",
  "Share fields report": "Compartir reporte de parcela/s",
  "show all": "mostrar todo",
  "Show All Layers": "Mostrar todas las capas",
  "Show current season only": "Mostrar solo la temporada actual",
  "show fields with no data": "Mostrar campos sin dato",
  "show filter list": "Mostrar lista de filtros",
  "Show Global Layer": "Mostrar Capa Global",
  "Show Measure Tools": "Mostrar herramientas de medición",
  "shp-tooltip": "Estos números pueden ser modificados según las necesidades de uso y están asociados a cada clase de geometría en la tabla de atributos SHP exportada. Comúnmente se ajusta para establecer la cantidad de fertilizante o semillas que se aplicarán por unidad de superficie.",
  "singular selected": "seleccionado",
  "Slice tool only works on Polygons and Circle": "La herramienta de corte sólo funciona con círculos o polígonos",
  "Slider Tool": "Herramienta de comparación",
  "soil": "Suelo",
  "soil_type": "Tipo de suelo",
  "sólo para la generación de SHP": "Sólo para la generación de SHP",
  "Sort": "Ordenar",
  "sort by date": "ordenar por fecha",
  "Start date": "Fecha de inicio",
  "static layers": "capas fijas",
  "Statistics": "Seguimiento",
  "status": "Estado",
  "stem_num": "Númbero de tallos",
  "Streets distance": "Distancia entre calles",
  "Subtype": "Subtipo",
  "Suc/Ha": "Sac/Ha",
  "sum_of_bases": "Suma de bases",
  "Superficie ( Ha )": "Superficie ( Ha )",
  "Superficie Sector ( Ha )": "Superficie Sector ( Ha )",
  "surfaceUnit": "Unidades de superficie",
  "switch between all samples and current season samples": "cambiar entre todas las muestras y las muestras de la temporada actual",
  "Switch to hybrid map": "Ver mapa híbrido",
  "Switch to Mapbox": "Ver mapa satelite2",
  "Switch to satellite map": "Ver mapa satelite",
  "Switch to Terrain map": "Ver mapa de alturas",
  "target date": "Fecha de referencia",
  "target multiparcela": "Target Multiparcela",
  "tell us": "Coméntanos",
  "Temporal Comparison tutorial": "Tutorial: Comparación Temporal",
  "textura del suelo": "Textura del suelo",
  "The date should be in DD/MM/YYYY format": "La fecha de referencia debe ser válida (DD/MM/AAAA)",
  "The distance must be in meters": "La distancia debe ser en metros",
  "the extra parameters will be removed": "los parámetros adicionales serán borrados",
  "the following layers are no longer available in offline mode, download again if necessary": "las siguientes capas no se encuentran disponibles en el modo offline, volver a descargarlas de ser necesario",
  "The production must be numeric/decimal": "La producción debe ser un valor numérico",
  "The right column shows the total amount to be applied according to the value entered per class, and the total per plot at the bottom": "La columna de la derecha muestra la cantidad total a aplicar según el valor introducido por clase, y el total por parcela en la parte inferior.",
  "The sample location must be inside one of the fields.": "La ubicación de la muestra debe estar dentro de uno de los campos.",
  "There are no samples to download": "No hay muestras para descargar",
  "There is no operated flight on this field.": "Todavía no hay resultados procesados en este campo",
  "Thermal Accumulation": "Acumulación Térmica",
  "These numbers can be modified according to application needs and are associated with each geometry in the attribute table of the exported SHP": "Estos números pueden ser modificados según las necesidades de aplicación y se asocian a cada geometría en la tabla de atributos del SHP exportado.",
  "This action will delete the field and all its related data": "Esta acción borrará la parcela y toda la información relacionada",
  "this action will modify your field selection": "Esta acción modificara las parcelas seleccionadas",
  "this action will open field view and lose current field selection": "Esta acción abrirá la parcela en la visión de mapa y perderá la selección de campos actual ",
  "This button allows you to draw lines or polygons to measure a distance or area.": "Este botón te permite dibujar líneas o polígonos para calcular una distancia o área.",
  "This button allows you to switch to the hybrid view.": "Este botón te permite acceder a la vista híbrida.",
  "This can take a long time": "Esto puede tardar varios minutos",
  "This field is not downloaded.": "No descargaste este campo.",
  "This tool lets you cut polygons. Use single clicks to draw the line and a double click to finish drawing.": "Esta herramienta te permite recortar polígonos. Pulsa sobre el mapa para dibujar la línea y haz doble click para dejar de dibujar.",
  "This tool lets you draw polygons. Try to draw a simple one. Make sure you close it!": "Esta herramienta te permite dibujar polígonos. Prueba a dibujar uno simple. Asegúrate de que quede cerrado!",
  "This tool lets you measure the distance between a set of points. Use single clicks to set the points and a double click to finish drawing.": "Esta herramienta te permite calcular la distancia entre un conjunto de puntos. Pulsa sobre el mapa para añadir puntos y haz doble click para dejar de dibujar.",
  "Those fields are mandatory": "Estos campos son obligatorios",
  "TIME_COMPARISON_DROP_ZONE": "Arrastra aquí...",
  "TINTA BARROCA": "TINTA BARROCA",
  "TINTA FRANCISCA": "TINTA FRANCISCA",
  "TINTO": "TINTO",
  "TINTO CÃO": "TINTO CÃO",
  "tipo de dosificacion": "Tipo de dosificación",
  "to": "Hasta",
  "today": "hoy",
  "todos": "Todos",
  "tons of cane - annual vision": "Toneladas de Caña - Visión anual",
  "Total": "Total",
  "total production": "Producción total (Kg)",
  "total_fruits_bigSize": "Total de frutos grandes",
  "total_fruits_mediumSize": "Total de frutos medianos",
  "total_fruits_smallSize": "Total de frutos pequeños",
  "total_weight": "Peso total",
  "total_weight_bigSize": "Peso total de frutos grandes",
  "total_weight_canes": "Peso total de caña",
  "total_weight_mediumSize": "Peso todal de frutos medianos",
  "total_weight_smallSize": "Peso total de frutos pequeños",
  "TOURIGA FRANCA": "TOURIGA FRANCA",
  "training": "Entrenamiento",
  "TRINCADEIRA": "TRINCADEIRA",
  "Try our mobile app for a better experience": "¡Utiliza nuestra APP para una experiencia perfecta!",
  "type": "Cultivo",
  "Type": "Tipo de cultivo",
  "type here your notes": "escribe aquí tus notas",
  "Type your text": "Nombre de parcela",
  "type_sampler": "Tipo de aforo",
  "Undo Action": "Deshacer acciones",
  "unidades": "Unidades",
  "unitSystem": "Unidades",
  "Unknown": "Desconocido",
  "unnamed field": "Parcela sin nombre",
  "unsaved changes will be lost": "se perderán los cambios no guardados",
  "update sample": "actualizar muestra",
  "updated": "Actualizado",
  "Use this buttons to select which layer to compare.": "Utiliza este botón para selecciónar qué capa comparar",
  "Use this tool to compare two flights temporally. To start, drag one of the flights into the one you want to compare it with.": "Utiliza esta herramienta para comparar dos vuelos temporalmente. Para empezar, arrastra uno de los vuelos hacia el vuelo con el cual lo quieres comparar.",
  "Use this tool to freely move the created polygons.": "Utiliza esta herramienta para mover libremente los polígonos creados anteriormente.",
  "used": "usado",
  "Username": "Nombre de usuario",
  "users": "Usuarios",
  "Value": "Valor",
  "variabilidad multiparcela": "Variabilidad Multiparcela",
  "variabilidad parcela (vra)": "Variabilidad Parcela (VRA)",
  "variability": "Variabilidad",
  "Variedad": "Variedad",
  "vegetative growth": "Crecimiento Vegetativo",
  "vegetative growth per day": "Crecimiento Vegetativo Acumulado Diario",
  "vegetative growth variability": "Variabilidad del Crecimiento Vegetativo",
  "Ver detalles": "Ver detalles",
  "very high": "Muy alto",
  "very high level": "Muy alto",
  "very low": "Muy bajo",
  "very low level": "Muy bajo",
  "view": "ver",
  "vigor": "VIGOR",
  "vigor a nivel árbol": "Vigor / planta",
  "vigor acumulado dos meses (ndvi)": "Vigor acumulado dos meses (NDVI)",
  "vigor acumulado un año (ndvi)": "Vigor acumulado un año (NDVI)",
  "vigor vegetativo": "Vigor vegetativo",
  "vigor vegetativo - pcd": "Vigor Vegetativo - PCD",
  "vigor vegetativo (ndvi)": "Vigor Vegetativo (NDVI)",
  "vigor vegetativo (ndvi) - fases tempranas": "Vigor Vegetativo (NDVI) - Fases Tempranas",
  "vigor zonificado": "Vigor zonificado",
  "VINHÃO": "VINHÃO",
  "Visibility": "Visibilidad",
  "Vulnerable area": "Área vulnerable",
  "wait": "esperar",
  "warning": "Alerta",
  "warning map": "Mapa de alertas",
  "Water level": "Nivel de agua",
  "We are processing the data for your flight. Results will be available soon.": "Estamos procesando los datos de su vuelo, por favor espere a que estén disponibles",
  "weeds area": "Área de malas hierbas",
  "week": "Semana",
  "weight_1": "Peso 1",
  "weight_2": "Peso 2",
  "weight_3": "Peso 3",
  "weight_4": "Peso 4",
  "weight_5": "Peso 5",
  "With this tool you can draw circles. Click to place the center, and click again to choose the radius.": "Con esta herramienta puedes dibujar círculos. Haz click una vez para colocar el centro, y otra para elegir el radio.",
  "With this tool, you can draw a polygon with 3 or more points to measure its area": "Con esta herramienta puedes dibujar un polígono con 3 o más puntos para medir su área.",
  "without warning": "Sin alerta",
  "Wrong credentials": "Credenciales inválidas",
  "year": "Año",
  "years": "Año",
  "You can change here the information of the new field.": "Puedes modificar aquí la información del nuevo campo.",
  "You can move this bar to compare the layers temorally.": "Puedes  esta barra para comparar las capas temporalmente",
  "You can only compare dates from the same sources": "Solo puedes comparar fechas de las mismas fuentes",
  "You can use this button at any moment to undo your last actions.": "Puedes utiliar este botón en cualquier momento para deshacer tus últimas acciones.",
  "You can use this slider to change the opacity of the selected layer.": "Puedes utilizar este slider para cambiar la opacidad de la capa seleccionada.",
  "You cannot create field because you don't have any customers. Please ask us for adding ones": "No puedes crear parcela porque no tiene clientes. Porfavor, pidanos que añadamos alguno.",
  "You must enter a decimal": "Debe introducir un decimal",
  "You must enter an integer": "Debe introducir un número entero",
  "You must select an irrigation type": "Debe seleccionar un tipo de riego",
  "You must type a field name": "Debes escribir un nombre de parcela",
  "You must type a text": "Debe introducir texto",
  "Your field has been successfully deleted.": "El campo ha sido borrado correctamente",
  "Your LAYERS version has expired. Click to update.": "Tu versión de LAYERS ha caducado. Haz click para actualizar."
}
