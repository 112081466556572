import { RECEIVE_CUSTOMERS, REQUEST_CUSTOMERS, RECEIVE_PROFILE, RECEIVE_LOGIN, ERROR_LOGIN, RECEIVE_PATCH_USER_CONFIG } from '../actions/users'
// eslint-disable-next-line import/no-duplicates
import { addDeliverableUIFeatures } from '../actions/deliverables'
// eslint-disable-next-line import/no-duplicates
import { SET_LAYER_TOOL_VALUE } from '../actions/deliverables'
import parseRolesString from '@layers-frontend/commons/helpers/parseRolesString'

export const customers = (state = [], action) => {
  switch (action.type) {
    case REQUEST_CUSTOMERS:
      return state
    case RECEIVE_CUSTOMERS:
      return action.payload
    default:
      return state
  }
}

const initialState = {
  token: null,
  errorLogin: null
}

export const user = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_PROFILE:
      return {
        ...action.payload,
        roles: parseRolesString(action.payload.roles),
        // eslint-disable-next-line no-extra-boolean-cast
        prospect_layers: !!action.payload.prospect_layers
          ? action.payload.prospect_layers.map(layer => ({
              ...layer,
              deliverable: addDeliverableUIFeatures(layer.deliverable, false)
            }))
          : []
      }
    case SET_LAYER_TOOL_VALUE:
      return {
        ...state,
        prospect_layers: state.prospect_layers.map(layer => ({
          ...layer,
          deliverable: {
            ...layer.deliverable,
            [action.payload.tool]: layer.id === action.payload.layer.id ? action.payload.value : layer.deliverable[action.payload.tool]
          }
        }))
      }

    case RECEIVE_LOGIN:
      return {
        ...state,
        token: action.token
      }

    case RECEIVE_PATCH_USER_CONFIG:
      return {
        ...state,
        config: action.config
      }
    case ERROR_LOGIN:
      return {
        ...state,
        errorLogin: action.error
      }

    default:
      return state
  }
}
