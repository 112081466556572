import React, { useState } from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import moment from 'moment'
import styled, { css } from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
// eslint-disable-next-line import/no-duplicates
import { disabledPattern, disabledPatternSolid } from '../../../constants'
import { NO_CULTIVO_ID } from '@layers-frontend/commons/constants'

import first from 'lodash/first'
import floor from 'lodash/floor'
import get from 'lodash/get'
import last from 'lodash/last'
import map from 'lodash/map'
import toNumber from 'lodash/toNumber'
import capitalize from 'lodash/capitalize'

import { UIStyles } from '../../../theme'
import { useFormContext } from 'react-hook-form'
// eslint-disable-next-line import/no-duplicates
import { FIELD_SEASON_FORM_KEYS as KEYS } from '../../../constants'
import RenderIf from '@layers-frontend/commons/helpers/RenderIf'
import { StyledTooltip } from '../../UIComponents/StyledTooltip'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  height: 2rem;
  padding: 0 1.5rem;
  margin-bottom: 2rem;
`

const Season = styled.div`
  color: ${themeColors.whiteColor};
  border: 1px solid;
  border-right: 0;
  flex: ${props => props.flexWidth};
  min-width: 1.5rem;
  transition: background-color 0.5s ease-out;
  ${props =>
    props.noCrop
      ? css`
          background: ${themeColors.transparent};
          &:after {
            display: block;
            content: '';
            background: url(${props => (props.isSelected ? disabledPatternSolid : disabledPattern)}) repeat;
            width: 100%;
            height: 100%;
          }
        `
      : css`
          background: ${themeColors.blueColor};
        `};
  ${props =>
    props.isSelected
      ? css`
          background: ${themeColors.vomitColorTransparent};
          cursor: initial;
        `
      : css`
          cursor: pointer;
        `}
  &:first-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid;
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
  }
  position: relative;
  &:before {
    display: ${props => (props.todayOffset ? 'block' : 'none')};
    content: '';
    text-align: center;
    height: 160%;
    width: 0.25rem;
    background-color: ${UIStyles.vomitColor};
    position: absolute;
    top: -30%;
    border-radius: 0.2rem;
    left: ${props => props.todayOffset + '%'};
  }
`

const TodayLabel = styled.span`
  position: absolute;
  bottom: -3rem;
  left: ${props => props.todayOffset + '%'};
  transform: translateX(-50%);
  color: ${UIStyles.whiteColor};
  font-weight: 300;
  font-size: 1.2rem;
`

const ErrorBadge = styled.span`
  position: absolute;
  height: 1.2rem;
  width: 1.2rem;
  border-radius: 50%;
  background-color: ${UIStyles.redColor};
  top: -0.6rem;
  right: calc(50% - 0.6rem);
  z-index: 1;
`

function SeasonTimelineSelector({ t, seasons, activeSeasonIndex, onSeasonSelect }) {
  const [tooltipText, setTooltipText] = useState(null)
  const {
    getValues,
    formState: { errors }
  } = useFormContext()
  const seasonsErrors = get(errors, KEYS.SEASONS)

  const handleClick = index => () => {
    onSeasonSelect(index)
  }

  const createSeasonDiv = tooltipStartText => (season, index) => {
    const isSelected = index === activeSeasonIndex
    const hasError = !!get(seasonsErrors, index)
    const today = moment()
    const startDate = moment(get(season, 'startDate'), 'YYYY-MM-DD')
    const endDate = moment(get(season, 'endDate'), 'YYYY-MM-DD')
    const isTodayInSeason = today.isBetween(startDate, endDate)
    const seasonLength = endDate.diff(startDate, 'days')
    const flexWidth = floor(seasonLength * multiplier)

    const label = getValues(`${KEYS.SEASONS}.${index}.${KEYS.SEASON_LABEL}`) || get(season, 'label')
    const tooltipText = `${label ? label + ' / ' : ''}${startDate.format('DD-MM-YYYY')} / ${endDate.format('DD-MM-YYYY')}`

    const todayOffset = isTodayInSeason ? floor(Math.abs((today.diff(startDate, 'days') * 100) / seasonLength)) : undefined

    const formType = getValues(`${KEYS.SEASONS}.${index}.${KEYS.TYPE}`)
    const reduxType = get(season, ['type', 'id'])
    // eslint-disable-next-line no-unneeded-ternary
    const noCrop = (formType ? formType : reduxType) === NO_CULTIVO_ID

    return (
      <Season
        key={index}
        onMouseLeave={() => setTooltipText(null)}
        onMouseEnter={() => setTooltipText(`${capitalize(tooltipStartText)}: "${tooltipText}"`)}
        noCrop={noCrop}
        flexWidth={flexWidth}
        isSelected={isSelected}
        onClick={handleClick(index)}
        todayOffset={todayOffset}
        hasError={hasError}
      >
        <>
          <RenderIf condition={todayOffset}>
            <TodayLabel todayOffset={todayOffset}>{capitalize(t('today'))}</TodayLabel>
          </RenderIf>
          <RenderIf condition={hasError}>
            <ErrorBadge />
          </RenderIf>
        </>
      </Season>
    )
  }

  const earliestSeasonStartDate = moment(get(last(seasons), 'startDate'), 'YYYY-MM-DD')
  const latestSeasonEndDate = moment(get(first(seasons), 'endDate'), 'YYYY-MM-DD')

  const timelineTotalLength = latestSeasonEndDate.diff(earliestSeasonStartDate, 'days')
  const multiplier = toNumber(100 / timelineTotalLength)

  const seasonsDivs = map(seasons, createSeasonDiv(t('select season')))

  return (
    <StyledTooltip title={<>{tooltipText}</>} open={!!tooltipText} followCursor position="top-start">
      <Wrapper>{seasonsDivs}</Wrapper>
    </StyledTooltip>
  )
}

export default withTranslator(SeasonTimelineSelector)
