import { RECEIVE_REPORT_TYPES } from '../storeConstants'

const initialState = []

export default function reportsTypes(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_REPORT_TYPES:
      return action.types
    default:
      return state
  }
}
