import React from 'react'
import { UIStyles } from '../../theme'
import { withTranslator } from '../HOCComponents/withTranslator'
import styled from 'styled-components'
import { Button, Checkbox, FormControlLabel, Icon } from '@mui/material'

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 10px;
`

export const FormSection = ({ title, icon, className, children, rightTitle, style, titleStyle, little, align }) => (
  <div
    className={'formSection ' + (typeof little !== 'undefined' ? 'col-md-6' : 'col-md-12') + ' ' + (typeof className !== 'undefined' ? className : '')}
    style={style}
  >
    <div className="desc-icon" style={{ textAlign: align }}>
      {icon && <i className={icon} />}
      {title && (
        <h2 style={{ ...titleStyle }}>
          {title} {rightTitle && <div>{rightTitle}</div>}
        </h2>
      )}
    </div>

    <div className="autocomp">{children}</div>
  </div>
)

export const StyledLabel = styled.span`
  font-weight: ${UIStyles.labelFontWeight};
  font-size: 20px;
  color: ${UIStyles.vomitColor};
`

const checkboxSx = {
  color: UIStyles.vomitColor
}

export const ButtonIcon = ({ icon, style, iconStyle, href, onClick, disabled, testId }) => (
  <Button href={href} target="_blank" iconClassName={icon} style={style} disabled={disabled} onClick={onClick} data-testid={testId}>
    <Icon className={icon} style={{ ...iconStyle }} />
  </Button>
)

export const CheckboxDashboard = withTranslator(({ onCheck, style, icon, label, value, t }) => (
  <FilterWrapper>
    <FormControlLabel
      label={<StyledLabel>{t(label)}</StyledLabel>}
      style={{ fontWeight: UIStyles.labelFontWeight, fontSize: '20px' }}
      control={
        <>
          <i className={icon} style={{ color: UIStyles.vomitColor, fontSize: '20px', lineHeight: 'normal', marginRight: '-10px' }} />
          <Checkbox style={{ ...style }} checked={value} onChange={() => onCheck(!value)} sx={checkboxSx} />
        </>
      }
    />
  </FilterWrapper>
))
