import themeColors from '@layers-frontend/commons/styles/themeColors'
import { createTheme } from '@mui/material'

export const UIStyles = themeColors

export const paletteTheme = {
  primary: {
    light: UIStyles.vomitLight,
    main: UIStyles.vomitColor,
    dark: UIStyles.vomitColor
  },
  secondary: {
    main: themeColors.grayColor
  },
  text: {
    primary: UIStyles.whiteColor,
    secondary: UIStyles.whiteColor,
    disabled: themeColors.grayColor
  }
}

export const typographyTheme = {
  fontSize: 20
}

export const baseThemeComponents = {
  MuiInput: {
    styleOverrides: {
      root: {
        '::before': {
          borderBottomColor: UIStyles.grayColor,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid'
        },
        fontSize: 15
      },
      input: {
        '::placeholder': {
          color: UIStyles.grayColor,
          opacity: 1
        }
      }
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: UIStyles.grayColor,
        fontSize: 15
      }
    },
    defaultProps: {
      color: 'secondary'
    }
  },
  MuiAutocomplete: {
    styleOverrides: {
      popper: {
        zIndex: 1600,
        maxHeight: 500
      },
      option: {
        color: UIStyles.black,
        fontSize: 15,
        paddingBottom: 10,
        paddingTop: 10,
        '&:hover': {
          background: UIStyles.vomitColor
        }
      },
      noOptions: {
        color: '#ccc',
        fontSize: 15
      }
    }
  },
  MuiChip: {
    styleOverrides: {
      root: {
        color: UIStyles.whiteColor,
        height: 30,
        fontSize: 14
      },
      deleteIcon: {
        color: UIStyles.redColor,
        width: 18,
        '&:hover': {
          color: UIStyles.redColor
        }
      },
      avatar: {
        color: UIStyles.whiteColor,
        width: 30,
        height: 30,
        marginLeft: 0,
        fontSize: 14
      }
    }
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        background: UIStyles.blueColor,
        maxWidth: '35%'
      }
    }
  },
  MuiDatePicker: {
    styleOverrides: {
      root: {
        color: UIStyles.vomitColor,
        fontSize: '1.2rem'
      },
      contentWrapper: {
        background: 'transparent',
        '& .MuiInputAdornment ': {
          color: themeColors.whiteColor
        },
        '&  .MuiButtonBase-root-MuiIconButton-root': {
          color: themeColors.whiteColor
        }
      }
    }
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        color: UIStyles.white,
        fontSize: 13
      }
    }
  },

  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 12,
        position: 'absolute',
        bottom: '-20px',
        color: UIStyles.redColor
      }
    }
  },
  MuiSwitch: {
    styleOverrides: {
      track: {
        background: UIStyles.whiteColor
      }
    }
  },
  MuiIcon: {
    styleOverrides: {
      root: {
        width: 30
      }
    }
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: UIStyles.grayColor
      }
    }
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        background: UIStyles.mediumDarkGrayColor
      }
    }
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        padding: '10px 0',
        justifyContent: 'space-between',
        marginTop: 5,
        color: UIStyles.vomitColor
      }
    }
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        fontSize: '3rem',
        color: 'white',
        textAlign: 'center'
      }
    }
  },
  MuiSelect: {
    styleOverrides: {
      icon: {
        color: UIStyles.grayColor,
        fontSize: 25
      },
      root: {
        '&.Mui-disabled': {
          color: UIStyles.grayColor
        }
      }
    }
  },
  MuiTextField: {
    defaultProps: {
      variant: 'standard',
      InputLabelProps: {
        shrink: true
      }
    }
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        color: UIStyles.black,
        '&:hover': {
          background: UIStyles.vomitColor
        },
        '&.Mui-selected': {
          background: UIStyles.vomitColor
        },
        '&.Mui-selected:hover': {
          background: UIStyles.vomitColor
        }
      }
    }
  },
  MuiSnackbarContent: {
    styleOverrides: {
      action: {
        margin: '0 auto'
      },
      root: {
        justifyContent: 'center',
        fontSize: 15
      }
    }
  },
  MuiPickersLayout: {
    styleOverrides: {
      contentWrapper: {
        // background: 'rgba(8,44,63,.8)',
        color: themeColors.whiteColor,
        '& .MuiIconButton-root': {
          color: themeColors.grayColor
        },

        '& .MuiIconButton-root:hover': {
          background: themeColors.darkGrayHoverColor
        },

        '& .MuiPickersCalendarHeader-label': {
          color: themeColors.whiteColor,
          fontSize: 17.5
        },

        '& .MuiCalendarPicker-viewTransitionContainer': {
          overflow: 'hidden'
        },

        '& .MuiCalendarPicker-viewTransitionContainer:has(.MuiYearPicker-root)': {
          overflowY: 'auto'
        },

        '& .MuiPickersMonth-monthButton.Mui-disabled': { color: themeColors.grayColor },

        '& .MuiPickersYear-yearButton.Mui-disabled': { color: themeColors.grayColor },

        '& .MuiTypography-root': {
          color: themeColors.grayColor,
          fontSize: '13px'
        },

        '& .MuiButton-text': {
          color: themeColors.whiteColor,
          background: 'none',
          width: '36px',
          minWidth: '36px',
          height: '36px',
          borderRadius: '50%',
          margin: '0 2px',
          pointerEvents: 'none'
        },

        '& .MuiButton-root:disabled': {
          color: themeColors.grayColor
        },

        '& .Mui-withSources': {
          background: themeColors.customLightOverlayBackground,
          cursor: 'pointer',
          pointerEvents: 'auto'
        },

        '& .Mui-withSources:hover': {
          background: themeColors.vomitLightHover,
          cursor: 'pointer'
        },

        '& .Mui-selected,.Mui-selected:hover ': {
          background: themeColors.vomitColorTransparent
        },

        '& .PrivatePickersYear-root .Mui-selected, .MuiMonthPicker-root .Mui-selected, & .PrivatePickersYear-root .Mui-selected:hover, .MuiMonthPicker-root .Mui-selected:hover': {
          color: themeColors.whiteColor,
          background: themeColors.lightBlue
        },

        '& .MuiTypography-root:disabled, .PrivatePickersYear-root .Mui-disabled': {
          color: themeColors.lightGrey,
          background: themeColors.transparent
        },

        '& .MuiTypography-root:disabled:hover, .PrivatePickersYear-root .Mui-disabled:hover': {
          background: themeColors.transparent
        },

        '& .PrivatePickersYear-yearButton:hover, .PrivatePickersMonth-root:hover': {
          background: themeColors.lightBlue
        },

        '& .PrivatePickersSlideTransition-root .Mui-disabled': {
          color: themeColors.lightGrey
        }
      },
      root: {
        color: themeColors.whiteColor,
        '& .MuiPickersLayout-contentWrapper': {
          backgroundColor: themeColors.blueColor
        },
        '& .MuiPickersLayout-toolbar': {
          display: 'none'
        },
        '& .MuiDialogActions-root': {
          background: 'red',
          display: 'none'
        },
        background: 'transparent'
      }
    }
  }
}

export const baseTheme = createTheme({
  palette: paletteTheme,
  typography: typographyTheme,
  components: baseThemeComponents
})

export const deliverablesSelectorTheme = createTheme({
  palette: paletteTheme,
  typography: typographyTheme,
  components: {
    ...baseThemeComponents,
    MuiPaper: {
      styleOverrides: {
        root: {
          background: themeColors.blueColorOpacity
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: themeColors.whiteColor
        }
      }
    }
  }
})
