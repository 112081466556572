// prettier-ignore
export default {
  "% Fallas": "% Fallas",
  "% Mala Hierba": "% Erva daninha",
  "ACELGA": "ACELGA",
  "ACELGA VERDE": "ACELGA VERDE",
  "action": "açao",
  "ag_accesses_the_viewer": "k. O AG acessa o visor?",
  "AGUACATE": "ABACATE",
  "AJO": "ALHO",
  "ALBARICOQUE": "DAMASCO",
  "ALBARINO": "ALBARINO",
  "ALFALFA": "ALFALFA",
  "ALGODON": "ALGODÃO",
  "aluminum": "Alumínio",
  "aluminum_saturation": "Saturação de Alumínio",
  "annotation": "anotação",
  "aphid_controller": "pulgón - controlador",
  "aphid_infested": "pulgón - infestación",
  "aphid_infested_leafs": "pulgón - hojas infestadas",
  "aphid_mite_total_leafs": "pulgón y ácaro - total hojas",
  "APIO": "AIPO",
  "apparent_density": "Densidad aparente",
  "ARANDANOS": "MIRTILO",
  "ARBOSANA": "ARBOSANA",
  "ARROZ": "ARROZ",
  "assesor_name": "Nombre Evaluador",
  "atr": "Atr",
  "AVENA": "AVEIA",
  "BARBECHO": "POUSIO",
  "BATAVIA": "ALFACE",
  "beans_10_plants": "Plantas de 10 grãos",
  "billaea_healthy_pupe": "billaea - pupas sanas",
  "billaea_larva": "billaea - larvas",
  "billaea_parasitized_larva": "billaea - larvas parasitadas",
  "billaea_residual_pupe": "billaea - pupas residual",
  "bipolaris_sacchari_incidence": "bipolaris sacchari \"mancha del ojo\" - porcentaje de incidencia",
  "bipolaris_sacchari_severity": "bipolaris sacchari \"mancha del ojo\" - severidad",
  "bipolaris_sacchari_total_infected_leafs": "bipolaris sacchari \"mancha del ojo\" - total hojas infectadas",
  "BLANCO": "BRANCO",
  "blastobasis_healthy_chrysalis": "blastobasis - crisálidas sanas",
  "blastobasis_larva_number": "blastobasis - larvas",
  "blastobasis_residual_chrysalis": "blastobasis - crisálidas residual",
  "boron": "Boro",
  "bouquet10roots_2_8cm": "02.Buquê 10 raízes (2-8cm)",
  "bouquet10roots_2cm": "01.Buquê 10 raízes (0-2cm)",
  "bouquet10roots_8cm": "03.Buquê 10 raízes (>8cm)",
  "brix": "Brix",
  "BROCOLI": "BRÓCOLIS",
  "bud_blastobasis_drilled": "yemas diatraea blastobasis perforados",
  "bud_dehydrated": "yemas deshidratadas",
  "bud_diatrea_drilled": "yemas diatraea perforados",
  "bud_mechanical_damage": "yemas daño mecánico",
  "bud_sprout": "yemas - lalas",
  "bud_total": "yemas totales",
  "bud_turgid": "yemas turgentes",
  "CABERNET SAUVIGNON": "CABERNET SAUVIGNON",
  "CALABACIN": "ABOBRINHA",
  "calcium": "Cálcio",
  "calcium_saturation": "Saturação de Cálcio",
  "CAÑA DE AZUCAR": "CANA DE AÇÚCAR",
  "CAQUI": "CAQUÍ",
  "casida_afa_perc": "Cásida - AFA(%)",
  "casida_n_of_adults_in_50_plants": "Cásida - Nº de adultos em 50 plantas",
  "casida_n_of_eggs_in_50_plants": "Cásida - Nº de ovos em 50 plantas",
  "casida_n_of_large_larvae_in_50_plants": "Cassida - No. de larvas grandes 50 plantas",
  "casida_n_of_small_larvae_in_50_plants": "Cassida - No. de pequenas larvas 50 plantas",
  "CAUCHO": "SERINGUEIRA",
  "CEBADA": "CEVADA",
  "CEBADA HIBRIDA": "CEVADA HÍBRIDA",
  "CEBADA MALTERA": "CEVADA PARA MALTE",
  "CEBOLLA": "CEBOLA",
  "cercospora_20_100_spots_leaf": "Cercospora -F- 20-100 manchas/folha",
  "cercospora_50_afa": "Cercospora -L- 50% AFA",
  "cercospora_50_perc_leaves_ext_with_80_100_severity": "Cercospora -K- 50% folhas ext com 80-100% de severidade",
  "cercospora_75_afa": "Cercospora -M- 75%AFA",
  "cercospora_90_100_afa": "Cercospora -N- 90-100%AFA",
  "cercospora_healthy_plant": "Cercospora -A- Planta saudável",
  "cercospora_isolated_spots_on_some_leaves_10_15_sl_100_leaves": "Cercospora -D- Manchas isoladas em algumas folhas (10-50%HS/100 folhas).",
  "cercospora_isolated_spots_on_some_leaves_5_10_sl_100_leaves": "Cercospora -C- Manchas isoladas em algumas folhas (5-10%HS/100 folhas)",
  "cercospora_isolated_spots_on_some_leaves_minus_5_sl_100_leaves": "Cercospora -B- Manchas isoladas em algumas folhas (<5%HS/100 folhas)",
  "cercospora_leaf_necrosis_ext": "Cercospora -H- Necrose das folhas EXT.",
  "cercospora_leaves_ext_almost_total_necrosis": "Cercospora -J- Folhas EXT: necrose quase total",
  "cercospora_leaves_with_major_100_sports": "Cercospora -G- Folhas com > 100 manchas",
  "cercospora_minus_20_spots_leaf": "Cercospora -E- <20 manchas/folha",
  "cercospora_necrosis_on_most_leaves_int": "Cercospora -I- Necrose na maioria das folhas INT.",
  "cercospora_treatment": "Cercospora -o- Tratamento",
  "CEREZA": "CEREJA",
  "CESPED": "GRAMA",
  "CHARDONNAY": "CHARDONNAY",
  "chromium": "Crômio",
  "cic": "Cic",
  "CIRUELA": "AMEIXA",
  "clay": "Argila",
  "CLEMENTINA": "CLEMENTINA",
  "close": "fechado",
  "COLZA": "COLZA",
  "copper": "Cobre",
  "cotesia_cocon": "cotesia - cocones",
  "cotesia_parasitized_larva": "cotesia - larvas parasitadas",
  "cotyledon": "cotilédones",
  "current_ndre_status": "a. Estado atual do NDRE",
  "date_of_first_informative_email_sent": "j. Data do envio do primeiro e-mail informativo",
  "date_of_hemav_ndre_layer_screenshot": "h. Data do envio da captura da camada NDRE da Hemav",
  "defoliator_leaf_evaluated": "defoliador - hojas evaluadas",
  "defoliator_marasmia_sp_larva_alive": "defoliador - marasmia sp. - larvas vivas",
  "defoliator_marasmia_sp_larva_death": "defoliador - marasmia sp. - larvas muertas",
  "defoliator_marasmia_sp_larva_per_stem": "defoliador - marasmia sp. - larvas por tallo",
  "defoliator_mythimna_sp_larva_alive": "defoliador - mythimna sp. - larvas vivas",
  "defoliator_mythimna_sp_larva_death": "defoliador - mythimna sp. - larvas muertas",
  "defoliator_mythimna_sp_larva_per_stem": "defoliador - mythimna sp. - larvas por tallo",
  "defoliator_spodoptera_sp_larva_alive": "defoliador - spodoptera sp. - larvas vivas",
  "defoliator_spodoptera_sp_larva_death": "defoliador - spodoptera sp. - larvas muertas",
  "defoliator_spodoptera_sp_larva_per_stem": "defoliador - spodoptera sp. - larvas por tallo",
  "defoliator_stem_total": "defoliador - tallos totales",
  "depth": "Profundidade",
  "desconocido": "Desconhecido",
  "DESCONOCIDO": "DESCONHECIDO",
  "diatraea_saccharalis_larva_big": "diatraea sachharalis - larvas grandes",
  "diatraea_saccharalis_larva_healthy_chrysalis": "diatraea sachharalis - crisálidas sanas",
  "diatraea_saccharalis_larva_medium": "diatraea sachharalis - larvas medianas",
  "diatraea_saccharalis_larva_residual_chrysalis": "diatraea sachharalis - crisálidas residual",
  "diatraea_saccharalis_larva_small": "diatraea sachharalis - larvas pequeñas",
  "diatraea_saccharalis_larva_total": "diatraea sachharalis - total larvas",
  "disease": "Doença",
  "Disease": "Doença",
  "disease_level": "08.Nível da enfermidade",
  "distance_between_rows": "03.Distância entre ranhuras",
  "does_it_follow_the_recommendation": "f. Segue a recomendação?",
  "Drip": "Gotejamento",
  "dry_weight": "Peso seco",
  "effective_cation_exchange_capacity": "Capacidade Efetiva de Troca de Cátions (CECT)",
  "ESCAROLA": "ENVÍDIA",
  "ESCAROLA LISA": "ENVÍDIA LISA",
  "ESCAROLA RIZADA": "ENVÍDIA FRISADA",
  "ESPARRAGO": "ASPARGOS",
  "ESPINACA": "ESPINAFRE",
  "Estrés hídrico (LWS)": "Estresse hídrico (LWS)",
  "evaluator": "10.Nombre evaluador",
  "Ex": "Ex.",
  "exchangeable_acidity": "Acidez Trocável",
  "fire": "Incêndio",
  "flowering": "Floração",
  "forecast_yield_sampling_1": "PREVISÃO DE RENDIMENTO 1",
  "forecast_yield_sampling_2": "PREVISÃO DE RENDIMENTO 2",
  "forecast_yield_sampling_3": "PREVISÃO DE RENDIMENTO 3",
  "forecast_yield_sampling_4": "PREVISÃO DE RENDIMENTO 4",
  "fresh_weight": "Peso fresco",
  "FRIJOL": "FEIJÃO",
  "fruits_count": "Número de frutas",
  "FRUTAL": "FRUTAS",
  "Generic": "Genérica",
  "GEWURZTRAMINNER": "GEWURZTRAMINNER",
  "GIRASOL": "GIRASOL",
  "gm_avg_ndvi": "Vigor vegetativo (NDVI-Drone)",
  "gm_luvi_ndvi_mean": "Vigor vegetativo (NDVI-LUVI)",
  "gm_ndmi_mean": "Umidade vegetativa (NDMI)",
  "gm_ndre_mean": "Nitrogênio-Clorofila (NDRE)",
  "gm_perc_overseeding": "% Falha",
  "gm_perc_weeds": "% Erva daninha",
  "GODELLO": "GODELLO",
  "GOLDEN DELICIOUS": "GOLDEN DELICIOUS",
  "GOLDEN REINDERS": "GOLDEN REINDERS",
  "Grams": "Gramas",
  "GRANADA": "ROMÃ",
  "green_dots": "pontos verdes",
  "GRENACHE BLANC": "GRENACHE BLANC",
  "GUISANTE": "ERVILHA",
  "HABA": "VAGEM",
  "hanger_installation": "Instalação de poleiro",
  "height": "Altura",
  "HIBRIDO": "HÍBRIDO",
  "high irrigation": "Estresse hídrico alto",
  "high_n_from_irrigation_water": "d. Alto N pela água de irrigação",
  "high_n_from_nitrogen_input": "e. Alto N pela contribuição de N",
  "high_n_from_previous_years": "c. Alto N de anos anteriores",
  "higher level": "Nível Mais Alto",
  "HIGO": "FIGO",
  "Humedad Vegetativa (NDMI) ": "Umidade vegetativa (NDMI)",
  "humidity": "Umidade",
  "humidity_100_beans": "Humedad de 100 grãos",
  "Imperial": "Imperial",
  "inspection": "inspeção",
  "internodes_blastobasis_damage_longitude": "entrenudos blastobasis longitud daño",
  "internodes_blastobasis_drilled": "entrenudos blastobasis perforados",
  "internodes_diatraea_damage_longitude": "entrenudos diatrea longitud daño",
  "internodes_diatraea_drilled": "entrenudos diatrea perforados",
  "internodes_evaluated": "entrenudos total",
  "internodes_healthy": "entrenudos sanos",
  "internodes_mechanical_damage": "entrenudos daño mecánico",
  "internodes_physiological_damage": "entrenudos daño fisiológico",
  "iron": "Ferro",
  "irrigation": "IRRIGAÇÃO",
  "Irrigation": "Irrigação",
  "irrigation_dosage": "Dosagem do Irrigação",
  "irrigation_uniformity": "Uniformidade de irrigação",
  "KUMQUATS": "XINXIM",
  "language": "Idioma do sistema",
  "larva_number_big": "estadio larval - larva grande",
  "larva_number_medium": "estadio larval - larva mediana",
  "larva_number_small": "estadio larval - larva pequeña",
  "last_fertilizer_app_date": "b. Data da última aplicação de fertilizante",
  "last_irrigation": "Última rega",
  "lead": "Chumbo",
  "Leaf": "Folha",
  "leaf_moisture_status": "Estado de umidade da folha",
  "LECHUGA": "ALFACE",
  "LECHUGA ICEBERG": "ALFACE AMERICANA",
  "leptosphaeria_sacchari_incidence": "leptosphaeria sacchari \"mancha anular\" - porcentaje de incidencia",
  "leptosphaeria_sacchari_severity": "leptosphaeria sacchari \"mancha anular\" - severidad",
  "leptosphaeria_sacchari_total_infected_leafs": "leptosphaeria sacchari \"mancha anular\" - total hojas infectadas",
  "LIMON": "LIMÃO",
  "linear_meters": "02.Metros lineares",
  "loam": "Franco",
  "loss_of_vigor": "Perda de vigor",
  "low irrigation": "Estresse hídrico baixo",
  "lower level": "Nível Mais Baixo",
  "lowered_unload": "03.Baixado na descarrega",
  "MACABEU": "MACABEO",
  "MACABEU VIURA": "MACABEO VIURA",
  "magnesium": "Magnésio",
  "magnesium_saturation": "Saturação de Magnésio",
  "MAIZ": "MILHO",
  "MALVASIA AROMATICA": "MALVASIA AROMATICA",
  "MALVASIA DE SITGES": "MALVASIA DE SITGES",
  "MALVASIA GROSSA": "MALVASIA GROSSA",
  "MANDARINA": "TANGERINA",
  "manganese": "Manganês",
  "MANZANA": "MAÇÃ",
  "mean level": "Nível Meio",
  "medium irrigation": "Estresse hídrico medio",
  "medium_moisture_saturation": "Saturação Média de Umidade",
  "MELOCOTON": "PÊSSEGO",
  "MELON": "MELÃO",
  "mercury": "Mercúrio",
  "MERLOT": "MERLOT",
  "Metric": "Métrica",
  "microorganisms_larva": "larva microorganismos",
  "mite_grade": "ácaro - grado",
  "mite_infested": "ácaro - infestación",
  "mite_infested_leafs": "ácaro - hojas infestadas",
  "moisture_status": "07.Status da umidade",
  "molybdenum": "Molybdenum",
  "MONTONEC": "MONTONEC",
  "MONTONEGA": "MONTONEGA",
  "MOSCATELL DE ALEXANDRIA": "MOSCATELL DE ALEXANDRIA",
  "MOSCATELL ROMA": "MOSCATELL ROMA",
  "NARANJA": "LARANJA",
  "NDRE": "NDRE",
  "NDVI": "NDVI",
  "NECATRINA": "TANGERINA",
  "NECTARINA": "NECTARINA",
  "nickel": "Níquel",
  "Nitrogen": "Nitrogênio",
  "Nitrógeno Foliar": "Nitrogênio foliar",
  "nitrógeno-clorofila (ndre)": "Nitrogênio-Clorofila (NDRE)",
  "Nitrógeno-Clorofila (NDRE)": "Nitrogênio-Clorofila (NDRE)",
  "No crop or too dry": "Sem cultivo o muito seco",
  "NO CULTIVO": "SEM CULTURA",
  "No hay datos": "Não há dados",
  "NOGAL": "NOZ",
  "notification_to_the_farmer_high_n_index": "g. Notificação ao agricultor: Alto índice de N",
  "Observations": "Observações",
  "OLIVO": "OLIVEIRA",
  "opogona_larva_number": "opogona - larvas",
  "organic_material": "Matéria orgânica",
  "organic_oxidizable_carbon": "Carbono Orgânico Oxidável",
  "PANSA BLANCA": "PANSA BLANCA",
  "PANSAL": "PANSAL",
  "PARAGUAYO": "PÊSSEGO PARAGUAIO",
  "PARELLADA": "PARELLADA",
  "PATATA": "BATATA",
  "pattern": "Padrão",
  "PERA": "PÊRA",
  "percent_disease": "Porcentaje de doença",
  "perkinsiella_345_instar_nymph": "perkinsiella - ninfas 3-4-5 instar",
  "perkinsiella_adult": "perkinsiella - adultos",
  "perkinsiella_controller_coccinelidos": "perkinsiella - controladores - coccinelidos",
  "perkinsiella_controller_crysopas": "perkinsiella - controladores - crysopas",
  "perkinsiella_controller_spiders": "perkinsiella - controladores - arañas",
  "perkinsiella_controller_tytthus_parviceps": "perkinsiella - controladores - tytthus parviceps",
  "perkinsiella_controller_zelus": "perkinsiella - controladores - zelus",
  "perkinsiella_death_adult": "perkinsiella - adultos muertos",
  "perkinsiella_death_nymph": "perkinsiella - ninfas muertas",
  "petiole_sampling": "i. Coleta de amostras de pecíolo",
  "ph": "PH",
  "pheromone_trap_diatraea_adults_number": "trampa feromonas - adultos diatraea",
  "pheromone_trap_installation_date": "trampa feromonas - fecha instalación",
  "pheromone_trap_number": "trampa feromonas - trampas",
  "pheromone_trap_other_butterfly": "trampa feromonas - otras mariposas",
  "pheromone_trap_pheromone_change": "trampa feromonas - cambio de feromona",
  "phosphorus": "Fósforo",
  "PIMIENTO": "PIMENTÃO",
  "PINO": "PINHEIRO",
  "PINOT NOIR": "PINOT NOIR",
  "PISTACHO": "PISTACHE",
  "Pivot": "Pivô",
  "Plague": "Praga",
  "plague_level": "09.Nível de pragas",
  "Plant": "Planta",
  "Plant Density": "Densidade da planta",
  "plantation_date": "Data de plantio",
  "plants_per_surface_unit": "Plantas por unidade de área",
  "poda": "Poda",
  "pol": "Pol",
  "POMELO": "TORANJA",
  "POMELOS": "TORANJA",
  "potassium": "Potássio",
  "potassium_saturation": "Saturação de Potássio",
  "powdery_mildew_afa_perc": "Oídio - AFA(%)",
  "powdery_mildew_perc_of_affected_plants": "Oídio - % de plantas afetadas (INCIDÊNCIA)",
  "powdery_mildew_treatment": "Oidio - Tratamento",
  "Production": "Produção",
  "pseudomonas_rubrilineans_stem_number": "raya roja - total de tallos",
  "pseudomonas_rubrilineans_stem_number_affected": "raya roja - tallos afectados",
  "pseudomonas_rubrilineans_stem_number_with_symptoms": "raya roja - tallos con síntomas",
  "puccinia_melanocephala_accumulated_scale": "roya - escala acumulado",
  "puccinia_melanocephala_infestation": "roya - infestación",
  "puccinia_melanocephala_infested_leafs": "roya - hojas infestadas",
  "puccinia_melanocephala_points": "roya - puntos",
  "puccinia_melanocephala_total_leafs": "roya - hojas totales",
  "puccinia_sp_incidence": "puccinia sp \"roya\" - incidencia",
  "puccinia_sp_severity": "puccinia sp \"roya\" - severidad",
  "puccinia_sp_total_infected_leafs": "puccinia sp \"roya\" - total hojas infectadas",
  "PUERRO": "ALHO-PORRO",
  "Quality": "Qualidade",
  "QUINOA": "QUINOA",
  "RAIGRAS": "AZEVÉM",
  "real_leaves": "folhas verdadeiras",
  "reform": "Reforma",
  "REMOLACHA": "BETERRABA",
  "REMOLACHA AZUCARERA": "BETERRABA PARA AÇÚCAR",
  "resowing_surface": "Área de replantio",
  "result": "resultado",
  "riego": "Irrigação",
  "rodents_infestation_percent": "Roedores - infestação",
  "rot10roots_complete": "03.Podridão 10 Raízes - Inteiras",
  "rot10roots_nothing": "01.Podridão  10 raízes - Nada",
  "rot10roots_partial": "02.Podridão  10 raízes - Tocada",
  "rust_10_25_afa": "Roya - 10-25 % AFA",
  "rust_100_afa": "Roya -N- 100 % AFA. Folhagem original completamente destruída",
  "rust_25_35_afa": "Roya -J- 25-35 % AFA. 1/4 -1/3 da folhagem é severamente afetada",
  "rust_3_10_afa": "Roya -I- 3-10 % AFA",
  "rust_35_50_afa": "Roya -K- 35-50 % AFA.  1/3 - 1/2 da folhagem é severamente afetada",
  "rust_50_75_afa": "Roya -L- 50-75 % AFA. 1/2 da folhagem é severamente afetada",
  "rust_75_90_afa": "Roya -M- 75-90 % AFA. Toda a folhagem é severamente afetada",
  "rust_healthy_plant": "Roya -A- Planta saudável",
  "rust_isolated_spots_on_some_leaves_1_9_perc": "Roya -B- Manchas isoladas em algumas folhas. 1-9 (%HS)",
  "rust_isolated_spots_on_some_leaves_10_19_perc": "Roya -C- Manchas isoladas em algumas folhas. 10-19 (%HS)",
  "rust_isolated_spots_on_some_leaves_20_29_perc": "Roya -D- Manchas isoladas em algumas folhas. 20-29 (%HS)",
  "rust_isolated_spots_on_some_leaves_30_50_perc": "Roya -E- Manchas isoladas em algumas folhas. 30-50 (%HS)",
  "rust_regrowth_restart": "Roya -O- Início do recrescimento. Adicionar 0,5 pontos para cada semana até a colheita",
  "rust_spots_leaves_100_300": "Roya -H- Folhas com 100-300 manchas",
  "rust_spots_leaves_20_100": "Roya -G- 20-100 manchas por folha na maioria das folhas",
  "rust_spots_leaves_50": "Roya -F- >50% HS e <20 manchas por folha na maioria das folhas",
  "rust_treatment": "Roya -p- Tratamento",
  "sac": "Sac",
  "Safety": "Segurança",
  "sample_color": "05.Cor da amostra",
  "sand": "Areia",
  "SAUVIGNON BLANC": "SAUVIGNON BLANC",
  "severity": "Severidade",
  "soca_width": "04.Número de raízes",
  "sodium": "Sódio",
  "sodium_saturation": "Saturação de Sódio",
  "Soil": "Solo",
  "soil_moisture_status": "Estado de umidade do solo",
  "SOJA": "SOJA",
  "sowing_failure": "Falha de plantio",
  "sprout_dead": "brotes muertos",
  "sprout_total": "total brotes",
  "starch": "Amido",
  "stems_evaluated": "tallos evaluados",
  "sulfur": "Enxofre",
  "Superficial": "Superficial",
  "SYRAH": "SYRAH",
  "TABACO": "TABACO",
  "TE": "CHÁ",
  "TEA": "CHÁ",
  "TEMPRANILLO": "TEMPRANILLO",
  "TOMATE": "TOMATE",
  "tombing": "Tombamento",
  "total_arsenic": "Arsênio Total",
  "total_cadmium": "Cádmio Total",
  "TOURIGA NACIONAL": "TOURIGA NACIONAL",
  "TRIGO": "TRIGO",
  "TRIGO BLANDO": "TRIGO MOLE",
  "TRIGO DURO": "TRIGO DURO",
  "TRITICALE": "TRITICALE",
  "type_disease": "Tipo de doença",
  "uneven_budding": "Brotação irregular",
  "ustilago_scitaminea_infection": "ustilago scitaminea \"carbón\" - infección",
  "ustilago_scitaminea_stem_affected": "ustilago scitaminea \"carbón\" - total tallos enfermos",
  "ustilago_scitaminea_stem_symptoms": "ustilago scitaminea \"carbón\" - tallos con síntomas",
  "ustilago_scitaminea_stem_total": "ustilago scitaminea \"carbón\" - tallos totales",
  "ustilago_scitaminea_stem_whip": "ustilago scitaminea \"carbón\" - tallos látigo",
  "UVA": "UVA",
  "UVA DE MESA": "UVA DE MESA",
  "Variabilidad": "Variabilidade",
  "Variabilidad (NDVI-LUVI)": "Variabilidade (NDVI-LUVI)",
  "VERDEJO": "VERDEJO",
  "very high irrigation": "Estresse hídrico muito alto",
  "very low irrigation": "Estresse hídrico muito baixo",
  "VIGNE": "VIDEIRA",
  "Vigor Vegetativo (NDVI-Drone)": "Vigor vegetativo (NDVI-Drone)",
  "Vigor Vegetativo (NDVI-LUVI)": "Vigor vegetativo (NDVI-LUVI)",
  "vigor vegetativo (ndvi) - vigor alto": "Vigor vegetativo (NDVI) - Vigor alto",
  "VIÑA": "VINHA",
  "VIOGNIER": "VIOGNIER",
  "virgin_female_georeferenced_trap_diatraea_adult_total": "trampa hembra virgen - total adultos diatraea",
  "virgin_female_georeferenced_trap_female_change": "trampa hembra virgen - cambio de hembra",
  "virgin_female_georeferenced_trap_other_butterfly": "trampa hembra virgen - otras mariposas",
  "virgin_female_georeferenced_trap_point": "trampa hembra virgen - punto de trampa",
  "virgin_female_trap_diatraea_adult_number": "trampa hembra virgen - adultos diatraea",
  "virgin_female_trap_female_change": "trampa hembra virgen - cambio de hembra",
  "virgin_female_trap_installation_date": "trampa hembra virgen - fecha instalación",
  "virgin_female_trap_number": "trampa hembra virgen - trampas",
  "virgin_female_trap_other_butterfly": "trampa hembra virgen - otras mariposas",
  "VIURA": "VIURA",
  "weed_unload": "01. Ervas (descarrega)",
  "Weeds": "Ervas daninhas",
  "weight_100_beans": "Plantas de 100 grãos",
  "weight_kg": "06.Peso da amostra",
  "whey": "Soro",
  "without_footwear": "01.Sem sapatos",
  "without_gloves": "04.Sem luvas",
  "without_vest": "02.Sem colete",
  "XARELLO": "XARELLO",
  "yellowness_mild": "Amarelecimento - Ataque ligeiro (plantas isoladas)",
  "yellowness_moderate": "Amarelecimento - Ataque moderado (stands)",
  "yellowness_strong": "Amarelo - Ataque pesado (parcela completamente afetada)",
  "yellowness_without_symptoms": "Amarelecimento - Sem sintomas",
  "zinc": "Zinco"
}
