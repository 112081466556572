import React from 'react'
import PropTypes from 'prop-types'

import OlVectorSource from 'ol/source/Vector'
import OlMap from 'ol/Map'

import DrawDistance from '../olComponents/interaction/drawDistance'
import DrawArea from '../olComponents/interaction/drawArea'
import { DISTANCE_TOOL, AREA_TOOL } from '../../actions/editor'

export default class Measures extends React.Component {
  getChildContext() {
    return {
      map: this.context.map,
      source: this.context.source
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.context.map.getOverlays().forEach(function (overlay) {
      if (overlay.getElement().classList.contains('measure-static')) overlay.getElement().style.display = nextProps.isMeasureToolsActive ? 'block' : 'none'
    })
  }

  render() {
    // eslint-disable-next-line prefer-const
    let _this = this

    /*=============================================>>>>>
        = Modify Interaction =
        ===============================================>>>>>*/

    /*=============================================>>>>>
            = Draw Interaction =
            ===============================================>>>>>*/

    // eslint-disable-next-line prefer-const
    let MeasureTool = (function (tool) {
      switch (tool) {
        case DISTANCE_TOOL:
          return <DrawDistance config={_this.props.config} />
        case AREA_TOOL:
          return <DrawArea config={_this.props.config} />
        default:
          return null
      }
    })(this.props.activeTool)

    return <div>{MeasureTool}</div>
  }
}

Measures.propTypes = {
  activeTool: PropTypes.string,
  dispatch: PropTypes.func,
  isMeasureToolsActive: PropTypes.bool
}

Measures.childContextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}

Measures.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
