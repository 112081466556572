import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import FieldDescription from './FieldDescription'

import ceil from 'lodash/ceil'
import { UIStyles } from '../../theme'
import SelectedFieldsTabsContainer from '../../containers/FieldsContainers/SelectedFieldsTabsContainer'

const Wrapper = styled.div`
  top: 60px;
  left: 60px;
  max-height: calc(100vh - 60px);
  position: fixed;
  z-index: 4;
  width: 360px;
  overflow: hidden;
  display: flex;
  background-color: ${UIStyles.blueColorOpacity};
  flex-direction: column;
  height: auto;
`

const TabsAndContentWrapper = styled.div``

function SelectedFieldsAside({
  shouldShowSamplesInMap,
  toggleExpandSelectedFieldAside,
  toggleShowSampleMarkers,
  fields,
  temporal,
  flightGroups,
  openSearchMode,
  closeEditMode,
  asideExpanded,
  fetchActiveFieldsSamples,
  samplesMode,
  setSamplesMode,
  surfaceUnit,
  hasSamples,
  selectedFlightGroupDate
}) {
  const [tabContentHeight, setTabContentHeight] = useState(0)
  const onTabsHeaderLayout = useCallback(boundingClientRect => {
    const { height, top } = boundingClientRect
    const offsetTop = height + top
    const maxHeight = `calc(100vh - ${ceil(offsetTop)}px)`

    setTabContentHeight(maxHeight)
  }, [])

  return (
    <Wrapper>
      <FieldDescription
        asideExpanded={asideExpanded}
        toggleExpandSelectedFieldAside={toggleExpandSelectedFieldAside}
        shouldShowSamplesInMap={shouldShowSamplesInMap}
        toggleShowSampleMarkers={toggleShowSampleMarkers}
        fields={fields}
        temporal={temporal}
        flightGroups={flightGroups}
        openSearchMode={openSearchMode}
        closeEditMode={closeEditMode}
        fetchActiveFieldsSamples={fetchActiveFieldsSamples}
        samplesMode={samplesMode}
        setSamplesMode={setSamplesMode}
        surfaceUnit={surfaceUnit}
        hasSamples={hasSamples}
        selectedFlightGroupDate={selectedFlightGroupDate}
      />
      <TabsAndContentWrapper>
        <SelectedFieldsTabsContainer contentMaxHeight={tabContentHeight} onTabsHeaderLayout={onTabsHeaderLayout} />
      </TabsAndContentWrapper>
    </Wrapper>
  )
}

export default SelectedFieldsAside
