import React from 'react'
import FlightTemporalSwipeContainer from '../../containers/FieldsContainers/FlightTemporalSwipeContainer'
import SelectedFieldsAsideContainer from '../../containers/FieldsContainers/SelectedFieldsAsideContainer'
export default class FieldView extends React.Component {
  openSearchMode = () => {
    this.props.openSearchMode()
    this.props.toggleSearch()
  }

  render = () => {
    const { selectedFields, flightGroups, temporal, closeEditMode } = this.props

    return (
      <div className="fieldViewContainer">
        <SelectedFieldsAsideContainer
          fields={selectedFields}
          temporal={temporal}
          flightGroups={flightGroups}
          openSearchMode={this.openSearchMode}
          closeEditMode={closeEditMode}
        />
        {temporal && <FlightTemporalSwipeContainer />}
      </div>
    )
  }
}
