import { UIStyles } from '../../theme'
import styled from 'styled-components'
import React from 'react'
import withTranslator from '../HOCComponents/withTranslator'
import { StyledTooltip } from './StyledTooltip'

const CloseModalButton = styled.button`
  background-color: ${UIStyles.darkBlue};
  color: ${UIStyles.redColor};
  border: none;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  height: 2.2rem;
  width: 2.2rem;
  i.fas {
    width: auto;
    height: auto;
  }
  &:hover {
    color: ${UIStyles.whiteColor};
  }
`

export default withTranslator(props => (
  <StyledTooltip title={props.t('Close')} placement="top">
    <CloseModalButton {...props}>
      <i className="fas fa-times" />
    </CloseModalButton>
  </StyledTooltip>
))
