import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { RECEIVE_SEASON_LAYERS } from '../actions/seasonLayers'

const initialState = {}

export default function seasonLayers(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SEASON_LAYERS: {
      const { seasonLayers } = action

      return reduce(
        seasonLayers,
        (accumulator, layer) => {
          const { deliverable, seasonId } = layer
          const previousSeasonLayers = get(accumulator, seasonId, {})
          const deliverableTypeId = get(deliverable, 'deliverableType.id')

          return {
            ...accumulator,
            [seasonId]: {
              ...previousSeasonLayers,
              [deliverableTypeId]: layer
            }
          }
        },
        state
      )
    }

    default:
      return state
  }
}
