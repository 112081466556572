import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import DeleteOverlay from '../../components/olComponents/deleteOverlay'
// eslint-disable-next-line import/no-duplicates
import { POLYGON_SLICE } from '../../actions/editor'

// eslint-disable-next-line import/no-duplicates
import { deletePolygon } from '../../actions/editor'

import { getEditorPresentData } from '../../selectors/editor'
import { getUserConfig } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  isSliceTool: getEditorPresentData(state, 'activeTool') === POLYGON_SLICE,
  config: getUserConfig(state)
})

const mapDispatchToProps = dispatch => {
  // eslint-disable-next-line object-shorthand
  return bindActionCreators({ deletePolygon: deletePolygon }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteOverlay)
