import { connect } from 'react-redux'
import { compose } from 'redux'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'
import { getUserName } from '../../selectors/users'
import { areRequestsPending } from '../../selectors/ui'

import { getIsAppReady, getIsDashboardReady } from '../../selectors/config'
import HeadTitle from '../../components/UIComponents/HeadTitle'

const mapStateToProps = state => ({
  userName: getUserName(state),
  isAppReady: getIsAppReady(state),
  isDashboardReady: getIsDashboardReady(state),
  isLoading: areRequestsPending(state)
})

export default compose(withCurrentRoute, connect(mapStateToProps))(HeadTitle)
