import React, { useEffect, useRef, useState, useCallback } from 'react'
import get from 'lodash/get'
import noop from 'lodash/noop'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import capitalize from 'lodash/capitalize'
import isUndefined from 'lodash/isUndefined'
import { Icon, Tab, Tabs } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'

import SelectedFieldsDetailsContainer from '../../containers/FieldsContainers/SelectedFieldsDetailsContainer'
import SamplesTabContainer from '../../containers/FieldsContainers/SamplesTabContainer'
import FileGeneratorContainer from '../../containers/FileGeneratorContainer/FileGeneratorContainer'
import DeliverablesLayerBoxesContainer from '../../containers/DeliverablesLayerBoxesContainer'
import SelectedFieldsCalendarContainer from '../../containers/FieldsContainers/SelectedFieldsCalendarContainer'

import RenderIf from '@layers-frontend/commons/helpers/RenderIf'
import withTranslator from '../HOCComponents/withTranslator'

import { trackEvent } from '../../components/analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

import { UIStyles } from '../../theme'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .tabs {
    & > div {
      background-color: transparent !important;
    }
  }

  .custom-icon {
    color: ${themeColors.whiteColor} !important;
    font-size: ${p => p.iconSize} !important;
    &.active {
      color: ${themeColors.vomitColor} !important;
    }
  }
`

const TabContentWrapper = styled.div`
  max-height: ${p => (isUndefined(p.contentMaxHeight) ? 'initial' : p.contentMaxHeight)};
`

const TabsHeaderWrapper = styled.div`
  border-bottom: 1px solid ${UIStyles.lightGrey};
`

const muiStyles = {
  buttonStyle: {
    height: '6rem',
    minWidth: 'auto'
  }
}

const TabIconWrapper = styled.span`
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const EmptyComponent = () => <span />

const tabEventMapping = {
  fieldsInfo: { event: TRACK_EVENTS.FIELD_PANEL_INFO, button: 'Field Info' },
  calendar: { event: TRACK_EVENTS.FIELD_PANEL_CALENDAR, button: 'Calendar' },
  layers: { event: TRACK_EVENTS.FIELD_PANEL_LAYERS, button: 'Layers' },
  seasonLayers: { event: TRACK_EVENTS.FIELD_PANEL_SEASON_LAYERS, button: 'Season Layers' },
  downloads: { event: TRACK_EVENTS.FIELD_PANEL_DOWNLOADS, button: 'Downloads' },
  samples: { event: TRACK_EVENTS.FIELD_PANEL_SAMPLES, button: 'Samples' },
  fileGenerator: { event: TRACK_EVENTS.FIELD_PANEL_FILE_GENERATOR, button: 'File Generator' }
}

function SelectedFieldsTabs(props) {
  const {
    t,
    asideExpanded,
    onTabsHeaderLayout,
    contentMaxHeight,
    setSeasonLayersZIndexBottom,
    setSeasonLayersZIndexTop,
    activeLayers,
    expandSelectedFieldsAside,
    layers,
    seasonLayers,
    pdfDeliverables,
    fetchSamplesParametersByType,
    fetchSampleTypes,
    shouldFetch,
    allSelectedFieldTabs,
    selectedFieldTab,
    setSelectedFieldTab,
    setSelectedFieldTabVisibility,
    resetSelectedFieldTabs,
    hideSelectedFieldTab,
    hasSelectedFlightGroupGeometry
  } = props

  const headerRef = useRef(null)

  const [tooltipText, setTooltipText] = useState(null)

  const manageZIndex = useCallback(
    (handler, seasonLayersOnTop) => () => {
      handler()

      if (seasonLayersOnTop) {
        return setSeasonLayersZIndexTop()
      }

      return setSeasonLayersZIndexBottom()
    },
    [setSeasonLayersZIndexTop, setSeasonLayersZIndexBottom]
  )

  /* eslint-disable react-hooks/exhaustive-deps */
  const activateLayersTab = useCallback(
    manageZIndex(() => {
      activeLayers(true)
    }, false),
    []
  )

  const activateDownloadsTab = useCallback(
    manageZIndex(() => {
      activeLayers(false)
    }, false),
    []
  )

  useEffect(() => {
    if (shouldFetch) {
      fetchSamplesParametersByType()
      fetchSampleTypes()
    }
  }, [])

  const activateSeasonLayersTab = useCallback(manageZIndex(noop, true), [])

  const activateFieldsInfoTab = useCallback(manageZIndex(noop, false), [])

  const activateFieldsCalendarTab = useCallback(manageZIndex(noop, false), [])

  const activateFileGenetatorTab = useCallback(manageZIndex(noop, false), [])

  const activateSamplesTab = useCallback(manageZIndex(noop, false), [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const selectedFieldTabsInfo = {
    fieldsInfo: {
      component: SelectedFieldsDetailsContainer,
      onTabSelect: activateFieldsInfoTab
    },
    calendar: {
      component: SelectedFieldsCalendarContainer,
      onTabSelect: activateFieldsCalendarTab
    },
    layers: {
      component: DeliverablesLayerBoxesContainer,
      onTabSelect: activateLayersTab
    },
    seasonLayers: {
      component: DeliverablesLayerBoxesContainer,
      onTabSelect: activateSeasonLayersTab
    },
    downloads: {
      component: DeliverablesLayerBoxesContainer,
      onTabSelect: activateDownloadsTab
    },
    samples: {
      component: SamplesTabContainer,
      onTabSelect: activateSamplesTab
    },
    fileGenerator: {
      component: FileGeneratorContainer,
      onTabSelect: activateFileGenetatorTab
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isEmpty(seasonLayers)) {
      setSelectedFieldTabVisibility('seasonLayers')
    }
  }, [seasonLayers])

  useEffect(() => {
    if (!isEmpty(pdfDeliverables)) {
      setSelectedFieldTabVisibility('downloads')
    } else {
      hideSelectedFieldTab('downloads')
    }
  }, [pdfDeliverables])

  useEffect(() => {
    if (!isEmpty(layers)) {
      setSelectedFieldTabVisibility('layers')
    } else {
      hideSelectedFieldTab('layers')
    }
  }, [layers])

  useEffect(() => {
    if (!isEmpty(layers)) {
      setSelectedFieldTabVisibility('samples')
    } else {
      hideSelectedFieldTab('samples')
    }
  }, [layers])

  useEffect(() => {
    if (!isEmpty(layers)) {
      setSelectedFieldTabVisibility('fileGenerator')
    } else {
      hideSelectedFieldTab('fileGenerator')
    }
  }, [layers])

  const handleTabChange = useCallback(
    (_, index) => {
      const { name } = find(allSelectedFieldTabs, (tab, tabIndex) => tabIndex === index)
      const onTabSelect = get(selectedFieldTabsInfo, [name, 'onTabSelect'])

      if (onTabSelect) onTabSelect()

      const tabConfig = tabEventMapping[name]
      if (tabConfig)
        trackEvent(tabConfig.event, {
          button: tabConfig.button,
          location: TRACK_EVENTS.FIELD_PANEL
        })

      setSelectedFieldTab(index)
    },
    [setSelectedFieldTab, allSelectedFieldTabs]
  )

  useEffect(() => {
    if (headerRef.current.getBoundingClientRect) {
      const boundingClientRect = headerRef.current.getBoundingClientRect()

      onTabsHeaderLayout(boundingClientRect)
    }
  }, [headerRef.current])

  const showTooltip = useCallback(title => () => setTooltipText(capitalize(t(title))), [])
  /* eslint-enable react-hooks/exhaustive-deps */

  const hideTooltip = useCallback(() => setTooltipText(null), [])

  const onTabSelect = ({ tabIndex }) => {
    const { name } = find(allSelectedFieldTabs, (_, index) => tabIndex === index)
    get(selectedFieldTabsInfo, [name, 'onTabSelect'])(tabIndex)

    expandSelectedFieldsAside()
    setSelectedFieldTab(tabIndex)
  }

  useEffect(() => {
    if (!hasSelectedFlightGroupGeometry) {
      hideSelectedFieldTab('calendar')
      setSelectedFieldTab(0)
    }
    return resetSelectedFieldTabs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Wrapper iconSize="2rem">
      <StyledTooltip title={tooltipText} open={!!tooltipText} followCursor placement="top-start">
        <div ref={headerRef}>
          <TabsHeaderWrapper>
            <Tabs onChange={handleTabChange} value={selectedFieldTab} id="selected-fields-tabs" className="tabs" variant="fullWidth">
              {allSelectedFieldTabs.map(
                ({ icon, title, visible, name }, tabIndex) =>
                  visible && (
                    <Tab
                      key={tabIndex}
                      value={tabIndex}
                      {...{ 'data-test-id': `selected-fields-tab-${name}` }}
                      icon={
                        <TabIconWrapper onMouseEnter={showTooltip(title)} onMouseLeave={hideTooltip} onClick={() => onTabSelect({ tabIndex, tabName: name })}>
                          <Icon className={`${icon} custom-icon ${selectedFieldTab === tabIndex && 'active'}`} />
                        </TabIconWrapper>
                      }
                      style={muiStyles.buttonStyle}
                    />
                  )
              )}
            </Tabs>
          </TabsHeaderWrapper>
        </div>
      </StyledTooltip>
      <RenderIf condition={asideExpanded}>
        <SwipeableViews index={selectedFieldTab} onChangeIndex={handleTabChange} style={{ overflow: 'hidden' }}>
          {allSelectedFieldTabs.map(({ name }, tabIndex) => {
            if (tabIndex === selectedFieldTab) {
              const ComponentToRender = get(selectedFieldTabsInfo, [name, 'component'])

              return (
                <TabContentWrapper contentMaxHeight={contentMaxHeight} key={tabIndex}>
                  <ComponentToRender activeTab={name} />
                </TabContentWrapper>
              )
            }

            return <EmptyComponent key={tabIndex} />
          })}
        </SwipeableViews>
      </RenderIf>
    </Wrapper>
  )
}

export default withTranslator(SelectedFieldsTabs)
