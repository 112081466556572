import React, { useEffect, useMemo, useState } from 'react'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { useTable, useSortBy, usePagination, useGlobalFilter, useRowSelect } from 'react-table'
import styled from 'styled-components'
import size from 'lodash/size'
import get from 'lodash/get'
import map from 'lodash/map'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import capitalize from 'lodash/capitalize'
import truncate from 'lodash/truncate'
import ReportCard from './ReportCard'
import includes from 'lodash/includes'
import withConfirmation from '../../HOCComponents/withConfirmation'
import StyledSelect from '../../UIComponents/StyledSelect'
import StyledTextInput from '../../UIComponents/StyledTextInput'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import { MenuMoreOptions } from '../../UIComponents/MenuMoreOptions'
import { convertAsString } from '@layers-frontend/commons/conversions'
import { Switch } from '@mui/material'
import reduce from 'lodash/reduce'
import { StyledTooltip } from '../../UIComponents/StyledTooltip'

export const ManagerHeaderContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  color: ${themeColors.whiteColor};
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid;
  font-size: 3rem;
  padding: 1rem 0 1rem 0;
`

const ManagerHeaderTitle = styled.div`
  display: flex;
  flex: 1 1 0px;
  justify-content: center;
`

const HeaderLeft = styled(ManagerHeaderTitle)`
  justify-content: flex-start;
`

const HeaderRight = styled.div`
  display: flex;
  flex: 1 1 0px;
  flex-flow: row wrap;
  justify-content: flex-end;
`

const Icon = styled.i`
  color: ${themeColors.whiteColor};
  font-size: 1.4rem;
`

const LeftIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 1rem;
`

const RightIcon = styled(Icon)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -2rem;
  padding-right: 0.6rem;
  cursor: pointer;
  z-index: 3;
`

const SortIcon = styled(Icon)`
  padding-right: 1rem;
`

const DeleteIcon = styled(Icon)`
  z-index: 10;
  display: block;
  text-align: center;
  cursor: pointer;
`

const DeleteIconContainer = styled.div`
  padding: 10px;
  margin: -5px;

  &:hover ${DeleteIcon} {
    transition: color 0.6s ease-in-out;
    color: ${themeColors.redColor};
  }
`
// table style

export const TableContainer = styled.div`
  background: ${themeColors.darkBlue};
  overflow-x: scroll;

  table {
    border-collapse: collapse;
    text-align: left;
    width: 100%;
    margin-bottom: 1rem;
  }
  table tbody tr {
    cursor: pointer;
    color: ${themeColors.whiteColor};
  }
  table tr {
    border-bottom: 1px solid;
    color: ${themeColors.whiteColor};
  }
  table th,
  table td {
    padding: 10px 10px;
    color: ${themeColors.whiteColor};
    font-size: 1.5rem;
  }
`

const Card = styled.div`
  color: ${themeColors.whiteColor};
  background: ${themeColors.darkBlue};
  padding: 20px;
  max-height: 50vh;
  position: relative;
`

const PaginatorIcon = styled.i`
  font-size: 2rem;
`

const PaginatorText = styled.div`
  color: ${themeColors.whiteColor};
  padding: 0 1rem;
`

const PaginatorContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`
const SquaredButton = styled(Button)`
  min-width: 36px !important;
  color: ${props => (props.disabled ? themeColors.darkGrayColor : themeColors.whiteColor)}!important;
`

const SquaredPlaceholder = styled.div`
  height: 36px;
  width: 36px;
  color: ${themeColors.darkBlue};
`

const StyledTd = styled.td`
  border-top: 1px solid #ddd;
`

const SelectWrapper = styled.div`
  height: 35px;
`

const EditButton = styled.button`
  background: none;
  border: none;
  font-size: 22px;

  &:hover {
    color: ${themeColors.vomitColor};
  }
`
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`

const Row = styled.div`
  display: flex;
  align-items: center;
`

const searchInputSx = {
  width: '250px'
}

const DeleteCell = ({ setConfirmationOpen }) => (
  <DeleteIconContainer
    onClick={() => {
      setConfirmationOpen(true)
    }}
  >
    <DeleteIcon className="fa fa-times" />
  </DeleteIconContainer>
)

const canSortColumn = column => !includes(['id', 'delete', 'selection'], column.id)
const isCellClickable = cell => !includes(['statusId', 'delete', 'selection'], cell.column.id)

export const ReportsTable = ({
  t,
  reports,
  fieldsLength,
  reportTypes,
  reportStatuses,
  updateReportStatus,
  fieldsNamesByIds,
  fieldsByIds,
  deleteReportById,
  editReport,
  editingReportId,
  surfaceUnit,
  isShowCurrentSeasonReportsMode,
  toggleCurrentSeasonReportsMode
}) => {
  const [activeRow, setActiveRow] = useState(undefined)
  const [filterInput, setFilterInput] = useState('')
  const [status, setStatus] = useState()

  const DeleteCellWithConfirmation = withConfirmation(DeleteCell, ({ row }) => deleteReportById(row.original.id))
  const Paginator = () => {
    return (
      <PaginatorContainer>
        <SquaredButton onClick={() => previousPage()} disabled={!canPreviousPage}>
          <PaginatorIcon className="fas fa-caret-left" />
        </SquaredButton>
        <PaginatorText>
          {pageIndex + 1} - {pageOptions.length}
        </PaginatorText>
        <SquaredButton onClick={() => nextPage()} disabled={!canNextPage}>
          <PaginatorIcon className="fas fa-caret-right" />
        </SquaredButton>
      </PaginatorContainer>
    )
  }

  const StatusSelect = ({ value: initialValue, row: { values }, column: { id }, updateReportStatus }) => {
    const onChange = e => {
      updateReportStatus(get(values, 'id'), e)
    }
    const placeholder = find(reportStatuses, item => item.id === initialValue)
    const placeholderName = get(placeholder, 'name')
    const reportStatusesTranslated = map(reportStatuses, status => ({ ...status, name: t(status.name) }))

    return (
      <SelectWrapper>
        <StyledSelect
          defaultValue={t('status')}
          value={initialValue}
          onChange={onChange}
          placeholder={t(placeholderName)}
          options={reportStatusesTranslated}
          values={initialValue}
        />
      </SelectWrapper>
    )
  }

  const TruncateValue = ({ cell: { value } }) => <>{truncate(value, 40)}</>

  const Filter = () => {
    const onChange = e => {
      const value = e
      setGlobalFilter(value)
      setFilterInput(value)
    }

    return (
      <SearchWrapper>
        <LeftIcon className="fas fa-search" />
        <StyledTextInput
          name={'search'}
          value={filterInput}
          onChange={onChange}
          placeholder={t('Search')}
          autoFocus={true}
          inputProps={{ sx: searchInputSx, 'data-testid': 'search-reports-input' }}
        />
        <RightIcon
          className={'fas fa-times-circle'}
          onClick={() => {
            setFilterInput('')
            setGlobalFilter('')
          }}
        />
      </SearchWrapper>
    )
  }
  const ManagerHeader = ({ handleEditReport, isShowCurrentSeasonReportsMode, toggleCurrentSeasonReportsMode }) => {
    return (
      <ManagerHeaderContainer>
        <HeaderLeft>
          {activeRow ? (
            <SquaredButton onClick={() => setActiveRow(undefined)}>
              <PaginatorIcon className="fas fa-chevron-left" />
            </SquaredButton>
          ) : (
            <>
              <Filter />
              <SquaredPlaceholder />
            </>
          )}
        </HeaderLeft>
        <ManagerHeaderTitle>
          {activeRow ? `${capitalize(activeRow.original.author)} - ${capitalize(t(activeRow.original.typeName))}` : t('reports manager')}
        </ManagerHeaderTitle>
        <HeaderRight>
          {activeRow ? (
            <EditButton onClick={handleEditReport}>
              <i className="fa fa-edit" />
            </EditButton>
          ) : (
            <Row>
              <StyledTooltip title={capitalize(t(isShowCurrentSeasonReportsMode ? 'show all' : 'Show current season only'))} placement="bottom-end">
                <Switch checked={isShowCurrentSeasonReportsMode} onChange={e => toggleCurrentSeasonReportsMode(e.target.checked)} />
              </StyledTooltip>
              <MenuMoreOptions
                options={[t('open_selected'), t('close_selected')]}
                values={[1, 2]}
                handleChange={setStatus}
                disabled={selectedRows.length === 0}
              />
            </Row>
          )}
        </HeaderRight>
      </ManagerHeaderContainer>
    )
  }

  // data and table struct
  const data = useMemo(() => {
    return reduce(
      reports,
      (acc, report) => {
        const reportWithTypeName = {
          ...report,
          typeName: find(reportTypes, type => type.id === report.typeId).name,
          fieldNames: fieldsNamesByIds(map(report.fields, field => field.field_id)),
          fields: fieldsByIds(map(report.fields, field => field.field_id)),
          status: find(reportStatuses, status => status.id === report.statusId).name,
          affectedHa: report.affectedHa && convertAsString(report.affectedHa, 'Ha', surfaceUnit),
          creationDate: report.creationDate.substring(0, 10),
          relatedDate: report.relatedDate && report.relatedDate.substring(0, 10),
          updateDate: report.updateDate.substring(0, 10)
        }

        if (isShowCurrentSeasonReportsMode && !report.isCurrentSeasonReport) {
          return acc
        }
        return [...acc, reportWithTypeName]
      },
      []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, isShowCurrentSeasonReportsMode, fieldsLength])

  const columns = useMemo(
    () => [
      {
        Header: t('Id'),
        accessor: 'id'
      },
      {
        Header: t('report type'),
        accessor: data => t(get(data, 'typeName'))
      },
      {
        Header: t('author'),
        accessor: 'author'
      },
      {
        Header: t('Recipients'),
        accessor: 'recipients',
        Cell: TruncateValue
      },
      {
        id: 'fieldNames',
        Header: t('fields'),
        accessor: 'fieldNames',
        Cell: TruncateValue
      },
      {
        Header: capitalize(t('notes')),
        accessor: 'notes',
        Cell: TruncateValue
      },
      {
        id: 'relatedDate',
        Header: t('Related date'),
        accessor: 'relatedDate'
      },
      {
        Header: t('created'),
        accessor: 'creationDate'
      },
      {
        Header: t('updated'),
        accessor: 'updateDate'
      },
      {
        Header: t('status'),
        accessor: 'statusId',
        Cell: StatusSelect
      },
      {
        Header: t('Delete'),
        id: 'delete',
        deleteReportById,
        Cell: DeleteCellWithConfirmation
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  // init table hook
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    rows,
    selectedFlatRows,
    state: { pageIndex }
  } = useTable(
    {
      columns,
      data,
      updateReportStatus,
      initialState: {
        pageIndex: 0,
        checked: false,
        pageSize: 10,
        hiddenColumns: ['relatedDate'],
        sortBy: [{ id: 'relatedDate', desc: true }]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push((columns, state) => {
        const allRowsSelected = !isEmpty(state.data) && !isEmpty(state.selectedRowIds) && size(state.selectedRowIds) <= state.pageSize

        if (!allRowsSelected) {
          return [
            {
              id: 'selection',
              // use getToggleAllRowsSelectedProps to select all rows on all pages, getToggleAllPageRowsSelectedProps - select all rows on the current page
              Header: ({ getToggleAllPageRowsSelectedProps }) => <Checkbox {...getToggleAllPageRowsSelectedProps()} inputProps={{ 'aria-label': 'control' }} />,
              Cell: ({ row }) => <Checkbox {...row.getToggleRowSelectedProps()} inputProps={{ 'aria-label': 'controlled' }} />
            },
            ...columns
          ]
        }
      })
    }
  )
  const selectedRows = useMemo(() => selectedFlatRows.map(d => d.original), [selectedFlatRows])

  useEffect(() => {
    const editingRow = find(rows, item => item.original.id === editingReportId)
    setActiveRow(editingRow)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    if (selectedRows && size(selectedRows) >= 1)
      each(selectedRows, row => {
        if (row.statusId !== status) updateReportStatus(row.id, status)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const handleEditReport = () => editReport(activeRow.original)

  return (
    <>
      <ManagerHeader
        handleEditReport={handleEditReport}
        isShowCurrentSeasonReportsMode={isShowCurrentSeasonReportsMode}
        toggleCurrentSeasonReportsMode={toggleCurrentSeasonReportsMode}
      />
      <TableContainer>
        {!activeRow ? (
          <>
            <table style={{ backgroundColor: themeColors.darkBlue }} {...getTableProps()}>
              <thead>
                {headerGroups.map((headerGroup, index) => {
                  if (index !== headerGroups.length - 1) return null
                  return (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                          {canSortColumn(column) ? (
                            <span>
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <SortIcon className="fas fa-caret-down" />
                                ) : (
                                  <SortIcon className="fas fa-caret-up" />
                                )
                              ) : (
                                <SortIcon className="fas fa-sort" />
                              )}
                            </span>
                          ) : null}

                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  )
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row)

                  return (
                    <tr {...row.getRowProps()} style={{ color: themeColors.grayColor }}>
                      {row.cells.map(cell => {
                        return (
                          <StyledTd
                            onClick={() => {
                              if (isCellClickable(cell)) {
                                setActiveRow(row)
                              }
                            }}
                            {...cell.getCellProps()}
                          >
                            {cell.render('Cell')}
                          </StyledTd>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            <Paginator />
          </>
        ) : (
          <Card>
            <ReportCard data={activeRow.original} editReport={editReport} />
          </Card>
        )}
      </TableContainer>
    </>
  )
}
