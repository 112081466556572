import React from 'react'
import styled from 'styled-components'

const UndoActionsWrapper = styled.div``

export default class UndoActions extends React.Component {
  render() {
    return (
      <UndoActionsWrapper>
        <div
          className={'tools ' + (!this.props.canUndo ? 'inactive ' : '')}
          onClick={() => {
            this.props.onUndoClick()
          }}
        >
          <i className="ion-ios-undo" />
        </div>
        <div
          className={'tools ' + (!this.props.canRedo ? 'inactive ' : '')}
          onClick={() => {
            this.props.onRedoClick()
          }}
        >
          <i className="ion-ios-redo" />
        </div>
      </UndoActionsWrapper>
    )
  }
}
