import { createSlice } from '@reduxjs/toolkit'
import { DEFAULT_LOCALE } from '@layers-frontend/commons/constants'

const initialState = DEFAULT_LOCALE

const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    // Give case reducers meaningful past-tense "event"-style names
    localeSet(state, action) {
      return action.payload
    }
  }
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { localeSet } = localeSlice.actions

// Export the slice reducer as the default export
export const localeSliceReducer = localeSlice.reducer
