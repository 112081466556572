import { loader } from 'graphql.macro'
import { fetchGraphqlWrapper } from './ui'
import { RECEIVE_FIELDS_TILE_ORDER, REQUEST_FIELDS_TILE_ORDER } from '@layers-frontend/commons/store/storeConstants'
import { hasRolePlanet } from '@layers-frontend/commons/store/selectors/user'

const getFieldsTileOrder = loader('../graphql/getFieldsTileOrder.gql').loc.source.body

export const fetchFieldsTileOrder = () => dispatch => {
  hasRolePlanet &&
    dispatch(
      fetchGraphqlWrapper({
        query: getFieldsTileOrder,
        requestType: REQUEST_FIELDS_TILE_ORDER,
        onSuccess: data => ({ type: RECEIVE_FIELDS_TILE_ORDER, data })
      })
    )
}
