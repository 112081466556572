import React, { useEffect } from 'react'
import StyledListButton from '../UIComponents/StyledListButton'
import isEmpty from 'lodash/fp/isEmpty'
import includes from 'lodash/includes'
import { USER_ROLES } from '@layers-frontend/commons/constants'

const { ROLE_PREDTECH_AUX, ROLE_PREDTECH_FORECASTS, ROLE_PREDTECH_REPORTS } = USER_ROLES

function GlobalFilesButton({ t, user, globalFiles, userRoles, openGlobalFilesModal, fetchGlobalFiles }) {
  const isDisabled = isEmpty(globalFiles)
  const isHidden = !(includes(userRoles, ROLE_PREDTECH_AUX) || includes(userRoles, ROLE_PREDTECH_FORECASTS) || includes(userRoles, ROLE_PREDTECH_REPORTS))
  useEffect(() => {
    if (!isHidden) {
      if (isDisabled) {
        const payload = { user_id: `${user}` }
        fetchGlobalFiles(payload)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isDisabled, isHidden])

  return (
    <StyledListButton
      label={!isDisabled ? t('global files') : t('no data available')} // add translations
      onClick={!isDisabled ? openGlobalFilesModal : null}
      labelDistance={6}
      icon="fa-solid fa-file-arrow-down"
      disabled={isDisabled}
      testId="global-files-button" // check selenium tests
      hidden={isHidden}
    />
  )
}

export default GlobalFilesButton
