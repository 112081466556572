import React, { useEffect } from 'react'

import styled from 'styled-components'
import { ReportsTable } from './ReportsTable'

const ReportManagerWrapper = styled.div`
  height: auto;
  position: relative;
  margin: 0 1.5rem 0 1.5rem;
`

export default function ReportManagerModal({
  t,
  fetchReports,
  fetchReportsByFieldIds,
  reportTypes,
  reportStatuses,
  reports,
  fieldsLength,
  updateReportStatus,
  fieldsNamesByIds,
  selectedFieldsIds,
  fieldsByIds,
  deleteReportById,
  editReport,
  editingReportId,
  surfaceUnit,
  isShowCurrentSeasonReportsMode,
  toggleCurrentSeasonReportsMode
}) {
  useEffect(() => {
    selectedFieldsIds.length ? fetchReportsByFieldIds({ fieldIds: selectedFieldsIds }) : fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypes])

  return (
    <ReportManagerWrapper>
      <ReportsTable
        t={t}
        reports={reports}
        fieldsLength={fieldsLength}
        reportTypes={reportTypes}
        reportStatuses={reportStatuses}
        updateReportStatus={updateReportStatus}
        fieldsNamesByIds={fieldsNamesByIds}
        fieldsByIds={fieldsByIds}
        deleteReportById={deleteReportById}
        editReport={editReport}
        editingReportId={editingReportId}
        surfaceUnit={surfaceUnit}
        isShowCurrentSeasonReportsMode={isShowCurrentSeasonReportsMode}
        toggleCurrentSeasonReportsMode={toggleCurrentSeasonReportsMode}
      />
    </ReportManagerWrapper>
  )
}
