import Routing from '../Routing'
import userStoredData, { getToken } from '../userStoredData'
import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'
import { hasUserRole } from '../selectors/users'
import { graphqlUri } from '../apolloFetch'
import { REQUEST_START, REQUEST_END, REQUEST_FAIL, REQUEST_NOT_AUTHORIZED_BY_ROLE } from '@layers-frontend/commons/store/storeConstants'
import { setRequestStart, setRequestEnd, setRequestFail, requestNotAuthorizedByRole } from '@layers-frontend/commons/store/actions/requests'
import { createFetchRequestWrapper, createFetchGraphqlWrapper } from '@layers-frontend/commons/helpers/createFetch'
export { setRequestStart, setRequestEnd, setRequestFail, requestNotAuthorizedByRole }

export { REQUEST_START, REQUEST_END, REQUEST_FAIL, REQUEST_NOT_AUTHORIZED_BY_ROLE }

export const UPDATE_SAT_STATISTICS_FETCH_FROM_DATE = 'UPDATE_SAT_STATISTICS_FETCH_FROM_DATE'
export const UPDATE_RADAR_DATA_FETCH_FROM_DATE = 'UPDATE_RADAR_DATA_FETCH_FROM_DATE'
export const EXPAND_FIELDS_DETAILS = 'EXPAND_FIELDS_DETAILS'
export const COLLAPSE_FIELDS_DETAILS = 'COLLAPSE_FIELDS_DETAILS'
export const SET_CHARTS_LAYOUT = 'SET_CHARTS_LAYOUT'
export const GRID_LAYOUT = 'GRID_LAYOUT'
export const LIST_LAYOUT = 'LIST_LAYOUT'
export const SET_VISIBILITY_SEASON_LAYER = 'SET_VISIBILITY_SEASON_LAYER'
export const SET_OPACITY_SEASON_LAYER = 'SET_OPACITY_SEASON_LAYER'
export const SET_ORDER_SEASON_LAYERS = 'SET_ORDER_SEASON_LAYERS'
export const TOGGLE_SEASON_LAYER_LEGENDS = 'TOGGLE_SEASON_LAYER_LEGENDS'
export const SET_SEASON_LAYERS_TO_TOP = 'SET_SEASON_LAYERS_TO_TOP'
export const SET_SEASON_LAYERS = 'SET_SEASON_LAYERS'
export const SET_SEASON_LAYERS_TO_BOTTOM = 'SET_SEASON_LAYERS_TO_BOTTOM'
export const TOGGLE_SHOW_SAMPLE_MARKERS = 'TOGGLE_SHOW_SAMPLE_MARKERS'
export const TOGGLE_EXPANDED_SELECTED_FIELD_ASIDE = 'TOGGLE_EXPANDED_SELECTED_FIELD_ASIDE'
export const TOGGLE_TOOLBARS_DISABLED = 'TOGGLE_TOOLBARS_DISABLED'
export const SET_FIELDS_UPDATED_AT = 'SET_FIELDS_UPDATED_AT'
export const SET_SEARCH_LIST_BAR_MODE = 'SET_SEARCH_LIST_BAR_MODE'
export const TOGGLE_CURRENT_SEASON_REPORTS_MODE = 'TOGGLE_CURRENT_SEASON_REPORTS_MODE'
export const OPEN_NEW_SAMPLE_FORM = 'OPEN_NEW_SAMPLE_FORM'
export const CLOSE_NEW_SAMPLE_FORM = 'CLOSE_NEW_SAMPLE_FORM'
export const SET_ZOOM_LEVEL = 'SET_ZOOM_LEVEL'
export const SET_VIEWPORT_BBOX = 'SET_VIEWPORT_BBOX'
export const setZoomLevel = zoomLevel => ({
  type: SET_ZOOM_LEVEL,
  zoomLevel
})

export const updateSatStatisticsFetchFromDate = date => ({
  type: UPDATE_SAT_STATISTICS_FETCH_FROM_DATE,
  date
})

export const updateRadarDataFetchFromDate = date => ({
  type: UPDATE_RADAR_DATA_FETCH_FROM_DATE,
  date
})

export const fetchRequestWrapper = createFetchRequestWrapper({ getToken, routing: Routing })

export const fetchGraphqlWrapper = createFetchGraphqlWrapper({ getToken, uri: graphqlUri })

export const fetchDownloadableWrapper = ({
  requestType,
  route,
  urlParams = {},
  fetchOptions = {},
  onSuccess,
  onError = error => console.log(error),
  customRoute,
  authorizingRole
}) => (dispatch, getState) => {
  if (authorizingRole) {
    const isAuthorized = hasUserRole(getState(), authorizingRole)
    if (!isAuthorized) {
      return dispatch(requestNotAuthorizedByRole(requestType, authorizingRole))
    }
  }

  dispatch(setRequestStart(requestType))

  const requestOptions = assign({ api_key: userStoredData.API_KEY }, urlParams)

  // eslint-disable-next-line no-unneeded-ternary
  const finalRoute = customRoute ? customRoute : Routing.generate(route, requestOptions)

  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(finalRoute, isEmpty(fetchOptions) ? undefined : fetchOptions)
      /*eslint no-case-declarations: "warn"*/
      if (!response.ok) {
        const error = await response.json()
        throw new Error(`${response.status} ${error.detail}`)
      }
      const blob = await response.blob()
      dispatch(onSuccess(blob))
      dispatch(setRequestEnd(requestType))
      resolve(blob)
    } catch (error) {
      dispatch(setRequestFail(requestType))
      onError(error)
      reject(error)
    }
  })
}

export const expandFieldsDetails = () => ({
  type: EXPAND_FIELDS_DETAILS
})

export const collapseFieldsDetails = () => ({
  type: COLLAPSE_FIELDS_DETAILS
})

export const openNewSampleForm = () => ({
  type: OPEN_NEW_SAMPLE_FORM
})

export const closeNewSampleForm = () => ({
  type: CLOSE_NEW_SAMPLE_FORM
})

const setChartsLayout = layout => ({
  type: SET_CHARTS_LAYOUT,
  layout
})

export const setChartsLayoutGrid = () => setChartsLayout(GRID_LAYOUT)
export const setChartsLayoutList = () => setChartsLayout(LIST_LAYOUT)

export const setVisibilitySeasonLayer = (seasonLayerIndex, visible) => ({
  type: SET_VISIBILITY_SEASON_LAYER,
  seasonLayerIndex,
  visible
})

export const setOpacitySeasonLayer = (seasonLayerIndex, opacity) => ({
  type: SET_OPACITY_SEASON_LAYER,
  seasonLayerIndex,
  opacity
})

export const setOrderSeasonLayers = (oldIndex, newIndex) => ({
  type: SET_ORDER_SEASON_LAYERS,
  oldIndex,
  newIndex
})

export const toggleSeasonLayerLegends = seasonLayerIndex => ({
  type: TOGGLE_SEASON_LAYER_LEGENDS,
  seasonLayerIndex
})

export const setSeasonLayersZIndexTop = () => ({
  type: SET_SEASON_LAYERS_TO_TOP
})

export const setSeasonLayers = seasonLayers => ({
  type: SET_SEASON_LAYERS,
  seasonLayers
})

export const setSeasonLayersZIndexBottom = () => ({
  type: SET_SEASON_LAYERS_TO_BOTTOM
})

export const toggleShowSampleMarkers = show => ({ type: TOGGLE_SHOW_SAMPLE_MARKERS, show })

export const toggleExpandSelectedFieldAside = expanded => ({ type: TOGGLE_EXPANDED_SELECTED_FIELD_ASIDE, expanded })

export const expandSelectedFieldsAside = () => toggleExpandSelectedFieldAside(true)

export const toggleToolbarsDisabled = () => ({ type: TOGGLE_TOOLBARS_DISABLED })

export const setFieldsUpdatedAt = () => ({ type: SET_FIELDS_UPDATED_AT })

export const setSearchBarListMode = mode => ({ type: SET_SEARCH_LIST_BAR_MODE, mode })

export const toggleCurrentSeasonReportsMode = () => ({ type: TOGGLE_CURRENT_SEASON_REPORTS_MODE })
