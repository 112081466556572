import { closeTemporalCompareMode } from './viewmode'
import { compareToFlightGroup } from './fields'

export const SET_ACTIVE_CALENDAR_DATE = 'SET_ACTIVE_CALENDAR_DATE'
export const SET_CALENDAR_DOTS = 'SET_CALENDAR_DOTS'
export const SET_TEMPORAL_COMPARISON_DATE = 'SET_TEMPORAL_COMPARISON_DATE'
export const SET_CLOUD_COVERAGE = 'SET_CLOUD_COVERAGE'

export const setActiveCalendarDate = date => ({ type: SET_ACTIVE_CALENDAR_DATE, date })

export const setCalendarDots = dots => ({ type: SET_CALENDAR_DOTS, dots })

export const setTemporalComparisonDate = date => ({ type: SET_TEMPORAL_COMPARISON_DATE, date })

export const setCloudCoverage = cloudCoverage => ({ type: SET_CLOUD_COVERAGE, cloudCoverage })

export const resetTempComparison = () => dispatch => {
  dispatch(compareToFlightGroup(undefined))
  dispatch(closeTemporalCompareMode())
}
