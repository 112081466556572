import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, Select } from '@mui/material'

/**
 * Dropdown Select Component with the name of an organization.
 */

const Organizations = () => {
  const organizations = useSelector(state => state.userLayers.organizations.data)
  const [organizationName, setOrganizationName] = useState('')

  useEffect(() => {
    if (organizations && organizations.length > 0 && organizations[0].name) {
      // for now we always have an array of 1 organization
      setOrganizationName(organizations[0].name)
    }
  }, [organizations])

  const organizationSelect = (
    <Select sx={{ m: 1, minWidth: 120 }} labelId="organizations-select-label" id="organizations-select" value={organizationName} label="Username">
      <MenuItem value={organizationName}>{organizationName}</MenuItem>
    </Select>
  )

  return organizationName ? organizationSelect : <></>
}

export default Organizations
