import React from 'react'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px 0 0;
  opacity: ${props => (!props.isDrone && props.droneSnapshotsMode ? 0.5 : 1)};
  transition: 150ms linear;
  border-radius: 15px;
  cursor: ${props => (props.isDrone ? 'pointer' : 'default')};
  padding: ${props => (props.isDrone ? '7px 10px' : 0)};
  background: ${props =>
    props.isDrone && props.droneSnapshotsMode
      ? themeColors.vomitColorTransparent
      : props.isDrone
      ? themeColors.customLightOverlayBackground
      : themeColors.transparent};

  &:hover {
    background: ${props =>
      props.isDrone && !props.droneSnapshotsMode
        ? themeColors.vomitLightHoverTransparent
        : props.isDrone
        ? themeColors.customLightOverlayBackground
        : themeColors.transparent};
  }
`

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${props => props.dotColor || themeColors.whiteColor};
  border-radius: 50%;
  ${props => (props.style ? props.style : '')}
`

const Label = styled.div`
  font-size: 12px;
  margin-left: 8px;
  color: ${props => props.color || themeColors.whiteColor};
`

const SelectedFieldsCalendarLegendItem = ({ dotColor, name, handleClick, isDrone, droneSnapshotsMode }) => (
  <Wrapper onClick={handleClick} isDrone={isDrone} droneSnapshotsMode={droneSnapshotsMode}>
    <Dot dotColor={dotColor} />
    <Label>{name}</Label>
  </Wrapper>
)

export default SelectedFieldsCalendarLegendItem
