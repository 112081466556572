import { SET_MESSAGES } from '../storeConstants'

export default function messages(state = {}, action = {}) {
  switch (action.type) {
    case SET_MESSAGES:
      return action.messages
    default:
      return state
  }
}
