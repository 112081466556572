import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { FIELDS_REPORT_KEYS } from '../constants'

const {
  BASE_64_IMAGE,
  SCREENSHOT_PATH,
  RECIPIENT_EMAIL_INPUT,
  RECIPIENT_EMAILS,
  REPORT_TYPE_ID,
  ERRORS,
  SENDER_NAME,
  SELECTED_FIELDS_IDS,
  NOTES,
  AFFECTED_HA,
  RELATED_DATE,
  REPORT_ID
} = FIELDS_REPORT_KEYS

export const getFieldsReportModal = get('fieldsReportModal')

const getFieldsReportModalData = key => pipe(getFieldsReportModal, get(key))

export const getFieldsReportModalBase64Image = getFieldsReportModalData(BASE_64_IMAGE)

export const getFieldsReportModalScreenshotPath = getFieldsReportModalData(SCREENSHOT_PATH)

export const getFieldsReportModalRecipientEmailInput = getFieldsReportModalData(RECIPIENT_EMAIL_INPUT)

export const getFieldsReportModalRecipientEmails = getFieldsReportModalData(RECIPIENT_EMAILS)

export const getFieldsReportModalReportTypeId = getFieldsReportModalData(REPORT_TYPE_ID)

export const getFieldsReportModalErrors = pipe(getFieldsReportModal, get(ERRORS))

const getFieldsReportModalErrorsByKey = key => pipe(getFieldsReportModalErrors, get(key))

export const getFieldReportModalRecipientEmailInputError = getFieldsReportModalErrorsByKey(RECIPIENT_EMAIL_INPUT)

export const getFieldsReportModalSenderName = pipe(getFieldsReportModal, get(SENDER_NAME))

export const getFieldsReportModalSelectedFieldsIds = pipe(getFieldsReportModal, get(SELECTED_FIELDS_IDS))

export const getFieldsReportModalNotes = pipe(getFieldsReportModal, get(NOTES))

export const getFieldsReportAffectedHa = pipe(getFieldsReportModal, get(AFFECTED_HA))

export const getFieldsReportRelatedDate = pipe(getFieldsReportModal, get(RELATED_DATE))

export const getFieldsReportId = pipe(getFieldsReportModal, get(REPORT_ID))
