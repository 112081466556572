import { connect } from 'react-redux'
import { compose } from 'redux'
import { isSingleFieldSelected } from '../selectors/fields'
import LayersLeftMenuTop from '../components/LayersComponents/LayersLeftMenuTop'
import { withTranslator } from '../components/HOCComponents/withTranslator'
import { withCurrentRoute } from '../components/HOCComponents/withCurrentRoute'
import { openFieldSeasonFormModal } from '../actions/modal'
import { getUserRoles } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  userRoles: getUserRoles(state),
  isSingleFieldSelected: isSingleFieldSelected(state)
})

const mapDispatchToProps = {
  openFieldSeasonFormModal
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withTranslator, withCurrentRoute)(LayersLeftMenuTop)
