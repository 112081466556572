import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import { rotate, shake } from '../UIComponents/buttons'
import { StyledTooltip } from '../UIComponents/StyledTooltip'
import { compose } from 'redux'
import withTranslator from '../HOCComponents/withTranslator'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: ${props => (props.hidden ? 0 : 1)};
  transition: all 0.5s ease;
  background-color: inherit;
`

const StyledWrapperCounter = styled.button`
  width: 35px;
  height: 35px;
  border: 2px solid ${UIStyles.vomitColor};
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  padding: 0;
  animation: ${props => props.primaryAnimation && `${shake} 0.5s linear 0s 1`};
  background-color: inherit;
  &:after {
    display: ${props => (props.loading ? `flex` : `none`)};
    content: '';
    position: relative;
    top: -34px;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 14px 10px;
    background-color: #ffffff00;
    border: 5px solid transparent;
    border-bottom: 7px solid ${UIStyles.blueColor};
    border-radius: 50%;
    animation: ${rotate} 1.5s ease-in-out infinite;
  }
`

const StyledCounter = styled.div`
  font-size: ${({ smallSize }) => (smallSize ? '12px' : '15px')};
  margin-top: ${({ smallSize }) => (smallSize ? '3px' : '0')};
  font-weight: bold;
  color: ${UIStyles.vomitColor};
  transition: all 0.5s ease;
  padding: 5px 0;
`

const StyledIconClose = styled.i`
  font-size: 14px;
  background-color: #c6c30d;
  color: #082c3f;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  vertical-align: middle;
  line-height: 20px;
  margin-left: 6px;
  cursor: pointer;
`

function ActionButton({ selectFields, flushFieldsGroup, selectedFields, isLoading, currentLocation, isSearchFocus, closeSearchFilters, t, testIdCrossBtn }) {
  const selectedFieldsLength = selectedFields.length
  const moreThanAThousandFieldsSelected = selectedFieldsLength > 999
  const formattedSelectedFields = moreThanAThousandFieldsSelected ? '+999' : selectedFieldsLength
  const { isDashboard } = currentLocation

  const handleSelectFields = () => {
    if (selectedFieldsLength && !isDashboard) {
      selectFields()
    }
    if (isDashboard && isSearchFocus) {
      closeSearchFilters()
    }
  }

  const handleFlushFieldsGroup = () => {
    if (selectedFieldsLength) {
      flushFieldsGroup()
    }
  }
  return (
    <StyledWrapper>
      <StyledWrapperCounter loading={isLoading} primaryAnimation={selectedFieldsLength} onClick={handleSelectFields}>
        <StyledTooltip title={selectedFieldsLength ? t('open temporal comparison') : t('select fields to open temporal comparison')}>
          <StyledCounter smallSize={moreThanAThousandFieldsSelected} data-testid="selected-field-counter">
            {formattedSelectedFields}
          </StyledCounter>
        </StyledTooltip>
      </StyledWrapperCounter>

      <StyledTooltip title={selectedFieldsLength ? t('clear selected fields') : t('no fields to clear')}>
        <StyledIconClose
          id="remove-fields-selection"
          className="fas fa-times"
          onClick={handleFlushFieldsGroup}
          selected={selectedFieldsLength}
          data-testid={testIdCrossBtn}
        />
      </StyledTooltip>
    </StyledWrapper>
  )
}

export default compose(withTranslator)(ActionButton)
