import PropTypes from 'prop-types'
import OLComponent from './ol-component'

import OlView from 'ol/View'
import OlMap from 'ol/Map'
import { fromLonLat, transform } from 'ol/proj'

export default class View extends OLComponent {
  constructor(props) {
    super(props)

    this.view = new OlView({
      projection: props.projection.to,
      center: fromLonLat([37.41, 8.82]),
      zoom: 2.7
    })
  }

  onCenterChanged(event) {
    this.props.onNavigation({
      center: this.view.getCenter()
    })
  }

  onResolutionChanged(event) {
    this.props.onNavigation({
      resolution: this.view.getResolution()
    })
    return true
  }

  updateCenterAndResolutionFromProps_(props) {
    this.view.setCenter(transform(props.center, props.projection.from, props.projection.to))

    if (typeof props.resolution !== 'undefined') {
      this.view.setResolution(props.resolution)
    } else if (typeof props.zoom !== 'undefined') {
      this.view.setZoom(props.zoom)
    }
  }

  updateFromProps_(props, isMounting) {
    if (isMounting) {
      // Update the center and the resolution of the view only when it is
      // mounted the first time but not when the properties are updated
      this.updateCenterAndResolutionFromProps_(props)
    }
  }

  componentDidMount() {
    this.context.map.setView(this.view)
    this.updateFromProps_(this.props, /* isMounting = */ true)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateFromProps_(newProps)
  }
}

View.propTypes = {
  center: PropTypes.arrayOf(PropTypes.number).isRequired,
  projection: PropTypes.object,
  resolution: PropTypes.number,
  zoom: PropTypes.number,
  onNavigation: PropTypes.func
}

View.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
