import Cookie from 'js-cookie'
import { browserLanguage } from './constants'

class UserStorageData {
  constructor() {
    this._token = Cookie.get('token')
    this._locale = Cookie.get('locale') || browserLanguage
  }

  get hasToken() {
    return !!this._token
  }

  get hasLocale() {
    return !!this._locale
  }

  setToken(token) {
    this._token = token

    Cookie.set('token', this._token, { expires: 31 })
  }

  removeToken() {
    Cookie.remove('token')
    this._token = null
  }

  setLocale(locale) {
    this._locale = locale

    Cookie.set('locale', this._locale, { expires: 365 })
  }

  get locale() {
    return this._locale
  }

  get API_KEY() {
    return this.getToken()
  }

  get token() {
    return this.getToken()
  }

  getToken = () => {
    return this._token
  }
}
const userInstance = new UserStorageData()

export default userInstance

export const { getToken } = userInstance
