import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

import { openSendNewFieldsReportModal } from '../../actions/modal'
import { isSelectedFieldsEmpty } from '../../selectors/fields'
import SendFieldsReportButton from '../../components/ToolsComponents/SendFieldsReportButton'

const mapStateToProps = state => ({
  disabled: isSelectedFieldsEmpty(state)
})

const mapDispatchToProps = {
  openSendNewFieldsReportModal
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(SendFieldsReportButton)
