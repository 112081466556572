import React from 'react'
import { MAX_CLOUD_COVERAGE_INITIAL_STATE } from '../../../actions/dashboard'
import StyledSlider from '../../UIComponents/StyledSlider'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const temperatureLabelStyle = {
  color: themeColors.grayColor,
  fontSize: '12px',
  fontWeight: 600
}

export default function TemporalEvolutionMaxCloudCoverageSlider({ t, setTemporalEvolutionMaxCloudCoverage }) {
  return (
    <StyledSlider
      label={t('Cloud Coverage')}
      onChange={setTemporalEvolutionMaxCloudCoverage}
      defaultValue={0}
      step={1}
      minValue={0}
      maxValue={MAX_CLOUD_COVERAGE_INITIAL_STATE}
      unit={'%'}
      fullWidth={false}
      labelStyle={temperatureLabelStyle}
    />
  )
}
