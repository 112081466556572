import React from 'react'
import DatePickerCalendar from './DatePickerCalendar'
import moment from 'moment'
import { withTranslator } from '../../../HOCComponents/withTranslator'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${props => (props.margin ? props.margin : '0rem 0rem')};
  .MuiTextField-root {
    margin-right: 20px;
  }
`
const labelStyle = {
  fontWeight: 600,
  color: themeColors.whiteColor
}

const inputStyle = {
  color: themeColors.vomitColor
}

function DatePickerRange({ t, name, from, to, fromLabel = 'from', toLabel = 'to', onChange, filterId, margin }) {
  const today = new Date()

  const toMinDate = from ? new Date(from) : new Date(today)
  toMinDate.setDate(toMinDate.getDate() + 1)

  const fromMaxDate = to ? new Date(to) : new Date(today)
  fromMaxDate.setDate(fromMaxDate.getDate() - 1)

  const handleChangeFrom = date => {
    const fromFormatted = date.format('YYYY-MM-DD')
    onChange(filterId, { from: fromFormatted, to })
  }

  const handleChangeTo = date => {
    const toFormatted = date.format('YYYY-MM-DD')
    onChange(filterId, { from, to: toFormatted })
  }

  return (
    <Wrapper margin={margin}>
      <DatePickerCalendar
        name={`${name}From`}
        floatingLabelText={t(fromLabel)}
        onChange={handleChangeFrom}
        value={moment(from)}
        maxDate={moment(fromMaxDate)}
        labelStyle={labelStyle}
        inputStyle={inputStyle}
      />
      <DatePickerCalendar
        name={`${name}To`}
        floatingLabelText={t(toLabel)}
        onChange={handleChangeTo}
        value={moment(to)}
        minDate={moment(toMinDate)}
        labelStyle={labelStyle}
        inputStyle={inputStyle}
      />
    </Wrapper>
  )
}

export default withTranslator(DatePickerRange)
