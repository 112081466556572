import React from 'react'
import DatePickerRange from '../../UIComponents/Calendars/DateRangeCalendar/DatePickerRange'
import { withTranslator } from '../../HOCComponents/withTranslator'
import RenderIf from '@layers-frontend/commons/helpers/RenderIf'

function FieldsComparisonDateRangeSelect({ t, filterId, to, from, onChange, isVisible, weekSelected }) {
  if (weekSelected) {
    // week view date range from today to week ago - filter DATE_RANGE
    const weekAgo = new Date()
    weekAgo.setDate(weekAgo.getDate() - 7)
    return <DatePickerRange to={new Date()} from={weekAgo} onChange={onChange} name={filterId} filterId={filterId} margin={'0rem 0rem 0rem 5rem'} />
  }
  return (
    <RenderIf condition={isVisible}>
      <DatePickerRange to={to} from={from} onChange={onChange} name="FieldsComparisonDateRangeSelect" filterId={filterId} margin={'0rem 0rem 0rem 5rem'} />
    </RenderIf>
  )
}

export default withTranslator(FieldsComparisonDateRangeSelect)
