import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import find from 'lodash/fp/find'

export const getReportsTypes = get('reportsTypes')

export const getReportType = state => reportId => {
  return pipe(
    getReportsTypes,
    find(item => reportId === item.id),
    get('name')
  )(state)
}
