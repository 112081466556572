import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import SendFieldsReportModal from '../../components/ModalComponents/SendFieldsReportModal'
import {
  captureScreenshotForFieldsReportModal,
  removeScreenshotForFieldsReportModal,
  addRecipientEmailToFieldsReportModal,
  setRecipientEmailInputToFieldsReportModal,
  removeRecipientEmailFromFieldsReportModal,
  setReportTypeIdForFieldsReportModal,
  closeAndResetFieldsReportModal,
  setFieldsReportModalSenderName,
  setFieldsReportModalSelectedFieldsIds,
  setFieldsReportModalNotes,
  validateAndSendFieldsReport,
  setFieldsReportModalAffectedHa,
  setFieldsReportModalRelatedDate
} from '../../actions/fieldsReportModal'
import {
  getFieldsReportAffectedHa,
  getFieldsReportId,
  getFieldsReportModalBase64Image,
  getFieldsReportModalErrors,
  getFieldsReportModalNotes,
  getFieldsReportModalRecipientEmailInput,
  getFieldsReportModalRecipientEmails,
  getFieldsReportModalReportTypeId,
  getFieldsReportModalScreenshotPath,
  getFieldsReportModalSelectedFieldsIds,
  getFieldsReportModalSenderName,
  getFieldsReportRelatedDate
} from '../../selectors/fieldsReportModal'
import { getReportsTypes } from '@layers-frontend/commons/store/selectors/reportsTypes'
import { getSelectedFields } from '../../selectors/fields'
import { openReportManagerModal } from '../../actions/modal'
import { getUserConfigSurface } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  affectedHa: getFieldsReportAffectedHa(state),
  appSelectedFields: getSelectedFields(state),
  errors: getFieldsReportModalErrors(state),
  modalSelectedFieldsIds: getFieldsReportModalSelectedFieldsIds(state),
  notes: getFieldsReportModalNotes(state),
  recipentEmails: getFieldsReportModalRecipientEmails(state),
  recipientInput: getFieldsReportModalRecipientEmailInput(state),
  relatedDate: getFieldsReportRelatedDate(state),
  reportTypes: getReportsTypes(state),
  screenshotSource: getFieldsReportModalBase64Image(state),
  screenshotPath: getFieldsReportModalScreenshotPath(state),
  selectedReportId: getFieldsReportModalReportTypeId(state),
  senderName: getFieldsReportModalSenderName(state),
  reportId: getFieldsReportId(state),
  surfaceUnit: getUserConfigSurface(state)
})

const mapDispatchToProps = {
  addRecipientEmailToFieldsReportModal,
  captureScreenshotForFieldsReportModal,
  closeAndResetFieldsReportModal,
  removeRecipientEmailFromFieldsReportModal,
  removeScreenshotForFieldsReportModal,
  setFieldsReportModalAffectedHa,
  setFieldsReportModalNotes,
  setFieldsReportModalRelatedDate,
  setFieldsReportModalSelectedFieldsIds,
  setFieldsReportModalSenderName,
  setRecipientEmailInputToFieldsReportModal,
  setReportTypeIdForFieldsReportModal,
  validateAndSendFieldsReport,
  openReportManagerModal
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(SendFieldsReportModal)
