import { fetchRequestWrapper } from './ui'
import moment from 'moment'
import { getToken } from '../userStoredData'

export const REQUEST_FETCH_METEOROLOGICAL_API_DATA = 'REQUEST_FETCH_METEOROLOGICAL_API_DATA'
export const RECEIVE_FETCH_METEOROLOGICAL_API_DATA = 'RECEIVE_FETCH_METEOROLOGICAL_API_DATA'

function fetchSingleMeteoData({ startDate, endDate, fieldId }) {
  const requestType = `${REQUEST_FETCH_METEOROLOGICAL_API_DATA}_${fieldId}_${startDate}_${endDate}`
  const baseCustomRoute = `${window.location.origin}/athena/weather`

  return fetchRequestWrapper({
    customRoute: `${baseCustomRoute}?${new URLSearchParams({ api_key: getToken() })}`,
    requestType,
    fetchOptions: {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify({ from: moment(startDate).format('YYYY-MM-DD'), to: moment(endDate).format('YYYY-MM-DD'), api_key: getToken(), field_id: fieldId })
    },
    onSuccess: meteorologicalData => {
      return { type: RECEIVE_FETCH_METEOROLOGICAL_API_DATA, meteorologicalData: meteorologicalData.data }
    }
  })
}

export const fetchMeteorologicalApiData = ({ startDate, endDate, fieldId }) => dispatch => {
  return dispatch(
    fetchSingleMeteoData({
      startDate,
      endDate,
      fieldId
    })
  )
}
