import React from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import moment from 'moment'
import { DateBox } from '../UIComponents/flights'
import styled from 'styled-components'
import { UIStyles } from '../../theme'

const ButtonWrapper = styled.button`
  width: 40px;
  height: 40px;
  background: ${UIStyles.blueColor};
  border-radius: 50%;
  position: absolute;
  top: 15%;
  margin-left: -15px;
  border: 0;
`

const StyledIconClose = styled.i`
  font-size: 24px;
  color: ${UIStyles.redColor};
`

export default class FlightTemporalSwipe extends React.Component {
  handleDrag = (e, data) => {
    this.props.moveTemporalSlider(e.clientX)
  }

  render = () => {
    const { firstFlightDate, secondFlightDate, resetTempComparison } = this.props

    return (
      <div>
        <Draggable key="dragbar" axis="x" handle=".temporalswipe" bounds="parent" onDrag={this.handleDrag}>
          <div>
            <DateBox align="left">{moment(firstFlightDate).format('DD MMM YY')}</DateBox>
            <DateBox align="right">{moment(secondFlightDate).format('DD MMM YY')}</DateBox>
            <ButtonWrapper onClick={resetTempComparison}>
              <StyledIconClose className="ion-close" />
            </ButtonWrapper>
            <div className="temporalswipe">
              <i className="fas fa-arrows-alt-h" />
            </div>
          </div>
        </Draggable>
      </div>
    )
  }
}

FlightTemporalSwipe.propTypes = {
  firstFlightDate: PropTypes.string,
  moveTemporalSlider: PropTypes.func,
  secondFlightDate: PropTypes.string,
  temporalSwipe: PropTypes.number
}
