import undoable, { includeAction } from 'redux-undo'
import {
  OPEN_SEARCH_MODE,
  OPEN_CREATION_MODE,
  CLOSE_CREATION_MODE,
  OPEN_EDIT_MODE,
  CLOSE_EDIT_MODE,
  CLOSE_READ_ONLY_MODE,
  RECEIVE_VIEWER_DATA,
  OPEN_EDIT_MODE_WITH_CURRENT_GEOMETRY,
  OPEN_SAMPLE_LOCATION_MODE
} from '../actions/viewmode'

import {
  CLICK_POLYGON_MOVE,
  CLICK_POLYGON_DRAW,
  CLICK_CIRCLE_DRAW,
  CLICK_UNDO,
  CLICK_DELETE,
  CLICK_POLYGON_SLICE,
  CLICK_DISTANCE_TOOL,
  CLICK_AREA_TOOL,
  POLYGON_DRAW_START,
  POLYGON_ADD,
  POLYGON_MOVE,
  POLYGON_SLICE,
  ADD_POINT,
  ADD_NEW_POINT,
  ADD_HOLE,
  FIELD_MODIFY,
  POLYGON_DRAW,
  DISTANCE_TOOL,
  DELETE,
  SLICE,
  TOGGLE_MEASURE_TOOLS,
  SET_EDITOR_FEATURES_AND_SURFACE,
  UPDATE_FIELD_AREA,
  MARKER_TOOL,
  ADD_MARKER
} from '../actions/editor'

import { CHANGE_TUTORIAL_STEP, TOGGLE_TUTORIAL } from '../actions/tutorial'
import toString from 'lodash/toString'
import { CONFIRM_SAMPLE_FORM_LOCATION } from '../actions/samplesForm'
import { DENY_SAMPLE_LOCATION } from '@layers-frontend/commons/store/storeConstants'

const initialEditorState = {
  active: false,
  features: [],
  activeTool: POLYGON_DRAW,
  drawing: false,
  isMeasureToolsActive: false,
  delete: false,
  currentDrawingGeometry: {},
  exceptionMessage: '',
  surface: 0,
  markerLocation: null
}

const editor = (state = initialEditorState, action) => {
  switch (action.type) {
    case OPEN_CREATION_MODE:
      return Object.assign({}, state, {
        active: true,
        features: [],
        activeTool: POLYGON_DRAW,
        delete: false
      })
    case OPEN_EDIT_MODE:
      return Object.assign({}, state, {
        active: true,
        features: action.features,
        activeTool: POLYGON_DRAW,
        delete: true
      })

    case OPEN_SAMPLE_LOCATION_MODE:
      return Object.assign({}, state, {
        active: true,
        features: [],
        activeTool: MARKER_TOOL
      })

    case OPEN_EDIT_MODE_WITH_CURRENT_GEOMETRY:
      return {
        ...state,
        active: true,
        activeTool: POLYGON_DRAW,
        delete: true
      }

    case UPDATE_FIELD_AREA:
      return {
        ...state,
        surface: action.surface
      }

    case SET_EDITOR_FEATURES_AND_SURFACE:
      return {
        ...state,
        delete: true,
        active: true,
        features: action.features,
        surface: action.surface
      }

    case OPEN_SEARCH_MODE:
    case CLOSE_CREATION_MODE:
    case CLOSE_EDIT_MODE:
    case CLOSE_READ_ONLY_MODE:
      return {
        ...state,
        active: false,
        features: [],
        activeTool: state.isMeasureToolsActive ? DISTANCE_TOOL : POLYGON_DRAW,
        drawing: false,
        delete: false
      }

    case CONFIRM_SAMPLE_FORM_LOCATION:
    case DENY_SAMPLE_LOCATION:
      return {
        ...state,
        active: false,
        features: [],
        activeTool: POLYGON_DRAW,
        drawing: false,
        delete: false
      }

    case TOGGLE_MEASURE_TOOLS:
      return {
        ...state,
        isMeasureToolsActive: !state.isMeasureToolsActive,
        activeTool: state.isMeasureToolsActive ? POLYGON_DRAW : DISTANCE_TOOL
      }

    case CLICK_POLYGON_MOVE:
    case CLICK_POLYGON_DRAW:
    case CLICK_CIRCLE_DRAW:
    case CLICK_POLYGON_SLICE:
    case CLICK_UNDO:
    case CLICK_DELETE:
    case CLICK_DISTANCE_TOOL:
    case CLICK_AREA_TOOL:
      return Object.assign({}, state, {
        activeTool: action.payload
      })

    case POLYGON_DRAW_START:
      return Object.assign({}, state, {
        drawing: true,
        currentDrawingGeometry: action.payload.geometry
      })

    case DELETE:
      // eslint-disable-next-line no-case-declarations, prefer-const
      let dFeatures = state.features
        .filter(element => {
          return toString(element.uid) !== toString(action.payload.uid)
        })
        .map((polygon, index) => {
          return polygon
        })

      return Object.assign({}, state, {
        features: dFeatures,
        delete: dFeatures.length
      })
    case POLYGON_MOVE:
    case ADD_POINT: {
      const uid = action.payload.uid
      const newGeometry = action.payload.geometry
      const updatedFeatures = state.features.map(feature =>
        feature.uid === uid
          ? {
              // eslint-disable-next-line object-shorthand
              uid: uid,
              geometry: newGeometry,
              area: action.payload.area
            }
          : feature
      )
      return Object.assign({}, state, {
        features: updatedFeatures,
        drawing: action.payload.drawing
      })
    }
    case ADD_NEW_POINT: {
      // const uid = action.payload.uid
      const newGeometry = action.payload.geometry

      return Object.assign({}, state, {
        currentDrawingGeometry: newGeometry,
        drawing: action.payload.drawing
      })
    }
    case POLYGON_ADD:
      // eslint-disable-next-line no-var
      var features = [
        ...state.features,
        {
          uid: action.payload.uid,
          geometry: action.payload.geometry
        }
      ]
      return Object.assign({}, state, {
        // eslint-disable-next-line object-shorthand
        features: features,
        drawing: action.payload.drawing,
        currentDrawingGeometry: {}
      })

    case ADD_MARKER:
      return Object.assign({}, state, {
        features: [
          {
            uid: action.payload.uid,
            geometry: action.payload.geometry,
            area: action.payload.area
          }
        ]
      })

    case ADD_HOLE:
      /* eslint-disable no-var */
      var uid = action.payload.uid
      var newGeometry = action.payload.geometry
      // update existing feature geometry
      var updatedFeatures = state.features.map(feature =>
        /* eslint-enable no-var */
        toString(feature.uid) === toString(uid)
          ? {
              // eslint-disable-next-line object-shorthand
              uid: uid,
              geometry: newGeometry,
              area: action.payload.area
            }
          : feature
      )
      return Object.assign({}, state, {
        features: updatedFeatures,
        drawing: action.payload.drawing,
        currentDrawingGeometry: {}
      })

    case FIELD_MODIFY:
      return Object.assign({}, state, {
        features: action.payload.geometries
      })

    default:
      return state
  }
}

export default undoable(editor, {
  filter: includeAction([POLYGON_ADD, ADD_HOLE, ADD_POINT, DELETE, POLYGON_MOVE]),
  groupBy: (action, currentState) => {
    if (
      (action.type === POLYGON_ADD || action.type === CHANGE_TUTORIAL_STEP || action.type === DELETE || action.type === SLICE) &&
      currentState.activeTool === POLYGON_SLICE
    ) {
      return SLICE
    } else {
      return null
    }
  }, // group by only works in version 1.0.0 beta atm
  initTypes: [RECEIVE_VIEWER_DATA, CLOSE_CREATION_MODE, CLOSE_EDIT_MODE, TOGGLE_TUTORIAL],
  syncFilter: true
})
