import React, { useMemo } from 'react'
import GlobalFilesTabs from './GlobalFilesTabs'
import withTranslator from '../../HOCComponents/withTranslator'
import { organizeData } from './prepareJson'
import GlobalFilesTable from './GlobalFilesTable'
import isEmpty from 'lodash/fp/isEmpty'
import includes from 'lodash/includes'
import { PREDICTIVE_REPORTS_CATEGORIES, USER_ROLES } from '../../../constants'

const GlobalFilesModal = props => {
  const { userId, t, globalFiles, userRoles } = props
  const { FORECAST, REPORT, AUXILIARY } = PREDICTIVE_REPORTS_CATEGORIES
  const { ROLE_PREDTECH_AUX, ROLE_PREDTECH_FORECASTS, ROLE_PREDTECH_REPORTS } = USER_ROLES

  const data = useMemo(() => {
    if (userId && !isEmpty(globalFiles)) {
      const cleanedData = organizeData(globalFiles.globalFiles, userId)
      return cleanedData
    }
  }, [userId, globalFiles])

  const tabs = data => {
    const tabList = []

    if (data[FORECAST] && includes(userRoles, ROLE_PREDTECH_FORECASTS)) {
      tabList.push({
        label: t('forecasts'),
        content: <GlobalFilesTable {...props} reports={data[FORECAST]} />
      })
    }

    if (data[REPORT] && includes(userRoles, ROLE_PREDTECH_REPORTS)) {
      tabList.push({
        label: t('reports manager'),
        content: <GlobalFilesTable {...props} reports={data[REPORT]} />
      })
    }

    if (data[AUXILIARY] && includes(userRoles, ROLE_PREDTECH_AUX)) {
      tabList.push({
        label: t('auxiliary files'),
        content: <GlobalFilesTable {...props} reports={data[AUXILIARY]} />
      })
    }

    return tabList
  }
  return data && <GlobalFilesTabs tabs={tabs(data)} />
}
export default withTranslator(GlobalFilesModal)
