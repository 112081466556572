import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { closeModal } from '../../actions/modal'
import GoToFieldModal from '../../components/ModalComponents/GoToFieldModal'

const mapDispatchToProps = {
  closeModal
}

export default compose(withTranslator, connect(null, mapDispatchToProps))(GoToFieldModal)
