import { SELECT_SAMPLE_BY_ID, DESELECT_ALL_SAMPLES, RECEIVE_SAMPLES_BY_SEASON_IDS, DELETE_SAMPLE, UPDATE_SAMPLE_POSITION } from '../actions/samples'
import get from 'lodash/get'
import first from 'lodash/first'
import uniqBy from 'lodash/uniqBy'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import { DENY_SAMPLE_LOCATION } from '@layers-frontend/commons/store/storeConstants'

const samplesInitialState = {}

export function samples(state = samplesInitialState, action = {}) {
  switch (action.type) {
    case RECEIVE_SAMPLES_BY_SEASON_IDS:
      // eslint-disable-next-line no-case-declarations
      const { samples } = action
      return reduce(
        samples,
        (accumulator, sample) => {
          const seasonId = get(sample, 'seasonId')
          sample.selected = false
          return {
            ...accumulator,
            [seasonId]: accumulator[seasonId] ? uniqBy([...accumulator[seasonId], sample], 'id') : [sample]
          }
        },
        state
      )
    case SELECT_SAMPLE_BY_ID:
      return reduce(
        state,
        (accumulator, seasonSamples) => {
          const seasonId = get(first(seasonSamples), 'seasonId')
          return {
            ...accumulator,
            [seasonId]: map(seasonSamples, sample => (sample.id === action.sampleId ? { ...sample, selected: true } : { ...sample, selected: false }))
          }
        },
        {}
      )
    case DESELECT_ALL_SAMPLES:
      return reduce(
        state,
        (accumulator, seasonSamples) => {
          const seasonId = get(first(seasonSamples), 'seasonId')
          return {
            ...accumulator,
            [seasonId]: map(seasonSamples, sample => ({ ...sample, selected: false }))
          }
        },
        {}
      )
    case UPDATE_SAMPLE_POSITION:
      // eslint-disable-next-line no-case-declarations
      const { sampleId, lat, lon, seasonId } = action
      return {
        ...state,
        [seasonId]: map(state[seasonId], sample => (sample.id === sampleId ? { ...sample, lat, lon } : sample))
      }

    case DELETE_SAMPLE:
      // eslint-disable-next-line no-case-declarations, camelcase
      const { id, season_id } = action.sample
      // eslint-disable-next-line no-case-declarations, camelcase
      const filteredSamples = filter(state[season_id], sample => sample.id !== id)
      return {
        ...state,
        // eslint-disable-next-line camelcase
        [season_id]: filteredSamples
      }
    case DENY_SAMPLE_LOCATION:
      // eslint-disable-next-line no-case-declarations
      const mapFn = sample => {
        if (sample.id === action.sampleId) {
          return { ...sample, lat: action.lat, lon: action.lon }
        }
        return sample
      }
      return {
        ...state,
        [action.seasonId]: map(state[action.seasonId], mapFn)
      }

    default:
      return state
  }
}
