import {
  RESET_SELECTED_FIELD_TABS,
  SET_ACTIVE_FIELD_TAB,
  SET_SELECTED_FIELD_SAMPLES_TAB,
  HIDE_SELECTED_FIELD_TAB,
  SET_SELECTED_FIELD_TAB_VISIBILITY
} from '../actions/selectedFieldTabs'
import map from 'lodash/map'
import { DENY_SAMPLE_LOCATION } from '@layers-frontend/commons/store/storeConstants'
import { CONFIRM_SAMPLE_FORM_LOCATION } from '../actions/samplesForm'

const initialSelectedFieldTabsState = {
  tabs: [
    {
      name: 'fieldsInfo',
      title: 'info',
      icon: 'fas fa-info',
      visible: true
    },
    {
      name: 'calendar',
      title: 'calendar',
      icon: 'fa-solid fa-calendar-days',
      visible: true
    },
    {
      name: 'layers',
      title: 'layers',
      icon: 'icon-top-season-layer',
      visible: false
    },
    {
      name: 'seasonLayers',
      title: 'static layers',
      icon: 'icon-bottom-season-layer-1',
      visible: false
    },
    {
      name: 'downloads',
      title: 'downloads',
      icon: 'fas fa-download',
      visible: false
    },
    {
      name: 'samples',
      title: 'samples',
      icon: 'fas fa-tag',
      visible: false
    },
    {
      name: 'fileGenerator',
      title: 'File Generator',
      icon: 'fas fa-file-export',
      visible: true
    }
  ],
  activeTabIndex: 1
}

export default function selectedFieldTabs(state = initialSelectedFieldTabsState, action) {
  switch (action.type) {
    case SET_ACTIVE_FIELD_TAB:
      return {
        ...state,
        activeTabIndex: action.tabIndex
      }
    case SET_SELECTED_FIELD_TAB_VISIBILITY:
      return {
        ...state,
        tabs: map(state.tabs, tab => (tab.name === action.tabName ? { ...tab, visible: true } : tab))
      }
    case HIDE_SELECTED_FIELD_TAB:
      // eslint-disable-next-line no-case-declarations
      const activeTabName = state.tabs[state.activeTabIndex].name
      // eslint-disable-next-line no-case-declarations
      const updatedTabIndex = activeTabName === action.tabName ? 2 : state.activeTabIndex

      return {
        ...state,
        tabs: map(state.tabs, tab => (tab.name === action.tabName ? { ...tab, visible: false } : tab)),
        activeTabIndex: updatedTabIndex
      }

    case RESET_SELECTED_FIELD_TABS:
      return initialSelectedFieldTabsState

    case SET_SELECTED_FIELD_SAMPLES_TAB:
    case DENY_SAMPLE_LOCATION:
    case CONFIRM_SAMPLE_FORM_LOCATION:
      return {
        ...state,
        activeTabIndex: 5
      }

    default:
      return state
  }
}
