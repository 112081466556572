import { getFieldById, getFirstSelectedFieldId, getSelectedFieldsIds } from './fields'
import { getCalendarActiveDate } from './calendar'

import moment from 'moment'

import find from 'lodash/fp/find'
import filter from 'lodash/fp/filter'
import get from 'lodash/fp/get'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import reduce from 'lodash/fp/reduce'
import keys from 'lodash/fp/keys'
import toNumber from 'lodash/fp/toNumber'
import isNil from 'lodash/fp/isNil'
import { getSelectedFlightGroupsDate } from './flightGroups'
import { getSeasonById } from './seasons'

export const getFieldsSeasonsTimeline = get('fieldsSeasonsTimeline')

const getFieldSeasonsTimeline = fieldId => pipe(getFieldsSeasonsTimeline, get(fieldId))

export const getFieldSeasonsTimelineIds = fieldId => pipe(getFieldSeasonsTimeline(fieldId), map('id'))

export const getSelectedFieldsSeasonsTimelineIds = state => {
  const fieldIds = getSelectedFieldsIds(state)
  return reduce((accumulator, fieldId) => {
    const currentFieldSeasons = getFieldSeasonsTimelineIds(fieldId)(state)
    return currentFieldSeasons ? [...accumulator, ...currentFieldSeasons] : accumulator
  }, [])(fieldIds)
}

export const getFirstSelectedFieldSeasonTimelineIds = state => {
  const selectedField = getFirstSelectedFieldId(state)

  return getFieldSeasonsTimelineIds(selectedField)(state)
}

export const getFieldsSeasonsTimelineFieldIds = pipe(getFieldsSeasonsTimeline, keys, map(toNumber))

export const getFieldSeasonTimeLineActiveSeasonByFieldId = (state, fieldId) => {
  const activeDate = moment(getCalendarActiveDate(state))
  const seasons = getFieldSeasonsTimeline(fieldId)(state)
  return find(season => moment(season.startDate).isSameOrBefore(activeDate, 'day') && moment(season.endDate).isSameOrAfter(activeDate, 'day'))(seasons)
}

export const getSelectedFieldsActiveSeasonTimeLine = state => {
  const fieldIds = getSelectedFieldsIds(state)
  return map(fieldId => getFieldSeasonTimeLineActiveSeasonByFieldId(state, fieldId))(fieldIds)
}

export const getFieldSeasonTimeLineActiveSeasonIdByFieldId = (state, fieldId) => {
  const season = getFieldSeasonTimeLineActiveSeasonByFieldId(state, fieldId)
  return get('id')(season)
}

// deprecated
export const getFieldSeasonTimelineFirstSeasonIds = state =>
  pipe(
    getFieldsSeasonsTimelineFieldIds,
    reduce((accumulator, fieldId) => {
      const seasonId = getFieldSeasonTimeLineActiveSeasonIdByFieldId(state, fieldId)
      return !isNil(seasonId) ? [...accumulator, seasonId] : accumulator
    }, [])
  )(state)

const getSeasonWithinDateRangeByFieldId = (state, fieldId, from, to) => {
  const seasons = getFieldSeasonsTimeline(fieldId)(state)
  const fromDate = moment(from)
  const toDate = moment(to)
  return filter(
    season =>
      (moment(season.startDate).isSameOrBefore(fromDate, 'day') && moment(season.endDate).isSameOrAfter(fromDate, 'day')) ||
      (moment(season.startDate).isSameOrBefore(toDate, 'day') && moment(season.endDate).isSameOrAfter(toDate, 'day'))
  )(seasons)
}

export const getSeasonIdsWithinDateRangeByFieldId = (state, fieldId, from, to) => {
  const seasonsInDateRange = getSeasonWithinDateRangeByFieldId(state, from, to, fieldId)
  return map(get('id'))(seasonsInDateRange)
}

/**
 * Retrieves the season for a given field ID and date.
 *
 * @param {Object} state - The global state object.
 * @param {string} fieldId - The ID of the field to get the seasons for.
 * @param {string|Date} date - The date to check within the season timeline.
 * @returns {Object|null} The season object if a matching season is found, otherwise null.
 */

const getSeasonByFieldIdAndDate = (state, fieldId, date) => {
  const seasons = getFieldSeasonsTimeline(fieldId)(state)
  return find(season => moment(season.startDate).isSameOrBefore(date, 'day') && moment(season.endDate).isSameOrAfter(date, 'day'))(seasons)
}

/**
 * Gets the season ID and surface for a given field ID and date.
 *
 * @param {Object} state - The global state object.
 * @param {string} fieldId - The ID of the field to get the season details for.
 * @param {string|Date} date - The date to check within the season timeline.
 * @returns {Object} An object containing the season ID and surface, or null if no matching season is found.
 * @property {number|null} seasonId - The ID of the season.
 * @property {number|null} surface - The surface area of the field.
 */

export const getSeasonIdsByFieldIdAndDate = (state, fieldId, date) => {
  const season = getSeasonByFieldIdAndDate(state, fieldId, date)
  const seasonId = get('id')(season)
  const surface = get('surface')(season)
  return { seasonId, surface }
}

const getSeasonWithinDateRangeByFieldIds = (state, fieldIds, from, to) =>
  reduce((accumulator, fieldId) => {
    return [...accumulator, ...getSeasonWithinDateRangeByFieldId(state, fieldId, from, to)]
  }, [])(fieldIds)

export const getSeasonIdsWithinDateRangeByFieldIds = (state, fieldIds, from, to) => {
  const seasons = getSeasonWithinDateRangeByFieldIds(state, fieldIds, from, to)
  return map(get('id'))(seasons)
}

export const getSelectedFieldsWithSeason = state => {
  const selectedFieldsIds = getSelectedFieldsIds(state)
  const selectedFlightGroupDate = getSelectedFlightGroupsDate(state)
  const seasonIds = getSeasonIdsWithinDateRangeByFieldIds(state, selectedFieldsIds, selectedFlightGroupDate, selectedFlightGroupDate)
  return reduce((accumulator, seasonId) => {
    const season = getSeasonById(seasonId)(state)
    const fieldId = get('fieldId')(season)
    const field = getFieldById(state, fieldId)
    return [...accumulator, { ...field, season }]
  }, [])(seasonIds)
}
