import { toggleSearchBar } from './search'
import { closeCreationMode, closeEditMode } from './viewmode'
import { toggleMeasureTool } from './editor'
import '../utils'

/** actions **/

export const TOGGLE_TUTORIAL = 'TOGGLE_TUTORIAL'
export const CHANGE_TUTORIAL_STEP = 'CHANGE_TUTORIAL_STEP'
export const SET_TUTORIAL = 'SET_TUTORIAL'

/** different tutorials **/

export const SATELLITE_TUTORIAL = 'SATELLITE_TUTORIAL'
export const MEASURES_TUTORIAL = 'MEASURES_TUTORIAL'
export const ADD_FIELD_TUTORIAL = 'ADD_FIELD_TUTORIAL'
export const EDIT_FIELD_TUTORIAL = 'EDIT_FIELD_TUTORIAL'

export const toggleUITutorial = () => {
  return {
    type: TOGGLE_TUTORIAL
  }
}

export const toggleTutorial = () => {
  return function (dispatch, getState) {
    // we init elements when opening tutorial
    /* eslint-disable prefer-const */
    let isSearchOpen = getState().search.open
    let isDrawingOpen = getState().editor.present.active
    let selectedField = getState().selectedField
    let isMeasuresToolsOpen = getState().editor.present.isMeasureToolsActive
    /* eslint-enable prefer-const */

    if (isSearchOpen) dispatch(toggleSearchBar())

    if (isDrawingOpen)
      if (selectedField.isNullObject()) dispatch(closeCreationMode())
      else dispatch(closeEditMode())

    if (isMeasuresToolsOpen) dispatch(toggleMeasureTool())

    return dispatch(toggleUITutorial())
  }
}

export const changeStep = step => {
  return {
    type: CHANGE_TUTORIAL_STEP,
    payload: step
  }
}

export const setTutorial = tutorial => {
  return {
    type: SET_TUTORIAL,
    payload: tutorial
  }
}
