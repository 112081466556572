import { connect } from 'react-redux'
import { TEMPORAL_COMPARE_VIEW, FIELD_VIEW } from '../../actions/viewmode'
import { isFieldSeasonFormEditorOpen } from '../../selectors/fieldSeasonForm'
import { denyFieldSeasonFormGeometryAndSurface, confirmFieldSeasonFormGeometryAndSurface } from '../../actions/fieldSeasonForm'
import { denySampleLocation } from '@layers-frontend/commons/store/actions/samplesForm'
/* eslint-disable import/no-duplicates */
import { confirmSampleLocation } from '../../actions/samplesForm'
import { setSampleFormLocation } from '../../actions/samplesForm'
import { validateSampleFormLocation } from '../../actions/samplesForm'
/* eslint-enable import/no-duplicates */
import { isTemporalView } from '../../selectors/viewmode'
import toString from 'lodash/toString'

export const connectToolBar = WrappedComponent => {
  const mapStateToProps = state => {
    const isTemporalActive = isTemporalView(state)
    return {
      // eslint-disable-next-line object-shorthand
      isTemporalActive: isTemporalActive,
      isToolbarOpen: state.editor.present.active || isTemporalActive,
      isFieldSeasonFormEditorOpen: isFieldSeasonFormEditorOpen(state),
      mode:
        state.selectedField.isNullObject() &&
        toString(state.screenView) !== TEMPORAL_COMPARE_VIEW &&
        !(FIELD_VIEW === state.screenView && state.selectedFields.length === 1)
          ? 'Creation'
          : 'Edit',
      action: !(state.editor.present.active || isTemporalActive) ? 'open' : 'close'
    }
  }

  const mapDispatchToProps = {
    denyFieldSeasonFormGeometryAndSurface,
    confirmFieldSeasonFormGeometryAndSurface,
    setSampleFormLocation,
    validateSampleFormLocation,
    confirmSampleLocation,
    denySampleLocation
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
