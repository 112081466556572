import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'

import { getFieldsFilteredByView } from '../../selectors/availableFields'
import SearchBarFiltersAndList from '../../components/SearchComponents/SearchBarFiltersAndList'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => ({
  fields: getFieldsFilteredByView({ fieldsWithAvailableAndSelected, filterAvailableAndSelected: false })(state)
})

export default compose(withFieldsAvailableAndSelected, connect(mapStateToProps, null))(SearchBarFiltersAndList)
