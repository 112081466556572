import { RECEIVE__SAMPLE_PARAMETERS_BY_TYPE } from '../storeConstants'
import { map, reduce } from 'lodash'

const normalizeSampleParameters = sampleParametersByType => {
  return reduce(
    sampleParametersByType,
    (accumulator, { id: sampleTypeId, sampleTypeParameters }) => {
      return {
        ...accumulator,
        [sampleTypeId]: map(sampleTypeParameters, 'sampleParameter')
      }
    },
    {}
  )
}

export const fetchSampleParametersByTypeSuccess = ({ sampleParametersByType }) => ({
  type: RECEIVE__SAMPLE_PARAMETERS_BY_TYPE,
  sampleParametersByType: normalizeSampleParameters(sampleParametersByType)
})
