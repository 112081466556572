import PropTypes from 'prop-types'
import OLInteraction from './ol-interaction'

import OlTranslate from 'ol/interaction/Translate'
import OlMap from 'ol/Map'
import OlVectorLayer from 'ol/layer/Vector'
import OlCollection from 'ol/Collection'

export default class Translate extends OLInteraction {
  constructor(props, context) {
    super(props, context)
    this.interaction = this.createInteraction(props, context)
    this.eventHandlerKeys_ = {}
  }

  createInteraction(props, context) {
    return new OlTranslate({
      condition: props.condition,
      layer: context.layer
    })
  }
}

Translate.olEvents = ['translateend', 'translatestart']

Translate.propTypes = Object.assign({}, OLInteraction.propTypes, {
  active: PropTypes.bool,
  style: PropTypes.arrayOf(PropTypes.object),
  translateend: PropTypes.func,
  translatestart: PropTypes.func,
  features: PropTypes.instanceOf(OlCollection)
})

Translate.contextTypes = {
  map: PropTypes.instanceOf(OlMap),
  layer: PropTypes.instanceOf(OlVectorLayer)
}
