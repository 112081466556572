import React from 'react'

import { withTranslator } from '../HOCComponents/withTranslator'
import StyledListButton from '../UIComponents/StyledListButton'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

const DownloadGeoJsonButton = ({ t, isFieldsGeoJsonLoading, downloadSelectedFieldsGeoJson, selectedFields }) => {
  const isDisabled = selectedFields.length === 0

  const handleClick = () => {
    downloadSelectedFieldsGeoJson()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_DOWNLOAD_SELECTED_GEOMETRIES, {
      button: 'Download selected geometries',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return (
    <div>
      <StyledListButton
        labelDistance={6}
        icon={'icon-json'}
        onClick={handleClick}
        disabled={isDisabled}
        testId="geo-json-button"
        loading={isFieldsGeoJsonLoading}
        label={t('download selected geometries')}
      />
    </div>
  )
}

export default withTranslator(DownloadGeoJsonButton)
