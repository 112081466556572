import React from 'react'
import { BarChart, Bar, Cell, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import { Column, BoxContainer, ChartTitle, ChartSubTitle } from './Layout'

import get from 'lodash/get'
import isNumber from 'lodash/isNumber'
import isInteger from 'lodash/isInteger'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'

export const TooltopHeading = styled.p`
  margin: 0px;
  padding: 8px 10px;
  background: ${UIStyles.vomitColor};
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  border-radius: 5px;
  font-weight: bold;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  text-align: ${props => props.textAlign || 'center'};
`
export const TooltipWrapper = styled.div`
  margin: 0px;
  padding: 8px 10px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  text-align: ${props => props.textAlign || 'center'};

  p.value {
    color: ${UIStyles.lightBlue};
  }

  p:last-child {
    margin-bottom: 0;
  }
`

const HistogramChartCustomTooltipWrapper = styled.div`
  color: ${UIStyles.darkestGrayColor};
`

export const CustomTooltip = props => {
  if (!props.active) return null

  const { label, title, valueName, value } = props
  const formattedValue = isNumber(value) && !isInteger(value) ? +value.toFixed(2) : value

  return (
    <div>
      {title ? <TooltopHeading>{title}</TooltopHeading> : null}
      <TooltipWrapper>
        <p>{label}</p>
        <p className="value">
          {valueName}: {formattedValue}
        </p>
      </TooltipWrapper>
    </div>
  )
}

export const HistogramChartCustomTooltip = props => {
  if (!props.active) return null

  const customerName = get(props, 'payload[0].payload.customer')
  const label = get(props, 'payload[0].payload.label')
  const valueName = get(props, 'payload[0].name')
  const value = get(props, 'payload[0].value')
  const formattedValue = isNumber(value) && !isInteger(value) ? +value.toFixed(4) : value

  return (
    <HistogramChartCustomTooltipWrapper>
      {customerName ? <TooltopHeading>{customerName}</TooltopHeading> : null}
      <TooltipWrapper>
        <p>{label}</p>
        <p className="value">
          {valueName}: {formattedValue}
        </p>
      </TooltipWrapper>
    </HistogramChartCustomTooltipWrapper>
  )
}

const TitleAndAverageWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`
const AverageWrapper = styled.div`
  ${ChartSubTitle} {
    margin-bottom: 0px;
  }
  ${ChartSubTitle}:first-child {
    color: ${UIStyles.lightBlue};
  }
  ${ChartSubTitle}:last-child {
    color: ${UIStyles.vomitColor};
  }
`

const HistogramChartTitle = styled(ChartTitle)`
  font-size: 25px;
`

export const HistogramChart = ({ title, subtitle, data = [], XLabel, YLabel, withAverage, onClick, columnWidth, order = 'asc' }) => {
  if (isEmpty(data)) return null
  const average = withAverage ? data.reduce((sum, item) => sum + item[YLabel], 0) / data.length : null
  // eslint-disable-next-line no-extra-boolean-cast
  const formattedAverage = !!average ? (Math.abs(average) < 1 ? average.toFixed(4) : average.toFixed(2)) : ''
  const sortedData = orderBy(data, item => new Date(get(item, 'flight_date')), [order])
  return (
    <Column marginBottom="30px" width={columnWidth}>
      <BoxContainer height="auto">
        <TitleAndAverageWrapper>
          <HistogramChartTitle>{title}</HistogramChartTitle>
          <AverageWrapper>
            <ChartSubTitle>{subtitle}</ChartSubTitle>
            <ChartSubTitle>{formattedAverage}</ChartSubTitle>
          </AverageWrapper>
        </TitleAndAverageWrapper>
        <ResponsiveContainer width="100%" height={270}>
          <BarChart data={sortedData} margin={{ bottom: -15 }}>
            <CartesianGrid horizontal={false} vertical={false} strokeDasharray="3 3" />
            <XAxis strokeWidth="2" axisLine={false} dataKey={XLabel} tick={null} />
            <YAxis strokeWidth="2" tickFormatter={value => `${value} `} tick={{ fill: UIStyles.whiteColor, dx: -5 }} />
            <Tooltip content={<HistogramChartCustomTooltip />} />
            <Bar dataKey={YLabel} fill={UIStyles.lightBlue} onClick={onClick} isAnimationActive={false}>
              {sortedData.map((_, index) => (
                <Cell cursor="pointer" key={`cell-${index}`} />
              ))}
            </Bar>
            {average ? <ReferenceLine y={average} stroke={UIStyles.vomitLight} strokeWidth="2" strokeDasharray="5 5" /> : null}
            <ReferenceLine y={0} stroke={UIStyles.whiteColor} strokeWidth="2" />
          </BarChart>
        </ResponsiveContainer>
      </BoxContainer>
    </Column>
  )
}
