import { connect } from 'react-redux'
import { compose } from 'redux'
import { withFieldsAvailableAndSelected } from '../../components/HOCComponents/withFieldsAvailableAndSelected'
import FieldsLayer from '../../components/LayersComponents/FieldsLayer'
import { FIELD_EDIT_VIEW, FIELD_VIEW, SAMPLE_LOCATION_VIEW, TEMPORAL_COMPARE_VIEW } from '../../actions/viewmode'
import { getFieldsFilteredByView } from '../../selectors/availableFields'
import { getSelectedFlightGroupFieldsWithSeason, getGeometries, getSelectedFieldsIds } from '../../selectors/fields'
import { getConfigProjection } from '../../selectors/config'
import { getScreenView, isFieldEditView } from '../../selectors/viewmode'
import { getFilteredFieldIds } from '../../selectors/fieldsStatistics'
import { getIsGlobalLayerLegendOpen } from '../../selectors/globalLayer'

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => {
  return {
    fieldIds: getFilteredFieldIds(state),
    fields: getFieldsFilteredByView({ fieldsWithAvailableAndSelected, filterAvailableAndSelected: false })(state),
    geometries: getGeometries(state),
    selectedFieldIds: getSelectedFieldsIds(state),
    selectedFlightGroupFields: getSelectedFlightGroupFieldsWithSeason(state),
    projection: getConfigProjection(state),
    isEditView: isFieldEditView(state),
    isVisualization: [FIELD_VIEW, FIELD_EDIT_VIEW, TEMPORAL_COMPARE_VIEW, SAMPLE_LOCATION_VIEW].includes(getScreenView(state)),
    isGlobalLayerOpen: getIsGlobalLayerLegendOpen(state)
  }
}

export default compose(withFieldsAvailableAndSelected, connect(mapStateToProps))(FieldsLayer)
