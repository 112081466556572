import {
  RESET_CROP_FILTERS,
  RESET_CUSTOMER_FILTERS,
  RESET_PLANTATION_DATE_FILTERS,
  RESET_SEARCH_INPUT_FILTERS,
  ADD_CROPS_TO_FILTERS,
  ADD_CUSTOMERS_TO_FILTERS,
  SET_CROP_TO_FILTERS,
  SET_CUSTOMER_TO_FILTERS,
  SET_PLANTATION_DATE_RANGE_TO_FILTERS,
  SET_SEARCH_INPUT_TEXT_FILTERS,
  REMOVE_CROPS_FROM_FILTERS,
  RESET_ALL_FILTERS,
  SET_CUT_NUMBER_FILTERS,
  RESET_CUT_NUMBER_FILTERS,
  SET_SEASON_LABEL_FILTERS,
  RESET_SEASON_LABEL_FILTERS
} from '../actions/filters'

import { advancedSearch } from '../constants'

import union from 'lodash/union'
import isArray from 'lodash/isArray'
import without from 'lodash/without'

import { combineReducers } from 'redux'

const { PLANTATION_RANGE_FILTER, TYPE_FILTER, CUSTOMER_FILTER, CUT_NUMBER_FILTER, SEASON_LABEL_FILTER } = advancedSearch

const prepareArray = value => {
  return isArray(value) ? value : [value]
}
const cropsInitialState = []

const crops = (state = cropsInitialState, action) => {
  switch (action.type) {
    case RESET_CROP_FILTERS:
    case RESET_ALL_FILTERS:
      return cropsInitialState
    case ADD_CROPS_TO_FILTERS: {
      const cropIds = prepareArray(action.cropIds)
      return union(state, cropIds)
    }
    case REMOVE_CROPS_FROM_FILTERS: {
      const cropIds = prepareArray(action.cropIds)
      return without(state, ...cropIds)
    }
    case SET_CROP_TO_FILTERS:
      return [action.cropId]
    default:
      return state
  }
}

const customersInitialState = []

const customers = (state = customersInitialState, action) => {
  switch (action.type) {
    case RESET_CUSTOMER_FILTERS:
    case RESET_ALL_FILTERS:
      return customersInitialState

    case ADD_CUSTOMERS_TO_FILTERS: {
      const customerIds = prepareArray(action.customerIds)
      return union(state, customerIds)
    }

    case REMOVE_CROPS_FROM_FILTERS: {
      const customerIds = prepareArray(action.cropIds)
      return without(state, ...customerIds)
    }

    case SET_CUSTOMER_TO_FILTERS:
      return [action.customerId]
    default:
      return state
  }
}

const plantationDateRangeInitialState = {
  from: undefined,
  to: undefined
}

const plantationDateRange = (state = plantationDateRangeInitialState, action) => {
  switch (action.type) {
    case RESET_PLANTATION_DATE_FILTERS:
    case RESET_ALL_FILTERS:
      return plantationDateRangeInitialState

    case SET_PLANTATION_DATE_RANGE_TO_FILTERS:
      return {
        ...state,
        to: action.filter.to,
        from: action.filter.from
      }

    default:
      return state
  }
}

const searchInputInitialState = ''

const searchInput = (state = searchInputInitialState, action) => {
  switch (action.type) {
    case RESET_SEARCH_INPUT_FILTERS:
    case RESET_ALL_FILTERS:
      return searchInputInitialState

    case SET_SEARCH_INPUT_TEXT_FILTERS:
      return action.searchInputText

    default:
      return state
  }
}

const cutNumberInitialState = []

const cutNumber = (state = cutNumberInitialState, action) => {
  switch (action.type) {
    case SET_CUT_NUMBER_FILTERS:
      return [action.cutNumber]

    case RESET_CUT_NUMBER_FILTERS:
    case RESET_ALL_FILTERS:
      return cutNumberInitialState

    default:
      return state
  }
}

const seasonLabelInitialState = []

const seasonLabel = (state = seasonLabelInitialState, action) => {
  switch (action.type) {
    case SET_SEASON_LABEL_FILTERS:
      return [action.seasonLabel]

    case RESET_SEASON_LABEL_FILTERS:
    case RESET_ALL_FILTERS:
      return seasonLabelInitialState

    default:
      return state
  }
}

export default combineReducers({
  [TYPE_FILTER]: crops,
  [CUSTOMER_FILTER]: customers,
  [PLANTATION_RANGE_FILTER]: plantationDateRange,
  [CUT_NUMBER_FILTER]: cutNumber,
  [SEASON_LABEL_FILTER]: seasonLabel,
  searchInput
})
