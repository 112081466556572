import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { fetchDataStart, fetchDataSuccess, fetchDataFailure } from '../auth/authSlice'

// convert object to x-www-form-urlencoded string
const toUrlEncoded = obj =>
  Object.keys(obj)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]))
    .join('&')

export const usersApi = createApi({
  reducerPath: 'usersApi',
  baseQuery: fetchBaseQuery({ baseUrl: `${window.location.origin}/auth/access/` }),
  endpoints: build => ({
    createToken: build.mutation({
      query: credentials => ({
        url: `user/token`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: toUrlEncoded(credentials)
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'token', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'token', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'token', error }))
        }
      }
    }),
    getUserByToken: build.query({
      query: token => ({
        url: `user/me`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'user', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'user', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'user', error }))
        }
      }
    }),
    getOrganizationsForUser: build.query({
      query: token => ({
        url: `organization`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        dispatch(fetchDataStart({ key: 'organizations', error: null }))
        try {
          const { data } = await queryFulfilled
          dispatch(fetchDataSuccess({ key: 'organizations', payload: data }))
        } catch (error) {
          dispatch(fetchDataFailure({ key: 'organizations', error }))
        }
      }
    })
  })
})

export const { useCreateTokenMutation, useGetUserByTokenQuery, useGetOrganizationsForUserQuery } = usersApi
// Hook names: https://redux-toolkit.js.org/rtk-query/usage/queries#hook-types
