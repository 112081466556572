/**
 * Updates a policy object by performing the specified action on a given key-value pair.
 *
 * @param {Object} obj - The original policy object to be updated.
 * @param {string} key - The key in the policy object to be updated.
 * @param {*} value - The value to be set for the specified key.
 * @param {string} [action='update'] - The action to be performed. Can be 'update', 'delete', or 'favourites'.
 * @returns {Object} - An object containing the updated policy.
 */
export const updatePolicy = (obj, key, value, action = 'update') => {
  const body = { ...obj }

  const actions = {
    delete: () => delete body[key],
    favourites: () => {
      Array.isArray(value) && value.length > 0 ? (body[key] = value) : delete body[key]
    },
    update: () => (body[key] = value)
  }

  if (!actions[action]) {
    console.warn(`Unknown action: ${action}`)
  } else {
    actions[action]()
  }

  return { policy: body }
}

/**
 * Object containing policy mappings within accounts list requirements.
 * @type {Object.<string, string>}
 */

export const accountListPolicies = {
  '39fd7414-8175-4de4-946a-5ee578857cfb': 'default_login_account',
  'f540de47-dc0d-4867-90e5-e73c0c1bb08c': 'favourite_accounts'
}

/**
 * Finds matching policies for a given user ID within a user config policy array (accountsConfig=>user=>policy)
 *
 * @param {Object.<string, string>} accountPolicies - The mapping of policy keys to policy names.
 * @param {Array.<Object>} config - The configuration array containing user policies.
 * @param {string} userID - The user ID to search for in the configuration.
 * @returns {Array.<Object>} An array of objects containing matching policy keys and values.
 */

export const findMatchingPolicies = (accountPolicies, config, userID) => {
  const results = []

  config.forEach(item => {
    if (item.id === userID) {
      const matchingKeys = Object.keys(item.policy).filter(key => key in accountPolicies)
      matchingKeys.forEach(key => {
        results.push({
          key: accountPolicies[key],
          value: item.policy[key]
        })
      })
    }
  })

  return results
}

/**
 * Retrieves the value associated with a specific policy key from an array of policy objects.
 *
 * @param {Array.<Object>} policies - The array of policy objects.
 * @param {string} key - The key to search for in the policy objects.
 * @returns {*} The value associated with the specified key, or null if not found.
 */

export const getPolicyValue = (policies, key) => {
  const policy = policies.find(p => p.key === key)
  return policy ? policy.value : null
}
