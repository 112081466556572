import React, { useEffect } from 'react'

import StyledTextInput from './StyledTextInput'
import useFloat from '../../hooks/inputFormatters/useFloat'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const defaultInputStyle = { color: themeColors.vomitColor }

function StyledFloatInput({
  name,
  inputValue,
  label,
  errorText,
  onChange,
  onBlur,
  margin,
  fullwidth,
  maxValue,
  minValue,
  disabled,
  inputProps = { style: defaultInputStyle },
  wrapperStyle = {},
  placeholder
}) {
  // eslint-disable-next-line object-shorthand
  const { value, onChangeText } = useFloat({ value: inputValue, onChangeText: onChange, minValue: minValue, maxValue: maxValue })

  useEffect(() => {
    inputValue === undefined && onChangeText(undefined)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  return (
    <StyledTextInput
      name={name}
      value={value || ''}
      label={label}
      helperText={errorText}
      onChange={onChangeText}
      onBlur={onBlur}
      fullWidth={fullwidth}
      margin={margin}
      disabled={disabled}
      inputProps={inputProps}
      placeholder={placeholder}
      textFieldStyle={wrapperStyle}
    />
  )
}

export default StyledFloatInput
