import {
  CAPTURE_SCREENSHOT_FOR_FIELDS_REPORT_MODAL_SUCCESS,
  ADD_RECIPIENT_EMAIL_TO_FIELDS_REPORT_MODAL,
  SET_FIELDS_REPORT_MODAL_PROP,
  ADD_ERROR_TO_FIELDS_REPORT_MODAL,
  CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL,
  REMOVE_RECIPIENT_EMAIL_FROM_FIELDS_REPORT_MODAL,
  RESET_FIELDS_REPORT_MODAL,
  SET_FIELDS_REPORT_MODAL_SENDER_NAME,
  SET_FIELDS_REPORT_MODAL_SELECTED_FIELDS_IDS,
  SET_FIELDS_REPORT_MODAL_NOTES,
  SET_FIELDS_REPORT_MODAL_AFFECTED_HA,
  SET_FIELDS_REPORT_MODAL_RELATED_DATE,
  SET_FIELDS_REPORT_MODAL_INFO
} from '../actions/fieldsReportModal'
import omit from 'lodash/omit'
import map from 'lodash/map'
import without from 'lodash/without'
import union from 'lodash/union'
import toLower from 'lodash/toLower'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import isString from 'lodash/isString'
import { FIELDS_REPORT_KEYS } from '../constants'
import moment from 'moment'

const {
  BASE_64_IMAGE,
  SCREENSHOT_PATH,
  RECIPIENT_EMAIL_INPUT,
  RECIPIENT_EMAILS,
  REPORT_TYPE_ID,
  ERRORS,
  SENDER_NAME,
  SELECTED_FIELDS_IDS,
  NOTES,
  AFFECTED_HA,
  RELATED_DATE,
  REPORT_ID
} = FIELDS_REPORT_KEYS

const fieldsReportsModalInitialState = {
  [BASE_64_IMAGE]: undefined,
  [SCREENSHOT_PATH]: undefined,
  [RECIPIENT_EMAIL_INPUT]: '',
  [RECIPIENT_EMAILS]: null,
  [REPORT_TYPE_ID]: null,
  [ERRORS]: {},
  [SENDER_NAME]: '',
  [SELECTED_FIELDS_IDS]: null,
  [NOTES]: '',
  [AFFECTED_HA]: null,
  [RELATED_DATE]: moment(),
  [REPORT_ID]: null
}

export default function fieldsReportsModal(state = fieldsReportsModalInitialState, action = {}) {
  switch (action.type) {
    case CAPTURE_SCREENSHOT_FOR_FIELDS_REPORT_MODAL_SUCCESS:
      return {
        ...state,
        [BASE_64_IMAGE]: action[BASE_64_IMAGE],
        [SCREENSHOT_PATH]: fieldsReportsModalInitialState[SCREENSHOT_PATH]
      }

    case ADD_RECIPIENT_EMAIL_TO_FIELDS_REPORT_MODAL:
      return {
        ...state,
        [RECIPIENT_EMAILS]: union(state[RECIPIENT_EMAILS], [toLower(action[RECIPIENT_EMAIL_INPUT])])
      }

    case REMOVE_RECIPIENT_EMAIL_FROM_FIELDS_REPORT_MODAL: {
      const newRecipientMails = without(state[RECIPIENT_EMAILS], action.emailToRemove)

      return {
        ...state,
        [RECIPIENT_EMAILS]: isEmpty(newRecipientMails) ? fieldsReportsModalInitialState[RECIPIENT_EMAILS] : newRecipientMails
      }
    }

    case SET_FIELDS_REPORT_MODAL_PROP:
      return {
        ...state,
        [action.prop]: isString(action.value) ? trim(action.value) : action.value
      }

    case ADD_ERROR_TO_FIELDS_REPORT_MODAL: {
      return {
        ...state,
        [ERRORS]: {
          ...state[ERRORS],
          [action.pathToError]: action.error
        }
      }
    }

    case CLEAR_ERROR_FOR_FIELDS_REPORT_MODAL: {
      return {
        ...state,
        [ERRORS]: omit(state[ERRORS], action.pathToError)
      }
    }

    case SET_FIELDS_REPORT_MODAL_SENDER_NAME:
      return {
        ...state,
        [SENDER_NAME]: action[SENDER_NAME]
      }

    case SET_FIELDS_REPORT_MODAL_SELECTED_FIELDS_IDS: {
      const selectedFieldsIds = isEmpty(action[SELECTED_FIELDS_IDS]) ? fieldsReportsModalInitialState[SELECTED_FIELDS_IDS] : action[SELECTED_FIELDS_IDS]

      return {
        ...state,
        [SELECTED_FIELDS_IDS]: selectedFieldsIds
      }
    }

    case SET_FIELDS_REPORT_MODAL_NOTES:
      return {
        ...state,
        [NOTES]: action[NOTES]
      }

    case SET_FIELDS_REPORT_MODAL_INFO:
      return {
        ...state,
        [BASE_64_IMAGE]: action.info[BASE_64_IMAGE] || fieldsReportsModalInitialState[BASE_64_IMAGE],
        [SCREENSHOT_PATH]: action.info[SCREENSHOT_PATH] || fieldsReportsModalInitialState[SCREENSHOT_PATH],
        [RECIPIENT_EMAILS]: action.info.recipients || fieldsReportsModalInitialState[RECIPIENT_EMAILS],
        [REPORT_TYPE_ID]: action.info.typeId || fieldsReportsModalInitialState[REPORT_TYPE_ID],
        [ERRORS]: {},
        [SENDER_NAME]: action.info.author || fieldsReportsModalInitialState[SENDER_NAME],
        [SELECTED_FIELDS_IDS]: map(action.info.fields, 'id') || fieldsReportsModalInitialState[SELECTED_FIELDS_IDS],
        [NOTES]: action.info.notes || fieldsReportsModalInitialState[NOTES],
        [AFFECTED_HA]: action.info.affectedHa || fieldsReportsModalInitialState[AFFECTED_HA],
        [RELATED_DATE]: action.info.relatedDate || fieldsReportsModalInitialState[RELATED_DATE],
        [REPORT_ID]: action.info.id || fieldsReportsModalInitialState[REPORT_ID]
      }

    case SET_FIELDS_REPORT_MODAL_AFFECTED_HA:
      return {
        ...state,
        [AFFECTED_HA]: action[AFFECTED_HA]
      }

    case SET_FIELDS_REPORT_MODAL_RELATED_DATE:
      return {
        ...state,
        [RELATED_DATE]: action[RELATED_DATE]
      }

    case RESET_FIELDS_REPORT_MODAL:
      return fieldsReportsModalInitialState

    default:
      return state
  }
}
