import { getMessagesTranslator } from '@layers-frontend/commons/store/selectors/messages'

/** actions **/

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION'
export const RESET_NOTIFICATION = 'RESET_NOTIFICATION'

/** css classes for notifications **/

export const SUCCESS_NOTIFICATION_CLASS = 'SUCCESS_NOTIFICATION_CLASS'
export const ERROR_NOTIFICATION_CLASS = 'ERROR_NOTIFICATION_CLASS'
export const ALERT_NOTIFICATION_CLASS = 'ALERT_NOTIFICATION_CLASS'

export const WEB_TYPE = 'WEB_TYPE'
export const FIREBASE_TYPE = 'FIREBASE_TYPE'

export const addNotification = (text, className, type = WEB_TYPE, duration = 4000) => (dispatch, getState) => {
  const t = getMessagesTranslator(getState())

  dispatch({
    type: ADD_NOTIFICATION,
    payload: {
      text: t(text),
      className,
      type,
      duration
    }
  })
}

export const successNotification = text => {
  return addNotification(text, SUCCESS_NOTIFICATION_CLASS)
}

export const infoNotification = text => {
  return addNotification(text, ALERT_NOTIFICATION_CLASS)
}

export const alertNotification = text => {
  return addNotification(text, '')
}

export const errorNotification = text => {
  return addNotification(text, ERROR_NOTIFICATION_CLASS)
}

export const resetNotification = () => {
  return {
    type: RESET_NOTIFICATION
  }
}

export const firebaseNotification = text => {
  return addNotification(text, '', FIREBASE_TYPE)
}
