export const ACCOUNTS = {
  account: 'Account',
  favourite_accounts: 'Favourite accounts',
  default_account: 'Default start account'
}

export const UserConfigPolicies = {
  default_login_account: '39fd7414-8175-4de4-946a-5ee578857cfb',
  favourite_accounts: 'f540de47-dc0d-4867-90e5-e73c0c1bb08c'
}
