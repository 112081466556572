import PropTypes from 'prop-types'
import OLComponent from './ol-component'

import OlVectorSource from 'ol/source/Vector'
import OlMap from 'ol/Map'
import OlBaseLayer from 'ol/layer/Base'
import OlCollection from 'ol/Collection'

export default class SourceVector extends OLComponent {
  constructor(props) {
    super(props)
    this.source = new OlVectorSource(
      Object.assign(
        {
          features: new OlCollection()
        },
        this.props
      )
    )

    this.state = {
      triggerMapUpdate: true
    }
  }

  getChildContext() {
    return {
      source: this.source,
      map: this.context.map
    }
  }

  componentDidMount() {
    this.context.layer.setSource(this.source)
  }
}

SourceVector.propTypes = {}

SourceVector.defaultProps = {}

SourceVector.contextTypes = {
  layer: PropTypes.instanceOf(OlBaseLayer),
  map: PropTypes.instanceOf(OlMap)
}

SourceVector.childContextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
