import React from 'react'

import { SatelliteButtonContainer, DownloadGeoJsonContainer } from '../../containers/ToolsContainers/ToolsContainers'
import DownloadStatisticsButtonContainer from '../../containers/ToolsContainers/DownloadStatisticsButtonContainer'
import SendFieldsReportButtonContainer from '../../containers/ToolsContainers/SendFieldsReportButtonContainer'
import ReportManagerButtonContainer from '../../containers/ToolsContainers/ReportManagerButtonContainer'
import GlobalFilesButtonContainer from '../../containers/ToolsContainers/GlobalFilesButtonContainer'

export default function LayersLeftMenuBottom() {
  return (
    <>
      <GlobalFilesButtonContainer />
      <ReportManagerButtonContainer />
      <SendFieldsReportButtonContainer />
      <DownloadStatisticsButtonContainer />
      <DownloadGeoJsonContainer />
      <SatelliteButtonContainer />
    </>
  )
}
