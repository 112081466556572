import React from 'react'
import { withTranslator } from '../HOCComponents/withTranslator'
import StyledListButton from '../UIComponents/StyledListButton'
import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

const DownloadStatisticsButton = ({ t, openDownloadStatisticsModal }) => {
  const handleClick = () => {
    openDownloadStatisticsModal()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_VIEW_STATISTICS, {
      button: 'Statistics',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return <StyledListButton label={t('Statistics')} onClick={handleClick} labelDistance={6} icon={'icon-xls'} testId="excel-monitoring-button" />
}

export default withTranslator(DownloadStatisticsButton)
