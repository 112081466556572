import reduce from 'lodash/reduce'
import get from 'lodash/get'
import omit from 'lodash/omit'
import moment from 'moment'
import { loader } from 'graphql.macro'
import { plantsMetadataKeys } from '../constants'
import { fetchRequestWrapper, fetchGraphqlWrapper } from './ui'

const { DRY_MATTER, ATR_POL, ATR, POL } = plantsMetadataKeys

export const REQUEST_FIELD_PLANTS = 'REQUEST_FIELD_PLANTS'
export const RECEIVE_FIELD_PLANTS = 'RECEIVE_FIELD_PLANTS'

export const REQUEST_DOWNLOAD_SELECTED_FIELDS_PLANTS = 'REQUEST_DOWNLOAD_SELECTED_FIELDS_PLANTS'
export const RECEIVE_DOWNLOAD_SELECTED_FIELDS_PLANTS = 'RECEIVE_DOWNLOAD_SELECTED_FIELDS_PLANTS'

export const REQUEST_SAMPLE_IMAGE_URL = 'REQUEST_SAMPLE_IMAGE_URL'
export const RECEIVE_SAMPLE_IMAGE_URL = 'RECEIVE_SAMPLE_IMAGE_URL'
export const REQUEST_SAMPLE_PHOTO_ID = 'REQUEST_SAMPLE_PHOTO_ID'
export const RECEIVE_SAMPLE_PHOTO_ID = 'RECEIVE_SAMPLE_PHOTO_ID'

const fetchFieldPlantsSuccess = plants => {
  const plantsByTypeSampler = reduce(
    plants,
    (accumulator, plant) => {
      const fieldId = get(plant, 'field.id')
      const currentPlant = {
        ...get(plant, 'metadata'),
        ...omit(plant, ['field', 'metadata']),
        // eslint-disable-next-line object-shorthand
        fieldId: fieldId,
        formattedCreationDate: plant.sample_creation_date ? moment(plant.sample_creation_date).format('YYYYMMDD') : undefined
      }
      const currentTypeSampler = get(currentPlant, 'type_sampler')

      const hasDryMatter = get(currentPlant, DRY_MATTER)
      const hasAtrPol = get(currentPlant, ATR) && get(currentPlant, POL)
      const currentMetadataKey = hasDryMatter ? DRY_MATTER : ATR_POL

      if (!hasAtrPol && !hasDryMatter) {
        return accumulator
      }

      const previousTypeSampler = get(accumulator, currentTypeSampler)
      const previousMetadataKey = get(previousTypeSampler, currentMetadataKey)
      const previousFields = get(previousMetadataKey, fieldId)

      return !previousTypeSampler
        ? {
            ...accumulator,
            [currentTypeSampler]: {
              [currentMetadataKey]: {
                [fieldId]: [currentPlant]
              }
            }
          }
        : !previousMetadataKey
        ? {
            ...accumulator,
            [currentTypeSampler]: {
              ...previousTypeSampler,
              [currentMetadataKey]: {
                [fieldId]: [currentPlant]
              }
            }
          }
        : !previousFields
        ? {
            ...accumulator,
            [currentTypeSampler]: {
              ...previousTypeSampler,
              [currentMetadataKey]: {
                ...previousMetadataKey,
                [fieldId]: [currentPlant]
              }
            }
          }
        : {
            ...accumulator,
            [currentTypeSampler]: {
              ...previousTypeSampler,
              [currentMetadataKey]: {
                ...previousMetadataKey,
                [fieldId]: [...previousFields, currentPlant]
              }
            }
          }
    },
    {}
  )

  return {
    type: RECEIVE_FIELD_PLANTS,
    plants: plantsByTypeSampler
  }
}

export const fetchPlants = type =>
  fetchRequestWrapper({
    route: 'get_user_plants_historic',
    onSuccess: fetchFieldPlantsSuccess,
    urlParams: type ? { type } : undefined,
    requestType: REQUEST_FIELD_PLANTS
  })

const getActiveSamplePhotoByExternalIdQuery = loader('../graphql/samples/getActiveSamplePhotoByExternalId.gql').loc.source.body

export const fetchPlantImageUrl = externalId => async (dispatch, getState) => {
  const photosData = await fetchGraphqlWrapper({
    query: getActiveSamplePhotoByExternalIdQuery,
    variables: { externalId },
    requestType: REQUEST_SAMPLE_PHOTO_ID,
    onSuccess: photosData => ({ type: RECEIVE_SAMPLE_PHOTO_ID, photoId: get(photosData, 'picturePaths.0.picturePath') })
  })(dispatch, getState)

  await fetchRequestWrapper({
    route: 'get_s3_url',
    urlParams: { path: get(photosData, 'picturePaths.0.picturePath') },
    requestType: REQUEST_SAMPLE_IMAGE_URL,
    onSuccess: s3Data => ({ type: RECEIVE_SAMPLE_IMAGE_URL, url: s3Data.url })
  })(dispatch, getState)
}
