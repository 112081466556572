import React from 'react'
import map from 'lodash/map'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import withTranslator from '../HOCComponents/withTranslator'

const Key = styled.div`
  font-weight: 400;
  margin-bottom: 5px;
  color: ${props => props.color || themeColors.whiteColor};
`

const Value = styled.div`
  color: #999;
  margin-left: auto;
`
const ListItem = styled.div`
  display: flex;
  align-items: baseline;
  & > div:not(:last-child) {
    margin-right: 1rem;
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.color || themeColors.whiteColor};
  position: absolute;
  padding: 10px 15px;
  top: ${props => props.top};
  left: ${props => props.left};
  z-index: 10000;
`

const List = ({ properties, position, t }) => {
  return (
    <Wrapper left={position[1]} top={position[0]} color={themeColors.blueColorOpacity}>
      {map(properties, (value, key) => {
        return (
          <ListItem key={key}>
            <Key>{t(key)}</Key>
            <Value>{t(value)}</Value>
          </ListItem>
        )
      })}
    </Wrapper>
  )
}

export default withTranslator(List)
