import React from 'react'
import capitalize from 'lodash/capitalize'
import { Helmet } from 'react-helmet'

export default class HeadTitle extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      intervalID: null,
      loadingTitleText: ''
    }
  }

  componentDidMount() {
    this.setLoadingInterval()
  }

  componentDidUpdate(prevProps) {
    if (this.isReady) {
      this.clearInterval(this.state.intervalID)
    }
    if (prevProps.currentLocation.route !== this.props.currentLocation.route && !this.isReady) {
      this.setLoadingInterval()
    }
  }

  clearInterval(intervalID) {
    clearInterval(intervalID)
  }

  setLoadingInterval() {
    if (this.state.intervalID) {
      this.clearInterval(this.state.intervalID)
    }

    let titleIndex = 0

    this.setState({
      intervalID: setInterval(() => {
        // eslint-disable-next-line prefer-const
        let loadingTitleText = (howManyDotsToAdd => {
          let text = ''
          for (let i = 0; i < howManyDotsToAdd; i++) {
            text += '.'
          }

          return text
        })(titleIndex)

        this.setState({ loadingTitleText })

        if (titleIndex < 4) {
          titleIndex++
        } else {
          titleIndex = 0
        }
      }, 400)
    })
  }

  get isReady() {
    return this.props.currentLocation.route === 'dashboard' ? this.props.isDashboardReady && this.props.isAppReady : this.props.isAppReady
  }

  render() {
    const { userName, currentLocation, isLoading } = this.props
    const route = currentLocation.route === 'viewer' ? 'layers' : currentLocation.route
    const formattedRoute = `${capitalize(route)} `
    const formattedUserName = userName ? `/ ${userName.toUpperCase()} ` : ''

    return (
      <Helmet>
        <title>
          {formattedRoute}
          {formattedUserName}
          {isLoading ? this.state.loadingTitleText : ''}
        </title>
      </Helmet>
    )
  }
}
