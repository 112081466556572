import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import moment from 'moment'

const WarningText = styled.p`
  color: ${UIStyles.whiteColor};
  font-size: 18px;
  text-align: center;
  width: 100%;
  margin: 25px;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
`

const StyledButton = styled.button`
  background-color: ${props => props.backgroundColor || 'transparent'};
  padding: 6px 12px;
  color: ${props => props.textColor};
  border: none;
  border-radius: 6px;
  margin: 0 40px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 0px 7px 4px rgba(198, 195, 12, 0.16);
    transform: translateY(2px) translateX(1px);
  }
`

export default function ExtraStatisticsFetchConfirmationModal({ t, closeModal, from, to, selectedFieldsIds, fetchTemporalEvolutionStatistics }) {
  const formattedFrom = moment(from).format('YYYY-MM-DD')
  const formattedTo = moment(to).format('YYYY-MM-DD')

  const confirmHandler = () => {
    closeModal()
    fetchTemporalEvolutionStatistics({ fieldIds: selectedFieldsIds, from: formattedFrom, to: formattedTo })
  }

  return (
    <Wrapper>
      <WarningText>{t('current selection could take a moment to process')}</WarningText>
      <StyledButton backgroundColor={UIStyles.niceRedColor} textColor={UIStyles.darkBlue} onClick={closeModal}>
        {t('edit range')}
      </StyledButton>
      <StyledButton backgroundColor={UIStyles.vomitColor} textColor={UIStyles.darkBlue} onClick={confirmHandler}>
        {t('confirm')}
      </StyledButton>
    </Wrapper>
  )
}
