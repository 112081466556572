import moment from 'moment'

export const MONTH = 'YYYY-MM'
export const YEAR = 'YYYY'
export const DATE = 'YYYY-MM-DD'

export const monthDates = date => {
  const from = moment(date.format('YYYY-MM-01'))
  const to = moment(date.format('YYYY-MM-') + date.daysInMonth())
  const yearMonth = date.format(MONTH)
  return { from, to, yearMonth }
}

export const formatDate = date => date.format(DATE)
