import React from 'react'
import UserMenuContainer from '../../containers/UserContainers/UserMenuContainer'
import Avatar from './avatar'

export default class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isToggleOn: false
    }
  }

  toggleMenu = () => {
    this.setState({
      isToggleOn: !this.state.isToggleOn
    })
  }

  closeMenu = () => {
    this.setState({
      isToggleOn: false
    })
  }

  render() {
    const { user, hasRoleIrriga } = this.props
    const shouldShowUserMenu = !hasRoleIrriga && this.state.isToggleOn

    return (
      <div>
        <Avatar handleToggleMenu={this.toggleMenu} user={user} hasRoleIrriga={hasRoleIrriga} />

        {shouldShowUserMenu && <UserMenuContainer onMenuClose={this.closeMenu} />}
      </div>
    )
  }
}
