import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import SwipeableViews from 'react-swipeable-views'
import FieldDetailsAndTimeline from './FieldDetailsAndTimeline'
import FieldSeasonFormFooter from './FieldSeasonFormFooter'
import FieldSeasonFormContent from './FieldSeasonFormContent'
import FieldSeasonFormBasic from './FieldSeasonFormBasic'
import FieldSeasonFormAdvanced from './FieldSeasonFormAdvanced'
import styled from 'styled-components'

import includes from 'lodash/includes'
import curry from 'lodash/curry'
import isEmpty from 'lodash/isEmpty'
import some from 'lodash/some'
import get from 'lodash/get'
import keys from 'lodash/keys'
import map from 'lodash/map'
import Loader from './Loader'
import Header from './Header'
import NullComponent from '@layers-frontend/commons/helpers/NullComponent'
import { reduxFieldToFormHook, reduxSeasonToFormHook } from '../../../seasonFormUtils'
import { FIELD_SEASON_FORM_KEYS as KEYS, BASIC_FIELD_SEASON_FORM_KEYS as BASIC_KEYS } from '../../../constants'
import isString from 'lodash/isString'
import FieldSeasonFormDeletingConfirmationModal from './FieldSeasonFormDeletingConfirmationModal'

const Wrapper = styled.div`
  position: relative;
`
const Form = styled.form`
  opacity: ${props => (props.isLoading ? 0 : 1)};
  transition: opacity 1s ease-in-out;
  margin-bottom: 0;
`

export default function FieldSeasonFormModal({
  t,
  activeSeasonIndex,
  customers,
  fetchFirstSelectedFieldSeasons,
  fetchPreviewFieldSeasons,
  fieldTypes,
  seasons,
  selectedField,
  previewField,
  setActiveSeasonIndex,
  isNewField,
  setEditorGeometry,
  setEditorFeaturesAndSurface,
  toggleFieldSeasonFormEditor,
  resetEditingFeaturesAndSurface,
  openEditModeWithCurrentGeometry,
  editingGeometry,
  createNewFieldSeasons,
  postFieldWithSeasons,
  patchFieldWithSeason,
  closeModal,
  hasRoleDemo,
  onDeleteField,
  isReadOnly
}) {
  const [isSeasonLoading, setIsSeasonLoading] = useState(true)
  const [isOpenDeletingModal, setIsOpenDeletingModal] = useState(false)
  const isShowDeleteButton = !hasRoleDemo && !isNewField

  const closeDeletingModal = () => setIsOpenDeletingModal(false)
  const handleDeleteField = () => {
    closeDeletingModal()
    onDeleteField(selectedField.id)
  }

  const methods = useForm({
    shouldFocusError: false,
    defaultValues: {
      ...reduxFieldToFormHook(isNewField ? {} : isReadOnly ? previewField : selectedField),
      [KEYS.SEASONS]: []
    }
  })
  methods.register(KEYS.COMMENT)

  useEffect(() => {
    if (isReadOnly) {
      fetchPreviewFieldSeasons().then(() => {
        setIsSeasonLoading(false)
      })
      return
    }
    if (!isNewField) {
      fetchFirstSelectedFieldSeasons().then(() => {
        setIsSeasonLoading(false)
      })
      return
    }
    createNewFieldSeasons()
    setIsSeasonLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const isSeasonModified = !!get(methods.formState, ['dirtyFields', KEYS.SEASONS, activeSeasonIndex])
    const isGeometryModified = !!methods.getValues(`${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.FEATURES}`)

    if (!isEmpty(seasons) && !isSeasonModified && !isGeometryModified) {
      const selectedSeason = get(seasons, activeSeasonIndex)
      const formSeason = reduxSeasonToFormHook(selectedSeason)

      methods.setValue(`${KEYS.SEASONS}.${activeSeasonIndex}`, formSeason)
      const seasonGeometry = get(selectedSeason, 'geometry')

      if (seasonGeometry) {
        setEditorGeometry(seasonGeometry)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons, activeSeasonIndex])

  useEffect(() => {
    if (isString(editingGeometry.features)) {
      methods.setValue(`${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.FEATURES}`, editingGeometry.features)
      methods.setValue(`${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.SURFACE}`, editingGeometry.surface)
      resetEditingFeaturesAndSurface()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingGeometry])

  const onSeasonSelect = seasonIndex => {
    setActiveSeasonIndex(seasonIndex)
    const selectedSeason = methods.getValues(`${KEYS.SEASONS}.${seasonIndex}`)
    const features = get(selectedSeason, KEYS.FEATURES)
    if (isString(features)) {
      const surface = get(selectedSeason, KEYS.SURFACE)
      const parsedFeatures = JSON.parse(features)

      return setEditorFeaturesAndSurface({ features: parsedFeatures, surface })
    }
  }

  const detailsAndTimelineProps = {
    seasons,
    activeSeasonIndex,
    onSeasonSelect,
    customers,
    isReadOnly
  }

  const seasonProps = {
    activeSeasonIndex,
    fieldTypes,
    openEditModeWithCurrentGeometry,
    toggleFieldSeasonFormEditor,
    setIsOpenDeletingModal,
    isShowDeleteButton,
    isReadOnly
  }

  const headerProps = {
    seasons,
    activeSeasonIndex,
    selectedField,
    previewField,
    isNewField,
    closeModal,
    isReadOnly
  }

  const currentSeasonErrorKeys = keys(methods.formState.errors?.[KEYS.SEASONS]?.[activeSeasonIndex])
  const currentSeasonHasBasicError = some(currentSeasonErrorKeys, curry(includes)(BASIC_KEYS))

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Header {...headerProps} />
        <Loader isLoading={isSeasonLoading} />
        <Form isLoading={isSeasonLoading} data-testid="field-season-form">
          <FieldDetailsAndTimeline {...detailsAndTimelineProps} />
          <SwipeableViews index={activeSeasonIndex} axis="x-reverse">
            {map(seasons, (_, index) => {
              if (index !== activeSeasonIndex) {
                return <NullComponent key={index} />
              }
              return (
                <FieldSeasonFormContent
                  key={index}
                  tabs={[
                    {
                      label: t('Basic'),
                      tooltipText: t('basic season parameters'),
                      content: <FieldSeasonFormBasic {...seasonProps} />,
                      hasError: currentSeasonHasBasicError
                    },
                    {
                      label: t('Advanced'),
                      tooltipText: t('advanced season parameters'),
                      content: <FieldSeasonFormAdvanced {...seasonProps} />,
                      hasError: false
                    }
                  ]}
                />
              )
            })}
          </SwipeableViews>
          {!isReadOnly ? (
            <FieldSeasonFormFooter closeModal={closeModal} hasRoleDemo={hasRoleDemo} onConfirm={isNewField ? postFieldWithSeasons : patchFieldWithSeason} />
          ) : null}
          {isOpenDeletingModal && (
            <FieldSeasonFormDeletingConfirmationModal
              t={t}
              closeDeletingModal={closeDeletingModal}
              onDelete={handleDeleteField}
              selectedFieldName={selectedField.name}
            />
          )}
        </Form>
      </FormProvider>
    </Wrapper>
  )
}
