import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import get from 'lodash/get'
import find from 'lodash/find'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import isEmpty from 'lodash/isEmpty'
import withTranslator from '../../HOCComponents/withTranslator'
import CloseModalButton from '../../UIComponents/CloseModalButton'

const Title = styled.div`
  font-size: 1.6rem;
  line-height: 1;
  color: ${themeColors.whiteColor};
  font-weight: 400;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &::first-letter {
    text-transform: uppercase;
  }
`

const Wrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${themeColors.whiteColor};
`

const generateFieldTitle = (activeSeason, selectedField) => {
  const externalId = get(selectedField, 'externalReferenceId')
  const parameters = get(activeSeason, 'parameters')
  const labelParameter = find(parameters, parameter => get(parameter, ['parameter', 'id']) === 65)
  const label = get(labelParameter, 'value')
  const fieldName = get(selectedField, 'name')
  let title = fieldName || ''
  const separator = ' | '
  title = label ? `${title}${separator}${label}` : title
  title = externalId ? `${title}${separator}${externalId}` : title

  return title
}
export default withTranslator(({ t, seasons, activeSeasonIndex, selectedField, isNewField, closeModal, isReadOnly, previewField }) => {
  const preTitle = isReadOnly ? '' : isNewField ? t('Create New Field') : `${t('edit')}:`
  const [title, setTitle] = useState(preTitle)

  useEffect(() => {
    if (!isEmpty(get(seasons, activeSeasonIndex))) {
      const season = get(seasons, activeSeasonIndex)
      const fieldTitle = isNewField ? '' : generateFieldTitle(season, isReadOnly ? previewField : selectedField)
      setTitle(`${preTitle} ${fieldTitle}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seasons, activeSeasonIndex, selectedField])

  return (
    <Wrapper>
      <Title>
        {isReadOnly ? <i className="fa-solid fa-circle-info" /> : null}
        {title}
      </Title>
      <CloseModalButton onClick={closeModal} />
    </Wrapper>
  )
})
