import { connect } from 'react-redux'

import { setSearchInputTextFilters, resetSearchInputFilters } from '../../actions/filters'
import { toggleFocusSearchBar, openSearchFilters } from '../../actions/search'
import { isSearchFocus } from '../../selectors/search'
import { areRequestsLoading } from '../../selectors/ui'
import { REQUEST_FILTERED_FIELDS } from '../../actions/fields'

export const connectSearch = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      isFocus: isSearchFocus(state),
      disabled: areRequestsLoading(state, [REQUEST_FILTERED_FIELDS])
    }
  }

  const mapDispatchToProps = {
    setSearchInputTextFilters,
    resetSearchInputFilters,
    toggleFocusSearchBar,
    openSearchFilters
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
