import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setLocale, seti18n } from '../../actions/locale'
import { getLocale } from '../../selectors/i18n'
import { getConfigAvailableLocales } from '../../selectors/config'

export const connectLocale = WrappedComponent => {
  const mapStateToProps = state => ({
    locale: getLocale(state),
    availableLocales: getConfigAvailableLocales(state)
  })

  const mapDispatchToProps = dispatch => {
    // eslint-disable-next-line object-shorthand
    return bindActionCreators({ setLocale: setLocale, seti18n: seti18n }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
