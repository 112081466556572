import { compose } from 'redux'
import { connect } from 'react-redux'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'
import { getUser, hasRoleIrriga } from '@layers-frontend/commons/store/selectors/user'
import Profile from '../../components/UserComponents/Profile'

const mapStateToProps = state => {
  return {
    user: getUser(state),
    hasRoleIrriga: hasRoleIrriga(state)
  }
}

export default compose(withCurrentRoute, connect(mapStateToProps))(Profile)
