/**
 * Retrieves the range of values for a given class based on its description.
 * The class can define a range using different formats, such as "< x", "x - y", or "> x". The function uses the provided
 * minimum and maximum intervals to determine the range.
 *
 * @param {Object} cls - The class object containing the class definition.
 * @param {string} cls.class - The string representation of the class range, which can be in the formats:
 * "< x", "x - y", ">= x", or "> x".
 * @param {string} cls.color - The color associated with the class.
 * @param {number} cls.value - The numerical value representing the class.
 * @param {number} cls.fraction - The fraction associated with the class.
 * @param {number} cls.surface - The surface area or value associated with the class.
 * @param {number} minInterval - The minimum interval value to consider.
 * @param {number} maxInterval - The maximum interval value to consider.
 * @returns {number[]} An array containing the start and end of the range.
 */

export const getClassRange = (cls, minInterval, maxInterval) => {
  let range = [minInterval, maxInterval]

  if (cls.class.includes('< -')) {
    range[1] = Number(cls.class.replace('< ', ''))
  } else if (cls.class.includes('-')) {
    range = cls.class.split(' - ').map(Number)
  } else if (cls.class.includes('<')) {
    range[1] = Number(cls.class.replace('< ', ''))
  } else if (cls.class.includes('>')) {
    range[0] = Number(cls.class.replace('> ', ''))
  }
  if (isNaN(range[0]) || isNaN(range[1])) {
    return [minInterval, maxInterval]
  }

  return range
}

/**
 * The threshold value used to determine the minimum spacing between tick marks.
 */
export const threshold = 0.01

/**
 * Filters an array of tick positions to remove overlapping ticks based on a specified threshold.
 *
 * @param {number[]} ticks - An array of tick positions to filter.
 * @param {number} threshold - The minimum spacing between ticks. Ticks within this distance from the last accepted tick will be excluded.
 * @returns {number[]} An array of filtered tick positions that are spaced apart by at least the specified threshold.
 */
export const filterOverlappingTicks = (ticks, threshold) => {
  const filteredTicks = []
  let lastTick = null

  ticks.forEach(tick => {
    if (lastTick === null || tick - lastTick > threshold) {
      filteredTicks.push(tick)
      lastTick = tick
    }
  })

  return filteredTicks
}
