import React from 'react'
import withTranslator from '../../HOCComponents/withTranslator'
import { useForm, Controller } from 'react-hook-form'
import { NOT_REQUIRED_FORM_VALIDATION_RULE, REQUIRED_FORM_VALIDATION_RULE } from '../../../constants'
import StyledAutoComplete from '../../UIComponents/StyledAutocomplete'
import { defaultValuesSampleFormFields, sampleFormFields } from '@layers-frontend/commons/constants'
import styled from 'styled-components'
import StyledDatePicker from '../../UIComponents/Calendars/StyledDatePicker'
import StyledTextArea from '../../UIComponents/StyledTextArea'
import { Button } from '@mui/material'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import isEmpty from 'lodash/isEmpty'
import capitalize from 'lodash/capitalize'
import map from 'lodash/map'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import SampleImageUpload from './SampleImageUpload'
import StyledTextInput from '../../UIComponents/StyledTextInput'
import { UIStyles } from '../../../theme'
import { Button as StyledButton } from '../../UIComponents/StyledButtons'
import toLower from 'lodash/toLower'
import StyledSelect from '../../UIComponents/StyledSelect'
import keys from 'lodash/keys'
import toNumber from 'lodash/toNumber'
import { sortedParameters } from './ExtraParameters/utils'

const StyledIcon = styled.i`
  color: ${UIStyles.redColor};
  font-size: 1.5rem;
  margin-bottom: 15px;
  cursor: pointer;
  transition: 200ms;

  &:hover {
    opacity: 0.8;
  }
`

const { NOTES, SAMPLE_CREATION_DATE, LOCATION, TYPE, BASE_LAYER, PICTURE_TEMP_PATH } = sampleFormFields

const Wrapper = styled.div``

const InputWrapper = styled.div`
  flex: 1;
  margin: 0 20px;
`

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonWrapper = styled(Row)`
  margin: 10px 20px;
  align-items: flex-start;
`

const ButtonText = styled.p`
  border-bottom: 1px solid #fff;
  color: ${themeColors.whiteColor};
  font-size: 13px;
  margin-bottom: 0;
  text-transform: none;
`

const style = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  padding: '5px',
  position: 'relative'
}

const iconStyle = {
  fontSize: 20,
  color: themeColors.whiteColor,
  margin: 0
}

const selectWrapperStyle = {
  margin: '5px 0'
}

const Badge = styled.span`
  position: absolute;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 50%;
  background-color: ${props => (props.hasValue ? themeColors.lightgreen : themeColors.redColor)};
  top: -0.6rem;
  left: 30px;
  z-index: 1;
`

export const StyledSampleIconButton = ({ title, icon, onClick, hasValue, children = null, errorText }) => (
  <Button variant="text" style={style} startIcon={<StyledIcon style={iconStyle} className={icon} />} onClick={onClick}>
    {(errorText || hasValue) && <Badge hasValue={hasValue} />}
    <ButtonText>{capitalize(title)}</ButtonText>
    {children}
  </Button>
)

const ModifiedController = ({ name, control, rules, setFormField, component }) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ field: { onChange, value }, fieldState: { error } }) => {
      const onChangeCombined = value => {
        onChange(value)
        setFormField(name, value)
      }
      return component({ onChange: onChangeCombined, value, name, errorText: error?.type })
    }}
  />
)

const extraParametersFormatter = {
  float: { type: 'number', formatter: parseFloat },
  int: { type: 'number', formatter: parseInt },
  date: { type: 'date', formatter: val => val },
  string: { type: 'test', formatter: val => val }
}

const SampleForm = ({
  t,
  type,
  notes,
  location,
  baseLayer,
  sampleCreationDate,
  picturePath,
  setFormField,
  extraParameters,
  sampleTypes,
  selectedFlightGroupDeliverables,
  sampleExtraParameters,
  setSampleFormExtraParameters,
  setSampleFormExtraParamValueById,
  removeSampleFormExtraParam,
  openSampleLocationMode,
  setSampleFormType,
  getDeliverablesTypesNameById,
  resetAndCloseSampleForm,
  getSampleFormExtraParameterById,
  postPatchSample,
  getS3Url,
  isNewSampleMode,
  resetSampleFormPicturePath
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: {
      [TYPE]: type,
      [SAMPLE_CREATION_DATE]: sampleCreationDate,
      [NOTES]: notes,
      [BASE_LAYER]: baseLayer,
      [LOCATION]: location,
      [PICTURE_TEMP_PATH]: picturePath,
      ...extraParameters
    }
  })

  const removeTextInput = id => ({
    endAdornment: <StyledIcon className={'fas fa-minus-circle'} onClick={() => removeSampleFormExtraParam(id)} />
  })

  const handleResetCancel = () => {
    resetAndCloseSampleForm()
    reset(defaultValuesSampleFormFields)
  }

  const onSubmit = data => {
    if (isEmpty(errors)) {
      postPatchSample(data)
      handleResetCancel()
    }
  }

  const baseLayersOptions = map(selectedFlightGroupDeliverables, deliverable => ({
    id: deliverable.type.id,
    name: t(toLower(getDeliverablesTypesNameById(deliverable.type.id)))
  }))

  const sortedSampleExtraParameters = sortedParameters(sampleExtraParameters, t)

  return (
    <Wrapper>
      <Row>
        <InputWrapper>
          <ModifiedController
            name={TYPE}
            control={control}
            rules={REQUIRED_FORM_VALIDATION_RULE}
            setFormField={setSampleFormType}
            component={props => <StyledAutoComplete disabled={!isNewSampleMode} label={'sample type'} options={sampleTypes} {...props} />}
          />
        </InputWrapper>
        <InputWrapper>
          <ModifiedController
            name={SAMPLE_CREATION_DATE}
            control={control}
            rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
            setFormField={setFormField}
            component={props => <StyledDatePicker label={'sample_creation_date'} {...props} customStyleMargin="16px 0px 8px" />}
          />
        </InputWrapper>
      </Row>
      <InputWrapper>
        <ModifiedController
          name={NOTES}
          control={control}
          rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
          setFormField={setFormField}
          component={props => <StyledTextArea label={NOTES} {...props} />}
        />
      </InputWrapper>
      <InputWrapper>
        <ModifiedController
          name={BASE_LAYER}
          control={control}
          rules={REQUIRED_FORM_VALIDATION_RULE}
          setFormField={setFormField}
          component={props => (
            <StyledAutoComplete
              label={BASE_LAYER}
              options={baseLayersOptions}
              disabled={!isNewSampleMode}
              placeholder={t(toLower(getDeliverablesTypesNameById(baseLayer)))}
              {...props}
            />
          )}
        />
      </InputWrapper>
      <ButtonWrapper>
        <ModifiedController
          name={LOCATION}
          control={control}
          rules={REQUIRED_FORM_VALIDATION_RULE}
          component={({ errorText }) => (
            <StyledSampleIconButton
              hasValue={!isEmpty(location)}
              title={t('add location')}
              icon={'fas fa-map-marker-alt'}
              onClick={openSampleLocationMode}
              errorText={errorText}
            />
          )}
        />
        <ModifiedController
          name={PICTURE_TEMP_PATH}
          control={control}
          rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
          setFormField={setFormField}
          component={props => (
            <SampleImageUpload
              resetSampleFormPicturePath={resetSampleFormPicturePath}
              isNewSampleMode={isNewSampleMode}
              getS3Url={getS3Url}
              picturePath={picturePath}
              {...props}
            />
          )}
        />
      </ButtonWrapper>
      <InputWrapper>
        <StyledSelect
          disabled={isNil(type)}
          wrapperStyle={selectWrapperStyle}
          onChange={setSampleFormExtraParameters}
          options={sortedSampleExtraParameters}
          value={map(keys(extraParameters), item => toNumber(item))}
          multiple={true}
          renderValue={selectedIds => {
            return selectedIds.length
              ? selectedIds.length + ' ' + (selectedIds.length === 1 ? t('singular selected') : t('plural selected'))
              : capitalize(t('extra parameters'))
          }}
        />
      </InputWrapper>
      {!isEmpty(extraParameters) &&
        map(extraParameters, (_, id) => (
          <InputWrapper key={id}>
            <Controller
              name={id}
              control={control}
              rules={REQUIRED_FORM_VALIDATION_RULE}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const param = getSampleFormExtraParameterById(id)
                const units = get(param, 'measureUnit.shortName')
                const label = `${t(get(param, 'name'))}${units ? `, ${units}` : ''}`
                const formatter = extraParametersFormatter[get(param, 'format.name')]
                let inputProps = removeTextInput(id)

                const onChangeCombined = value => {
                  onChange(value)
                  setSampleFormExtraParamValueById(id, get(formatter, 'formatter')(value))
                }

                if (get(param, 'format.name') === 'float') {
                  inputProps = { ...inputProps, step: 'any' }
                }

                return (
                  <StyledTextInput
                    value={value}
                    type={get(formatter, 'type')}
                    label={label}
                    helperText={error?.type}
                    onChange={onChangeCombined}
                    InputLabelProps={{ shrink: true }}
                    InputProps={inputProps}
                  />
                )
              }}
            />
          </InputWrapper>
        ))}
      <ButtonWrapper>
        <StyledButton label={t('cancel')} onClick={handleResetCancel} background={UIStyles.redColor} />
        <StyledButton label={t('confirm')} onClick={handleSubmit(onSubmit)} />
      </ButtonWrapper>
    </Wrapper>
  )
}

export default withTranslator(SampleForm)
