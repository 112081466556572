import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { getMeteorologicalStatisticsSelectedFieldId } from './dashboard'

export const getMeteorologicalData = get('meteorologicalData')

export const getMeteorologicalDataByFieldId = fieldId => pipe(getMeteorologicalData, get(fieldId))

export const getSelectedFieldMeteorologicalData = state => {
  const selectedFieldId = getMeteorologicalStatisticsSelectedFieldId(state)
  const meteorologicalData = getMeteorologicalData(state)

  return get(selectedFieldId)(meteorologicalData)
}
