import React from 'react'
import SendFieldsReportButtonContainer from '../../containers/ToolsContainers/SendFieldsReportButtonContainer'
import ReportManagerButtonContainer from '../../containers/ToolsContainers/ReportManagerButtonContainer'

export default function DashboardLeftMenuBottom() {
  return (
    <>
      <ReportManagerButtonContainer />
      <SendFieldsReportButtonContainer />
    </>
  )
}
