import React, { useEffect } from 'react'
import StyledSelect from '../../UIComponents/StyledSelect'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import first from 'lodash/first'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import size from 'lodash/size'

function FieldsSelector({ t, appSelectedFields, selectedIds, setSelectedFieldsIds, error }) {
  useEffect(() => {
    if (isEmpty(selectedIds) && get(appSelectedFields, 'length') === 1) {
      setSelectedFieldsIds([get(first(appSelectedFields), 'id')])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isMultiSelectDisabled = size(appSelectedFields) === 1

  return (
    <ToolWrapper>
      <ToolTitle>{t('Reported fields')} *</ToolTitle>
      <StyledSelect
        errorText={t(error)}
        disabled={isMultiSelectDisabled}
        onChange={setSelectedFieldsIds}
        options={appSelectedFields}
        placeholder={t('Select')}
        value={selectedIds}
        multiple={true}
        renderValue={selectedIds =>
          selectedIds.length ? selectedIds.length + ' ' + (selectedIds.length === 1 ? t('singular selected') : t('plural selected')) : t('Select')
        }
      />
    </ToolWrapper>
  )
}

export default withTranslator(FieldsSelector)
