import { withCurrentRoute } from './withCurrentRoute'
import { compose } from 'redux'
import { connect } from 'react-redux'

import { getAvailableFieldsByView, getFieldsBySearchBarListMode } from '../../selectors/availableFields'
import { getSelectedFieldsIds } from '../../selectors/fields'
import { getFilteredFieldIds, getGlobalMapFeatures, getIsGlobalLayerLegendOpen } from '../../selectors/globalLayer'

export function withFieldsAvailableAndSelected(WrappedComponent) {
  const mapStateToProps = (state, propsAddedByWithCurrentRoute) => {
    const unfilteredAvailableFieldsIds = getAvailableFieldsByView(propsAddedByWithCurrentRoute.currentLocation.view)(state) || []
    const selectedFieldsIds = getSelectedFieldsIds(state)
    const filteredFieldIdsFromGlobalLayer = getFilteredFieldIds(state)
    const isGlobalLayerLegendOpen = getIsGlobalLayerLegendOpen(state)

    const globalLayerFeatures = getGlobalMapFeatures(state)
    const totalGlobalLayerFeatures = globalLayerFeatures?.length
    const availableFieldsIds =
      filteredFieldIdsFromGlobalLayer.length === totalGlobalLayerFeatures
        ? unfilteredAvailableFieldsIds
        : unfilteredAvailableFieldsIds.filter(fieldId => filteredFieldIdsFromGlobalLayer.includes(+fieldId))

    return {
      fieldsWithAvailableAndSelected: getFieldsBySearchBarListMode({
        availableFieldsIds: isGlobalLayerLegendOpen ? availableFieldsIds : unfilteredAvailableFieldsIds,
        selectedFieldsIds
      })(state)
    }
  }

  return compose(withCurrentRoute, connect(mapStateToProps))(WrappedComponent)
}
