import React, { useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import SwipeableViews from 'react-swipeable-views'
import styled from 'styled-components'

const Wrapper = styled.div`
  .tabs {
    & > div {
      padding-right: 1rem;
    }
  }
`

const TabContentWrapper = styled.div`
  padding: 1rem;
`

const muiStyles = {
  buttonStyle: {
    height: '7rem'
  }
}

const GlobalFilesTabs = ({ tabs = [] }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const handleChange = (_, index) => setActiveTabIndex(index)

  return (
    <Wrapper>
      <Tabs onChange={handleChange} value={activeTabIndex} className="tabs" textColor="inherit">
        {tabs.map(({ label }, tabIndex) => (
          <Tab key={tabIndex} value={tabIndex} sx={muiStyles.buttonStyle} label={label} />
        ))}
      </Tabs>
      <SwipeableViews index={activeTabIndex} onChangeIndex={handleChange}>
        {tabs.map(({ content }, tabIndex) => (
          <TabContentWrapper key={tabIndex}>{content}</TabContentWrapper>
        ))}
      </SwipeableViews>
    </Wrapper>
  )
}

export default GlobalFilesTabs
