import PropTypes from 'prop-types'
import OLComponent from '../ol-component'

import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'
import { unByKey } from 'ol/Observable'

export default class OLInteraction extends OLComponent {
  constructor(props, context) {
    super(props, context)
    this.interaction = this.createInteraction(props, context)
    this.eventHandlerKeys_ = {}
  }

  componentDidMount() {
    this.updateActiveState_(this.props)
    this.updateEventHandlersFromProps_(this.props)
    this.context.map.addInteraction(this.interaction)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateActiveState_(newProps)
    this.updateEventHandlersFromProps_(newProps, this.props)
  }

  componentWillUnmount() {
    this.context.map.removeInteraction(this.interaction)

    this.updateEventHandlersFromProps_({})
  }

  createInteraction(props) {
    throw new TypeError('You must override createInteraction() in classes derived from OLInteraction')
  }

  updateActiveState_(props) {
    // eslint-disable-next-line no-prototype-builtins
    if (props.hasOwnProperty('active')) {
      this.interaction.setActive(props.active)
    } else {
      this.interaction.setActive(true)
    }
  }

  updateEventHandler_(name, handler) {
    const key = this.eventHandlerKeys_[name]
    if (key) {
      unByKey(key)
      delete this.eventHandlerKeys_[name]
    }

    if (handler) {
      if (name === 'boxend') {
        this.eventHandlerKeys_[name] = this.interaction.on(name, () => {
          handler(this.interaction.getGeometry().getExtent())
        })
      } else {
        this.eventHandlerKeys_[name] = this.interaction.on(name, handler)
      }
    }
  }

  updateEventHandlersFromProps_(props, oldProps) {
    const events = this.constructor.olEvents || []
    // eslint-disable-next-line prefer-const
    for (let prop of events) {
      const handler = props[prop]
      const oldHandler = oldProps ? oldProps[prop] : undefined
      if (oldHandler !== handler) {
        this.updateEventHandler_(prop, handler)
      }
    }
  }
}

OLInteraction.propTypes = {
  active: PropTypes.bool.isRequired
}

OLInteraction.defaultProps = {
  active: true
}

OLInteraction.contextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}
