import React, { useEffect } from 'react'
import { DASHBOARD_FILTERS_TYPES } from '../../../actions/dashboard'
import some from 'lodash/some'
import StyledSelect from '../../UIComponents/StyledSelect'

const { METEOROLOGICAL_STATISTICS_FIELDS_SELECT } = DASHBOARD_FILTERS_TYPES

const wrapperStyle = {
  width: '270px',
  marginTop: '15.5px',
  flexWrap: 'wrap'
}

export default function MeteorologicalStatistisFieldSelect({
  t,
  toggleDashFilter,
  meteorologicalStatisticsSelectedFieldId,
  isFetchingMeteorilogicalData,
  isSelectedFieldsEmpty,
  fieldsOptions,
  setToggled
}) {
  const onMeteorologicalStatisticsFieldsSelectChange = fieldId => {
    setToggled(false)
    toggleDashFilter(METEOROLOGICAL_STATISTICS_FIELDS_SELECT, fieldId)
  }

  const resetFieldSelection = () => onMeteorologicalStatisticsFieldsSelectChange('')

  const isSelectedFieldWithinOptions = some(fieldsOptions, field => field.id === meteorologicalStatisticsSelectedFieldId)

  if (meteorologicalStatisticsSelectedFieldId && !isSelectedFieldWithinOptions) {
    resetFieldSelection()
  }

  useEffect(() => {
    return resetFieldSelection()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateEmptyNames = options => {
    for (const field of options) {
      if (field.name === '') {
        field.name = t('unnamed field')
      }
    }
    return options
  }

  return (
    !isSelectedFieldsEmpty && (
      <StyledSelect
        disabled={isFetchingMeteorilogicalData || isSelectedFieldsEmpty}
        fullWidth={false}
        onChange={onMeteorologicalStatisticsFieldsSelectChange}
        options={updateEmptyNames(fieldsOptions)}
        placeholder={t('fields')}
        value={meteorologicalStatisticsSelectedFieldId}
        wrapperStyle={wrapperStyle}
      />
    )
  )
}
