import React, { useMemo } from 'react'
import { AnalyticsBrowser } from '@segment/analytics-next'
import { AnalyticsContext } from './context'

export const AnalyticsProvider = ({ children }) => {
  const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY

  const value = useMemo(() => ({ analytics: AnalyticsBrowser.load({ writeKey, trackAppLifecycleEvents: true, collectDeviceId: true }) }), [writeKey])

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>
}
