import React from 'react'
import { DISTANCE_TOOL, AREA_TOOL } from '../../actions/editor'
import { withTranslator } from '../HOCComponents/withTranslator'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

function Measuresbar({ t, activeTool, isMeasureToolsActive, selectTool, toggleMeasureTool }) {
  const MeasureToolButton = (
    <div className={'openToolButton MeasureToolButton ' + (isMeasureToolsActive ? 'closeTool' : '')} onClick={toggleMeasureTool}>
      <StyledTooltip title={isMeasureToolsActive ? t('Hide Measure Tools') : t('Show Measure Tools')} placement="top-end">
        {isMeasureToolsActive ? <i className="ion-eye-disabled" /> : <i className="ion-wrench" data-testid="tool-button" />}
      </StyledTooltip>
    </div>
  )

  return (
    <div className={'toolbar measuretoolbar ' + (isMeasureToolsActive ? 'showed ' : '')}>
      <div
        className={'tools ' + (activeTool === DISTANCE_TOOL ? 'selected' : '')}
        onClick={() => {
          selectTool(DISTANCE_TOOL)
        }}
      >
        <StyledTooltip title={t('Ruler')} placement="top-end">
          <i className="icon-ruler" />
        </StyledTooltip>
      </div>
      <div
        className={'tools ' + (activeTool === AREA_TOOL ? 'selected' : '')}
        onClick={() => {
          selectTool(AREA_TOOL)
        }}
      >
        <StyledTooltip title={t('Crop')} placement="top-end">
          <i className="ion-ios-crop-strong" />
        </StyledTooltip>
      </div>
      {MeasureToolButton}
    </div>
  )
}
export default withTranslator(Measuresbar)
