import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import StyledSelect from '../UIComponents/StyledSelect'
import { useSelector, useDispatch } from 'react-redux'
import { localeSet } from '../../features/locale/localeSlice'
import userStoredData from '../../../../web/src/userStoredData'
import { setMessagesFromLocale } from '@layers-frontend/commons/store/actions/messages'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  a {
    text-transform: initial;
  }
`

const StyledIconLanguage = styled.i`
  color: ${UIStyles.vomitColor};
  padding-top: 20px;
  padding-bottom: 20px;
  margin-right: 40px;
`

export default function LanguageButtons({ inputStyle = {} }) {
  // Get the actual `dispatch` function with `useDispatch`
  const dispatch = useDispatch()

  // Select values from the state with `useSelector`
  const locale = useSelector(state => state.locale)
  const availableLocales = useSelector(state => state.config.i18n.availableLocales)

  const onChangeHandler = _locale => {
    if (_locale !== locale) {
      userStoredData.setLocale(_locale)
      dispatch(setMessagesFromLocale(_locale))
      dispatch(localeSet(_locale))
    }
  }
  return (
    <Wrapper>
      {<StyledIconLanguage className="fas fa-globe" />}
      {locale && (
        <StyledSelect
          testid="language-select"
          menuProps={{ disablePortal: true }}
          renderValue={_locale => {
            return availableLocales.find(({ locale }) => locale === _locale).label
          }}
          inputStyle={{ textAlign: 'left', marginLeft: '30px', width: '30%', ...inputStyle }}
          onChange={onChangeHandler}
          value={locale}
          options={availableLocales}
          labelSelector={'label'}
          valueSelector={'locale'}
        />
      )}
    </Wrapper>
  )
}
