import { connect } from 'react-redux'
import Editor from '../../components/editorComponents/Editor'

import { getEditorPresentData } from '../../selectors/editor'
import { getSelectedField } from '../../selectors/fields'
import { getConfigProjection } from '../../selectors/config'

const mapStateToProps = state => ({
  features: getEditorPresentData(state, 'features'),
  activeTool: getEditorPresentData(state, 'activeTool'),
  selectedField: getSelectedField(state),
  projection: getConfigProjection(state)
})

export default connect(mapStateToProps)(Editor)
