import React from 'react'
import PropTypes from 'prop-types'
import GeometryUtils from '../../geometryUtils'

import OlGeoJSON from 'ol/format/GeoJSON'
// eslint-disable-next-line import/no-duplicates
import OlPolygon from 'ol/geom/Polygon'
// eslint-disable-next-line import/no-duplicates
import { fromCircle } from 'ol/geom/Polygon'
import OlFeature from 'ol/Feature'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'
import OlLinearRing from 'ol/geom/LinearRing'
import { containsExtent } from 'ol/extent'

import DrawCircle from '../olComponents/interaction/drawCircle'
import DrawPolygon from '../olComponents/interaction/drawPolygon'
import SlicePolygon from '../olComponents/interaction/slicePolygon'
import ModifyInt from '../olComponents/interaction/modify'
import Translate from '../olComponents/interaction/translate'
import { POLYGON_DRAW, CIRCLE_DRAW, POLYGON_SLICE, POLYGON_MOVE, MARKER_TOOL } from '../../actions/editor'
import DrawPoint from '../olComponents/interaction/drawPoint'
import toString from 'lodash/toString'

import Map from '../olComponents/map'
import forEach from 'lodash/forEach'

/* eslint prefer-const: 0 */
export default class Interactions extends React.Component {
  constructor(props) {
    super(props)

    this.formater = new OlGeoJSON({
      featureProjection: props.projection.to
    })

    this.handleFeatureModify = this.handleFeatureModify.bind(this)
    this.handlePolygonUpdate = this.handlePolygonUpdate.bind(this)
    this.getStyle = this.getStyle.bind(this)
  }

  handlePolygonUpdate(evt) {
    let feature = evt.target
    let uid = feature.ol_uid

    let newGeometry = this.formater.writeGeometryObject(feature.getGeometry())

    //Compare coords
    let oldGeometry = this.props.currentDrawingGeometry
    if (newGeometry.coordinates[0].length !== oldGeometry.coordinates[0].length) {
      if (evt.target.getGeometry() instanceof OlPolygon) {
        let area = GeometryUtils.areaFromGeometry(newGeometry, this.props.projection.to)
        this.props.addNewPoint(uid, newGeometry, area)
      }
    }
  }

  handleFeatureModify(feature) {
    let uid = feature.getId()
    let geometry =
      toString(feature.getGeometry().getType()) === 'Circle'
        ? GeometryUtils.polygonFromCircle(feature.getGeometry(), this.props.projection.to)
        : this.formater.writeGeometryObject(feature.getGeometry())
    let area = GeometryUtils.areaFromGeometry(geometry, this.props.projection.to)
    this.props.addPoint(uid, geometry, area)
  }

  drawPolygonStart(evt) {
    let feature = evt.feature
    this.formater.writeGeometryObject(feature.getGeometry())
  }

  drawPolygonEnd(evt) {
    let feature = evt.feature
    let isHole = this.holeChecker(feature)
    let uid = feature.ol_uid
    let geometry = this.formater.writeGeometryObject(feature.getGeometry())
    let area = GeometryUtils.areaFromGeometry(geometry, this.props.projection.to)

    // feature.removeEventListener('change')
    if (!isHole.isHole) {
      this.props.polygonAdd(uid, geometry, area)
    } else {
      //  add hole
      this.props.addHole(isHole.uid, isHole.geometry, isHole.area, isHole.holeUid)
    }
  }

  drawCircleStart(evt, start) {
    let feature = evt.feature
    let uid = feature.ol_uid
    let geometry = GeometryUtils.polygonFromCircle(feature.getGeometry(), this.props.projection.to)
    this.props.polygonDrawStart(uid, geometry)
  }

  drawMarker(evt) {
    const feature = evt.feature
    const uid = feature.ol_uid
    const geometry = this.formater.writeGeometryObject(feature.getGeometry())
    const area = GeometryUtils.areaFromGeometry(geometry, this.props.projection.to)
    const marker = {
      type: 'Point',
      coordinates: geometry.coordinates
    }
    this.props.addMarker(uid, marker, area, geometry)
  }

  updateMarker(evt) {
    const _geometry = evt.feature.getGeometry()
    const samplesLayer = Map.getLayerFromName('SamplesLayer', this.context.map).getSource().getFeatures()

    forEach(samplesLayer, sample => {
      if (sample.get('field').id === this.props.selectedSample.id) {
        sample.setGeometry(_geometry)
        const formattedGeometry = this.formater.writeGeometryObject(_geometry)
        this.props.updatePosition({
          lat: formattedGeometry.coordinates[1],
          lon: formattedGeometry.coordinates[0]
        })
      }
    })
  }

  drawCircleEnd(evt) {
    let feature = evt.feature
    let polygonizedCircle = new OlFeature()
    polygonizedCircle.setGeometry(fromCircle(feature.getGeometry(), 500))
    let isHole = this.holeChecker(polygonizedCircle)
    let uid = feature.ol_uid
    let geometry = GeometryUtils.polygonFromCircle(feature.getGeometry(), this.props.projection.to)
    let area = GeometryUtils.areaFromGeometry(geometry, this.props.projection.to)
    if (!isHole.isHole) {
      this.props.polygonDrawEnd(uid, geometry, area)
    } else {
      //  add hole
      this.props.addHole(isHole.uid, isHole.geometry, isHole.area, isHole.holeUid)
    }
  }

  movePolygon(evt) {
    let feature = evt.features.array_[0]
    let uid = feature.getId()

    let geometry =
      feature.getGeometry() instanceof OlPolygon
        ? this.formater.writeGeometryObject(feature.getGeometry())
        : GeometryUtils.polygonFromCircle(feature.getGeometry())

    let area = GeometryUtils.areaFromGeometry(geometry, this.props.projection.to)

    this.props.polygonMove(uid, geometry, area)
  }

  slicePolygonEnd(evt) {
    let source = this.context.source
    let slicer = evt.feature
    let slicerGeometry = evt.feature.getGeometry()
    source.forEachFeatureIntersectingExtent(slicerGeometry.getExtent(), feature => {
      let uid = feature.getId()
      // If feature is a circle transforme it to polygon before cut
      if (toString(feature.getGeometry().getType()) === 'Circle') {
        feature.setGeometry(fromCircle(feature.getGeometry(), 500))
      }
      try {
        let polyCollection = GeometryUtils.turfCut(this.formater.writeFeatureObject(feature), this.formater.writeFeatureObject(slicer))
        let polyCollectionWithUid = polyCollection.features.map(feature => {
          // eslint-disable-next-line camelcase
          let ol_geometry = this.formater.readGeometry(feature.geometry)
          return {
            // eslint-disable-next-line camelcase
            uid: ol_geometry.ol_uid,
            geometry: feature.geometry
          }
        })

        this.props.polygonSlice(uid, polyCollectionWithUid)
      } catch (e) {
        this.props.sliceException(e.message)
      }
    })
  }

  holeChecker(newFeature) {
    let newExtent = newFeature.getGeometry().getExtent()
    let source = this.context.source
    let isHole = false
    let featureUid = ''
    let newGeometry
    let newArea = 0
    let holeUid = newFeature.ol_uid
    let polygon
    let polygonWithHole
    source.forEachFeature(feature => {
      let extent = feature.getGeometry().getExtent()
      if (containsExtent(extent, newExtent)) {
        isHole = true
        featureUid = feature.getId()
        if (toString(feature.getGeometry().getType()) === 'Circle') {
          polygon = fromCircle(feature.getGeometry(), 500)
        } else {
          polygon = feature.getGeometry()
        }
        let hole = new OlLinearRing(newFeature.getGeometry().getCoordinates()[0])
        polygonWithHole = GeometryUtils.addHoleToPolygon(polygon, hole)
        newGeometry = this.formater.writeGeometryObject(polygonWithHole, {
          dataProjection: this.props.projection.from,
          featureProjection: this.props.projection.to
        })
        newArea = polygonWithHole.getArea()
      }
    })
    return {
      // eslint-disable-next-line object-shorthand
      isHole: isHole,
      uid: featureUid,
      geometry: newGeometry,
      area: newArea,
      // eslint-disable-next-line object-shorthand
      holeUid: holeUid
    }
  }

  getStyle() {
    return [
      {
        stroke: {
          width: 4,
          color: 'rgba(255, 0, 0, 0.8)'
        },
        fill: {
          color: 'rgba(255, 255, 255, 0.1)'
        },
        // RS is for regularshape
        image: {
          fill: {
            color: 'red'
          },
          points: 0,
          radius1: 15,
          radius2: 1
        }
      }
    ]
  }

  getSlicerStyle() {
    return [
      {
        stroke: {
          color: 'rgba(255, 0, 0, 0.8)',
          lineDash: [10, 10],
          width: 2
        },
        fill: {
          color: 'rgba(255, 255, 255, 0.2)'
        },
        // RS is for regularshape
        image: {
          fill: {
            color: 'rgba(255, 255, 255, 0.2)'
          },
          points: 0,
          radius1: 15,
          radius2: 1,
          stroke: {
            color: 'rgba(0, 0, 0, 0.7)'
          }
        }
      }
    ]
  }

  getChildContext() {
    return {
      map: this.context.map
    }
  }

  render() {
    let _this = this

    /*=============================================>>>>>
      = Modify Interaction =
      ===============================================>>>>>*/

    let modify = this.props.isModifyTool ? (
      <ModifyInt
        modifyend={evt => {
          _this.handleFeatureModify(evt.target.dragSegments_[0][0].feature)
        }}
      />
    ) : null

    /*=============================================>>>>>
          = Draw Interaction =
          ===============================================>>>>>*/

    let drawingTool = () => {
      switch (this.props.activeTool) {
        case POLYGON_DRAW: {
          return <DrawPolygon drawstart={evt => _this.drawPolygonStart(evt)} drawend={evt => _this.drawPolygonEnd(evt)} style={_this.getStyle()} />
        }
        case CIRCLE_DRAW: {
          return <DrawCircle drawstart={evt => _this.drawCircleStart(evt)} drawend={evt => _this.drawCircleEnd(evt)} style={_this.getStyle()} />
        }
        case MARKER_TOOL: {
          return <DrawPoint drawstart={evt => (this.props.selectedSample ? _this.updateMarker(evt) : _this.drawMarker(evt))} style={_this.getStyle()} />
        }
        default:
          // eslint-disable-next-line no-useless-return
          return
      }
    }

    /*=============================================>>>>>
            = Slice interaction =
            ===============================================>>>>>*/
    let slice = (
      <SlicePolygon
        drawstart={evt => {
          // eslint-disable-next-line no-useless-return
          return
        }}
        drawend={evt => _this.slicePolygonEnd(evt)}
        style={_this.getSlicerStyle()}
      />
    )

    /*=============================================>>>>>
          = Move Polygon action =
          ===============================================>>>>>*/
    let move = (
      <Translate
        translatestart={evt => {
          // eslint-disable-next-line no-useless-return
          return
        }}
        translateend={evt => _this.movePolygon(evt)}
        style={_this.getStyle()}
      />
    )

    /*=============================================>>>>>
                   = Editor tools selection =
                   ===============================================>>>>>*/
    switch (_this.props.activeTool) {
      case POLYGON_DRAW:
      case CIRCLE_DRAW:
        return (
          <div>
            {' '}
            {drawingTool()} {modify}
          </div>
        )
      case MARKER_TOOL: {
        return drawingTool()
      }
      case POLYGON_SLICE:
        return <div> {slice} </div>
      case POLYGON_MOVE:
        return <div> {move} </div>
      default:
        return (
          <div>
            {' '}
            {drawingTool()} {modify}
          </div>
        )
    }
  }
}

/*= End of Editor tools selection =*/
/*=============================================<<<<<*/
Interactions.propTypes = {
  activeTool: PropTypes.string,
  addHole: PropTypes.func,
  addNewPoint: PropTypes.func,
  addPoint: PropTypes.func,
  currentDrawingGeometry: PropTypes.object,
  deselectField: PropTypes.func,
  drawing: PropTypes.bool,
  features: PropTypes.arrayOf(PropTypes.object),
  fieldModify: PropTypes.func,
  isCircleDrawingTool: PropTypes.bool,
  isDrawingTools: PropTypes.bool,
  isModifyTool: PropTypes.bool,
  isPolygonDrawingTool: PropTypes.bool,
  polygonAdd: PropTypes.func,
  polygonDrawEnd: PropTypes.func,
  polygonDrawStart: PropTypes.func,
  polygonMove: PropTypes.func,
  polygonSlice: PropTypes.func,
  projection: PropTypes.object,
  screenView: PropTypes.string,
  selectField: PropTypes.func,
  selectedField: PropTypes.object,
  sliceException: PropTypes.func
}

Interactions.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

Interactions.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
