import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import curryRight from 'lodash/curryRight'
import includes from 'lodash/fp/includes'
import map from 'lodash/fp/map'
import last from 'lodash/fp/last'
import keys from 'lodash/fp/keys'
import filter from 'lodash/fp/filter'
import pick from 'lodash/fp/pick'
import { USER_ROLES } from '../constants'
import { getUser, getUserName, getToken, hasUserRole, getBiLabsUrl } from '@layers-frontend/commons/store/selectors/user'

export { getUser, getToken, hasUserRole, getUserName, getBiLabsUrl }
export const getCustomers = get('customers')

export const getErrorLogin = pipe(getUser, get('errorLogin'))

export const getUserData = (state, key) => pipe(getUser, get(key))(state)

export const getFieldsProperties = state => {
  const statistics = curryRight(getUserData)('statistics')(state)
  const fieldsProperties = statistics.reduce((allProperties, currentProperty) => {
    const metadata = get('metadata')(currentProperty)
    const features = get('features')(metadata)
    const lastFeatureProperties = last(map('properties')(features))
    const fieldId = get('field_id')(lastFeatureProperties)

    return {
      ...allProperties,
      [fieldId]: allProperties[fieldId] ? [...allProperties[fieldId], lastFeatureProperties] : [lastFeatureProperties]
    }
  }, {})

  const fieldsIdsWithMoreThanThreeSnapshots = filter(fieldId => fieldsProperties[fieldId].length > 2)(keys(fieldsProperties))
  // const fieldsIdsWithMoreThanThreeSnapshots = uniqBy(fieldId => fieldsProperties[fieldId].length > 2)(keys(fieldsProperties))

  return pick(fieldsIdsWithMoreThanThreeSnapshots)(fieldsProperties)
}

export const hasUserCoordsInLoggerAuthorization = pipe(curryRight(getUserData)('roles'), includes(USER_ROLES.ROLE_COORDS_LOGGER))
