import React, { Component } from 'react'
import styled from 'styled-components'
import { TextField } from '@mui/material'
import { UIStyles } from '../../../theme'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import { withTranslator } from '../../HOCComponents/withTranslator'

import capitalize from 'lodash/capitalize'

const MultiLineTextAreaWrapper = styled(ToolWrapper)`
  &:focus-within input {
    border-color: ${UIStyles.vomitColor} !important;
  }
`

class Notes extends Component {
  constructor(props) {
    super()
    this.state = {
      notes: props.storeNotes,
      textAreaBorderColor: UIStyles.grayColor
    }
  }

  handleSetFieldsReportModalMessage = notes => {
    this.setState({ notes })
  }
  // eslint-disable-next-line lines-between-class-members
  onFocus = () => this.setState({ textAreaBorderColor: UIStyles.vomitColor })

  onBlur = () => {
    this.setState({ textAreaBorderColor: UIStyles.grayColor })
    this.props.setFieldsReportModalNotes(this.state.notes)
  }

  render() {
    const { notes } = this.state
    const { t, error, errorNode } = this.props

    const multiLineTextAreaStyle = {
      border: `1px solid`,
      backgroundColor: UIStyles.blueColorOpacity,
      borderRadius: '8px',
      padding: '12px 8px',
      borderColor: error ? UIStyles.errorRed : this.state.textAreaBorderColor,
      maxHeight: '300px',
      overflow: 'auto',
      '.MuiInput-root::before': {
        borderBottom: 'none'
      },
      '.MuiInput-root::after': {
        borderBottom: 'none'
      },
      '.MuiInput-root:hover:not(.Mui-disabled):before': {
        borderBottom: 'none'
      },
      'textarea::placeholder': {
        color: UIStyles.grayColor,
        opacity: 1
      }
    }

    return (
      <MultiLineTextAreaWrapper>
        <ToolTitle>{capitalize(t('notes'))} *</ToolTitle>
        <TextField
          placeholder={`${t('Message')}...`}
          value={notes}
          multiline
          onChange={({ target }) => this.handleSetFieldsReportModalMessage(target.value)}
          rows={7}
          fullWidth
          sx={multiLineTextAreaStyle}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
        {errorNode}
      </MultiLineTextAreaWrapper>
    )
  }
}

export default withTranslator(Notes)
