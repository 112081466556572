import { useState } from 'react'

// custom hook for localStorage
const useLocalStorage = (key, initialValue) => {
  // read the localStorage item
  const storedValue = localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : initialValue

  // State to track the current value
  const [value, setValue] = useState(storedValue)

  // update local storage and state when the value changes
  const setStoredValue = newValue => {
    setValue(newValue)
    localStorage.setItem(key, JSON.stringify(newValue))
  }

  // remove item from local storage and reset state
  const removeStoredValue = () => {
    setValue(initialValue)
    localStorage.removeItem(key)
  }

  return [value, setStoredValue, removeStoredValue]
}

export default useLocalStorage
