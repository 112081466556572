import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import DeliverablesLayerBoxes from '../components/DeliverablesComponents/DeliverablesLayerBoxes'
import { toggleDeliverable, toggleVisibility, setTemporalComparison, changeOpacity, setLayerOrder, activeLayers } from '../actions/deliverables'
import { setVisibilitySeasonLayer, setOpacitySeasonLayer, setOrderSeasonLayers, toggleSeasonLayerLegends } from '../actions/ui'
import { getUISeasonLayers } from '../selectors/ui'
import {
  getFlightGroupsLength,
  getSelectedFlightGroupActiveLayers,
  getSelectedFlightGroupDeliverables,
  getSelectedFlightGroupIndex,
  getSelectedFlightGroupPdfDeliverables,
  getTemporalComparisonFlightGroupDeliverables
} from '../selectors/flightGroups'
import { isTemporalView as isTemporalComparisonView } from '../selectors/viewmode'

import unionBy from 'lodash/fp/unionBy'
import pipe from 'lodash/fp/pipe'
import orderBy from 'lodash/fp/orderBy'
import { getActiveDateCalendarDots } from '../selectors/calendar'
import { selectFlightGroup } from '../actions/fields'

const mapStateToProps = state => {
  const isTemporalView = isTemporalComparisonView(state)
  const selectedFlightGroupDeliverables = getSelectedFlightGroupDeliverables(state) || []
  const deliverables = !isTemporalView
    ? selectedFlightGroupDeliverables
    : pipe(getTemporalComparisonFlightGroupDeliverables, unionBy('typeId')(selectedFlightGroupDeliverables), orderBy('type.order_by', 'desc'))(state)
  return {
    deliverables,
    seasonLayers: getUISeasonLayers(state),
    pdfDeliverables: getSelectedFlightGroupPdfDeliverables(state),
    isTemporalView,
    isLayers: getFlightGroupsLength(state) === 0 ? true : getSelectedFlightGroupActiveLayers(state),
    activeDateCalendarDots: getActiveDateCalendarDots(state),
    selectedFlightGroupIndex: getSelectedFlightGroupIndex(state)
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      setLayerOrder,
      activeLayers,
      toggleDeliverable,
      toggleVisibility,
      changeOpacity,
      setTemporalComparison,
      setVisibilitySeasonLayer,
      setOpacitySeasonLayer,
      setOrderSeasonLayers,
      toggleSeasonLayerLegends,
      selectFlightGroup
    },
    dispatch
  )
}
export default connect(mapStateToProps, mapDispatchToProps)(DeliverablesLayerBoxes)
