import { RECEIVE_CUSTOMERS_DELIVERABLES } from '../actions/customersDeliverables'

import reduce from 'lodash/reduce'
import get from 'lodash/get'
import union from 'lodash/union'
import map from 'lodash/map'
import forEach from 'lodash/forEach'

const customersDeliverablesNormalizer = ({ customers, fieldTypeLayers }) => {
  const fieldTypesByDeliverableId = reduce(
    fieldTypeLayers,
    (accumulator, fieldTypeLayer) => {
      const deliverableTypeId = get(fieldTypeLayer, ['deliverable', 'typeId'])

      if (deliverableTypeId) {
        const previousFieldTypeIds = get(accumulator, deliverableTypeId, [])
        const currentFieldTypeId = get(fieldTypeLayer, 'fieldTypeId')

        return {
          ...accumulator,
          [deliverableTypeId]: union(previousFieldTypeIds, [currentFieldTypeId])
        }
      }
      return accumulator
    },
    {}
  )

  const customersDeliverableByIdFieldType = reduce(
    customers,
    (accumulator, customer) => {
      const { id, layers } = customer

      const customerDeliverables = map(layers, 'deliverable')
      // eslint-disable-next-line prefer-const
      let currentCustomerFieldTypesDeliverables = {}

      forEach(customerDeliverables, deliverable => {
        const deliverableTypeId = get(deliverable, 'typeId')
        const fieldTypesForDeliverable = fieldTypesByDeliverableId[deliverableTypeId]
        forEach(fieldTypesForDeliverable, fieldType => {
          const previousDeliverablesForFieldType = get(currentCustomerFieldTypesDeliverables, fieldType, [])

          currentCustomerFieldTypesDeliverables[fieldType] = union(previousDeliverablesForFieldType, [deliverable])
        })
      })

      return {
        ...accumulator,
        [id]: currentCustomerFieldTypesDeliverables
      }
    },
    {}
  )

  return customersDeliverableByIdFieldType
}

export default function customersDeliverables(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE_CUSTOMERS_DELIVERABLES:
      return customersDeliverablesNormalizer(action)
    default:
      return state
  }
}
