import { loader } from 'graphql.macro'
import { fetchRequestWrapper, fetchGraphqlWrapper } from './ui'
import { DELIVERABLE_FORMATS } from '@layers-frontend/commons/constants'
import toString from 'lodash/toString'

export const SET_LAYER_ORDER_GROUP = 'SET_LAYER_ORDER_GROUP'
export const TOGGLE_DELIVERABLE_GROUP = 'TOGGLE_DELIVERABLE_GROUP'
export const TOGGLE_VISIBILITY_DELIVERABLE_GROUP = 'TOGGLE_VISIBILITY_DELIVERABLE_GROUP'
export const CHANGE_OPACITY_GROUP = 'CHANGE_OPACITY_GROUP'
export const CHANGE_RANGE_COLOR_GROUP = 'CHANGE_RANGE_COLOR_GROUP'
export const CLEAR_VISIBILITIES_GROUP = 'CLEAR_VISIBILITIES_GROUP'
export const SET_LAYER_TOOL_VALUE = 'SET_LAYER_TOOL_VALUE'
export const ACTIVE_LAYERS = 'ACTIVE_LAYERS'
export const REQUEST_DELIVERABLE_TYPES = 'REQUEST_DELIVERABLE_TYPES'
export const RECEIVE_DELIVERABLE_TYPES = 'RECEIVE_DELIVERABLE_TYPES'
export const OPEN_NDVI_DIALOG = 'OPEN_NDVI_DIALOG'
export const SET_SELECTED_DELIVERABLE = 'SET_SELECTED_DELIVERABLE'
export const CLOSE_NDVI_DIALOG = 'CLOSE_NDVI_DIALOG'
export const CLOSE_GROUP_DELIVERABLE_DIALOG = 'CLOSE_GROUP_DELIVERABLE_DIALOG'
export const REQUEST_DELIVERY_S3_URL = 'REQUEST_DELIVERY_S3_URL'
export const RECEIVE_DELIVERY_S3_URL = 'RECEIVE_DELIVERY_S3_URL'

/*** DELIVERABLE template ***/

// deliverableTemplate = {
//   geoserver_url: '',
//   id: null,
//   max: undefined,
//   min: undefined,
//   opacity: 1,
//   opened: true,
//   s3_url: '',
//   type: {},
//   visible: false
// }

/*** DELIVERABLE TYPES ***/
const { DYNAMIC_RANGE } = DELIVERABLE_FORMATS
/************************* */
/*** Explanation for dynamic url */
/*** contrary to drones, there is just a satellite deliverable by type
 * ex :
 *  https://services.sentinel-hub.com/ogc/wms/${API_KEY}?service=WMS&version=1.1.1&request=GetMap&layers=VIGOR&outputFormat=application%2Fjson&transparent=true&width=512&height=512&time=${END_DATE}&srs=EPSG%3A3857&geometry=${FIELD_GEOMETRY}
&SRS=EPSG:3857
 *   parameters as end_date and geometry will be replaced automatically in the mapping
  */

export const setLayerOrder = (oldIndex, newIndex) => ({
  type: SET_LAYER_ORDER_GROUP,
  payload: {
    // eslint-disable-next-line object-shorthand
    oldIndex: oldIndex,
    // eslint-disable-next-line object-shorthand
    newIndex: newIndex
  }
})

/** Use to activate tabs ( layers, PDF ) true for layers, false for PDF */
export const activeLayers = open => ({
  type: ACTIVE_LAYERS,
  payload: open
})

/** Open/close deliverable legend */
export const toggleDeliverable = deliverable => ({
  type: TOGGLE_DELIVERABLE_GROUP,
  payload: deliverable
})

/** use to toggle visibility of a deliverable in the UI ( eye icon ) */
export const toggleVisibility = deliverable => ({
  type: TOGGLE_VISIBILITY_DELIVERABLE_GROUP,
  payload: deliverable
})

/** use to change opacity of a deliverable in the UI ( the range tool to change opacity of a layer ) */
export const changeOpacity = (deliverable, opacity) => ({
  type: CHANGE_OPACITY_GROUP,
  payload: {
    // eslint-disable-next-line object-shorthand
    deliverable: deliverable,
    // eslint-disable-next-line object-shorthand
    opacity: opacity
  }
})

/** deactivate all eye icons ( deactive all layers visibility ) */

export const clearVisibilities = () => ({
  type: CLEAR_VISIBILITIES_GROUP
})

/** active temporal comparison */
export const setTemporalComparison = deliverable => dispatch => {
  dispatch(clearVisibilities())
  return dispatch(toggleVisibility(deliverable))
}

export const setSelectedDeliverable = deliverable => ({
  type: SET_SELECTED_DELIVERABLE,
  deliverable
})

export const receiveDeliverableTypes = deliverableTypes => ({
  type: RECEIVE_DELIVERABLE_TYPES,
  deliverableTypes
})

const getDeliverableTypes = loader('../graphql/getDeliverableTypes.gql').loc.source.body

export const fetchDeliverableTypes = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_DELIVERABLE_TYPES,
    query: getDeliverableTypes,
    onSuccess: ({ deliverableTypes }) => receiveDeliverableTypes(deliverableTypes)
  })

/** Add UI features to deliverable ( basically the bottom left deliverable box )
 * Nota bene : min & max are not used for now
 */
export const addDeliverableUIFeatures = (deliverable, visible) => ({
  ...deliverable,
  opened: false,
  // eslint-disable-next-line object-shorthand
  visible: visible,
  opacity: 1,
  min: toString(deliverable.type.format.id) === toString(DYNAMIC_RANGE) ? deliverable.min_value : null,
  max: toString(deliverable.type.format.id) === toString(DYNAMIC_RANGE) ? deliverable.max_value : null
})

/**
 * this is only used for prospect layers, it's a nicer way to handle every tools below
 * see how it is used in containers/SearchContainers/SearchViewContainer
 * and js/reducers/users
 */
export const setLayerToolValue = (layer, tool, value = null) => ({
  type: SET_LAYER_TOOL_VALUE,
  payload: {
    /* eslint-disable object-shorthand */
    layer: layer,
    tool: tool,
    value: value
    /* eslint-enable object-shorthand */
  }
})

export const getDeliveryS3Url = path =>
  fetchRequestWrapper({
    route: 'get_s3_url',
    urlParams: { path },
    requestType: REQUEST_DELIVERY_S3_URL,
    onSuccess: data => ({ type: RECEIVE_DELIVERY_S3_URL, data })
  })
