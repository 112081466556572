import { connect } from 'react-redux'
import FlightTemporalSwipe from '../../components/FieldsComponents/FlightTemporalSwipe'
import { resetTempComparison } from '../../actions/calendar'
import { moveTemporalSlider } from '../../actions/fields'
import { getSelectedFlightGroupFirstFlightDate } from '../../selectors/flightGroups'
import { getTempComparisonDate } from '../../selectors/calendar'
import { getTemporalSwipe } from '../../selectors/fields'

const mapStateToProps = state => {
  return {
    firstFlightDate: getSelectedFlightGroupFirstFlightDate(state),
    secondFlightDate: getTempComparisonDate(state),
    temporalSwipe: getTemporalSwipe(state)
  }
}
const mapDispatchToProps = {
  resetTempComparison,
  moveTemporalSlider
}

export default connect(mapStateToProps, mapDispatchToProps)(FlightTemporalSwipe)
