import { connect } from 'react-redux'
import SelectedFieldsDetails from '../../components/FieldsComponents/SelectedFieldsDetails'
import { getUserRoles } from '@layers-frontend/commons/store/selectors/user'
import { getSelectedFieldsWithActiveSeason, getSelectedFlightGroupFieldsWithSeason } from '../../selectors/fields'
import { openFieldSeasonFormModal } from '../../actions/modal'
import { setPreviewFieldId } from '../../actions/fields'
import unionBy from 'lodash/unionBy'

const mapStateToProps = state => {
  const selectedFlightGroupFieldsWithSeason = getSelectedFlightGroupFieldsWithSeason(state)
  const selectedFieldsWithSeason = getSelectedFieldsWithActiveSeason(state)
  const selectedFields = unionBy(selectedFlightGroupFieldsWithSeason, selectedFieldsWithSeason, 'id')

  return {
    userRoles: getUserRoles(state),
    selectedFields
  }
}

const mapDispatchToProps = {
  openFieldSeasonFormModal,
  setPreviewFieldId
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectedFieldsDetails)
