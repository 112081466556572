import { fetchRequestWrapper } from './ui'
import { PRED_TECH_LOG_TYPES } from '../constants'
import { getToken } from '../userStoredData'

export const FETCH_FIELDS_WITH_PRED_TECH_LOGS = 'FETCH_FIELDS_WITH_PRED_TECH_LOGS'
export const RECEIVE_FIELDS_WITH_PRED_TECH_LOGS = 'RECEIVE_FIELDS_WITH_PRED_TECH_LOGS'
export const REQUEST_PRED_TECH_DATA_INITIALIZATION = 'REQUEST_PRED_TECH_DATA_INITIALIZATION'
export const RECEIVE_FIELDS_WEEKLY = 'RECEIVE_FIELDS_WEEKLY'
export const RECEIVE_FIELDS_IDEAL_SEASON = 'RECEIVE_FIELDS_IDEAL_SEASON'
export const RECEIVE_FIELDS_SEASON_MAX = 'RECEIVE_FIELDS_SEASON_MAX'

const getBaseCustomRoute = () => `${window.location.origin}/athena/forecast`

const makeRequestOptions = (fieldIds, logType, mode) => ({
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ api_key: getToken(), field_ids: fieldIds, log_type: Number(logType[0]), mode })
})

export const fetchPredTech = ({ fieldIds, logType, requestType, returnType, authorizingRole = null, overlay = false, mode }) => {
  const response = fetchRequestWrapper({
    customRoute: `${getBaseCustomRoute()}?${new URLSearchParams({ api_key: getToken() })}`,
    requestType,
    authorizingRole,
    fetchOptions: makeRequestOptions(fieldIds, logType, mode),
    overlay,
    onSuccess: data => ({ type: returnType, data: data.data, logType: [logType], mode })
  })

  return response
}

export const fetchPredTechByLogType = ({ fieldIds, mode }) => dispatch => {
  if (fieldIds.length === 0) return

  Object.values(PRED_TECH_LOG_TYPES).forEach(logType => {
    if (['weekly', 'ideal_season', 'season_max'].includes(mode)) {
      dispatch(
        fetchPredTech({
          mode,
          fieldIds,
          logType: [logType],
          requestType: FETCH_FIELDS_WITH_PRED_TECH_LOGS,
          returnType: `RECEIVE_FIELDS_${mode.toUpperCase()}`
        })
      )
    }
  })
}
