import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'

import FilterModule from '../../components/DashboardComponents/FilterModule'
import { toggleDashFilter } from '../../actions/dashboard'

import {
  getActiveFilters,
  getDashboardFormFilters,
  getSelectedHarvestPlanningMultiFieldsSelectIds,
  getHarvesPlanningToolFieldsOptions,
  getHarvestPlanningToolDryMatterRangeMin,
  getHarvestPlanningToolDryMatterRangeMax
} from '../../selectors/dashboard'
import { getMetadatasDateRange } from '../../selectors/config'
import { isUserCustomer } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  form: getDashboardFormFilters(state),
  activeFilters: getActiveFilters(state),
  metadatasDateRange: getMetadatasDateRange(state),
  isUserCustomer: isUserCustomer(state),
  selectedHarvesPlanningToolFieldsIds: getSelectedHarvestPlanningMultiFieldsSelectIds(state),
  harvestPlanningToolFields: getHarvesPlanningToolFieldsOptions(state),
  harvestPlanningToolDryMatterRangeMin: getHarvestPlanningToolDryMatterRangeMin(state),
  harvestPlanningToolDryMatterRangeMax: getHarvestPlanningToolDryMatterRangeMax(state)
})

const mapDispatchToProps = {
  toggleDashFilter
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(FilterModule)
