import { connect } from 'react-redux'
import { compose } from 'redux'

import TemporalEvolutionMetadataDateRangeSelect from '../../../components/DashboardComponents/DashboadFilters/TemporalEvolutionMetadataDateRangeSelect'

import { withTranslator } from '../../../components/HOCComponents/withTranslator'
import { withFieldsAvailableAndSelected } from '../../../components/HOCComponents/withFieldsAvailableAndSelected'
import { getFieldsFilteredByView } from '../../../selectors/availableFields'

import { getDashboardFormFilter } from '../../../selectors/dashboard'
import { getSatStatisticsFetchFromDate } from '../../../selectors/ui'

import { toggleDashFilter, DASHBOARD_FILTERS_TYPES } from '../../../actions/dashboard'
import { openExtraStatisticsFetchConfirmationModal } from '../../../actions/modal'

const { TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM, TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO } = DASHBOARD_FILTERS_TYPES

const mapStateToProps = (state, { fieldsWithAvailableAndSelected }) => ({
  dateRangeFrom: getDashboardFormFilter(state, `${TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_FROM}.value`),
  dateRangeTo: getDashboardFormFilter(state, `${TEMPORAL_EVOLUTION_METADATA_DATE_RANGE_SELECT_TO}.value`),
  satStatisticsFetchFromDate: getSatStatisticsFetchFromDate(state),
  selectedFields: getFieldsFilteredByView({ fieldsWithAvailableAndSelected })(state)
})

const mapDispatchToProps = {
  toggleDashFilter,
  openExtraStatisticsFetchConfirmationModal
}

export default compose(withFieldsAvailableAndSelected, withTranslator, connect(mapStateToProps, mapDispatchToProps))(TemporalEvolutionMetadataDateRangeSelect)
