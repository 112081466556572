import React from 'react'
import { GRID_LAYOUT } from '../../actions/ui'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import capitalize from 'lodash/capitalize'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const StyledIcon = styled.i`
  width: 36px;
  height: 36px;
  padding: 5px;
  border: 1px solid ${UIStyles.vomitColor};
  border-radius: 2px;
  cursor: pointer;
  font-size: 16px;
  color: ${UIStyles.vomitColor};
  display: flex;
  justify-content: center;
  align-items: center;
`

export default function SwitchLayoutIcon({ chartsLayout, setChartsLayoutList, setChartsLayoutGrid, t }) {
  const isGridLayout = chartsLayout === GRID_LAYOUT
  const handleLayoutChange = isGridLayout ? setChartsLayoutList : setChartsLayoutGrid
  const tooltipTitle = `${t('view')} ${isGridLayout ? t('list') : t('grid')}`

  return (
    <StyledTooltip title={capitalize(tooltipTitle)} placement="left">
      <StyledIcon className={`fas fa-${isGridLayout ? 'bars' : 'th-large'}`} onClick={handleLayoutChange} />
    </StyledTooltip>
  )
}
