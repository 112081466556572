import React, { useEffect } from 'react'
import { Route, Navigate, useLocation, Routes } from 'react-router-dom'
import { isMobile, isIOS } from 'react-device-detect'
import userStoredData from '../userStoredData'
import { authenticatedRoutes } from './routes'
import LeftBar from '../components/LeftBar'
import TopBar from '../containers/TopBar/TopBar'
import ModalBaseContainer from '../containers/ModalContainers/ModalBaseContainer'
import ScreenshotOverlayContainer from '../containers/UtilitiesContainers/ScreenshotOverlayContainer'
import AlertMobile from './UIComponents/AlertMobile'
import styled from 'styled-components'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import withTranslator from './HOCComponents/withTranslator'
import 'moment/locale/en-gb'
import 'moment/locale/es'
import 'moment/locale/pt'
import 'moment/locale/fr'
import 'moment/locale/hu'
import 'moment/locale/uk'
import 'moment/locale/sr'
import 'moment/locale/ro'
import 'moment/locale/sk'
import 'moment/locale/it'
import 'moment/locale/ru'
import 'moment/locale/de'
import moment from 'moment'
import { pageViewed } from './analytics/analytics'
import { FALLBACK_LANGUAGE } from '../constants'
import * as Sentry from '@sentry/browser'

const ToolbarsWrapper = styled.div`
  pointer-events: ${props => (props.disablePointerEvents ? 'none' : 'initial')};
`

const localeMap = {
  de: 'de',
  en: 'en-gb',
  es: 'es',
  fr: 'fr',
  hu: 'hu',
  it: 'it',
  pt: 'pt',
  ro: 'ro',
  ru: 'ru',
  sk: 'sk',
  sr: 'sr',
  uk: 'uk'
}

moment.updateLocale('en', {
  week: {
    dow: 1
  }
})

function AuthenticatedApp({ t, user, toolbarsDisabled, hasRoleIrriga, locale, setLocale, seti18n, hasMobileControlActionDisabled }) {
  const location = useLocation()

  useEffect(() => {
    if (hasRoleIrriga) {
      const systemLanguage = user?.config?.language || FALLBACK_LANGUAGE
      setLocale(systemLanguage)
      seti18n(systemLanguage)
    }
  }, [hasRoleIrriga, user?.config?.language, setLocale, seti18n])

  useEffect(() => {
    if (location.pathname) pageViewed(`${location.pathname}`, { userId: user.id, locale })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  moment.locale(locale)

  const localeText = {
    cancelButtonLabel: t('cancel'),
    clearButtonLabel: t('clear')
  }

  if (!user.username) {
    return <Navigate to={'/'} state={{ redirectedFrom: location.pathname }} />
  } else Sentry.setUser({ username: user.username })

  if (isMobile && !hasRoleIrriga && !hasMobileControlActionDisabled) {
    return <AlertMobile isIOS={isIOS} apiKey={userStoredData.API_KEY} />
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={localeMap[locale]} localeText={localeText}>
      <ToolbarsWrapper disablePointerEvents={toolbarsDisabled}>
        <TopBar user={user.username || ''} />
        {location.pathname !== 'accounts' && <LeftBar />}
      </ToolbarsWrapper>

      <Routes>
        {authenticatedRoutes.map(route => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>

      <ModalBaseContainer />
      <ScreenshotOverlayContainer />
    </LocalizationProvider>
  )
}

export default withTranslator(AuthenticatedApp)
