import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../../components/HOCComponents/withTranslator'
import TemporalEvolutionMaxCloudCoverageSlider from '../../../components/DashboardComponents/DashboadFilters/TemporalEvolutionMaxCloudCoverageSlider'
import { setTemporalEvolutionMaxCloudCoverage } from '../../../actions/dashboard'
import { getTemporalEvolutionMaxCloudCoverage } from '../../../selectors/dashboard'

const mapStateToProps = state => ({
  temporalEvolutionMaxCloudCoverage: getTemporalEvolutionMaxCloudCoverage(state)
})
const mapDispatchToProps = {
  setTemporalEvolutionMaxCloudCoverage
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(TemporalEvolutionMaxCloudCoverageSlider)
