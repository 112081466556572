import { fetchGraphqlWrapper } from './ui'
import { loader } from 'graphql.macro'
import { fetchSampleParametersByTypeSuccess } from '@layers-frontend/commons/store/actions/sampleParametersByType'
import { REQUEST_SAMPLE_PARAMETERS_BY_TYPE } from '@layers-frontend/commons/store/storeConstants'

const getSamplesParametersByType = loader('../graphql/samples/getSamplesParametersByType.gql').loc.source.body

export const fetchSamplesParametersByType = () =>
  fetchGraphqlWrapper({
    query: getSamplesParametersByType,
    requestType: REQUEST_SAMPLE_PARAMETERS_BY_TYPE,
    onSuccess: fetchSampleParametersByTypeSuccess
  })
