import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import filter from 'lodash/fp/filter'
import curryRight from 'lodash/curryRight'
import { getConfigData } from './config'
import { getToken } from '../userStoredData'

const getForms = get('deliverablesForms')

export const getFormsData = (state, key) => pipe(getForms, get(key))(state)

export const getKeyForm = (state, form, key) => {
  const formSelected = getFormsData(state, form)
  return formSelected[key]
}

const curriedRightFormData = curryRight(getFormsData)

export const getFormDataFilterById = (state, keyToFilter, id) => {
  return pipe(curriedRightFormData(keyToFilter), filter(['id', id]))(state)
}

export const getRealtimeDeliverableUrlWithAuth = state => {
  const url = getConfigData(state, 'baseRealtimeDeliverableURL')
  const userToken = getToken()
  return `${url}?api_key=${userToken}`
}

export const getSelectedDeliverable = pipe(getForms, get('selectedDeliverable'))

const getSelectedDeliverableType = pipe(getSelectedDeliverable, get('type'))

export const getSelectedDeliverableBaseFileName = pipe(getSelectedDeliverableType, get('base_file_name'))
