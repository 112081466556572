import filter from 'lodash/fp/filter'
import flatten from 'lodash/fp/flatten'
import get from 'lodash/fp/get'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import reduce from 'lodash/fp/reduce'
import uniqBy from 'lodash/fp/uniqBy'
import values from 'lodash/fp/values'
import { getSelectedFlightGroupSeasonIds } from './flightGroups'
import toNumber from 'lodash/fp/toNumber'

export const getSeasonLayers = get('seasonLayers')

export const getLayersSeasonsIds = pipe(getSeasonLayers, keys, map(toNumber))

export const getLayersBySeasonId = (state, seasonId) => pipe(getSeasonLayers, get(seasonId))(state)

export const getActiveSeasonLayers = state => {
  const fieldSeasonIds = getSelectedFlightGroupSeasonIds(state)
  return reduce((accumulator, seasonId) => {
    const currentLayers = getLayersBySeasonId(state, seasonId)
    return currentLayers ? { ...accumulator, [seasonId]: currentLayers } : accumulator
  }, {})(fieldSeasonIds)
}

const getSeasonDeliverableTypesBySeasonId = (state, seasonId) => pipe(getSeasonLayers, get(seasonId), values, map('deliverable.deliverableType'))(state)

export const getFileGeneratorSeasonBaseLayersBySeasonId = (state, seasonId, key) => {
  const types = getSeasonDeliverableTypesBySeasonId(state, seasonId)
  return filter(type => type[key])(types)
}
export const getSelectedFieldsAvailableSeasonBaseLayers = (state, key) => {
  const layersSeasonsIds = getSelectedFlightGroupSeasonIds(state)
  const selectedFieldsDeliverableTypes = map(seasonId => getFileGeneratorSeasonBaseLayersBySeasonId(state, seasonId, key))(layersSeasonsIds)
  return pipe(flatten, uniqBy('base_file_name'))(selectedFieldsDeliverableTypes)
}

export const getSelectedFieldsAvailableSeasonBaseFileNames = state => {
  const seasonLayers = getActiveSeasonLayers(state)
  return reduce((accumulator, seasonId) => {
    return {
      ...accumulator,
      [seasonId]: map(get('deliverable.deliverableType.base_file_name'))(seasonLayers[seasonId])
    }
  }, {})(keys(seasonLayers))
}
