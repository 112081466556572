import React from 'react'
import PropTypes from 'prop-types'

import OLComponent from '../ol-component'
import OlMultiPolygon from 'ol/geom/MultiPolygon'
import OlMap from 'ol/Map'
import OlFeature from 'ol/Feature'
import OlGeometry from 'ol/geom/Geometry'

export default class MultiPolygon extends OLComponent {
  constructor(props) {
    super(props)
    this.geometry = new OlMultiPolygon([])
    this.updateFromProps(props)
    this.zoomLevel = 0
  }

  updateFromProps(props) {
    const { children, projection } = props
    // Check if coordinates are defined, are an array, and not empty
    if (children !== undefined && children !== null && Array.isArray(children) && children.length > 0) {
      this.geometry.setCoordinates(children)

      // Ensure projection and its properties are valid
      if (projection && projection.from && projection.to) {
        this.geometry.transform(projection.from, projection.to)
      } else {
        console.error('Invalid projection data: from/to fields are missing.')
      }
    } else {
      console.error('Invalid coordinates: children prop is missing or improperly structured.')
    }
  }

  componentDidMount() {
    this.context.feature.setGeometry(this.geometry)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateFromProps(newProps)
  }

  render() {
    return <div>{this.props.notification != null ? this.props.notification : ''}</div>
  }
  // eslint-disable-next-line lines-between-class-members
  getChildContext() {
    return {
      geometry: this.geometry,
      map: this.context.map
    }
  }
}

MultiPolygon.propTypes = {
  children: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)))).isRequired,
  notification: PropTypes.object,
  projection: PropTypes.object
}

MultiPolygon.contextTypes = {
  feature: PropTypes.instanceOf(OlFeature),
  map: PropTypes.instanceOf(OlMap)
}

MultiPolygon.childContextTypes = {
  geometry: PropTypes.instanceOf(OlGeometry),
  map: PropTypes.instanceOf(OlMap)
}
