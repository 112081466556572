import { connect } from 'react-redux'
import Login from '../components/Login'
import { compose } from 'redux'
import { fetchLogin, fetchProfile, fetchApiKeyWithOauthToken, REQUEST_PROFILE, REQUEST_LOGIN } from '../actions/users'
import { errorNotification } from '../actions/notifications'
import { getErrorLogin, getUserName, getUser } from '../selectors/users'
import { withTranslator } from '../components/HOCComponents/withTranslator'
import { hasRequestFail, areRequestsLoading } from '../selectors/ui'
import { getLocale } from '../selectors/i18n'

const mapStateToProps = state => ({
  error: getErrorLogin(state),
  isLoginLoading: areRequestsLoading(state, [REQUEST_LOGIN]),
  isProfileLoading: areRequestsLoading(state, [REQUEST_PROFILE]),
  isInvalidProfile: hasRequestFail(state, REQUEST_PROFILE),
  userName: getUserName(state),
  user: getUser(state),
  locale: getLocale(state)
})

const mapDispatchToProps = {
  fetchLogin,
  fetchProfile,
  errorNotification,
  fetchApiKeyWithOauthToken
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(Login)
