import { getDeliverableTypes } from './deliverables'

import {
  DELIVERABLE_FORMATS,
  DELIVERABLE_TYPE_PREFIXES,
  LANDSAT_SNAPSHOT_SOURCE,
  PLANET_SNAPSHOT_SOURCE,
  RADAR_ASC_SNAPSHOT_SOURCE,
  RADAR_DESC_SNAPSHOT_SOURCE,
  SAT_SNAPSHOT_SOURCE
} from '@layers-frontend/commons/constants'

import filter from 'lodash/fp/filter'
import get from 'lodash/fp/get'
import includes from 'lodash/fp/includes'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'
import pipe from 'lodash/fp/pipe'
import reduce from 'lodash/fp/reduce'

const { DYNAMIC_RANGE, STATIC_RANGE, DYNAMIC_URL, LANDSAT_PLANET_TEMP } = DELIVERABLE_FORMATS

/* eslint-disable dot-notation */
const RADAR_DELIVERABLE_TYPE_PREFIXES = DELIVERABLE_TYPE_PREFIXES['RADAR']
const PLANET_DELIVERABLE_TYPE_PREFIXES = DELIVERABLE_TYPE_PREFIXES['PLANET']
const LANDSAT_DELIVERABLE_TYPE_PREFIXES = DELIVERABLE_TYPE_PREFIXES['LANDSAT']
/* eslint-enable dot-notation */

const deliverablesTemplate = {
  [SAT_SNAPSHOT_SOURCE]: { deliverables: [], pdfDeliverables: [] },
  [RADAR_ASC_SNAPSHOT_SOURCE]: { deliverables: [], pdfDeliverables: [] },
  [RADAR_DESC_SNAPSHOT_SOURCE]: { deliverables: [], pdfDeliverables: [] },
  [LANDSAT_SNAPSHOT_SOURCE]: { deliverables: [], pdfDeliverables: [] },
  [PLANET_SNAPSHOT_SOURCE]: { deliverables: [], pdfDeliverables: [] }
}

const _normalizeDeliverablesBySource = allDeliverables => {
  return reduce((accumulator, key) => {
    const currentDeliverables = allDeliverables[key]

    return {
      ...accumulator,
      ...reduce((deliverablesAccumulator, deliverable) => {
        const baseFileName = get(['type', 'base_file_name'])(deliverable)
        switch (true) {
          case !includes(RADAR_DELIVERABLE_TYPE_PREFIXES[0], baseFileName) &&
            !includes(RADAR_DELIVERABLE_TYPE_PREFIXES[1], baseFileName) &&
            !includes(LANDSAT_DELIVERABLE_TYPE_PREFIXES[0], baseFileName) &&
            !includes(PLANET_DELIVERABLE_TYPE_PREFIXES[0], baseFileName):
            return {
              ...deliverablesAccumulator,
              [SAT_SNAPSHOT_SOURCE]: {
                ...deliverablesAccumulator[SAT_SNAPSHOT_SOURCE],
                [key]: [...deliverablesAccumulator[SAT_SNAPSHOT_SOURCE][key], deliverable]
              }
            }
          case includes(RADAR_DELIVERABLE_TYPE_PREFIXES[0], baseFileName) && includes('ASC', baseFileName):
            return {
              ...deliverablesAccumulator,
              [RADAR_ASC_SNAPSHOT_SOURCE]: {
                ...deliverablesAccumulator[RADAR_ASC_SNAPSHOT_SOURCE],
                [key]: [...deliverablesAccumulator[RADAR_ASC_SNAPSHOT_SOURCE][key], deliverable]
              }
            }
          case includes(RADAR_DELIVERABLE_TYPE_PREFIXES[0], baseFileName) && includes('DESC', baseFileName):
            return {
              ...deliverablesAccumulator,
              [RADAR_DESC_SNAPSHOT_SOURCE]: {
                ...deliverablesAccumulator[RADAR_DESC_SNAPSHOT_SOURCE],
                [key]: [...deliverablesAccumulator[RADAR_DESC_SNAPSHOT_SOURCE][key], deliverable]
              }
            }
          case includes(LANDSAT_DELIVERABLE_TYPE_PREFIXES[0], baseFileName):
            return {
              ...deliverablesAccumulator,
              [LANDSAT_SNAPSHOT_SOURCE]: {
                ...deliverablesAccumulator[LANDSAT_SNAPSHOT_SOURCE],
                [key]: [...deliverablesAccumulator[LANDSAT_SNAPSHOT_SOURCE][key], deliverable]
              }
            }
          case includes(PLANET_DELIVERABLE_TYPE_PREFIXES[0], baseFileName):
            return {
              ...deliverablesAccumulator,
              [PLANET_SNAPSHOT_SOURCE]: {
                ...deliverablesAccumulator[PLANET_SNAPSHOT_SOURCE],
                [key]: [...deliverablesAccumulator[PLANET_SNAPSHOT_SOURCE][key], deliverable]
              }
            }

          default:
            return deliverablesAccumulator
        }
      }, accumulator)(currentDeliverables)
    }
  }, deliverablesTemplate)(keys(allDeliverables))
}

const getCustomersDeliverables = get('customersDeliverables')

const getCustomerDeliverables = customerId => state => pipe(getCustomersDeliverables, get(customerId))(state)

export const getCustomerDeliverablesByFieldType = (customerId, fieldType) => state => {
  const deliverableTypes = getDeliverableTypes(state)
  return pipe(
    getCustomerDeliverables(customerId),
    get(fieldType),
    map(deliverable => {
      const typeId = get('typeId')(deliverable)
      const type = get(typeId)(deliverableTypes)
      return { ...deliverable, type }
    })
  )(state)
}

export const getFrontEndVisibilityDeliverables = (customerId, fieldType) => {
  return pipe(
    getCustomerDeliverablesByFieldType(customerId, fieldType),
    filter(deliverable => {
      const frontEndVisibility = get(['type', 'front_end_visibility'])(deliverable)
      const formatId = get(['type', 'format', 'id'])(deliverable)
      const hasFrontEndFormat = includes(formatId, [DYNAMIC_URL, DYNAMIC_RANGE, STATIC_RANGE, LANDSAT_PLANET_TEMP])
      return frontEndVisibility && hasFrontEndFormat
    })
  )
}

export const getDownloadVisibilityDeliverables = (customerId, fieldType) => {
  return pipe(
    getCustomerDeliverablesByFieldType(customerId, fieldType),
    filter(deliverable => get(['type', 'download_visibility'])(deliverable))
  )
}

export const getDeliverablesBySource = (customerId, fieldType) => state => {
  const deliverables = getFrontEndVisibilityDeliverables(customerId, fieldType)(state)
  const pdfDeliverables = getDownloadVisibilityDeliverables(customerId, fieldType)(state)
  const allDeliverables = { deliverables, pdfDeliverables }
  return _normalizeDeliverablesBySource(allDeliverables)
}
