import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import includes from 'lodash/fp/includes'
import some from 'lodash/fp/some'
import isEqual from 'lodash/fp/isEqual'
import { LANGUAGE, UNIT_SYSTEM, SURFACE_UNIT, USER_ROLES, DEFAULT_GLOBAL_MAP } from '@layers-frontend/commons/constants'

export const getUser = get('user')

export const getUserId = pipe(getUser, get('id'))

export const getUserName = pipe(getUser, get('username'))

export const getToken = pipe(getUser, get('token'))

export const getUserRoles = pipe(getUser, get('roles'))

export const hasUserRole = (state, role) => pipe(getUserRoles, includes(role))(state)

export const isUserCustomer = pipe(getUserRoles, includes(USER_ROLES.ROLE_FARMER))

export const isCooperativeCustomer = pipe(getUserRoles, includes(USER_ROLES.ROLE_COOPERATIVE))

export const isAgroCustomer = pipe(getUserRoles, includes(USER_ROLES.ROLE_AGRO))

export const hasRoleDemo = pipe(getUserRoles, includes(USER_ROLES.ROLE_DEMO))

export const hasRoleIrriga = pipe(getUserRoles, includes(USER_ROLES.ROLE_IRRIGA))

export const hasRolePlanet = pipe(getUserRoles, includes(USER_ROLES.ROLE_PLANET))

export const hasRoleOrderedFeatures = pipe(getUserRoles, includes(USER_ROLES.ROLE_ORDERED_FEATURES))

export const hasRoleSHCalendar = pipe(getUserRoles, includes(USER_ROLES.ROLE_SH_CALENDAR))

export const hasMobileControlActionDisabled = pipe(getUserRoles, includes(USER_ROLES.ROLE_DISABLED_MOBILE))

export const getUserConfig = pipe(getUser, get('config'))

export const getUserConfigLanguage = pipe(getUserConfig, get(LANGUAGE))

export const getUserConfigUnits = pipe(getUserConfig, get(UNIT_SYSTEM))

export const getUserConfigSurface = pipe(getUserConfig, get(SURFACE_UNIT))

export const getUserConfigGlobalMap = pipe(getUserConfig, get(DEFAULT_GLOBAL_MAP))

export const isMetric = pipe(getUserConfigUnits, isEqual('metric'))

export const hasUserSomeRole = rolesToTest =>
  pipe(
    getUserRoles,
    some(userRole => includes(userRole)(rolesToTest))
  )

export const getBiLabsUrl = pipe(getUser, get('bi_labs_url'))

export const getUserAzureState = state => get('user_azure')(state)

export const hasRoleDeactivateDashboard = pipe(getUserRoles, includes(USER_ROLES.ROLE_DEACTIVATE_DASHBOARD))
