import PropTypes from 'prop-types'

import OLComponent from './ol-component'
import { buildStyle } from './style'
import OlStyle from 'ol/style/Style'

import OlFeature from 'ol/Feature'
import OlMap from 'ol/Map'
import OlSource from 'ol/source/Source'
import OlCluster from 'ol/source/Cluster'

export default class Feature extends OLComponent {
  constructor(props) {
    super(props)
    this.feature = new OlFeature({})
    this.feature.setId(props.id)
    this.feature.set('field', props.field)
    this.updateFromProps(props)
  }

  updateFromProps(props) {
    this.feature.setStyle(buildStyle(props.style))
  }

  getChildContext() {
    return {
      feature: this.feature,
      map: this.context.map
    }
  }

  componentDidMount() {
    if (this.context.clusterSource) {
      this.context.clusterSource.addFeature(this.feature)
    } else {
      this.context.source.addFeature(this.feature)
    }
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateFromProps(newProps)
    this.feature.set('field', newProps.field)
  }

  componentWillUnmount() {
    this.context.source.removeFeature(this.feature)
  }
}

Feature.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  field: PropTypes.object,
  style: PropTypes.oneOfType([
    PropTypes.instanceOf(OlStyle),
    PropTypes.object,
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(OlStyle), PropTypes.object]))
  ]),
  id: PropTypes.any.isRequired
}

Feature.contextTypes = {
  clusterSource: PropTypes.instanceOf(OlCluster),
  source: PropTypes.instanceOf(OlSource),
  map: PropTypes.instanceOf(OlMap)
}

Feature.childContextTypes = {
  feature: PropTypes.instanceOf(OlFeature),
  map: PropTypes.instanceOf(OlMap)
}
