import React from 'react'
import styled from 'styled-components'
import SeasonTimelineSelector from './SeasonTimelineSelector'
import FieldDetailsSection from './FieldDetailsSection'

import themeColors from '@layers-frontend/commons/styles/themeColors'
import withTranslator from '../../HOCComponents/withTranslator'

const Wrapper = styled.div`
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${themeColors.whiteColor};
`

function FieldDetailsAndTimeline({ t, seasons, customers, onSeasonSelect, activeSeasonIndex, isReadOnly }) {
  return (
    <Wrapper>
      <FieldDetailsSection customers={customers} isReadOnly={isReadOnly} />
      <SeasonTimelineSelector seasons={seasons} onSeasonSelect={onSeasonSelect} activeSeasonIndex={activeSeasonIndex} />
    </Wrapper>
  )
}

export default withTranslator(FieldDetailsAndTimeline)
