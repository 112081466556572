import React from 'react'
import PropTypes from 'prop-types'

import SelectInt from '../olComponents/interaction/select'
import DoubleClick from '../olComponents/interaction/doubleClick'
import DragBox from '../olComponents/interaction/drag-box'

import Map from '../olComponents/map'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'
import OlGeoJSON from 'ol/format/GeoJSON'

import get from 'lodash/get'
import includes from 'lodash/includes'
import uniq from 'lodash/uniq'

export default class FieldSelector extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.formater = new OlGeoJSON()
  }

  getChildContext() {
    return {
      map: this.context.map
    }
  }

  render() {
    if (!this.props.isSelectActive) return null
    /*=============================================>>>>>
      = Select Interaction =
      ===============================================>>>>>*/
    const { selectedFieldsIds } = this.props

    /* eslint-disable prefer-const */
    let select = (
      <div>
        {' '}
        <SelectInt
          select={evt => {
            let field = evt.selected[0].get('field')
            let fieldId = get(field, 'fieldId') || get(field, 'id') || get(field, 'field_id') || get(field, 'gm_field_id') // checks both global layer and normal layers

            this.toggleFieldToGroup(fieldId)
          }}
        >
          {' '}
        </SelectInt>
        <DoubleClick
          select={evt => {
            let field = evt.selected[0].get('field')
            let fieldId = get(field, 'fieldId') || get(field, 'id') || get(field, 'field_id') || get(field, 'gm_field_id')
            /* eslint-enable prefer-const */
            this.props.addFieldsToGroup([fieldId])

            this.props.selectFields()
            evt.target.getFeatures().clear()
          }}
        >
          {' '}
        </DoubleClick>
        <DragBox
          boxend={extent => {
            const featuresLayer = Map.getLayerFromName('global_layer', this.context.map)
            const featuresLayerGeometries = Map.getLayerFromName('geometries', this.context.map)
            const fieldsIdsWithinDragBox = []
            if (featuresLayer) {
              featuresLayer.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                const fieldId =
                  get(feature.get('field'), 'fieldId') ||
                  get(feature.get('field'), 'id') ||
                  get(feature.get('field'), 'field_id') ||
                  get(feature.get('field'), 'gm_field_id')
                if (!includes(selectedFieldsIds, fieldId)) {
                  fieldsIdsWithinDragBox.push(fieldId)
                }
              })
            } else {
              featuresLayerGeometries.getSource().forEachFeatureIntersectingExtent(extent, feature => {
                const fieldId = get(feature.get('field'), 'fieldId') || get(feature.get('field'), 'id') || get(feature.get('field'), 'field_id')
                if (!includes(selectedFieldsIds, fieldId)) {
                  fieldsIdsWithinDragBox.push(fieldId)
                }
              })
            }
            const withoutDuplicates = uniq(fieldsIdsWithinDragBox)
            this.props.addFieldsToGroup(withoutDuplicates)
          }}
        />
      </div>
    )

    /*= End of Select Interaction =*/
    /*=============================================<<<<<*/
    return select
  }

  toggleFieldToGroup = featureId => {
    const { selectedFieldsIds, removeFieldToGroup, addFieldsToGroup } = this.props
    if (includes(selectedFieldsIds, featureId)) {
      removeFieldToGroup(featureId)
    } else {
      addFieldsToGroup([featureId])
    }
  }
}

FieldSelector.propTypes = {
  screenView: PropTypes.string,
  isSelectActive: PropTypes.bool,
  selectedFieldsIds: PropTypes.array,
  selectFields: PropTypes.func,
  addFieldsToGroup: PropTypes.func,
  removeFieldToGroup: PropTypes.func
}

FieldSelector.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

FieldSelector.contextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}
