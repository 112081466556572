import { connect } from 'react-redux'
import GlobalFilesModal from '../../components/ModalComponents/GlobalFilesModal'
import { fetchLayerClasses, downloadGlobalFile } from '../../actions/fileGenerator'
import { getUserId, getUserRoles } from '@layers-frontend/commons/store/selectors/user'
import { getGlobalFiles } from '@layers-frontend/commons/store/selectors/reports'

const mapStateToProps = state => ({
  userId: getUserId(state),
  globalFiles: getGlobalFiles(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = {
  fetchLayerClasses,
  downloadGlobalFile
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalFilesModal)
