import { FIELD_SEASON_FORM_KEYS as FORM_KEYS, NEW_SEASON_START_DATE, NEW_SEASON_END_DATE } from './constants'
import get from 'lodash/get'
import map from 'lodash/map'
import compact from 'lodash/compact'
import isString from 'lodash/isString'
import moment from 'moment'
import GeometryUtils from './geometryUtils'

export const reduxFieldToFormHook = field => {
  return {
    [FORM_KEYS.FIELD_ID]: get(field, 'id'),
    [FORM_KEYS.FIELD_NAME]: get(field, 'name'),
    [FORM_KEYS.CUSTOMER]: get(field, ['customer', 'id']),
    [FORM_KEYS.EXTERNAL_REFERENCE_ID]: get(field, 'externalReferenceId'),
    [FORM_KEYS.COMMENT]: get(field, 'comment')
  }
}

const formatDate = dateString => {
  if (isString(dateString) && moment(dateString).isValid()) {
    return moment(dateString).format('YYYY-MM-DD')
  }
  return ''
}

export const reduxSeasonToFormHook = season => {
  const seasonId = get(season, 'id')
  const label = get(season, 'label')
  const type = get(season, ['type', 'id'])
  const subType = get(season, ['subtype', 'id'])
  const startDate = get(season, 'startDate')
  const endDate = get(season, 'endDate')

  const parameters = get(season, 'parameters')
  const plantationDate = get(parameters, ['plantationDate', 'value'])
  const surface = get(season, 'surface') || 0

  return {
    [FORM_KEYS.SEASON_ID]: seasonId,
    [FORM_KEYS.SEASON_LABEL]: label,
    [FORM_KEYS.TYPE]: type,
    [FORM_KEYS.SUB_TYPE]: subType,
    [FORM_KEYS.PLANTATION_DATE]: formatDate(plantationDate),
    [FORM_KEYS.START_DATE]: formatDate(startDate),
    [FORM_KEYS.END_DATE]: formatDate(endDate),
    [FORM_KEYS.FEATURES]: undefined,
    [FORM_KEYS.SURFACE]: surface
  }
}

const emptySeason = {
  startDate: NEW_SEASON_START_DATE,
  endDate: NEW_SEASON_END_DATE,
  plantationDate: { value: null }
}

export const createReduxEmptySeason = () => emptySeason

export const formSeasonsFieldToRequest = field => {
  const fieldId = get(field, FORM_KEYS.FIELD_ID)
  const customer = get(field, FORM_KEYS.CUSTOMER)
  const name = get(field, FORM_KEYS.FIELD_NAME) || null
  const comment = get(field, FORM_KEYS.COMMENT) || null

  const formSeasons = compact(get(field, FORM_KEYS.SEASONS))
  const seasons = map(formSeasons, season => {
    const seasonId = get(season, FORM_KEYS.SEASON_ID)
    const startDate = get(season, FORM_KEYS.START_DATE)
    const endDate = get(season, FORM_KEYS.END_DATE)
    const label = get(season, FORM_KEYS.SEASON_LABEL) || null
    const plantationDate = get(season, FORM_KEYS.PLANTATION_DATE)
    const type = get(season, FORM_KEYS.TYPE)
    const subType = get(season, FORM_KEYS.SUB_TYPE)
    const surface = get(season, FORM_KEYS.SURFACE)
    const features = get(season, FORM_KEYS.FEATURES)

    // eslint-disable-next-line no-undef-init
    let geometry = undefined
    if (isString(features)) {
      const featuresArray = JSON.parse(features)
      const geometries = map(featuresArray, 'geometry')
      const olMultipolygon = GeometryUtils.geometriesToMultipolygon(geometries)
      geometry = JSON.stringify(olMultipolygon.getCoordinates())
    }

    const parameterValues = plantationDate
      ? [
          {
            parameter: 2,
            value: plantationDate
          }
        ]
      : undefined

    return {
      id: seasonId,
      startDate,
      endDate,
      label,
      type,
      subType,
      surface,
      parameterValues,
      geometry
    }
  })

  return {
    id: fieldId,
    name,
    customer,
    comment,
    seasons
  }
}

export const fieldResponseToReduxField = fieldFromResponse => ({
  id: get(fieldFromResponse, 'id'),
  name: get(fieldFromResponse, 'name'),
  comment: get(fieldFromResponse, 'comment', null),
  externalReferenceId: get(fieldFromResponse, 'external_reference_id', null),
  customer: get(fieldFromResponse, 'customer')
})

export const fieldResponseToReduxSeason = fieldFromResponse => {
  const fieldFromResponseSeasons = get(fieldFromResponse, 'seasons')
  const seasons = map(fieldFromResponseSeasons, season => {
    return {
      id: get(season, 'id'),
      fieldId: get(fieldFromResponse, 'id'),
      startDate: get(season, 'start_date'),
      endDate: get(season, 'end_date'),
      type: get(season, 'type'),
      subtype: get(season, 'sub_type'),
      surface: get(season, 'surface'),
      geometry: get(season, 'geometry'),
      parameters: get(season, 'parameter_values'),
      label: get(season, 'label')
    }
  })

  return seasons
}
