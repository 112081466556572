import { RECEIVE__SAMPLE_PARAMETERS_BY_TYPE } from '../storeConstants'

export default function sampleParametersByType(state = {}, action = {}) {
  switch (action.type) {
    case RECEIVE__SAMPLE_PARAMETERS_BY_TYPE:
      return action.sampleParametersByType

    default:
      return state
  }
}
