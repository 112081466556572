import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'

const getModal = get('modal')

export const isModalOpen = pipe(getModal, get('isOpen'))

export const isModalVisible = pipe(getModal, get('isVisible'))

export const getModalProps = pipe(getModal, get('props'))

export const getModalComponentName = pipe(getModal, get('componentName'))

export const getModalProp = (state, key) => pipe(getModalProps, get(key))(state)
