import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useFormContext, Controller } from 'react-hook-form'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { Button } from '../../UIComponents/StyledButtons'
import StyledAutoComplete from '../../UIComponents/StyledAutocomplete'
import StyledDatePicker from '../../UIComponents/Calendars/StyledDatePicker'

import get from 'lodash/get'
import find from 'lodash/find'
import some from 'lodash/some'
import capitalize from 'lodash/capitalize'
import StyledTextInput from '../../UIComponents/StyledTextInput'
import withTranslator from '../../HOCComponents/withTranslator'

import { FIELD_SEASON_FORM_KEYS as KEYS, REQUIRED_FORM_VALIDATION_RULE, NOT_REQUIRED_FORM_VALIDATION_RULE } from '../../../constants'
import { ButtonIcon } from '../../UIComponents/Form'

const Wrapper = styled.div``

const Inputs = styled.div`
  color: ${themeColors.whiteColor};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 3rem;
  justify-content: center;
  margin-bottom: 1.5rem;
`

const InputWrapper = styled.div`
  display: block;
`

const InputButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StyledDeleteButton = styled(ButtonIcon)`
  padding: 10px;
  margin: 5px;
`

const buttonStyle = {
  minWidth: 0,
  width: 40,
  height: 40,
  borderRadius: '50%'
}

function FieldSeasonFormBasic({
  t,
  fieldTypes,
  toggleFieldSeasonFormEditor,
  activeSeasonIndex,
  openEditModeWithCurrentGeometry,
  setIsOpenDeletingModal,
  isShowDeleteButton,
  isReadOnly
}) {
  const [subTypes, setSubtypes] = useState([])

  const { control, watch, setValue, getValues } = useFormContext()

  const SEASON_LABEL_KEY = `${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.SEASON_LABEL}`
  const SEASON_TYPE_KEY = `${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.TYPE}`
  const SEASON_SUB_TYPE_KEY = `${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.SUB_TYPE}`
  const SEASON_PLANTATION_DATE_KEY = `${KEYS.SEASONS}.${activeSeasonIndex}.${KEYS.PLANTATION_DATE}`

  const watchType = watch(SEASON_TYPE_KEY)

  useEffect(() => {
    const type = find(fieldTypes, ['id', watchType])
    const subTypes = get(type, 'field_sub_types', [])

    setSubtypes(subTypes)
    const previousSubType = getValues(SEASON_SUB_TYPE_KEY)
    const shouldResetSubType = !!previousSubType && !some(subTypes, ['id', previousSubType])
    if (shouldResetSubType) {
      setValue(SEASON_SUB_TYPE_KEY, null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType])

  return (
    <Wrapper>
      <Inputs>
        <InputWrapper>
          <Controller
            name={SEASON_LABEL_KEY}
            control={control}
            rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledTextInput
                disabled={isReadOnly}
                name={SEASON_LABEL_KEY}
                value={value}
                label="season name"
                helperText={error?.type}
                onChange={text => onChange(text)}
                activeSeasonIndex={activeSeasonIndex}
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={SEASON_TYPE_KEY}
            control={control}
            rules={REQUIRED_FORM_VALIDATION_RULE}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <StyledAutoComplete
                disabled={isReadOnly}
                label="Crop type"
                value={value}
                options={fieldTypes}
                errorText={error?.type}
                onChange={onChange}
                testId="crop-type-input"
              />
            )}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={SEASON_PLANTATION_DATE_KEY}
            control={control}
            rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
            render={({ field: { onChange, value } }) => {
              return <StyledDatePicker disabled={isReadOnly} value={value} label={t('Plantation date')} onChange={onChange} customStyleMargin="16px 0px 8px" />
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <Controller
            name={SEASON_SUB_TYPE_KEY}
            rules={NOT_REQUIRED_FORM_VALIDATION_RULE}
            control={control}
            render={({ field: { onChange, value } }) => {
              return <StyledAutoComplete disabled={isReadOnly} label="Crop type" value={value} options={subTypes} onChange={onChange} />
            }}
          />
        </InputWrapper>
      </Inputs>
      {!isReadOnly ? (
        <InputButtonWrapper>
          <Button
            icon={'ion-edit'}
            label={capitalize(t('geometry'))}
            background={themeColors.vomitColor}
            color={themeColors.darkBlue}
            testId="edit-geometry-button"
            onClick={() => {
              openEditModeWithCurrentGeometry()
              toggleFieldSeasonFormEditor()
            }}
          />
          {isShowDeleteButton && (
            <StyledDeleteButton style={buttonStyle} icon="fas fa-trash" testId="delete-field-button" onClick={() => setIsOpenDeletingModal(true)} />
          )}
        </InputButtonWrapper>
      ) : null}
    </Wrapper>
  )
}

export default withTranslator(FieldSeasonFormBasic)
