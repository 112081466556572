import React from 'react'
import styled from 'styled-components'
import AddSampleButton from './AddSampleButton'
import SampleFormContainer from '../../../containers/FieldsContainers/SampleFormContainers/SampleFormContainer'

const Wrapper = styled.div``

function SampleFormWrapper({ isSampleFormOpen, openNewSampleForm }) {
  return (
    <Wrapper>
      {!isSampleFormOpen && <AddSampleButton openNewSampleForm={openNewSampleForm} />}
      {isSampleFormOpen && <SampleFormContainer />}
    </Wrapper>
  )
}

export default SampleFormWrapper
