import SearchView from '../../components/SearchComponents/SearchView'

import { connect } from 'react-redux'
import { compose } from 'redux'
import { SEARCH_DELETED_VIEW, SEARCH_VIEW } from '../../actions/viewmode'

import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'

import { setLayerToolValue } from '../../actions/deliverables'

import includes from 'lodash/includes'
import { getSearchData } from '../../selectors/search'
import { getScreenView, isSearchDeletedView } from '../../selectors/viewmode'
import { getUserData } from '../../selectors/users'
import { getConfigData } from '../../selectors/config'
import { isModalOpen } from '../../selectors/modal'
import { getFieldCount } from '../../selectors/fieldsStatistics'

const mapStateToProps = state => {
  return {
    isAppReady: getConfigData(state, 'isAppReady'),
    isSearchView: includes([SEARCH_DELETED_VIEW, SEARCH_VIEW], getScreenView(state)),
    isFocus: getSearchData(state, 'focus'),
    hasDeleted: isSearchDeletedView(state),
    // eslint-disable-next-line no-extra-boolean-cast
    prospectLayers: !!getUserData(state, 'prospect_layers') ? getUserData(state, 'prospect_layers') : null,
    isModalOpen: isModalOpen(state),
    totalFieldCount: getFieldCount(state)
  }
}

const mapDispatchToProps = dispatch => ({
  onChangeOpacity: (layer, opacity) => dispatch(setLayerToolValue(layer, 'opacity', opacity)),
  onToggleLayer: layer => dispatch(setLayerToolValue(layer, 'opened', !layer.deliverable.opened)),
  onToggleVisibility: layer => dispatch(setLayerToolValue(layer, 'visible', !layer.deliverable.visible))
})

export default compose(withTranslator, withCurrentRoute, connect(mapStateToProps, mapDispatchToProps))(SearchView)
