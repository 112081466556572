import React from 'react'
import { Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const StyledSwitchComponent = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: themeColors.vomitColor,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

const SwitchContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

const StyledSwitch = props => {
  return (
    <SwitchContainer>
      <p>{props.label}</p>
      <StyledSwitchComponent {...props} />
    </SwitchContainer>
  )
}

export default StyledSwitch
