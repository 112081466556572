import React from 'react'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'
import DashboardLeftMenuTopContainer from '../../containers/DashboardContainers/DashboardLeftMenuTopContainer'
import LayersLeftMenuTopContainer from '../../containers/LayersLeftMenuTopContainer'

function LeftBarTop({ currentLocation }) {
  return (
    <div>
      {currentLocation.isDashboard && <DashboardLeftMenuTopContainer />}
      {currentLocation.isViewer && <LayersLeftMenuTopContainer />}
    </div>
  )
}

export default withCurrentRoute(LeftBarTop)
