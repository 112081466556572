import { connect } from 'react-redux'
import SeasonLayers from '../../components/LayersComponents/SeasonLayers'
import { getSelectedFieldsIds } from '../../selectors/fields'
import { getActiveSeasonLayers } from '../../selectors/seasonLayers'
import { getConfigProjection } from '../../selectors/config'
import { fetchSeasonLayersBySeasonIds } from '../../actions/seasonLayers'
import { getUISeasonLayers, getUISeasonLayersOrderMap, getSeasonLayersBaseZIndex } from '../../selectors/ui'
import { shouldShowSeasonLayers } from '../../selectors/viewmode'
import { getSelectedFlightGroupSeasonIds, getSelectedFlightGroupSeasons } from '../../selectors/flightGroups'

const mapState = state => ({
  uiSeasonLayers: getUISeasonLayers(state),
  selectedFlightGroupSeasons: getSelectedFlightGroupSeasons(state),
  selectedFieldsIds: getSelectedFieldsIds(state),
  selectedFlightGroupSeasonIds: getSelectedFlightGroupSeasonIds(state),
  seasonLayers: getActiveSeasonLayers(state),
  projection: getConfigProjection(state),
  seasonLayersOrderMap: getUISeasonLayersOrderMap(state),
  seasonLayersBaseZIndex: getSeasonLayersBaseZIndex(state),
  showSeasonLayers: shouldShowSeasonLayers(state)
})

const mapDispatch = {
  fetchSeasonLayersBySeasonIds
}

export default connect(mapState, mapDispatch)(SeasonLayers)
