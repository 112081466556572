import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PdfDeliverable from '../../components/DeliverablesComponents/PdfDeliverable'
import { getDeliveryS3Url } from '../../actions/deliverables'
import { getLanguage } from '../../selectors/i18n'

const mapStateToProps = state => {
  return {
    language: getLanguage(state)
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getDeliveryS3Url
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfDeliverable)
