import { fetchGraphqlWrapper } from './ui'
import { loader } from 'graphql.macro'

export const REQUEST_CUSTOMERS_DELIVERABLES = 'REQUEST_CUSTOMERS_DELIVERABLES'
export const RECEIVE_CUSTOMERS_DELIVERABLES = 'RECEIVE_CUSTOMERS_DELIVERABLES'

const getCustomersDeliverablesQuery = loader('../graphql/customers/getCustomersDeliverables.gql').loc.source.body
export const fetchCustomersDeliverables = () =>
  fetchGraphqlWrapper({
    requestType: REQUEST_CUSTOMERS_DELIVERABLES,
    query: getCustomersDeliverablesQuery,
    onSuccess: ({ customers, fieldTypeLayers }) => ({ type: RECEIVE_CUSTOMERS_DELIVERABLES, customers, fieldTypeLayers })
  })
