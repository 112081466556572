import { connect } from 'react-redux'
import { getLocale } from '../../selectors/i18n'

export default function withLocale(Component) {
  return connect(mapStateToProps)(Component)
}

const mapStateToProps = state => ({
  locale: getLocale(state)
})
