import React from 'react'
import PropTypes from 'prop-types'
import GeometryUtils from '../../geometryUtils'

import Draw from '../olComponents/interaction/draw'
import OlLineString from 'ol/geom/LineString'
import OlPolygon from 'ol/geom/Polygon'
import { unByKey } from 'ol/Observable'
import OlMap from 'ol/Map'
import OlVectorSource from 'ol/source/Vector'
import OlOverlay from 'ol/Overlay'
import { convertHectares } from '@layers-frontend/commons/conversions/index'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import { SURFACE_UNIT, SURFACE_UNIT_CONFIGURATION, UNIT_SYSTEM } from '@layers-frontend/commons/constants'

export default class MeasureTool extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.getStyle = this.getStyle.bind(this)

    this.listener = null
    this.measureTooltipElement = null
    this.measureTooltip = null
    this.sketch = null
  }

  getStyle() {
    return [
      {
        stroke: {
          color: 'rgba(255, 255, 255, 0.5)',
          lineDash: [10, 10],
          width: 2
        },
        fill: {
          color: 'rgba(255, 255, 255, 0.2)'
        },
        // RS is for regularshape
        image: {
          fill: {
            color: 'rgba(255, 255, 255, 0.2)'
          },
          points: 4,
          radius1: 15,
          radius2: 1,
          stroke: {
            color: 'rgba(0, 0, 0, 0.7)'
          }
        }
      }
    ]
  }

  componentDidMount() {
    this.createMeasureTooltip()
  }

  startMeasure = evt => {
    this.sketch = evt.feature

    /** @type {ol.Coordinate|undefined} */
    let tooltipCoord = evt.coordinate

    this.listener = this.sketch.getGeometry().on('change', evt => {
      // eslint-disable-next-line no-var
      var geom = evt.target
      // eslint-disable-next-line no-var
      var output
      const isMetric = isEqual(get(this.props.config, UNIT_SYSTEM), 'metric')
      const surfaceUnit = this.props.config[SURFACE_UNIT]
      const label = SURFACE_UNIT_CONFIGURATION.find(conf => conf.value === surfaceUnit).shortLabel

      // if it's distance
      if (geom instanceof OlLineString) {
        output = isMetric ? GeometryUtils.formatLength(geom) : GeometryUtils.formatImperialLength(geom)
        tooltipCoord = geom.getLastCoordinate()
      }
      // if it's areareact@
      if (geom instanceof OlPolygon) {
        output = `${convertHectares(GeometryUtils.getHectares(geom), surfaceUnit).toFixed(2)} ${label}`
        tooltipCoord = geom.getInteriorPoint().getCoordinates()
      }
      this.measureTooltipElement.innerHTML = output
      this.measureTooltip.setPosition(tooltipCoord)
    })
  }

  stopMeasure = evt => {
    /* eslint-disable prefer-const */
    let deleteElement = document.createElement('div')
    let deleteButton = document.createElement('i')

    deleteElement.className = 'measureDeleteButton'
    deleteButton.className = 'ion-android-delete'
    let feature = evt.feature
    let currentOverlay = this.measureTooltip
    /* eslint-enable prefer-const */

    this.measureTooltipElement.appendChild(deleteElement)
    this.measureTooltipElement.className = 'tooltip tooltip-static measure-static'
    this.measureTooltip.setOffset([0, -7])
    // unset sketch
    this.sketch = null

    deleteElement.addEventListener('click', () => {
      this.deleteMeasureTool(feature, currentOverlay)
    })

    deleteElement.appendChild(deleteButton)
    // unset tooltip so that a new one can be created
    this.measureTooltipElement = null
    this.createMeasureTooltip()
    unByKey(this.listener)
  }

  deleteMeasureTool = (feature, overlay) => {
    this.context.source.removeFeature(feature)
    this.context.map.removeOverlay(overlay)
  }

  createMeasureTooltip = () => {
    if (this.measureTooltipElement != null) {
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement)
    }
    this.measureTooltipElement = document.createElement('div')
    this.measureTooltipElement.className = 'tooltip tooltip-measure'
    this.measureTooltip = new OlOverlay({
      element: this.measureTooltipElement,
      offset: [0, -15],
      positioning: 'bottom-center'
    })
    this.context.map.addOverlay(this.measureTooltip)
  }

  getChildContext() {
    return {
      map: this.context.map,
      source: this.context.source
    }
  }

  render() {
    return <Draw drawstart={evt => this.startMeasure(evt)} drawend={evt => this.stopMeasure(evt)} style={this.getStyle()} type={this.props.type} />
  }
}

MeasureTool.propTypes = {
  type: PropTypes.string
}

MeasureTool.childContextTypes = {
  map: PropTypes.instanceOf(OlMap),
  source: PropTypes.instanceOf(OlVectorSource)
}

MeasureTool.contextTypes = {
  source: PropTypes.instanceOf(OlVectorSource),
  map: PropTypes.instanceOf(OlMap)
}
