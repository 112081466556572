import React from 'react'
import LayerVector from '../olComponents/layervector'
import SourceVector from '../olComponents/sourcevector'
import Feature from '../olComponents/feature'
import Point from '../olComponents/geom/Point'

import { Fill, Stroke, Style, Text } from 'ol/style'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import map from 'lodash/map'
import get from 'lodash/get'
import { SAMPLES_LAYER_Z_INDEX } from '../../constants'
import { markerStyle } from '../editorComponents/Editor'
import isEmpty from 'lodash/isEmpty'

/*=============================================>>>>>
= Cached styles =
===============================================>>>>>*/

// eslint-disable-next-line prefer-const
let vectorStyleCache = {}
export const samplesLayerStyleFunction = (feature, resolution) => {
  let style = {}
  const DEFAULT_MARKER_SIZE = 36
  const SELECTED_MARKER_SIZE = 42
  const isSelected = get(feature.get('field'), 'selected')
  const fillColor = isSelected ? themeColors.niceRedColor : themeColors.vomitColor
  const styleKey = isSelected ? 'selected' : 'deselected'
  const markerSize = isSelected ? SELECTED_MARKER_SIZE : DEFAULT_MARKER_SIZE

  // eslint-disable-next-line no-prototype-builtins
  if (!vectorStyleCache.hasOwnProperty(styleKey)) {
    const fill = new Fill({
      color: fillColor
    })

    const stroke = new Stroke({
      color: themeColors.black,
      width: 2
    })

    style = new Style({
      text: new Text({
        text: '\uf3c5',
        textAlign: 'center',
        font: `900 ${markerSize}px "Font Awesome 5 Free"`,
        textBaseline: 'bottom',
        // eslint-disable-next-line object-shorthand
        fill: fill,
        // eslint-disable-next-line object-shorthand
        stroke: stroke
      })
    })

    vectorStyleCache[styleKey] = style
  } else {
    style = vectorStyleCache[styleKey]
  }
  return style
}

/*= End of Cached styles =*/
/*=============================================<<<<<*/
export default function SamplesLayer({ samples, projection, showSamplesInMap, samplesFormLocation, isAddingMarker, samplesForm, selectedSample }) {
  const features = map(samples, sample => {
    const coordinates = [sample.lon, sample.lat]
    const isSelected = selectedSample && sample.id === selectedSample.id
    if (isSelected && !isAddingMarker) return null
    return (
      <Feature key={sample.id} id={sample.id} field={sample} style={isSelected ? markerStyle : samplesLayerStyleFunction}>
        <Point projection={projection}>{coordinates}</Point>
      </Feature>
    )
  })

  return (
    <LayerVector visible={showSamplesInMap} name={'SamplesLayer'} zIndex={SAMPLES_LAYER_Z_INDEX} opacity={1} key={'SamplesLayer'}>
      <SourceVector>
        {features}
        {!isEmpty(samplesFormLocation) && !isAddingMarker && (
          <Feature key="sampleFormLocationMarker" id="sampleFormLocationMarker" style={markerStyle}>
            <Point projection={projection}>{samplesFormLocation}</Point>
          </Feature>
        )}
      </SourceVector>
    </LayerVector>
  )
}
