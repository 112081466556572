import { connect } from 'react-redux'
import { compose } from 'redux'

import { withTranslator } from '../../../components/HOCComponents/withTranslator'

import MeteorologicalStatistisFieldSelect from '../../../components/DashboardComponents/DashboadFilters/MeteorologicalStatistisFieldSelect'

import { toggleDashFilter } from '../../../actions/dashboard'
import { REQUEST_FETCH_METEOROLOGICAL_API_DATA } from '../../../actions/meteorologicalData'

import { getMeteorologicalStatisticsSelectedFieldId } from '../../../selectors/dashboard'
import { isSelectedFieldsEmpty } from '../../../selectors/fields'
import { areRequestsLoading } from '../../../selectors/ui'

const mapStateToProps = state => ({
  meteorologicalStatisticsSelectedFieldId: getMeteorologicalStatisticsSelectedFieldId(state),
  isFetchingMeteorilogicalData: areRequestsLoading(state, REQUEST_FETCH_METEOROLOGICAL_API_DATA),
  isSelectedFieldsEmpty: isSelectedFieldsEmpty(state)
})

const mapDispatchToProps = {
  toggleDashFilter
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(MeteorologicalStatistisFieldSelect)
