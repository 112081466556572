import {
  SET_SAMPLE_FORM_FIELD,
  DENY_SAMPLE_LOCATION,
  EDIT_SAMPLE,
  RESET_SAMPLE_FORM_FIELDS,
  ADD_SAMPLE_FORM_EXTRA_PARAMETER,
  REMOVE_SAMPLE_FORM_EXTRA_PARAMETER,
  SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID,
  FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS
} from '../storeConstants'
import { sampleFormFields } from '../../constants'
import get from 'lodash/get'

const { SNAPSHOT_DATE, BASE_LAYER } = sampleFormFields

export const setFormField = (field, value) => ({ type: SET_SAMPLE_FORM_FIELD, field, value })

export const resetBaseLayerAndSnapshotDate = () => dispatch => {
  dispatch(setFormField(SNAPSHOT_DATE, undefined))
  dispatch(setFormField(BASE_LAYER, undefined))
}

export const denySampleLocation = () => (dispatch, getState) => {
  const state = getState()
  const originalSample = get(state, 'samplesForm.originalSample')
  const lon = get(originalSample, 'lon')
  const lat = get(originalSample, 'lat')
  const seasonId = get(originalSample, 'seasonId')
  const sampleId = get(originalSample, 'id')
  if (originalSample) {
    return dispatch({ type: DENY_SAMPLE_LOCATION, lon, lat, seasonId, sampleId })
  }
  return dispatch({ type: DENY_SAMPLE_LOCATION })
}

export const editSample = sample => ({ type: EDIT_SAMPLE, sample })

export const resetFormFields = () => ({ type: RESET_SAMPLE_FORM_FIELDS })

export const addSampleFormExtraParam = parameter => ({ type: ADD_SAMPLE_FORM_EXTRA_PARAMETER, parameter })

export const removeSampleFormExtraParam = parameterId => ({ type: REMOVE_SAMPLE_FORM_EXTRA_PARAMETER, parameterId })

export const flushSampleFormExtraParams = () => ({ type: FLUSH_SAMPLE_FORM_EXTRA_PARAMETERS })

export const setSampleFormExtraParamValueById = (parameterId, value) => ({ type: SET_SAMPLE_FORM_EXTRA_PARAM_VALUE_BY_ID, parameterId, value })
