import React from 'react'
import { baseTheme } from '../theme'
// https://medium.com/@adebalanced02/using-fontello-with-react-6bdc32b3c868
import '../assets/fontello/css/fontello.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { notAuthenticatedRoutes } from './routes'
import AuthenticatedAppContainer from '../containers/AuthenticatedAppContainer'
import NotifierContainer from '../containers/UIContainers/NotifierContainer'
import HeadTitleContainer from '../containers/UIContainers/HeadTitleContainer'
import LoadingPageViewContainer from '../containers/PagesContainers/LoadingPageViewContainer'
import DataManagerContainer from '../containers/DataManagerContainer'
import { ThemeProvider } from '@mui/material'
import { AnalyticsProvider } from './analyticsContext'

export default function App() {
  return (
    <ThemeProvider theme={baseTheme}>
      <BrowserRouter>
        <AnalyticsProvider>
          <DataManagerContainer />
          <NotifierContainer />
          <HeadTitleContainer />
          <LoadingPageViewContainer />
          <Routes>
            {notAuthenticatedRoutes.map(route => (
              <Route key={route.path} path={route.path} element={route.element} />
            ))}
            <Route path="/*" element={<AuthenticatedAppContainer />} />
          </Routes>
        </AnalyticsProvider>
      </BrowserRouter>
    </ThemeProvider>
  )
}
