import React from 'react'
import { compose } from 'redux'
import { withTranslator } from '../../../HOCComponents/withTranslator'
import { connectLocale } from '../../../HOCComponents/connectLocale'
import { DatePicker } from '@mui/x-date-pickers'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import moment from 'moment'

// used in plantation date range calendar & dashboard

const DatePickerCalendar = ({ minDate, maxDate, floatingLabelText, onChange, locale, name, value, defaultValue }) => {
  const checkValueIsMomentObject = date => (moment.isMoment(date) ? date : moment(date))

  return (
    <DatePicker
      locale={locale}
      label={floatingLabelText}
      onChange={onChange}
      name={name}
      minDate={moment(minDate || new Date(2017, 0, 1))}
      maxDate={moment(maxDate || new Date())}
      value={checkValueIsMomentObject(value)}
      defaultValue={checkValueIsMomentObject(defaultValue)}
      sx={{
        '& .MuiSvgIcon-root': {
          // calendar icon
          color: themeColors.whiteColor
        }
      }}
    />
  )
}

export default compose(withTranslator, connectLocale)(DatePickerCalendar)
