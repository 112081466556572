import { connect } from 'react-redux'
import { compose } from 'redux'
import { resetFieldsComparisonFilters } from '../../actions/dashboard'
import ConfirmChangeFieldsSelectionModal from '../../components/ModalComponents/ConfirmChangeFieldsSelectionModal'
import { addFieldsToGroup, flushFieldsGroup } from '../../actions/fields'
import { getSelectedFields } from '../../selectors/fields'

const mapStateToProps = state => ({
  selectedFields: getSelectedFields(state)
})

const mapDispatchToProps = {
  resetFieldsComparisonFilters,
  addFieldsToGroup,
  flushFieldsGroup
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ConfirmChangeFieldsSelectionModal)
