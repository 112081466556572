import { compose } from 'redux'
import { connect } from 'react-redux'

import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { getBiLabsUrl } from '@layers-frontend/commons/store/selectors/user'
import Iframe from '../../components/UIComponents/Iframe'

const mapStateToProps = state => ({
  url: getBiLabsUrl(state),
  title: 'BI Labs'
})

export default compose(withTranslator, connect(mapStateToProps))(Iframe)
