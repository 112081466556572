import filter from 'lodash/filter'
import includes from 'lodash/includes'
import forEach from 'lodash/forEach'
import findIndex from 'lodash/findIndex'
import { FILETYPE } from '@layers-frontend/commons/constants'
import { PREDICTIVE_REPORTS_CATEGORIES } from '../../../constants'

/**
 * Filters an array of file paths to include only items ending with '.pdf' or '.csv'.
 *
 * @param {string[]} array - The input array containing file paths.
 * @returns {string[]} - An array containing only items ending with '.pdf' or '.csv'.
 */
const filterPdfCsvFiles = array => {
  const { CSV, PDF } = FILETYPE
  const allowedExtensions = [`.${PDF}`, `.${CSV}`]
  return filter(array, item => {
    const fileExtension = item.slice(-4)
    return includes(allowedExtensions, fileExtension)
  })
}

/**
 * Filters an array of file paths to include only items ending with '.pdf' or '.csv'.
 *
 * @param {string} inputString - The file path
 * @param {boolean} [time=false] - When time is false, the function filters by date regex, else - by time
 * @returns {string} - Returns date or time
 */

function extractDateOrTimeFromPath(inputString, time = false) {
  const dateRegex = !time ? /(\d{4}-\d{2}-\d{2})/ : /((\d{2}:\d{2}))/ // Regular expression for matching YYYY-MM-DD format or MM:SS format for time
  const match = inputString.match(dateRegex)
  const result = match ? match[1] : null
  return result
}

/**
 * Filters an array of file paths to include only items ending with '.pdf' or '.csv'.
 *
 * @param {{}} data - The object with PREDICTIVE_REPORTS_CATEGORIES keys
 * @returns {{}} - Returns the objects with paths sorted by the newest (date & time) inside the categories
 */
function sortReportsByDate(data) {
  const { FORECAST, REPORT, AUXILIARY } = PREDICTIVE_REPORTS_CATEGORIES
  const categories = [FORECAST, REPORT, AUXILIARY]

  forEach(categories, category => {
    if (data[category]) {
      data[category].sort((a, b) => {
        const dateA = new Date(`${a.date}T${a.time || '00:00'}`)
        const dateB = new Date(`${b.date}T${b.time || '00:00'}`)
        return dateB - dateA
      })
    }
  })
  return data
}

/**
 * Removes categories with empty reports and data arrays from the input object
 *
 * @param {Object} json - The input object containing PREDICTIVE_REPORTS_CATEGORIES categories with arrays.
 * @returns {Object} - A new object with categories removed if PREDICTIVE_REPORTS_CATEGORIES values are empty.
 */

function removeEmptyCategories(json) {
  const filtered = Object.fromEntries(Object.entries(json).filter(([key, value]) => value.length > 0))
  return filtered
}

/**
 * Organizes and formats the data based on provided user ID.
 *
 * @param {string[]} data - The array of file paths.
 * @param {string} userId - The user ID.
 * @returns {Object} - An object containing organized data.
 */

export function organizeData(data, userId) {
  const { FORECAST, REPORT, AUXILIARY } = PREDICTIVE_REPORTS_CATEGORIES

  const cleanedJson = {
    [FORECAST]: [],
    [REPORT]: [],
    [AUXILIARY]: []
  }
  const { CSV, PDF } = FILETYPE
  const pathsArray = filterPdfCsvFiles(data)

  forEach(pathsArray, item => {
    if (includes(item, ' ')) {
      return // Skip items with a space in the path
    }
    // Extract variable, crop, and fileName from the item based on the userId
    const parts = item.split('/')
    const userIdIndex = findIndex(parts, part => part.startsWith(`${userId}_`))
    if (userIdIndex === -1) {
      return // Skip items without the specified userId
    }
    // eslint-disable-next-line no-unused-vars
    const [_, variable, crop] = parts[userIdIndex].split('_')
    const fileName = parts[parts.length - 1]

    // Extract date and time
    const date = extractDateOrTimeFromPath(item) // Extract the date part
    const time = extractDateOrTimeFromPath(item, true) // Extract the time part if available, otherwise set it to null

    // Extract the file type (csv or pdf) based on the item's extension
    const fileType = item.endsWith(`.${CSV}`) ? CSV : PDF

    // Extract the category (preprocessing, reports, or auxiliary files) based on the item's link
    let category

    if (fileType === PDF) {
      category = REPORT
    } else if (fileType === CSV && includes(item, FORECAST) && !includes(item, 'forecast_summary')) {
      category = FORECAST
    } else category = AUXILIARY

    // Add the item to the appropriate category and type (reports or data)
    const newItem = {
      payload: {
        s3_url: `s3://${item}`
      },
      fileName,
      variable,
      crop,
      date,
      time
    }

    cleanedJson[category].push(newItem)
  })

  const cleanedJsonWithoutEmptyCategories = removeEmptyCategories(cleanedJson)
  // sort the json by recent date and time
  return sortReportsByDate(cleanedJsonWithoutEmptyCategories)
}
