import { REQUEST_START, REQUEST_END, REQUEST_FAIL, REQUEST_NOT_AUTHORIZED_BY_ROLE } from '../storeConstants'

export const setRequestStart = (requestType, overlay = true, item) => ({
  type: REQUEST_START,
  requestType,
  overlay,
  item
})

export const setRequestEnd = (requestType, item) => ({
  type: REQUEST_END,
  requestType,
  item
})

export const setRequestFail = (requestType, item) => ({
  type: REQUEST_FAIL,
  requestType,
  item
})

export const requestNotAuthorizedByRole = (requestType, missingUserRole) => ({
  type: REQUEST_NOT_AUTHORIZED_BY_ROLE,
  requestType,
  missingUserRole
})
