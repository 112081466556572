import React, { useEffect, useCallback, useMemo } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts'
import { sigma0ToVegGrowth } from '../../dashboardUtils'
import moment from 'moment'
import map from 'lodash/map'
import some from 'lodash/some'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import times from 'lodash/times'
import isFinite from 'lodash/isFinite'
import lte from 'lodash/lte'
import debounce from 'lodash/debounce'
import { UIStyles } from '../../theme'
import { Column, BoxContainer, FullSizeContainer, Row, FullWidthColumn, ChartTitle, TabTitle } from './Layout'
import SwitchLayoutIcon from '../../containers/DashboardContainers/SwitchLayoutIconContainer'
import TemporalEvolutionMaxCloudCoverageSliderContainer from '../../containers/DashboardContainers/DashboadFiltersContainers/TemporalEvolutionMaxCloudCoverageSliderContainer'
import styled from 'styled-components'
import createSeasonEvolutionTooltip from './SeasonEvolution/createSeasonEvolutionTooltip'

import { GRID_LAYOUT } from '../../actions/ui'

import { colorArray } from './colorArray'
import { FETCH_STATISTICS_DEBOUNCE_TIME, L9_TYPE, PLANET_TYPE, SATELLITE_TYPE, RADAR_DESC_TYPE } from '../../constants'

import Loader from '../Loader/Loader'

const CloudCoverageColumn = styled(Column)`
  flex: 1;
  margin-top: -5px;
  position: relative;
`

const CloudCoverageRow = styled(Row)`
  align-items: center;
  margin-bottom: 10px;
`

export default function TemporalEvolutionCharts({
  t,
  isLoading,
  selectedFlightDateRange,
  chartsLayout,
  temporalEvolutionMaxCloudCoverage,
  fetchTemporalEvolutionStatistics,
  normalizedTemporalEvolution,
  selectedFieldsIds
}) {
  const isGridLayout = chartsLayout === GRID_LAYOUT
  const chartsWidth = isGridLayout ? '50%' : '100%'

  const { from, to } = selectedFlightDateRange

  const selectedFrom = moment(from)
  const selectedTo = moment(to)
  const startDate = selectedFrom.clone()

  const daysDifference = selectedTo.diff(selectedFrom, 'days')

  const areFieldsSelected = get(selectedFieldsIds, 'length') > 0

  const datesArray = selectedFieldsIds.length ? times(daysDifference, () => ({ chartDate: startDate.add(1, 'days').format('DD/MM/YYYY') })) : []

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetchTemporalEvolutionStatistics = useCallback(debounce(fetchTemporalEvolutionStatistics, FETCH_STATISTICS_DEBOUNCE_TIME), [])

  useEffect(() => {
    debouncedFetchTemporalEvolutionStatistics({ fieldIds: selectedFieldsIds, from: selectedFrom.format('YYYY-MM-DD'), to: selectedTo.format('YYYY-MM-DD') })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFieldsIds.length])

  const getTooltipData = (payload, data) => {
    return map(payload, item => {
      const currentField = item
      const fieldName = get(currentField, 'name') // same as field_id
      const value = get(item, 'value').toFixed('2')
      const color = get(item, 'color')

      function getCustomerNameByFieldId(data, fieldId) {
        if (Object.hasOwn(data, fieldId)) {
          const fieldData = data[fieldId]
          for (const key in fieldData) {
            if (Object.hasOwn(fieldData[key], 'field_name')) {
              if (fieldData[key].field_name !== '') return fieldData[key].field_name
              else if (fieldData[key].customer_name && fieldData[key].customer_name !== '') return fieldData[key].customer_name
              else return t('nameless_field')
            }
          }
        }
      }

      const label = getCustomerNameByFieldId(data, fieldName)
      return { label, value, color }
    })
  }

  const createChart = ({ title, indexToShow, data, formater, shouldRender, key }) => {
    if (!shouldRender) return
    const keysToDisplay = selectedFieldsIds
    const radarCloudCoverageFallback = 0

    const hasIndexToShow = some(keysToDisplay, key => !isNil(get(data, key)))
    if (!hasIndexToShow) return null

    const getFieldData = fieldId => ({ chartDate }) => {
      const fieldData = get(data, fieldId)
      const dataFromCurrentDate = get(fieldData, moment(chartDate, 'DD/MM/YYYY').format('YYYYMMDD'))
      const rawValueToShow = get(dataFromCurrentDate, indexToShow, null)
      const valueToShow = !formater ? rawValueToShow : formater(rawValueToShow)
      const currentCloudCoverage = Number(get(dataFromCurrentDate, 'cloud_coverage', radarCloudCoverageFallback))
      const validCloudCoverage = lte(currentCloudCoverage, temporalEvolutionMaxCloudCoverage)

      return validCloudCoverage ? valueToShow : null
    }

    const hasValues = some(keysToDisplay, fieldId =>
      some(datesArray, ({ chartDate }) => {
        return isFinite(getFieldData(fieldId)({ chartDate }))
      })
    )

    if (!hasValues) {
      return null
    }

    return (
      <Column marginBottom="30px" width={chartsWidth} transition="0.3s ease-in-out" key={key}>
        <BoxContainer>
          <ChartTitle>{t(title)}</ChartTitle>
          <ResponsiveContainer width="100%" height={350}>
            <LineChart fill={UIStyles.lightBlue} height={4500} data={datesArray} margin={{ top: 20, bottom: 0, left: 0, right: 20 }}>
              <XAxis stroke="#ffffff" dataKey="chartDate" />
              <YAxis stroke="#ffffff" type="number" interval={0} />
              <CartesianGrid strokeDasharray="3 3" stroke={UIStyles.lightBlue} fill="rgba(255,255,255,0.08)" />
              {createSeasonEvolutionTooltip({
                getTitle: payload => {
                  return get(payload[0], ['payload', 'chartDate'])
                },
                getData: payload => {
                  return getTooltipData(payload, data)
                }
              })}
              {map(keysToDisplay, (fieldId, index) => (
                <Line
                  key={fieldId}
                  name={fieldId}
                  dataKey={getFieldData(fieldId)}
                  type="basis"
                  connectNulls
                  stroke={colorArray[index]}
                  strokeWidth={2}
                  isAnimationActive={false}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>
        </BoxContainer>
      </Column>
    )
  }

  const noDataAvailable =
    normalizedTemporalEvolution[PLANET_TYPE] === undefined &&
    normalizedTemporalEvolution[SATELLITE_TYPE] === undefined &&
    normalizedTemporalEvolution[L9_TYPE] === undefined &&
    normalizedTemporalEvolution[RADAR_DESC_TYPE] === undefined

  const noNdviDataAvailable =
    normalizedTemporalEvolution[PLANET_TYPE] === undefined &&
    normalizedTemporalEvolution[SATELLITE_TYPE] === undefined &&
    normalizedTemporalEvolution[L9_TYPE] === undefined

  // merge ndvi_mean values for sources 3, 10, 11
  function uniteDataByFieldsAndDates(data) {
    const unitedData = {}

    // iterate over sources
    for (const source in data) {
      // iterate over field IDs
      for (const fieldId in data[source]) {
        // if the field ID doesn't exist in unitedData, initialize it
        if (!(fieldId in unitedData)) {
          unitedData[fieldId] = {}
        }
        // Iterate over dates
        for (const date in data[source][fieldId]) {
          const entry = data[source][fieldId][date]

          // If the date doesn't exist for the field, add it
          if (!(date in unitedData[fieldId])) {
            unitedData[fieldId][date] = entry
          } else {
            // Rule 1: If only one SOURCE is present, return it
            if (Object.keys(unitedData[fieldId][date]).length === 1) {
              unitedData[fieldId][date] = entry
            } //  Check if ndvi_mean is not null ?
            else {
              // Rule 2: Return the one with the LEAST cloud coverage
              if (entry.cloud_coverage < unitedData[fieldId][date].cloud_coverage) {
                unitedData[fieldId][date] = entry
              } else if (entry.cloud_coverage === unitedData[fieldId][date].cloud_coverage) {
                // Rule 3: If all have the same cloud coverage, return source type 3, then 10, then 11
                if (source === +SATELLITE_TYPE && unitedData[fieldId][date].source !== +SATELLITE_TYPE) {
                  unitedData[fieldId][date] = entry
                } else if (
                  source === +PLANET_TYPE &&
                  unitedData[fieldId][date].source !== +SATELLITE_TYPE &&
                  unitedData[fieldId][date].source !== +PLANET_TYPE
                ) {
                  unitedData[fieldId][date] = entry
                } else if (
                  source === +L9_TYPE &&
                  unitedData[fieldId][date].source !== +SATELLITE_TYPE &&
                  unitedData[fieldId][date].source !== +PLANET_TYPE &&
                  unitedData[fieldId][date].source !== +L9_TYPE
                ) {
                  unitedData[fieldId][date] = entry
                }
              }
            }
          }
        }
      }
    }
    return unitedData
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const unitedData = useMemo(() => uniteDataByFieldsAndDates(normalizedTemporalEvolution), [normalizedTemporalEvolution, noDataAvailable])

  /* source 3,10,11:
    "ndvi_mean", "ndvi_std", "ndre_mean", "moisture_mean", "nitrogen_relative_mean","potassium_relative_mean","phosphorus_relative_mean"
  */

  /* source 9:
    "mean_sigma0", "std_sigma0"
  */

  /* all sources share: cloud_coverage, customer_name, field_id, field_name, flight_date, source */

  const drawCharts = (noDataAvailable, noNdviDataAvailable) => {
    if (noDataAvailable) {
      return <ChartTitle>{t('no data with current selection')}</ChartTitle>
    }
    const charts = [
      { title: 'NDVI', indexToShow: 'ndvi_mean', data: unitedData, formater: null, shouldRender: !noNdviDataAvailable },
      {
        title: 'Variabilidad',
        indexToShow: 'ndvi_std',
        formater: null,
        data: normalizedTemporalEvolution[SATELLITE_TYPE],
        shouldRender: normalizedTemporalEvolution[SATELLITE_TYPE] !== undefined
      },
      {
        title: 'nitrógeno-clorofila (ndre)',
        indexToShow: 'ndre_mean',
        data: normalizedTemporalEvolution[SATELLITE_TYPE],
        formater: null,
        shouldRender: normalizedTemporalEvolution[SATELLITE_TYPE] !== undefined
      },
      {
        title: 'Nitrogen',
        indexToShow: 'nitrogen_relative_mean',
        data: normalizedTemporalEvolution[SATELLITE_TYPE],
        formater: null,
        shouldRender: normalizedTemporalEvolution[SATELLITE_TYPE] !== undefined
      },
      {
        title: 'hydric status',
        indexToShow: 'moisture_mean',
        data: normalizedTemporalEvolution[SATELLITE_TYPE],
        formater: null,
        shouldRender: normalizedTemporalEvolution[SATELLITE_TYPE] !== undefined
      },
      {
        title: 'vegetative growth',
        indexToShow: 'mean_sigma0',
        data: normalizedTemporalEvolution[RADAR_DESC_TYPE],
        formater: sigma0ToVegGrowth,
        shouldRender: normalizedTemporalEvolution[RADAR_DESC_TYPE] !== undefined
      },
      {
        title: 'vegetative growth variability',
        indexToShow: 'std_sigma0',
        data: normalizedTemporalEvolution[RADAR_DESC_TYPE],
        formater: null,
        shouldRender: normalizedTemporalEvolution[RADAR_DESC_TYPE] !== undefined
      }
    ]

    return (
      <Row>
        {charts.map(({ title, indexToShow, formater, data, shouldRender, index }) =>
          createChart({ title, indexToShow, data, formater, shouldRender, key: index })
        )}
      </Row>
    )
  }

  return (
    <FullSizeContainer>
      <Loader zIndex={1} backgroundColor={'rgba(0,0,0, 0.3)'} isLoading={isLoading} />

      <Row>
        <FullWidthColumn>
          <CloudCoverageRow>
            <Column width="auto">
              <TabTitle>{t('FIELD_TEMPORAL_EVOLUTION')}</TabTitle>
            </Column>
            {areFieldsSelected ? (
              <>
                <CloudCoverageColumn>
                  <TemporalEvolutionMaxCloudCoverageSliderContainer />
                </CloudCoverageColumn>
                <Column width="auto">
                  <SwitchLayoutIcon />
                </Column>
              </>
            ) : null}
          </CloudCoverageRow>
          {areFieldsSelected ? drawCharts(noDataAvailable, noNdviDataAvailable) : <ChartTitle>{t('select at least one field')}</ChartTitle>}
        </FullWidthColumn>
      </Row>
    </FullSizeContainer>
  )
}
