import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import sortBy from 'lodash/fp/sortBy'
import filter from 'lodash/fp/filter'
import uniqBy from 'lodash/uniqBy'
import { plantsMetadataKeys } from '../constants'
import { getHarvestPlanningToolSelectedMetadataKey } from './dashboard'
import isEmpty from 'lodash/isEmpty'

const { ATR, POL, DRY_MATTER } = plantsMetadataKeys

const SAMPLER_TYPE_BY_METADATA_KEY = {
  [ATR]: `Quality.${ATR}`,
  [POL]: `Quality.${POL}`,
  [DRY_MATTER]: `Production.${DRY_MATTER}`
}

export const getPlants = get('plants')

export const getPlantsByType = (state, type) => pipe(getPlants, get(type))(state)

export const getProductionTypePlants = state => getPlantsByType(state, 'Production')

export const getFieldsWithMetadataKey = state => {
  const selectedMetadataKey = getHarvestPlanningToolSelectedMetadataKey(state)

  const typeSampler = SAMPLER_TYPE_BY_METADATA_KEY[selectedMetadataKey]
  const plantsByType = getPlantsByType(state, typeSampler)
  const plantsSortedByDateAndWithMetadataKey = {}

  for (const fieldId in plantsByType) {
    // eslint-disable-next-line no-prototype-builtins
    if (plantsByType.hasOwnProperty(fieldId)) {
      const withMetadataKey = filter(plant => !isEmpty(plant[selectedMetadataKey]))(plantsByType[fieldId])
      const sortedByDate = sortBy('sample_creation_date')(withMetadataKey)
      plantsSortedByDateAndWithMetadataKey[fieldId] = sortedByDate
    }
  }

  return plantsSortedByDateAndWithMetadataKey
}

export const getFieldsPlantsWithMoreThanOnePlant = state => {
  const fieldsPlants = getFieldsWithMetadataKey(state)

  const fieldsWithMoreThanOne = {}

  for (const fieldId in fieldsPlants) {
    // eslint-disable-next-line no-prototype-builtins
    if (fieldsPlants.hasOwnProperty(fieldId)) {
      const hasDates = get('length')(uniqBy(fieldsPlants[fieldId], 'formattedCreationDate')) > 1

      if (hasDates) {
        const fieldWithTwoLastPlants = fieldsPlants[fieldId].slice(-2)
        fieldsWithMoreThanOne[fieldId] = fieldWithTwoLastPlants
      }
    }
  }

  return fieldsWithMoreThanOne
}
