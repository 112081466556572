import { closeEditMode, closeCreationMode } from './viewmode'
import { toggleMeasureTool } from './editor'
import '../utils'
import moment from 'moment'
import { advancedSearch } from '../constants.js'

import { openPlantationDateFilterModal } from './modal'

import get from 'lodash/get'
import { isFieldEditView, isFieldNewView } from '../selectors/viewmode'
import { isEditorMeasureToolActive } from '../selectors/editor'
import { isSearchOpen } from '../selectors/search'
import toString from 'lodash/toString'

const { PLANTATION_RANGE_FILTER, TYPE_FILTER, CUSTOMER_FILTER } = advancedSearch

export const TOGGLE_SEARCH_BAR = 'TOGGLE_SEARCH_BAR'
export const TYPE_SEARCH_TEXT = 'TYPE_SEARCH_TEXT'
export const DELETE_SEARCH_TEXT = 'DELETE_SEARCH_TEXT'
export const SELECT_SEARCH_ITEM = 'SELECT_SEARCH_ITEM'
export const TOGGLE_FOCUS_SEARCH_BAR = 'TOGGLE_FOCUS_SEARCH_BAR'
export const TOGGLE_ADVANCED_SEARCH = 'TOGGLE_ADVANCED_SEARCH'
export const OPEN_FILTER = 'OPEN_FILTER'
export const SELECT_FILTER = 'SELECT_FILTER'
export const ASCENDENT = 'asc'
export const DESCENDENT = 'desc'
export const TOGGLE_SEARCH_SORT_BY = 'TOGGLE_SEARCH_SORT_BY'
export const SET_SEARCH_OPTIONS = 'SET_SEARCH_OPTIONS'
export const UPDATE_SEARCH_LIST = 'ADD_SEARCH_LIST'

/*define filters here
		have the name of the attribute/filter as a key
		it contains an object with two functions
		the first is the element to push in the filters Array: for type we push field.season.type in our searchList Array
		the second is a function filter used to avoid pushing duplicates in the specific search filter array */

export const SearchFilters = {
  [TYPE_FILTER]: {
    getElement: field => get(field, ['season', 'type']),
    filter: (element, field) => element.id === get(field, ['season', 'type', 'id']),
    sortBy: 'name'
  },
  [CUSTOMER_FILTER]: {
    getElement: field => field.customer,
    filter: (element, field) => element.id === field.customer.id,
    sortBy: 'name'
  },
  [PLANTATION_RANGE_FILTER]: {
    getElement: field => ({
      name: moment(get(field, ['season', 'parameters', 'plantationDate', 'value'])).format('YYYY'),
      id: moment(get(field, ['season', 'parameters', 'plantationDate', 'value'])).format('YYYY')
    }),
    filter: (element, field) => element.id === moment(get(field, ['season', 'parameters', 'plantationDate', 'value'])).format('YYYY'),
    sortBy: 'id'
  },
  [PLANTATION_RANGE_FILTER]: {
    getElement: field => ({ name: get(field, 'name'), id: field.id }),
    filter: (element, field) => element.id === field.id,
    sortBy: 'name'
  }
}

/**
 * this function keep updated other filters
 * If I select one, the other ones are updated and show you only available filters based on the other selected ones
 * ( advanced search )
 * @param {*} selectedFilters
 * @param {*} field
 * @param {*} currentFilter
 */
export const matchFilters = (selectedFilters, field, currentFilter) => {
  let match = true

  // eslint-disable-next-line prefer-const
  for (let filter in selectedFilters) {
    /* We want to be able to select all available filter if the currentone is selected :
			I select 'cebadas' as 'cultivo'. I want to be able to change to another type of field */
    if (toString(filter) !== toString(currentFilter) && toString(filter) !== 'selected' && !selectedFilters[filter].isNullObject()) {
      match = match && SearchFilters[filter].filter(selectedFilters[filter], field)
    }
  }

  return match
}

/**
 * check if there is at least one selected Filter
 * @param {*} filters
 */
export const hasFilters = filters => {
  // eslint-disable-next-line prefer-const
  for (let filter in filters) {
    if (filters[filter] !== null && !filters[filter].isNullObject()) return true
  }
  return false
}

export const typeSearchText = text => ({
  type: TYPE_SEARCH_TEXT,
  payload: text
})

export const deleteSearchText = () => ({
  type: DELETE_SEARCH_TEXT
})

export const toggleFocusSearch = isFocus => ({
  type: TOGGLE_FOCUS_SEARCH_BAR,
  payload: isFocus
})

export const openSearchFilters = () => toggleFocusSearch(true)

export const closeSearchFilters = () => toggleFocusSearch(false)

export const toggleSearchSortBy = () => ({
  type: TOGGLE_SEARCH_SORT_BY
})

export const setSearchOptions = searchOptions => ({
  type: SET_SEARCH_OPTIONS,
  searchOptions
})

/**
 * function used to toggle the focus of search bar ( UI concerns )
 * @param {*} isFocus
 */
export const toggleFocusSearchBar = isFocus => (dispatch, getState) => {
  const state = getState()
  // eslint-disable-next-line prefer-const
  let isMeasuresTools = isEditorMeasureToolActive(state)

  if (isFieldEditView(state) && isFocus) {
    dispatch(closeEditMode())
  }

  if (isFieldNewView(state) && isFocus) {
    dispatch(closeCreationMode())
  }

  if (isMeasuresTools) {
    dispatch(toggleMeasureTool())
  }

  return dispatch(toggleFocusSearch(isFocus))
}

export const toggleSearch = () => ({
  type: TOGGLE_SEARCH_BAR
})

/**
 * Function used to navigate between screens
 */
export const toggleSearchBar = () => (dispatch, getState) => {
  const state = getState()
  // eslint-disable-next-line prefer-const
  let isMeasuresTools = isEditorMeasureToolActive(state)
  // eslint-disable-next-line prefer-const
  let isOpen = isSearchOpen(state)

  if (isFieldEditView(state) && !isOpen) dispatch(closeEditMode())
  if (isFieldNewView(state) && !isOpen) dispatch(closeCreationMode())

  if (isMeasuresTools) dispatch(toggleMeasureTool())

  return dispatch(toggleSearch())
}

export const toggleAdvancedSearch = () => ({
  type: TOGGLE_ADVANCED_SEARCH
})

export const selectSearchItem = item => ({
  type: SELECT_SEARCH_ITEM,
  payload: item
})

const openFilter = filter => ({
  type: OPEN_FILTER,
  payload: filter
})

export const openFilterType = type => dispatch => {
  type === PLANTATION_RANGE_FILTER ? dispatch(openPlantationDateFilterModal()) : dispatch(openFilter(type))
}
