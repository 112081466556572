import { RECEIVE_MICROSERVICE_SPECS } from '../actions/fileGenerator'

const microserviceSpecsInitialState = {}

export default function microserviceSpecs(state = microserviceSpecsInitialState, action = {}) {
  switch (action.type) {
    case RECEIVE_MICROSERVICE_SPECS: {
      return action.specs
    }

    default:
      return state
  }
}
