import React from 'react'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
const generateIcon = fontAwesomeIcon => <i className={`fa fa-${fontAwesomeIcon}`} />

const Image = styled.img`
  max-height: 90vh;
`

const Wrapper = styled.div`
  position: relative;
`

const ToolsWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  background: ${themeColors.blueColorOpacity};
`

const Button = styled.div`
  background: none;
  border: none;
  padding: 12px;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    background: ${themeColors.vomitLightHover};
  }
`

export default function SampleImageModal({ src }) {
  return (
    <TransformWrapper initialScale={1}>
      {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
        <Wrapper>
          <ToolsWrapper>
            <Button onClick={() => zoomIn()}>{generateIcon('plus')}</Button>
            <Button onClick={() => zoomOut()}>{generateIcon('minus')}</Button>
          </ToolsWrapper>
          <TransformComponent>
            <Image src={src} alt="" />
          </TransformComponent>
        </Wrapper>
      )}
    </TransformWrapper>
  )
}
