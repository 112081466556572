import { connect } from 'react-redux'
import { compose } from 'redux'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { isSelectedFieldsEmpty } from '../../selectors/fields'
import { getSelectedFieldMeteorologicalData } from '../../selectors/meteorologicalData'
// eslint-disable-next-line import/no-duplicates
import { getMeteorologicalStatisticsBaseTemperature } from '../../selectors/dashboard'
import MeteorologicalStatistics from '../../components/DashboardComponents/MeteorologicalStatistics'
// eslint-disable-next-line import/no-duplicates
import { getMeteorologicalStatisticsSelectedField } from '../../selectors/dashboard'
import { isMetric } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  selectedFieldMeteorologicalData: getSelectedFieldMeteorologicalData(state),
  isSelectedFieldsEmpty: isSelectedFieldsEmpty(state),
  baseTemperature: getMeteorologicalStatisticsBaseTemperature(state),
  selectedField: getMeteorologicalStatisticsSelectedField(state),
  isMetric: isMetric(state)
})

export default compose(withTranslator, connect(mapStateToProps))(MeteorologicalStatistics)
