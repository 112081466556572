import React from 'react'
import SearchBarFiltersContainer from '../../containers/SearchContainers/SearchBarFiltersContainer'
import SearchBarListContainer from '../../containers/SearchContainers/SearchBarListContainer'

export default function SearchBarFiltersAndList({ fields, currentLocation, totalFieldCount }) {
  return (
    <>
      <SearchBarFiltersContainer fields={fields} currentLocation={currentLocation} totalFieldCount={totalFieldCount} />
      <SearchBarListContainer fields={fields} currentLocation={currentLocation} />
    </>
  )
}
