import get from 'lodash/fp/get'
import { getFieldIdBySeasonId } from './seasons'
import pipe from 'lodash/fp/pipe'
import find from 'lodash/fp/find'
import map from 'lodash/fp/map'
import { getSelectedFlightGroupFlights, getTemporalComparisonFlightGroupFlights } from './flightGroups'
import reduce from 'lodash/fp/reduce'
import { getFieldTileOrder } from '@layers-frontend/commons/store/selectors/fieldsTileOrder'
import moment from 'moment'
import { getSelectedFieldsIds } from './fields'
import size from 'lodash/size'
import union from 'lodash/union'

export const getSeasonTileOrdersSubscriptionByDateRange = (seasonId, from, to) => state => {
  const fieldId = getFieldIdBySeasonId(seasonId)(state)
  const fieldTileOrders = getFieldTileOrder(fieldId)(state)

  const activeFieldTileOrder = find(
    fieldTileOrder => new Date(fieldTileOrder?.startDate) <= new Date(to) && new Date(from) <= new Date(fieldTileOrder?.endDate)
  )(fieldTileOrders)
  if (activeFieldTileOrder) {
    return activeFieldTileOrder.tileSubscription
  }
  return null
}

export const getSeasonTileOrdersInstanceIdByDateRange = (seasonId, from, to) => state => {
  return pipe(getSeasonTileOrdersSubscriptionByDateRange(seasonId, from, to), get('instanceId'))(state)
}

export const getPlanetApiKeys = state => {
  const selectedFlightGroupFlights = getSelectedFlightGroupFlights(state)
  const temporalComparisonFlightGroupFlights = getTemporalComparisonFlightGroupFlights(state)
  const flights = union(selectedFlightGroupFlights, temporalComparisonFlightGroupFlights)
  return reduce((accumulator, flight) => {
    const flightSeasons = get('seasons')(flight)
    const flightSeasonIds = map(season => get('id')(season))(flightSeasons)

    const date = get('date')(flight)

    const seasonKeys = reduce((seasons, seasonId) => {
      const seasonKey = getSeasonTileOrdersInstanceIdByDateRange(seasonId, date, date)(state)
      return seasonKey ? { ...seasons, [seasonId]: seasonKey } : seasons
    }, accumulator)(flightSeasonIds)
    return seasonKeys
  }, {})(flights)
}

export const getSelectedFieldsTileOrdersActiveSubscription = state => {
  const fieldIds = getSelectedFieldsIds(state)
  const fieldsTileOrders = map(fieldId => getFieldTileOrder(fieldId)(state))(fieldIds)
  const today = new Date()

  return reduce((accumulator, currentFieldTileOrders) => {
    const activeTileOrder = find(fieldTileOrder => {
      const subscription = get('tileSubscription')(fieldTileOrder)
      return moment(today).isSameOrAfter(subscription.startDate, 'day') && moment(today).isSameOrBefore(moment(subscription.endDate), 'day')
    })(currentFieldTileOrders)

    if (activeTileOrder) return [...accumulator, activeTileOrder]
    return accumulator
  }, [])(fieldsTileOrders)
}

export const hasSelectedFieldTileOrderSubscription = pipe(getSelectedFieldsTileOrdersActiveSubscription, size, Boolean)
