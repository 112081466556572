import React, { useState } from 'react'
import styled from 'styled-components'
import { BoxContainer } from './Layout'
import { UIStyles } from '../../theme'
import { SortButton } from '../UIComponents/SortButton'
import FieldsComparisonChartsMaxCloudCoverageInputContainer from '../../containers/DashboardContainers/DashboadFiltersContainers/FieldsComparisonChartsMaxCloudCoverageInputContainer'

import isEmpty from 'lodash/isEmpty'
import isFinite from 'lodash/isFinite'
import reduce from 'lodash/reduce'
import filter from 'lodash/filter'
import lte from 'lodash/lte'
import toNumber from 'lodash/toNumber'
import get from 'lodash/get'
import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import StyledSelect from '../UIComponents/StyledSelect'
import StyledFloatInput from '../UIComponents/StyledFloatInput'

const Container = styled(BoxContainer)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
`

const FiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-right: 15px;
    height: 50px;
    display: flex;
    align-items: flex-end;
  }
`

export const OpenModalButton = styled.button`
  border: 1px solid;
  border-radius: 4px;
  outline: none;
  align-self: center;
  background-color: transparent;
  color: ${UIStyles.whiteColor};
  border-color: ${UIStyles.whiteColor};
  padding: 6px 12px;
  display: block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;

  &:hover {
    color: ${UIStyles.vomitColor};
    border-color: ${UIStyles.vomitColor};
  }

  &[disabled] {
    color: ${UIStyles.grayColor};
    border-color: ${UIStyles.grayColor};
    cursor: not-allowed;
  }
`
const VerticalLine = styled.span`
  border: 1px solid white;
  margin: 10px 20px;
`

const selectStyle = {
  width: '250px',
  justifyContent: 'center'
}

const wrapperInputStyle = {
  width: '60px',
  marginTop: 0,
  display: 'flex',
  justifyContent: 'center'
}

const filterFieldIdsByRange = ({ dataToFilter, pathToKey, min, max, pathToFieldId }) =>
  reduce(
    dataToFilter,
    (accumulator, data) => {
      const selectedDataKeyToFilterValue = get(data, pathToKey)

      const isInRange = Number(selectedDataKeyToFilterValue) >= Number(min) && Number(selectedDataKeyToFilterValue) <= Number(max)
      return isInRange ? [...accumulator, Number(get(data, pathToFieldId))] : accumulator
    },
    []
  )

export default function FieldsComparisonChartsFilters({
  t,
  openConfirmChangeFieldsSelectionModal,
  filtersRangeKeyDisplay,
  currentlyDisplayedMetadata = [],
  currentlyDisplayedRadarData = [],
  maxCloudCoverage,
  isDroneFlight,
  fieldComparisonOrder,
  toggleFieldsComparisonOrderByDate
}) {
  const [rangeMin, setRangeMin] = useState(undefined)
  const [rangeMax, setRangeMax] = useState(undefined)
  const [rangeKey, setRangeKey] = useState(undefined)

  const resetFilters = () => {
    setRangeMin(undefined)
    setRangeMax(undefined)
    setRangeKey(undefined)
  }

  const numberMin = Number(rangeMin)
  const numberMax = Number(rangeMax)
  const shouldExecuteActionForRangeFilter = !isEmpty(rangeKey) && isFinite(numberMin) && isFinite(numberMax) && !isEmpty(currentlyDisplayedMetadata)

  const shouldExecuteActionForCloudFilter = isFinite(maxCloudCoverage) && !isEmpty(currentlyDisplayedMetadata)

  const isRadarKey = rangeKey === 'std_sigma0' || rangeKey === 'mean_sigma0'

  const openModalForRangeFilter = () => {
    const filteredFieldsIds = filterFieldIdsByRange({
      dataToFilter: isRadarKey ? currentlyDisplayedRadarData : currentlyDisplayedMetadata,
      pathToKey: `properties.${rangeKey}`,
      pathToFieldId: 'field_id',
      min: numberMin,
      max: numberMax
    })

    openConfirmChangeFieldsSelectionModal({ fieldsIdsToSelect: filteredFieldsIds, onModalClose: resetFilters })
  }

  const openModalForCloudFilter = () => {
    const metadataFilteredByCloudCoverage = filter(currentlyDisplayedMetadata, flightData => {
      const currentFlightCloudCoverage = toNumber(get(flightData, 'cloud_coverage'))
      const isCloudy = lte(currentFlightCloudCoverage, maxCloudCoverage)
      return isCloudy
    })
    const filteredFieldsIds = map(metadataFilteredByCloudCoverage, 'field_id')

    openConfirmChangeFieldsSelectionModal({ fieldsIdsToSelect: filteredFieldsIds, onModalClose: resetFilters })
  }

  const noFieldIsSelected = isEmpty(filtersRangeKeyDisplay)

  const rangeFilters = (
    <>
      <StyledSelect
        disabled={noFieldIsSelected}
        onChange={setRangeKey}
        options={filtersRangeKeyDisplay}
        placeholder={t('select fields by')}
        value={rangeKey}
        wrapperStyle={selectStyle}
        autoWidth={false}
      />
      <StyledFloatInput inputValue={rangeMin} placeholder={'Min'} onChange={setRangeMin} wrapperStyle={wrapperInputStyle} disabled={noFieldIsSelected} />
      <StyledFloatInput inputValue={rangeMax} placeholder={'Max'} onChange={setRangeMax} wrapperStyle={wrapperInputStyle} disabled={noFieldIsSelected} />
      <OpenModalButton disabled={!shouldExecuteActionForRangeFilter} onClick={shouldExecuteActionForRangeFilter ? openModalForRangeFilter : undefined}>
        {t('Select')}
      </OpenModalButton>
    </>
  )

  const cloudFilters = isDroneFlight ? null : (
    <>
      <VerticalLine />
      <FieldsComparisonChartsMaxCloudCoverageInputContainer
        buttonDisabled={!shouldExecuteActionForCloudFilter}
        openModal={openModalForCloudFilter.bind(this)}
      />
      <VerticalLine />
    </>
  )

  return (
    <Container>
      <FiltersWrapper>
        {rangeFilters}
        {cloudFilters}
      </FiltersWrapper>
      <SortButton
        mainIcon={'fas fa-calendar-days'}
        direction={fieldComparisonOrder}
        onClick={toggleFieldsComparisonOrderByDate}
        tooltipTitle={capitalize(t('sort by date'))}
      />
    </Container>
  )
}
