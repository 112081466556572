import React from 'react'
import styled from 'styled-components'
import { VomitButton, RedButton } from '../UIComponents/buttons'
import themeColors from '@layers-frontend/commons/styles/themeColors'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
`

const StyledLabel = styled.label`
  margin-top: 10px;
  color: ${themeColors.vomitColor};
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  font-size: 2rem;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  button {
    width: 120px;
  }
`

const GoToFieldModal = props => {
  const { closeModal, t, selectField } = props
  return (
    <ContentWrapper>
      <StyledLabel>{t('this action will open field view and lose current field selection')}</StyledLabel>
      <ButtonsWrapper>
        <RedButton
          label={t('cancel')}
          onClick={e => {
            closeModal()
          }}
        />
        <VomitButton
          label={t('ok')}
          onClick={e => {
            selectField()
            closeModal()
          }}
        />
      </ButtonsWrapper>
    </ContentWrapper>
  )
}

export default GoToFieldModal
