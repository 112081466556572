import isUndefined from 'lodash/isUndefined'
import React from 'react'
import styled from 'styled-components'
import LayersLogo from './LayersLogo'

const Wrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
  background-color: ${props => (isUndefined(props.backgroundColor) ? 'rgba(7, 39, 50, 1)' : props.backgroundColor)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${props => (isUndefined(props.zIndex) ? 100 : props.zIndex)};
`

const Loader = props => {
  const { isLoading } = props
  if (!isLoading) return null

  if (props.component)
    return (
      <Wrapper>
        <component />
      </Wrapper>
    )

  return (
    <Wrapper zIndex={props.zIndex} backgroundColor={props.backgroundColor}>
      <LayersLogo />
    </Wrapper>
  )
}

Loader.defaultProps = {
  isLoading: true
}

export default Loader
