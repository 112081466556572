import React from 'react'
import PropTypes from 'prop-types'

import LayerVector from '../olComponents/layervector'
import SourceVector from '../olComponents/sourcevector'
import Polygon from '../olComponents/geom/Polygon'
import Circle from '../olComponents/geom/Circle'
import Feature from '../olComponents/feature'
import DeleteOverlayContainer from '../../containers/olContainers/DeleteOverlayContainer'
import InteractionsContainer from '../../containers/EditorContainers/InteractionsContainer'

import OlMap from 'ol/Map'
import { MARKER_TOOL } from '../../actions/editor'
import Point from '../olComponents/geom/Point'
import isEmpty from 'lodash/isEmpty'
import { Fill, Stroke, Style, Text } from 'ol/style'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import { SAMPLES_LAYER_Z_INDEX } from '../../constants'
import first from 'lodash/first'

const drawStyle = [
  {
    stroke: {
      width: 4,
      color: 'rgba(54, 215, 183,1)'
    }
  },
  {
    fill: {
      color: 'rgba(0, 0, 0,0.5)'
    }
  }
]

export const markerStyle = () => {
  const SELECTED_MARKER_SIZE = 42

  const fill = new Fill({
    color: themeColors.dangerRed
  })

  const stroke = new Stroke({
    color: themeColors.black,
    width: 2
  })

  return new Style({
    text: new Text({
      text: '\uf3c5',
      textAlign: 'center',
      font: `900 ${SELECTED_MARKER_SIZE}px "Font Awesome 5 Free"`,
      textBaseline: 'bottom',
      // eslint-disable-next-line object-shorthand
      fill: fill,
      // eslint-disable-next-line object-shorthand
      stroke: stroke
    })
  })
}

export default class Editor extends React.Component {
  getChildContext() {
    return {
      map: this.context.map
    }
  }

  componentWillUnmount() {}
  render() {
    // eslint-disable-next-line no-var
    var _this = this
    let polygons

    if (_this.props.activeTool === MARKER_TOOL) {
      const feature = first(_this.props.features)
      if (!isEmpty(feature)) {
        polygons = (
          <Feature key={feature.uid} id={feature.uid} style={markerStyle}>
            <Point projection={_this.props.projection}>{feature.geometry.coordinates}</Point>
          </Feature>
        )
      }
    } else {
      polygons = _this.props.features.map(feature => {
        if (typeof feature.geometry.center === 'undefined' && typeof feature.geometry.radius === 'undefined') {
          return (
            <Feature key={_this.props.activeTool + '' + JSON.stringify(feature.geometry.coordinates) + feature.uid} id={feature.uid} style={drawStyle}>
              <Polygon projection={_this.props.projection} notification={<DeleteOverlayContainer feature={feature} />}>
                {feature.geometry.coordinates}
              </Polygon>
            </Feature>
          )
        } else {
          return (
            <Feature key={JSON.stringify(feature.geometry.radius) + JSON.stringify(feature.geometry.center)} id={feature.uid} style={drawStyle}>
              <Circle
                projection={_this.props.projection}
                center={feature.geometry.center}
                radius={feature.geometry.radius}
                notification={<DeleteOverlayContainer feature={feature} />}
              />
            </Feature>
          )
        }
      })
    }

    return (
      <div>
        <LayerVector visible={true} name="editor" zIndex={SAMPLES_LAYER_Z_INDEX}>
          <SourceVector key={'e' + _this.props.features.length}>
            <InteractionsContainer />
            {polygons}
          </SourceVector>
        </LayerVector>
      </div>
    )
  }
}

Editor.propTypes = {
  features: PropTypes.array
}

Editor.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

Editor.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
