import map from 'lodash/map'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { TextButton } from '../../UIComponents/StyledButtons'
import themeColors from '@layers-frontend/commons/styles/themeColors'

export function LegendCategorical({
  legend,
  translate,
  onShowHideAllClick,
  isSelectionReset,
  onLegendElementClick,
  onLegendElementMouseEnter,
  onLegendElementMouseLeave
}) {
  if (legend.length === 0) {
    return <span data-testid="no-data">{translate('no data')}</span>
  }

  const showHideAllLabel = isSelectionReset ? translate('Hide All Layers') : translate('Show All Layers')
  const showHideAllTestId = isSelectionReset ? 'hide-all-button' : 'show-all-button'

  const legendItems = map(legend, (current, index) => {
    let { visible, color, label } = current

    if (index === 0 && current.name) {
      label = translate(current.name)
    }

    return (
      <LegendElement
        key={`${index}-${label}`}
        visible={visible}
        onClick={() => onLegendElementClick(index)}
        onMouseEnter={() => onLegendElementMouseEnter(current)}
        onMouseLeave={onLegendElementMouseLeave}
      >
        <LegendColor bg={color} visible={visible} />
        {translate(label)}
      </LegendElement>
    )
  })

  return (
    <>
      <TextButton
        onClick={onShowHideAllClick}
        label={showHideAllLabel}
        background={themeColors.blueColor}
        color={themeColors.whiteColor}
        testId={showHideAllTestId}
      />

      <LegendInnerContainer>{legendItems}</LegendInnerContainer>
    </>
  )
}

const LegendInnerContainer = styled.ul`
  margin-bottom: 8px;
  list-style-type: none;
  padding-inline-start: 0;
  overflow-y: auto;
`
const LegendElement = styled.li`
  display: flex;
  align-items: center;
  color: ${props => (props.visible ? 'white' : '#aaa')};
  cursor: pointer;
`
const LegendColor = styled.span`
  width: 10px;
  height: 10px;
  background: ${props => (props.visible ? props.bg : '#aaa')};
  margin-right: 5px;
  border: 1px solid ${props => (props.visible ? 'white' : '#aaa')};
  border-radius: 2px;
`

LegendCategorical.propTypes = {
  legend: PropTypes.arrayOf(
    PropTypes.shape({
      // properties of categorical legends from DB and enrichedLegend
      visible: PropTypes.bool.isRequired,
      color: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      // properties of categorical legends not from DB only
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
      // properties of categorical legends from DB only
      name: PropTypes.string,
      id: PropTypes.number,
      isScaleFromDB: PropTypes.bool,
      isScaleTypeCategoric: PropTypes.bool,
      range: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.number)])
    })
  ).isRequired,
  translate: PropTypes.func.isRequired,
  onShowHideAllClick: PropTypes.func.isRequired,
  isSelectionReset: PropTypes.bool.isRequired,
  onLegendElementClick: PropTypes.func.isRequired,
  onLegendElementMouseEnter: PropTypes.func.isRequired,
  onLegendElementMouseLeave: PropTypes.func.isRequired
}
