import React from 'react'
import PropTypes from 'prop-types'
import FieldSelectorContainer from '../../containers/EditorContainers/FieldSelectorContainer'
import EditorContainer from '../../containers/EditorContainers/EditorContainer'
import { UIStyles } from '../../theme'
import { POLYGON_DRAW, CIRCLE_DRAW, POLYGON_SLICE, POLYGON_MOVE, MARKER_TOOL } from '../../actions/editor'
import OlMap from 'ol/Map'
import { defaults } from 'ol/interaction'
import MouseTooltip from '../UIComponents/MouseTooltip'
import SamplesLayerInteractionContainer from '../../containers/olContainers/SamplesLayerInteractionContainer'
import MapClickInteractionContainer from '../../containers/olContainers/MapClickInteractionContainer'
import { CLUSTER_ZOOM_THRESHOLD } from '@layers-frontend/commons/constants'

import get from 'lodash/get'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import GeometryUtils from '../../geometryUtils'

const tooltipStyle = {
  padding: '10px',
  backgroundColor: UIStyles.blueColor,
  color: UIStyles.vomitColor
}

export default class Map extends React.Component {
  constructor(props) {
    super(props)
    this.map = new OlMap({
      loadTilesWhileAnimating: props.loadTilesWhileAnimating,
      loadTilesWhileInteracting: props.loadTilesWhileInteracting,
      interactions: props.useDefaultInteractions ? defaults() : [],
      controls: []
    })

    this.state = {
      initMap: false,
      cursor: 'default',
      sumatoryCoords: '',
      isVisibleNameFieldTooltip: false,
      fieldNameWithMouseOver: '',
      shouldFitEditorGeometry: true
    }

    if (props.onChangeSize) {
      this.map.on('change:size', props.onChangeSize)
    }
  }

  selectPointerMove = event => {
    let fieldName = null
    const { isSearchView, isFieldView, isEditorMeasureToolActive, isGlobalLayerView, zoomLevel } = this.props
    const isFieldNameTooltipActive = (isSearchView || isFieldView) && !isEditorMeasureToolActive
    if (isGlobalLayerView && zoomLevel < CLUSTER_ZOOM_THRESHOLD) {
      this.setState({ cursor: 'default', isVisibleNameFieldTooltip: false, fieldNameWithMouseOver: fieldName })
      return
    }
    if (isFieldNameTooltipActive) {
      const mouseCoordInMapPixels = [event.originalEvent.offsetX, event.originalEvent.offsetY]

      //detect feature at mouse coords
      const hit = this.map.forEachFeatureAtPixel(mouseCoordInMapPixels, feature => {
        const fieldFeatures = feature.get('field')
        const fieldId = get(fieldFeatures, 'fieldId') || get(fieldFeatures, 'gm_field_id') || get(fieldFeatures, 'id')
        const field = this.props.getFieldById(fieldId)

        fieldName = get(field, 'name') || get(fieldFeatures, 'name') || get(fieldFeatures, 'gm_name')

        if (fieldFeatures.name === '' || fieldFeatures.gm_name === '') {
          fieldName = this.props.t('nameless field')
        }
        return true
      })
      this.setState({ cursor: hit ? 'pointer' : 'default', isVisibleNameFieldTooltip: !!hit, fieldNameWithMouseOver: fieldName })
    } else {
      this.setState({ cursor: 'default', isVisibleNameFieldTooltip: false, fieldNameWithMouseOver: fieldName })
    }
  }

  componentDidMount() {
    this.map.setTarget(this.refs.target)

    this.map.on('pointermove', this.selectPointerMove)

    this.map.on('moveend', () => {
      const currentZoom = this.props.zoomLevel
      const newZoom = this.map.getView().getZoom()
      const isZoom = currentZoom !== newZoom
      const disableZoomRegister = currentZoom >= CLUSTER_ZOOM_THRESHOLD && newZoom >= CLUSTER_ZOOM_THRESHOLD
      if (!currentZoom) this.props.setZoomLevel(newZoom)
      if (isZoom && !disableZoomRegister) {
        this.props.setZoomLevel(newZoom)
      }
    })

    /* center map with boundaires element after init */

    if (this.props.focusOnMount) {
      this.focus()
    }

    const featuresLayer = this.constructor.getLayerFromName('geometries', this.map)
    if (this.props.isFieldView && featuresLayer) {
      this.zoomToLayer(featuresLayer)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // reset curor when comming from editor
    if (!nextProps.isSearchView) {
      this.setState({ cursor: 'default' })
    }

    /*** center all fields on init ***/
    const clusterLayer = this.constructor.getLayerFromName('cluster_layer', this.map)
    const featuresLayer = this.constructor.getLayerFromName('geometries', this.map)
    if (clusterLayer && !this.state.initMap) {
      const clusterSource = clusterLayer.getSource()

      this.zoomToLayer(clusterSource)
      this.setState({ initMap: true })
    }

    // fix extent to currently filtred fields
    const hasFiltersUpdate = this.props.filters !== nextProps.filters
    const hasSearchInputUpdate = this.props.searchInput !== nextProps.searchInput && nextProps.searchInput !== ''
    if (hasFiltersUpdate || hasSearchInputUpdate) {
      // hack to fix zoomToLayer delay
      setTimeout(() => {
        const filteredFeaturesExtent = GeometryUtils.boundingBoxFromFeatures(nextProps.filteredFeatures)
        if (!filteredFeaturesExtent.includes(Infinity) || !filteredFeaturesExtent.includes(NaN)) {
          this.zoomToBbox(filteredFeaturesExtent)
        }
      }, 100)
    }

    // fit geometry on editor interaction
    if (this.state.shouldFitEditorGeometry && nextProps.isEditorActive && nextProps.isFieldSeasonFormEditorOpen) {
      const editorGeo = this.constructor.getLayerFromName('editor', this.map)
      if (editorGeo) {
        this.setState({ shouldFitEditorGeometry: false }, () => this.zoomToLayer(editorGeo))
      }
    }

    if (!nextProps.isFieldSeasonFormEditorOpen && !this.state.shouldFitEditorGeometry) {
      this.setState({ shouldFitEditorGeometry: true })
    }

    if (nextProps.isFieldView) {
      // check if new geometries extent is different then old
      const oldGeometriesArray = map(this.props.selectedFlightGroupSeasons, season => get(season, 'geometry'))
      const newGeometriesArray = map(nextProps.selectedFlightGroupSeasons, season => get(season, 'geometry'))
      const oldExtent = get(GeometryUtils.boundingBoxFromGeometries(oldGeometriesArray), 'bbox')
      const newExtent = get(GeometryUtils.boundingBoxFromGeometries(newGeometriesArray), 'bbox')
      if (!isEqual(oldExtent, newExtent)) {
        setTimeout(() => {
          this.zoomToLayer(featuresLayer)
        }, 1)
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.isFieldView && this.props.isFieldView !== prevProps.isFieldView) {
      const featuresLayer = this.constructor.getLayerFromName('geometries', this.map)
      this.zoomToLayer(featuresLayer)
    }
  }

  getChildContext() {
    return { map: this.map }
  }

  componentWillUnmount() {
    this.map.removeInteraction(this.selectPointerMove)
    this.map.setTarget(undefined)
  }

  generateFont = (font, code, position, size) => {
    const canvas = document.createElement('canvas')

    canvas.width = 24
    canvas.height = 24
    // eslint-disable-next-line no-var
    var ctx = canvas.getContext('2d')
    ctx.fillStyle = UIStyles.vomitColor
    ctx.font = size + " 24px '" + font + "'"
    ctx.textAlign = 'center'
    ctx.textBaseline = 'middle'
    ctx.fillText(String.fromCharCode(code), 12, 12)
    const dataURL = canvas.toDataURL('image/png', 1)
    return 'url(' + dataURL + ') ' + position + ', auto'
  }

  getCursorStyle = () => {
    const { isEditorActive, activeTool } = this.props
    if (isEditorActive) {
      switch (activeTool) {
        case POLYGON_DRAW:
          return this.generateFont('Font Awesome 5 Free', '0xf303', '0 24', '900')
        case MARKER_TOOL:
          return
        case CIRCLE_DRAW:
          return this.generateFont('Font Awesome 5 Free', '0xf303', '0 24', '900')
        case POLYGON_MOVE:
          return this.generateFont('Font Awesome 5 Free', '0xf0b2', '12 12', '900')
        case POLYGON_SLICE:
          return this.generateFont('Ionicons', '0xf34b', '12 12', '300')
        default:
          return this.generateFont('Font Awesome 5 Free', '0xf303', '0 24', '900')
      }
    } else {
      return this.state.cursor
    }
  }

  render = () => {
    /** if not editor, we render the field selector */
    const { isSearchView, isEditorActive, children, view, zoomLevel, isGlobalLayerView } = this.props
    const fieldSelector = isSearchView && (!isGlobalLayerView || zoomLevel >= CLUSTER_ZOOM_THRESHOLD) ? <FieldSelectorContainer /> : null
    return (
      <div style={{ cursor: this.getCursorStyle() }}>
        <div ref="target">
          {isEditorActive && <EditorContainer />}
          {fieldSelector}
          <SamplesLayerInteractionContainer />
          <MapClickInteractionContainer />
        </div>
        <div>
          {children}
          {view}
        </div>
        {this.state.fieldNameWithMouseOver && (
          <MouseTooltip style={tooltipStyle} visible={this.state.isVisibleNameFieldTooltip} offsetX={15} offsetY={10}>
            <span>{this.state.fieldNameWithMouseOver}</span>
          </MouseTooltip>
        )}
      </div>
    )
  }

  /*=============================================>>>>>
  = Map utilities =
  ===============================================>>>>>*/
  /*----------- Map focus -----------*/

  focus() {
    const viewport = this.map.getViewport()
    viewport.tabIndex = 0
    viewport.focus()
  }

  /*----------- Get layer from name -----------*/
  static getLayerFromName(name, map) {
    let returnLayer
    map
      .getLayers()
      .getArray()
      .forEach(function (layer) {
        if (layer.get('name') === name) {
          returnLayer = layer
        }
      })
    return returnLayer
  }

  /* ----------- zoom to layer ----------- */
  zoomToBbox(bbox) {
    if (bbox && bbox[0] !== Infinity) {
      const view = this.map.getView()
      view.fit(bbox, { duration: 750, maxZoom: 16 })
    }
  }

  /* ----------- zoom to layer ----------- */
  zoomToLayer(layer, isGroup = false) {
    const boundingExtent = isGroup ? layer.getExtent() : layer?.getSource()?.getExtent()
    if (boundingExtent && boundingExtent[0] !== Infinity) {
      const view = this.map.getView()
      view.fit(boundingExtent, { duration: 750, maxZoom: 16 })
    }
  }

  /* ----------- center map to feature ----------- */
  zoomToFeature(feature) {
    this.map.getView().fit(feature.getGeometry().getExtent(), this.map.getSize())
  }

  /* ----------- Get feature by id ----------- */

  getFeatureById(featureId, layer) {
    return this.map.getLayers().getArray()[layer].getSource().getFeatureById(featureId)
  }
  /* = End of Map utilities = */
  /* =============================================<<<<< */
}

// types
Map.propTypes = {
  activeTool: PropTypes.string,
  dispatch: PropTypes.func,
  focusOnMount: PropTypes.bool.isRequired,
  isAllActive: PropTypes.bool,
  isEditorActive: PropTypes.bool,
  isFetchedData: PropTypes.bool,
  isTemporalView: PropTypes.bool,
  selectedField: PropTypes.object,
  useDefaultInteractions: PropTypes.bool.isRequired,
  useDefaultControls: PropTypes.bool.isRequired,
  loadTilesWhileAnimating: PropTypes.bool,
  loadTilesWhileInteracting: PropTypes.bool,
  onSingleClick: PropTypes.func,
  onChangeSize: PropTypes.func,
  view: PropTypes.element.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
}

Map.defaultProps = {
  useDefaultInteractions: true,
  useDefaultControls: true,
  focusOnMount: false
}

Map.childContextTypes = {
  map: PropTypes.instanceOf(OlMap)
}
