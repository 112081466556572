import { point, polygon, featureCollection, feature } from '@turf/helpers'

import inside from '@turf/boolean-point-in-polygon'
import explode from '@turf/explode'
import within from '@turf/points-within-polygon'
import buffer from '@turf/buffer'
import difference from '@turf/difference'
import distance from '@turf/distance'
import lineIntersect from '@turf/line-intersect'
import bbox from '@turf/bbox'
import simplify from '@turf/simplify'
import envelope from '@turf/envelope'

//Ol
import OlMultiPolygon from 'ol/geom/MultiPolygon'
import OlGeoJSON from 'ol/format/GeoJSON'
import OlFeature from 'ol/Feature'
// eslint-disable-next-line import/no-duplicates
import OlPolygon from 'ol/geom/Polygon'
// eslint-disable-next-line import/no-duplicates
import { fromCircle } from 'ol/geom/Polygon'
import { getArea, getLength } from 'ol/sphere'
import OlWKT from 'ol/format/WKT'
import { transformExtent, transform } from 'ol/proj.js'
import { MAX_SENTINEL_URL_LENGTH } from './constants'

import map from 'lodash/map'
import toString from 'lodash/toString'
import { convertAsString } from '@layers-frontend/commons/conversions'

export default class GeometryUtils {
  static formatGeometry(coordinates) {
    return {
      type: 'MultiPolygon',
      // eslint-disable-next-line object-shorthand
      coordinates: coordinates
    }
  }

  /*=============================================>>>>>
  = Openlayers geojson utilities =
  ===============================================>>>>>*/
  static multipolygonToGeometries(geometry, projection = 'EPSG:3857') {
    /* eslint-disable prefer-const */
    let formater = new OlGeoJSON({
      featureProjection: projection
    })

    // Extact polygon geometries from selected field
    let multipolygonGeom = formater.readGeometry(geometry)
    let multipolygon = new OlFeature()
    let area = this.areaToHa(multipolygonGeom.getArea())
    multipolygon.setGeometry(multipolygonGeom)
    let polygonsArray = multipolygon.values_.geometry.getPolygons()
    /* eslint-enable prefer-const */
    // eslint-disable-next-line prefer-const, camelcase
    let geometriesArray = polygonsArray.map(ol_polygon => ({
      // eslint-disable-next-line camelcase
      uid: ol_polygon.ol_uid,
      geometry: formater.writeGeometryObject(ol_polygon),
      // eslint-disable-next-line object-shorthand
      area: area
    }))
    return geometriesArray
  }

  static geometriesToMultipolygon(geometries, projection = 'EPSG:3857') {
    // eslint-disable-next-line prefer-const
    let multi = new OlMultiPolygon([])
    // eslint-disable-next-line prefer-const
    let polygon = new OlPolygon([])
    // let formater = new OlGeoJSON({
    //   featureProjection: projection
    // })
    geometries.forEach(geom => {
      polygon.setCoordinates(geom.coordinates)
      multi.appendPolygon(polygon)
    })
    return multi
  }

  static areaFromMultipolygon(multi, projectionFrom = 'EPSG:4326', projectionTo = 'EPSG:3857') {
    multi.transform(projectionFrom, projectionTo)
    // eslint-disable-next-line prefer-const
    let area = getArea(multi)
    return this.areaToHa(area)
  }

  /**
   *
   * @var geometry : geojson geometry
   *returns area in ha
   */
  static areaFromGeometry(geometry, projectionFrom = 'EPSG:3857') {
    // eslint-disable-next-line prefer-const
    let formater = new OlGeoJSON({
      featureProjection: projectionFrom
    })
    //  transform to ol object
    // eslint-disable-next-line camelcase, prefer-const
    let ol_geometry = formater.readGeometry(geometry)
    // eslint-disable-next-line prefer-const
    let area = getArea(ol_geometry)
    return this.areaToHa(area)
  }

  //areas are in ha already
  static areaFromGeometries(geometries) {
    let area = 0
    geometries.forEach(geometry => {
      area += geometry.area
    })
    return area.toFixed(2)
  }

  static polygonFromCircle(circle, projectionFrom = 'EPSG:3857', points = 500) {
    // eslint-disable-next-line prefer-const
    let formater = new OlGeoJSON({
      featureProjection: projectionFrom
    })

    let polygon = fromCircle(circle, points)
    polygon = formater.writeGeometryObject(polygon)
    polygon.radius = circle.getRadius()
    polygon.center = circle.getCenter()
    return polygon
  }

  static Xtransform(extent, projectionFrom = 'EPSG:4326', projectionTo = 'EPSG:3857') {
    return transformExtent(extent, projectionFrom, projectionTo)
  }

  static transform(extent, projectionFrom = 'EPSG:3857', projectionTo = 'EPSG:4326') {
    return transform(extent, projectionFrom, projectionTo)
  }

  static addHoleToPolygon(polygon, hole) {
    polygon.appendLinearRing(hole)
    return polygon
  }

  static getArea(polygon) {
    return getArea(polygon)
  }

  static getHectares(polygon) {
    const area = getArea(polygon)
    return GeometryUtils.areaToHa(area)
  }

  static formatArea(polygon) {
    /* eslint-disable no-var */
    var area = getArea(polygon)

    return GeometryUtils.areaToHa(area) + ' Ha'
  }

  static areaToHa(area) {
    return Math.round(area / 100) / 100
  }

  static formatMetricLength(line) {
    return GeometryUtils.formatLength(line)
  }

  static formatImperialLength(line) {
    var length = getLength(line)
    var output = convertAsString(length, 'meters', 'miles')
    return output
  }

  static formatLength(line) {
    var length = getLength(line)
    var output
    /* eslint-enable no-var */
    if (length > 100) {
      output = Math.round((length / 1000) * 100) / 100 + ' km'
    } else {
      output = Math.round(length * 100) / 100 + ' m'
    }
    return output
  }

  /**
   *  Returns intersection points between a polygon and a linestring
   *
   * @param {geometry} geometry in geojson format
   * @param {projectionFrom} input projection
   * @param {projectionTo}  output projection
   * @param {decimals} number of decimals for coords in output
   * @param {tolerance} tolerance value for simplify geometry
   *
   * @returns {geometry} geomerty in wkt format why
   */

  static geojsonToWkt(geometry, decimals = 2, tolerance = 0.00007, projectionFrom = 'EPSG:4326', projectionTo = 'EPSG:3857') {
    // eslint-disable-next-line prefer-const
    let geoJsonFormater = new OlGeoJSON({
      featureProjection: projectionFrom
    })

    // eslint-disable-next-line prefer-const
    let wktFormater = new OlWKT({
      featureProjection: projectionTo
    })
    // console.log('before: ', geometry)
    // eslint-disable-next-line prefer-const, object-shorthand
    let simplifyOptions = { tolerance: tolerance, highQuality: false }
    geometry = simplify(geometry, simplifyOptions)
    // console.log('After: ', geometry)
    // read the geojson input and convert it to ol geometry
    // eslint-disable-next-line prefer-const
    let olGeometry = geoJsonFormater.readGeometry(geometry)
    olGeometry.transform(projectionFrom, projectionTo)

    // write ol geometry to wkt format
    // eslint-disable-next-line object-shorthand
    return wktFormater.writeGeometry(olGeometry, { decimals: decimals })
  }

  /**
   *  Transform a geometry projection from input to output
   *
   * @param {geometry} geometry in geojson format
   * @param {projectionFrom} input projection
   * @param {projectionTo}  output projection
   *
   * @returns {geometry} geomerty in output projection
   */

  static translateGeometry(geometry, projectionFrom = 'EPSG:4326', projectionTo = 'EPSG:3857') {
    // eslint-disable-next-line prefer-const
    let geoJsonFormater = new OlGeoJSON()
    // eslint-disable-next-line prefer-const
    let olGeometry = geoJsonFormater.readGeometry(geometry, {
      dataProjection: projectionFrom
    })
    olGeometry.transform(projectionFrom, projectionTo)
    return geoJsonFormater.writeGeometryObject(olGeometry)
  }
  /*= End of Openlayers geojson utilities =*/
  /*=============================================<<<<<*/

  /*=============================================>>>>>
  = Tools based on turf =
  ===============================================>>>>>*/
  /**
   *  Returns intersection points between a polygon and a linestring
   *
   * @param {polygon} Polygon to be sliced in geojson format
   * @param {LineString} Slicer linestring in geojson format
   *
   * @returns {array} Array of resulting polygons after slice
   */

  static getIntersectionPoints(polygon, slicer) {
    return lineIntersect(polygon, slicer)
  }

  /**
   *  Returns the boundingbox of a given geojson geometry
   *
   * @param {geometry} a GeoJSON object
   *
   * @returns {boundingbox} Array of coords
   */

  static geometryToBbox(geometry) {
    return bbox(geometry)
  }

  /*=============================================>>>>>
  = Turf cut last implementation =
  ===============================================>>>>>*/
  static turfCut(poly, line, tolerance, toleranceType) {
    /* eslint-disable no-var */
    var intersections
    var lp
    var lpoint
    /* eslint-enable no-var */
    if (tolerance == null) {
      tolerance = 0.01
    }
    if (toleranceType == null) {
      toleranceType = 'meters'
    }
    // eslint-disable-next-line no-void
    if (poly.geometry === void 0 || poly.geometry.type !== 'Polygon') {
      throw Error('Slice tool only works on Polygons and Circle')
    }
    if (
      // eslint-disable-next-line no-void
      line.geometry === void 0 ||
      (line.geometry.type !== 'LineString' && line.geometry.type !== 'Polygon' && toString(line.geometry.type) !== 'MultiLineString')
    )
      throw Error('Slice tool only accepts LineString or polygon type as axe input')
    if (toString(line.geometry.type) === 'LineString') {
      if (inside(point(line.geometry.coordinates[0]), poly) || inside(point(line.geometry.coordinates[line.geometry.coordinates.length - 1]), poly))
        throw Error('Both first and last points of the slice line must be outside of the polygon/circle')
    } else {
      /* eslint-disable no-var */
      var points = explode(line)
      if (!within(points, featureCollection([poly]))) throw Error('All points of polygon must be within tract.')
    }

    intersections = lineIntersect(line, poly)
    var lineExp = explode(line)
    for (var p = 1; p < lineExp.features.length - 1; ++p) {
      intersections.features.push(point(lineExp.features[p].geometry.coordinates))
    }
    var _axe =
      toString(line.geometry.type) === 'LineString' || toString(line.geometry.type) === 'MultiLineString'
        ? buffer(line, tolerance, { units: toleranceType })
        : line // turf-buffer issue #23
    var _body = difference(poly, _axe)
    var pieces = []

    if (toString(_body.geometry.type) === 'Polygon') {
      pieces.push(polygon(_body.geometry.coordinates))
    } else {
      _body.geometry.coordinates.forEach(function (a) {
        pieces.push(polygon(a))
      })
    }

    // Zip the polygons back together
    for (p = 0; p < pieces; p++) {
      var piece = pieces[p]
      for (var c = 0; c < piece.geometry.coordinates[0].length; c++) {
        const coord = piece.geometry.coordinates[0][c]
        const p = point(coord)
        for (lp = 0; lp < intersections.features.length; lp++) {
          lpoint = intersections.features[lp]
          if (distance(lpoint, p, { units: toleranceType }) <= tolerance * 2) {
            piece.geometry.coordinates[0][c] = lpoint.geometry.coordinates
          }
        }
      }
    }

    // Filter out duplicate points
    for (p = 0; p < pieces; p++) {
      var newcoords = []
      piece = pieces[p]
      for (c = 0; c < piece.geometry.coordinates[0].length; c++) {
        var coord = piece.geometry.coordinates[0][c]
        /* eslint-enable no-var */
        if (
          c === 0 ||
          toString(coord[0]) !== toString(newcoords[newcoords.length - 1][0]) ||
          toString(coord[1]) !== toString(newcoords[newcoords.length - 1][1])
        ) {
          newcoords.push(coord)
        }
      }
      piece.geometry.coordinates[0] = newcoords
    }

    pieces.forEach(function (a) {
      a.properties = poly.properties
    })

    return featureCollection(pieces)
  }

  static isValidSentinelRequest(sentinelUrl) {
    return encodeURIComponent(sentinelUrl).length < MAX_SENTINEL_URL_LENGTH
  }

  static addGeometryToSentinelUrl(urlMissingGeometry, geometry) {
    const wktGeometry = this.geojsonToWkt(geometry, 2, 0)
    // eslint-disable-next-line no-template-curly-in-string
    let sentinelTileUrl = urlMissingGeometry.replace('${FIELD_GEOMETRY}', wktGeometry)

    let j = 1
    while (!this.isValidSentinelRequest(sentinelTileUrl)) {
      const tolerance = 0.0000000001 + j / 10000000

      const simplifyOptions = { tolerance, highQuality: false }
      const simplifiedGeometry = simplify(geometry, simplifyOptions)
      const stringifiedGeometry = this.geojsonToWkt(simplifiedGeometry, 2, 0)
      // eslint-disable-next-line no-template-curly-in-string
      sentinelTileUrl = urlMissingGeometry.replace('${FIELD_GEOMETRY}', stringifiedGeometry)

      j += 1
    }

    return sentinelTileUrl
  }

  /**
   *  Feature collection from geometries array
   *
   * @param {geometries array} geojson geometries array
   *
   * @returns {featureCollection} geojson feature collection
   */

  static featureCollectionFromGeometries(geometries) {
    const featuresArray = map(geometries, geometry => feature(geometry))
    return featureCollection(featuresArray)
  }

  /**
   *  Bbox from geometries array
   *
   * @param {geometries array} geojson geometries array
   *
   * @returns {polygon} rectangular polygon containing all geometries
   */

  static boundingBoxFromGeometries(geometries) {
    const collection = this.featureCollectionFromGeometries(geometries)
    return envelope(collection)
  }

  /**
   *  Bbox from features array
   *
   * @param {features array} features
   *
   * @returns {polygon} rectangular polygon containing all geometries
   */

  static boundingBoxFromFeatures(features) {
    const collection = featureCollection(features)
    const bbox = envelope(collection)
    const transformed = this.Xtransform(bbox.bbox, 'EPSG:4326', 'EPSG:3857')
    return transformed
  }

  /*= End of Tools based on turf =*/

  /*=============================================<<<<<*/
}
