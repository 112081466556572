import PropTypes from 'prop-types'
import OLComponent from '../ol-component'

import OlPoint from 'ol/geom/Point'
import OlFeature from 'ol/Feature'

export default class Point extends OLComponent {
  constructor(props) {
    super(props)
    this.geometry = new OlPoint([])
    this.updateFromProps(props)
  }

  updateFromProps(props) {
    this.geometry.setCoordinates(this.props.children)
    this.geometry.transform(props.projection.from, props.projection.to)
  }

  componentDidMount() {
    this.context.feature.setGeometry(this.geometry)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.updateFromProps(newProps)
  }

  render() {
    return false
  }
}

Point.propTypes = {
  children: PropTypes.arrayOf(PropTypes.number).isRequired
}

Point.contextTypes = {
  feature: PropTypes.instanceOf(OlFeature)
}
