import React from 'react'
import withTranslator from '../HOCComponents/withTranslator'

import capitalize from 'lodash/capitalize'
import { TextField } from '@mui/material'

const StyledTextArea = ({ t, value, label, errorText, fullWidth = true, onChange, disabled = false, inputProps }) => (
  <TextField
    value={value || ''}
    multiline
    label={capitalize(t(label))}
    helperText={errorText}
    onChange={e => onChange(e.target.value)}
    maxRows={4}
    fullWidth={fullWidth}
    margin={'normal'}
    InputLabelProps={{ shrink: true, color: 'secondary' }}
    variant="standard"
    disabled={disabled}
    inputProps={inputProps}
  />
)

export default withTranslator(StyledTextArea)
