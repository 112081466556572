import React from 'react'
import styled from 'styled-components'
import { UIStyles } from '../../theme'
import { Link } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { StyledTooltip } from '../UIComponents/StyledTooltip'

const Wrapper = styled.div`
  transition: right 0.3s ease-in-out;
  display: inline-flex;
  height: 100%;
`
const IconWrapper = styled(Wrapper)`
  background-color: ${props => (props.disabled ? UIStyles.blueGrey : 'transparent')};
  &,
  > a {
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    text-decoration: none;
  }
`

const StyledLink = styled(Link)`
  height: 100%;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover > i {
    color: ${props => !props.disabled && UIStyles.vomitColor};
  }
`

const StyledIcon = styled.i`
  font-size: 35px;
  color: ${props => (props.disabled ? UIStyles.vomitColor : UIStyles.lightGrey)};
`

export const DashboardViewerToggler = props => {
  const { t, locale, isLayers, isDashboard, isBiLabs, selectFields, selectedFieldsLength, biLabsUrl, hasRoleDeactivateDashboard } = props
  const generateUrl = route => (locale !== 'es' ? '/' + locale : '') + '/' + route
  const onLayersSwitch = () => {
    if (selectedFieldsLength > 0) {
      selectFields()
    }
  }

  return (
    <Wrapper>
      <IconWrapper disabled={isLayers}>
        <StyledLink onClick={onLayersSwitch} to={generateUrl('layers')} disabled={isLayers}>
          <StyledTooltip title={t('layers')}>
            <StyledIcon data-testid="icon-visor" className="fa fa-regular fa-map" disabled={isLayers} />
          </StyledTooltip>
        </StyledLink>
      </IconWrapper>

      {!hasRoleDeactivateDashboard && (
        <IconWrapper disabled={isDashboard}>
          <StyledLink to={generateUrl('dashboard')} disabled={isDashboard} id="link-dashboard">
            <StyledTooltip title={t('dashboard')}>
              <StyledIcon data-testid="icon-dashboard" className="fa fa-chart-column" disabled={isDashboard} />
            </StyledTooltip>
          </StyledLink>
        </IconWrapper>
      )}

      {!isEmpty(biLabsUrl) && (
        <IconWrapper disabled={isBiLabs}>
          <StyledLink to={generateUrl('bilabs')} disabled={isBiLabs}>
            <StyledTooltip title={'BI Labs'}>
              <StyledIcon className="fa fa-chart-bar" disabled={isBiLabs} />
            </StyledTooltip>
          </StyledLink>
        </IconWrapper>
      )}
    </Wrapper>
  )
}
