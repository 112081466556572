import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import { PRED_TECH_LOG_TYPES } from '../constants'
const { SAC_HA, PROD_HA, NITRO, TCH_PRED, POL_PRED } = PRED_TECH_LOG_TYPES

export const getPredTechLogs = get('predTech')

export const getLogsByType = logType => pipe(getPredTechLogs, get(logType))

export const getSacHa = getLogsByType(SAC_HA)

export const getProdHa = getLogsByType(PROD_HA)

export const getNitro = getLogsByType(NITRO)

export const getTchPred = getLogsByType(TCH_PRED)

export const getPolPred = getLogsByType(POL_PRED)

export const getIdealParameters = pipe(getPredTechLogs, get('idealParameters'))
