import { useDraggable } from '@dnd-kit/core'
import { Button } from '@mui/material'
import styled from 'styled-components'
import map from 'lodash/map'

/**
 * DraggableDay component with drag-and-drop functionality.
 * */

export const DraggableDay = ({ formattedDate, currentDateFlightGroups, isSelected, dayInCurrentMonth, date, selectDate }) => {
  const { isDragging, attributes, listeners, setNodeRef, transform } = useDraggable({
    id: formattedDate,
    disabled: isSelected,
    data: {
      date: formattedDate,
      currentDateFlightGroups
    }
  })

  return (
    <>
      <Button
        className={`
          ${isSelected && 'Mui-selected'} 
          ${currentDateFlightGroups && 'Mui-withSources'}
          ${dayInCurrentMonth.today && 'Mui-today'} 
          `}
        type="button"
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        key={formattedDate}
        onClick={selectDate}
      >
        {date.date()}
        <DotsWrapper>
          {map(currentDateFlightGroups, flightGroup => (
            <Dot key={flightGroup.source} color={flightGroup.color} />
          ))}
        </DotsWrapper>
        <span className="MuiTouchRipple-root" />
      </Button>
      {isDragging ? <DraggableDayStyle transform={transform}>{date.date()}</DraggableDayStyle> : null}
    </>
  )
}

const DotsWrapper = styled.div`
  position: absolute;
  display: flex;
  bottom: 3px;
`
const Dot = styled.div`
  width: 5px;
  height: 5px;
  background: ${props => props.color};
  border-radius: 50%;
  margin: 0 1px;
`
const DraggableDayStyle = styled.div`
  transform: ${({ transform }) => (transform ? `translate(${transform.x}px, ${transform.y - 36}px)` : '')};
  position: fixed;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  font-size: 12.5px;
`
