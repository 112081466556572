import { saveAs } from 'file-saver'
import papa from 'papaparse'
import moment from 'moment'
import invoke from 'lodash/invoke'
import { errorNotification } from './notifications'
import { fetchRequestWrapper } from './ui'

export const START_SCREENSHOT_CAPTURE = 'START_SCREENSHOT_CAPTURE'
export const END_SCREENSHOT_CAPTURE = 'END_SCREENSHOT_CAPTURE'
export const SCREENSHOT_SUCCESS = 'SCREENSHOT_SUCCESS'
export const SCREENSHOT_ERROR = 'SCREENSHOT_ERROR'

export const DOWNLOAD_CSV_DATA_SUCCESS = 'DOWNLOAD_CSV_DATA_SUCCESS'
export const DOWNLOAD_CSV_DATA_FAIL = 'DOWNLOAD_CSV_DATA_FAIL'

const REQUEST_S3_URL = 'REQUEST_S3_URL'
export const RECEIVE_S3_URL = 'RECEIVE_S3_URL'

export const downloadCSVData = ({ data, fileName = 'data', parserOptions = {} }) => dispatch => {
  try {
    const csv = papa.unparse(data, { delimiter: ';', ...parserOptions })
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
    const timeNow = moment().format('DD_MM_YYYY_hh_mm_ss')
    saveAs(blob, `${fileName}_${timeNow}.csv`)
    dispatch({ type: DOWNLOAD_CSV_DATA_SUCCESS, csv })
  } catch (error) {
    dispatch({ type: DOWNLOAD_CSV_DATA_FAIL, error })
  }
}

const defaultDisplayMediaOptions = {
  video: {
    cursor: 'never'
  },
  audio: false
}

export const captureScreenshot = ({
  onScreenshotSuccess = base64Screenshot => ({ type: SCREENSHOT_SUCCESS, base64Screenshot }),
  onScreenshotError = () => ({ type: SCREENSHOT_ERROR }),
  displayMediaOptions = defaultDisplayMediaOptions
}) => dispatch => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    dispatch({ type: START_SCREENSHOT_CAPTURE })

    try {
      const mediaStream = await invoke(global, 'navigator.mediaDevices.getDisplayMedia', displayMediaOptions)

      const video = document.createElement('video')
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      video.autoplay = true
      video.srcObject = mediaStream

      const timeToMountVideoAndCanvas = 600
      setTimeout(() => {
        try {
          const [w, h] = [video.videoWidth, video.videoHeight]
          canvas.width = w
          canvas.height = h
          context.drawImage(video, 0, 0, w, h)
          const base64Screenshot = canvas.toDataURL()

          const tracks = video.srcObject.getTracks()

          tracks.forEach(track => track.stop())
          video.srcObject = null

          dispatch({ type: END_SCREENSHOT_CAPTURE })
          dispatch(onScreenshotSuccess(base64Screenshot))
          resolve(base64Screenshot)
        } catch {
          dispatch({ type: END_SCREENSHOT_CAPTURE })
          dispatch(errorNotification('Not supported by browser'))
          dispatch(onScreenshotError())
          // eslint-disable-next-line prefer-promise-reject-errors
          reject()
        }
      }, timeToMountVideoAndCanvas)
    } catch {
      dispatch({ type: END_SCREENSHOT_CAPTURE })
      dispatch(errorNotification('Not supported by browser'))
      dispatch(onScreenshotError())
      // eslint-disable-next-line prefer-promise-reject-errors
      reject()
    }
  })
}

export const getS3Url = s3Path =>
  fetchRequestWrapper({
    route: 'get_s3_url',
    urlParams: { path: s3Path },
    requestType: REQUEST_S3_URL,
    overlay: false,
    onSuccess: data => ({ type: RECEIVE_S3_URL, data })
  })
