import React from 'react'
import { withCurrentRoute } from '../HOCComponents/withCurrentRoute'

import LayersLeftMenuBottom from '../LayersComponents/LayersLeftMenuBottom'
import DashboardLeftMenuBottom from '../DashboardComponents/DashboardLeftMenuBottom'

const RenderInViewComponent = ({ IsInView, Component }) => (IsInView ? <Component /> : null)

function LeftBarBottom({ currentLocation }) {
  return (
    <div>
      <RenderInViewComponent IsInView={currentLocation.isViewer} Component={LayersLeftMenuBottom} />
      <RenderInViewComponent IsInView={currentLocation.isDashboard} Component={DashboardLeftMenuBottom} />
    </div>
  )
}

export default withCurrentRoute(LeftBarBottom)
