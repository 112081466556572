import React, { useEffect, useState } from 'react'
import StyledFloatInput from '../../UIComponents/StyledFloatInput'
import { withTranslator } from '../../HOCComponents/withTranslator'
import { ToolTitle, ToolWrapper } from './LayoutComponents'
import { UIStyles } from '../../../theme'
import isString from 'lodash/isString'
import first from 'lodash/first'
import toNumber from 'lodash/toNumber'

const inputStyle = { color: UIStyles.whiteColor }

function AffectedHaInput({ t, affectedHa, setAffectedHa, surfaceUnit }) {
  const [ha, setHa] = useState(null)

  useEffect(() => {
    if (isString(affectedHa)) {
      const affectedHaNumber = toNumber(first(affectedHa.split(' ')))
      setAffectedHa(affectedHaNumber)
    }

    setHa(affectedHa)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affectedHa])

  const onBlur = () => {
    if (Number(ha)) {
      return setAffectedHa(Number(ha))
    }
    if (ha === '') {
      setAffectedHa(null)
    }
  }

  return (
    <ToolWrapper>
      <ToolTitle>
        {t('Affected Surface')} ({surfaceUnit})
      </ToolTitle>
      <StyledFloatInput
        name="affectedHaInput"
        onChange={value => setHa(value)}
        inputValue={ha || ''}
        style={inputStyle}
        onBlur={onBlur}
        fullWidth
        margin="none"
      />
    </ToolWrapper>
  )
}

export default withTranslator(AffectedHaInput)
