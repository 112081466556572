import { fetchGraphqlWrapper } from './ui'
import { loader } from 'graphql.macro'
import { getSeasonsByIds } from '../selectors/seasons'

import { addSeasons } from './fieldSeasonForm'
import { isFieldEditView } from '../selectors/viewmode'
import {
  getFirstSelectedFieldSeasonTimelineIds,
  getSeasonIdsWithinDateRangeByFieldIds,
  getFieldSeasonsTimelineIds,
  getFieldSeasonTimelineFirstSeasonIds
} from '../selectors/fieldsSeasonsTimeline'

import { getFieldCustomerById, getSelectedFieldsIds, getSelectedPreviewField } from '../selectors/fields'
import { isReadOnly } from '../selectors/fieldSeasonForm'
import { getDeliverablesBySource } from '../selectors/customersDeliverables'

import get from 'lodash/get'
import map from 'lodash/map'

export const REQUEST_FIELDS_SEASONS_BY_IDS = 'REQUEST_FIELDS_SEASONS_BY_IDS'
export const RECEIVE_FIELDS_SEASONS_BY_IDS = 'RECEIVE_FIELDS_SEASONS_BY_IDS'
export const REQUEST_FIELDS_SEASON_PARAMETERS = 'REQUEST_FIELDS_SEASON_PARAMETERS'
export const RECEIVE_FIELDS_SEASON_PARAMETERS = 'RECEIVE_FIELDS_SEASON_PARAMETERS'

const _addDeliverablesToSeason = (season, state) => {
  const seasonTypeId = get(season, ['type', 'id'])
  const fieldId = get(season, 'fieldId')
  const customer = getFieldCustomerById(state, fieldId)
  const customerId = get(customer, 'id')
  const deliverables = getDeliverablesBySource(customerId, seasonTypeId)(state)

  return {
    ...season,
    deliverables
  }
}

export const receiveFieldsSeasonsByIds = (seasons, seasonsIds) => (dispatch, getState) => {
  const seasonsWithDeliverables = map(seasons, season => {
    const state = getState()
    return _addDeliverablesToSeason(season, state)
  })
  const state = getState()

  const editFormOpen = isFieldEditView(state)

  if (!editFormOpen && !isReadOnly(state)) {
    return dispatch({ type: RECEIVE_FIELDS_SEASONS_BY_IDS, seasons: seasonsWithDeliverables })
  }

  dispatch({ type: RECEIVE_FIELDS_SEASONS_BY_IDS, seasons: seasonsWithDeliverables })
  const formSeasons = getSeasonsByIds(seasonsIds)(getState())
  return dispatch(addSeasons(formSeasons))
}
const getFieldSeasonsByIdsQuery = loader('../graphql/seasons/getFieldSeasonsByIds.gql').loc.source.body

const fetchFieldSeasonsByIds = ({ seasonIds }) => dispatch => {
  return dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_SEASONS_BY_IDS,
      variables: { fieldSeasonIds: seasonIds },
      query: getFieldSeasonsByIdsQuery,
      overlay: false,
      onSuccess: ({ seasons }) => receiveFieldsSeasonsByIds(seasons, seasonIds)
    })
  )
}

export const fetchPreviewFieldSeasons = () => (dispatch, getState) => {
  const state = getState()
  const selectedFieldId = getSelectedPreviewField(state)
  const seasonIds = getFieldSeasonsTimelineIds(selectedFieldId)(state)
  return dispatch(fetchFieldSeasonsByIds({ seasonIds }))
}

export const fetchFirstSelectedFieldSeasons = () => (dispatch, getState) => {
  const seasonIds = getFirstSelectedFieldSeasonTimelineIds(getState())
  return dispatch(fetchFieldSeasonsByIds({ seasonIds }))
}

// deprecated
export const fetchInitialFieldSeasons = () => (dispatch, getState) => {
  const seasonIds = getFieldSeasonTimelineFirstSeasonIds(getState())
  return dispatch(fetchFieldSeasonsByIds({ seasonIds }))
}

export const fetchSelectedFieldsSeasonsByDateRange = (from, to) => (dispatch, getState) => {
  const state = getState()
  const selectedFieldsIds = getSelectedFieldsIds(state)
  const seasonIds = getSeasonIdsWithinDateRangeByFieldIds(state, selectedFieldsIds, from, to)
  return dispatch(fetchFieldSeasonsByIds({ seasonIds }))
}

const getFieldSeasonParametersQuery = loader('../graphql/seasons/getFieldSeasonParameters.gql').loc.source.body

export const fetchFieldSeasonParameters = () => dispatch => {
  return dispatch(
    fetchGraphqlWrapper({
      requestType: REQUEST_FIELDS_SEASON_PARAMETERS,
      query: getFieldSeasonParametersQuery,
      onSuccess: ({ parameters }) => ({ type: RECEIVE_FIELDS_SEASON_PARAMETERS, parameters })
    })
  )
}
