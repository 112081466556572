import pipe from 'lodash/fp/pipe'
import get from 'lodash/fp/get'
import keys from 'lodash/fp/keys'
import reduce from 'lodash/fp/reduce'
import pick from 'lodash/fp/pick'
import map from 'lodash/fp/map'
import toNumber from 'lodash/fp/toNumber'
import valuesIn from 'lodash/fp/valuesIn'
import orderBy from 'lodash/fp/orderBy'
import { createSelector } from 'reselect'

export const getSeasons = get('seasons')

export const getSeasonsIds = pipe(getSeasons, keys, map(toNumber))

export const getSeasonsByIdObject = createSelector(
  getSeasons,
  reduce(
    (acc, season) => ({
      ...acc,
      [season.id]: season
    }),
    {}
  )
)

export const getSeasonsByIds = seasonIds => state => pipe(getSeasons, pick(seasonIds), valuesIn, orderBy('startDate', 'desc'))(state)

export const getSeasonById = seasonId => pipe(getSeasons, get(seasonId))

export const getFieldIdBySeasonId = seasonId => pipe(getSeasonById(seasonId), get('fieldId'))

export const getFieldSeasonParameters = get('fieldSeasonParameters')

export const getDeliverablesBySeasonId = seasonId => pipe(getSeasonById(seasonId), get('deliverables'))

export const getDeliverablesBySeasonIdAndSource = (seasonId, source) => pipe(getDeliverablesBySeasonId(seasonId), get(source))
