import React from 'react'

import { withTranslator } from '../HOCComponents/withTranslator'
import StyledListButton from '../UIComponents/StyledListButton'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

export const SatelliteButton = withTranslator(({ icon, label, toggleBaseMap, t }) => {
  const handleClick = () => {
    toggleBaseMap()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_VIEW_STATISTICS, {
      button: label,
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return <StyledListButton label={t(label)} onClick={handleClick} labelDistance={6} icon={icon} />
})
