import React, { useEffect, useState } from 'react'
import styled, { injectGlobal } from 'styled-components'
import { Navigate, useLocation } from 'react-router-dom'
import logoWhiteUrl from '../assets/img/logoWhite.png'
import layersLogo from '../assets/img/logo-layers-full.png'
import azureLogo from '../assets/img/logo-azure-blue.svg'
import { UIStyles } from '../theme'
import { LargeButton } from './UIComponents/buttons'
import userStoredData from '../userStoredData'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { FALLBACK_LANGUAGE, ROUTES, TRANSLATED_LINKS } from '../constants'
import Routing from '../Routing'
import LanguageButtons from '../../src/components/UserComponents/LanguageButtons'
import StyledTextInput from './UIComponents/StyledTextInput'
import capitalize from 'lodash/capitalize'

import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import { loginRequest } from '../azureAuthConfig'

import { identifyUser } from './analytics/analytics'
import { useDispatch, useSelector } from 'react-redux'
import { localeSet } from '../features/locale/localeSlice'
import { setMessagesFromLocale } from '@layers-frontend/commons/store/actions/messages'

const mediaQueries = style => `@media (max-width: 768px) { ${style} }`
// eslint-disable-next-line no-undef-init, prefer-const
let popup = undefined

const LoginWrapper = styled.div`
  overflow: auto;
  height: 100%;
  display: flex;
  position: relative;
  z-index: 5;
`

export const RadialGradientBG = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: radial-gradient(119.22% 119.22% at 4.02% 8.7%, #4d2359 0%, #1c2541 36.46%, #1a3844 69.41%, #688245 100%);
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
`

const LoginContent = styled.div`
  margin: auto;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const LanguageButtonsWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 50px;
  min-width: 200px;
  z-index: 6;
  ${mediaQueries('right: 20px; min-width: 130px; & > div, a { font-size: 24px; } & .fas { display: none; } ')}
`

const HemavBrandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
  & > img {
    margin-bottom: 0;
  }
`
const HemavLogoWrapper = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 20px;
  ${mediaQueries('display: none')}
`

const HemavLogoImg = styled.img`
  width: 90px;
  margin-left: 10px;
  ${mediaQueries('width: 10rem;')}
`

const HemavLogoLabel = styled.label`
  color: ${UIStyles.whiteColor};
  margin-bottom: 0px;
`

const HLineWrapper = styled.div`
  display: flex;
  width: 230px;
  align-items: center;
  ${mediaQueries('font-size: 16px; ')}
`
const Or = styled.span`
  flex: 1;
  text-align: center;
  color: ${UIStyles.whiteColor};
`
const HLine = styled.div`
  display: flex;
  flex: 2;
  background-color: ${UIStyles.whiteColor};
  height: 1px;
`

const AzureImg = styled.img`
  width: 20px;
  height: 20px;

  ${mediaQueries('margin: 10px;')}
`

const StyledLargeButton = styled(LargeButton)`
  background: ${UIStyles.whiteColor};
  padding: 10px 30px;
  width: 230px;
  margin: 10px 0 15px;
  font-style: normal;
  ${mediaQueries('font-size: 16px;  margin: 5px 20px')}
`

const StyledForgotPass = styled.a`
  cursor: pointer;
  text-align: left;
  width: 230px;
  margin-bottom: 15px;
  &:hover {
    color: ${UIStyles.vomitColor};
  }
  ${mediaQueries('font-size: 16px;')}
`

const StyledIcon = styled.i`
  padding-right: 10px;
`

const SocialLoginButton = styled.div`
  background-color: ${UIStyles.transparent};
  border: 1px solid ${UIStyles.whiteColor};
  border-color: ${UIStyles.whiteColor};
  cursor: pointer;
  border-radius: 7px;
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: background-color 1s;
  width: 230px;
  padding: 5px 0;

  :hover {
    background-color: ${UIStyles.blueLightHover};
  }
  ${mediaQueries('margin: 15px 30px; padding: 0')}
`
const SocialLoginLabel = styled.span`
  background: ${UIStyles.transparent};
  color: ${UIStyles.whiteColor};
  padding: 5px 14px;

  ${mediaQueries('font-size: 16px;  font-size: 16px; width: 200px;')}
`
const LayersImg = styled.img`
  margin-bottom: 20px;
  ${mediaQueries('width: 200px')}
`

const ContactWrapper = styled.div`
  margin: auto 20px 20px;
  display: flex;
  flex-direction: row;
  width: 230px;
  justify-content: space-between;
  align-items: center;
  ${mediaQueries('width: 240px; font-size: 16px;')}
`

const ContactLabel = styled.label`
  font-weight: 500;
  margin-bottom: 0;
  color: ${UIStyles.whiteColor};
  flex-grow: 1;

  ${mediaQueries('font-size: 15px;')}
`

const ContactLink = styled.button`
  background-color: ${UIStyles.transparent};
  border: 1px solid ${UIStyles.whiteColor};
  border-radius: 7px;
  color: ${UIStyles.whiteColor};
  padding: 5px 7px;
  cursor: pointer;
  transition: background-color 1s;
  margin-left: 10px;
  flex-grow: 1;

  &:hover {
    background-color: ${UIStyles.blueButton};
    text-decoration: none;
    color: ${UIStyles.whiteColor};
  }

  ${mediaQueries('font-size: 15px; padding: 5px 7px;')}
`

const textFieldStyle = { width: '230px' }

const AzureButton = ({ t, fetchApiKeyWithOauthToken, fetchProfileAndRedirect, setAzureAccount }) => {
  const { instance, accounts } = useMsal()
  const isAuthenticated = useIsAuthenticated()

  useEffect(() => {
    if (isAuthenticated) {
      const request = {
        ...loginRequest,
        account: accounts[0]
      }
      // get oauth access token
      instance
        .acquireTokenSilent(request)
        .then(response => {
          setAzureAccount(accounts[0])
          // get hemav token
          fetchApiKeyWithOauthToken({ oauthData: { accessToken: response.accessToken } }).then(() => {
            fetchProfileAndRedirect()
          })
        })
        .catch(e => {
          instance.acquireTokenPopup(request).then(response => {
            console.log(response.accessToken)
          })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  const azureLoginHandler = () => {
    if (!userStoredData.API_KEY && isEmpty(accounts)) {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e)
      })
    }
  }
  return (
    <SocialLoginButton onClick={azureLoginHandler}>
      <AzureImg src={azureLogo} />
      <SocialLoginLabel>{t('Quick start with Azure')} </SocialLoginLabel>
    </SocialLoginButton>
  )
}

const Login = ({
  fetchProfile,
  t,
  isLoginLoading,
  isInvalidProfile,
  userName,
  user,
  isProfileLoading,
  fetchLogin,
  errorNotification,
  fetchApiKeyWithOauthToken
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const availableLocales = useSelector(state => state.config.i18n.availableLocales)

  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [azureAccount, setAzureAccount] = useState()

  useEffect(() => {
    const storedLocale = userStoredData.locale
    const isStoredLocaleAvailable = availableLocales.some(available => storedLocale === available.locale)
    const locale = isStoredLocaleAvailable ? storedLocale : FALLBACK_LANGUAGE

    dispatch(localeSet(locale))
    dispatch(setMessagesFromLocale(locale))
  }, [availableLocales, dispatch])

  useEffect(() => {
    injectGlobal`
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    input[type=number] {
      -moz-appearance: textfield;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition: background-color 600000s 0s, color 600000s 0s;
    }
    .loginInput input:-webkit-autofill,
    .loginInput input:-webkit-autofill:hover, 
    .loginInput input:-webkit-autofill:focus,
    .loginInput textarea:-webkit-autofill,
    .loginInput textarea:-webkit-autofill:hover,
    .loginInput textarea:-webkit-autofill:focus,
    .loginInput select:-webkit-autofill,
    .loginInput select:-webkit-autofill:hover,
    .loginInput select:-webkit-autofill:focus {
      border: 0px;
      -webkit-text-fill-color: white;
      color: white;
      -webkit-box-shadow: unset;
      box-shadow: unset;
      background-color: transparent;
      transition: background-color 5000s ease-in-out 0s;
    }
  @media (max-width: 768px) {
    .loginInput{
    font-size: 26px !important;
    width: 456px !important;
    height: 82px !important;
    }
  }
  `
  }, [])

  useEffect(() => {
    window.addEventListener('message', e => {
      const messageData = get(e, 'data')
      const messageSource = get(messageData, 'source')
      if (messageSource === 'symfony') {
        const token = get(messageData, ['payload', 'token'])
        userStoredData.setToken(token)
        popup.close()
        fetchProfileAndRedirect()
      }
    })
    if (userStoredData.API_KEY) {
      fetchProfileAndRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user && user.id) {
      const { id, roles } = user
      const username = azureAccount ? get(azureAccount, 'name') : get(user, 'username')
      const email = azureAccount ? get(azureAccount, 'username') : get(user, 'email')
      const azureId = azureAccount ? get(azureAccount, 'localAccountId') : undefined

      const identifyProps = {
        username,
        email,
        roles,
        azureId,
        platform: 'web'
      }
      identifyUser(`${id}`, identifyProps)
    }
  }, [user, azureAccount])

  const fetchProfileAndRedirect = token => {
    if (!userStoredData.API_KEY) {
      userStoredData.setToken(token)
    }

    fetchProfile().catch(() => {
      userStoredData.removeToken()
    })
  }

  const loginHandler = () => {
    fetchLogin({ login, password })
      .then(fetchProfileAndRedirect)
      .catch(({ message }) => {
        const unauthorizedStatus = 401
        const isUnauthorized = message.includes(unauthorizedStatus)
        errorNotification(isUnauthorized ? t('Invalid credentials') : t('An error occurred from the server. Please try again'))
        setPassword('')
      })
  }

  const hasFilledUserAndPassword = () => {
    return !isEmpty(login) && !isEmpty(password)
  }

  const onKeyPressHandler = ({ key }) => {
    if (key === 'Enter' && hasFilledUserAndPassword) {
      loginHandler()
    }
  }

  const onLoginChange = login => {
    console.log(login)
    setLogin(login)
  }
  const onPasswordChange = password => setPassword(password)

  const hasLoggedIn = !!userName
  const isCheckingCredentials = isLoginLoading || userStoredData.API_KEY || isProfileLoading

  const contactHandler = () => {
    window.open(TRANSLATED_LINKS[userStoredData.locale], '_self')
  }

  if (hasLoggedIn) {
    const previousePage = get(location, 'state.redirectedFrom')
    const redirectRoute = previousePage || `/${ROUTES.LAYERS_ROUTE}`

    return <Navigate to={redirectRoute} replace />
  }

  if (isCheckingCredentials) {
    return null
  }

  if (isInvalidProfile) {
    userStoredData.removeToken()
  }

  const placeHolderUser = (
    <>
      <StyledIcon className="fas fa-user" />
      <span>{t('Username')}</span>
    </>
  )

  const placeHolderPass = (
    <>
      <StyledIcon className="fas fa-lock" />
      <span>{t('Password')}</span>
    </>
  )

  return (
    <>
      <LanguageButtonsWrapper>
        <LanguageButtons showGlobeIcon inputStyle={{ marginLeft: '10px' }} />
      </LanguageButtonsWrapper>
      <LoginWrapper>
        <RadialGradientBG aria-hidden="true" />
        <LoginContent>
          <HemavBrandingWrapper>
            <LayersImg src={layersLogo} />
          </HemavBrandingWrapper>
          <Form onKeyDown={onKeyPressHandler}>
            <StyledTextInput
              label={placeHolderUser}
              onChange={onLoginChange}
              value={login}
              textFieldStyle={textFieldStyle}
              id="input-user"
              inputProps={{ 'data-testid': 'user-input' }}
            />
            <StyledTextInput
              label={placeHolderPass}
              onChange={onPasswordChange}
              type="password"
              value={password}
              textFieldStyle={textFieldStyle}
              id="input-password"
              inputProps={{ 'data-testid': 'password-input' }}
            />
            <StyledForgotPass className="forget_password" href={'//' + Routing.locationHostName + '/resetting/request'} data-testid="forgot-password-link">
              {t('Did you forget your password ?')}
            </StyledForgotPass>
            <StyledLargeButton label={t('Log in').toUpperCase()} onClick={loginHandler} id="button-login" testId="login-button" />
          </Form>
          <HLineWrapper>
            <HLine />
            <Or>{t('or')}</Or>
            <HLine />
          </HLineWrapper>
          <AzureButton
            t={t}
            fetchApiKeyWithOauthToken={fetchApiKeyWithOauthToken}
            fetchProfileAndRedirect={fetchProfileAndRedirect}
            setAzureAccount={setAzureAccount}
          />
          <HemavLogoWrapper>
            <HemavLogoLabel>Powered by </HemavLogoLabel>
            <HemavLogoImg src={logoWhiteUrl} />
          </HemavLogoWrapper>
          <ContactWrapper>
            <ContactLabel>{t('Not a user yet ?')}</ContactLabel>
            <ContactLink onClick={contactHandler}>{capitalize(t('get a free trial'))}</ContactLink>
          </ContactWrapper>
        </LoginContent>
      </LoginWrapper>
    </>
  )
}

export default Login
