import React from 'react'
import { withTranslator } from '../../HOCComponents/withTranslator'
import styled from 'styled-components'

const Title = styled.h2`
  font-size: 2.5rem;
  text-align: center;
  width: 100%;
  margin: 0 1.5rem;
`

function ModalTitle({ t }) {
  return <Title>{t('report')} </Title>
}

export default withTranslator(ModalTitle)
