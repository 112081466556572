import { connect } from 'react-redux'
import Measures from '../../components/editorComponents/Measures'

import { getEditorPresentData } from '../../selectors/editor'
import { getUserConfig } from '@layers-frontend/commons/store/selectors/user'

const mapStateToProps = state => ({
  activeTool: getEditorPresentData(state, 'activeTool'),
  isMeasureToolsActive: getEditorPresentData(state, 'isMeasureToolsActive'),
  config: getUserConfig(state)
})

export default connect(mapStateToProps, null, null)(Measures)
