import React from 'react'
import StyledListButton from '../UIComponents/StyledListButton'
import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

function ReportManagerButton({ t, openNewReportManagerModal }) {
  const handleClick = () => {
    openNewReportManagerModal()
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_MANAGE_REPORT, {
      button: 'Manage report',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return <StyledListButton label={t('Manage report')} onClick={handleClick} labelDistance={6} icon="fas fa-envelope-open-text" testId="manage-report-button" />
}

export default ReportManagerButton
