import samplesFormCommon, { initialState as initialSampleFormState } from '@layers-frontend/commons/store/reducers/samplesForm'
import { sampleFormFields } from '@layers-frontend/commons/constants'
import { ADD_MARKER } from '../actions/editor'
import isNil from 'lodash/isNil'
import { RESET_SAMPLE_FORM_EXTRA_PARAMETERS, SET_SAMPLE_FORM_EXTRA_PARAMETERS } from '../actions/samplesForm'
import reduce from 'lodash/reduce'
import get from 'lodash/get'
const { LOCATION, EXTRA_PARAMETERS } = sampleFormFields

export default function samplesForm(state = initialSampleFormState, action = {}) {
  switch (action.type) {
    case SET_SAMPLE_FORM_EXTRA_PARAMETERS: {
      if (isNil(action.parameterIds)) return state

      const extraParam = reduce(
        action.parameterIds,
        (accumulator, parameter) => {
          if (get(state[EXTRA_PARAMETERS], parameter)) {
            return { ...accumulator, [parameter]: state[EXTRA_PARAMETERS][parameter] }
          }

          return { ...accumulator, [parameter]: null }
        },
        {}
      )

      return {
        ...state,
        [EXTRA_PARAMETERS]: extraParam,
        extraParametersOrder: action.parameterIds
      }
    }
    case ADD_MARKER: {
      return {
        ...state,
        [LOCATION]: action.payload.geometry.coordinates
      }
    }

    case RESET_SAMPLE_FORM_EXTRA_PARAMETERS: {
      return {
        ...state,
        [EXTRA_PARAMETERS]: [],
        extraParametersOrder: []
      }
    }

    default:
      return {
        ...samplesFormCommon(state, action)
      }
  }
}
