import { compose } from 'redux'
import DrawingTools from '../../components/ToolsComponents/drawingTools'
import MeasuresBar from '../../components/ToolsComponents/measuresbar'
import { SatelliteButton } from '../../components/ToolsComponents/satelliteButton'
import ActionButton from '../../components/ToolsComponents/ActionButton'
import DownloadGeoJsonButton from '../../components/ToolsComponents/DownloadGeoJsonButton'
import ToolBar from '../../components/ToolsComponents/toolbar'
import UndoActions from '../../components/ToolsComponents/undoActions'
import { withAuthentication } from '../../components/HOCComponents/withAuthentication'
import { withTutorial } from '../../components/HOCComponents/withTutorial'
import { connectDrawingTools } from '../../components/HOCComponents/connectDrawingTools'
import { connectMeasures } from '../../components/HOCComponents/connectMeasures'
import { connectToolBar } from '../../components/HOCComponents/connectToolBar'
import { connectEditorMode } from '../../components/HOCComponents/connectEditorMode'
import { connectSatellite } from '../../components/HOCComponents/connectSatellite'
import { connectUndo } from '../../components/HOCComponents/connectUndo'
import { connectFieldsGroup } from '../../components/HOCComponents/connectFieldsGroup'
import { withCurrentRoute } from '../../components/HOCComponents/withCurrentRoute'
import { USER_ROLES } from '../../constants'

import { MEASURES_TUTORIAL, SATELLITE_TUTORIAL } from '../../actions/tutorial'

const { ROLE_AGRO, ROLE_COOPERATIVE } = USER_ROLES

export const DrawingToolsContainer = connectDrawingTools(withAuthentication([ROLE_AGRO, ROLE_COOPERATIVE])(DrawingTools))
export const MeasuresBarContainer = connectMeasures(withTutorial(MEASURES_TUTORIAL)(MeasuresBar))
export const SatelliteButtonContainer = connectSatellite(withTutorial(SATELLITE_TUTORIAL)(SatelliteButton))
export const ToolBarContainer = compose(connectEditorMode, connectToolBar, withCurrentRoute)(ToolBar)
export const UndoActionsContainer = connectUndo(withAuthentication([ROLE_AGRO, ROLE_COOPERATIVE])(UndoActions))
export const ActionButtonContainer = connectFieldsGroup(withCurrentRoute(ActionButton))
export const DownloadGeoJsonContainer = connectFieldsGroup(DownloadGeoJsonButton)
