import React, { useState } from 'react'
import styled from 'styled-components'

const Button = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Icon = styled.img`
  width: 20px;
  height: 20px;
`

/**
 * IconButton component that changes its icon based on hover and selected states.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.defaultIcon - The default icon source.
 * @param {string} props.hoverIcon - The icon source to use on hover.
 * @param {string} props.selectedIcon - The icon source to use when selected.
 * @param {Function} props.onClick - Function to call when the button is clicked.
 * @param {boolean} selected - The button is selected.
 * @returns {JSX.Element} The rendered component.
 */

const IconButton = ({ defaultIcon, hoverIcon, selectedIcon, onClick, selected, isDisabled }) => {
  const [isSelected, setIsSelected] = useState(selected)
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => {
    setIsSelected(!isSelected)
    onClick()
  }

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  let iconSrc = defaultIcon
  if (isSelected) {
    iconSrc = selectedIcon
  } else if (isHovered) {
    iconSrc = hoverIcon
  }

  return (
    <Button
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={isSelected ? 'selected' : ''}
      disabled={isDisabled}
    >
      <Icon src={iconSrc} alt="icon" />
    </Button>
  )
}

export default IconButton
