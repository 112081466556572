import { v4 as uuidv4 } from 'uuid'

export function getUniqueId() {
  const storedUniqueId = localStorage.getItem('uniqueId')
  if (storedUniqueId) {
    return storedUniqueId
  } else {
    const newUniqueId = uuidv4()
    localStorage.setItem('uniqueId', newUniqueId)
    return newUniqueId
  }
}
