/* eslint-disable camelcase */
import { RECEIVE_FIELDS_WITH_PRED_TECH_LOGS, RECEIVE_FIELDS_WEEKLY, RECEIVE_FIELDS_IDEAL_SEASON, RECEIVE_FIELDS_SEASON_MAX } from '../actions/predTech'
import { PRED_TECH_LOG_TYPES } from '../constants'
// https://medium.datadriveninvestor.com/https-medium-com-sabesan96-why-you-should-choose-day-js-instead-of-moment-js-9cf7bb274bbd
import moment from 'moment'

const { SAC_HA, PROD_HA, ATR_PRED, NITRO, POL_PRED } = PRED_TECH_LOG_TYPES

const predTechInitialState = {
  idealParameters: false,

  [SAC_HA]: {
    idealCurve: [],
    idealMax: null,
    normalized: {}
  },
  [PROD_HA]: {
    idealCurve: [],
    idealMax: null,
    normalized: {}
  },
  [ATR_PRED]: {
    idealCurve: [],
    idealMax: null,
    normalized: {}
  },
  [NITRO]: {
    idealCurve: [],
    idealMax: null,
    normalized: {}
  },
  [POL_PRED]: {
    idealCurve: [],
    idealMax: null,
    normalized: {}
  }
}

const normalizeData = forecasts => {
  return forecasts.reduce((acc, { field_id, log_date, week_avg, week_from_plantation_date, customer_name, field_name }) => {
    const date = moment(log_date, 'YYYY-MM-DD')
    const dateNumber = date.valueOf() // Convert date to timestamp
    const isFuture = date.isAfter(moment(), 'day')

    if (!acc[field_id]) {
      acc[field_id] = { byWeek: {}, byDate: {} }
    }

    const weekIndex = week_from_plantation_date.toString()
    const dataObject = { week_from_plantation_date, avg: week_avg, isFuture, date: dateNumber, customer_name, field_name }

    acc[field_id].byWeek[weekIndex] = dataObject
    acc[field_id].byDate[dateNumber] = dataObject

    return acc
  }, {})
}

export function predTech(state = predTechInitialState, { type, logType, mode, data }) {
  switch (type) {
    case RECEIVE_FIELDS_WITH_PRED_TECH_LOGS: {
      const { predictions, season_max_value, idealCurve } = data
      const modifiedArray = predictions && normalizeData(predictions)

      return season_max_value && idealCurve
        ? {
            ...state,
            idealParameters: true,
            [logType]: {
              ...state[logType],
              normalized: { ...modifiedArray },
              idealMax: season_max_value,
              idealCurve
            }
          }
        : {
            ...state,
            [logType]: {
              ...state[logType],
              normalized: { ...modifiedArray }
            }
          }
    }

    case RECEIVE_FIELDS_WEEKLY: {
      return {
        ...state,
        [logType]: {
          ...state[logType],
          normalized: { ...normalizeData(data) }
        }
      }
    }

    case RECEIVE_FIELDS_IDEAL_SEASON: {
      return {
        ...state,
        [logType]: {
          ...state[logType],
          idealCurve: data,
          idealParameters: true
        }
      }
    }

    case RECEIVE_FIELDS_SEASON_MAX: {
      return {
        ...state,
        [logType]: {
          ...state[logType],
          idealMax: data[0].value,
          idealParameters: true
        }
      }
    }

    default:
      return state
  }
}
