import approve from 'approvejs'
import moment from 'moment'
export default class Validator {
  static isInteger = value =>
    approve.value(value, {
      ignoreNull: true,
      numeric: true
    }).approved

  static isDecimal = value =>
    approve.value(value, {
      ignoreNull: true,
      decimal: true
    }).approved

  static isRequired = value => approve.value(value, { required: true }).approved

  static isDate = value =>
    approve.value(value, {
      ignoreNull: true,
      format: /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[0-2])[/](19[0-9][0-9]|20[0-4][0-9]|2050)$/gi,
      min: 10,
      max: 10
    }).approved

  static isOnlyLetters = value =>
    approve.value(value, {
      ignoreNull: true,
      alpha: true
    }).approved

  static isSentinelDate = value => {
    const isValidDate = !!value && moment(value, 'DD/MM/YYYY').isValid()

    return (
      isValidDate &&
      approve.value(value, {
        ignoreNull: true,
        format: /^(0?[1-9]|[12][0-9]|3[01])[/](0?[1-9]|1[0-2])[/](201[6-9]|20[2-4][0-9]|2050)$/gi,
        min: 10,
        max: 10
      }).approved
    )
  }

  static isPastDate = value => {
    return moment(value, 'DD/MM/YYYY').isBefore(moment())
  }
}
