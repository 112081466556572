import { seti18n } from './locale'
import { fetchDeliverableTypes } from './deliverables'
import { fetchCustomers } from './users'
import {
  fetchFieldTypes,
  fetchIrrigationTypes,
  fetchFieldIds,
  fetchFilteredFields,
  fetchGeometries,
  fetchCustomerValues,
  fetchCutNumberValues,
  fetchFieldTypesValues,
  fetchSeasonLabelValues
} from './fields'

import { receiveAllData, receiveAllDashboardData } from './viewmode'

import { setFilteredFieldIds, fetchGlobalMapCentroids, fetchGlobalLayerByLegend, fetchGlobalLayerByDeliverableName } from './globalLayer'

import { fetchSamplePrefix } from './config'
import userStoredData from '../userStoredData'
import { fetchSampleTypes } from './sampleTypes'
import { fetchEox } from './eox'
import { setRequestStart, setRequestEnd } from './ui'
import { fetchFieldSeasonParameters } from './seasons'
import { fetchFieldsTileOrder } from './fieldsTileOrder'
import { fetchReportTypes, fetchReportStatuses } from './reports'
import { fetchCustomersDeliverables } from './customersDeliverables'

import { isUIRequestViewerDataInitializationSuccess } from '../selectors/ui'

export const initializeLocale = () => seti18n(userStoredData.locale)
export const REQUEST_DASHBOARD_DATA_INITIALIZATION = 'REQUEST_DASHBOARD_DATA_INITIALIZATION'
export const REQUEST_VIEWER_DATA_INITIALIZATION = 'REQUEST_VIEWER_DATA_INITIALIZATION'

export const initializeViewer = () => async dispatch => {
  dispatch(setRequestStart(REQUEST_VIEWER_DATA_INITIALIZATION))

  try {
    await dispatch(fetchEox())
  } catch (error) {
    console.log("Couldn't fetch EOX capabilities")
  }

  await initializeViewerData(dispatch)

  dispatch(setFilteredFieldIds())
  dispatch(receiveAllData())
  dispatch(setRequestEnd(REQUEST_VIEWER_DATA_INITIALIZATION))
}

const initializeViewerData = async dispatch => {
  const fetchInitialData = [
    dispatch(fetchDeliverableTypes()),
    dispatch(fetchCustomersDeliverables()),
    dispatch(fetchGlobalMapCentroids()),
    dispatch(fetchFieldIds())
  ]

  const fetchData = [
    dispatch(fetchGeometries()),
    dispatch(fetchGlobalLayerByLegend()),
    dispatch(fetchGlobalLayerByDeliverableName()),
    dispatch(fetchFieldTypes()),
    dispatch(fetchCustomers()),
    dispatch(fetchIrrigationTypes()),
    dispatch(fetchReportTypes()),
    dispatch(fetchReportStatuses()),
    dispatch(fetchFieldSeasonParameters()),
    dispatch(fetchFieldsTileOrder()),
    dispatch(fetchCustomerValues()),
    dispatch(fetchCutNumberValues()),
    dispatch(fetchFieldTypesValues()),
    dispatch(fetchSeasonLabelValues()),
    dispatch(fetchSampleTypes()),
    dispatch(fetchSamplePrefix()),
    dispatch(fetchFilteredFields())
  ]

  await Promise.all(fetchInitialData)
  return await Promise.all(fetchData)
}

export const initializeDashboard = () => (dispatch, getState) => {
  if (!isUIRequestViewerDataInitializationSuccess(getState())) {
    dispatch(initializeViewer())
  }
  dispatch(setRequestStart(REQUEST_DASHBOARD_DATA_INITIALIZATION))
  dispatch(receiveAllDashboardData())
  dispatch(setRequestEnd(REQUEST_DASHBOARD_DATA_INITIALIZATION))
}
