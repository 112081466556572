import reduce from 'lodash/reduce'
import get from 'lodash/get'
import { RECEIVE_FIELDS_TILE_ORDER } from '../storeConstants'

const initialState = {}

export default function fieldsTileOrder(state = initialState, action = {}) {
  switch (action.type) {
    case RECEIVE_FIELDS_TILE_ORDER:
      const data = get(action.data, 'tileOrders')

      return reduce(
        data,
        (accumulator, tileSubscription) => {
          const fieldId = get(tileSubscription, 'fieldId')
          return {
            ...accumulator,
            [fieldId]: accumulator[fieldId] ? [...accumulator[fieldId], tileSubscription] : [tileSubscription]
          }
        },
        {}
      )
    default:
      return state
  }
}
