import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { withTranslator } from '../components/HOCComponents/withTranslator'

import DashboardApp from '../components/DashboardApp'
import { addFieldsToGroup, selectFields, selectFlightGroup, flushFieldsGroup } from '../actions/fields'
import { toggleVisibility } from '../actions/deliverables'

import { hasDashboardAvailableFields } from '../selectors/availableFields'
import { getDashboardView, getSelectedFlightFilterId } from '../selectors/dashboard'
import { getConfigData } from '../selectors/config'
import { getUser, getBiLabsUrl, hasRoleDeactivateDashboard } from '@layers-frontend/commons/store/selectors/user'
import { errorNotification } from '../actions/notifications'
import { openGoToFieldModal } from '../actions/modal'
import { toggleFocusSearch } from '../actions/search'
import { initializeDashboard } from '../actions/initialization'

import '../utils'
import { getFlightGroups } from '../selectors/flightGroups'

const mapStateToProps = state => {
  const { locale } = state
  return {
    flightGroups: getFlightGroups(state),
    locale,
    user: getUser(state),
    dashboardView: getDashboardView(state),
    isDashboardReady: getConfigData(state, 'isDashboardReady'),
    isAppReady: getConfigData(state, 'isAppReady'),
    selectedFlightFilterId: getSelectedFlightFilterId(state),
    hasDashboardAvailableFields: hasDashboardAvailableFields(state),
    biLabsUrl: getBiLabsUrl(state),
    hasRoleDeactivateDashboard: hasRoleDeactivateDashboard(state)
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      addFieldsToGroup,
      selectFields,
      selectFlightGroup,
      toggleVisibility,
      errorNotification,
      flushFieldsGroup,
      openGoToFieldModal,
      toggleFocusSearch,
      initializeDashboard
    },
    dispatch
  )
}

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(DashboardApp)
