import get from 'lodash/fp/get'
import pipe from 'lodash/fp/pipe'
import isEmpty from 'lodash/fp/isEmpty'
import isUndefined from 'lodash/isUndefined'
import { advancedSearch } from '../constants.js'

const { TYPE_FILTER, CUSTOMER_FILTER, PLANTATION_RANGE_FILTER, SEARCH_INPUT_FILTER, CUT_NUMBER_FILTER, SEASON_LABEL_FILTER } = advancedSearch

export const getFilters = get('filters')

export const getFiltersByType = filterType => pipe(getFilters, get(filterType))

export const getFiltersCropsIds = getFiltersByType(TYPE_FILTER)

export const getFiltersCustomersIds = getFiltersByType(CUSTOMER_FILTER)

export const getFiltersPlantationDateRange = getFiltersByType(PLANTATION_RANGE_FILTER)

export const getFiltersSearchInputString = getFiltersByType(SEARCH_INPUT_FILTER)

export const getFiltersCutNumber = getFiltersByType(CUT_NUMBER_FILTER)

export const getFiltersSeasonLabel = getFiltersByType(SEASON_LABEL_FILTER)

export const isFiltersCropsIdsEmpty = pipe(getFiltersCropsIds, isEmpty)

export const isFiltersCustomersIdsEmpty = pipe(getFiltersCustomersIds, isEmpty)

export const isFilterSearchInputStringEmpty = pipe(getFiltersSearchInputString, isEmpty)

export const isFiltersPlantationDateRangeEmpty = pipe(getFiltersPlantationDateRange, ({ from, to }) => isUndefined(from) && isUndefined(to))

export const isFiltersCutNumberEmpty = pipe(getFiltersCutNumber, isEmpty)

export const isFiltersSeasonLabelEmpty = pipe(getFiltersSeasonLabel, isEmpty)

export const isFiltersEmpty = state => {
  const isCropsEmpty = isFiltersCropsIdsEmpty(state)
  const isCustomersEmpty = isFiltersCustomersIdsEmpty(state)
  const isSearchInputEmpty = isFilterSearchInputStringEmpty(state)
  const isPlantationDateRangeEmpty = isFiltersPlantationDateRangeEmpty(state)
  const isCutNumberEmpty = isFiltersCutNumberEmpty(state)
  const isSeasonLabelEmpty = isFiltersSeasonLabelEmpty(state)

  return isCropsEmpty && isCustomersEmpty && isSearchInputEmpty && isPlantationDateRangeEmpty && isCutNumberEmpty && isSeasonLabelEmpty
}
