import { connect } from 'react-redux'
import { activeLayers } from '../../actions/deliverables'
import { setSeasonLayersZIndexBottom, setSeasonLayersZIndexTop, expandSelectedFieldsAside } from '../../actions/ui'
import SelectedFieldsTabs from '../../components/FieldsComponents/SelectedFieldsTabs'
import { getSelectedFieldAsideExpanded, getUISeasonLayers } from '../../selectors/ui'
import { getActiveSamples } from '../../selectors/samples'
import { getSelectedFlightGroupDeliverables, getSelectedFlightGroupPdfDeliverables } from '../../selectors/flightGroups'
import { getAllSelectedFieldTabs, getSelectedFieldTab } from '../../selectors/selectedFieldTabs'
import { resetSelectedFieldTabs, hideSelectedFieldTab, setSelectedFieldTab, setSelectedFieldTabVisibility } from '../../actions/selectedFieldTabs'
import { hasSelectedFlightGroupGeometry } from '../../selectors/fields'
import isEmpty from 'lodash/isEmpty'
import { getSampleParametersByType } from '@layers-frontend/commons/store/selectors/sampleParametersByType'
import { getSampleTypes } from '@layers-frontend/commons/store/selectors/sampleTypes'
import { fetchSamplesParametersByType } from '../../actions/samplesParametersByType'
import { fetchSampleTypes } from '../../actions/sampleTypes'

const mapStateToProps = state => ({
  asideExpanded: getSelectedFieldAsideExpanded(state),
  layers: getSelectedFlightGroupDeliverables(state),
  seasonLayers: getUISeasonLayers(state),
  pdfDeliverables: getSelectedFlightGroupPdfDeliverables(state),
  samples: getActiveSamples(state),
  allSelectedFieldTabs: getAllSelectedFieldTabs(state),
  selectedFieldTab: getSelectedFieldTab(state),
  hasSelectedFlightGroupGeometry: hasSelectedFlightGroupGeometry(state),
  shouldFetch: isEmpty(getSampleParametersByType(state)) || isEmpty(getSampleTypes(state))
})

const mapDispatchToProps = {
  setSeasonLayersZIndexBottom,
  setSeasonLayersZIndexTop,
  activeLayers,
  expandSelectedFieldsAside,
  setSelectedFieldTab,
  setSelectedFieldTabVisibility,
  resetSelectedFieldTabs,
  hideSelectedFieldTab,
  fetchSamplesParametersByType,
  fetchSampleTypes
}
export default connect(mapStateToProps, mapDispatchToProps)(SelectedFieldsTabs)
