import React from 'react'
import styled from 'styled-components'

import { authorize } from '../HOCComponents/withAuthentication'

import { ROLE_AGRO, ROLE_COOPERATIVE } from '../../actions/users'
import { SEARCH_VIEW, FIELD_VIEW } from '../../actions/viewmode'

import StyledListButton from '../UIComponents/StyledListButton'

import { trackEvent } from '../analytics/analytics'
import { TRACK_EVENTS } from '../../constants'

const Wrapper = styled.div``

// eslint-disable-next-line import/no-anonymous-default-export
export default props => {
  const { userRoles = [], isSingleFieldSelected, t, currentLocation, openFieldSeasonFormModal } = props

  const isRoleUserAuthorized = authorize(userRoles, [ROLE_AGRO, ROLE_COOPERATIVE])

  const isAuthorizedAndIsSearchView = isRoleUserAuthorized && currentLocation.view === SEARCH_VIEW

  const isAuthorizedAndIsSingleFieldSelectedAndIsFieldView = isRoleUserAuthorized && isSingleFieldSelected && currentLocation.view === FIELD_VIEW

  const handleNewField = () => {
    openFieldSeasonFormModal('new')
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_CREATE_NEW_FIELD, {
      view: currentLocation.view,
      button: 'Create New Field',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  const handleEditField = () => {
    openFieldSeasonFormModal('edit')
    trackEvent(TRACK_EVENTS.LEFT_SIDEBAR_EDIT_FIELD, {
      view: currentLocation.view,
      button: 'Edit Field',
      location: TRACK_EVENTS.LEFT_SIDEBAR
    })
  }

  return (
    <Wrapper>
      <StyledListButton
        icon="ion-plus"
        labelDistance={6}
        onClick={handleNewField}
        label={t('Create New Field')}
        testId="open-close-editor-button"
        disabled={!isAuthorizedAndIsSearchView}
      />

      <StyledListButton
        icon="ion-edit"
        labelDistance={6}
        labelPosition="right"
        label={t('Edit Field')}
        testId="edit-field-button"
        onClick={handleEditField}
        disabled={!isAuthorizedAndIsSingleFieldSelectedAndIsFieldView}
      />
    </Wrapper>
  )
}
