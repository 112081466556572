import get from 'lodash/fp/get'
import find from 'lodash/fp/find'
import pipe from 'lodash/fp/pipe'
import keys from 'lodash/keys'
import map from 'lodash/fp/map'
import toNumber from 'lodash/fp/toNumber'
import { getSelectedFlightGroupSeasonIds } from './flightGroups'
import reduce from 'lodash/fp/reduce'
import { getSelectedFieldsSeasonsTimelineIds } from './fieldsSeasonsTimeline'
import { SAMPLES_MODE } from '../constants'
import { getSamplesMode } from './ui'
import size from 'lodash/size'
import orderBy from 'lodash/orderBy'

export const getSamples = get('samples')

export const getActiveSeasonSamples = state => {
  const fieldSeasonIds = pipe(getSelectedFlightGroupSeasonIds)(state)

  return reduce((accumulator, seasonId) => {
    const currentSamples = getSamplesBySeasonId(state, seasonId)
    return currentSamples ? [...accumulator, ...currentSamples] : accumulator
  }, [])(fieldSeasonIds)
}
export const getAllSeasonsSamples = state => {
  const fieldsAllSeasonIds = getSelectedFieldsSeasonsTimelineIds(state)

  return reduce((accumulator, seasonId) => {
    const currentSamples = getSamplesBySeasonId(state, seasonId)
    return currentSamples ? [...accumulator, ...currentSamples] : accumulator
  }, [])(fieldsAllSeasonIds)
}

export const getActiveSamples = state => {
  const samplesMode = getSamplesMode(state)
  const samples = samplesMode === SAMPLES_MODE.SEASON_SAMPLES ? getActiveSeasonSamples(state) : getAllSeasonsSamples(state)
  return orderBy(samples, 'sampleCreationDate', 'desc')
}

export const getSelectedSample = state => {
  const samplesMode = getSamplesMode(state)
  const samplesArr = samplesMode === SAMPLES_MODE.SEASON_SAMPLES ? getActiveSeasonSamples : getAllSeasonsSamples
  return pipe(samplesArr, find('selected'))(state)
}

export const getSamplesParameters = get('samplesParameters')

export const getSamplesParametersByType = get('sampleParametersByType')

export const getSamplesSeasonsIds = pipe(getSamples, keys, map(toNumber))

export const getSamplesBySeasonId = (state, seasonId) => pipe(getSamples, get(seasonId))(state)

export const hasSamples = pipe(getSamples, size)
