import { fetchRequestWrapper } from './ui'
import { REQUEST_EOX_CAPABILITIES, RECEIVE_EOX_CAPABILITIES } from '@layers-frontend/commons/constants'

const eoxUrl = `${window.location.origin}/eox`

export const fetchEox = () =>
  fetchRequestWrapper({
    customRoute: eoxUrl,
    requestType: REQUEST_EOX_CAPABILITIES,
    responseParser: 'text',
    onSuccess: response => {
      return { type: RECEIVE_EOX_CAPABILITIES, eoxXML: response }
    }
  })
