import React, { useEffect, useState } from 'react'
import withTranslator from '../HOCComponents/withTranslator'
import { Slider, Typography } from '@mui/material'
import styled from 'styled-components'

const valuetext = value => `${value}°C`

const SliderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${props => (props.wrapperStyle ? { ...props.wrapperStyle } : {})}
`

const StyledSlider = ({ t, label, labelStyle = {}, fullWidth, wrapperStyle, onChange, minValue, maxValue, defaultValue, step = 1, unit }) => {
  const marks = [
    {
      value: +minValue,
      label: `${minValue} ${unit}`
    },
    {
      value: +maxValue,
      label: `${maxValue} ${unit}`
    }
  ]

  const [sliderValue, setSliderValue] = useState(null)

  useEffect(() => {
    setSliderValue(+defaultValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SliderWrapper wrapperStyle={wrapperStyle}>
      <Typography style={labelStyle}>{t(label)}</Typography>
      <Slider
        defaultValue={+defaultValue}
        getAriaValueText={valuetext}
        step={step}
        valueLabelDisplay="auto"
        marks={marks}
        onChange={(_, value) => setSliderValue(value)}
        onChangeCommitted={() => onChange(sliderValue)}
        value={sliderValue}
        min={+minValue}
        max={+maxValue}
        unit={unit}
        style={{ width: fullWidth ? 'auto' : '200px' }}
      />
    </SliderWrapper>
  )
}

export default withTranslator(StyledSlider)
