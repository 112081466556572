import React from 'react'
import { CALENDAR_LEGENDS } from '@layers-frontend/commons/constants'
import { deliverablesSelectorTheme } from '../../theme'
import { ThemeProvider } from '@mui/material'
import withTranslator from '../HOCComponents/withTranslator'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import styled from 'styled-components'
import StyledSelect from '../UIComponents/StyledSelect'
import find from 'lodash/find'
import map from 'lodash/map'
import { Dot } from '../FieldsComponents/SelectedFieldsCalendarLegendItem'

const inputStyle = {
  color: themeColors.whiteColor,

  '::before': {
    display: 'none'
  },
  '::after': {
    display: 'none'
  }
}

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 15px;
`

const InputValue = styled.div`
  margin-left: 10px;
`

const wrapperStyle = {
  borderBottom: `1px solid ${themeColors.lightGrayHoverColor}`
}

const DeliverablesSourceSelector = ({ t, activeDateCalendarDots, selectFlightGroup, selectedFlightGroupIndex }) => {
  const options = map(activeDateCalendarDots, activeDateCalendarDot => ({
    id: activeDateCalendarDot.index,
    name: CALENDAR_LEGENDS[activeDateCalendarDot.source].name,
    color: activeDateCalendarDot.color
  }))

  const onChange = value => {
    selectFlightGroup(value)
  }

  return (
    <ThemeProvider theme={deliverablesSelectorTheme}>
      <StyledSelect
        defaultValue={selectedFlightGroupIndex}
        isCalendarSelector
        fullWidth={true}
        value={selectedFlightGroupIndex}
        onChange={onChange}
        options={options}
        inputStyle={inputStyle}
        wrapperStyle={wrapperStyle}
        renderValue={value => {
          const activeValue = find(options, item => item.id === value)

          return (
            <InputWrapper>
              <Dot key={activeValue.source} dotColor={activeValue.color} />
              <InputValue>{activeValue.name}</InputValue>
            </InputWrapper>
          )
        }}
      />
    </ThemeProvider>
  )
}

export default withTranslator(DeliverablesSourceSelector)
