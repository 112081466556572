import moment from 'moment'
import { TOGGLE_DASH_FILTER } from '../actions/dashboard'
import { ADD_FLIGHT_GROUPS, REQUEST_SATELLITE_FLIGHT_DATES, RECEIVE_FIELDS_COUNT } from '../actions/fields'
import { REQUEST_START } from '@layers-frontend/commons/store/storeConstants'
import { CHUNK_SIZE } from '@layers-frontend/commons/constants'
import { BOTTOM_LAYERS_BASE_Z_INDEX, SAMPLES_MODE, SEARCH_BAR_LIST_MODE, TOP_LAYERS_BASE_Z_INDEX } from '../constants'
import {
  UPDATE_SAT_STATISTICS_FETCH_FROM_DATE,
  UPDATE_RADAR_DATA_FETCH_FROM_DATE,
  EXPAND_FIELDS_DETAILS,
  COLLAPSE_FIELDS_DETAILS,
  SET_CHARTS_LAYOUT,
  GRID_LAYOUT,
  SET_VISIBILITY_SEASON_LAYER,
  SET_OPACITY_SEASON_LAYER,
  SET_ORDER_SEASON_LAYERS,
  TOGGLE_SEASON_LAYER_LEGENDS,
  SET_SEASON_LAYERS_TO_TOP,
  SET_SEASON_LAYERS_TO_BOTTOM,
  TOGGLE_SHOW_SAMPLE_MARKERS,
  TOGGLE_EXPANDED_SELECTED_FIELD_ASIDE,
  TOGGLE_TOOLBARS_DISABLED,
  SET_FIELDS_UPDATED_AT,
  SET_SEASON_LAYERS,
  SET_SEARCH_LIST_BAR_MODE,
  TOGGLE_CURRENT_SEASON_REPORTS_MODE,
  OPEN_NEW_SAMPLE_FORM,
  CLOSE_NEW_SAMPLE_FORM,
  SET_ZOOM_LEVEL,
  SET_VIEWPORT_BBOX
} from '../actions/ui'
import { START_SCREENSHOT_CAPTURE, END_SCREENSHOT_CAPTURE } from '../actions/utilities'
import { OPEN_SEARCH_MODE } from '../actions/viewmode'
import { arrayMove } from '../utils'
import { RECEIVE_SAMPLES_BY_SEASON_IDS, RECEIVE_SAMPLES_MODE, REQUEST_SAMPLES_BY_SEASON_IDS } from '../actions/samples'

import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import uniqBy from 'lodash/uniqBy'
import keys from 'lodash/keys'
import values from 'lodash/values'
import { RECEIVE_FIELDS_SEASONS_BY_IDS, REQUEST_FIELDS_SEASONS_BY_IDS } from '../actions/seasons'

const FIELDS_UPDATED_AT_FORMAT = 'YYYY/MM/DD hh:mm:ss'

const initialUIState = {
  baseZIndexGroupDeliverables: TOP_LAYERS_BASE_Z_INDEX,
  baseZIndexSeasonLayers: BOTTOM_LAYERS_BASE_Z_INDEX,
  fieldsUpdatedAt: moment().format(FIELDS_UPDATED_AT_FORMAT),
  isFlightGroupLoading: false,
  isUISelectedFieldsDetailsExpanded: false,
  radarDataFetchFromDate: moment().format('YYYY-MM-DD'),
  satStatisticsFetchFromDate: moment().format('YYYY-MM-DD'),
  screenshotOverlayVisibility: false,
  seasonLayers: [],
  selectedFieldAsideExpanded: true,
  shouldQueryFilters: false,
  showSamplesInMap: true,
  temporalEvolutionLayout: GRID_LAYOUT,
  toolbarsDisabled: false,
  samplesMode: SAMPLES_MODE.ALL_SAMPLES,
  searchBarListMode: SEARCH_BAR_LIST_MODE.ALL.value,
  isShowCurrentSeasonReportsMode: true,
  isSampleFormOpen: false,
  zoomLevel: null,
  viewportBBox: []
}

export default function UI(state = initialUIState, action = {}) {
  switch (action.type) {
    case SET_VIEWPORT_BBOX:
      return {
        ...state,
        viewportBBox: action.viewportBBox
      }
    case SET_ZOOM_LEVEL:
      return {
        ...state,
        zoomLevel: action.zoomLevel
      }

    case TOGGLE_DASH_FILTER:
    case UPDATE_SAT_STATISTICS_FETCH_FROM_DATE:
      return {
        ...state,
        satStatisticsFetchFromDate: action.date
      }

    case UPDATE_RADAR_DATA_FETCH_FROM_DATE:
      return {
        ...state,
        radarDataFetchFromDate: action.date
      }

    case EXPAND_FIELDS_DETAILS:
      return {
        ...state,
        isUISelectedFieldsDetailsExpanded: true
      }
    case COLLAPSE_FIELDS_DETAILS:
      return {
        ...state,
        isUISelectedFieldsDetailsExpanded: false
      }

    case SET_CHARTS_LAYOUT:
      return {
        ...state,
        temporalEvolutionLayout: action.layout
      }

    case START_SCREENSHOT_CAPTURE:
      return {
        ...state,
        screenshotOverlayVisibility: true
      }

    case END_SCREENSHOT_CAPTURE:
      return {
        ...state,
        screenshotOverlayVisibility: false
      }

    case SET_VISIBILITY_SEASON_LAYER:
      return {
        ...state,
        seasonLayers: map(state.seasonLayers, (layer, index) => (action.seasonLayerIndex === index ? { ...layer, visible: action.visible } : layer))
      }

    case SET_OPACITY_SEASON_LAYER:
      return {
        ...state,
        seasonLayers: map(state.seasonLayers, (layer, index) => (action.seasonLayerIndex === index ? { ...layer, opacity: action.opacity } : layer))
      }

    case SET_ORDER_SEASON_LAYERS:
      // eslint-disable-next-line no-case-declarations
      const seasonLayers = arrayMove([...state.seasonLayers], action.oldIndex, action.newIndex)
      return {
        ...state,
        seasonLayers
      }

    case TOGGLE_SEASON_LAYER_LEGENDS:
      return {
        ...state,
        seasonLayers: map(state.seasonLayers, (layer, index) => {
          if (index === action.seasonLayerIndex) {
            return {
              ...layer,
              opened: !layer.opened
            }
          }

          return layer
        })
      }

    case SET_SEASON_LAYERS: {
      const { seasonLayers } = action
      const seasonLayersArr = reduce(
        keys(seasonLayers),
        (accumulator, seasonId) => {
          return [...accumulator, ...values(seasonLayers[seasonId])]
        },
        []
      )
      const visibleLayerIndex = seasonLayersArr.length - 1

      const layers = map(seasonLayersArr, (layer, index) => {
        const deliverableType = get(layer, 'deliverable.deliverableType')
        const { id, name, legends } = deliverableType
        const layerId = get(layer, ['deliverable', 'id'])

        return {
          id: layerId,
          opened: false,
          visible: index === visibleLayerIndex,
          opacity: 1,
          type: {
            name,
            id,
            legend_colors: legends
          }
        }
      })
      return {
        ...state,
        seasonLayers: uniqBy(layers, 'type.id')
      }
    }

    case SET_SEASON_LAYERS_TO_TOP:
      return {
        ...state,
        baseZIndexSeasonLayers: TOP_LAYERS_BASE_Z_INDEX,
        baseZIndexGroupDeliverables: BOTTOM_LAYERS_BASE_Z_INDEX
      }

    case SET_SEASON_LAYERS_TO_BOTTOM:
      return {
        ...state,
        baseZIndexSeasonLayers: BOTTOM_LAYERS_BASE_Z_INDEX,
        baseZIndexGroupDeliverables: TOP_LAYERS_BASE_Z_INDEX
      }

    case OPEN_SEARCH_MODE:
      return {
        ...state,
        seasonLayers: [],
        isFlightGroupLoading: false,
        samplesMode: SAMPLES_MODE.ALL_SAMPLES,
        isSampleFormOpen: false
      }
    case REQUEST_START:
      // eslint-disable-next-line no-case-declarations
      const { requestType } = action
      if (requestType === REQUEST_SAMPLES_BY_SEASON_IDS || requestType === REQUEST_FIELDS_SEASONS_BY_IDS || requestType === REQUEST_SATELLITE_FLIGHT_DATES) {
        return {
          ...state,
          isFlightGroupLoading: true
        }
      }
      return state
    case ADD_FLIGHT_GROUPS:
    case RECEIVE_FIELDS_SEASONS_BY_IDS:
    case RECEIVE_SAMPLES_BY_SEASON_IDS:
      return {
        ...state,
        isFlightGroupLoading: false
      }
    case TOGGLE_SHOW_SAMPLE_MARKERS:
      return {
        ...state,
        showSamplesInMap: action.show
      }

    case TOGGLE_EXPANDED_SELECTED_FIELD_ASIDE:
      return {
        ...state,
        selectedFieldAsideExpanded: action.expanded
      }

    case TOGGLE_TOOLBARS_DISABLED:
      return {
        ...state,
        toolbarsDisabled: !state.toolbarsDisabled
      }

    case SET_FIELDS_UPDATED_AT:
      return {
        ...state,
        fieldsUpdatedAt: moment().format(FIELDS_UPDATED_AT_FORMAT)
      }
    case RECEIVE_SAMPLES_MODE:
      return {
        ...state,
        samplesMode: action.mode
      }
    case SET_SEARCH_LIST_BAR_MODE:
      return {
        ...state,
        searchBarListMode: action.mode
      }
    case TOGGLE_CURRENT_SEASON_REPORTS_MODE:
      return {
        ...state,
        isShowCurrentSeasonReportsMode: !state.isShowCurrentSeasonReportsMode
      }

    case RECEIVE_FIELDS_COUNT:
      // eslint-disable-next-line no-case-declarations
      const { fieldIds } = action
      if (fieldIds.length < CHUNK_SIZE) {
        return state
      }

      return {
        ...state,
        shouldQueryFilters: true
      }

    case OPEN_NEW_SAMPLE_FORM:
      return {
        ...state,
        isSampleFormOpen: true
      }
    case CLOSE_NEW_SAMPLE_FORM:
      return {
        ...state,
        isSampleFormOpen: false
      }
    default:
      return state
  }
}
