import PropTypes from 'prop-types'
import Draw from './draw'
import { buildStyle } from '../style'

//ol
import OlDraw from 'ol/interaction/Draw'

export default class DrawCircle extends Draw {
  createInteraction(props, context) {
    return new OlDraw({
      type: 'Circle',
      style: buildStyle(props.style),
      source: context.source
    })
  }
}

DrawCircle.propTypes = {
  active: PropTypes.bool,
  drawend: PropTypes.func,
  drawstart: PropTypes.func,
  style: PropTypes.arrayOf(PropTypes.object)
}
