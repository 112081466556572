import { connect } from 'react-redux'

import SamplesLayerInteraction from '../../components/olComponents/interaction/SamplesLayerInteraction'
import { selectSampleById, deselectAllSamples } from '../../actions/samples'
import { getSeasonLayersBaseZIndex, shouldShowSamplesInMap } from '../../selectors/ui'
import { TOP_LAYERS_BASE_Z_INDEX } from '../../constants'
import { isFieldView } from '../../selectors/viewmode'
import { getSelectedSample } from '../../selectors/samples'
import { setSelectedFieldSamplesTab } from '../../actions/selectedFieldTabs'

const mapStateToProps = state => ({
  isFieldViewActive: isFieldView(state),
  isSeasonLayersTabActive: getSeasonLayersBaseZIndex(state) === TOP_LAYERS_BASE_Z_INDEX,
  showSamplesInMap: shouldShowSamplesInMap(state),
  selectedSample: getSelectedSample(state)
})

const mapDispatchToProps = {
  selectSampleById,
  deselectAllSamples,
  setSelectedFieldSamplesTab
}

export default connect(mapStateToProps, mapDispatchToProps)(SamplesLayerInteraction)
