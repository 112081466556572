import React from 'react'
import styled from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import get from 'lodash/get'
import includes from 'lodash/includes'

const GoToFieldButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const MapIcon = styled.i`
  padding-right: 5px;
  color: ${themeColors.lightBlue};
`

const GoToFieldButton = styled.button`
  background-color: ${themeColors.blueColor};
  color: ${themeColors.whiteColor};
  border: 1px solid ${themeColors.lightBlue};
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 5px;
  &:hover {
    color: ${themeColors.vomitColor};
    background-color: ${themeColors.blueColor};
    border: 1px solid ${themeColors.vomitColor};
    ${MapIcon} {
      color: ${themeColors.vomitColor};
    }
  }
`

export default function ReportGoToFieldsButton({ goToField, closeModal, fieldsIds, t }) {
  const urlPathName = get(window, 'location.href')
  const isDashboard = includes(urlPathName, 'dashboard')

  const handleSelectFields = () => {
    closeModal()
    goToField(fieldsIds, isDashboard)
  }

  return (
    <GoToFieldButtonContainer>
      <GoToFieldButton onClick={handleSelectFields}>
        <MapIcon className="far fa-map" />
        {t('go to field')}
      </GoToFieldButton>
    </GoToFieldButtonContainer>
  )
}
