import { connect } from 'react-redux'
import { compose } from 'redux'
import { getFiltersPlantationDateRange } from '../../selectors/filters'
import { withTranslator } from '../../components/HOCComponents/withTranslator'
import { setPlantationDateFilters } from '../../actions/filters'
import { closeModal } from '../../actions/modal'
import PlantationDateFilterModal from '../../components/ModalComponents/PlantationDateFilterModal'

const mapStateToProps = state => ({
  plantationDate: getFiltersPlantationDateRange(state)
})

const mapDispatchToProps = { setPlantationDateFilters, closeModal }

export default compose(withTranslator, connect(mapStateToProps, mapDispatchToProps))(PlantationDateFilterModal)
