import { compose } from 'redux'
import { connect } from 'react-redux'

import { getLocale } from '../selectors/i18n'
import { getIsAppReady } from '../selectors/config'
import AuthenticatedApp from '../components/AuthenticatedApp'
import { getUser, getUserName } from '../selectors/users'
import { getToolbarsDisabled } from '../selectors/ui'
import { hasRoleIrriga, hasMobileControlActionDisabled } from '@layers-frontend/commons/store/selectors/user'
import { seti18n, setLocale } from '../actions/locale'

const mapStateToProps = state => ({
  locale: getLocale(state),
  isAppReady: getIsAppReady(state),
  user: getUser(state),
  userName: getUserName(state),
  toolbarsDisabled: getToolbarsDisabled(state),
  hasRoleIrriga: hasRoleIrriga(state),
  hasMobileControlActionDisabled: hasMobileControlActionDisabled(state)
})

const mapDispatchToProps = {
  setLocale,
  seti18n
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(AuthenticatedApp)
