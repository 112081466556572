import PropTypes from 'prop-types'

import OLContainer from './ol-container'

import OlLayerGroup from 'ol/layer/Group'
import OlLayerTile from 'ol/layer/Tile'
import OlSourceTileWMS from 'ol/source/TileWMS'
import XYZ from 'ol/source/XYZ.js'
import OlMap from 'ol/Map'
import { createXYZ } from 'ol/tilegrid'
import Utils from '../../geometryUtils'

import {
  DRONE_RGB_SNAPSHOT_SOURCE,
  DRONE_SNAPSHOT_SOURCE,
  PLANET_SNAPSHOT_SOURCE,
  RADAR_ASC_SNAPSHOT_SOURCE,
  RADAR_DESC_SNAPSHOT_SOURCE,
  SAT_SNAPSHOT_SOURCE
} from '@layers-frontend/commons/constants'

import get from 'lodash/get'
import includes from 'lodash/includes'
import toString from 'lodash/toString'

export default class LayerGroup extends OLContainer {
  constructor(props) {
    super(props)
    const _addApiKeyToTilerUrl = url => {
      return `${url}&api_key=${this.props.token}`
    }
    const _getTileGridBySource = source => {
      switch (source) {
        case SAT_SNAPSHOT_SOURCE:
        case RADAR_ASC_SNAPSHOT_SOURCE:
        case RADAR_DESC_SNAPSHOT_SOURCE:
          return createXYZ({ maxZoom: 17, minZoom: 14 })
        case PLANET_SNAPSHOT_SOURCE:
        case DRONE_RGB_SNAPSHOT_SOURCE:
        case DRONE_SNAPSHOT_SOURCE:
          return createXYZ({ maxZoom: 22, minZoom: 14 })
        default:
          return createXYZ({ maxZoom: 22, minZoom: 0 })
      }
    }
    const _buildDeliverablesLayers = () => {
      const layers = this.props.layers
        .filter(layer => !!layer)
        .map(layer => {
          const tilerBaseUrl = process.env.REACT_APP_TILER_BASE_URL
          const url = get(layer, 'url')
          const isXYZ = tilerBaseUrl && includes(url, tilerBaseUrl)

          const tile = new OlLayerTile({
            zIndex: this.props.zIndex,
            source: !isXYZ
              ? new OlSourceTileWMS({
                  // eslint-disable-next-line object-shorthand
                  url: url,
                  tileGrid: _getTileGridBySource(layer.source),
                  params: {
                    SERVICE: 'WMS',
                    REQUEST: 'GetMap',
                    TRANSPARENT: true,
                    TILED: true
                  }
                })
              : new XYZ({
                  url: _addApiKeyToTilerUrl(url),
                  tileSize: 512
                })
          })
          // eslint-disable-next-line no-extra-boolean-cast
          if (!!layer.extent) {
            tile.setExtent(Utils.Xtransform(layer.extent))
          }

          return tile
        })
      return layers
    }

    this.layerGroup = new OlLayerGroup({
      visible: this.props.visible,
      name: this.props.name,
      zIndex: this.props.zIndex,
      layers: _buildDeliverablesLayers()
    })
    this.layerGroup.setZIndex(props.zIndex)
    this.layerGroup.setOpacity(props.opacity)
    this.layerGroup.setVisible(props.visible)
  }

  _addSplitScreenAbilities = tile => {
    tile.on('precompose', event => {
      // eslint-disable-next-line no-var
      var ctx = event.context
      // eslint-disable-next-line no-var
      var width = ctx.canvas.width * (this.props.temporalSwipe / window.innerWidth)
      ctx.save()
      ctx.beginPath()
      if (toString(this.props.display) === 'all') {
        ctx.rect(0, 0, ctx.canvas.width, ctx.canvas.height)
      } else if (toString(this.props.display) === 'right') {
        ctx.rect(width, 0, ctx.canvas.width - width, ctx.canvas.height)
      } else {
        ctx.rect(0, 0, width, ctx.canvas.height)
      }

      ctx.clip()
    })

    tile.on('postcompose', function (event) {
      // eslint-disable-next-line no-var
      var ctx = event.context
      ctx.restore()
    })

    return tile
  }

  getChildContext() {
    return {
      layerGroup: this.layerGroup,
      map: this.context.map
    }
  }

  componentDidMount() {
    this.context.map.addLayer(this.layerGroup)
    this.layerGroup.getLayers().forEach(layer => {
      layer = this._addSplitScreenAbilities(layer)
    })
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    this.layerGroup.setOpacity(newProps.opacity)
    this.layerGroup.setVisible(newProps.visible)

    this.layerGroup.getLayers().forEach((layer, index) => {
      layer.setZIndex(newProps.zIndex)
      if (get(newProps, ['layers', index])) {
        layer.setExtent(Utils.Xtransform(newProps.layers[index].extent))
      }
    })

    // Update urls update after COG validation
    this.layerGroup.getLayers().forEach((layer, index) => {
      if (get(newProps, ['layers', index])) {
        layer.getSource().setUrl(newProps.layers[index].url)
      }
    })

    this.context.map.render()
  }

  componentWillUnmount() {
    this.context.map.removeLayer(this.layerGroup)
  }
}

LayerGroup.propTypes = {
  children: PropTypes.object,
  display: PropTypes.string,
  opacity: PropTypes.number,
  visible: PropTypes.bool,
  zIndex: PropTypes.number
}

LayerGroup.defaultProps = {
  visible: true
}

LayerGroup.contextTypes = {
  map: PropTypes.instanceOf(OlMap)
}

LayerGroup.childContextTypes = {
  layerGroup: PropTypes.instanceOf(OlLayerGroup),
  map: PropTypes.instanceOf(OlMap)
}
