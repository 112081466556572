import { connect } from 'react-redux'
import FieldSelector from '../../components/editorComponents/FieldSelector'
import { removeFieldToGroup, selectFields, addFieldsToGroup } from '../../actions/fields'
import { bindActionCreators } from 'redux'

import { getScreenView } from '../../selectors/viewmode'
import { getSelectedFieldsIds } from '../../selectors/fields'
import { isEditorPresentActive, isEditorMeasureToolActive } from '../../selectors/editor'

const mapStateToProps = state => ({
  screenView: getScreenView(state),
  isSelectActive: !isEditorPresentActive(state) && !isEditorMeasureToolActive(state),
  selectedFieldsIds: getSelectedFieldsIds(state)
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      /* eslint-disable object-shorthand */
      addFieldsToGroup: addFieldsToGroup,
      selectFields: selectFields,
      removeFieldToGroup: removeFieldToGroup
      /* eslint-enable object-shorthand */
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(FieldSelector)
