import React from 'react'
import { Button, ButtonGroup } from '@mui/material'
import map from 'lodash/map'
import themeColors from '@layers-frontend/commons/styles/themeColors'
import withTranslator from '../HOCComponents/withTranslator'
import isEqual from 'lodash/isEqual'
import styled from 'styled-components'

const buttonStyle = {
  color: themeColors.whiteColor,
  borderColor: themeColors.whiteColor,
  background: themeColors.transparent,
  '&:hover': {
    borderColor: themeColors.whiteColor,
    background: themeColors.blueLightGrey
  },
  '&.Mui-disabled': {
    color: themeColors.grayColor,
    borderColor: themeColors.grayColor
  }
}
const selectedButtonStyle = {
  background: themeColors.blueLightGrey
}
const TextLabel = styled.p`
  color: white;
  font-size: 12px;
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const StyledButtonGroup = ({ t, selectedButton, onChange, buttons, textLabel = null, fullWidth = true, disabled }) => {
  return (
    <Wrapper>
      {textLabel && <TextLabel>{textLabel}</TextLabel>}
      <ButtonGroup size="small" aria-label="small button group" fullWidth={fullWidth} onClick={disabled ? null : onChange}>
        {map(buttons, button => (
          <Button disabled={disabled} value={button.value} sx={[buttonStyle, isEqual(button.value, selectedButton) && selectedButtonStyle]} key={button.value}>
            {t(button.label)}
          </Button>
        ))}
      </ButtonGroup>
    </Wrapper>
  )
}

export default withTranslator(StyledButtonGroup)
