import get from 'lodash/get'
import { SET_MESSAGES } from '../storeConstants'
import es from '../../translations/es'
import en from '../../translations/en'
import pt from '../../translations/pt'
import fr from '../../translations/fr'
import hu from '../../translations/hu'
import sr from '../../translations/sr'
import ro from '../../translations/ro'
import sk from '../../translations/sk'
import it from '../../translations/it'
import uk from '../../translations/uk'
import ru from '../../translations/ru'
import de from '../../translations/de'

import esDb from '../../translations/es_in_db'
import enDb from '../../translations/en_in_db'
import ptDb from '../../translations/pt_in_db'
import frDb from '../../translations/fr_in_db'
import huDb from '../../translations/hu_in_db'
import srDb from '../../translations/sr_in_db'
import roDb from '../../translations/ro_in_db'
import skDb from '../../translations/sk_in_db'
import itDb from '../../translations/it_in_db'
import ukDb from '../../translations/uk_in_db'
import ruDb from '../../translations/ru_in_db'
import deDb from '../../translations/de_in_db'

const translations = {
  es: { ...es, ...esDb },
  en: { ...en, ...enDb },
  pt: { ...pt, ...ptDb },
  fr: { ...fr, ...frDb },
  sr: { ...sr, ...srDb },
  ro: { ...ro, ...roDb },
  hu: { ...hu, ...huDb },
  sk: { ...sk, ...skDb },
  it: { ...it, ...itDb },
  uk: { ...uk, ...ukDb },
  ru: { ...ru, ...ruDb },
  de: { ...de, ...deDb }
}

const getTranslations = (locale = 'es') => get(translations, locale)

export const setMessages = messages => ({ type: SET_MESSAGES, messages })

export const setMessagesFromLocale = locale => setMessages(getTranslations(locale))
