import React from 'react'
import styled, { css } from 'styled-components'
import themeColors from '@layers-frontend/commons/styles/themeColors'

import noop from 'lodash/noop'

const StyledButton = styled.button`
  color: ${props => (props.color ? props.color : themeColors.darkBlue)};
  border: none;
  background-color: ${props => (props.background ? props.background : themeColors.vomitColor)};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  padding: 1rem 1.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  border-radius: 0.3rem;
  line-height: 1;
  i {
    margin-left: 0.5rem;
  }
  ${props =>
    props.text &&
    css`
      display: inline-flex;
      text-decoration-line: underline;
      padding-left: 0;
    `};
`

export const Button = ({ label, onClick = noop, background, color, icon, disabled, text, testId }) => (
  <StyledButton
    onClick={e => {
      e.preventDefault()
      onClick()
    }}
    background={background}
    color={color}
    disabled={disabled}
    text={text}
    data-testid={testId}
  >
    {label}
    {icon && <i className={icon} />}
  </StyledButton>
)

export const DangerButton = props => <Button {...props} background={themeColors.niceRedColor} />

export const TextButton = props => <Button {...props} background={themeColors.blueColor} text />
