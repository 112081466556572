import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { ActionCreators as UndoActionCreators } from 'redux-undo'

export const connectUndo = WrappedComponent => {
  const mapStateToProps = state => {
    return {
      canUndo: state.editor.past.length > 0,
      canRedo: state.editor.future.length > 0
    }
  }

  const mapDispatchToProps = dispatch => {
    return bindActionCreators(
      {
        onUndoClick: () => UndoActionCreators.undo(),
        onRedoClick: () => UndoActionCreators.redo()
      },
      dispatch
    )
  }

  return connect(mapStateToProps, mapDispatchToProps)(WrappedComponent)
}
