import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setTutorial, changeStep } from '../../actions/tutorial'
import toString from 'lodash/toString'

export const withTutorial = (tutorialName, ...Pages) => WrappedComponent => {
  class Tutorial extends React.Component {
    /*this.state = {
				isAuthorized : isAuthorized	
			}
			*/

    /*authorizeUser(roles) {
			
			let authorization = false;
			
			roles.forEach(role => {
				if (this.props.UserRoles.includes(role))
					authorization = true;
			})
			
			return authorization
		}
		*/

    isMe() {
      return toString(tutorialName) === toString(this.props.tutorial.name)
    }

    /*firstStep() {
			
			if (this.isMe() && this.props.isItOpen && this.props.step == 1)
				this.props.changeStep(2);
		}*/

    setAction(event) {
      if (!this.isMe() && this.props.isTutorialOpen) this.props.setTutorial(tutorialName)

      this.blockChildrenEvents(event)
    }

    blockChildrenEvents(event) {
      if (this.props.isTutorialOpen) {
        event.stopPropagation()
        event.preventDefault()
      }
    }

    render() {
      return (
        <div
          className={
            'tutorialComponent ' +
            (((this.props.isTutorialOpen && this.props.tutorial.name == null) || this.isMe()) &&
              (Pages.includes(this.props.screenView) || !Pages.length ? 'tuthighlighted' : ''))
          }
          onClickCapture={event => this.setAction(event)}
          onFocusCapture={event => this.blockChildrenEvents(event)}
          onMouseDownCapture={event => this.blockChildrenEvents(event)}
          onDragCapture={event => this.blockChildrenEvents(event)}
          onDragStartCapture={event => this.blockChildrenEvents(event)}
          onDragOverCapture={event => this.blockChildrenEvents(event)}
          onMouseMoveCapture={event => this.blockChildrenEvents(event)}
          onMouseUpCapture={event => this.blockChildrenEvents(event)}
        >
          <WrappedComponent {...this.props} />
        </div>
      )
    }
  }

  function mapStateToProps(state, props) {
    // REDACTED
    return {
      tutorial: state.tutorial,
      isTutorialOpen: state.tutorial.isOpen,
      step: state.tutorial.steps,
      screenView: state.screenView
    }
  }

  const mapDispatchToProps = dispatch => {
    // eslint-disable-next-line object-shorthand
    return bindActionCreators({ setTutorial: setTutorial, changeStep: changeStep }, dispatch)
  }

  return connect(mapStateToProps, mapDispatchToProps)(Tutorial)
}
